module["exports"] = [
  "Afghanistan",
  "Ai Cập",
  "Albania",
  "Algérie",
  "Andorra",
  "Angola",
  "Vương quốc Liên hiệp Anh và Bắc Ireland",
  "Antigua và Barbuda",
  "Áo",
  "Ả Rập Xê Út",
  "Argentina",
  "Armenia",
  "Azerbaijan",
  "Ấn Độ",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belize",
  "Bénin",
  "Bhutan",
  "Bỉ",
  "Bolivia",
  "Bosna và Hercegovina",
  "Botswana",
  "Bồ Đào Nha",
  "Bờ Biển Ngà",
  "Brasil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Các Tiểu Vương quốc Ả Rập Thống nhất",
  "Cameroon",
  "Campuchia",
  "Canada",
  "Chile",
  "Colombia",
  "Comoros",
  "Cộng hòa Congo",
  "Cộng hòa Dân chủ Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Djibouti",
  "Dominica",
  "Cộng hòa Dominica",
  "Đan Mạch",
  "Đông Timor",
  "Đức",
  "Ecuador",
  "El Salvador",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Gabon",
  "Gambia",
  "Ghana",
  "Grenada",
  "Gruzia",
  "Guatemala",
  "Guiné-Bissau",
  "Guinea Xích Đạo",
  "Guinée",
  "Guyana",
  "Haiti",
  "Hà Lan",
  "Hàn Quốc",
  "Hoa Kỳ",
  "Honduras",
  "Hungary",
  "Hy Lạp",
  "Iceland",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Jamaica",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Lào",
  "Latvia",
  "Lesotho",
  "Liban",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Litva",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malaysia",
  "Mali",
  "Malta",
  "Maroc",
  "Quần đảo Marshall",
  "Mauritanie",
  "Mauritius",
  "México",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mông Cổ",
  "Montenegro",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nam Sudan",
  "Nam Phi",
  "Nauru",
  "Na Uy",
  "Nepal",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Nga",
  "Nhật Bản",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Pháp",
  "Phần Lan",
  "Philippines",
  "Qatar",
  "România",
  "Rwanda",
  "Saint Kitts và Nevis",
  "Saint Lucia",
  "Saint Vincent và Grenadines",
  "Samoa",
  "San Marino",
  "São Tomé và Príncipe",
  "Séc",
  "Sénégal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Síp",
  "Slovakia",
  "Slovenia",
  "Solomon",
  "Somalia",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Tây Ban Nha",
  "Tchad",
  "Thái Lan",
  "Thổ Nhĩ Kỳ",
  "Thụy Điển",
  "Thụy Sĩ",
  "Togo",
  "Tonga",
  "Triều Tiên",
  "Trinidad và Tobago",
  "Trung Quốc",
  "Trung Phi",
  "Tunisia",
  "Turkmenistan",
  "Tuvalu",
  "Úc",
  "Uganda",
  "Ukraina",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican",
  "Venezuela",
  "Việt Nam",
  "Ý",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];
