module["exports"] = [
  "0800 ### ###",
  "0800 ## ## ##",
  "0## ### ## ##",
  "0## ### ## ##",
  "+41 ## ### ## ##",
  "0900 ### ###",
  "076 ### ## ##",
  "079 ### ## ##",
  "078 ### ## ##",
  "+4176 ### ## ##",
  "+4178 ### ## ##",
  "+4179 ### ## ##",
  "0041 76 ### ## ##",
  "0041 78 ### ## ##",
  "0041 79 ### ## ##",
];
