import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import { getFacilityPages } from "../../../components/Navbar/AdminNavBarContent";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { OverviewHeader } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import Title from "../../../styles/Title";
import { ArrayElement, ThenArg } from "../../../types";

const columnSizes = [3, 3, 1, 1];

const NotificationOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.notification.overview.location"), false),
    createHeaderItem(t("admin.notification.overview.reason"), true, "reason"),
    //createHeaderItem("", false),
  ];
  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listReportReasons>[1]>(tableHeaders);

  const userCompany = userState?.companyIds[0];

  type ReportReasonItem = ArrayElement<
    ThenArg<
      ReturnType<typeof client.listReportReasons>
    >["data"]["result"]["items"]
  >;

  const [data, setData] = useState<Array<ReportReasonItem>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<ReportReasonItem>();

  const history = useHistory();

  const fetchListItems = useCallback(async () => {
    if (userCompany) {
      return handleRequest(
        client.listReportReasons(
          userCompany,
          sortField,
          sortOrder,
          takeSize,
          skipParam
        )
      );
    }
  }, [userCompany, handleRequest, client, sortField, sortOrder, skipParam]);

  useEffect(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setTableData(createTableData(items));
        setData(items || []);
        setTotalItems(total);
      }
    });
  }, [skipParam, sortOrder, sortField, userCompany]);

  const createTableData = useCallback((items: ReportReasonItem[]) => {
    const tableData: TableContent[] = items.map(
      ({ reason, location, id, _operations }) => {
        const data: ReactElement[] = [
          <ExtraSmallText key="label">{location.name}</ExtraSmallText>,
          <ExtraSmallText key="label">{reason}</ExtraSmallText>,
        ];
        return {
          data,
          id,
          canDelete: _operations?.canDelete,
          cannotDeleteDescription: _operations?.cannotDeleteDescription,
          canEdit: true,
        };
      }
    );
    return tableData;
  }, []);

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteReportReason(itemSelected!.id)
    );

    if (response && response.status === 204) {
      await fetchListItems().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items, total } = result.data.result;
        if (items) {
          setTableData(createTableData(items));
          setData(items);
          setTotalItems(total);
        }
      });

      setShowDeleteMessage(true);
    }
  }, [
    client,
    createTableData,
    setTableData,
    setTotalItems,
    fetchListItems,
    setData,
    itemSelected,
    setShowDeleteModal,
  ]);

  return (
    <>
      <Container>
        <OverviewHeader>
          <Title>{t("admin.notification.overview.title")}</Title>
          <Button
            onPress={() => history.push("/admin/notification-overview/create")}
          >
            {t("admin.notification.overview.addButton")}
          </Button>
        </OverviewHeader>
        <PageSelector pages={getFacilityPages()} />
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        ) : null}
        {isLoading ? null : (
          <Table
            tableId="notification_overview"
            headerItems={headerItems}
            tableContent={tableData}
            columnSizes={columnSizes}
            sortList={onSortList}
            sortOrder={sortOrder}
            sortField={sortField}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/notification-overview/update/${id}`)
            }
            onDeleteItem={openDeleteModal}
          />
        )}
        {totalItems ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.notification.delete.warning")}
      />
    </>
  );
};

export default NotificationOverview;
