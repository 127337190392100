module["exports"] = [
  "(0##) ### ####",
  "0## ### ####",
  "+27 ## ### ####",
  "01# ### #####",
  "02# ### #####",
  "03# ### #####",
  "04# ### #####",
  "05# ### #####",
  "0800 ### ###",
  "0860 ### ###",
  "01#########",
  "01# ########"
];