import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { t } from "../../i18n";

import { AmountIndicatorSecondary } from "../../styles/AmountIndicator";
import { SmallTitle } from "../../styles/Title";

import Checkbox from "../Checkbox";
import Toggle from "../Toggle";
import Filter, { FilterHeader } from ".";
import { ExtraSmallText } from "../../styles/Text";
import PickerInput from "../PickerInput";
import styled from "styled-components/native";
import { spacing } from "../../styles/theme";
import { getLanguageItems } from "../../screens/admin/CompanyOverview/Create";
import useUserGroups from "../../hooks/useUserGroups";
import { useAuth } from "../../context/authContext";
import Select, { SelectItemInterface } from "../Select";

const Row = styled.View`
  margin-bottom: ${spacing.medium};
`;

export interface UserFilter {
  isVisible?: boolean;
  active?: boolean;
  language?: string;
  department?: string;
}

interface UsersFilterInterface {
  showFilter: boolean;
  setShowFilter(newValue: boolean): void;
  onFilter(filterObject: UserFilter, filterSize: number): void;
}

export const getInviteOptions = () => [
  {
    label: t("general.yes"),
    value: true,
  },
  {
    label: t(`general.no`),
    value: false,
  },
];
export const getStatusOptions = () => [
  {
    label: t("general.enabled"),
    value: "active",
  },
  {
    label: t(`general.disabled`),
    value: "inactive",
  },
];

const UsersFilter: FC<UsersFilterInterface> = ({
  showFilter,
  setShowFilter,
  onFilter,
}) => {
  const { userState } = useAuth();

  const statusOptions = getStatusOptions().map((item) => ({
    label: item.label,
    value: item.value,
  }));
  const inviteOptions = getInviteOptions().map((item) => ({
    label: item.label,
    value: item.value,
  }));
  const languages = getLanguageItems().map((item) => ({
    label: item.label,
    value: item.value,
  }));

  const [isActive, setIsActive] = useState("");
  const [inviteSend, setInviteSend] = useState("");

  const [language, setLanguage] = useState("");
  const [inviteOption, setInviteOption] = useState("");
  const [statusOption, setStatusOption] = useState("");

  const [userGroupId, setUserGroupId] = useState("");
  const userGroups = useUserGroups(userState?.companyIds[0] || "");
  const userGroupOptions = useMemo(() =>
      userGroups.map((group) => ({ value: group.id, label: group.label })),
    [userGroups],
  );

  const createFilter = (): UserFilter => ({
    isVisible: inviteSend || undefined,
    active: isActive || undefined,
    language: language || undefined,
    department: userGroupId || undefined,
  });

  const getFilterSize = useCallback(() => {
    const arr = [inviteSend, isActive, language, userGroupId];
    return arr.filter(Boolean).length;
  }, [inviteSend, isActive, language, userGroupId]);

  const applyFilter = () => {
    onFilter(createFilter(), getFilterSize());
    setShowFilter(false);
  };

  const cancelFilter = useCallback(
    (hideFilter: boolean) => {
      setShowFilter(hideFilter);
    },
    [setShowFilter],
  );

  return (
    <Filter
      showFilter={showFilter}
      setShowFilter={cancelFilter}
      setFilter={applyFilter}
    >
      <FilterHeader>
        <SmallTitle hasMarginBottom>{t("general.filter.title")}</SmallTitle>
        <AmountIndicatorSecondary hasMarginBottom>
          {getFilterSize()}
        </AmountIndicatorSecondary>
      </FilterHeader>
      <Row>
      <ExtraSmallText mediumWeight>
        {t("admin.user.filter.userStatus")}
      </ExtraSmallText>
      <PickerInput
        noMarginBottom
        selectedValue={isActive}
        onValueChange={(itemValue) => setIsActive(itemValue.toString())}
        items={[
          {
            label: t("admin.reservation.overview.choose"),
            value: "",
          },
          ...statusOptions,
        ]}
      />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.user.filter.invitationSend")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={inviteSend}
          onValueChange={(itemValue) => setInviteSend(itemValue.toString())}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...inviteOptions,
          ]}
        />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.user.filter.userGroups")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={userGroupId}
          onValueChange={(itemValue) => setUserGroupId(itemValue.toString())}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...userGroupOptions,
          ]}
        />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.user.filter.language")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={language}
          onValueChange={(itemValue) => setLanguage(itemValue.toString())}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...languages,
          ]}
        />
      </Row>
    </Filter>
  );
};

export default UsersFilter;
