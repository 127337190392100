import { REACT_APP_CLIENT_ID } from "@env";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Pressable, Linking, Clipboard } from "react-native";
import styled from "styled-components/native";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import Link from "../../../components/Link";
import LoadOverlay from "../../../components/LoadOverlay";
import { getUserPages } from "../../../components/Navbar/AdminNavBarContent";
import copyEmailIcon from "../../../assets/icons/icon-copy-email.svg";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import SearchInput from "../../../components/SearchInput";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { Roles, useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useImpersonation } from "../../../hooks/useImpersonation";
import { useSearch } from "../../../hooks/useSearch";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import {
  OverviewHeader,
  OverviewHeaderInnerRight,
} from "../../../styles/Overview";
import { ExtraSmallText, SmallText } from "../../../styles/Text";
import { color, spacing } from "../../../styles/theme";
import Title from "../../../styles/Title";
import { ArrayElement, ThenArg } from "../../../types";

const UploadIcon = styled.Image`
  margin-right: ${spacing.small};
  color: ${color.grey};
`;

const IconWrapper = styled.View`
  margin-right: 10px;
`;

const columnSizes = [2, 2, 2, 1, 1, 1];

const AdminUserOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.user.overview.name"), true, "firstName"),
    createHeaderItem(t("admin.user.overview.email"), true, "email"),
    createHeaderItem(t("admin.user.overview.company"), false),
    createHeaderItem(t("admin.user.overview.roles"), false),
    //createHeaderItem("", false),
    createHeaderItem("", false),
  ];

  const { userState } = useAuth();
  const client = useClient();

  type AdminUserItem = ArrayElement<
    ThenArg<ReturnType<typeof client.listAdminUsers>>["data"]["result"]["items"]
  >;

  const { debouncedSearchTerm, setDebouncedSearchTerm } = useSearch();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listAdminUsers>[0]>(tableHeaders);
  const userCompany = userState?.companyIds[0];

  const [data, setData] = useState<AdminUserItem[]>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [itemSelected, setItemSelected] = useState<AdminUserItem | undefined>(
    undefined
  );
  const [searchTerm, setSearchTerm] = useState("");

  const { impersonate } = useImpersonation();
  const history = useHistory();

  const isSuperAdmin =  userState?.roles?.includes(Roles.ROLE_ADMIN);
  const isCustomerAdmin =  userState?.roles?.includes(Roles.ROLE_CUSTOMER_ADMIN);

  const fetchListItems = useCallback(async () => {
    if (
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(
        client.listAdminUsers(
          sortField,
          isSuperAdmin ? undefined : userCompany,
          sortOrder,
          debouncedSearchTerm,
          takeSize,
          skipParam
        )
      );
    }
  }, [
    debouncedSearchTerm,
    searchTerm,
    skipParam,
    sortOrder,
    sortField,
    userCompany,
    isSuperAdmin,
    client,
    handleRequest,
  ]);

  useEffect(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setTableData(createTableData(items));
        setData(items);
        setTotalItems(total);
      }
    });
  }, [fetchListItems]);

  const impersonateUser = useCallback(
    async (id: number) => {
      //TEST here is the problem too
      const result = await handleRequest(
        client.loginAsAdminUser(id, REACT_APP_CLIENT_ID)
      );
      if (result) {
        const { refresh_token } = result.data.result;
        await impersonate(refresh_token);
      }
    },
    [impersonate]
  );

  const createTableData = useCallback(
    (items: AdminUserItem[]) => {
      const tableData: TableContent[] = items.map(
        ({
          firstName,
          infix,
          lastName,
          email,
          roles,
          id,
          companies,
          _operations,
        }) => {
          const fullName = infix
            ? `${firstName} ${infix} ${lastName}`
            : `${firstName} ${lastName}`;

          const copyEmail = ()=> Clipboard.setString(email);

          const data: ReactElement[] = [
            <SmallText
              key="fullName"
              onPress={() => history.push(`/admin/admin-user-overview/update/` + id) }
            >
              {fullName}
            </SmallText>,
            <ExtraSmallText
              onPress={(copyEmail)}
            >
              {email}
              <UploadIcon
                style={{ width: 14, height: 16, marginLeft: 5, top: 4 }}
                source={copyEmailIcon}
              />
            </ExtraSmallText>,
            <SmallText key="company">
              {companies.map((company) => company.name).join(", ")}
            </SmallText>,
            <SmallText key="role">
              {t(`admin.user.roles.${roles[0]}`)}
            </SmallText>,
            // <Link
            //      key="resetPassword"
            //      //onPress={() => resetPassWord(email)}
            //      style={{ color: color.secondary }}
            // >
            // <ExtraSmallText
            //   style={{ color: color.secondary }}
            // >Reset password</ExtraSmallText>
            // </Link>,

            isSuperAdmin ? (
              <Link
                key="impersonate"
                onPress={() => impersonateUser(id)}
                isActive
                style={{ color: color.secondary }}
              >
                <ExtraSmallText
                  style={{ color: color.secondary }}
                >
                  {t("admin.user.overview.impersonate")}
                </ExtraSmallText>
              </Link>
            ) : (
              <></>
            ),
          ];
          return {
            data,
            id: id.toString(),
            canDelete: _operations?.canDelete,
            cannotDeleteDescription: _operations?.cannotDeleteDescription,
            canEdit: true,
          };
        }
      );
      return tableData;
    },
    [isSuperAdmin, impersonateUser]
  );

  const getItemById = useCallback(
    (itemId: number) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: number) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteAdminUser(itemSelected!.id)
    );

    if (response && response.status === 204) {
      await fetchListItems().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items, total } = result.data.result;
        if (items) {
          setTableData(createTableData(items));
          setData(items);
          setTotalItems(total);
        }
      });

      setShowDeleteMessage(true);
    }
  }, [
    client,
    createTableData,
    fetchListItems,
    setData,
    itemSelected,
    setShowDeleteModal,
  ]);

  const updateSearch = (text: string) => {
    setSearchTerm(text);
    setDebouncedSearchTerm(text);
    setSkipParam(0);
  };

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>
            {t("admin.user.overview.adminUserTitle")}
          </Title>
          <OverviewHeaderInnerRight>
            <SearchInput
              wrapperStyle={`margin-right: ${spacing.medium};`}
              placeholder={t("general.filter.search")}
              onChangeText={updateSearch}
              value={searchTerm}
            />
            <Button
              onPress={() => history.push("/admin/admin-user-overview/create")}
            >
              {t("admin.user.create.addButton")}
            </Button>
          </OverviewHeaderInnerRight>
        </OverviewHeader>
        <PageSelector pages={getUserPages()} />
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        ) : null}
        {isLoading ? null : (
          <Table
            tableId="admin_user_overview"
            headerItems={headerItems}
            tableContent={tableData}
            columnSizes={columnSizes}
            sortList={onSortList}
            sortOrder={sortOrder}
            sortField={sortField}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/admin-user-overview/update/${id}`)
            }
            onDeleteItem={(id) => openDeleteModal(parseInt(id))}
          />
        )}
        {totalItems && !isLoading ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.user.delete.warning", {
          name: itemSelected?.firstName,
        })}
      />
    </>
  );
};

export default AdminUserOverview;
