import { SelectItemInterface } from "../Select";
import moment from "moment";

export const dayFormat = "DD";
export const monthFormat = "MM";
export const yearFormat = "YYYY";
export const dateFormat = "YYYY-MM-DD";
export const alternativeDateFormat = "DD/MM/YYYY";

const currentMonth = moment().format(monthFormat);
const currentYear = moment().format(yearFormat);
const monthYear = `${currentYear}-${currentMonth}`;

const createItems = (periods: string[]): SelectItemInterface[] => {
  return periods.map((period) => {
    return { label: period, value: period };
  });
};

export const getDaysOfMonth = (monthYear: string): SelectItemInterface[] => {
  const selectedDate = moment(monthYear, dateFormat);
  const daysInMonth = Array.from(
    { length: selectedDate.daysInMonth() },
    (x, i) => selectedDate.startOf("month").add(i, "days").format("DD")
  );
  return createItems(daysInMonth);
};

export const days: SelectItemInterface[] = getDaysOfMonth(monthYear);

export const months: SelectItemInterface[] = createItems([
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
]);

export const years: SelectItemInterface[] = createItems([
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
]);
