module["exports"] = [
  "Grass carp",
  "Peruvian anchoveta",
  "Silver carp",
  "Common carp",
  "Asari,",
  "Japanese littleneck,",
  "Filipino Venus,",
  "Japanese cockle,",
  "Alaska pollock",
  "Nile tilapia",
  "Whiteleg shrimp",
  "Bighead carp",
  "Skipjack tuna",
  "Catla",
  "Crucian carp",
  "Atlantic salmon",
  "Atlantic herring",
  "Chub mackerel",
  "Rohu",
  "Yellowfin tuna",
  "Japanese anchovy",
  "Largehead hairtail",
  "Atlantic cod",
  "European pilchard",
  "Capelin",
  "Jumbo flying squid",
  "Milkfish",
  "Atlantic mackerel",
  "Rainbow trout",
  "Araucanian herring",
  "Wuchang bream",
  "Gulf menhaden",
  "Indian oil sardine",
  "Black carp",
  "European anchovy",
  "Northern snakehead",
  "Pacific cod",
  "Pacific saury",
  "Pacific herring",
  "Bigeye tuna",
  "Chilean jack mackerel",
  "Yellow croaker",
  "Haddock",
  "Gazami crab",
  "Amur catfish",
  "Japanese common catfish",
  "European sprat",
  "Pink salmon",
  "Mrigal carp",
  "Channel catfish",
  "Blood cockle",
  "Blue whiting",
  "Hilsa shad",
  "Daggertooth pike conger",
  "California pilchard",
  "Cape horse mackerel",
  "Pacific anchoveta",
  "Japanese flying squid",
  "Pollock",
  "Chinese softshell turtle",
  "Kawakawa",
  "Indian mackerel",
  "Asian swamp eel",
  "Argentine hake",
  "Short mackerel",
  "Southern rough shrimp",
  "Southern African anchovy",
  "Pond loach",
  "Iridescent shark",
  "Mandarin fish",
  "Chinese perch",
  "Nile perch",
  "Round sardinella",
  "Japanese pilchard",
  "Bombay-duck",
  "Yellowhead catfish",
  "Korean bullhead",
  "Narrow-barred Spanish mackerel",
  "Albacore",
  "Madeiran sardinella",
  "Bonga shad",
  "Silver cyprinid",
  "Nile tilapia",
  "Longtail tuna",
  "Atlantic menhaden",
  "North Pacific hake",
  "Atlantic horse mackerel",
  "Japanese jack mackerel",
  "Pacific thread herring",
  "Bigeye scad",
  "Yellowstripe scad",
  "Chum salmon",
  "Blue swimming crab",
  "Pacific sand lance",
  "Pacific sandlance",
  "Goldstripe sardinella"
]