import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/native";
import {
  BillingType,
  CompanyWithoutId,
  InlineResponse400Errors,
  Language,
  Currency,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import DateSelector from "../../../components/DateSelector";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Select from "../../../components/Select";
import Toggle from "../../../components/Toggle";
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { t } from "../../../i18n";
import { useHistory } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { color, spacing } from "../../../styles/theme";
import { MediumTitle, SmallTitle } from "../../../styles/Title";
import { isValidEmail } from "../../../utils";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

export const today = moment().format("YYYY-MM-DD");

export const getLanguageItems = () => [
  {
    label: t(`admin.company.language.${Language.Nl}`),
    value: Language.Nl,
    isActive: true,
  },
  {
    label: t(`admin.company.language.${Language.En}`),
    value: Language.En,
  },
  {
    label: t(`admin.company.language.${Language.De}`),
    value: Language.De,
  },
];
export const getMetric = () => [
  {
    label: t(`admin.company.language.kilometer`),
    value: "Kilometer",
    isActive: true,
  },
  {
    label: t(`admin.company.language.mile`),
    value: "Mile",
  },
];
export const getCurrencies = () => [
  {
    label: t(`admin.company.language.euro`),
    value: "Euro",
    isActive: true,
  },
  {
    label: t(`admin.company.language.dollar`),
    value: "Dollar",
  },
  {
    label: t(`admin.company.language.pound`),
    value: "Pound",
  },
  {
    label: t(`admin.company.language.rand`),
    value: "Rand",
  },
];
export const getBillingItems = () => [
  {
    label: t(`admin.company.billingType.${BillingType.Month}`),
    value: BillingType.Month,
    isActive: true,
  },
  {
    label: t(`admin.company.billingType.${BillingType.Year}`),
    value: BillingType.Year,
  },
  {
    label: t(`admin.company.billingType.${BillingType.Enterprise}`),
    value: BillingType.Enterprise,
  },
  {
    label: t(`admin.company.billingType.${BillingType.Free}`),
    value: BillingType.Free,
  },
  {
    label: t(`admin.company.billingType.${BillingType.None}`),
    value: BillingType.None,
  },
  {
    label: t(`admin.company.billingType.${BillingType.ContactForm}`),
    value: BillingType.ContactForm,
  }
];

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [daysAdvance, setDaysAdvance] = useState("");
  const [forceTimeSlotsFeature, setForceTimeSlotsFeature] = useState(false);
  const [parkingSpotsAsResourceEnabled, setParkingSpotsAsResourceEnabled] = useState(false);
  const [hideCheckInTimeEnabled, setHideCheckInTimeEnabled] = useState(false);
  const [checkInFeature, setCheckInFeature] = useState(true);
  const [lunchFeature, setLunchFeature] = useState(true);
  const [visitorFeature, setVisitorFeature] = useState(true);
  const [homeEnabled, setHomeEnabled] = useState(true);
  const [
    visitorReservationConfirmEmailEnabled,
    setVisitorReservationConfirmEmailEnabled,
  ] = useState(true);
  const [facilitymanagementFeature, setFacilitymanagementFeature] =
    useState(true);
  const [reservationForUserEnabled, setReservationForUserEnabled] =
    useState(true);
  const [meetingFeature, setMeetingFeature] = useState(true);
  const [recurringReservationFeature, setRecurringReservationFeature] =
    useState(true);
  const [geofenceFeature, setGeofenceFeature] = useState(false);
  const [overlappingReservations, setOverlappingReservations] = useState(false);
  const [mapEnabled, setMapEnabled] = useState(true);
  const [presentpaneEnabled, setPresentpaneEnabled] = useState(true);
  const [multiSelectResourceEnabled, setMultiSelectResourceEnabled] = useState(true);
  const [microsoftSsoEnabled, setMicrosoftSsoEnabled] = useState(false);
  const [microsoftSyncEnabled, setMicrosoftSyncEnabled] = useState(false);
  const [languages] = useState(getLanguageItems());
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    Language.Nl
  );
  const [notes, setNotes] = useState("");
  const [provisioningDomain, setProvisioningDomain] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [pricePerCustomer, setPricePerCustomer] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [billingStartDate, setBillingStartDate] = useState(today);
  const [billingTypes] = useState(getBillingItems());
  const [selectedBillingType, setSelectedBillingType] =
    useState<BillingType | null>(BillingType.Month);

  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  const isFormValid = useCallback(() => {
    return (
      Boolean(name) &&
      Boolean(firstName) &&
      Boolean(lastName) &&
      Boolean(selectedLanguage) &&
      isValidEmail(email) &&
      Boolean(phoneNumber) &&
      Boolean(daysAdvance) &&
      Boolean(selectedBillingType) &&
      Boolean(pricePerCustomer) &&
      Boolean(billingStartDate)
    );
  }, [
    name,
    firstName,
    lastName,
    email,
    phoneNumber,
    daysAdvance,
    selectedBillingType,
    selectedLanguage,
    pricePerCustomer,
    billingStartDate,
  ]);

  const submitForm = useCallback(async () => {
    const billingDate = moment.utc(billingStartDate).toISOString();
    const company: CompanyWithoutId = {
      name,
      contactFirstname: firstName,
      contactLastname: lastName,
      contactEmail: email,
      contactPhone: phoneNumber,
      reservationWindowLength: parseInt(daysAdvance),
      lunchEnabled: Boolean(lunchFeature),
      visitorsEnabled: Boolean(visitorFeature),
      homeEnabled: Boolean(homeEnabled),
      visitorReservationConfirmEmailEnabled: Boolean(
        visitorReservationConfirmEmailEnabled
      ),
      facilitymanagementEnabled: Boolean(facilitymanagementFeature),
      reservationForUserEnabled: Boolean(reservationForUserEnabled),
      hideCheckInTimeEnabled: Boolean(hideCheckInTimeEnabled),
      meetingEnabled: Boolean(meetingFeature),
      checkInEnabled: Boolean(checkInFeature),
      forceTimeslotUse: Boolean(forceTimeSlotsFeature),
      parkingSpotsAsResourceEnabled: Boolean(parkingSpotsAsResourceEnabled),
      forceGeofence: Boolean(geofenceFeature),
      recurringEnabled: Boolean(recurringReservationFeature),
      overlappingUserReservationsEnabled: Boolean(overlappingReservations),
      mapEnabled: Boolean(mapEnabled),
      presentpaneEnabled: Boolean(presentpaneEnabled),
      multiSelectResourceEnabled: Boolean(multiSelectResourceEnabled),
      microsoftSsoEnabled: Boolean(microsoftSsoEnabled),
      microsoftSyncEnabled: Boolean(microsoftSyncEnabled),
      language: selectedLanguage,
      employeeCount: parseInt(employeeCount) || undefined,
      billingVoucherCode: voucherCode,
      billingType: selectedBillingType!,
      billingPricePerCustomer: parseFloat(pricePerCustomer),
      billingStartDate: billingDate,
      autoProvisioningDomain: provisioningDomain,
      notes,
    };
    try {
      const success = await handleRequest(client.addCompany(company));

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    handleRequest,
    client,
    name,
    firstName,
    lastName,
    email,
    phoneNumber,
    daysAdvance,
    lunchFeature,
    visitorFeature,
    homeEnabled,
    visitorReservationConfirmEmailEnabled,
    facilitymanagementFeature,
    hideCheckInTimeEnabled,
    reservationForUserEnabled,
    meetingFeature,
    checkInFeature,
    forceTimeSlotsFeature,
    parkingSpotsAsResourceEnabled,
    geofenceFeature,
    recurringReservationFeature,
    selectedBillingType,
    pricePerCustomer,
    billingStartDate,
    employeeCount,
    mapEnabled,
    overlappingReservations,
    presentpaneEnabled,
    multiSelectResourceEnabled,
    microsoftSsoEnabled,
    microsoftSyncEnabled,
    notes,
    selectedLanguage,
    voucherCode,
    history,
    provisioningDomain,
  ]);

  const updateDaysAdvance = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setDaysAdvance(days);
    },
    [setDaysAdvance]
  );

  const updateEmployeeCount = useCallback(
    (newValue) => {
      const count = newValue.replace(/[^0-9]/g, "");
      setEmployeeCount(count);
    },
    [setEmployeeCount]
  );

  const updatePricePerCustomer = useCallback(
    (newValue) => {
      const price = newValue.replace(/[^0-9.]/g, "");
      setPricePerCustomer(price);
    },
    [setPricePerCustomer]
  );

  const updateBillingType = useCallback(
    (newValue) => {
      setSelectedBillingType(newValue[0].value);
    },
    [setSelectedBillingType]
  );

  const updateLanguage = useCallback(
    (newValue) => {
      setSelectedLanguage(newValue[0].value);
    },
    [setSelectedLanguage]
  );

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.company.form.addTitle")}
          </MediumTitle>
          <LabeledInput>
            <SmallTitle hasMarginBottom>
              {t("admin.company.form.contactHeader")}
            </SmallTitle>
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.personName")} required>
            <Input
              onChangeText={setFirstName}
              value={firstName}
              placeholder={t("admin.company.form.personName") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.personLastname")} required>
            <Input
              onChangeText={setLastName}
              value={lastName}
              placeholder={t("admin.company.form.personLastname") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.email")} required>
            <Input
              onChangeText={setEmail}
              value={email}
              placeholder={t("admin.company.form.email") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.phone")} required>
            <Input
              onChangeText={setPhoneNumber}
              value={phoneNumber}
              placeholder={t("admin.company.form.phone") + " *"}
            />
          </LabeledInput>
          <LabeledInput>
            <SmallTitle hasMarginBottom>
              {t("admin.company.form.settingsHeader")}
            </SmallTitle>
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.company.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.days")} required>
            <Input
              onChangeText={updateDaysAdvance}
              value={daysAdvance}
              placeholder={t("admin.company.form.days") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.language")}
            required
            style={{ zIndex: 11 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={languages}
              onChange={updateLanguage}
              placeholder={t("admin.company.form.language")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.billingType")}
            required
            style={{ zIndex: 10 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={billingTypes}
              onChange={updateBillingType}
              placeholder={t("admin.company.form.billingType") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.employeeCount")}>
            <Input
              onChangeText={updateEmployeeCount}
              value={employeeCount}
              placeholder={t("admin.company.form.employeeCount")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.billingPricePerCustomer")}
            required
          >
            <Input
              onChangeText={updatePricePerCustomer}
              value={pricePerCustomer}
              placeholder={
                t("admin.company.form.billingPricePerCustomer") + " *"
              }
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.voucherCode")}>
            <Input
              onChangeText={setVoucherCode}
              value={voucherCode}
              placeholder={t("admin.company.form.voucherCode")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.billingStartDate")}
            required
            style={{ zIndex: 9 }}
          >
            <DateSelector
              date={billingStartDate}
              onSelect={setBillingStartDate}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.forceTimeslots")}
              onChange={setForceTimeSlotsFeature}
              toggleValue={forceTimeSlotsFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.parkingSpotsAsResources")}
              onChange={setParkingSpotsAsResourceEnabled}
              toggleValue={parkingSpotsAsResourceEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.hideCheckInTimeEnabled")}
              onChange={setHideCheckInTimeEnabled}
              toggleValue={hideCheckInTimeEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.checkIn")}
              onChange={setCheckInFeature}
              toggleValue={checkInFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.lunch")}
              onChange={setLunchFeature}
              toggleValue={lunchFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.visitors")}
              onChange={setVisitorFeature}
              toggleValue={visitorFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.home")}
              onChange={setHomeEnabled}
              toggleValue={homeEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t(
                "admin.company.form.visitorReservationConfirmEmailEnabled"
              )}
              onChange={setVisitorReservationConfirmEmailEnabled}
              toggleValue={visitorReservationConfirmEmailEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.facilitymanagement")}
              onChange={setFacilitymanagementFeature}
              toggleValue={facilitymanagementFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.reservationForUser")}
              onChange={setReservationForUserEnabled}
              toggleValue={reservationForUserEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.meeting")}
              onChange={setMeetingFeature}
              toggleValue={meetingFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.recurring")}
              onChange={setRecurringReservationFeature}
              toggleValue={recurringReservationFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.geofence")}
              onChange={setGeofenceFeature}
              toggleValue={geofenceFeature}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.overlappingReservations")}
              onChange={setOverlappingReservations}
              toggleValue={overlappingReservations}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.mapEnabled")}
              onChange={setMapEnabled}
              toggleValue={mapEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.presentpaneEnabled")}
              onChange={setPresentpaneEnabled}
              toggleValue={presentpaneEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.multiSelectResourceEnabledParking")}
              onChange={setMultiSelectResourceEnabled}
              toggleValue={multiSelectResourceEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.microsoftSsoEnabled")}
              onChange={setMicrosoftSsoEnabled}
              toggleValue={microsoftSsoEnabled}
            />
          </LabeledInput>
          <LabeledInput>
            <Toggle
              label={t("admin.company.form.microsoftSyncEnabled")}
              onChange={setMicrosoftSyncEnabled}
              toggleValue={microsoftSyncEnabled}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.provisioningDomain")}>
            <Input
              onChangeText={setProvisioningDomain}
              value={provisioningDomain}
              placeholder={t("admin.company.form.provisioningDomain")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.notes")}>
            <Input
              onChangeText={setNotes}
              value={notes}
              placeholder={t("admin.company.form.notes")}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.company.form.createSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
