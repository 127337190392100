module["exports"] = [
  "Capalna",
  "Gheorghe Duca",
  "Acvila",
  "Lisabona",
  "Campulung",
  "Ilie Gurita",
  "Succesului",
  "Siret",
  "Mihai Viteazul",
  "Complexului",
  "Chihlimbarului",
  "Prahova",
  "George Cosbuc",
  "Bobalna",
  "Eroina De La Jiu",
  "Cucuzel Ion",
  "Sergent Turturica",
  "Timisul De Sus",
  "Tuberozelor",
  "Rulmentului",
  "Soldat Polosca Tanase",
  "Nabucului",
  "Flautului",
  "Poiana Codrului",
  "Daia",
  "Soldat Dima Dumitru",
  "Bulandra Tony",
  "Pastravilor",
  "Eternitatii",
  "Tufanilor",
  "Discului",
  "Garnitei",
  "Costaforu",
  "Aerogarii",
  "Preluca",
  "Delureni",
  "Soldat Velicu Stefan",
  "Ocalei",
  "Calboranu George",
  "Cercelus",
  "Timocului",
  "Alexandru Locusteanu",
  "Pumnul Aron",
  "Migdalului",
  "Valului",
  "Inginer Radu Elie",
  "Cricovului",
  "Mavrogheni",
  "Bauxitei",
  "Movilitei",
  "Pictor Hentia Sava",
  "Abus",
  "Fieni",
  "Zambetului",
  "Izvorul Muresului",
  "Rolei",
  "Utiesului",
  "Gheorghe Popescu",
  "Radulescu Drumea",
  "Sacele",
  "Soimarestilor",
  "Romeo",
  "Voicesti",
  "Carada Eugeniu",
  "Barometrului",
  "Tudorache Gheorghe",
  "Morii",
  "Jules Michelet",
  "Nicolae G. Caranfil",
  "Sergent Anghel Gheorghe",
  "Reconstructiei",
  "Vespasian",
  "Tincani",
  "Stirbei Voda",
  "Ariesu Mare",
  "Penelului",
  "Piscul Vechi",
  "Natiunile Unite",
  "Doctor Tanasescu Ion",
  "Stefan Greceanu",
  "Banul Scarlat",
  "Crinului",
  "Domnita Florica",
  "Plutonier Nita Ion",
  "Soldat Ionita Stere",
  "Rovine",
  "Tropicelor",
  "Matei Millo",
  "Veronica Micle",
  "Pictor Iser Iosif",
  "Mitropolit Nifon",
  "Locotenent Victor Manu",
  "Studioului",
  "Piatra Morii",
  "Valea Lupului",
  "Episcop Chesarie",
  "Sergent Major Drag Marin",
  "Bega",
  "Dobrun",
  "Fulgeresti"
];