module["exports"] = [
  "Livros",
  "Filmes",
  "Música",
  "Jogos",
  "Eletrônicos",
  "Computadores",
  "Casa",
  "Jardim",
  "Ferramentas",
  "Mercearia",
  "Saúde",
  "Beleza",
  "Brinquedos",
  "Crianças",
  "Bebê",
  "Roupas",
  "Sapatos",
  "Jóias",
  "Esportes",
  "Turismo",
  "Automotivo",
  "Industrial"
];
