module["exports"] = [
  "North",
  "East",
  "South",
  "West",
  "Northeast",
  "Northwest",
  "Southeast",
  "Southwest"
];
