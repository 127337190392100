module["exports"] = [
  "انطباقی",
  "پیشرفته",
  "بهبود یافته",
  "جذب شده",
  "خودکار",
  "متعادل",
  "متمرکز بر تجارت",
  "متمرکز",
  "کلون شده",
  "سازگار",
  "قابل تنظیم",
  "گروه متقابل",
  "متمرکز بر مشتری",
  "قابل تنظیم",
  "غیر متمرکز",
  "مهندسی شده",
  "دیجیتالی شده",
  "توزیع شده",
  "گوناگون",
  "کوچک شده",
  "پیشرفته",
  "ارگونومیک",
  "انحصاری",
  "منبسط",
  "تمدید شده",
  "رو در رو",
  "متمرکز شده",
  "خط مقدم",
  "کاملاً قابل تنظیم",
  "مبتنی بر عملکرد",
  "اساسی",
  "ضد آینده",
  "افقی",
  "پیاده سازی شده",
  "خلاقانه",
  "یکپارچه",
  "شهودی",
  "معکوس",
  "اجباری",
  "نظارت شده",
  "چند کاناله",
  "چند جانبی",
  "متن باز",
  "عملیاتی",
  "بهینه شده",
  "اختیاری",
  "ارگانیک",
  "سازمان یافته",
  "پشتکار",
  "مداوم",
  "درجه",
  "قطبی",
  "فعال",
  "متمرکز بر سود",
  "ژرف",
  "قابل برنامه ریزی",
  "ترقی خواه",
  "متمرکز بر کیفیت",
  "واکنش گرا",
  "تصحیح شده",
  "رکود",
  "مهندسی معکوس",
  "قدرتمند",
  "بدون درز",
  "امن",
  "خود توانمندی",
  "همه کاره",
  "مجازی",
  "بینایی",
];
