export const spacing = {
  extraSmall: "4px",
  small: "8px",
  tiny: "12px",
  medium: "16px",
  large: "32px",
  larger: "40px",
  extraLarge: "100px",
};

export const font = {
  defaultLight: "Poppins_300Light",
  defaultRegular: "Poppins_400Regular",
  defaultMedium: "Poppins_500Medium",
  defaultSemiBold: "Poppins_600SemiBold",
  defaultBold: "Poppins_700Bold",
};

export const color = {
  primary: "#ED8C8C",
  primaryLight: "#FCEEE8",
  secondary: "#40A2B2",
  secondaryLight: "#EBF5F7",
  tertiary: "#FFE4B1",
  tertiaryDark: "#BF8B2C",
  tertiaryLight: "#FBF0DB",
  quaternary: "#6194E0",
  quaternaryLight: "#E8EFFB",
  quinary: "#3EB8A0",
  quinaryLight: "#E2F5F1",
  senaryLight: "#F5F8FD",
  red: 'red',
  black: "#000000",
  dawnPink: "#E8E7E9",
  bmdPurple: "#7A79B1",
  bmdPurpleLight: "#DADAE8",
  bmdPurleDark: "#525098",
  bmdRed: "#ED4949",
  bmdRedLight: "#F7D6D6",
  bmdRedDark: "#DC3232",

  dark: "#2B233B",
  darker: "#140C25",
  white: "white",
  whiteLight: "#F8F7F7",
  greyLightest: "#EFEFEF",
  greyLighter: "#F5F5F4",
  greyLight: "#E0E0E0",
  greyChateau: "#A0A5BC",
  greySelected: "#B9B9B9",
  grey: "#999",
  alto: "#DCDCDC",

  leaderboard010: "#9895FF",
  leaderboard1125: "#40A2B2",
  leaderboard2650: "#3EB8A0",
  leaderboard5180: "#6194E0",
  leaderboard8194: "#EDB349",
  leaderboard95100: "#ED8C8C",

  transparent: "transparant",
  background: "#F7F9FD",
  transparentBackground: "rgba(0, 0, 0, 0.2)",

  bmdBlue: "#6194E0",

  reservationHome: "#575690",
  reservationVisitor: "#7A79B1",
  reservationOther: "#FF9552",
  activeTable: "#DFEAF9",
  hoverTable: "#F0F0F0",

  trialYellow: "#FFE4B1",
  trialOrange: "#FFCCB1",
  trialDarkOrange: "#FFB1B1",
  trialOnHold: "#CBDBF2",
  subscriptionRunning: "#6194E0",
  subscriptionRunningEnterprise: "#7A79B1",
};

export const text = {
  tiny: {
    size: "10px",
    lineHeight: "22px",
  },
  extraSmall: {
    size: "13px",
    lineHeight: "36px",
  },
  extraSmallGood: {
    size: "13px",
    lineHeight: "18px",
  },
  small: {
    size: "14px",
    lineHeight: "20px",
  },
  medium: {
    size: "16px",
    lineHeight: "36px",
  },
  large: {
    size: "20px",
  },
  larger: {
    size: "26px",
  },


  big: {
    size: "30px",
  },
  extraLarge: {
    size: "36px",
  },
};

export const borderRadius = {
  small: "5px",
  tiny: "8px",
  medium: "10px",
  large: "15px",
  extraLarge: "20px",
  full: "9999px",
};

export const borderWidth = {
  small: "1px",
  medium: "2px",
};

export const boxShadow = {
  small: `
      shadow-radius: 4px;
      shadow-offset: 0 2px;
      shadow-color: black;
      shadow-opacity: .15;
    `,
  medium: `
      shadow-radius: 4px;
      shadow-offset: 0 2px;
      shadow-color: black;
      shadow-opacity: .5;
    `,
  extreme: `
    shadow-radius: 6px;
    shadow-offset: 0 4px;
    shadow-color: black;
    shadow-opacity: .2;
  `,

};

export const breakpoint = {
  mobile: 992,
  tablet: 1024,
  desktop: 1200,
};

export const nav = {
  avatarSize: "46px",
  logoWidth: "208px",
  logoHeight: "46px",
};

export const iconSize = {
  extraSmall: `
  width: 13px;
  height: 13px;
`,
  small: `
  width: 18px;
  height: 18px;
`,
  medium: `
  width: 24px;
  height: 24px;
`,
  large: `
  width: 39px;
  height: 39px;
`,
  extraLarge: `
  width: 65px;
  height: 65px;
`,
};

export const button = {
  paddingSmall: "14px",
  padding: "28px",
  height: "46px",
};

export const badge = {
  extraSmall: {
    size: "32px",
  },
  small: {
    size: "36px",
  },
  medium: {
    size: "40px",
  },
};

export const dot = {
  small: {
    size: "8px",
  },
};

export const chevron = {
  width: "10px",
  height: "6px",
};

export const input = {
  height: "46px",
};

export const cursor = {
  pointer: "pointer",
}

export const TextAlign = {
  center: "center",
  left: "left",
  right: "right",
}

export const flex = {
  justifyContent: {
    center: "center",
    spaceBetween: "space-between",
    flexEnd: "flex-end",
  },
  direction: {
    row: "row",
    column: "column",
  }
}

export const padding = {
  small: "10px",
}

export const display = {
  none: "none",
  block: "block",
  inline: "inline",
  flex: "flex",
}


export const whiteSpace = {
  nowrap: "nowrap",
}

export const margin = {
  small: "10px",
  medium: "20px",
  large: "30px",
}

export const opacity = {
  default: "1",
  disabled: "0.6",
}

export enum Position {
  fixed = "fixed",
  absolute = "absolute",
  relative = "relative",
  sticky = "sticky"
}

export enum Overflow {
  scroll = "scroll",
  auto = "auto",
}
