import React, { FC, useState, useEffect } from "react";
import styled from "styled-components/native";
import { spacing } from "../../styles/theme";
import Link from "../Link";
import { SmallText } from "../../styles/Text";
import PaginatorLink from "./paginatorLink";
import { t } from "../../i18n";
import Input from "../../styles/Input";

export const takeSize = 20;
export const pageOption = [20, 50, 100, 200];
const maxVisiblePages = 5;

const LinkWrapper = styled.View`
  justify-content: center;
  flex-direction: row;
`;

const TotalWrapper = styled.View`
  position: absolute;
  left: ${spacing.medium};
`;

const PaginatorWrapper = styled.View`
  justify-content: center;
  flex-direction: row;
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.medium};
`;

enum PaginatorType {
  FIRST,
  PREVIOUS,
  NEXT,
  LAST,
  PAGE
}

interface PaginatorInterface {
  skipParam: number;
  totalItems: number;
  setSkipParam(newValue: number): void;
}

const Paginator: FC<PaginatorInterface> = ({
  skipParam,
  totalItems,
  setSkipParam,
}) => {
  const [pages, setPages] = useState<Array<number>>([]);

  const isPreviousDisabled = (): boolean => {
    return pages.findIndex((page) => page === skipParam / takeSize) === 0;
  };

  const isNextDisabled = () => {
    return (
      pages.findIndex((page) => page === skipParam / takeSize) ===
      pages.length - 1
    );
  };

  const lastPage = Math.floor(totalItems / 10) * 10;
  const currentPageEntry = (skipParam / takeSize) + 1;

  const calculateSkipParam = (action: PaginatorType, value = takeSize) => {
    switch (action) {
      case PaginatorType.FIRST: {
        setSkipParam(0);
        break;
      }
      case PaginatorType.PREVIOUS: {
        setSkipParam(skipParam - value);
        break;
      }
      case PaginatorType.NEXT: {
        setSkipParam(skipParam + value);
        break;
      }
      case PaginatorType.PAGE: {
        setSkipParam(value * takeSize);
        break;
      }
      case PaginatorType.LAST: {
        setSkipParam(lastPage);
        break;
      }
      case PaginatorType.ENTRY: {
        setSkipParam((setCurrentPage + 1) * 20);
        break;
      }
    }
  };

  const createPages = (): Array<number> => {
    const numberOfPages = Math.ceil(totalItems / takeSize);
    const pages = Array.from({ length: numberOfPages }, (_, i) => i);
    if (pages.length > maxVisiblePages) {
      return getPagesSubset(pages);
    } else {
      return pages;
    }
  };

  const getPagesSubset = (pages: Array<number>) => {
    const maxIndex = 3;
    const activePage = skipParam / takeSize;
    const activePageIndex = pages.indexOf(activePage);
    if (activePageIndex < maxIndex) {
      return pages.slice(0, maxVisiblePages);
    } else if (pages.length - activePageIndex < maxIndex) {
      return pages.slice(-maxVisiblePages);
    } else {
      return pages.slice(activePageIndex - 2, activePageIndex + 3);
    }
  };

  useEffect(() => {
    if (totalItems < skipParam) {
      return calculateSkipParam(PaginatorType.PAGE, Math.floor(totalItems / takeSize));
    }
    setPages(createPages());
  }, [totalItems, skipParam]);

  return (
    <PaginatorWrapper>
      <TotalWrapper>
        <SmallText>
          {t("admin.pagination.totalItems", { count: totalItems })}
        </SmallText>
      </TotalWrapper>
      {totalItems > takeSize && (
        <LinkWrapper>
          <Link
            hasPadding
            disabled={isPreviousDisabled()}
            onPress={() => calculateSkipParam(PaginatorType.FIRST)}
          >
            {t("general.firstPage")}
          </Link>
          <Link
            hasPadding
            disabled={isPreviousDisabled()}
            onPress={() => calculateSkipParam(PaginatorType.PREVIOUS)}
          >
            {t("general.previous")}
          </Link>
          {pages.map((el, index) => (
            <PaginatorLink
              key={index}
              isActive={el === skipParam / takeSize}
              onPress={() => calculateSkipParam(PaginatorType.PAGE, el)}
            >
              {el + 1}
            </PaginatorLink>
          ))}
          <Link
            hasPadding
            disabled={isNextDisabled()}
            onPress={() => calculateSkipParam(PaginatorType.NEXT)}
          >
            {t("general.next")}
          </Link>
          <Link
            hasPadding
            disabled={isNextDisabled()}
            //isActive={lastPage}
            onPress={() => calculateSkipParam(PaginatorType.LAST)}
          >
            {t("general.lastPage")}
          </Link>
        </LinkWrapper>
      )}
    </PaginatorWrapper>
  );
};

export default Paginator;
