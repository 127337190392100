import React, { FC, useCallback, useRef, useState } from "react";
import styled from "styled-components/native";
import { ExtraSmallText } from "../../styles/Text";
import { color, spacing } from "../../styles/theme";
import { useFocus, useHover } from "react-native-web-hooks";
import { TextProps, TouchableOpacity } from "react-native";
import { css } from "styled-components";

interface WrapperProps extends TextProps {
  isHovering: boolean;
  isActive?: boolean;
}

const Wrapper = styled(ExtraSmallText)<WrapperProps>`
  padding: ${spacing.small} ${spacing.medium};
  display: flex;
  align-items: center;

  ${({ isHovering }) =>
    isHovering &&
    css`
      background-color: ${color.greyLighter};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${color.secondary};
    `}
`;

export interface OnChangeProps {
  isActive: boolean;
  index: number;
}

interface SelectItemTypes {
  isActive?: boolean;
  index: number;
  onChange({ isActive, index }: OnChangeProps): void;
}

const SelectItem: FC<SelectItemTypes> = ({
  children,
  index,
  isActive: defaultActive,
  onChange,
}) => {
  const element = useRef(null);
  const isHovering = useHover(element);
  const hasFocus = useFocus(element);

  const [isActive, setIsActive] = useState(defaultActive);

  const toggleItem = useCallback(() => {
    const newValue = !isActive;
    setIsActive(newValue);
    onChange({
      isActive: newValue,
      index,
    });
  }, [isActive, setIsActive, onChange, index]);

  return (
    <TouchableOpacity onPress={toggleItem}>
      <Wrapper
        ref={element}
        isHovering={isHovering || hasFocus}
        isActive={isActive}
      >
        {children}
      </Wrapper>
    </TouchableOpacity>
  );
};

export default SelectItem;
