import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { OrderDirection } from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button, {
  ButtonsWrapper,
  ButtonWrapper,
} from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import SearchInput from "../../../components/SearchInput";
import LoadOverlay from "../../../components/LoadOverlay";
import { getOfficePages } from "../../../components/Navbar/AdminNavBarContent";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import LabeledInput from '../../../components/LabeledInput';
import Input from '../../../styles/Input';
import Toggle from "../../../components/Toggle";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSearch } from "../../../hooks/useSearch";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { OverviewHeader, OverviewHeaderInnerRight } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import { color, spacing } from "../../../styles/theme";
import Title from "../../../styles/Title";
import { ArrayElement, ThenArg } from "../../../types";

const columnSizes = [3, 3];

const LocationOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.barcodes.name"), false),
    createHeaderItem(t("admin.barcodes.code"), false),
  ];
  const { userState } = useAuth();
  const client = useClient();
  const history = useHistory();
  const { debouncedSearchTerm, setDebouncedSearchTerm } = useSearch();

  type BarcodeItem = ArrayElement<
    ThenArg<ReturnType<typeof client.listSeats>>["data"]["result"]["items"]
  >;

  const userCompany = userState?.companyIds[0];
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const [data, setData] = useState<BarcodeItem[]>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<BarcodeItem>();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<UserFilter>({});

  const fetchListItems = useCallback(async () => {
    if (
      userCompany &&
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(
        client.listSeats(
          userCompany,
          "315cee94-e063-4220-b8d9-8d2c7d16a3af",
          filter,
          debouncedSearchTerm,
          takeSize,
          skipParam
        )
      );
    }
  }, [
    userCompany,
    debouncedSearchTerm,
    searchTerm,
    handleRequest,
    client,
    filter,
    skipParam
  ]);

  const updateSearch = (text: string) => {
    setSearchTerm(text);
    setDebouncedSearchTerm(text);
    setSkipParam(0);
  };

  const saveBarcode = useCallback(
    (newValue) => {
      console.log(newValue);
    },
  );

  const initialize = useCallback(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setData(items || []);
        setTotalItems(total);
      }
    });
  }, [fetchListItems]);

  const fetch = useCallback(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setTableData(createTableData(items));
        setData(items);
        setTotalItems(total);
      }
    });
  }, [fetchListItems]);

  const createTableData = useCallback(
    (items: BarcodeItem[]) => {

      const tableData: TableContent[] = items.map(
        ({ name, barcodes, id, _operations }, index, currentArray) => {
          const data: ReactElement[] = [
            <ExtraSmallText key="name" mediumWeight>
              {name}
            </ExtraSmallText>,
            <ExtraSmallText key="barcodes" mediumWeight>
              <LabeledInput noMargin>
                <Input
                  maxLength={4}
                  onChangeText={saveBarcode}
                  value={barcodes.map((group) => group.code)}
                  //placeholder={t("admin.barcodes.inputCode")}
                />
              </LabeledInput>
            </ExtraSmallText>,
          ];
          return {
            data,
            id,
            canDelete: false,
            canEdit: false,
            hideDeleteButton: true,
            hideEditButton: true,
          };
        }
      );
      return tableData;
    },
    [skipParam, totalItems]
  );

  useEffect(() => {
    setTableData(createTableData(data));
  }, [createTableData, data]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );
  // <OverviewHeaderInnerRight>
  //   <SearchInput
  //     placeholder={t("general.filter.search")}
  //     onChangeText={updateSearch}
  //     value={searchTerm}
  //   />
  // </OverviewHeaderInnerRight>
  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.barcodes.title")}</Title>

        </OverviewHeader>
        <PageSelector pages={getOfficePages()} />
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
            {'\n'}
            {error}
          </Notification>
        )}
        {isLoading ? null : (
          <Table
            tableId="location_overview"
            headerItems={tableHeaders}
            tableContent={tableData}
            columnSizes={columnSizes}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/location-overview/update/${id}`)
            }
          />
        )}
        {totalItems && !isLoading ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>
    </>
  );
};

export default LocationOverview;
