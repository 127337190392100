import React, { FC, ReactText } from 'react';
import { View } from 'react-native';
import styled from "styled-components/native";
import { color, spacing } from "../../../../styles/theme";
import Text, { ExtraSmallText } from '../../../../styles/Text';
import { t } from "../../../../i18n";
import HeaderCard from '../../../../components/HeaderCard';
import InnerCard from '../../../../styles/Card/InnerCard';
import PickerInput from '../../../../components/PickerInput';
import Toggle from '../../../../components/Toggle';
import officeChairIcon from '../../../../assets/icons/office_chair.svg';
import meetingRoomIcon from '../../../../assets/icons/meeting_room.svg';
import lunchIcon from '../../../../assets/icons/ic_reservation_lunch.svg';
import userPlusIcon from '../../../../assets/icons/userPlus.svg';
import { ExpirationItems } from '../contants';

const HeaderCardTitleSuffixWrapper = styled.View`
  margin-left: auto;
  margin-right: -${spacing.medium};
`;

const SeatSettingRow = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const PickerInputWrapper = styled(View)`
  width: 45%;
  margin-right: ${spacing.medium};
`;

interface ModuleCardProps {
  checkInFeature: boolean;
  visitorFeature: boolean;
  meetingFeature: boolean;
  lunchAutoCheckoutTime: string;
  deskExpirationTime: string;
  deskAutoCheckoutTime: string;
  meetingExpirationTime: string;
  meetingAutoCheckoutTime: string;
  lunchFeature: boolean;
  lunchExpirationTime: string;
  onDeskExpirationTimeChange: (value: ReactText) => void;
  onDeskAutoCheckoutTimeChange: (value: ReactText) => void;
  onMeetingFeatureChange: (value: boolean) => void;
  onMeetingExpirationTimeChange: (value: ReactText) => void;
  onMeetingAutoCheckoutTimeChange: (value: ReactText) => void;
  onLunchFeatureChange: (value: boolean) => void;
  onLunchExpirationTimeChange: (value: ReactText) => void;
  onLunchAutoCheckoutTimeChange: (value: ReactText) => void;
  onVisitorFeatureChange: (value: boolean) => void;
}

const ModuleCard: FC<ModuleCardProps> = ({
  checkInFeature,
  meetingFeature,
  lunchAutoCheckoutTime,
  deskExpirationTime,
  deskAutoCheckoutTime,
  meetingExpirationTime,
  meetingAutoCheckoutTime,
  lunchFeature,
  lunchExpirationTime,
  visitorFeature,
  onDeskExpirationTimeChange,
  onDeskAutoCheckoutTimeChange,
  onMeetingFeatureChange,
  onMeetingExpirationTimeChange,
  onMeetingAutoCheckoutTimeChange,
  onLunchFeatureChange,
  onLunchExpirationTimeChange,
  onLunchAutoCheckoutTimeChange,
  onVisitorFeatureChange,
}) => {
  // TODO: need to implement func for toggling
  return (
    <>
      <HeaderCard
        icon={officeChairIcon}
        title={t("admin.company.form.deskTitle")}
        badgeColor={color.quinaryLight}
        disableHorizontalDivider={!checkInFeature}
        isAccordion
        titleSuffix={
          <HeaderCardTitleSuffixWrapper>
            <Toggle toggleValue />
          </HeaderCardTitleSuffixWrapper>
        }
      >
        {checkInFeature && (
          <>
            <InnerCard hasMarginBottom>
              <Text mediumWeight hasMarginBottom>
                {t("admin.company.form.reservationsExpire")}
              </Text>
              <SeatSettingRow>
                <PickerInputWrapper>
                  <PickerInput
                    noMarginBottom
                    selectedValue={deskExpirationTime}
                    items={[
                      {
                        label: t("general.not"),
                        value: "",
                      },
                      ...ExpirationItems,
                    ]}
                    onValueChange={onDeskExpirationTimeChange}
                  />
                </PickerInputWrapper>
                <ExtraSmallText>
                  {t("admin.company.form.afterStartTime")}
                </ExtraSmallText>
              </SeatSettingRow>
            </InnerCard>
            <InnerCard>
              <Text mediumWeight hasMarginBottom>
                {t("admin.company.form.autoCheckOut")}
              </Text>
              <SeatSettingRow>
                <PickerInputWrapper>
                  <PickerInput
                    noMarginBottom
                    selectedValue={deskAutoCheckoutTime}
                    items={ExpirationItems}
                    onValueChange={onDeskAutoCheckoutTimeChange}
                  />
                </PickerInputWrapper>
                <ExtraSmallText>
                  {t("admin.company.form.afterEndTime")}
                </ExtraSmallText>
              </SeatSettingRow>
            </InnerCard>
          </>
        )}
      </HeaderCard>
      <HeaderCard
        icon={meetingRoomIcon}
        title={t("admin.company.form.meetingTitle")}
        badgeColor={color.secondaryLight}
        disableHorizontalDivider={!meetingFeature || !checkInFeature}
        titleSuffix={
          <HeaderCardTitleSuffixWrapper>
            <Toggle
              onChange={onMeetingFeatureChange}
              toggleValue={meetingFeature}
            />
          </HeaderCardTitleSuffixWrapper>
        }
        isAccordion
      >
        {checkInFeature && meetingFeature && (
          <>
            <InnerCard hasMarginBottom>
              <Text mediumWeight hasMarginBottom>
                {t("admin.company.form.reservationsExpire")}
              </Text>
              <SeatSettingRow>
                <PickerInputWrapper>
                  <PickerInput
                    noMarginBottom
                    selectedValue={meetingExpirationTime}
                    items={[
                      {
                        label: t("general.not"),
                        value: "",
                      },
                      ...ExpirationItems,
                    ]}
                    onValueChange={onMeetingExpirationTimeChange}
                  />
                </PickerInputWrapper>
                <ExtraSmallText>
                  {t("admin.company.form.afterStartTime")}
                </ExtraSmallText>
              </SeatSettingRow>
            </InnerCard>
            <InnerCard>
              <Text mediumWeight hasMarginBottom>
                {t("admin.company.form.autoCheckOut")}
              </Text>
              <SeatSettingRow>
                <PickerInputWrapper>
                  <PickerInput
                    noMarginBottom
                    selectedValue={meetingAutoCheckoutTime}
                    items={ExpirationItems}
                    onValueChange={onMeetingAutoCheckoutTimeChange}
                  />
                </PickerInputWrapper>
                <ExtraSmallText>
                  {t("admin.company.form.afterEndTime")}
                </ExtraSmallText>
              </SeatSettingRow>
            </InnerCard>
          </>
        )}
      </HeaderCard>
      <HeaderCard
        icon={lunchIcon}
        title={t("admin.company.form.lunchTitle")}
        badgeColor={color.tertiaryLight}
        disableHorizontalDivider={!lunchFeature || !checkInFeature}
        titleSuffix={
          <HeaderCardTitleSuffixWrapper>
            <Toggle
              onChange={onLunchFeatureChange}
              toggleValue={lunchFeature}
            />
          </HeaderCardTitleSuffixWrapper>
        }
        isAccordion
      >
        {lunchFeature && checkInFeature && (
          <>
            <InnerCard hasMarginBottom>
              <Text mediumWeight hasMarginBottom>
                {t("admin.company.form.reservationsExpire")}
              </Text>
              <SeatSettingRow>
                <PickerInputWrapper>
                  <PickerInput
                    noMarginBottom
                    selectedValue={lunchExpirationTime}
                    items={[
                      {
                        label: t("general.not"),
                        value: "",
                      },
                      ...ExpirationItems,
                    ]}
                    onValueChange={onLunchExpirationTimeChange}
                  />
                </PickerInputWrapper>
                <ExtraSmallText>
                  {t("admin.company.form.afterStartTime")}
                </ExtraSmallText>
              </SeatSettingRow>
            </InnerCard>
            <InnerCard>
              <Text mediumWeight hasMarginBottom>
                {t("admin.company.form.autoCheckOut")}
              </Text>
              <SeatSettingRow>
                <PickerInputWrapper>
                  <PickerInput
                    noMarginBottom
                    selectedValue={lunchAutoCheckoutTime}
                    items={ExpirationItems}
                    onValueChange={onLunchAutoCheckoutTimeChange}
                  />
                </PickerInputWrapper>
                <ExtraSmallText>
                  {t("admin.company.form.afterEndTime")}
                </ExtraSmallText>
              </SeatSettingRow>
            </InnerCard>
          </>
        )}
      </HeaderCard>
      <HeaderCard
        icon={userPlusIcon}
        title={t("admin.company.form.visitorTitle")}
        badgeColor={color.transparentBackground}
        titleSuffix={
          <HeaderCardTitleSuffixWrapper>
            <Toggle
              onChange={onVisitorFeatureChange}
              toggleValue={visitorFeature}
            />
          </HeaderCardTitleSuffixWrapper>
        }
      >
      </HeaderCard>
    </>
  );
};

export default ModuleCard;
