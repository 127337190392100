module["exports"] = [
  "Adriana",
  "Alexandra",
  "Alice",
  "Amélia",
  "Ana",
  "Ariana",
  "Áurea",
  "Aurora",
  "Bárbara",
  "Beatriz",
  "Benedita",
  "Bruna",
  "Caetana",
  "Camila",
  "Carla",
  "Carlota",
  "Carminho",
  "Carmo",
  "Carolina",
  "Catarina",
  "Cecília",
  "Célia",
  "Clara",
  "Constança",
  "Daniela",
  "Débora",
  "Diana",
  "Eduarda",
  "Elisa",
  "Ema",
  "Emília",
  "Érica",
  "Eva",
  "Fabiana",
  "Filipa",
  "Flor",
  "Francisca",
  "Frederica",
  "Gabriela",
  "Helena",
  "Inês",
  "Irina",
  "Íris",
  "Isabel",
  "Jéssica",
  "Joana",
  "Júlia",
  "Juliana",
  "Julieta",
  "Lara",
  "Laura",
  "Leonor",
  "Letícia",
  "Lia",
  "Lorena",
  "Luana",
  "Luena",
  "Luísa",
  "Luna",
  "Madalena",
  "Mafalda",
  "Mara",
  "Márcia",
  "Margarida",
  "Maria",
  "Mariana",
  "Marta",
  "Matilde",
  "Melissa",
  "Mia",
  "Miriam",
  "Natália",
  "Nicole",
  "Núria",
  "Ofélia",
  "Olívia",
  "Paula",
  "Pilar",
  "Rafaela",
  "Raquel",
  "Rita",
  "Rosa",
  "Safira",
  "Sara",
  "Sílvia",
  "Sofia",
  "Soraia",
  "Tatiana",
  "Teresa",
  "Valentina",
  "Vânia",
  "Vera",
  "Vitória"
];
