module["exports"] = [
  "6304###########L",
  "6706###########L",
  "6771###########L",
  "6709###########L",
  "6304#########{5,6}L",
  "6706#########{5,6}L",
  "6771#########{5,6}L",
  "6709#########{5,6}L"
];
