module["exports"] = [
  "Argovie",
  "Appenzell Rhodes-Intérieures",
  "Appenzell Rhodes-Extérieures",
  "Bâle-Ville",
  "Bâle-Campagne",
  "Berne",
  "Fribourg",
  "Genève",
  "Glaris",
  "Grisons",
  "Jura",
  "Lucerne",
  "Neuchâtel",
  "Nidwald",
  "Obwald",
  "Schaffhouse",
  "Schwyt",
  "Soleure",
  "Saint-Gall",
  "Thurgovie",
  "Tessin",
  "Uri",
  "Valai",
  "Vaud",
  "Zoug",
  "Zurich"
];
