import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "../../../routing";

import {
  UserWithoutId,
  UserGroupLink,
  Language,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";
import { t } from "../../../i18n";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import Checkbox from "../../../components/Checkbox";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select, { SelectItemInterface } from "../../../components/Select";
import { getLanguageItems } from "../CompanyOverview/Create";

import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import { SmallTitle, MediumTitle } from "../../../styles/Title";
import Input from "../../../styles/Input";

import { useAuth } from "../../../context/authContext";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";

import { isValidEmail } from "../../../utils";
import InputValidationErrors from "../../../components/InputValidationErrors";
import "../../../styles/Title/title";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const [firstname, setFirstname] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [languages] = useState<SelectItemInterface[]>(getLanguageItems());
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    Language.Nl
  );
  const [userGroups, setUserGroups] = useState<SelectItemInterface[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroupLink[]>(
    []
  );
  const [sentInvitationMail, setSentInvitationMail] = useState(false);

  const history = useHistory();

  const userCompany = userState?.companyIds[0];

  useEffect(() => {
    if (userCompany) {
      const fetchUserGroups = async () =>
        await handleRequest(client.listUserGroups(userCompany));

      fetchUserGroups().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items } = result.data.result;
        setUserGroups(
          items.map((group) => ({
            label: group.label,
            value: group.id,
          }))
        );
      });
    }
  }, []);

  const updateLanguage = useCallback(
    (newValue) => {
      setSelectedLanguage(newValue[0].value);
    },
    [setSelectedLanguage]
  );

  const updateUserGroups = useCallback(
    (newValue) => {
      const userGroups: UserGroupLink[] = newValue.map(
        (group: SelectItemInterface) => ({
          id: group.value,
        })
      );
      setSelectedUserGroups(userGroups);
    },
    [setSelectedUserGroups]
  );

  const isFormValid = useCallback(
    () =>
      Boolean(firstname) &&
      Boolean(lastname) &&
      isValidEmail(email) &&
      Boolean(selectedLanguage),
    [firstname, lastname, email, selectedLanguage]
  );

  const submitForm = useCallback(async () => {
    const user: UserWithoutId = {
      firstName: firstname,
      title: jobTitle,
      lastName: lastname,
      email,
      language: selectedLanguage,
      userGroups: selectedUserGroups,
    };
    try {
      const success = await handleRequest(client.addUser(user));

      if (success) {
        if (sentInvitationMail) {
          const userId = success.data.result.user!.id;
          await handleRequest(
            client.sendUsersInvitations({ userIds: [userId] })
          );
        }
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    history,
    firstname,
    jobTitle,
    lastname,
    email,
    selectedUserGroups,
    selectedLanguage,
    sentInvitationMail,
    client,
    handleRequest,
  ]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.user.create.form.title")}
          </MediumTitle>
          <LabeledInput required label={t("admin.user.create.form.firstname")}>
            <Input
              onChangeText={setFirstname}
              value={firstname}
              placeholder={t("admin.user.create.form.firstname")}
            />
          </LabeledInput>

          <LabeledInput required label={t("admin.user.create.form.lastname")}>
            <Input
              onChangeText={setLastname}
              value={lastname}
              placeholder={t("admin.user.create.form.lastname")}
            />
          </LabeledInput>
          <LabeledInput required label={t("admin.user.create.form.email")}>
            <Input
              onChangeText={setEmail}
              value={email}
              placeholder={t("admin.user.create.form.email")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.jobTitle")}>
            <Input
              onChangeText={setJobTitle}
              value={jobTitle}
              placeholder={t("admin.user.create.form.jobTitle")}
            />
          </LabeledInput>

          <LabeledInput
            label={t("admin.user.create.form.userGroups")}
            style={{ zIndex: 5 }}
          >
            <Select
              isMultiSelect={true}
              items={userGroups}
              onChange={updateUserGroups}
              placeholder={t("admin.user.create.form.userGroups")}
            />
          </LabeledInput>
          <SmallTitle hasMarginBottom>
            {t("admin.user.edit.userGeneral")}
          </SmallTitle>
          <LabeledInput
            required
            label={t("admin.user.create.form.language")}
            style={{ zIndex: 4 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={languages}
              onChange={updateLanguage}
              placeholder={t("admin.user.create.form.language")}
            />
          </LabeledInput>

          <LabeledInput>
            <Checkbox
              label={t("admin.user.create.form.sendInvite")}
              onChange={setSentInvitationMail}
              checked={sentInvitationMail}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.user.create.addButton")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
