module.exports = [
  "Agliè",
  "Andezeno",
  "Barbania",
  "Brosso",
  "Buriasco",
  "Caluso",
  "Cantoira",
  "Carmagnola",
  "Cavour",
  "Chiaverano",
  "Ciconio",
  "Coazze",
  "Cumiana",
  "Feletto",
  "Front",
  "Giaveno",
  "Grugliasco",
  "Lauriano",
  "Locana",
  "Lusigliè",
  "Mathi",
  "Mompantero",
  "Nichelino",
  "Novalesa",
  "Oulx",
  "Perrero",
  "Pinerolo",
  "Pomaretto",
  "Pramollo",
  "Quassolo",
  "Rivara",
  "Rondissone",
  "Rueglio",
  "Santena",
  "Sparone",
  "Usseaux",
  "Varisella",
  "Vidracco",
  "Vinovo",
  "Volvera",
  "Balocco",
  "Campertogno",
  "Cervatto",
  "Cravagliana",
  "Formigliana",
  "Lamporo",
  "Moncrivello",
  "Pila",
  "Rassa",
  "Rossa",
  "Scopa",
  "Valduggia",
  "Vocca",
  "Biandrate",
  "Briona",
  "Comignago",
  "Galliate",
  "Invorio",
  "Mezzomerico",
  "Pisano",
  "Sillavengo",
  "Terdobbiate",
  "Vinzaglio",
  "Alto",
  "Barge",
  "Benevello",
  "Bosia",
  "Brondello",
  "Canosio",
  "Cartignano",
  "Castino",
  "Cervere",
  "Clavesana",
  "Demonte",
  "Envie",
  "Frassino",
  "Gorzegno",
  "Isasca",
  "Macra",
  "Marmora",
  "Montanera",
  "Murello",
  "Nucetto",
  "Pagno",
  "Peveragno",
  "Pocapaglia",
  "Revello",
  "Robilante",
  "Roddi",
  "Saliceto",
  "Sanfrè",
  "Sinio",
  "Treiso",
  "Venasca",
  "Vignolo",
  "Albugnano",
  "Bruno",
  "Canelli",
  "Castell'Alfero",
  "Coazzolo",
  "Cortazzone",
  "Fontanile",
  "Maretto",
  "Monale",
  "Quaranti",
  "Scurzolengo",
  "Tigliole",
  "Viale",
  "Balzola",
  "Bosio",
  "Carezzano",
  "Casasco",
  "Cereseto",
  "Dernice",
  "Frugarolo",
  "Giarole",
  "Lerma",
  "Molare",
  "Montaldeo",
  "Mornese",
  "Ottiglio",
  "Pasturana",
  "Prasco",
  "Rivarone",
  "Solero",
  "Terruggia",
  "Trisobbio",
  "Villalvernia",
  "Bioglio",
  "Camburzano",
  "Cerrione",
  "Donato",
  "Magnano",
  "Mottalciata",
  "Piedicavallo",
  "Pray",
  "Tollegno",
  "Verrone",
  "Lessona",
  "Baceno",
  "Bognanco",
  "Caprezzo",
  "Crodo",
  "Ghiffa",
  "Macugnaga",
  "Miazzina",
  "Omegna",
  "Premia",
  "Trasquera",
  "Allein",
  "Avise",
  "Brissogne",
  "Chamois",
  "Cogne",
  "Etroubles",
  "Gressan",
  "Issime",
  "Morgex",
  "Pollein",
  "Quart",
  "Saint-Denis",
  "Saint-Rhémy-en-Bosses",
  "Valpelline",
  "Villeneuve",
  "Azzate",
  "Besnate",
  "Bregano",
  "Brusimpiano",
  "Caravate",
  "Castelseprio",
  "Clivio",
  "Cugliate-Fabiasco",
  "Dumenza",
  "Gemonio",
  "Inarzo",
  "Luino",
  "Marnate",
  "Morazzone",
  "Saltrio",
  "Ternate",
  "Albiolo",
  "Barni",
  "Bregnano",
  "Cabiate",
  "Carbonate",
  "Cavargna",
  "Dizzasco",
  "Faloppio",
  "Guanzate",
  "Lasnigo",
  "Luisago",
  "Moltrasio",
  "Musso",
  "Pigra",
  "Pusiano",
  "Schignano",
  "Torno",
  "Zelbio",
  "Aprica",
  "Caiolo",
  "Chiavenna",
  "Dazio",
  "Gordona",
  "Livigno",
  "Morbegno",
  "Poggiridenti",
  "Sernio",
  "Tartano",
  "Valdidentro",
  "Abbiategrasso",
  "Binasco",
  "Buscate",
  "Carpiano",
  "Cisliano",
  "Corsico",
  "Gaggiano",
  "Inzago",
  "Magenta",
  "Melzo",
  "Nosate",
  "Pantigliate",
  "Rescaldina",
  "Sedriano",
  "Tribiano",
  "Vignate",
  "Albino",
  "Ardesio",
  "Barbata",
  "Bossico",
  "Brumano",
  "Capizzone",
  "Cerete",
  "Colzate",
  "Cusio",
  "Fontanella",
  "Gandosso",
  "Gorno",
  "Lallio",
  "Martinengo",
  "Mozzo",
  "Pagazzano",
  "Parzanica",
  "Piazzatorre",
  "Pradalunga",
  "Ranica",
  "Rovetta",
  "Selvino",
  "Sovere",
  "Taleggio",
  "Valbondione",
  "Valtorta",
  "Viadanica",
  "Zogno",
  "Acquafredda",
  "Artogne",
  "Berlingo",
  "Botticino",
  "Breno",
  "Calvisano",
  "Castelcovati",
  "Cellatica",
  "Cigole",
  "Cologne",
  "Edolo",
  "Gambara",
  "Gottolengo",
  "Iseo",
  "Lograto",
  "Maclodio",
  "Manerbio",
  "Milzano",
  "Muscoline",
  "Odolo",
  "Ospitaletto",
  "Passirano",
  "Poncarale",
  "Preseglie",
  "Sellero",
  "Sulzano",
  "Trenzano",
  "Piancogno",
  "Barbianello",
  "Borgarello",
  "Calvignano",
  "Casteggio",
  "Cervesina",
  "Copiano",
  "Fortunago",
  "Garlasco",
  "Langosco",
  "Lungavilla",
  "Menconico",
  "Nicorvo",
  "Retorbido",
  "Rosasco",
  "Sommo",
  "Vidigulfo",
  "Zerbo",
  "Azzanello",
  "Capergnanica",
  "Castelleone",
  "Crema",
  "Fiesco",
  "Grontardo",
  "Montodine",
  "Pandino",
  "Ricengo",
  "Soresina",
  "Tornata",
  "Voltido",
  "Casalromano",
  "Commessaggio",
  "Gonzaga",
  "Marmirolo",
  "Ostiglia",
  "Quistello",
  "Sabbioneta",
  "Viadana",
  "Barzago",
  "Bulciago",
  "Casatenovo",
  "Cremeno",
  "Erve",
  "Merate",
  "Morterone",
  "Pagnona",
  "Premana",
  "Sirtori",
  "Valmadrera",
  "Codogno",
  "Galgagnano",
  "Maccastorna",
  "Meleti",
  "Sordio",
  "Barlassina",
  "Briosco",
  "Concorezzo",
  "Lesmo",
  "Renate",
  "Triuggio",
  "Caponago",
  "Avelengo",
  "Brennero",
  "Castelbello-Ciardes",
  "Dobbiaco",
  "Gais",
  "Laion",
  "Luson",
  "Merano",
  "Naz-Sciaves",
  "Plaus",
  "Rasun-Anterselva",
  "Scena",
  "Stelvio",
  "Tirolo",
  "Vandoies",
  "Vipiteno",
  "Andalo",
  "Bieno",
  "Caldes",
  "Capriana",
  "Cavareno",
  "Cis",
  "Dambel",
  "Fierozzo",
  "Giustino",
  "Lavis",
  "Massimeno",
  "Mezzolombardo",
  "Nogaredo",
  "Pinzolo",
  "Ronzone",
  "Sanzeno",
  "Sover",
  "Storo",
  "Terragnolo",
  "Trambileno",
  "Vignola-Falesina",
  "Altavalle",
  "Novella",
  "Belfiore",
  "Buttapietra",
  "Concamarise",
  "Garda",
  "Legnago",
  "Oppeano",
  "Roncà",
  "Sommacampagna",
  "Trevenzuolo",
  "Zevio",
  "Altissimo",
  "Breganze",
  "Caltrano",
  "Castegnero",
  "Creazzo",
  "Gallio",
  "Longare",
  "Montegaldella",
  "Orgiano",
  "Roana",
  "Santorso",
  "Solagna",
  "Trissino",
  "Villaverla",
  "Valbrenta",
  "Gosaldo",
  "Sospirolo",
  "Casier",
  "Codognè",
  "Fontanelle",
  "Loria",
  "Montebelluna",
  "Paese",
  "Preganziol",
  "Sarmede",
  "Vedelago",
  "Cavarzere",
  "Eraclea",
  "Martellago",
  "Pianiga",
  "Spinea",
  "Agna",
  "Barbona",
  "Campodarsego",
  "Cartura",
  "Conselve",
  "Gazzo",
  "Loreggia",
  "Monselice",
  "Ponso",
  "Saccolongo",
  "Stanghella",
  "Tribano",
  "Vigonza",
  "Calto",
  "Ceneselli",
  "Gaiba",
  "Melara",
  "Rosolina",
  "Villadose",
  "Artegna",
  "Bordano",
  "Cassacco",
  "Comeglians",
  "Enemonzo",
  "Grimacco",
  "Mortegliano",
  "Pagnacco",
  "Pontebba",
  "Premariacco",
  "Ravascletto",
  "Rigolato",
  "Sedegliano",
  "Talmassons",
  "Torreano",
  "Varmo",
  "Sappada",
  "Monfalcone",
  "Turriaco",
  "Brugnera",
  "Claut",
  "Fontanafredda",
  "Porcia",
  "Spilimbergo",
  "Airole",
  "Bajardo",
  "Castellaro",
  "Chiusavecchia",
  "Dolcedo",
  "Ospedaletti",
  "Pontedassio",
  "Sanremo",
  "Triora",
  "Vessalico",
  "Arnasco",
  "Bormida",
  "Ceriale",
  "Giustenice",
  "Mallare",
  "Nasino",
  "Pallare",
  "Roccavignale",
  "Stellanello",
  "Vendone",
  "Bogliasco",
  "Carasco",
  "Cogoleto",
  "Fontanigorda",
  "Lorsica",
  "Mignanego",
  "Rapallo",
  "Rovegno",
  "Torriglia",
  "Zoagli",
  "Framura",
  "Pignone",
  "Zignago",
  "Bobbio",
  "Cerignale",
  "Gazzola",
  "Rottofreno",
  "Zerba",
  "Bore",
  "Compiano",
  "Fontevivo",
  "Palanzano",
  "Terenzo",
  "Varsi",
  "Brescello",
  "Castellarano",
  "Gattatico",
  "Poviglio",
  "Toano",
  "Bomporto",
  "Fanano",
  "Maranello",
  "Montefiorino",
  "Polinago",
  "Sestola",
  "Argelato",
  "Camugnano",
  "Fontanelice",
  "Marzabotto",
  "Valsamoggia",
  "Comacchio",
  "Tresignana",
  "Conselice",
  "Cesena",
  "Modigliana",
  "Sarsina",
  "Gemmano",
  "Saludecio",
  "Pennabilli",
  "Comano",
  "Montignoso",
  "Zeri",
  "Capannori",
  "Massarosa",
  "Pietrasanta",
  "Agliana",
  "Montale",
  "Uzzano",
  "Empoli",
  "Impruneta",
  "Pelago",
  "Signa",
  "Capoliveri",
  "Piombino",
  "Bientina",
  "Cascina",
  "Montescudaio",
  "Ponsacco",
  "Vicopisano",
  "Bucine",
  "Lucignano",
  "Monteriggioni",
  "Poggibonsi",
  "Sinalunga",
  "Campagnatico",
  "Gavorrano",
  "Pitigliano",
  "Montemurlo",
  "Bettona",
  "Collazzone",
  "Gubbio",
  "Norcia",
  "Pietralunga",
  "Sigillo",
  "Trevi",
  "Allerona",
  "Baschi",
  "Guardea",
  "Narni",
  "Porano",
  "Fermignano",
  "Lunano",
  "Montefelcino",
  "Petriano",
  "Sassofeltrio",
  "Vallefoglia",
  "Camerano",
  "Corinaldo",
  "Jesi",
  "Montemarciano",
  "Polverigi",
  "Staffolo",
  "Caldarola",
  "Colmurano",
  "Gagliole",
  "Montecassiano",
  "Muccia",
  "Sarnano",
  "Urbisaglia",
  "Castignano",
  "Force",
  "Montegallo",
  "Ripatransone",
  "Altidona",
  "Grottazzolina",
  "Montegranaro",
  "Ortezzano",
  "Smerillo",
  "Bomarzo",
  "Capranica",
  "Corchiano",
  "Graffignano",
  "Monterosi",
  "Proceno",
  "Tuscania",
  "Vetralla",
  "Amatrice",
  "Cantalice",
  "Collegiove",
  "Cottanello",
  "Leonessa",
  "Montebuono",
  "Selci",
  "Vacone",
  "Ariccia",
  "Canterano",
  "Ciciliano",
  "Formello",
  "Gorga",
  "Licenza",
  "Mentana",
  "Moricone",
  "Nettuno",
  "Pomezia",
  "Saracinesco",
  "Vallepietra",
  "Zagarolo",
  "Fiumicino",
  "Pontinia",
  "Sabaudia",
  "Terracina",
  "Alvito",
  "Arnara",
  "Casalattico",
  "Ceccano",
  "Esperia",
  "Fontechiari",
  "Morolo",
  "Picinisco",
  "Ripi",
  "Settefrati",
  "Terelle",
  "Vallerotonda",
  "Aielli",
  "Barete",
  "Calascio",
  "Capistrello",
  "Castellafiume",
  "Introdacqua",
  "Navelli",
  "Ortucchio",
  "Pescina",
  "Rivisondoli",
  "Scoppito",
  "Trasacco",
  "Arsita",
  "Campli",
  "Cermignano",
  "Cortino",
  "Notaresco",
  "Alanno",
  "Civitaquana",
  "Farindola",
  "Nocciano",
  "Picciano",
  "Salle",
  "Vicoli",
  "Atessa",
  "Casacanditella",
  "Castelguidone",
  "Crecchio",
  "Fraine",
  "Gessopalena",
  "Lentella",
  "Monteferrante",
  "Orsogna",
  "Palombaro",
  "Poggiofiorito",
  "Roccamontepiano",
  "Tollo",
  "Vacri",
  "Fallo",
  "Casacalenda",
  "Guardiaregia",
  "Lucito",
  "Monacilioni",
  "Oratino",
  "Portocannone",
  "Roccavivara",
  "Tavenna",
  "Ururi",
  "Filignano",
  "Macchiagodena",
  "Pescolanciano",
  "Roccamandolfi",
  "Venafro",
  "Aversa",
  "Capodrise",
  "Casaluce",
  "Ciorlano",
  "Francolise",
  "Liberi",
  "Parete",
  "Pratella",
  "Succivo",
  "Casapesenna",
  "Arpaia",
  "Bucciano",
  "Castelpagano",
  "Circello",
  "Forchia",
  "Montesarchio",
  "Paupisi",
  "Puglianello",
  "Vitulano",
  "Arzano",
  "Caivano",
  "Casamarciano",
  "Cicciano",
  "Frattamaggiore",
  "Liveri",
  "Nola",
  "Portici",
  "Ercolano",
  "Saviano",
  "Andretta",
  "Baiano",
  "Calitri",
  "Castelfranci",
  "Domicella",
  "Gesualdo",
  "Lapio",
  "Mercogliano",
  "Montella",
  "Nusco",
  "Quadrelle",
  "Senerchia",
  "Sturno",
  "Torrioni",
  "Venticano",
  "Agropoli",
  "Aquara",
  "Battipaglia",
  "Caggiano",
  "Cannalonga",
  "Ceraso",
  "Corbara",
  "Futani",
  "Laurito",
  "Montecorice",
  "Padula",
  "Perito",
  "Polla",
  "Ravello",
  "Rofrano",
  "Sapri",
  "Serramezzana",
  "Torchiara",
  "Valva",
  "Apricena",
  "Carlantino",
  "Faeto",
  "Manfredonia",
  "Serracapriola",
  "Vieste",
  "Alberobello",
  "Bitonto",
  "Conversano",
  "Molfetta",
  "Putignano",
  "Turi",
  "Crispiano",
  "Laterza",
  "Massafra",
  "Palagianello",
  "Cisternino",
  "Oria",
  "Alliste",
  "Collepasso",
  "Diso",
  "Giurdignano",
  "Lizzanello",
  "Melendugno",
  "Neviano",
  "Palmariggi",
  "Ruffano",
  "Seclì",
  "Sternatia",
  "Taviano",
  "Ugento",
  "Presicce-Acquarica",
  "Trani",
  "Armento",
  "Baragiano",
  "Calvera",
  "Castelmezzano",
  "Fardella",
  "Latronico",
  "Marsicovetere",
  "Montemilone",
  "Picerno",
  "Rapolla",
  "Rotonda",
  "Spinoso",
  "Trecchina",
  "Ginestra",
  "Calciano",
  "Garaguso",
  "Pomarico",
  "Tursi",
  "Aieta",
  "Amendolara",
  "Bocchigliero",
  "Campana",
  "Castrolibero",
  "Cerisano",
  "Cleto",
  "Diamante",
  "Fuscaldo",
  "Lattarico",
  "Maierà",
  "Marzi",
  "Mottafollone",
  "Panettieri",
  "Pietrafitta",
  "Rose",
  "Scigliano",
  "Verbicaro",
  "Amaroni",
  "Belcastro",
  "Conflenti",
  "Decollatura",
  "Girifalco",
  "Maida",
  "Montauro",
  "Petrizzi",
  "Squillace",
  "Zagarise",
  "Bagaladi",
  "Bovalino",
  "Canolo",
  "Ciminà",
  "Delianuova",
  "Giffone",
  "Maropati",
  "Monasterace",
  "Platì",
  "Roghudi",
  "Seminara",
  "Stignano",
  "Carfizzi",
  "Mesoraca",
  "Strongoli",
  "Briatico",
  "Dinami",
  "Filogaso",
  "Limbadi",
  "Nicotera",
  "Ricadi",
  "Spadola",
  "Vazzano",
  "Calatafimi-Segesta",
  "Gibellina",
  "Poggioreale",
  "Vita",
  "Aliminusa",
  "Bisacquino",
  "Caltavuturo",
  "Castelbuono",
  "Cinisi",
  "Lascari",
  "Montelepre",
  "Roccamena",
  "Trabia",
  "Villabate",
  "Antillo",
  "Castell'Umberto",
  "Falcone",
  "Frazzanò",
  "Graniti",
  "Limina",
  "Mandanici",
  "Mistretta",
  "Patti",
  "Roccafiorita",
  "Savoca",
  "Tortorici",
  "Venetico",
  "Bivona",
  "Cammarata",
  "Comitini",
  "Montallegro",
  "Ravanusa",
  "Bompensiere",
  "Gela",
  "Mussomeli",
  "Sommatino",
  "Assoro",
  "Cerami",
  "Pietraperzia",
  "Calatabiano",
  "Linguaglossa",
  "Mineo",
  "Pedara",
  "Sant'Alfio",
  "Vizzini",
  "Comiso",
  "Buccheri",
  "Floridia",
  "Pachino",
  "Aggius",
  "Banari",
  "Bono",
  "Buddusò",
  "Cargeghe",
  "Cossoine",
  "Ittireddu",
  "Mara",
  "Nule",
  "Osilo",
  "Pattada",
  "Romana",
  "Sennori",
  "Torralba",
  "Telti",
  "Stintino",
  "Atzara",
  "Bitti",
  "Dorgali",
  "Gairo",
  "Irgoli",
  "Loculi",
  "Mamoiada",
  "Olzai",
  "Orgosolo",
  "Osidda",
  "Posada",
  "Sorgono",
  "Tonara",
  "Urzulei",
  "Quartucciu",
  "Albagiara",
  "Assolo",
  "Bidonì",
  "Cuglieri",
  "Gonnostramatza",
  "Mogoro",
  "Nurachi",
  "Paulilatino",
  "Seneghe",
  "Simala",
  "Sorradile",
  "Uras",
  "Siapiccia",
  "Laconi",
  "Suni",
  "Barrali",
  "Carbonia",
  "Dolianova",
  "Esterzili",
  "Gergei",
  "Gonnesa",
  "Iglesias",
  "Monastir",
  "Nurallao",
  "Ortacesus",
  "Portoscuso",
  "Santadi",
  "Senorbì",
  "Setzu",
  "Silius",
  "Tuili",
  "Villacidro",
  "Villaperuccio",
  "Villaspeciosa"
];