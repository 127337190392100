import { useEffect, useState } from "react";
import { UserGroup } from "../../bookmydesk-api-sdk-typescript-axios";
import { useApi } from "./useApi";
import { useClient } from "./useClient";

const useUserGroups = (companyId: string): UserGroup[] => {
  const client = useClient();
  const { handleRequest } = useApi();

  const [locations, setLocations] = useState<UserGroup[]>([]);

  useEffect(() => {
    const init = async () => {
      if (!companyId) {
        return setLocations([]);
      }
      const response = await handleRequest(client.listUserGroups(companyId));

      setLocations(response?.data.result.items || []);
    };
    init();
  }, [handleRequest, companyId, client]);

  return locations;
};

export default useUserGroups;
