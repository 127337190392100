module["exports"] = [
  "فیستا",
  "فوکوس",
  "تاروس",
  "موستانگ",
  "اکسپلورر",
  "کروز",
  "مالیبو",
  "ایمپالا",
  "کامارو",
  "کروت",
  "کولورادو",
  "سیلورادو",
  "اسکالید",
  "آلپاین",
  "چارجر",
  "چلنجر",
  "رانگلر",
  "گرند چروکی",
  "رودستر",
  "کمری",
  "پریوس",
  "لندکروزر",
  "آکورد",
  "سیویک",
  "المنت",
  "سنترا",
  "آلتیما",
  "یبتل",
  "جتا",
  "گلف",
  "911",
  "اسپایدر",
  "کونتاچ",
  "مورسیه لاگو",
  "اونتادور",
];
