import { saveAs } from "file-saver";
import React, { FC, useCallback, useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";
import { Company } from "../../../bookmydesk-api-sdk-typescript-axios";
import { useAuth } from "../../context/authContext";
import { useApi } from "../../hooks/useApi";
import { useClient } from "../../hooks/useClient";
import { useImpersonation } from "../../hooks/useImpersonation";
import { t } from "../../i18n";
import { useHistory } from "../../routing";
import { TinyText } from "../../styles/Text";
import { borderRadius, color, font, spacing } from "../../styles/theme";
import Dropdown from "../Dropdown";
import Link from "../Link";
import { PageItem } from "../PageSelector/PageSelectorItem";

const pillLetterSpacing = "1px";

interface LinkInterface {
  label: string;
  path?: string;
  onClick?: () => void;
  active?: boolean;
}

interface NavigationItemProps extends LinkInterface {
  dropdownContent?: LinkInterface[] | undefined;
}

const PillText = styled(TinyText)`
  text-transform: uppercase;
  font-family: ${font.defaultMedium};
  letter-spacing: ${pillLetterSpacing};
`;

const Pill = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.extraSmall} ${spacing.medium};
  border-radius: ${borderRadius.full};
  background-color: ${color.tertiary};
`;

const PillWrapper = styled.View`
  padding-left: ${spacing.medium};
`;

const AdminNavbarContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const HeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DropdownWrapper = styled.View`
  margin: 0 ${spacing.medium};
`;

export const getCompanyPages = () => [
  {
    label: t("menu.item.settings"),
    path: "/admin/company-settings",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
  {
    label: t("menu.item.company"),
    path: "/admin/company-overview",
    roles: ["ROLE_ADMIN"],
  },
  {
    label: t("menu.item.timeslot"),
    path: "/admin/timeslot-overview",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
  {
    label: t("menu.item.seatTag"),
    path: "/admin/company/tag-overview",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
  {
    label: t("menu.item.archiving"),
    path: "/admin/company/archiving",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
  {
    label: t("menu.item.scheduled"),
    path: "/admin/company/reports",
    roles: ["ROLE_ADMIN"],
  },
  {
    label: t("menu.item.activeDirectory"),
    path: "/admin/company/active-directory",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
];

export const getUserPages = () => [
  {
    label: t("menu.item.adminUser"),
    path: "/admin/admin-user-overview",
    roles: ["ROLE_ADMIN","ROLE_CUSTOMER_ADMIN"],
  },
  {
    label: t("menu.item.user"),
    path: "/admin/user-overview",
    roles: ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
  },
  {
    label: t("menu.item.userGroup"),
    path: "/admin/user-group-overview",
    roles: ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
  },
];

export const getOfficePages = () => [
  {
    label: t("menu.item.location"),
    path: "/admin/location-overview",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
  {
    label: t("menu.item.map"),
    path: "/admin/map-overview",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },  
  {
    label: t("menu.item.parkingspots"),
    path: "/admin/resource-overview",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
  {
    label: t("menu.item.barcodes"),
    path: "/admin/office/barcodes",
    roles: ["ROLE_ADMIN"],
  },
];
export const getSaltoPage = () => [
  {
    label: t("menu.item.salto"),
    path: "/admin/salto-overview",
    roles: ["ROLE_CUSTOMER_ADMIN"],
  },
];

export const getFacilityPages = () => [
  {
    label: t("menu.item.notification"),
    path: "/admin/notification-overview",
    roles: ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
  },
  {
    label: t("menu.item.reports"),
    path: "/admin/report-overview",
    roles: ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
  },
  {
    label: t("menu.item.cleaningOverview"),
    path: "/admin/cleaning-map-overview",
    roles: ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
  },
];

const AdminNavBarContent: FC = () => {
  const { logOut, userState, companyPages } = useAuth();
  const { handleRequest } = useApi();
  const client = useClient();
  const { isImpersonating, stopImpersonating } = useImpersonation();
  const history = useHistory();

  const currentLocation = history.location.pathname;

  const exportCompanyUserCount = useCallback(async () => {
    const response = await handleRequest(
      client.exportCompanyUserCount({
        responseType: "blob",
      })
    );

    if (!response) {
      return;
    }

    saveAs(response.data, "company-user-count.xlsx");
  }, [client, handleRequest]);
  //console.log("user state", userState);

  const exportAdminUserEmails = useCallback(async () => {
    const response = await handleRequest(
      client.exportAdminUserEmails({
        responseType: "blob",
      })
    );

    if (!response) {
      return;
    }

    saveAs(response.data, "admin-user-emails.xlsx");
  }, [client, handleRequest]);

  const createLinkItem = ({ label, path, onClick }: LinkInterface) => ({
    label,
    active: currentLocation === path,
    path,
    onClick,
  });

  /* used for getting the start date to decide the trial period */
  const [billingStartDateRunning, setBillingStartDateRunning] = useState("");

  const [company, setCompany] = useState<Company | null>(null);
  const userCompany = userState?.companyIds[0];

  useEffect(() => {
    if (!userCompany) {
      return;
    }
    const fetchCompany = async () =>
      await handleRequest(client.getCompany(userCompany));

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }
      const { company } = result.data.result;
      setCompany(company);
      setBillingStartDateRunning(company.billingStartDate);

    });
  }, [client, handleRequest, userCompany]);

  let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  let billingStartDate = (new Date(billingStartDateRunning)).getTime(); //userCompany.billingStartDate 10/07/2022
  let today = (new Date()).getTime();

  let trialPeriodDays = 21 * 24 * 60 * 60 * 1000; // 21 days * hours * minutes* seconds* milliseconds
  let numberOfDaysTrial = Math.round(Math.abs(today - billingStartDate) / oneDay);
  let trialPeriodEnds = 22 - numberOfDaysTrial;

  let statusTextColor = color.grey;
  let statusBackgroundColor = color.greyLightest;
  let showTrialPeriod = "";

  switch (userState?.billingType) {
    case 'free':
      if (trialPeriodEnds > 8) {
        statusTextColor = color.black;
        statusBackgroundColor = color.trialYellow;
        showTrialPeriod = t("general.trialDuration", { trialPeriodEnds: trialPeriodEnds });
      }
      if (trialPeriodEnds <= 8) {
        statusTextColor = color.black;
        statusBackgroundColor = color.trialOrange;
        showTrialPeriod = t("general.trialDuration", { trialPeriodEnds: trialPeriodEnds });
      }
      if (trialPeriodEnds <= 3) {
        statusTextColor = color.black;
        statusBackgroundColor = color.trialDarkOrange;
        showTrialPeriod = t("general.trialDuration", { trialPeriodEnds: trialPeriodEnds });
      }
      if (trialPeriodEnds == 1) {
        statusTextColor = color.black;
        statusBackgroundColor = color.trialDarkOrange;
        showTrialPeriod = t("general.trialDurationOne", { trialPeriodEnds: trialPeriodEnds });
      }
      if (trialPeriodEnds <= 0) {
        statusTextColor = color.black;
        statusBackgroundColor = color.trialOnHold;
        showTrialPeriod = t("general.trialDurationEnded");
      }
      break;
    case 'none':
      statusTextColor = color.white;
      statusBackgroundColor = color.bmdRedDark;
      break;
    case 'stopped':
        statusTextColor = color.bmdRedDark;
        statusBackgroundColor = color.bmdRedLight;
        break;
    case 'enterprise':
      statusTextColor = color.white;
      statusBackgroundColor = color.subscriptionRunningEnterprise;
      break;
    case 'month':
      statusTextColor = color.white;
      statusBackgroundColor = color.subscriptionRunning;
      break;
    case 'year':
      statusTextColor = color.white;
      statusBackgroundColor = color.subscriptionRunning;
      break;
  }

  const renderTablePages = useCallback(() => {
    if (company?.saltoUsername && company?.saltoPassword) {
    return [...getOfficePages(), ...getSaltoPage()];
    }    
    return getOfficePages();

  },[company]);


  const createNavigationItem = (
    label: string,
    mainPathRoles: string[],
    path?: string,
    dropdownItems?: PageItem[]
  ): NavigationItemProps | undefined => {
    //console.log("userState", userState, mainPathRoles);
    if (
      mainPathRoles.length > 0 &&
      userState &&
      mainPathRoles.filter((role) => userState.roles.includes(role)).length ===
        0
    ) {
      return undefined;
    }
    const active = dropdownItems
      ? dropdownItems.some(({ path }) => path === currentLocation)
      : currentLocation === path;
    const dropdownContent = dropdownItems
      ? dropdownItems
          .filter(
            (page) =>
              !page.roles ||
              (userState &&
                page.roles.filter((role) => userState.roles.includes(role))
                  .length > 0)
          )
          .map(createLinkItem)
      : undefined;
    if (!path && dropdownContent && dropdownContent.length > 0) {
      path = dropdownContent[0].path;
    }

    //console.log(path);

    return {
      label,
      active,
      path,
      dropdownContent,
    };
  };

  const navigationItems = [
    createNavigationItem(
      t("menu.item.dashboard"),
      ["ROLE_ADMIN","ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
      "/admin/dashboard"
    ),
    createNavigationItem(
      t("menu.item.company"),
      ["ROLE_ADMIN","ROLE_CUSTOMER_ADMIN"],
      undefined,
      companyPages
    ),
    createNavigationItem(
      t("menu.item.office"),
      ["ROLE_CUSTOMER_ADMIN"],
      undefined,
    renderTablePages()
    ),
    createNavigationItem(
      t("menu.item.userManagement"),
      ["ROLE_ADMIN","ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
      undefined,
      getUserPages()
    ),
    createNavigationItem(t("menu.item.exports"), ["ROLE_ADMIN"], undefined, [
      {
        label: t("menu.item.exportCompanyUserCount"),
        onClick: exportCompanyUserCount,
        roles: ["ROLE_ADMIN"],
      },
      {
        label: t("menu.item.exportAdminUserEmails"),
        onClick: exportAdminUserEmails,
        roles: ["ROLE_ADMIN"],
      },
    ]),
    createNavigationItem(
      t("menu.item.facilities"),
      ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
      undefined,
      getFacilityPages()
    ),
    createNavigationItem(
      t("menu.item.reservation"),
      ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
      "/admin/reservation-overview"
    ),    
    createNavigationItem(
      t("menu.item.parkingspots"),
      ["ROLE_CUSTOMER_ADMIN","ROLE_OFFICE_MANAGER"],
      "/admin/resource-list-overview"
    ),
  ].filter((item) => typeof item !== "undefined") as NavigationItemProps[];

  const [menuItems, setMenuItems] =
    useState<Array<NavigationItemProps>>(navigationItems);

  useEffect(() => {
    setMenuItems(navigationItems);

  }, [companyPages, company]);

  const changeRoute = useCallback(
    (path: string) => {
      history.push(path);
      const currentLocation = history.location.pathname;
      const updatedMenuItems = menuItems.map((item) => {
        item.active = item.dropdownContent
          ? item.dropdownContent.some(({ path }) => path === currentLocation)
          : currentLocation === item.path;

        if (item.dropdownContent) {
          item.dropdownContent = item.dropdownContent.map(
            (link: LinkInterface) => {
              link.active = currentLocation === link.path;
              return link;
            }
          );
        }
        return item;
      });
      setMenuItems([...updatedMenuItems]);
    },
    [setMenuItems, history, menuItems]
  );
  return (

    <AdminNavbarContainer>
      <HeaderContainer>
        {menuItems.map(
          ({ label, path, active, dropdownContent, onClick }, index) => (
            <View key={index}>
              {dropdownContent ? (
                <DropdownWrapper>
                  <Dropdown
                    onPress={() => {
                      if (onClick) {
                        onClick();
                      }
                      if (path) {
                        changeRoute(path!);
                      }
                    }}
                    text={label}
                    isActive={active}
                    isOpeningOnHover
                  >
                    {dropdownContent.map(({ label, path, active, onClick }, index) => (
                      <Link
                        hasPadding
                        key={`${path}-${index}`}
                        isActive={active}
                        onPress={() => {

                          if (onClick) {
                            onClick();
                          }
                          if (path) {
                            changeRoute(path!);
                          }
                        }}
                      >
                        {label}
                      </Link>
                    ))}
                  </Dropdown>
                </DropdownWrapper>
              ) : (
                <Link
                  hasPadding
                  isActive={active}
                  onPress={() => {
                    if (onClick) {
                      onClick();
                    }
                    if (path) {
                      changeRoute(path!);
                    }
                  }}
                >
                  {label}
                </Link>
              )}
            </View>
          )
        )}
      </HeaderContainer>
      <HeaderContainer>
        <Dropdown
          text={
            !isImpersonating
              ? `${t("general.hi", { name: userState?.firstName })}`
              : `${t("impersonation.header", { name: userState?.firstName })}`
          }
        >
          <Link
            hasPadding
            onPress={() =>
              history.push('/admin/admin-user-overview/update/'+ userState.id, { profileName: 'Profile' })
            }
          >
          {t("menu.item.myProfile")}
          </Link>
          <Link hasPadding onPress={logOut}>
            {t("general.logout")}
          </Link>
          {isImpersonating ? (
            <Link hasPadding onPress={stopImpersonating}>
              {t("impersonation.exit")}
            </Link>
          ) : null}
        </Dropdown>
        <TouchableOpacity
          disabled={
            !["free", "trial"].includes(userState?.billingType || "trial")
          }
          onPress={() => {
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSfOoMVRac3ySi95sUFz2j4oI3-mbSr-_aHSiklaWIkfYUOkGg/viewform?entry.394934638=demo@bookmydesk.com&entry.1137742466=100",
              "_blank"
            );
          }}
        >
          <PillWrapper>
            <Pill
              style={{backgroundColor: statusBackgroundColor}}
            >
              <PillText
                style={{color: statusTextColor}}
              >
                {t(`general.billingType.${userState?.billingType}`)}{showTrialPeriod}
              </PillText>
            </Pill>
          </PillWrapper>
        </TouchableOpacity>
      </HeaderContainer>
    </AdminNavbarContainer>
  );
};

export default AdminNavBarContent;
