module["exports"] = [
  "poner en práctica",
  "utilizar",
  "integrar",
  "racionalizar",
  "optimizar",
  "evolucionar",
  "transformar",
  "abrazar",
  "habilitar",
  "orquestar",
  "apalancamiento",
  "reinventar",
  "agregado",
  "arquitecto",
  "mejorar",
  "incentivar",
  "transformarse",
  "empoderar",
  "Envisioneer",
  "monetizar",
  "arnés",
  "facilitar",
  "aprovechar",
  "desintermediar",
  "sinergia",
  "estrategias",
  "desplegar",
  "marca",
  "crecer",
  "objetivo",
  "sindicato",
  "sintetizar",
  "entregue",
  "malla",
  "incubar",
  "enganchar",
  "maximizar",
  "punto de referencia",
  "acelerar",
  "reintermediate",
  "pizarra",
  "visualizar",
  "reutilizar",
  "innovar",
  "escala",
  "desatar",
  "conducir",
  "extender",
  "ingeniero",
  "revolucionar",
  "generar",
  "explotar",
  "transición",
  "e-enable",
  "repetir",
  "cultivar",
  "matriz",
  "productize",
  "redefinir",
  "recontextualizar"
]
