module["exports"] = [
  "كتب",
  "ألعاب",
  "إلكترونيات",
  "حواسيب",
  "بيت",
  "حديقة",
  "أدوات",
  "بقالة",
  "صحة",
  "جمال",
  "ألعاب",
  "أطفال",
  "رضع",
  "ملابس",
  "أحذية",
  "مجوهرات",
  "أغراض رياضية",
  "في الهواء الطلق",
  "السيارات",
  "صناعة"
];
