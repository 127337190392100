import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { color } from "../../styles/theme";
import { LeaderboardInterface } from "../../../fake-data/dashboardData";
import PercentageIndicatorLine from "../PercentageIndicatorLine";
import Text, { SmallText } from "../../styles/Text";
import { SelectedItems } from "../Select";

const LeaderboardContainer = styled.div`
    display: block;
    max-height: 180px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: 2px;
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background: #FFF;
    }
    &::-webkit-scrollbar-thumb {
        background: #eee;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-corner {
        background: #eee;
    }
`;

const LeaderboardEntry = styled.div`
    display: block;
    display: flex;
    flex-direction: row;
`;

const LeaderboardEntryCell = styled.div`
    flex: 1;
`;

const limitCharacters = (text: string) => text.length > 21 ? text.substr(0, 21) + '...' : text

function getColor (percentage: number) {
    switch (true) {
        case percentage <= 10: return '#9895FF'
        case percentage <= 25: return '#40A2B2'
        case percentage <= 50: return '#3EB8A0'
        case percentage <= 80: return '#6194E0'
        case percentage <= 94: return '#EDB349'
        default: return '#ED8C8C'
    }
}

const Leaderboard: FC<LeaderboardInterface> = ({
    items,
}) => {
    const sortedItems = items.length ? items.sort((a, b) => a.percentage < b.percentage ? 1 : -1) : []

    return (
        <LeaderboardContainer>
            {sortedItems?.map(({ tag, percentage, active }, index) => (
                active ?
                <LeaderboardEntry
                    key={index}>
                    <LeaderboardEntryCell style={{ flex: 5, }}>
                        <SmallText>{ tag ? limitCharacters(tag) : '' }</SmallText>
                    </LeaderboardEntryCell>
                    <LeaderboardEntryCell style={{ flex: 4, alignItems: "flex-end", paddingRight: "10px", }}>
                        <PercentageIndicatorLine
                            percentage={percentage}
                            color={getColor(percentage)}></PercentageIndicatorLine>
                    </LeaderboardEntryCell>
                    <LeaderboardEntryCell style={{ flex: 1, alignItems: "flex-end", }}>
                        <SmallText>{percentage}%</SmallText>
                    </LeaderboardEntryCell>
                </LeaderboardEntry> : ''
            ))}
        </LeaderboardContainer>
    );
};

  export default Leaderboard;
