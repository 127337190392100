import { useState, useEffect } from "react";
import i18nCountries from "i18n-iso-countries";
import i18n from "../i18n";

interface Item {
  label: string;
  value: string;
}

export const useCountries = (): Item[] => {
  const [countries, setCountries] = useState<Item[]>([]);

  useEffect(() => {
    i18nCountries.registerLocale(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`i18n-iso-countries/langs/${i18n.locale}.json`)
    );
    const countryList = i18nCountries.getNames(i18n.locale);
    setCountries(
      Object.keys(i18nCountries.getNames(i18n.locale)).map((key) => ({
        label: countryList[key],
        value: key,
      }))
    );
  }, []);

  return countries;
};

export default useCountries;
