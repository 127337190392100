module["exports"] = [
  "חיפה",
  "רמת הגולן",
  "הגדה המערבית",
  "שומרון",
  "השרון",
  "יהודה",
  "הנגב",
  "השפלה",
  "הערבה"
];
