import styled from "styled-components/native";
import Text from "../Text";
import { font, text } from "../theme";
import { marginBottomLarge, MarginBottomProps } from "../helpers/marginBottom";

export default styled(Text)<MarginBottomProps>`
  font-family: ${font.defaultSemiBold};
  font-size: ${text.big.size};

  ${(props) => marginBottomLarge(props)}
`;
