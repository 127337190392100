import React, { FC, useCallback, useState, useRef } from "react";
import { useHistory, RouterProps } from "../routing";
import { TextInput } from "react-native";

import styled from "styled-components/native";
import { t } from "../i18n";

import Input from "../styles/Input";
import Title from "../styles/Title";
import { iconSize, spacing, text } from "../styles/theme";
import { FormCard } from "../styles/Card";
import { ErrorText, SmallText } from "../styles/Text";

import Button from "../components/Button";
import Link from "../components/Link";

import { useAuth } from "../context/authContext";

import logo from "../assets/emblem.png";

const path = "/reset-password";
const loginCardWidth = "600px";

export const LoginFormCard = styled(FormCard)`
  margin: auto;
  max-width: ${loginCardWidth};
  align-content: stretch;
  min-height: 60vh;
  min-width: 42vw;
  padding: ${spacing.large} ${spacing.medium};
`;

export const Wrapper = styled.View`
  width: 30vw;
  margin: auto;
  flex: 1;
  flex-basis: auto;
  justify-content: center;
`;

export const LoginContainer = styled.View`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-content: center;
`;

export const CenterContainer = styled.View`
  align-items: center;
`;

export const LoginTitle = styled(Title)`
  margin-bottom: ${spacing.medium};
  font-size: ${text.larger.size};
`;

export const Logo = styled.Image`
  margin-bottom: ${spacing.medium};
  ${iconSize.extraLarge}
`;

const Login: FC<RouterProps> = () => {
  const password_ref = useRef<TextInput>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { authState, login } = useAuth();
  const history = useHistory();

  const emailInputChange = useCallback(
    (newValue) => {
      setEmail(newValue);
    },
    [setEmail]
  );

  const passwordInputChange = useCallback(
    (newValue) => {
      setPassword(newValue);
    },
    [setPassword]
  );

  const submitForm = useCallback(async () => {
    await login(email, password);
  }, [email, password, login]);

  const isValidForm = useCallback(
    () => Boolean(password) && Boolean(email),
    [password, email]
  );

  const handleSubmit = useCallback(() => {
    if (isValidForm()) {
      submitForm();
    }
  }, [isValidForm, submitForm]);

  return (
    <LoginContainer>
      <LoginFormCard>
        <Wrapper>
          <CenterContainer>
            <Logo source={logo} />
            <LoginTitle>{t("login.title")}</LoginTitle>
          </CenterContainer>
          <SmallText mediumWeight>{t("login.email")}</SmallText>
          <Input
            value={email}
            onChangeText={emailInputChange}
            placeholder={t("login.email")}
            onSubmitEditing={() => password_ref!.current!.focus()}
            blurOnSubmit={false}
          />
          <SmallText mediumWeight>{t("login.password")}</SmallText>
          <Input
            value={password}
            onChangeText={passwordInputChange}
            secureTextEntry
            placeholder={t("login.password")}
            ref={password_ref as any}
            onSubmitEditing={() => handleSubmit()}
          />
          <Button
            hasMarginBottom
            disabled={!isValidForm()}
            onPress={submitForm}
          >
            {t("login.submit")}
          </Button>
          {Boolean(authState.errorMessage) && (
            <ErrorText>{authState.errorMessage}</ErrorText>
          )}
          <CenterContainer>
            <Link
              isActive
              onPress={() => {
                history.push(path);
              }}
            >
              {t("login.forgotPassword")}
            </Link>
          </CenterContainer>
        </Wrapper>
      </LoginFormCard>
    </LoginContainer>
  );
};

export default Login;
