module["exports"] = {
  wide: [
    "Հունվար",
    "Փետրվար",
    "Մարտ",
    "Ապրիլ",
    "Մայիս",
    "Հունիս",
    "Հուլիս",
    "Օգոստոս",
    "Սեպտեմբեր",
    "Հոկտեմբեր",
    "Նոյեմբեր",
    "Դեկտեմբեր"
  ],
  abbr: [
    "Հնվ",
    "Փտր",
    "Մրտ",
    "Ապր",
    "Մյս",
    "Հնս",
    "Հլս",
    "Օգս",
    "Սպտ",
    "Հկտ",
    "Նմբ",
    "Դկտ"
  ],
};
