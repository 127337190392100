import { SeatOnMap } from ".";
import { SeatInterface } from "../../../fake-data/mapData";

/**
 * A static cleaning map class that can persist data.
 * This class exists because it helps us remove complexity from the map detail page.
 */
export class CleaningMapFunctionality {
  static cleaningItems: SeatOnMap[] = [];

  static setCleaningItems(newCleaningItems: SeatOnMap[]) {
    this.cleaningItems = newCleaningItems;
  }

  static getSortedCleaningItems() {
    return this.cleaningItems.sort(
      (a, b) => Number(a?.isClean) - Number(b?.isClean)
    );
  }
}
