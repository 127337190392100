import { t } from "../i18n";

export type SeatType = {
  name: string;
  value: string;
};

const useSeatTypes = (): SeatType[] => {
  return ["desk", "lunch", "meeting"].map((item) => ({
    name: t(`admin.map.seatType.${item}`),
    value: item,
  }));
};

export default useSeatTypes;
