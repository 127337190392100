module["exports"] = [
  "Cargo Van",
  "Convertible",
  "Coupe",
  "Crew Cab Pickup",
  "Extended Cab Pickup",
  "Hatchback",
  "Minivan",
  "Passenger Van",
  "SUV",
  "Sedan",
  "Wagon"
];
