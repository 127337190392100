import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "./chart";
import React, { FC, useCallback } from "react";
import { Defs, LinearGradient, Stop, Svg } from "react-native-svg";
import { color, font } from "../../styles/theme";
import { OccupationChartProps } from "../../../fake-data/dashboardData";
import { View } from "react-native";
import moment from "moment";
import { formatCurrency } from "../../utils";

const dataStrokeWidth = "3";
const gridStrokeWidth = "1";
const tickLabelPaddingY = 25;
const tickLabelPaddingX = 20;
const chartWidth = 1100;
const chartHeight = 600;
const chartPadding = {
  top: 40,
  left: 90,
  bottom: 60,
  right: 50,
};

const defaultTickValuesY = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

interface PieProps {
  data: Array<OccupationChartProps>;
  tickValuesY?: Array<number>;
  tickValuesYFormat?: "currency";
}

interface TickValue {
  date: Date;
  show: boolean;
}

const LineChart: FC<PieProps> = ({
  data,
  tickValuesY = defaultTickValuesY,
  tickValuesYFormat,
}) => {
  const tickValuesX: Array<TickValue> = [];
  const totalXValues = (data[0]?.lineData || []).length;
  (data[0]?.lineData || []).map((item: { x: Date }, index) => {
    const show =
      totalXValues <= 8 ||
      index === 0 ||
      index === totalXValues - 1 ||
      index % Math.floor(totalXValues / 8) === 0;
    tickValuesX.push({ date: item.x, show });
  });

  const tickFormatY = useCallback(
    (value: number, total: number) => {
      if (tickValuesYFormat === "currency") {
        return formatCurrency(value, 0);
      }
      return `${value}%`;
    },
    [tickValuesYFormat]
  );

  return (
    <View>
      <Svg height={0} width={0}>
        <Defs>
          <LinearGradient
            id={"gradientStroke"}
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%"
          >
            <Stop
              offset="0%"
              stopColor={data[0]?.dataColor || "#ffffff"}
              stopOpacity="0.1"
            />
            <Stop offset="70%" stopColor="#ffffff" stopOpacity="0" />
          </LinearGradient>
        </Defs>
      </Svg>
      <VictoryChart
        width={chartWidth}
        height={chartHeight}
        padding={{
          top: chartPadding.top,
          left: chartPadding.left,
          bottom: chartPadding.bottom,
          right: chartPadding.right,
        }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis
          dependentAxis
          tickValues={tickValuesY}
          tickFormat={tickFormatY}
          orientation="left"
          style={{
            tickLabels: {
              padding: tickLabelPaddingX,
              fill: color.dark,
              fontFamily: font.defaultRegular,
            },
            grid: {
              stroke: color.greyLighter,
              strokeWidth: gridStrokeWidth,
            },
            axis: { stroke: "transparent" },
          }}
        />

        <VictoryAxis
          scale="time"
          style={{
            tickLabels: {
              padding: tickLabelPaddingY,
              fill: color.dark,
              fontFamily: font.defaultRegular,
            },
            grid: {
              stroke: color.greyLighter,
              strokeDasharray: "5,5",
              strokeWidth: gridStrokeWidth,
            },
            axis: {
              stroke: color.greyLighter,
              strokeWidth: gridStrokeWidth,
            },
          }}
          tickValues={tickValuesX.map((x) => x.date)}
          tickFormat={(x: Date) => {
            
            const tickValue = tickValuesX.find(
              (tickValue) => tickValue.date === x
            );
            if (!(x instanceof Date) || (tickValue && !tickValue.show)) {
              return "";
            }
            return moment(x).format("DD/MM");
          }}
        />

        {data.map(({ id, dataColor, lineData }, index) => (
          <VictoryArea
            key={index}
            interpolation="monotoneX"
            data={lineData}
            labelComponent={
              <VictoryTooltip
                cornerRadius={5}
                flyoutStyle={{
                  stroke: false,
                  fill: color.greyLighter,
                }}
              />
            }
            labels={({ datum }: { datum: Record<string, unknown> }) =>
              `${moment(datum.x).format("DD MMM")}\n${id}: ${tickFormatY(
                datum.y
              )}`
            }
            style={{
              data: {
                fill: index == 0 ? "url(#gradientStroke)" : "transparent",
                stroke: dataColor,
                strokeWidth: dataStrokeWidth,
              },
            }}
          />
        ))}
      </VictoryChart>
    </View>
  );
};
export default LineChart;
