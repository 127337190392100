module["exports"] = {
  "descriptor": [
    "Ведущий",
    "Генеральный",
    "Главный",
    "Глобальный",
    "Международный",
    "Национальный",
    "Областной",
    "Районный",
    "Региональный",
    "Центральный"
  ],
  "level": [
    "интеграционный",
    "логистический",
    "маркетинговый",
    "оптимизационный",
    "страховой",
    "функциональный",
    "коммуникационный",
    "операционный"
  ],
  "job": [
    "агент",
    "администратор",
    "аналитик",
    "архитектор",
    "дизайнер",
    "инженер",
    "консультант",
    "координатор",
    "менеджер",
    "планировщик",
    "помощник",
    "разработчик",
    "руководитель",
    "сотрудник",
    "специалист",
    "техник"
  ]
};
