module["exports"] = [
  "Alessandro",
  "Alexandre",
  "Anthony",
  "Antônio",
  "Arthur",
  "Benjamin",
  "Benício",
  "Bernardo",
  "Breno",
  "Bryan",
  "Caio",
  "Calebe",
  "Carlos",
  "Cauã",
  "César",
  "Daniel",
  "Danilo",
  "Davi",
  "Davi Lucca",
  "Deneval",
  "Eduardo",
  "Elísio",
  "Emanuel",
  "Enzo",
  "Enzo Gabriel",
  "Fabiano",
  "Fabrício",
  "Feliciano",
  "Felipe",
  "Frederico",
  "Fábio",
  "Félix",
  "Gabriel",
  "Gael",
  "Guilherme",
  "Gustavo",
  "Gúbio",
  "Heitor",
  "Henrique",
  "Hugo",
  "Hélio",
  "Isaac",
  "Joaquim",
  "João",
  "João Lucas",
  "João Miguel",
  "João Pedro",
  "Júlio",
  "Júlio César",
  "Kléber",
  "Ladislau",
  "Leonardo",
  "Lorenzo",
  "Lucas",
  "Lucca",
  "Marcelo",
  "Marcos",
  "Matheus",
  "Miguel",
  "Murilo",
  "Nataniel",
  "Nicolas",
  "Noah",
  "Norberto",
  "Pablo",
  "Paulo",
  "Pedro",
  "Pedro Henrique",
  "Pietro",
  "Rafael",
  "Raul",
  "Ricardo",
  "Roberto",
  "Salvador",
  "Samuel",
  "Silas",
  "Sirineu",
  "Tertuliano",
  "Théo",
  "Vicente",
  "Vitor",
  "Víctor",
  "Warley",
  "Washington",
  "Yago",
  "Yango",
  "Yuri",
  "Ígor"
];