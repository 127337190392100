module["exports"] = [
  "Adi",
  "Ado",
  "Andel",
  "Andelin",
  "Andelko",
  "Andelo",
  "Andi",
  "Andras",
  "Andrej",
  "Andrija",
  "Andro",
  "Anko",
  "Ante",
  "Antek",
  "Antonin",
  "Antonio",
  "Antun",
  "Bela",
  "Belimir",
  "Berti",
  "Boris",
  "Borko",
  "Borna",
  "Božidar",
  "Branimir",
  "Bruno",
  "Časlav",
  "Dado",
  "Damil",
  "Damir",
  "Danijel",
  "Dario",
  "Darko",
  "David",
  "Davor",
  "Davorin",
  "Domagoj",
  "Donat",
  "Dražen",
  "Držimir",
  "Držislav",
  "Dubravko",
  "Duje",
  "Dujo",
  "Emil",
  "Emilijan",
  "Erik",
  "Fabijan",
  "Filip",
  "Franjo",
  "Gaj",
  "Gašpar",
  "Goran",
  "Gordan",
  "Gradimir",
  "Grga",
  "Grgo",
  "Grgur",
  "Gvozden",
  "Hrvoj",
  "Hrvoje",
  "Ico",
  "Igor",
  "Ilija",
  "Ilko",
  "Ino",
  "Ivan",
  "Ive",
  "Ivek",
  "Ivica",
  "Ivo",
  "Jadran",
  "Jadranko",
  "Jadro",
  "Jakov",
  "Jan",
  "Janko",
  "Jasen",
  "Jasenko",
  "Josip",
  "Joško",
  "Joso",
  "Joža",
  "Jože",
  "Krešimir",
  "Kristijan",
  "Kruno",
  "Krunoslav",
  "Ladislav",
  "Ljudevit",
  "Lucijan",
  "Luka",
  "Marin",
  "Marko",
  "Martin",
  "Matan",
  "Mate",
  "Matej",
  "Matija",
  "Matko",
  "Mato",
  "Mihael",
  "Milan",
  "Mile",
  "Milivoj",
  "Miljenko",
  "Mimo",
  "Miran",
  "Mirko",
  "Miro",
  "Mislav",
  "Mladen",
  "Nedjeljko",
  "Neven",
  "Nikola",
  "Nino",
  "Ozren",
  "Pavel",
  "Pepo",
  "Pero",
  "Petar",
  "Pravdan",
  "Rino",
  "Robert",
  "Roberto",
  "Robi",
  "Roko",
  "Sanjin",
  "Saša",
  "Slavek",
  "Slaven",
  "Slavko",
  "Srećko",
  "Stanko",
  "Štef",
  "Stipan",
  "Stipe",
  "Stjepan",
  "Stribor",
  "Svebor",
  "Tihomir",
  "Toma",
  "Tome",
  "Tomislav",
  "Tomo",
  "Tonči",
  "Tone",
  "Toni",
  "Tonko",
  "Trpimir",
  "Trpko",
  "Tvrtko",
  "Valentin",
  "Vanja",
  "Vatroslav",
  "Vedran",
  "Velimir",
  "Vijeko",
  "Vjekoslav",
  "Vjenceslav",
  "Vladek",
  "Vladislav",
  "Vlado",
  "Vlatko",
  "Zdenko",
  "Zdravko",
  "Željko",
  "Zoltan",
  "Zrinislav",
  "Zrinko",
  "Zvonimir",

];
