module["exports"] = [
  'Abaco',
  'Abbondanzio',
  'Abbondio',
  'Abdone',
  'Abelardo',
  'Abele',
  'Abenzio',
  'Abibo',
  'Abramio',
  'Abramo',
  'Acacio',
  'Acario',
  'Accursio',
  'Achille',
  'Acilio',
  'Aciscolo',
  'Acrisio',
  'Adalardo',
  'Adalberto',
  'Adalfredo',
  'Adalgiso',
  'Adalrico',
  'Adamo',
  'Addo',
  'Adelardo',
  'Adelberto',
  'Adelchi',
  'Adelfo',
  'Adelgardo',
  'Adelmo',
  'Adeodato',
  'Adolfo',
  'Adone',
  'Adriano',
  'Adrione',
  'Afro',
  'Agabio',
  'Agamennone',
  'Agapito',
  'Agazio',
  'Agenore',
  'Agesilao',
  'Agostino',
  'Agrippa',
  'Aiace',
  'Aidano',
  'Aimone',
  'Aladino',
  'Alamanno',
  'Alano',
  'Alarico',
  'Albano',
  'Alberico',
  'Alberto',
  'Albino',
  'Alboino',
  'Albrico',
  'Alceo',
  'Alceste',
  'Alcibiade',
  'Alcide',
  'Alcino',
  'Aldo',
  'Aldobrando',
  'Aleandro',
  'Aleardo',
  'Aleramo',
  'Alessandro',
  'Alessio',
  'Alfio',
  'Alfonso',
  'Alfredo',
  'Algiso',
  'Alighiero',
  'Almerigo',
  'Almiro',
  'Aloisio',
  'Alvaro',
  'Alviero',
  'Alvise',
  'Amabile',
  'Amadeo',
  'Amando',
  'Amanzio',
  'Amaranto',
  'Amato',
  'Amatore',
  'Amauri',
  'Ambrogio',
  'Ambrosiano',
  'Amedeo',
  'Amelio',
  'Amerigo',
  'Amico',
  'Amilcare',
  'Amintore',
  'Amleto',
  'Amone',
  'Amore',
  'Amos',
  'Ampelio',
  'Anacleto',
  'Andrea',
  'Angelo',
  'Aniceto',
  'Aniello',
  'Annibale',
  'Ansaldo',
  'Anselmo',
  'Ansovino',
  'Antelmo',
  'Antero',
  'Antimo',
  'Antino',
  'Antioco',
  'Antonello',
  'Antonio',
  'Apollinare',
  'Apollo',
  'Apuleio',
  'Aquilino',
  'Araldo',
  'Aratone',
  'Arcadio',
  'Archimede',
  'Archippo',
  'Arcibaldo',
  'Ardito',
  'Arduino',
  'Aresio',
  'Argimiro',
  'Argo',
  'Arialdo',
  'Ariberto',
  'Ariele',
  'Ariosto',
  'Aris',
  'Aristarco',
  'Aristeo',
  'Aristide',
  'Aristione',
  'Aristo',
  'Aristofane',
  'Aristotele',
  'Armando',
  'Arminio',
  'Arnaldo',
  'Aronne',
  'Arrigo',
  'Arturo',
  'Ascanio',
  'Asdrubale',
  'Asimodeo',
  'Assunto',
  'Asterio',
  'Astianatte',
  'Ataleo',
  'Atanasio',
  'Athos',
  'Attila',
  'Attilano',
  'Attilio',
  'Auberto',
  'Audace',
  'Augusto',
  'Aureliano',
  'Aurelio',
  'Auro',
  'Ausilio',
  'Averardo',
  'Azeglio',
  'Azelio',
  'Bacco',
  'Baldassarre',
  'Balderico',
  'Baldo',
  'Baldomero',
  'Baldovino',
  'Barbarigo',
  'Bardo',
  'Bardomiano',
  'Barnaba',
  'Barsaba',
  'Barsimeo',
  'Bartolo',
  'Bartolomeo',
  'Basileo',
  'Basilio',
  'Bassiano',
  'Bastiano',
  'Battista',
  'Beato',
  'Bellino',
  'Beltramo',
  'Benedetto',
  'Beniamino',
  'Benigno',
  'Benito',
  'Benvenuto',
  'Berardo',
  'Berengario',
  'Bernardo',
  'Beronico',
  'Bertoldo',
  'Bertolfo',
  'Biagio',
  'Bibiano',
  'Bindo',
  'Bino',
  'Birino',
  'Bonagiunta',
  'Bonaldo',
  'Bonaventura',
  'Bonavita',
  'Bonifacio',
  'Bonito',
  'Boris',
  'Bortolo',
  'Brancaleone',
  'Brando',
  'Bruno',
  'Bruto',
  'Caino',
  'Caio',
  'Calanico',
  'Calcedonio',
  'Callisto',
  'Calogero',
  'Camillo',
  'Candido',
  'Cantidio',
  'Canziano',
  'Carlo',
  'Carmelo',
  'Carmine',
  'Caronte',
  'Carponio',
  'Casimiro',
  'Cassiano',
  'Cassio',
  'Casto',
  'Cataldo',
  'Catullo',
  'Cecco',
  'Cecilio',
  'Celso',
  'Cesare',
  'Cesario',
  'Cherubino',
  'Chiaffredo',
  'Cino',
  'Cipriano',
  'Cirano',
  'Ciriaco',
  'Cirillo',
  'Cirino',
  'Ciro',
  'Clarenzio',
  'Claudio',
  'Cleandro',
  'Clemente',
  'Cleonico',
  'Climaco',
  'Clinio',
  'Clodomiro',
  'Clodoveo',
  'Colmanno',
  'Colmazio',
  'Colombano',
  'Colombo',
  'Concetto',
  'Concordio',
  'Corbiniano',
  'Coreno',
  'Coriolano',
  'Cornelio',
  'Coronato',
  'Corrado',
  'Cosimo',
  'Cosma',
  'Costante',
  'Costantino',
  'Costanzo',
  'Cremenzio',
  'Crescente',
  'Crescenzio',
  'Crespignano',
  'Crispino',
  'Cristaldo',
  'Cristiano',
  'Cristoforo',
  'Crocefisso',
  'Cuniberto',
  'Cupido',
  'Daciano',
  'Dacio',
  'Dagoberto',
  'Dalmazio',
  'Damaso',
  'Damiano',
  'Damocle',
  'Daniele',
  'Danilo',
  'Danio',
  'Dante',
  'Dario',
  'Davide',
  'Davino',
  'Decimo',
  'Delfino',
  'Demetrio',
  'Democrito',
  'Demostene',
  'Deodato',
  'Desiderato',
  'Desiderio',
  'Didimo',
  'Diego',
  'Dino',
  'Diocleziano',
  'Diodoro',
  'Diogene',
  'Diomede',
  'Dione',
  'Dionigi',
  'Dionisio',
  'Divo',
  'Dodato',
  'Domenico',
  'Domezio',
  'Domiziano',
  'Donatello',
  'Donato',
  'Doriano',
  'Doroteo',
  'Duccio',
  'Duilio',
  'Durante',
  'Eberardo',
  'Ecclesio',
  'Edgardo',
  'Edilberto',
  'Edmondo',
  'Edoardo',
  'Efisio',
  'Efrem',
  'Egeo',
  'Egidio',
  'Eginardo',
  'Egisto',
  'Eleuterio',
  'Elia',
  'Eliano',
  'Elifio',
  'Eligio',
  'Elio',
  'Eliodoro',
  'Eliseo',
  'Elita',
  'Elmo',
  'Elogio',
  'Elpidio',
  'Elvezio',
  'Elvino',
  'Emanuele',
  'Emidio',
  'Emiliano',
  'Emilio',
  'Emmerico',
  'Empirio',
  'Endrigo',
  'Enea',
  'Enecone',
  'Ennio',
  'Enrico',
  'Enzo',
  'Eraclide',
  'Eraldo',
  'Erardo',
  'Erasmo',
  'Erberto',
  'Ercolano',
  'Ercole',
  'Erenia',
  'Eriberto',
  'Erico',
  'Ermanno',
  'Ermenegildo',
  'Ermes',
  'Ermete',
  'Ermilo',
  'Erminio',
  'Ernesto',
  'Eros',
  'Esa�',
  'Esuperio',
  'Eterie',
  'Ettore',
  'Euclide',
  'Eufebio',
  'Eufemio',
  'Eufronio',
  'Eugenio',
  'Eusebio',
  'Euseo',
  'Eustorgio',
  'Eustosio',
  'Eutalio',
  'Evaldo',
  'Evandro',
  'Evaristo',
  'Evasio',
  'Everardo',
  'Evidio',
  'Evodio',
  'Evremondo',
  'Ezechiele',
  'Ezio',
  'Fabiano',
  'Fabio',
  'Fabrizio',
  'Famiano',
  'Fausto',
  'Fazio',
  'Fedele',
  'Federico',
  'Fedro',
  'Felice',
  'Feliciano',
  'Ferdinando',
  'Fermiano',
  'Fermo',
  'Fernando',
  'Ferruccio',
  'Festo',
  'Fidenziano',
  'Fidenzio',
  'Filiberto',
  'Filippo',
  'Filomeno',
  'Fiorenziano',
  'Fiorenzo',
  'Flaviano',
  'Flavio',
  'Fleano',
  'Floriano',
  'Folco',
  'Fortunato',
  'Fosco',
  'Francesco',
  'Franco',
  'Frido',
  'Frontiniano',
  'Fulberto',
  'Fulgenzio',
  'Fulvio',
  'Furio',
  'Furseo',
  'Fuscolo',
  'Gabino',
  'Gabriele',
  'Gaetano',
  'Gaglioffo',
  'Gaio',
  'Galdino',
  'Galeazzo',
  'Galileo',
  'Gallicano',
  'Gandolfo',
  'Garimberto',
  'Gaspare',
  'Gastone',
  'Gaudenzio',
  'Gaudino',
  'Gautiero',
  'Gavino',
  'Gedeone',
  'Geminiano',
  'Generoso',
  'Genesio',
  'Gennaro',
  'Gentile',
  'Genziano',
  'Gerardo',
  'Gerasimo',
  'Geremia',
  'Gerino',
  'Germano',
  'Gerolamo',
  'Geronimo',
  'Geronzio',
  'Gervasio',
  'Gesualdo',
  'Gherardo',
  'Giacinto',
  'Giacobbe',
  'Giacomo',
  'Giadero',
  'Giambattista',
  'Gianbattista',
  'Giancarlo',
  'Giandomenico',
  'Gianfranco',
  'Gianluca',
  'Gianluigi',
  'Gianmarco',
  'Gianmaria',
  'Gianmario',
  'Gianni',
  'Gianpaolo',
  'Gianpiero',
  'Gianpietro',
  'Gianuario',
  'Giasone',
  'Gilberto',
  'Gildo',
  'Gillo',
  'Gineto',
  'Gino',
  'Gioacchino',
  'Giobbe',
  'Gioberto',
  'Giocondo',
  'Gioele',
  'Giona',
  'Gionata',
  'Giordano',
  'Giorgio',
  'Giosu�',
  'Giosuele',
  'Giotto',
  'Giovanni',
  'Giove',
  'Gioventino',
  'Giovenzio',
  'Girardo',
  'Girolamo',
  'Giuda',
  'Giuliano',
  'Giulio',
  'Giuseppe',
  'Giustiniano',
  'Giusto',
  'Glauco',
  'Goffredo',
  'Golia',
  'Gomberto',
  'Gondulfo',
  'Gonerio',
  'Gonzaga',
  'Gordiano',
  'Gosto',
  'Gottardo',
  'Graciliano',
  'Grato',
  'Graziano',
  'Gregorio',
  'Grimaldo',
  'Gualberto',
  'Gualtiero',
  'Guelfo',
  'Guerrino',
  'Guglielmo',
  'Guiberto',
  'Guido',
  'Guiscardo',
  'Gumesindo',
  'Gustavo',
  'Iacopo',
  'Iacopone',
  'Iago',
  'Icaro',
  'Icilio',
  'Ido',
  'Iginio',
  'Igino',
  'Ignazio',
  'Igor',
  'Ilario',
  'Ildebrando',
  'Ildefonso',
  'Illidio',
  'Illuminato',
  'Immacolato',
  'Indro',
  'Innocente',
  'Innocenzo',
  'Iorio',
  'Ippocrate',
  'Ippolito',
  'Ireneo',
  'Isacco',
  'Isaia',
  'Ischirione',
  'Isidoro',
  'Ismaele',
  'Italo',
  'Ivan',
  'Ivano',
  'Ivanoe',
  'Ivo',
  'Ivone',
  'Ladislao',
  'Lamberto',
  'Lancilotto',
  'Landolfo',
  'Lanfranco',
  'Lapo',
  'Laurentino',
  'Lauriano',
  'Lautone',
  'Lavinio',
  'Lazzaro',
  'Leandro',
  'Leo',
  'Leonardo',
  'Leone',
  'Leonida',
  'Leonio',
  'Leonzio',
  'Leopardo',
  'Leopoldo',
  'Letterio',
  'Liberato',
  'Liberatore',
  'Liberio',
  'Libero',
  'Liberto',
  'Liborio',
  'Lidio',
  'Lieto',
  'Lino',
  'Lisandro',
  'Livino',
  'Livio',
  'Lodovico',
  'Loreno',
  'Lorenzo',
  'Loris',
  'Luca',
  'Luciano',
  'Lucio',
  'Ludano',
  'Ludovico',
  'Luigi',
  'Macario',
  'Maccabeo',
  'Maffeo',
  'Maggiorino',
  'Magno',
  'Maiorico',
  'Malco',
  'Mamante',
  'Mancio',
  'Manetto',
  'Manfredo',
  'Manilio',
  'Manlio',
  'Mansueto',
  'Manuele',
  'Marcello',
  'Marciano',
  'Marco',
  'Mariano',
  'Marino',
  'Mario',
  'Marolo',
  'Martino',
  'Marzio',
  'Massimiliano',
  'Massimo',
  'Matroniano',
  'Matteo',
  'Mattia',
  'Maurilio',
  'Maurizio',
  'Mauro',
  'Medardo',
  'Medoro',
  'Melanio',
  'Melchiade',
  'Melchiorre',
  'Melezio',
  'Menardo',
  'Menelao',
  'Meneo',
  'Mennone',
  'Mercurio',
  'Metello',
  'Metrofane',
  'Michelangelo',
  'Michele',
  'Milo',
  'Minervino',
  'Mirco',
  'Mirko',
  'Mirocleto',
  'Misaele',
  'Modesto',
  'Monaldo',
  'Monitore',
  'Moreno',
  'Mos�',
  'Muziano',
  'Namazio',
  'Napoleone',
  'Narciso',
  'Narseo',
  'Narsete',
  'Natale',
  'Nazario',
  'Nazzareno',
  'Nazzaro',
  'Neopolo',
  'Neoterio',
  'Nereo',
  'Neri',
  'Nestore',
  'Nicarete',
  'Nicea',
  'Niceforo',
  'Niceto',
  'Nicezio',
  'Nico',
  'Nicodemo',
  'Nicola',
  'Nicol�',
  'Niniano',
  'Nino',
  'No�',
  'Norberto',
  'Nostriano',
  'Nunzio',
  'Oddone',
  'Oderico',
  'Odidone',
  'Odorico',
  'Olimpio',
  'Olindo',
  'Oliviero',
  'Omar',
  'Omero',
  'Onesto',
  'Onofrio',
  'Onorino',
  'Onorio',
  'Orazio',
  'Orenzio',
  'Oreste',
  'Orfeo',
  'Orio',
  'Orlando',
  'Oronzo',
  'Orsino',
  'Orso',
  'Ortensio',
  'Oscar',
  'Osmondo',
  'Osvaldo',
  'Otello',
  'Ottaviano',
  'Ottavio',
  'Ottone',
  'Ovidio',
  'Paciano',
  'Pacifico',
  'Pacomio',
  'Palatino',
  'Palladio',
  'Pammachio',
  'Pancario',
  'Pancrazio',
  'Panfilo',
  'Pantaleo',
  'Pantaleone',
  'Paolo',
  'Pardo',
  'Paride',
  'Parmenio',
  'Pasquale',
  'Paterniano',
  'Patrizio',
  'Patroclo',
  'Pauside',
  'Peleo',
  'Pellegrino',
  'Pericle',
  'Perseo',
  'Petronio',
  'Pierangelo',
  'Piergiorgio',
  'Pierluigi',
  'Piermarco',
  'Piero',
  'Piersilvio',
  'Pietro',
  'Pio',
  'Pippo',
  'Placido',
  'Platone',
  'Plinio',
  'Plutarco',
  'Polidoro',
  'Polifemo',
  'Pollione',
  'Pompeo',
  'Pomponio',
  'Ponziano',
  'Ponzio',
  'Porfirio',
  'Porziano',
  'Postumio',
  'Prassede',
  'Priamo',
  'Primo',
  'Prisco',
  'Privato',
  'Procopio',
  'Prospero',
  'Protasio',
  'Proteo',
  'Prudenzio',
  'Publio',
  'Pupolo',
  'Pusicio',
  'Quarto',
  'Quasimodo',
  'Querano',
  'Quintiliano',
  'Quintilio',
  'Quintino',
  'Quinziano',
  'Quinzio',
  'Quirino',
  'Radolfo',
  'Raffaele',
  'Raide',
  'Raimondo',
  'Rainaldo',
  'Ramiro',
  'Raniero',
  'Ranolfo',
  'Reginaldo',
  'Regolo',
  'Remigio',
  'Remo',
  'Remondo',
  'Renato',
  'Renzo',
  'Respicio',
  'Ricario',
  'Riccardo',
  'Richelmo',
  'Rinaldo',
  'Rino',
  'Robaldo',
  'Roberto',
  'Rocco',
  'Rodiano',
  'Rodolfo',
  'Rodrigo',
  'Rolando',
  'Rolfo',
  'Romano',
  'Romeo',
  'Romero',
  'Romoaldo',
  'Romolo',
  'Romualdo',
  'Rosario',
  'Rubiano',
  'Rufino',
  'Rufo',
  'Ruggero',
  'Ruperto',
  'Rutilo',
  'Sabato',
  'Sabazio',
  'Sabele',
  'Sabino',
  'Saffiro',
  'Saffo',
  'Saladino',
  'Salom�',
  'Salomone',
  'Salustio',
  'Salvatore',
  'Salvo',
  'Samuele',
  'Sandro',
  'Sansone',
  'Sante',
  'Santo',
  'Sapiente',
  'Sarbello',
  'Saturniano',
  'Saturnino',
  'Saul',
  'Saverio',
  'Savino',
  'Sebastiano',
  'Secondiano',
  'Secondo',
  'Semplicio',
  'Sempronio',
  'Senesio',
  'Senofonte',
  'Serafino',
  'Serapione',
  'Sergio',
  'Servidio',
  'Serviliano',
  'Sesto',
  'Settimio',
  'Settimo',
  'Severiano',
  'Severino',
  'Severo',
  'Sico',
  'Sicuro',
  'Sidonio',
  'Sigfrido',
  'Sigismondo',
  'Silvano',
  'Silverio',
  'Silvestro',
  'Silvio',
  'Simeone',
  'Simone',
  'Sinesio',
  'Sinfronio',
  'Sireno',
  'Siriano',
  'Siricio',
  'Sirio',
  'Siro',
  'Sisto',
  'Soccorso',
  'Socrate',
  'Solocone',
  'Sostene',
  'Sosteneo',
  'Sostrato',
  'Spano',
  'Spartaco',
  'Speranzio',
  'Stanislao',
  'Stefano',
  'Stiliano',
  'Stiriaco',
  'Surano',
  'Sviturno',
  'Taddeo',
  'Taide',
  'Tammaro',
  'Tancredi',
  'Tarcisio',
  'Tarso',
  'Taziano',
  'Tazio',
  'Telchide',
  'Telemaco',
  'Temistocle',
  'Teobaldo',
  'Teodoro',
  'Teodosio',
  'Teodoto',
  'Teogene',
  'Terenzio',
  'Terzo',
  'Tesauro',
  'Tesifonte',
  'Tibaldo',
  'Tiberio',
  'Tiburzio',
  'Ticone',
  'Timoteo',
  'Tirone',
  'Tito',
  'Tiziano',
  'Tizio',
  'Tobia',
  'Tolomeo',
  'Tommaso',
  'Torquato',
  'Tosco',
  'Tranquillo',
  'Tristano',
  'Tulliano',
  'Tullio',
  'Turi',
  'Turibio',
  'Tussio',
  'Ubaldo',
  'Ubertino',
  'Uberto',
  'Ugo',
  'Ugolino',
  'Uguccione',
  'Ulberto',
  'Ulderico',
  'Ulfo',
  'Ulisse',
  'Ulpiano',
  'Ulrico',
  'Ulstano',
  'Ultimo',
  'Umberto',
  'Umile',
  'Uranio',
  'Urbano',
  'Urdino',
  'Uriele',
  'Ursicio',
  'Ursino',
  'Ursmaro',
  'Valente',
  'Valentino',
  'Valeriano',
  'Valerico',
  'Valerio',
  'Valfredo',
  'Valfrido',
  'Valtena',
  'Valter',
  'Varo',
  'Vasco',
  'Vedasto',
  'Velio',
  'Venanzio',
  'Venceslao',
  'Venerando',
  'Venerio',
  'Ventura',
  'Venustiano',
  'Venusto',
  'Verano',
  'Verecondo',
  'Verenzio',
  'Verulo',
  'Vespasiano',
  'Vezio',
  'Vidiano',
  'Vidone',
  'Vilfredo',
  'Viliberto',
  'Vincenzo',
  'Vindonio',
  'Vinebaldo',
  'Vinfrido',
  'Vinicio',
  'Virgilio',
  'Virginio',
  'Virone',
  'Viscardo',
  'Vitale',
  'Vitalico',
  'Vito',
  'Vittore',
  'Vittoriano',
  'Vittorio',
  'Vivaldo',
  'Viviano',
  'Vladimiro',
  'Vodingo',
  'Volfango',
  'Vulmaro',
  'Vulpiano',
  'Walter',
  'Zabedeo',
  'Zaccaria',
  'Zaccheo',
  'Zanobi',
  'Zefiro',
  'Zena',
  'Zenaide',
  'Zenebio',
  'Zeno',
  'Zenobio',
  'Zenone',
  'Zetico',
  'Zoilo',
  'Zosimo',
  'Abbondanza',
  'Acilia',
  'Ada',
  'Adalberta',
  'Adalgisa',
  'Addolorata',
  'Adelaide',
  'Adelasia',
  'Adele',
  'Adelina',
  'Adina',
  'Adria',
  'Adriana',
  'Agape',
  'Agata',
  'Agnese',
  'Agostina',
  'Aida',
  'Alba',
  'Alberta',
  'Albina',
  'Alcina',
  'Alda',
  'Alessandra',
  'Alessia',
  'Alfonsa',
  'Alfreda',
  'Alice',
  'Alida',
  'Alina',
  'Allegra',
  'Alma',
  'Altea',
  'Amalia',
  'Amanda',
  'Amata',
  'Ambra',
  'Amelia',
  'Amina',
  'Anastasia',
  'Anatolia',
  'Ancilla',
  'Andromeda',
  'Angela',
  'Angelica',
  'Anita',
  'Anna',
  'Annabella',
  'Annagrazia',
  'Annamaria',
  'Annunziata',
  'Antea',
  'Antigone',
  'Antonella',
  'Antonia',
  'Apollina',
  'Apollonia',
  'Appia',
  'Arabella',
  'Argelia',
  'Arianna',
  'Armida',
  'Artemisa',
  'Asella',
  'Asia',
  'Assunta',
  'Astrid',
  'Atanasia',
  'Aurelia',
  'Aurora',
  'Ausilia',
  'Ausiliatrice',
  'Ave',
  'Aza',
  'Azelia',
  'Azzurra',
  'Babila',
  'Bambina',
  'Barbara',
  'Bartolomea',
  'Basilia',
  'Bassilla',
  'Batilda',
  'Beata',
  'Beatrice',
  'Belina',
  'Benedetta',
  'Beniamina',
  'Benigna',
  'Benvenuta',
  'Berenice',
  'Bernadetta',
  'Betta',
  'Bianca',
  'Bibiana',
  'Bice',
  'Brigida',
  'Brigitta',
  'Bruna',
  'Brunilde',
  'Calogera',
  'Calpurnia',
  'Camelia',
  'Camilla',
  'Candida',
  'Capitolina',
  'Carina',
  'Carla',
  'Carlotta',
  'Carmela',
  'Carmen',
  'Carola',
  'Carolina',
  'Casilda',
  'Casimira',
  'Cassandra',
  'Cassiopea',
  'Catena',
  'Caterina',
  'Cecilia',
  'Celeste',
  'Celinia',
  'Chiara',
  'Cinzia',
  'Cirilla',
  'Clara',
  'Claudia',
  'Clelia',
  'Clemenzia',
  'Cleo',
  'Cleofe',
  'Cleopatra',
  'Cloe',
  'Clorinda',
  'Cointa',
  'Colomba',
  'Concetta',
  'Consolata',
  'Cora',
  'Cordelia',
  'Corinna',
  'Cornelia',
  'Cosima',
  'Costanza',
  'Crescenzia',
  'Cristiana',
  'Cristina',
  'Crocefissa',
  'Cronida',
  'Cunegonda',
  'Cuzia',
  'Dafne',
  'Dalida',
  'Dalila',
  'Damiana',
  'Daniela',
  'Daria',
  'Deanna',
  'Debora',
  'Degna',
  'Delfina',
  'Delia',
  'Delinda',
  'Delizia',
  'Demetria',
  'Deodata',
  'Desdemona',
  'Desiderata',
  'Devota',
  'Diamante',
  'Diana',
  'Dianora',
  'Diletta',
  'Dina',
  'Diodata',
  'Dionisia',
  'Doda',
  'Dolores',
  'Domenica',
  'Donata',
  'Donatella',
  'Donna',
  'Dora',
  'Dorotea',
  'Druina',
  'Dulina',
  'Ebe',
  'Edda',
  'Edelberga',
  'Editta',
  'Edvige',
  'Egizia',
  'Egle',
  'Elaide',
  'Elda',
  'Elena',
  'Eleonora',
  'Elettra',
  'Eliana',
  'Elide',
  'Elimena',
  'Elisa',
  'Elisabetta',
  'Elisea',
  'Ella',
  'Eloisa',
  'Elsa',
  'Elvia',
  'Elvira',
  'Emanuela',
  'Emilia',
  'Emiliana',
  'Emma',
  'Enimia',
  'Enrica',
  'Eracla',
  'Ermelinda',
  'Ermenegarda',
  'Ermenegilda',
  'Erminia',
  'Ernesta',
  'Ersilia',
  'Esmeralda',
  'Estella',
  'Ester',
  'Esterina',
  'Eufemia',
  'Eufrasia',
  'Eugenia',
  'Eulalia',
  'Euridice',
  'Eusebia',
  'Eutalia',
  'Eva',
  'Evangelina',
  'Evelina',
  'Fabiana',
  'Fabiola',
  'Fatima',
  'Fausta',
  'Federica',
  'Fedora',
  'Felicia',
  'Felicita',
  'Fernanda',
  'Fiammetta',
  'Filippa',
  'Filomena',
  'Fiordaliso',
  'Fiore',
  'Fiorella',
  'Fiorenza',
  'Flaminia',
  'Flavia',
  'Flaviana',
  'Flora',
  'Floriana',
  'Floridia',
  'Florina',
  'Foca',
  'Fortunata',
  'Fosca',
  'Franca',
  'Francesca',
  'Fulvia',
  'Gabriella',
  'Gaia',
  'Galatea',
  'Gaudenzia',
  'Gelsomina',
  'Geltrude',
  'Gemma',
  'Generosa',
  'Genesia',
  'Genoveffa',
  'Germana',
  'Gertrude',
  'Ghita',
  'Giacinta',
  'Giada',
  'Gigliola',
  'Gilda',
  'Giliola',
  'Ginevra',
  'Gioacchina',
  'Gioconda',
  'Gioia',
  'Giorgia',
  'Giovanna',
  'Gisella',
  'Giuditta',
  'Giulia',
  'Giuliana',
  'Giulitta',
  'Giuseppa',
  'Giuseppina',
  'Giusta',
  'Glenda',
  'Gloria',
  'Godeberta',
  'Godiva',
  'Grazia',
  'Graziana',
  'Graziella',
  'Greta',
  'Griselda',
  'Guenda',
  'Guendalina',
  'Gundelinda',
  'Ianira',
  'Ida',
  'Idea',
  'Iginia',
  'Ilaria',
  'Ilda',
  'Ildegarda',
  'Ildegonda',
  'Ileana',
  'Ilenia',
  'Ilia',
  'Ilva',
  'Imelda',
  'Immacolata',
  'Incoronata',
  'Ines',
  'Innocenza',
  'Iolanda',
  'Iole',
  'Iona',
  'Ione',
  'Ionne',
  'Irene',
  'Iride',
  'Iris',
  'Irma',
  'Irmina',
  'Isa',
  'Isabella',
  'Iside',
  'Isidora',
  'Isotta',
  'Italia',
  'Ivetta',
  'Lara',
  'Laura',
  'Lavinia',
  'Lea',
  'Leda',
  'Lelia',
  'Lena',
  'Leonia',
  'Leonilda',
  'Leontina',
  'Letizia',
  'Lia',
  'Liana',
  'Liberata',
  'Liboria',
  'Licia',
  'Lidania',
  'Lidia',
  'Liliana',
  'Linda',
  'Lisa',
  'Livia',
  'Liviana',
  'Lodovica',
  'Loredana',
  'Lorella',
  'Lorena',
  'Lorenza',
  'Loretta',
  'Loriana',
  'Luana',
  'Luce',
  'Lucia',
  'Luciana',
  'Lucilla',
  'Lucrezia',
  'Ludovica',
  'Luigia',
  'Luisa',
  'Luminosa',
  'Luna',
  'Macaria',
  'Maddalena',
  'Mafalda',
  'Magda',
  'Maida',
  'Manuela',
  'Mara',
  'Marana',
  'Marcella',
  'Mareta',
  'Margherita',
  'Maria',
  'Marianna',
  'Marica',
  'Mariella',
  'Marilena',
  'Marina',
  'Marinella',
  'Marinetta',
  'Marisa',
  'Marita',
  'Marta',
  'Martina',
  'Maruta',
  'Marzia',
  'Massima',
  'Matilde',
  'Maura',
  'Melania',
  'Melissa',
  'Melitina',
  'Menodora',
  'Mercede',
  'Messalina',
  'Mia',
  'Michela',
  'Milena',
  'Mimma',
  'Mina',
  'Minerva',
  'Minervina',
  'Miranda',
  'Mirella',
  'Miriam',
  'Mirta',
  'Moira',
  'Monica',
  'Morena',
  'Morgana',
  'Nadia',
  'Natalia',
  'Natalina',
  'Neiva',
  'Nerea',
  'Nicla',
  'Nicoletta',
  'Nilde',
  'Nina',
  'Ninfa',
  'Nives',
  'Noemi',
  'Norina',
  'Norma',
  'Novella',
  'Nuccia',
  'Nunziata',
  'Odetta',
  'Odilia',
  'Ofelia',
  'Olga',
  'Olimpia',
  'Olinda',
  'Olivia',
  'Oliviera',
  'Ombretta',
  'Ondina',
  'Onesta',
  'Onorata',
  'Onorina',
  'Orchidea',
  'Oriana',
  'Orietta',
  'Ornella',
  'Orsola',
  'Orsolina',
  'Ortensia',
  'Osanna',
  'Otilia',
  'Ottilia',
  'Palladia',
  'Palmazio',
  'Palmira',
  'Pamela',
  'Paola',
  'Patrizia',
  'Pelagia',
  'Penelope',
  'Perla',
  'Petronilla',
  'Pia',
  'Piera',
  'Placida',
  'Polissena',
  'Porzia',
  'Prisca',
  'Priscilla',
  'Proserpina',
  'Prospera',
  'Prudenzia',
  'Quartilla',
  'Quieta',
  'Quiteria',
  'Rachele',
  'Raffaella',
  'Rainelda',
  'Rebecca',
  'Regina',
  'Renata',
  'Riccarda',
  'Rina',
  'Rita',
  'Roberta',
  'Romana',
  'Romilda',
  'Romina',
  'Romola',
  'Rosa',
  'Rosalia',
  'Rosalinda',
  'Rosamunda',
  'Rosanna',
  'Rosita',
  'Rosmunda',
  'Rossana',
  'Rossella',
  'Rufina',
  'Saba',
  'Sabina',
  'Sabrina',
  'Samanta',
  'Samona',
  'Sandra',
  'Santina',
  'Sara',
  'Savina',
  'Scolastica',
  'Sebastiana',
  'Seconda',
  'Secondina',
  'Sefora',
  'Selene',
  'Selvaggia',
  'Semiramide',
  'Serafina',
  'Serena',
  'Severa',
  'Sibilla',
  'Sidonia',
  'Silvana',
  'Silvia',
  'Simona',
  'Simonetta',
  'Siria',
  'Smeralda',
  'Soave',
  'Sofia',
  'Sofronia',
  'Solange',
  'Sonia',
  'Speranza',
  'Stefania',
  'Stella',
  'Susanna',
  'Sveva',
  'Tabita',
  'Tamara',
  'Tarquinia',
  'Tarsilla',
  'Taziana',
  'Tea',
  'Tecla',
  'Telica',
  'Teodata',
  'Teodolinda',
  'Teodora',
  'Teresa',
  'Teudosia',
  'Tina',
  'Tiziana',
  'Tosca',
  'Trasea',
  'Tullia',
  'Ugolina',
  'Ulfa',
  'Uliva',
  'Unna',
  'Vala',
  'Valentina',
  'Valeria',
  'Valeriana',
  'Vanda',
  'Vanessa',
  'Vanna',
  'Venera',
  'Veneranda',
  'Venere',
  'Venusta',
  'Vera',
  'Verdiana',
  'Verena',
  'Veriana',
  'Veridiana',
  'Veronica',
  'Viliana',
  'Vilma',
  'Vincenza',
  'Viola',
  'Violante',
  'Virginia',
  'Vissia',
  'Vittoria',
  'Viviana',
  'Wanda',
  'Zabina',
  'Zaira',
  'Zama',
  'Zanita',
  'Zarina',
  'Zelinda',
  'Zenobia',
  'Zita',
  'Zoe',
  'Zosima'
];
