module["exports"] = [
  "American Albino",
  "Abaco Barb",
  "Abtenauer",
  "Abyssinian",
  "Aegidienberger",
  "Akhal-Teke",
  "Albanian Horse",
  "Altai Horse",
  "Altèr Real",
  "American Cream Draft",
  "American Indian Horse",
  "American Paint Horse",
  "American Quarter Horse",
  "American Saddlebred",
  "American Warmblood",
  "Andalusian Horse",
  "Andravida Horse",
  "Anglo-Arabian",
  "Anglo-Arabo-Sardo",
  "Anglo-Kabarda",
  "Appaloosa",
  "AraAppaloosa",
  "Arabian Horse",
  "Ardennes Horse",
  "Arenberg-Nordkirchen",
  "Argentine Criollo",
  "Asian wild Horse",
  "Assateague Horse",
  "Asturcón",
  "Augeron",
  "Australian Brumby",
  "Australian Draught Horse",
  "Australian Stock Horse",
  "Austrian Warmblood",
  "Auvergne Horse",
  "Auxois",
  "Azerbaijan Horse",
  "Azteca Horse",
  "Baise Horse",
  "Bale",
  "Balearic Horse",
  "Balikun Horse",
  "Baluchi Horse",
  "Banker Horse",
  "Barb Horse",
  "Bardigiano",
  "Bashkir Curly",
  "Basque Mountain Horse",
  "Bavarian Warmblood",
  "Belgian Half-blood",
  "Belgian Horse",
  "Belgian Warmblood ",
  "Bhutia Horse",
  "Black Forest Horse",
  "Blazer Horse",
  "Boerperd",
  "Borana",
  "Boulonnais Horse",
  "Brabant",
  "Brandenburger",
  "Brazilian Sport Horse",
  "Breton Horse",
  "Brumby",
  "Budyonny Horse",
  "Burguete Horse",
  "Burmese Horse",
  "Byelorussian Harness Horse",
  "Calabrese Horse",
  "Camargue Horse",
  "Camarillo White Horse",
  "Campeiro",
  "Campolina",
  "Canadian Horse",
  "Canadian Pacer",
  "Carolina Marsh Tacky",
  "Carthusian Horse",
  "Caspian Horse",
  "Castilian Horse",
  "Castillonnais",
  "Catria Horse",
  "Cavallo Romano della Maremma Laziale",
  "Cerbat Mustang",
  "Chickasaw Horse",
  "Chilean Corralero",
  "Choctaw Horse",
  "Cleveland Bay",
  "Clydesdale Horse",
  "Cob",
  "Coldblood Trotter",
  "Colonial Spanish Horse",
  "Colorado Ranger",
  "Comtois Horse",
  "Corsican Horse",
  "Costa Rican Saddle Horse",
  "Cretan Horse",
  "Criollo Horse",
  "Croatian Coldblood",
  "Cuban Criollo",
  "Cumberland Island Horse",
  "Curly Horse",
  "Czech Warmblood",
  "Daliboz",
  "Danish Warmblood",
  "Danube Delta Horse",
  "Dole Gudbrandsdal",
  "Don",
  "Dongola Horse",
  "Draft Trotter",
  "Dutch Harness Horse",
  "Dutch Heavy Draft",
  "Dutch Warmblood",
  "Dzungarian Horse",
  "East Bulgarian",
  "East Friesian Horse",
  "Estonian Draft",
  "Estonian Horse",
  "Falabella",
  "Faroese",
  "Finnhorse",
  "Fjord Horse",
  "Fleuve",
  "Florida Cracker Horse",
  "Foutanké",
  "Frederiksborg Horse",
  "Freiberger",
  "French Trotter",
  "Friesian Cross",
  "Friesian Horse",
  "Friesian Sporthorse",
  "Furioso-North Star",
  "Galiceño",
  "Galician Pony",
  "Gelderland Horse",
  "Georgian Grande Horse",
  "German Warmblood",
  "Giara Horse",
  "Gidran",
  "Groningen Horse",
  "Gypsy Horse",
  "Hackney Horse",
  "Haflinger",
  "Hanoverian Horse",
  "Heck Horse",
  "Heihe Horse",
  "Henson Horse",
  "Hequ Horse",
  "Hirzai",
  "Hispano-Bretón",
  "Holsteiner Horse",
  "Horro",
  "Hungarian Warmblood",
  "Icelandic Horse",
  "Iomud",
  "Irish Draught",
  "Irish Sport Horse sometimes called Irish Hunter",
  "Italian Heavy Draft",
  "Italian Trotter",
  "Jaca Navarra",
  "Jeju Horse",
  "Jutland Horse",
  "Kabarda Horse",
  "Kafa",
  "Kaimanawa Horses",
  "Kalmyk Horse",
  "Karabair",
  "Karabakh Horse",
  "Karachai Horse",
  "Karossier",
  "Kathiawari",
  "Kazakh Horse",
  "Kentucky Mountain Saddle Horse",
  "Kiger Mustang",
  "Kinsky Horse",
  "Kisber Felver",
  "Kiso Horse",
  "Kladruber",
  "Knabstrupper",
  "Konik",
  "Kundudo",
  "Kustanair",
  "Kyrgyz Horse",
  "Latvian Horse",
  "Lipizzan",
  "Lithuanian Heavy Draught",
  "Lokai",
  "Losino Horse",
  "Lusitano",
  "Lyngshest",
  "M'Bayar",
  "M'Par",
  "Mallorquín",
  "Malopolski",
  "Mangalarga",
  "Mangalarga Marchador",
  "Maremmano",
  "Marismeño Horse",
  "Marsh Tacky",
  "Marwari Horse",
  "Mecklenburger",
  "Međimurje Horse",
  "Menorquín",
  "Mérens Horse",
  "Messara Horse",
  "Metis Trotter",
  "Mezőhegyesi Sport Horse",
  "Miniature Horse",
  "Misaki Horse",
  "Missouri Fox Trotter",
  "Monchina",
  "Mongolian Horse",
  "Mongolian Wild Horse",
  "Monterufolino",
  "Morab",
  "Morgan Horse",
  "Mountain Pleasure Horse",
  "Moyle Horse",
  "Murakoz Horse",
  "Murgese",
  "Mustang Horse",
  "Namib Desert Horse",
  "Nangchen Horse",
  "National Show Horse",
  "Nez Perce Horse",
  "Nivernais Horse",
  "Nokota Horse",
  "Noma",
  "Nonius Horse",
  "Nooitgedachter",
  "Nordlandshest",
  "Noriker Horse",
  "Norman Cob",
  "North American Single-Footer Horse",
  "North Swedish Horse",
  "Norwegian Coldblood Trotter",
  "Norwegian Fjord",
  "Novokirghiz",
  "Oberlander Horse",
  "Ogaden",
  "Oldenburg Horse",
  "Orlov trotter",
  "Ostfriesen",
  "Paint",
  "Pampa Horse",
  "Paso Fino",
  "Pentro Horse",
  "Percheron",
  "Persano Horse",
  "Peruvian Paso",
  "Pintabian",
  "Pleven Horse",
  "Poitevin Horse",
  "Posavac Horse",
  "Pottok",
  "Pryor Mountain Mustang",
  "Przewalski's Horse",
  "Pura Raza Española",
  "Purosangue Orientale",
  "Qatgani",
  "Quarab",
  "Quarter Horse",
  "Racking Horse",
  "Retuerta Horse",
  "Rhenish German Coldblood",
  "Rhinelander Horse",
  "Riwoche Horse",
  "Rocky Mountain Horse",
  "Romanian Sporthorse",
  "Rottaler",
  "Russian Don",
  "Russian Heavy Draft",
  "Russian Trotter",
  "Saddlebred",
  "Salerno Horse",
  "Samolaco Horse",
  "San Fratello Horse",
  "Sarcidano Horse",
  "Sardinian Anglo-Arab",
  "Schleswig Coldblood",
  "Schwarzwälder Kaltblut",
  "Selale",
  "Sella Italiano",
  "Selle Français",
  "Shagya Arabian",
  "Shan Horse",
  "Shire Horse",
  "Siciliano Indigeno",
  "Silesian Horse",
  "Sokolsky Horse",
  "Sorraia",
  "South German Coldblood",
  "Soviet Heavy Draft",
  "Spanish Anglo-Arab",
  "Spanish Barb",
  "Spanish Jennet Horse",
  "Spanish Mustang",
  "Spanish Tarpan",
  "Spanish-Norman Horse",
  "Spiti Horse",
  "Spotted Saddle Horse",
  "Standardbred Horse",
  "Suffolk Punch",
  "Swedish Ardennes",
  "Swedish coldblood trotter",
  "Swedish Warmblood",
  "Swiss Warmblood",
  "Taishū Horse",
  "Takhi",
  "Tawleed",
  "Tchernomor",
  "Tennessee Walking Horse",
  "Tersk Horse",
  "Thoroughbred",
  "Tiger Horse",
  "Tinker Horse",
  "Tolfetano",
  "Tori Horse",
  "Trait Du Nord",
  "Trakehner",
  "Tsushima",
  "Tuigpaard",
  "Ukrainian Riding Horse",
  "Unmol Horse",
  "Uzunyayla",
  "Ventasso Horse",
  "Virginia Highlander",
  "Vlaamperd",
  "Vladimir Heavy Draft",
  "Vyatka",
  "Waler",
  "Waler Horse",
  "Walkaloosa",
  "Warlander",
  "Warmblood",
  "Welsh Cob",
  "Westphalian Horse",
  "Wielkopolski",
  "Württemberger",
  "Xilingol Horse",
  "Yakutian Horse",
  "Yili Horse",
  "Yonaguni Horse",
  "Zaniskari",
  "Žemaitukas",
  "Zhemaichu",
  "Zweibrücker"
]