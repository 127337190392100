import { css } from "styled-components/native";
import { spacing } from "../theme";

export interface MarginBottomProps {
  hasMarginBottom?: boolean;
}

interface AllowOtherProps extends MarginBottomProps {
  [key: string]: any;
}

const marginBottom = ({ hasMarginBottom }: AllowOtherProps) =>
  hasMarginBottom &&
  css`
    margin-bottom: ${spacing.medium};
  `;

export const marginBottomLarge = ({ hasMarginBottom }: AllowOtherProps) =>
  hasMarginBottom &&
  css`
    margin-bottom: ${spacing.large};
  `;

export default marginBottom;
