module["exports"] = [
  "Aarau",
  "Adliswil",
  "Allschwil",
  "Arbon",
  "Baar",
  "Baden",
  "Basel",
  "Bellinzona",
  "Bern",
  "Biel/Bienne",
  "Binningen",
  "Brig-Glis",
  "Bulle",
  "Burgdorf",
  "Bülach",
  "Carouge",
  "Cham",
  "Chur",
  "Dietikon",
  "Dübendorf",
  "Einsiedeln",
  "Emmen",
  "Frauenfeld",
  "Freiburg",
  "Freienbach",
  "Genf",
  "Glarus Nord",
  "Gossau",
  "Grenchen",
  "Herisau",
  "Horgen",
  "Horw",
  "Illnau-Effretikon",
  "Kloten",
  "Kreuzlingen",
  "Kriens",
  "Köniz",
  "Küsnacht",
  "La Chaux-de-Fonds",
  "Lancy",
  "Langenthal",
  "Lausanne",
  "Liestal",
  "Locarno",
  "Lugano",
  "Luzern",
  "Lyss",
  "Martigny",
  "Meilen",
  "Mendrisio",
  "Meyrin",
  "Monthey",
  "Montreux",
  "Morges",
  "Muri bei Bern",
  "Muttenz",
  "Neuenburg",
  "Nyon",
  "Oftringen",
  "Olten",
  "Onex",
  "Opfikon",
  "Ostermundigen",
  "Pratteln",
  "Pully",
  "Rapperswil-Jona",
  "Regensdorf",
  "Reinach",
  "Renens",
  "Rheinfelden",
  "Richterswil",
  "Riehen",
  "Schaffhausen",
  "Schlieren",
  "Schwyz",
  "Siders",
  "Sitten",
  "Solothurn",
  "St. Gallen",
  "Steffisburg",
  "Stäfa",
  "Thalwil",
  "Thun",
  "Thônex",
  "Uster",
  "Val-de-Ruz",
  "Vernier",
  "Versoix",
  "Vevey",
  "Volketswil",
  "Wallisellen",
  "Wettingen",
  "Wetzikon",
  "Wil",
  "Winterthur",
  "Wohlen",
  "Wädenswil",
  "Yverdon-les-Bains",
  "Zug",
  "Zürich",
];
