var pt_PT = {};
module['exports'] = pt_PT;
pt_PT.title = "Portuguese (Portugal)";
pt_PT.address = require("./address");
pt_PT.internet = require("./internet");
pt_PT.name = require("./name");
pt_PT.phone_number = require("./phone_number");
pt_PT.cell_phone = require("./cell_phone");
pt_PT.commerce = require("./commerce");
pt_PT.date = require("./date");
