import React from "react";



const FileUploadIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      style={{ marginRight: "8px" }}
      data-name="Layer 2"
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        d="M16 1a15 15 0 1015 15A15.017 15.017 0 0016 1zm-4.307 9.893l3.6-3.6a1 1 0 011.416 0l3.6 3.6a1 1 0 11-1.414 1.414L17 10.414V19.2a1 1 0 11-2 0v-8.786l-1.893 1.893a1 1 0 11-1.414-1.414zM23.3 22a1 1 0 01-1 1H9.7a1 1 0 01-1-1v-2.8a1 1 0 012 0V21h10.6v-1.8a1 1 0 012 0z"
      ></path>
    </svg>
  );
};

export default FileUploadIcon;
