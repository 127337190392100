import React, { FC, ReactNode, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  children?: ReactNode;
  wrapperId?: string;
  zIndex?: number;
}

const Portal: FC<PortalProps> = ({
  children,
  wrapperId = "modal-portal",
  zIndex,
}) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement>();

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    if (!element) {
      const wrapperElement = document.createElement("div");
      wrapperElement.setAttribute("id", wrapperId);
      if (zIndex) {
        wrapperElement.style.zIndex = zIndex.toString();
      }
      document.body.appendChild(wrapperElement);
      element = wrapperElement;
    }
    setWrapperElement(element);
  }, [wrapperId]);

  if (wrapperElement) {
    return createPortal(children, wrapperElement);
  }

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      {wrapperElement ? createPortal(children, wrapperElement) : children}
    </>
  );
};

export default Portal;