module["exports"] = [
  "կարմիր",
  "կանաչ",
  "կապույտ",
  "դեղին",
  "մանուշակագույն",
  "սպիտակ",
  "սև",
  "նարնջագույն",
  "վարդագույն",
  "մոխրագույն",
  "երկնագույն",
  "ոսկեգույն"
];
