import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { InlineResponse400Errors } from "../../../bookmydesk-api-sdk-typescript-axios";
import { t } from "../../i18n";
import Text from "../../styles/Text";
import { color } from "../../styles/theme";
import Notification from "../../components/Notification";

interface Props {
  errors: InlineResponse400Errors[];
  closeNotification: () => void;
}

const InputValidationErrors: FunctionComponent<Props> = ({
  errors,
  closeNotification,
}) => {
  return (
    <Notification closeNotification={closeNotification}>
      {t("general.formSubmitError")}
      {errors.map((error) => (
        <View
          style={{ display: "flex" }}
          key={`${error.code}-${error.message}`}
        >
          <Text color={color.primary}>{error.message}</Text>
        </View>
      ))}
    </Notification>
  );
};

export default InputValidationErrors;
