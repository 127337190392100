import { TextInputProps, ViewStyle } from "react-native";
import styled from "styled-components/native";
import {
  borderRadius,
  borderWidth,
  color,
  font,
  spacing,
  text,
} from "../theme";
import { convertObjectToStyle } from "../../utils";

export interface CustomTextInput extends TextInputProps, ViewStyle {
  borderStyle?: string;
  outline?: string;
  type?: string;
}

export default styled.TextInput<CustomTextInput>`
  font-size: ${text.extraSmall.size};
  font-family: ${font.defaultRegular};
  padding: ${spacing.medium} ${spacing.medium};
  margin-bottom: ${spacing.medium};
  border-radius: ${borderRadius.small};
  border-width: ${borderWidth.small};
  border-color: ${color.greyLightest};
  background-color: ${({ editable }) =>
    editable === false ? color.greyLighter : "transparent"};
  ${(props) => convertObjectToStyle(props, ['borderWidth', 'marginBottom', 'outline', 'width'])};
`;
