module["exports"] = [
	"אביאל",
	"אביגדור",
	"אביה",
	"אביחי",
	"אבינועם",
	"אביעד",
	"אבירם",
	"אבירן",
	"אבישי",
	"אביתר",
	"אברהם",
	"אבשלום",
	"אגם",
	"אדיר",
	"אדם",
	"אדר",
	"אהוד",
	"אהרון",
	"אוהד",
	"אופיר",
	"אופק",
	"אור",
	"אורי",
	"אוריאל",
	"אוריה",
	"אורן",
	"אושרי",
	"אחיה",
	"אייל",
	"איל",
	"אילון",
	"אילן",
	"אימרי",
	"איתי",
	"איתם",
	"איתמר",
	"איתן",
	"אלדד",
	"אלדר",
	"אלון",
	"אלחי",
	"אלחנן",
	"אליאב",
	"אליאור",
	"אליאס",
	"אליה",
	"אליהו",
	"אלימלך",
	"אליסף",
	"אלירז",
	"אלירן",
	"אלישיב",
	"אלישמע",
	"אלישע",
	"אלכס",
	"אלכסנדר",
	"אלמוג",
	"אלעד",
	"אלקנה",
	"אלרועי",
	"אמור",
	"אמיר",
	"אמיתי",
	"אמנון",
	"אסיף",
	"אסף",
	"אפיק",
	"אפרים",
	"אראל",
	"ארבל",
	"ארז",
	"ארטיום",
	"ארי",
	"אריאל",
	"אריה",
	"ארנון",
	"ארתור",
	"אשד",
	"אשל",
	"אשר",
	"בארי",
	"בועז",
	"בן אל",
	"בן ציון",
	"בניה",
	"בנימין",
	"בצלאל",
	"בר",
	"בראל",
	"ברוך",
	"ברי",
	"ברק",
	"גד",
	"גדעון",
	"גוון",
	"ג'וי",
	"גולן",
	"ג'ון",
	"גוני",
	"גור",
	"גורן",
	"גיא",
	"גיורא",
	"גיל",
	"גילי",
	"גל",
	"גלבוע",
	"גלעד",
	"גפן",
	"דביר",
	"דגן",
	"דוד",
	"דולב",
	"דור",
	"דורון",
	"דן",
	"דני",
	"דניאל",
	"דקל",
	"דרור",
	"הדר",
	"הוד",
	"הלל",
	"הראל",
	"ולדימיר",
	"זאב",
	"זבולון",
	"זוהר",
	"זיו",
	"חביב",
	"חגי",
	"חושן",
	"חי",
	"חיים",
	"חמי",
	"חן",
	"חנוך",
	"חנן",
	"חסד",
	"טל",
	"טנא",
	"יאיר",
	"יאן",
	"יגאל",
	"יגל",
	"ידיד",
	"ידידיה",
	"יהב",
	"יהודה",
	"יהונתן",
	"יהורם",
	"יהושע",
	"יהל",
	"יהלום",
	"יהלי",
	"יואב",
	"יואל",
	"יובל",
	"יוגב",
	"יוחאי",
	"יונתן",
	"יוסף",
	"יורם",
	"יותם",
	"יחזקאל",
	"ים",
	"ינון",
	"יניב",
	"יעקב",
	"יפתח",
	"יצחק",
	"יקיר",
	"ירדן",
	"ירון",
	"יריב",
	"ישי",
	"ישראל",
	"יששכר",
	"יאיר",
	"יאן",
	"יגאל",
	"יגל",
	"ידיד",
	"ידידיה",
	"יהב",
	"יהודה",
	"יהונתן",
	"יהורם",
	"יהושע",
	"יהל",
	"יהלום",
	"יהלי",
	"יואב",
	"יואל",
	"יובל",
	"יוגב",
	"יוחאי",
	"יונתן",
	"יוסף",
	"יורם",
	"יותם",
	"יחזקאל",
	"ים",
	"ינון",
	"יניב",
	"יעקב",
	"יפתח",
	"יצחק",
	"יקיר",
	"ירדן",
	"ירון",
	"יריב",
	"ישי",
	"ישראל",
	"יששכר",
	"כוכב",
	"כליל",
	"כפיר",
	"כרם",
	"כרמי",
	"כרמל",
	"לב",
	"לביא",
	"לוטם",
	"לוטן",
	"לוי",
	"לי",
	"ליאון",
	"ליאור",
	"ליאל",
	"ליאם",
	"לידור",
	"ליעד",
	"ליר",
	"לירוי",
	"לירון",
	"לירז",
	"לירן",
	"מאור",
	"מאיר",
	"מגל",
	"מור",
	"מורן",
	"מיטב",
	"מיכאל",
	"מירן",
	"מלאכי",
	"מנחם",
	"מנשה",
	"מעיין",
	"מרדכי",
	"משה",
	"מתן",
	"נבו",
	"נדב",
	"נהוראי",
	"נוב",
	"נוי",
	"נועם",
	"נח",
	"נחשון",
	"נטע",
	"ניב",
	"ניסן",
	"ניצן",
	"ניקולס",
	"ניר",
	"ניתאי",
	"נמרוד",
	"נפתלי",
	"נרי",
	"נריה",
	"נתיב",
	"נתן",
	"נתנאל",
	"סביון",
	"סהר",
	"סול",
	"סטפן",
	"סיני",
	"סער",
	"סרגיי",
	"סשה",
	"סתיו",
	"עברי",
	"עדי",
	"עדן",
	"עודד",
	"עוז",
	"עוזיה",
	"עומר",
	"עומרי",
	"עופר",
	"עופרי",
	"עידו",
	"עידן",
	"עילאי",
	"עלי",
	"עמוס",
	"עמיחי",
	"עמית",
	"עמנואל",
	"עקיבא",
	"ערן",
	"פלא",
	"פלג",
	"צבי",
	"צדק",
	"צוף",
	"צוק",
	"צור",
	"צח",
	"צחי",
	"ציון",
	"צליל",
	"קסם",
	"ראובן",
	"ראם",
	"רביב",
	"רביד",
	"רגב",
	"רון",
	"רוני",
	"רועי",
	"רותם",
	"רז",
	"ריי",
	"רימון",
	"רם",
	"רן",
	"רעי",
	"רפאל",
	"שאול",
	"שגב",
	"שגיא",
	"שובל",
	"שוהם",
	"שחף",
	"שחק",
	"שחר",
	"שי",
	"שי לי",
	"שילה",
	"שיר",
	"שלום",
	"שלומי",
	"שלמה",
	"שמאי",
	"שמואל",
	"שמחה",
	"שמעון",
	"שקד",
	"שרון",
	"תבור",
	"תו",
	"תום",
	"תומר",
	"תיאו",
	"תמיר"
	];