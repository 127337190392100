var pt_BR = {};
module['exports'] = pt_BR;
pt_BR.title = "Portuguese (Brazil)";
pt_BR.address = require("./address");
pt_BR.company = require("./company");
pt_BR.commerce = require("./commerce");
pt_BR.internet = require("./internet");
pt_BR.lorem = require("./lorem");
pt_BR.name = require("./name");
pt_BR.phone_number = require("./phone_number");
pt_BR.date = require("./date");
