module["exports"] = [
  "а",
  "административных",
  "активизации",
  "активности",
  "активом",
  "актуальность",
  "анализа",
  "базы",
  "богатый",
  "в",
  "важную",
  "важные",
  "влечёт",
  "внедрения",
  "воздействия",
  "всего",
  "выбранный",
  "вызывает",
  "выполнять",
  "высокотехнологичная",
  "высшего",
  "гражданского",
  "дальнейшее",
  "дальнейших",
  "демократической",
  "деятельности",
  "для",
  "другой",
  "же",
  "за",
  "забывать",
  "зависит",
  "задания",
  "задач",
  "задача",
  "занимаемых",
  "значение",
  "значимость",
  "значительной",
  "и",
  "играет",
  "идейные",
  "изменений",
  "инновационный",
  "интересный",
  "информационно-пропогандистское",
  "кадров",
  "кадровой",
  "качества",
  "качественно",
  "количественный",
  "консультация",
  "концепция",
  "кругу",
  "курс",
  "массового",
  "материально-технической",
  "место",
  "мира",
  "модели",
  "модель",
  "модернизации",
  "на",
  "намеченных",
  "нами",
  "направлений",
  "напрямую",
  "нас",
  "настолько",
  "насущным",
  "национальный",
  "начало",
  "нашей",
  "не",
  "новая",
  "новых",
  "обеспечение",
  "обеспечивает",
  "образом",
  "обуславливает",
  "обучения",
  "общества",
  "общественной",
  "обществом",
  "однако",
  "определения",
  "опыт",
  "организации",
  "организационной",
  "особенности",
  "от",
  "отметить",
  "отношении",
  "оценить",
  "очевидна",
  "плановых",
  "по",
  "повседневная",
  "повседневной",
  "повышение",
  "повышению",
  "подготовке",
  "позволяет",
  "позиции",
  "показывает",
  "понимание",
  "порядка",
  "последовательного",
  "поставленных",
  "постоянное",
  "постоянный",
  "потребностям",
  "поэтапного",
  "правительством",
  "практика",
  "предложений",
  "предпосылки",
  "представляет",
  "прежде",
  "принимаемых",
  "принципов",
  "проблем",
  "проверки",
  "прогресса",
  "прогрессивного",
  "проект",
  "профессионального",
  "процесс",
  "путь",
  "работы",
  "равным",
  "развития",
  "различных",
  "разнообразный",
  "разработке",
  "рамки",
  "реализация",
  "ресурсосберегающих",
  "роль",
  "рост",
  "с",
  "систему",
  "системы",
  "следует",
  "сложившаяся",
  "собой",
  "современного",
  "создаёт",
  "создание",
  "сознания",
  "сомнений",
  "соображения",
  "соответствующей",
  "соответствующих",
  "социально-ориентированный",
  "социально-экономическое",
  "специалистов",
  "способствует",
  "степени",
  "стороны",
  "структура",
  "структуры",
  "существующий",
  "сущности",
  "сфера",
  "также",
  "таким",
  "технологий",
  "требует",
  "укрепления",
  "управление",
  "уровня",
  "условий",
  "уточнения",
  "участия",
  "участниками",
  "финансовых",
  "форм",
  "формировании",
  "формированию",
  "формирования",
  "целесообразности",
  "что",
  "шагов",
  "широким",
  "широкому",
  "экономической",
  "эксперимент",
  "этих"
];
