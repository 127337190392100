import React, { FC } from "react";
import styled from "styled-components/native";
import SeatTagOverview from "../../SeatTagOverview";
import HeaderCard from "../../../../components/HeaderCard";
import tagIcon from "../../../../assets/icons/icon-financial.svg";
import { t } from "../../../../i18n";
import Button from "../../../../components/Button";
import { useHistory } from "../../../../routing";

const HeaderCardTitleSuffixWrapper = styled.View`
  margin-left: auto;
`;

const InnerWrapper = styled.View`
  padding-bottom: 15px;
`;

const TagsCard: FC = () => {
  const history = useHistory();

  return (
    <HeaderCard
      icon={tagIcon}
      badgeColor="transparent"
      title={t("admin.seatTag.overview.title")}
      titleSuffix={
        <HeaderCardTitleSuffixWrapper>
          <Button
            onPress={() => history.push("/admin/seat-tag-overview/create")}
          >
            {t("admin.seatTag.overview.addButton")}
          </Button>

        </HeaderCardTitleSuffixWrapper>
      }
    >
      <InnerWrapper>
        <SeatTagOverview />
      </InnerWrapper>
    </HeaderCard>
  );
};

export default TagsCard;
