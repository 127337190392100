import styled from "styled-components/native";
import { spacing } from "../theme";

const regularOverviewHeaderHeight = "60px";

export const OverviewHeader = styled.View`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: ${spacing.medium} 0;
  align-items: center;
  min-height: ${regularOverviewHeaderHeight};
`;
