module["exports"] = [
  "Abreu",
  "Albuquerque",
  "Almeida",
  "Alves",
  "Amaral",
  "Amorim",
  "Andrade",
  "Anjos",
  "Antunes",
  "Araújo",
  "Assunção",
  "Azevedo",
  "Baptista",
  "Barbosa",
  "Barros",
  "Batista",
  "Borges",
  "Braga",
  "Branco",
  "Brito",
  "Campos",
  "Cardoso",
  "Carneiro",
  "Carvalho",
  "Castro",
  "Coelho",
  "Correia",
  "Costa",
  "Cruz",
  "Cunha",
  "Domingues",
  "Esteves",
  "Faria",
  "Fernandes",
  "Ferreira",
  "Figueiredo",
  "Fonseca",
  "Freitas",
  "Garcia",
  "Gaspar",
  "Gomes",
  "Gonçalves",
  "Guerreiro",
  "Henriques",
  "Jesus",
  "Leal",
  "Leite",
  "Lima",
  "Lopes",
  "Loureiro",
  "Lourenço",
  "Macedo",
  "Machado",
  "Magalhães",
  "Maia",
  "Marques",
  "Martins",
  "Matias",
  "Matos",
  "Melo",
  "Mendes",
  "Miranda",
  "Monteiro",
  "Morais",
  "Moreira",
  "Mota",
  "Moura",
  "Nascimento",
  "Neto",
  "Neves",
  "Nobre",
  "Nogueira",
  "Nunes",
  "Oliveira",
  "Pacheco",
  "Paiva",
  "Pereira",
  "Pinheiro",
  "Pinho",
  "Pinto",
  "Pires",
  "Ramos",
  "Raposo",
  "Reis",
  "Ribeiro",
  "Rocha",
  "Rodrigues",
  "Santos",
  "Saraiva",
  "Silva",
  "Simões",
  "Soares",
  "Sousa",
  "Sá",
  "Tavares",
  "Teixeira",
  "Torres",
  "Valente",
  "Vaz",
  "Vicente",
  "Vieira"
];
