import React, { FC, useState } from "react";
import { TextInputProps } from "react-native";
import Input from "../../styles/Input";
import styled, { css } from "styled-components/native";
import searchIcon from "./search.svg";
import { borderRadius, input, spacing, iconSize } from "../../styles/theme";

interface InputWrapperStyle {
  wrapperStyle?: string;
}

const maxSearchInputWidth = "500px";

const InputWrapper = styled.View<InputWrapperStyle>`
  position: relative;
  max-width: ${maxSearchInputWidth};
  flex-grow: 1;

  ${({ wrapperStyle }) =>
    wrapperStyle &&
    css`
      ${wrapperStyle}
    `}
`;

const SearchIcon = styled.Image`
  top: 0;
  bottom: 0;
  left: ${spacing.medium};
  margin: auto;
  position: absolute;
  ${iconSize.small};
`;

// These props are required because otherwise typescript will give errors.
// The props are overrides from the TextInputProps which will not work properly
// With the spread syntax that we use in this component.
interface InputInterface extends TextInputProps {
  style?: any;
  textStyle?: string;
  placeholderTextColor?: string;
}

const StyledInput = styled(Input)<InputInterface>`
  height: ${input.height};
  margin-bottom: 0;
  width: 100%;
  border-radius: ${borderRadius.full};
  padding-left: calc(${spacing.large} + ${spacing.medium});
`;

interface InputInterface extends TextInputProps {
  onSearch?(searchTerm: string): void;
  wrapperStyle?: string;
}

const SearchInput: FC<InputInterface> = ({
  onSearch,
  wrapperStyle,
  ...rest
}) => {
  const [value, setValue] = useState("");

  const submitOnEnter = () => {
    if (onSearch) {
      return onSearch(value);
    }
  };

  return (
    <InputWrapper wrapperStyle={wrapperStyle}>
      {/* @ts-ignore */}
      <StyledInput
        onChangeText={setValue}
        value={value}
        onSubmitEditing={submitOnEnter}
        {...rest}
      />
      <SearchIcon source={searchIcon} />
    </InputWrapper>
  );
};

export default SearchInput;
