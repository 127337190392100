// Source: http://unicode.org/cldr/trac/browser/tags/release-27/common/main/en.xml#L1847
module["exports"] = {
  wide: [
    "יום ראשון",
    "יום שני",
    "יום שלישי",
    "יום רביעי",
    "יום חמישי",
    "יום שישי",
    "שבת"
  ],
  // Property "wide_context" is optional, if not set then "wide" will be used instead
  // It is used to specify a word in context, which may differ from a stand-alone word
  wide_context: [
    "יום ראשון",
    "יום שני",
    "יום שלישי",
    "יום רביעי",
    "יום חמישי",
    "יום שישי",
    "שבת"
  ],
  abbr: [
    "יום א׳",
    "יום ב׳",
    "יום ג׳",
    "יום ד׳",
    "יום ה׳",
    "יום ו׳",
    "שבת"
  ],
  // Property "abbr_context" is optional, if not set then "abbr" will be used instead
  // It is used to specify a word in context, which may differ from a stand-alone word
  abbr_context: [
    "יום א׳",
    "יום ב׳",
    "יום ג׳",
    "יום ד׳",
    "יום ה׳",
    "יום ו׳",
    "שבת"
  ]
};
