module["exports"] = [
    "רוק",
    "רוק מטאלי",
    "פופ",
    "אלקטרוני",
    "עממית",
    "עולם",
    "קאנטרי",
    "ג'אז",
    "פאנק",
    "נשמה",
    "היפ הופ",
    "קלאסית",
    "לטינית",
    "רגאיי",
    "במה ומסך",
    "בלוז",
    "לא מוסיקה",
    "ראפ"
];
