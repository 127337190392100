import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Company, OrderDirection } from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button, {
  ButtonsWrapper,
  ButtonWrapper,
} from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import LoadOverlay from "../../../components/LoadOverlay";
import { getOfficePages, getSaltoPage } from "../../../components/Navbar/AdminNavBarContent";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import Toggle from "../../../components/Toggle";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import useCountries from "../../../hooks/useCountries";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { OverviewHeader } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import { color } from "../../../styles/theme";
import Title from "../../../styles/Title";
import { ArrayElement, ThenArg } from "../../../types";

const columnSizes = [3, 3, 1, 2, 1];

const LocationOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.location.overview.name"), false),
    createHeaderItem(t("admin.location.overview.address"), false),
    createHeaderItem(t("admin.location.overview.visible"), false),
    //createHeaderItem("", false),
    createHeaderItem(t("admin.location.overview.sorting"), false),
  ];
  const { userState } = useAuth();
  const client = useClient();

  type LocationItem = ArrayElement<
    ThenArg<ReturnType<typeof client.listLocations>>["data"]["result"]["items"]
  >;

  const { handleRequest, isLoading, error, dismissError } = useApi();
  const userCompany = userState?.companyIds[0];
  const [data, setData] = useState<LocationItem[]>([]);
  const [company, setCompany] =  useState<Company | null>(null);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<LocationItem>();
  const history = useHistory();

  const countries = useCountries();

  const fetchListItems = useCallback(async () => {
    if (userCompany) {
      return await handleRequest(
        client.listLocations(userCompany, takeSize, skipParam)
      );
    }
  }, [userCompany, handleRequest, client, skipParam]);

  const fetchCompany = useCallback(async () => {
    if (userCompany) {
      return await handleRequest(
        client.getCompany(userCompany)
      );
    }
  }, [userCompany, handleRequest, client]);

  const initialize = useCallback(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setData(items || []);
        setTotalItems(total);
      }
    });

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { company} = result.data.result;
      if (company) {
        setCompany(company as Company);
      }
    });

    
  }, [fetchCompany, fetchListItems]);

  const setLocationVisibility = useCallback(
    async (newValue, id) => {
      await handleRequest(
        client.updateLocation(id, {
          visible: newValue,
        }),
        false
      );
      //console.log(`new value: ${newValue}, id: ${id}`);
      await initialize();
    },
    [client, handleRequest, initialize]
  );

  const move = useCallback(
    async (location: LocationItem, direction: OrderDirection) => {
      await handleRequest(
        client.changeLocationOrder(location.id, {
          direction,
        }),
        false
      );
      await initialize();
    },
    [client, handleRequest, initialize]
  );

  const renderTablePages = useCallback(() => {
    if (company?.saltoUsername && company?.saltoPassword) {
    return [...getOfficePages(), ...getSaltoPage()];
    }    
    return getOfficePages();

  },[company]);

  const createTableData = useCallback(
    (items: LocationItem[]) => {
      const isLastPage = takeSize + skipParam + 1 > totalItems;
      const tableData: TableContent[] = items.map(
        ({ name, address, visible, id, _operations }, index, currentArray) => {
          const data: ReactElement[] = [
            <ExtraSmallText key="name" mediumWeight>
              {name}
            </ExtraSmallText>,
            <ExtraSmallText key="address">{`${address.street} ${
              address.number
            }, ${address.city}${
              address.countryCode
                ? `, ${
                    countries.find(
                      (country) => country.value === address.countryCode
                    )?.label
                  }`
                : ""
            } `}</ExtraSmallText>,
            <Toggle
              key="visible"
              toggleValue={Boolean(visible)}
              onChange={(newValue) => setLocationVisibility(newValue, id)}
            />,
            <ButtonsWrapper key="buttons">
              {totalItems !== 1 &&
                ((skipParam === 0 && index !== 0) || skipParam !== 0) && (
                  <ButtonWrapper>
                    <Button
                      backgroundColor={color.white}
                      backgroundHoverColor={color.secondary}
                      borderColor={color.secondary}
                      textHoverColor={color.white}
                      onPress={() => move(items[index], OrderDirection.Up)}
                    >
                      {t("general.up")}
                    </Button>
                  </ButtonWrapper>
                )}
              {totalItems !== 1 &&
                (!isLastPage ||
                  (isLastPage && index !== currentArray.length - 1)) && (
                  <ButtonWrapper>
                    <Button
                      backgroundColor={color.white}
                      backgroundHoverColor={color.secondary}
                      borderColor={color.secondary}
                      textHoverColor={color.white}
                      onPress={() => move(items[index], OrderDirection.Down)}
                    >
                      {t("general.down")}
                    </Button>
                  </ButtonWrapper>
                )}
            </ButtonsWrapper>,
          ];
          return {
            data,
            id,
            canDelete: _operations?.canDelete,
            cannotDeleteDescription: _operations?.cannotDeleteDescription,
            canEdit: true,
          };
        }
      );
      //console.log("New table data");
      return tableData;
    },
    [skipParam, totalItems, countries, setLocationVisibility, move]
  );

  useEffect(() => {
    setTableData(createTableData(data));
  }, [createTableData, data]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteLocation(itemSelected!.id)
    );

    if (response && response.status === 204) {
      await fetchListItems().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items, total } = result.data.result;
        if (items) {
          setTableData(createTableData(items));
          setData(items);
          setTotalItems(total);
        }
      });

      setShowDeleteMessage(true);
    }
  }, [handleRequest, client, itemSelected, fetchListItems, createTableData]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.location.overview.title")}</Title>
          <Button
            onPress={() => history.push("/admin/location-overview/create")}
          >
            {t("admin.location.overview.addButton")}
          </Button>
        </OverviewHeader>
        <PageSelector pages={renderTablePages()}/>
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        ) : null}
        {isLoading ? null : (
          <Table
            tableId="location_overview"
            headerItems={tableHeaders}
            tableContent={tableData}
            columnSizes={columnSizes}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditSchedule={(id) =>
              history.push(`/admin/location-overview/update/${id}/hours`)
            }
            onEditItem={(id) =>
              history.push(`/admin/location-overview/update/${id}`)
            }
            onDeleteItem={openDeleteModal}
          />
        )}
        {totalItems && !isLoading ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.location.delete.warning", {
          name: itemSelected?.name,
        })}
      />
    </>
  );
};

export default LocationOverview;
