import { DocumentResult } from "expo-document-picker";
import React, { FC, useCallback, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import FileUpload from "../../../components/FileUpload";
import {
  Asterisk,
  LabeledInputWrapper,
  LabelWrapper,
} from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { t } from "../../../i18n";
import { useHistory } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Text, { SmallText } from "../../../styles/Text";
import { color, spacing } from "../../../styles/theme";
import { MediumTitle } from "../../../styles/Title";
import { ThenArg } from "../../../types";
import example2CSV from "./example-four-columns.csv";
import example1CSV from "./example-single-column.csv";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Import: FC = () => {
  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();

  type ResponseType = ThenArg<
    ReturnType<typeof client.importUsers>
  >["data"]["result"];

  const [response, setResponse] = useState<null | ResponseType>(null);

  const [file, setFile] = useState<DocumentResult>();

  const history = useHistory();

  const userCompany = userState?.companyIds[0];

  const isFormValid = useCallback(() => Boolean(file), [file]);

  const submitForm = useCallback(async () => {
    if (!userCompany || file?.type !== "success") {
      return;
    }

    try {
      const response = await handleRequest(
        client.importUsers(userCompany, file.file)
      );
      if (response) {
        setResponse(response.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  }, [file, userCompany, client, handleRequest]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <Notification closeNotification={dismissError}>
              {t("general.error")}
            </Notification>
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.user.import.form.title")}
          </MediumTitle>
          {!response && (
            <>
              <Text>{t("admin.user.import.form.p1")}</Text>
              <Text>{t("admin.user.import.form.p2")}</Text>
              <Text>
                {t("admin.user.import.form.summary1")}{" "}
                <TouchableOpacity
                  onPress={() => {
                    window.open(example1CSV, "_blank");
                  }}
                >
                  <Text color={color.secondary}>
                    ({t("admin.user.import.form.example")})
                  </Text>
                </TouchableOpacity>
              </Text>
              <Text>
                {t("admin.user.import.form.summary2")}{" "}
                <TouchableOpacity
                  onPress={() => {
                    window.open(example2CSV, "_blank");
                  }}
                >
                  <Text color={color.secondary}>
                    ({t("admin.user.import.form.example")})
                  </Text>
                </TouchableOpacity>
              </Text>
              <LabeledInputWrapper>
                <LabelWrapper>
                  <SmallText mediumWeight hasMarginBottom>
                    {t("admin.user.import.form.file")}
                    <Asterisk mediumWeight>{" *"}</Asterisk>
                  </SmallText>
                </LabelWrapper>
                <View
                  style={{
                    flex: 1,
                    flexGrow: 2,
                    marginVertical: spacing.medium,
                  }}
                >
                  <FileUpload
                    acceptedFileTypes="text/csv"
                    placeholder={t("admin.user.import.form.filePlaceholder")}
                    onSubmit={setFile}
                  />
                </View>
              </LabeledInputWrapper>
              <Button
                hasMarginBottom
                disabled={!isFormValid()}
                onPress={submitForm}
              >
                {t("admin.user.import.form.submitButton")}
              </Button>
              <Button
                backgroundColor="transparent"
                backgroundHoverColor={color.primary}
                borderColor={color.primary}
                color={color.primary}
                textHoverColor={color.white}
                onPress={() => history.goBack()}
              >
                {t("general.cancel")}
              </Button>
            </>
          )}
          {!!response && (
            <>
              {response.successes.length > 0 && (
                <>
                  <MediumTitle hasMarginBottom>
                    {t("admin.user.import.response.successTitle")}
                  </MediumTitle>
                  {response.successes.map((message) => (
                    <Text key={message}>{message}</Text>
                  ))}
                </>
              )}
              {response.errors.length > 0 && (
                <>
                  <MediumTitle hasMarginBottom>
                    {t("admin.user.import.response.errorsTitle")}
                  </MediumTitle>
                  {response.errors.map((message) => (
                    <Text key={message}>{message}</Text>
                  ))}
                </>
              )}
              <Button hasMarginBottom onPress={() => window.location.reload()}>
                {t("admin.user.import.response.retry")}
              </Button>
              <Button
                backgroundColor={color.white}
                backgroundHoverColor={color.secondary}
                borderColor={color.secondary}
                textHoverColor={color.white}
                onPress={() => history.goBack()}
              >
                {t("general.close")}
              </Button>
            </>
          )}
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Import;
