module["exports"] = [
  "هم افزایی",
  "بازارها",
  "شراکت",
  "زیرساخت ها",
  "پلتفرم",
  "ابتکار عمل",
  "کانال ها",
  "اجتماعات",
  "راه حل ها",
  "خدمات الکترونیکی",
  "موارد عملی",
  "پورتال ها",
  "سوله",
  "فن آوری ها",
  "محتوا",
  "زنجیره تامین",
  "همگرایی",
  "روابط",
  "معماری",
  "رابط ها",
  "بازارهای الکترونیکی",
  "تجارت الکترونیک",
  "سیستم ها",
  "پهنای باند",
  "مدل ها",
  "ذهنیت",
  "تحویل دادنی ها",
  "کاربران",
  "شبکه ها",
  "برنامه های کاربردی",
  "تجارت الکترونیکی",
  "ویژگی ها",
  "تجربیات",
  "روش شناسی",
  "بلاکچین"
];
