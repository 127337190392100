module["exports"] = [
	"אביב",
	"אביבית",
	"אביגיל",
	"אביה",
	"אביטל",
	"אבישג",
	"אגם",
	"אדווה",
	"אדל",
	"אדר",
	"אהבה",
	"אודיה",
	"אודליה",
	"אווה",
	"אופיר",
	"אופירה",
	"אופל",
	"אופק",
	"אור",
	"אביגיל",
	"אורטל",
	"אורי",
	"אוריה",
	"אורית",
	"אורלי",
	"אושרי",
	"איב",
	"איה",
	"איילה",
	"איילת",
	"אילנה",
	"אילנית",
	"אימרי",
	"אירית",
	"אלה",
	"אלומה",
	"אליאנה",
	"אליה",
	"אלין",
	"אלינור",
	"אליענה",
	"אלירז",
	"אלישבע",
	"אלכס",
	"אלמוג",
	"אמונה",
	"אמור",
	"אן",
	"אנאבל",
	"אנה",
	"אסיף",
	"אסנת",
	"אסתר",
	"אפיק",
	"אפריל",
	"אפרת",
	"אראלה",
	"ארבל",
	"אריאל",
	"אריאלה",
	"אריאנה",
	"ארייה",
	"בארי",
	"בל",
	"בלה",
	"בר",
	"ברית",
	"ברקת",
	"בת",
	"בת אל",
	"בת חן",
	"בת שבע",
	"בתיה",
	"גאיה",
	"גבריאל",
	"גבריאלה",
	"גוון",
	"ג'וי",
	"ג'ון",
	"גוני",
	"גורן",
	"גיל",
	"גילי",
	"גילת",
	"גל",
	"גלי",
	"גליה",
	"גלית",
	"גפן",
	"דבורה",
	"דגן",
	"דגנית",
	"דור",
	"דורון",
	"דורין",
	"דורית",
	"דיאנה",
	"דינה",
	"דליה",
	"דנה",
	"דניאל",
	"דניאלה",
	"דנית",
	"דפנה",
	"דקל",
	"דקלה",
	"הגר",
	"הדס",
	"הדסה",
	"הדר",
	"הודיה",
	"הילה",
	"הלל",
	"ויויאן",
	"ויטה",
	"ויקטוריה",
	"ורד",
	"זהבה",
	"זוהר",
	"זיו",
	"חגית",
	"חושן",
	"חיננית",
	"חירות",
	"חמוטל",
	"חן",
	"חנה",
	"חנית",
	"טובה",
	"טופז",
	"טל",
	"טלי",
	"טליה",
	"טנא",
	"יהב",
	"יהודית",
	"יהל",
	"יהלום",
	"יהלי",
	"יובל",
	"יודפת",
	"יוכבד",
	"יוליה",
	"יונית",
	"יונת",
	"ים",
	"ימית",
	"יסכה",
	"יסמין",
	"יעל",
	"יעלה",
	"יערה",
	"יפה",
	"יפית",
	"יפעת",
	"ירדן",
	"כוכב",
	"כליל",
	"כרם",
	"כרמי",
	"כרמית",
	"כרמל",
	"לאה",
	"לבנה",
	"לוטם",
	"לוטן",
	"לוסי",
	"לי",
	"ליאור",
	"ליאורה",
	"ליאל",
	"ליאם",
	"ליאן",
	"ליאת",
	"ליב",
	"ליבי",
	"לידור",
	"ליה",
	"ליהיא",
	"ליזה",
	"ליטל",
	"לילי",
	"ליליה",
	"לילך",
	"לימור",
	"ליעד",
	"ליר",
	"לירון",
	"לירז",
	"לירן",
	"לשם",
	"מאור",
	"מאי",
	"מאיה",
	"מגי",
	"מגל",
	"מור",
	"מוריה",
	"מורן",
	"מיה",
	"מיטב",
	"מיטל",
	"מיכאל",
	"מיכאלה",
	"מיכל",
	"מיקה",
	"מירן",
	"מלכה",
	"מעיין",
	"מצדה",
	"מרב",
	"מריה",
	"מרים",
	"מרינה",
	"מתנה",
	"מתת",
	"נאוה",
	"נגה",
	"נוי",
	"נויה",
	"נועה",
	"נועם",
	"נופר",
	"נורית",
	"נטלי",
	"נטע",
	"נטעלי",
	"ניב",
	"נילי",
	"נינט",
	"ניצן",
	"נעמה",
	"נעמי",
	"נרי",
	"נריה",
	"סביון",
	"סהר",
	"סול",
	"סופיה",
	"סיגלית",
	"סיון",
	"סמדר",
	"ספיר",
	"סשה",
	"סתיו",
	"עדי",
	"עדן",
	"עדנה",
	"עומר",
	"עופרי",
	"עיינה",
	"עינב",
	"עינת",
	"עלמא",
	"עלמה",
	"עמית",
	"עמליה",
	"עמנואל",
	"ענבל",
	"ענבר",
	"ענהאל",
	"ענת",
	"עפרה",
	"ערגה",
	"פלג",
	"צאלה",
	"צוף",
	"צופיה",
	"צליל",
	"צמרת",
	"קורין",
	"קורל",
	"קטיה",
	"קליה",
	"קמה",
	"קסם",
	"קסניה",
	"קרן",
	"קשת",
	"רביד",
	"רבקה",
	"רויטל",
	"רוית",
	"רומי",
	"רון",
	"רונהלי",
	"רוני",
	"רונית",
	"רות",
	"רותם",
	"רז",
	"רחל",
	"ריטה",
	"רימון",
	"רינת",
	"רננה",
	"רעות",
	"שגב",
	"שגית",
	"שובל",
	"שוהם",
	"שולמית",
	"שונית",
	"שושנה",
	"שחף",
	"שחר",
	"שי",
	"שי לי",
	"שיר",
	"שיראל",
	"שירה",
	"שירז",
	"שירי",
	"שלהבת",
	"שלומית",
	"שלי",
	"שמחה",
	"שמרית",
	"שני",
	"שקד",
	"שקמה",
	"שרה",
	"שרון",
	"שרית",
	"תאיר",
	"תאנה",
	"תבור",
	"תהילה",
	"תו",
	"תום",
	"תומר",
	"תכלת",
	"תלמה",
	"תמר",
	"תמרה",
	"תפארת",
	"תקווה"

	];