import styled from "styled-components/native";
import { spacing } from "../../styles/theme";

const OverlayRightWrapper = styled.View`
  position: absolute;
  right: 0;
  top: calc(${spacing.large} + ${spacing.extraLarge});
`;

export default OverlayRightWrapper;
