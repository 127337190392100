import { useCallback, useState } from "react";
import { InlineResponse400Errors } from "../../bookmydesk-api-sdk-typescript-axios";

export interface InputValidationError {
  errors: InlineResponse400Errors[];
}

export function InputValidationError(
  this: InputValidationError,
  errors: InlineResponse400Errors[]
): void {
  this.errors = errors;
}

export const useApi = () => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRequest = useCallback(
    async <T>(request: Promise<T>, triggerLoading = true) => {
      if (triggerLoading) {
        setIsLoading(true);
      }
      try {
        const response = await request;
        setError("");
        return response;
      } catch (e) {
        console.log("The error yup", e.response);
        if (e instanceof Error) {
          setError(e.message);
        }
        console.error(e);
        if (e?.response?.data?.result?.errors) {
          throw new InputValidationError(e?.response?.data?.result?.errors);
        }
      } finally {
        if (triggerLoading) {
          setIsLoading(false);
        }
      }
    },
    [setIsLoading, setError]
  );

  const dismissError = useCallback(() => {
    setError("");
  }, [setError]);

  return { dismissError, error, isLoading, handleRequest };
};
