import React from "react";
import RouteWithTitle from "./RouteWithTitle";

import { t } from "../i18n";
import { Redirect, Router, Switch, Route } from "../routing";

// Pages
import Login from "../screens/Login";
import ResetPassword from "../screens/ResetPassword";

export default function UnAuthenticatedRoutes(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <RouteWithTitle
          exact
          screenTitle={t("login.title")}
          path="/login"
          component={Login}
        />
        <RouteWithTitle
          exact
          screenTitle={t("reset.title")}
          path="/reset-password"
          component={ResetPassword}
        />
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
}
