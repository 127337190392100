import moment, { Moment } from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { OccupationChartProps } from "../../../../fake-data/dashboardData";
import lineChartIcon from "../../../assets/icons/linechart.svg";
import LineChart from "../../../components/Chart/LineChart";
import Container from "../../../components/Container";
import DateRangeInput, {
  DateRangeInputProps,
} from "../../../components/DateRangeInput/DateRangeInput";
import Grid, { ChartColumn, Column } from "../../../components/Grid";
import HeaderCard, { HeaderCardLegend } from "../../../components/HeaderCard";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { t } from "../../../i18n";
import { color } from "../../../styles/theme";
import Title from "../../../styles/Title";
import { ThenArg } from "../../../types";
import { formatCurrency } from "../../../utils";
import { DashboardOverviewHeader, SimpleStatsWrapper, SimpleStatWrapper, StatText, Number } from "./DashboardStyles";


const currentDate = () => moment().subtract(0, "week").endOf("day");

// const useRevenueData = (): OccupationChartProps[] => {
//   const [revenueChartData, setRevenueChartData] = useState<
//     OccupationChartProps[]
//   >([]);
//   useEffect(() => {
//     setRevenueChartData([
//       {
//         id: t("admin.dashboard.admin.revenue"),
//         dataColor: color.primary,
//         lineData: [
//           {
//             x: moment("2022-01-01").toDate(),
//             y: 10000,
//           },
//           {
//             x: moment("2022-02-01").toDate(),
//             y: 15000,
//           },
//           {
//             x: moment("2022-03-01").toDate(),
//             y: 30000,
//           },
//         ],
//       },
//     ]);
//   }, [setRevenueChartData]);

//   return revenueChartData;
// };

const useStatisticsData = () => {
  const client = useClient();
  const [statistics, setStatistics] = useState<
    | ThenArg<
        ReturnType<typeof client.getBookMyDeskStatistics>
      >["data"]["result"]
    | null
  >(null);
  const { handleRequest } = useApi();

  useEffect(() => {
    handleRequest(client.getBookMyDeskStatistics()).then((response) => {
      if (response && response.data) {
        setStatistics(response.data.result);
      }
    });
  }, [client, handleRequest]);

  return statistics;
};

const filterActiveChartData = (
  chartData: OccupationChartProps[],
  activeLegend: string[] | null
) =>
  chartData.filter(
    (item) => activeLegend === null || activeLegend.includes(item.id)
  );

const filterAndSetActiveLegends =
  (setActiveLegends: (data: string[] | null) => void) =>
  (items: HeaderCardLegend[] | undefined) => {
    if (!items) {
      setActiveLegends(null);
      return;
    }
    console.log("items", items);
    setActiveLegends(
      items.filter((item) => item.isActive).map((item) => item.id)
    );
  };

const AdminDashboard: FC = () => {
  // const [startDate, setStartDate] = useState<Moment>(
  //   currentDate().subtract("1", "week").startOf("day")
  // );
  // const [endDate, setEndDate] = useState<Moment>(currentDate());
  // const onDateChange: DateRangeInputProps["onChange"] = useCallback(
  //   ([start, end]) => {
  //     setStartDate(moment(start));
  //     setEndDate(moment(end));
  //   },
  //   [setStartDate, setEndDate]
  // );
  // const revenueChartData = useRevenueData();
  // const [activeRevenueLegends, setActiveRevenueLegends] = useState<
  //   string[] | null
  // >(null);
  const statistics = useStatisticsData();

  if (!statistics) {
    return null;
  }

  return (
    <Container>
      <DashboardOverviewHeader>
        <View>
          <Title>{t("admin.dashboard.admin.title")}</Title>
        </View>
      </DashboardOverviewHeader>
      <Grid>
        <ChartColumn columns={3} style={{ zIndex: 20 }}>
          {/* {revenueChartData.length > 0 && (
            <HeaderCard
              legends={revenueChartData}
              onChangeActiveLegends={filterAndSetActiveLegends(
                setActiveRevenueLegends
              )}
              icon={lineChartIcon}
              title={t("admin.dashboard.admin.revenueTitle")}
              disableHorizontalDivider
              titleSuffix={
                <View style={{ marginLeft: "auto" }}>
                  <DateRangeInput
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onDateChange}
                  />
                </View>
              }
            >
              <LineChart
                tickValuesYFormat="currency"
                tickValuesY={[0, 50000]}
                data={filterActiveChartData(
                  revenueChartData,
                  activeRevenueLegends
                )}
              />
            </HeaderCard>
          )} */}
        </ChartColumn>
        <Column columns={3}>
          <HeaderCard title={t("admin.dashboard.admin.currentMonthlyRevenue")}>
            <SimpleStatsWrapper>
              <SimpleStatWrapper>
                <Number>
                  {formatCurrency(statistics.currentMonthRevenue)}
                </Number>
              </SimpleStatWrapper>
            </SimpleStatsWrapper>
          </HeaderCard>
          <HeaderCard title={t("admin.dashboard.admin.billableCompanies")}>
            <SimpleStatsWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.billedCompanyCount}</Number>
                <StatText>{t("admin.dashboard.admin.companies")}</StatText>
              </SimpleStatWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.billedUserCount}</Number>
                <StatText>{t("admin.dashboard.admin.users")}</StatText>
              </SimpleStatWrapper>
            </SimpleStatsWrapper>
          </HeaderCard>
          <HeaderCard title={t("admin.dashboard.admin.billingTypeCount")}>
            <SimpleStatsWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.billingTypeCount.enterprise}</Number>
                <StatText>
                  {t("admin.dashboard.admin.billingType.enterprise")}
                </StatText>
              </SimpleStatWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.billingTypeCount.year}</Number>
                <StatText>
                  {t("admin.dashboard.admin.billingType.year")}
                </StatText>
              </SimpleStatWrapper>
            </SimpleStatsWrapper>
            <SimpleStatsWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.billingTypeCount.month}</Number>
                <StatText>
                  {t("admin.dashboard.admin.billingType.month")}
                </StatText>
              </SimpleStatWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.billingTypeCount.free}</Number>
                <StatText>
                  {t("admin.dashboard.admin.billingType.free")}
                </StatText>
              </SimpleStatWrapper>
            </SimpleStatsWrapper>
          </HeaderCard>
          <HeaderCard title={t("admin.dashboard.admin.reservations")}>
            <SimpleStatsWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.reservationCount}</Number>
                <StatText>{t("admin.dashboard.admin.total")}</StatText>
              </SimpleStatWrapper>
              <SimpleStatWrapper>
                <Number>{statistics.todayReservationCount}</Number>
                <StatText>{t("admin.dashboard.admin.today")}</StatText>
              </SimpleStatWrapper>
            </SimpleStatsWrapper>
          </HeaderCard>
        </Column>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
