module["exports"] = [
  "Adolfa Waldingera",
  "Alanska",
  "Aljmaška",
  "Andrije Hebranga",
  "Andrije Kačića Miošića",
  "Andrije Mohorovičića",
  "Ante Paradžika",
  "Antuna Branka Šimića",
  "Antuna Kanižlića",
  "Antuna Mihanovića",
  "Antunovačka",
  "Bakarska",
  "Banjalučka",
  "Banova",
  "Bapska",
  "Baranjska",
  "Bartula Kašića",
  "Bartula Kašića",
  "Baščanska",
  "Bele Bartoka",
  "Belišćanska",
  "Belomanastirska",
  "Bihaćka",
  "Bilogorska",
  "Biogradska",
  "Biokovska",
  "Biševska",
  "Bjelolasička",
  "Bjelovarska",
  "Bogdanovačka",
  "Bokokotorska",
  "Bosanska",
  "Bračka",
  "Branka Radičevića",
  "Brijunska",
  "Brodska",
  "Brune Bjelinskog",
  "Buzetska",
  "Cara Hadrijana",
  "Cara Hadrijana",
  "Ćelijska",
  "Christiana Monspergera",
  "Ćićarijska",
  "Ciglarska",
  "Creska",
  "Crikvenička",
  "Crkvena",
  "Cvjetkova",
  "Cvjetna",
  "Čvrsnička",
  "Đakovačka",
  "Đakovština",
  "Daljska",
  "Dalmatinska",
  "Danice Pinterović",
  "Daruvarska",
  "Delnička",
  "Diljska",
  "Dinka Šimunovića",
  "Dobriše Cesarića",
  "Donjodravska obala",
  "Dragutina Domjanića",
  "Dragutina Neumana",
  "Drniška",
  "Dubrovačka",
  "Dugog otoka",
  "Erdutska",
  "Esperantska",
  "Eugena Kumičića",
  "Europska avenija",
  "Europska avenija",
  "Fakultetska",
  "Ferde Livadića",
  "Franje Antuna Blažića",
  "Franje Krežme",
  "Franje Kuhača",
  "Franje Markovića",
  "Franje Muckea",
  "Franjevačka",
  "Frankopanska",
  "Fruškogorska",
  "Gluha",
  "Gorička",
  "Gornjodravska obala",
  "Gospićka",
  "Gradačka",
  "Grgura Čevapovića",
  "Hercegovačka",
  "Hrvatske Republike",
  "Huge Conrada Hötzendorfa",
  "Humska",
  "Hvarska",
  "Ilirska",
  "Imotska",
  "Istarska",
  "Ivana Adamovića",
  "Ivana Gorana Kovačića",
  "Ivana Gundulića",
  "Ivana Zajca",
  "Ivane Brlić- Mažuranić",
  "Ivanovačka",
  "Ivanščice",
  "Ive Tijardovića",
  "Izidora Kršnjavoga",
  "Jahorinska",
  "Jankovačka",
  "Josipa Andrića",
  "Josipa Bösendorfera",
  "Josipa Huttlera",
  "Josipa Jurja Strossmayera",
  "Josipa Reihl Kira",
  "Jovana Gojkovića",
  "Južno predgrađe",
  "Kalnička",
  "Kamila Firingera",
  "Kapelska",
  "Kaptolska",
  "Kapucinska",
  "Kardinala Alojzija Stepinca",
  "Kardinala Alozija Stepinca",
  "Kaštelanska",
  "Klanac",
  "Kneza Borne",
  "Kneza Trpimira",
  "Kneza Trpimira",
  "Kninska",
  "Kolodvorska",
  "Kopačevska",
  "Koprivnička",
  "Korčulanska",
  "Kordunska",
  "Kornatska",
  "Kozjačka",
  "Krajna",
  "Kralja Petra Svačića",
  "Kralja Zvonimira",
  "Krčka",
  "Križevačka",
  "Krndije",
  "Krstova",
  "Ksavera Šandora Gjalskog",
  "Kutinska",
  "Kvarnerska",
  "Labinska",
  "Lađarska",
  "Laslovačka",
  "Lastovska",
  "Lavoslava Ružičke",
  "Ledine",
  "Lička",
  "Lipička",
  "Livadska",
  "Ljubljanska",
  "Ljudevita Posavskog",
  "Lokrumska",
  "Lopudska",
  "Lorenza Jägera",
  "Lošinjska",
  "Lovranska",
  "Lučki prilaz",
  "Lugovi",
  "Maceljska",
  "Makarska",
  "Marijana Lanosovića",
  "Marjanska",
  "Martina Divalta",
  "Matije Antuna Reljkovića",
  "Matije Gupca",
  "Matije Petra Katančića",
  "Medulinska",
  "Miholjačka",
  "Mije Kišpatića",
  "Milutina Milankovića",
  "Mlinska",
  "Mljetska",
  "Moslavačka",
  "Mosorska",
  "Mostarska",
  "Motovunska",
  "Murterska",
  "Naselje Antuna Gustava Matoša",
  "Naselje Miroslava Krleže",
  "Naselje Stanka Vraza",
  "Naselje Viševica",
  "Naselje Vladimira Nazora",
  "Našička",
  "Našičko naselje",
  "Nemetin",
  "Nikole Šubića Zrinskog",
  "Novogradiška",
  "Nuštarska",
  "Olibska",
  "Olimpije",
  "Opatijska",
  "Orahovička",
  "Orebićka",
  "Otokara Keršovanija",
  "Paklenička",
  "Pakračka",
  "Palagruška",
  "Palićka",
  "Pampas",
  "Papuk gore",
  "Park kneza Branimira",
  "Park kralja Petra Krešimira IV",
  "Park kraljice Katarine Kosača",
  "Paška",
  "Pašmanska",
  "Pavla Pejačevića",
  "Pazinska",
  "Pelješačka",
  "Perivoj kralja Tomislava",
  "Peručka",
  "Piranska",
  "Platačka",
  "Pleternička",
  "Plitvička",
  "Plješevička",
  "Podgoračka",
  "Podravska",
  "Podvinje",
  "Poljski put I",
  "Porečka",
  "Požeška",
  "Prenjska",
  "Prijedorska",
  "Primorska",
  "Prolaz Ante Slavičeka",
  "Prolaz Carla Bende",
  "Prolaz Josipa Leovića",
  "Prolaz Julija Benešića",
  "Prolaz Julija Knifera",
  "Prolaz kod Snježne Gospe",
  "Prolaz Lorenza Jägera",
  "Prolaz Matice Hrvatske",
  "Prolaz Radoslava Bačića",
  "Prolaz Vanje Radauša",
  "Prominska",
  "Psunjska",
  "Pulska",
  "Rapska",
  "Rastanci",
  "Reisnerova",
  "Reisnerova",
  "Retfala nova",
  "Ribarska",
  "Riječka",
  "Risnjačka",
  "Rovinjska",
  "Ruđera Boškovića",
  "Ružina",
  "Šamačka",
  "Šandora Petofia",
  "Sarajevska",
  "Šarengradska",
  "Sarvaška",
  "Senjska",
  "Šetalište kardinala Franje Šepera",
  "Šetalište kardinala Franje Šepera",
  "Šetalište Petra Preradovića",
  "Šetalište Vjekoslava Hengla",
  "Šibenska",
  "Silvija Strahimira Kranjčevića",
  "Širokobriješka",
  "Sisačka",
  "Sjenjak",
  "Školska",
  "Skopska",
  "Slatinska",
  "Slavonska",
  "Slaz",
  "Šlep u luci",
  "Sljemenska",
  "Snježnička",
  "Šokačka",
  "Solarski trg",
  "Šoltanska",
  "Splavarska",
  "Splitska",
  "Srijemska",
  "Stadionsko naselje",
  "Starigradska",
  "Štitarska",
  "Stjepana Radića",
  "Stonska",
  "Strma",
  "Subotička",
  "Šumska",
  "Sunčana",
  "Sv. Ane",
  "Sv. Petka",
  "Sv. Roka",
  "Svetog Leopolda Bogdana Mandića",
  "Svilajska",
  "Tadije Smičiklasa",
  "Tina Ujevića",
  "Tovarnička",
  "Travna",
  "Trg Ante Starčevića",
  "Trg Augusta Šenoe",
  "Trg bana Josipa Jelačića",
  "Trg Franje baruna Trenka",
  "Trg hrvatskih branitelja",
  "Trg Josifa Runjanina",
  "Trg Jurja Križanića",
  "Trg Lava Mirskog",
  "Trg Lavoslava Ružičke",
  "Trg Ljudevita Gaja",
  "Trg Nikole Tesle",
  "Trg Pape Ivana Pavla II",
  "Trg slobode",
  "Trg sv. Trojstva",
  "Trg Vatroslava Lisinskog",
  "Trogirska",
  "Trpanjska",
  "Tvornička",
  "Učka",
  "Ugljanska",
  "Umaška",
  "Valpovačka",
  "Varaždinska",
  "Vatrogasna",
  "Vatroslava Jagića",
  "Velaluška",
  "Velebitska",
  "Vidove gore",
  "Vijenac Augusta Cesarca",
  "Vijenac Dinare",
  "Vijenac Gorana Zobundžije",
  "Vijenac Ivana Česmičkog",
  "Vijenac Ivana Mažuranića",
  "Vijenac Ivana Meštrovića",
  "Vijenac Jakova Gotovca",
  "Vijenac Josipa Kozarca",
  "Vijenac Ljube Babića",
  "Vijenac Medvednice",
  "Vijenac Murse",
  "Vijenac Paje Kolarića",
  "Vijenac Petrove gore",
  "Vijenac Slavka Kolara",
  "Vijenac Vlahe Bukovca",
  "Vilajska",
  "Vinkovačka cesta",
  "Vinogradska",
  "Virovitička",
  "Virska",
  "Viška",
  "Vjekoslava Klaića",
  "Vjenceslava Novaka",
  "Vladimira Preloga",
  "Vladimira Vidrića",
  "Vlašićka",
  "Voćarska",
  "Voćarska ",
  "Voćinska",
  "Vodenička",
  "Vranska",
  "Vratnička",
  "Vrbik",
  "Vrt Jagode Truhelke",
  "Vrtna",
  "Vukovarska cesta",
  "Vukovarska cesta",
  "Woodrowa Wilsona",
  "Zadarska",
  "Zagorska",
  "Zagrebačka",
  "Zaostroška",
  "Zeleno polje",
  "Zlarinska",
  "Zrinjevac",
  "Zrinske gore",
  "Žumberačka",
  "Županijska",
  "Zvečevska"
];
