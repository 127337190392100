module["exports"] = [
  "África do Sul",
  "Áustria",
  "Índia",
  "Afeganistão",
  "Albânia",
  "Alemanha",
  "Andorra",
  "Angola",
  "Anguila",
  "Antárctida",
  "Antígua e Barbuda",
  "Antilhas Neerlandesas",
  "Arábia Saudita",
  "Argélia",
  "Argentina",
  "Arménia",
  "Aruba",
  "Austrália",
  "Azerbaijão",
  "Bélgica",
  "Bósnia e Herzegovina",
  "Baamas",
  "Bangladeche",
  "Barém",
  "Barbados",
  "Belize",
  "Benim",
  "Bermudas",
  "Bielorrússia",
  "Birmânia",
  "Bolívia",
  "Botsuana",
  "Brasil",
  "Brunei",
  "Bulgária",
  "Burúndi",
  "Burquina Faso",
  "Butão",
  "Cabo Verde",
  "Camarões",
  "Camboja",
  "Canadá",
  "Catar",
  "Cazaquistão",
  "Chade",
  "Chile",
  "China",
  "Chipre",
  "Colômbia",
  "Comores",
  "Congo-Brazzaville",
  "Congo-Kinshasa",
  "Coreia do Norte",
  "Coreia do Sul",
  "Costa Rica",
  "Costa do Marfim",
  "Croácia",
  "Cuba",
  "Dinamarca",
  "Domínica",
  "Egipto",
  "Emiratos Árabes Unidos",
  "Equador",
  "Eritreia",
  "Eslováquia",
  "Eslovénia",
  "Espanha",
  "Estónia",
  "Estados Unidos",
  "Etiópia",
  "Faroé",
  "Fiji",
  "Filipinas",
  "Finlândia",
  "França",
  "Gâmbia",
  "Gabão",
  "Gana",
  "Geórgia",
  "Geórgia do Sul e Sandwich do Sul",
  "Gibraltar",
  "Grécia",
  "Granada",
  "Gronelândia",
  "Guadalupe",
  "Guame",
  "Guatemala",
  "Guiana",
  "Guiana Francesa",
  "Guiné",
  "Guiné Equatorial",
  "Guiné-Bissau",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungria",
  "Iémen",
  "Ilha Bouvet",
  "Ilha Norfolk",
  "Ilha do Natal",
  "Ilhas Caimão",
  "Ilhas Cook",
  "Ilhas Falkland",
  "Ilhas Heard e McDonald",
  "Ilhas Marshall",
  "Ilhas Menores Distantes dos Estados Unidos",
  "Ilhas Salomão",
  "Ilhas Turcas e Caicos",
  "Ilhas Virgens Americanas",
  "Ilhas Virgens Britânicas",
  "Ilhas dos Cocos",
  "Indonésia",
  "Irão",
  "Iraque",
  "Irlanda",
  "Islândia",
  "Israel",
  "Itália",
  "Jamaica",
  "Japão",
  "Jibuti",
  "Jordânia",
  "Jugoslávia",
  "Kuwait",
  "Líbano",
  "Líbia",
  "Laos",
  "Lesoto",
  "Letónia",
  "Libéria",
  "Listenstaine",
  "Lituânia",
  "Luxemburgo",
  "México",
  "Mónaco",
  "Macau",
  "Macedónia",
  "Madagáscar",
  "Malásia",
  "Malávi",
  "Maldivas",
  "Mali",
  "Malta",
  "Marianas do Norte",
  "Marrocos",
  "Martinica",
  "Maurícia",
  "Mauritânia",
  "Mayotte",
  "Micronésia",
  "Moçambique",
  "Moldávia",
  "Mongólia",
  "Monserrate",
  "Níger",
  "Namíbia",
  "Nauru",
  "Nepal",
  "Nicarágua",
  "Nigéria",
  "Niue",
  "Noruega",
  "Nova Caledónia",
  "Nova Zelândia",
  "Omã",
  "Países Baixos",
  "Palau",
  "Panamá",
  "Papua-Nova Guiné",
  "Paquistão",
  "Paraguai",
  "Peru",
  "Pitcairn",
  "Polónia",
  "Polinésia Francesa",
  "Porto Rico",
  "Portugal",
  "Quénia",
  "Quirguizistão",
  "Quiribáti",
  "Rússia",
  "Reino Unido",
  "República Centro-Africana",
  "República Checa",
  "República Dominicana",
  "Reunião",
  "Roménia",
  "Ruanda",
  "São Cristóvão e Neves",
  "São Marinho",
  "São Pedro e Miquelon",
  "São Tomé e Príncipe",
  "São Vicente e Granadinas",
  "Síria",
  "Salvador",
  "Samoa",
  "Samoa Americana",
  "Santa Helena",
  "Santa Lúcia",
  "Sara Ocidental",
  "Seicheles",
  "Senegal",
  "Serra Leoa",
  "Singapura",
  "Somália",
  "Sri Lanca",
  "Suécia",
  "Suíça",
  "Suazilândia",
  "Sudão",
  "Suriname",
  "Svalbard e Jan Mayen",
  "Tailândia",
  "Taiwan",
  "Tajiquistão",
  "Tanzânia",
  "Território Britânico do Oceano Índico",
  "Territórios Austrais Franceses",
  "Timor Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trindade e Tobago",
  "Tunísia",
  "Turquemenistão",
  "Turquia",
  "Tuvalu",
  "Ucrânia",
  "Uganda",
  "Uruguai",
  "Usbequistão",
  "Vanuatu",
  "Vaticano",
  "Venezuela",
  "Vietname",
  "Wallis e Futuna",
  "Zâmbia",
  "Zimbabué"
];
