import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "../../../routing";

import styled from "styled-components/native";
import {
  ReportReasonWithoutId,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import { t } from "../../../i18n";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select, { SelectItemInterface } from "../../../components/Select";

import { MediumTitle } from "../../../styles/Title";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";

import { useClient } from "../../../hooks/useClient";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import { useAuth } from "../../../context/authContext";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [reason, setReason] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [locations, setLocations] = useState<SelectItemInterface[]>([]);

  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();
  const userCompany = userState?.companyIds[0];

  useEffect(() => {
    if (userCompany) {
      const fetchLocations = async () =>
        await handleRequest(client.listLocations(userCompany));

      fetchLocations().then((result) => {
        if (!result || !result.data.result) {
          return;
        }
        const { items } = result.data.result;
        const locationItems: SelectItemInterface[] = items.map((location) => ({
          label: location.name,
          value: location.id,
        }));
        setLocations(locationItems);
      });
    }
  }, [client, handleRequest]);

  const isFormValid = useCallback(
    () => Boolean(selectedLocation) && Boolean(reason),
    [selectedLocation, reason]
  );

  const submitForm = useCallback(async () => {
    const report: ReportReasonWithoutId = {
      reason,
      locationId: selectedLocation,
    };
    try {
      const success = await handleRequest(client.addReportReason(report));

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [handleRequest, client, reason, selectedLocation, history]);

  const updateLocation = useCallback(
    (newValue) => {
      setSelectedLocation(newValue[0].value);
    },
    [setSelectedLocation]
  );

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.notification.form.addTitle")}
          </MediumTitle>
          <LabeledInput label={t("admin.notification.form.reason")} required>
            <Input
              onChangeText={setReason}
              value={reason}
              placeholder={t("admin.notification.form.reason") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.notification.form.location")} required>
            <Select
              isMultiSelect={false}
              required
              items={locations}
              onChange={updateLocation}
              placeholder={t("admin.notification.form.location") + " *"}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.notification.form.createSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
