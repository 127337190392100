import styled from "styled-components/native";
import { SmallText } from "../Text";
import { borderRadius, color, font, spacing } from "../theme";

export const indicatorSize = "22px";

const AmountIndicator = styled(SmallText)`
  background-color: ${color.white};
  width: ${indicatorSize};
  height: ${indicatorSize};
  border-radius: ${borderRadius.full};
  text-align: center;
  line-height: ${indicatorSize};
  font-family: ${font.defaultMedium};
  margin-right: ${spacing.small};
`;

export default AmountIndicator;
