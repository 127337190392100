import React, { FC, useCallback, useState, useEffect } from "react";
import { useParams, useHistory } from "../../../routing";

import styled from "styled-components/native";
import { t } from "../../../i18n";
import {
  TimeslotProperties,
  TimeslotType,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select, { SelectItemInterface } from "../../../components/Select";
import { timeslotTypes } from "./Create";

import { MediumTitle } from "../../../styles/Title";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";

import { useClient } from "../../../hooks/useClient";
import { InputValidationError, useApi } from "../../../hooks/useApi";

import { isValidTime } from "../../../utils";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [selectedType, setSelectedType] = useState<TimeslotType | null>(null);
  const [types, setTypes] = useState<SelectItemInterface[]>(timeslotTypes);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const { id } = useParams();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  useEffect(() => {
    const fetchTimeslot = async () =>
      await handleRequest(client.getTimeslot(id));

    fetchTimeslot().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { name, type, from, to } = result.data.result.timeslot;
      setName(name);
      setSelectedType(type);
      setFrom(from);
      setTo(to);
      const updatedTypes = types.map((role) => ({
        ...role,
        isActive: role.value === type,
      }));
      setTypes(updatedTypes);
    });
  }, [id, client, handleRequest]);

  const isFormValid = useCallback(
    () =>
      Boolean(name) &&
      Boolean(selectedType) &&
      isValidTime(from) &&
      isValidTime(to),
    [name, selectedType, from, to]
  );

  const submitForm = useCallback(async () => {
    const timeslot: TimeslotProperties = {
      name,
      type: selectedType!,
      from,
      to,
    };
    try {
      const success = await handleRequest(client.updateTimeslot(id, timeslot));

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [handleRequest, client, id, name, selectedType, from, to, history]);

  const updateType = useCallback(
    (newValue) => {
      setSelectedType(newValue[0].value);
    },
    [setSelectedType]
  );

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.timeslot.form.editTitle")}
          </MediumTitle>
          <LabeledInput label={t("admin.timeslot.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.timeslot.form.name")}
            />
          </LabeledInput>
          <LabeledInput required label={t("admin.timeslot.form.type")}>
            <Select
              isMultiSelect={false}
              required
              items={types}
              onChange={updateType}
              placeholder={t("admin.timeslot.form.type")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.timeslot.form.start")}
            helperText={t("admin.timeslot.form.helper.from")}
            required
          >
            <Input
              onChangeText={setFrom}
              value={from}
              placeholder={t("admin.timeslot.form.start")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.timeslot.form.end")}
            helperText={t("admin.timeslot.form.helper.to")}
            required
          >
            <Input
              onChangeText={setTo}
              value={to}
              placeholder={t("admin.timeslot.form.end")}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.timeslot.form.editSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
