var es = {};
module['exports'] = es;
es.title = "Spanish";
es.address = require("./address");
es.company = require("./company");
es.internet = require("./internet");
es.name = require("./name");
es.phone_number = require("./phone_number");
es.cell_phone = require("./cell_phone");
es.commerce = require("./commerce");
