import { useState, useEffect } from "react";
import moment from "moment-timezone";

interface Item {
  label: string;
  value: string;
}

export const useTimezones = (): Item[] => {
  const [timezones, setTimezones] = useState<Item[]>([]);

  useEffect(() => {
    setTimezones(
      moment.tz
        .names()
        .filter((timezone) => timezone.indexOf("Etc/") === -1)
        .map((timezone) => {
          const offset = moment.tz(timezone).utcOffset() / 60;
          return {
            label: `${timezone} UTC${offset >= 0 ? "+" : ""}${offset}:00`,
            value: timezone,
          };
        })
    );
  }, []);

  return timezones;
};

export default useTimezones;
