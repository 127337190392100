module["exports"] = [
  "rood",
  "groen",
  "blauw",
  "koningblauw",
  "geel",
  "purper",
  "muntgroen",
  "groenblauw",
  "wit",
  "zwart",
  "oranje",
  "roze",
  "grijs",
  "donkergrijs",
  "oker",
  "terracotta",
  "kastanjebruin",
  "paars",
  "turkoois",
  "bruin",
  "hemelsblauw",
  "zalmroze",
  "oudroze",
  "appelgroen",
  "levend wit",
  "olijfgroen",
  "magenta",
  "limoen",
  "ivoor",
  "indigo",
  "goud",
  "fuchsia",
  "cyaan",
  "azuurblauw",
  "lavendel",
  "zilver"
];
