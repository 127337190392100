module["exports"] = [
  "Մարիա",
  "Նարե",
  "Մանե",
  "Մարի",
  "Մարիամ",
  "Անի",
  "Անգելինա",
  "Անահիտ",
  "Էլեն",
  "Միլենա",
  "Եվա",
  "Աննա",
  "Արփի",
  "Գայանե",
  "Լիլիթ",
  "Մերի",
  "Վիկտորյա",
  "Արինա",
  "Յանա",
  "Սոնա",
  "Նատալի",
  "Գոհար",
  "Հասմիկ",
  "Սոֆի",
  "Էմիլի",
  "Սուսաննա",
  "Արևիկ",
  "Կարինե",
  "Լյուսի",
  "Արիանա",
  "Տաթև",
  "Լիկա",
  "Ռուզաննա",
  "Լիլի",
  "Սոֆյա",
  "Անուշ",
  "Էլինա",
  "Սառա",
  "Նանե",
  "Էվա",
  "Լիանա",
  "Մոնիկա",
  "Կարինա",
  "Լիա",
  "Նարինե",
  "Լուսինե",
  "Դավիթ",
  "Նարեկ",
  "Ալեքս",
  "Տիգրան",
  "Հայկ",
  "Գոռ",
  "Արթուր",
  "Արմեն",
  "Մարկ",
  "Էրիկ",
  "Արամ",
  "Սամվել",
  "Արման",
  "Ալեն",
  "Աշոտ",
  "Արեն",
  "Գագիկ",
  "Դանիել",
  "Արեգ",
  "Ռոբերտ",
  "Արտյոմ",
  "Արսեն",
  "Միքայել",
  "Սարգիս",
  "Հովհաննես",
  "Վահե",
  "Կարեն",
  "Գևորգ",
  "Լևոն",
  "Վարդան",
  "Ռուբեն",
  "Սուրեն",
  "Հակոբ",
  "Մհեր",
  "Անդրանիկ",
  "Ավետ",
  "Գրիգոր",
  "Ռաֆայել",
  "Ալեքսանդր",
  "Մոնթե",
  "Հարություն",
  "Էդուարդ",
  "Ալբերտ",
  "Գարիկ",
  "Համլետ"
];