/* tslint:disable */
/* eslint-disable */
/**
 * BookMyDesk API with Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminUser
 */
export interface AdminUser {
    /**
     * 
     * @type {number}
     * @memberof AdminUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    infix?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUser
     */
    newsletter?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUser
     */
    roles: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof AdminUser
     */
    language: Language | null;
}
/**
 * 
 * @export
 * @interface AdminUserAllOf
 */
export interface AdminUserAllOf {
    /**
     * 
     * @type {number}
     * @memberof AdminUserAllOf
     */
    id: number;
}
/**
 * 
 * @export
 * @interface AdminUserCreate
 */
export interface AdminUserCreate {
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreate
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreate
     */
    infix?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreate
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreate
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserCreate
     */
    newsletter?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserCreate
     */
    roles: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof AdminUserCreate
     */
    language: Language | null;
    /**
     * Password for the user. If not provided, a random password will be generated.
     * @type {string}
     * @memberof AdminUserCreate
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreate
     */
    companyId: string;
    /**
     * Send an email to the user with the password reset details
     * @type {boolean}
     * @memberof AdminUserCreate
     */
    sendEmail?: boolean;
}
/**
 * 
 * @export
 * @interface AdminUserCreateAllOf
 */
export interface AdminUserCreateAllOf {
    /**
     * Password for the user. If not provided, a random password will be generated.
     * @type {string}
     * @memberof AdminUserCreateAllOf
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreateAllOf
     */
    companyId: string;
    /**
     * Send an email to the user with the password reset details
     * @type {boolean}
     * @memberof AdminUserCreateAllOf
     */
    sendEmail?: boolean;
}
/**
 * 
 * @export
 * @interface AdminUserProperties
 */
export interface AdminUserProperties {
    /**
     * 
     * @type {string}
     * @memberof AdminUserProperties
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserProperties
     */
    infix?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserProperties
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserProperties
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserProperties
     */
    newsletter?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserProperties
     */
    roles?: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof AdminUserProperties
     */
    language?: Language | null;
}
/**
 * 
 * @export
 * @interface AdminUserUpdate
 */
export interface AdminUserUpdate {
    /**
     * 
     * @type {string}
     * @memberof AdminUserUpdate
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserUpdate
     */
    infix?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserUpdate
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserUpdate
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserUpdate
     */
    newsletter?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserUpdate
     */
    roles?: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof AdminUserUpdate
     */
    language?: Language | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserUpdate
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface AdminUserUpdateAllOf
 */
export interface AdminUserUpdateAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdminUserUpdateAllOf
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface AdminUserWithRelations
 */
export interface AdminUserWithRelations {
    /**
     * 
     * @type {number}
     * @memberof AdminUserWithRelations
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithRelations
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithRelations
     */
    infix?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithRelations
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithRelations
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserWithRelations
     */
    newsletter?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserWithRelations
     */
    roles: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof AdminUserWithRelations
     */
    language: Language | null;
    /**
     * 
     * @type {Array<Company>}
     * @memberof AdminUserWithRelations
     */
    companies: Array<Company>;
}
/**
 * 
 * @export
 * @interface AdminUserWithRelationsAllOf
 */
export interface AdminUserWithRelationsAllOf {
    /**
     * 
     * @type {Array<Company>}
     * @memberof AdminUserWithRelationsAllOf
     */
    companies: Array<Company>;
}
/**
 * 
 * @export
 * @interface AdminUserWithoutId
 */
export interface AdminUserWithoutId {
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutId
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutId
     */
    infix?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutId
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutId
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserWithoutId
     */
    newsletter?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserWithoutId
     */
    roles: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof AdminUserWithoutId
     */
    language: Language | null;
}
/**
 * 
 * @export
 * @interface AdminUserWithoutIdAllOf
 */
export interface AdminUserWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutIdAllOf
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutIdAllOf
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutIdAllOf
     */
    email: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserWithoutIdAllOf
     */
    roles: Array<string>;
    /**
     * 
     * @type {Language}
     * @memberof AdminUserWithoutIdAllOf
     */
    language: Language | null;
}
/**
 * 
 * @export
 * @interface Analytics
 */
export interface Analytics {
    /**
     * 
     * @type {AnalyticsInsights}
     * @memberof Analytics
     */
    insights: AnalyticsInsights;
}
/**
 * 
 * @export
 * @interface AnalyticsInsights
 */
export interface AnalyticsInsights {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsInsights
     */
    active_check_ins: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsInsights
     */
    missed_check_ins: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BillingType {
    Enterprise = 'enterprise',
    None = 'none',
    Free = 'free',
    Year = 'year',
    Month = 'month',
    ContactForm = 'contact-form',
    Stopped = 'stopped'
}

/**
 * Type for checkin, usually manual
 * @export
 * @enum {string}
 */
export enum CheckInType {
    Manual = 'manual',
    Geofence = 'geofence',
    OnCreate = 'on_create'
}

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {Language}
     * @memberof Company
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    reservationWindowLength: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof Company
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof Company
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof Company
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof Company
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof Company
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof Company
     */
    created?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    contactFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    contactLastname: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    contactPhone: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    billingPricePerCustomer: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    billingId?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    billingStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    billingEndDate?: string | null;
    /**
     * 
     * @type {BillingType}
     * @memberof Company
     */
    billingType: BillingType;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    billingVoucherCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    autoProvisioningDomain?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    compensationCutOffDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    compensationMaxDailyDistance?: number | null;
    /**
     * 
     * @type {DistanceMetric}
     * @memberof Company
     */
    compensationDistanceMetric?: DistanceMetric;
    /**
     * Amount in cents
     * @type {number}
     * @memberof Company
     */
    compensationDistanceUnitCompensation?: number | null;
    /**
     * Amount in cents
     * @type {number}
     * @memberof Company
     */
    compensationDailyHomeCompensation?: number | null;
    /**
     * ISO_4217 format: https://en.wikipedia.org/wiki/ISO_4217
     * @type {string}
     * @memberof Company
     */
    compensationCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    compensationExportEmail?: string | null;
    /**
     * Make visitors anonymous after X days
     * @type {number}
     * @memberof Company
     */
    visitorAnonymousDays?: number | null;
    /**
     * Make reservations anonymous after X days
     * @type {number}
     * @memberof Company
     */
    reservationAnonymousDays?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    ssoDomain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    surfconextSsoEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    saltoClientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    saltoClientSecret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    saltoUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    saltoPassword?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyBasic
 */
export interface CompanyBasic {
    /**
     * 
     * @type {string}
     * @memberof CompanyBasic
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasic
     */
    name: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyBasic
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * Deprecated. Use reservationWindowLengthDesk, reservationWindowLengthLunch, reservationWindowLengthMeeting, reservationWindowLengthVisitor and reservationWindowLengthResource in user object instead
     * @type {number}
     * @memberof CompanyBasic
     * @deprecated
     */
    reservationWindowLength: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasic
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasic
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasic
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasic
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasic
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasic
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasic
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasic
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasic
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasic
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasic
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasic
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasic
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyBasic
     */
    created?: any | null;
}
/**
 * 
 * @export
 * @interface CompanyBasicProperties
 */
export interface CompanyBasicProperties {
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicProperties
     */
    name?: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyBasicProperties
     */
    language?: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    parkingSpotsAsResourceEnabled?: boolean;
    /**
     * This field is deprecated. Use the reservationWindowLength(desk/meeting/lunch/visitor) properties of the User instead.
     * @type {number}
     * @memberof CompanyBasicProperties
     * @deprecated
     */
    reservationWindowLength?: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicProperties
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicProperties
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicProperties
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyBasicProperties
     */
    created?: any | null;
}
/**
 * 
 * @export
 * @interface CompanyBasicWithRelations
 */
export interface CompanyBasicWithRelations {
    /**
     * 
     * @type {Array<Location>}
     * @memberof CompanyBasicWithRelations
     */
    locations: Array<Location>;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicWithRelations
     */
    name: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyBasicWithRelations
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * Deprecated. Use reservationWindowLengthDesk, reservationWindowLengthLunch, reservationWindowLengthMeeting, reservationWindowLengthVisitor and reservationWindowLengthResource in user object instead
     * @type {number}
     * @memberof CompanyBasicWithRelations
     * @deprecated
     */
    reservationWindowLength: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithRelations
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicWithRelations
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithRelations
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyBasicWithRelations
     */
    created?: any | null;
}
/**
 * 
 * @export
 * @interface CompanyBasicWithRelationsAllOf
 */
export interface CompanyBasicWithRelationsAllOf {
    /**
     * 
     * @type {Array<Location>}
     * @memberof CompanyBasicWithRelationsAllOf
     */
    locations: Array<Location>;
}
/**
 * 
 * @export
 * @interface CompanyBasicWithoutId
 */
export interface CompanyBasicWithoutId {
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicWithoutId
     */
    name: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyBasicWithoutId
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * Deprecated. Use reservationWindowLengthDesk, reservationWindowLengthLunch, reservationWindowLengthMeeting, reservationWindowLengthVisitor and reservationWindowLengthResource in user object instead
     * @type {number}
     * @memberof CompanyBasicWithoutId
     * @deprecated
     */
    reservationWindowLength: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyBasicWithoutId
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicWithoutId
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutId
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyBasicWithoutId
     */
    created?: any | null;
}
/**
 * 
 * @export
 * @interface CompanyBasicWithoutIdAllOf
 */
export interface CompanyBasicWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicWithoutIdAllOf
     */
    name: string;
    /**
     * Deprecated. Use reservationWindowLengthDesk, reservationWindowLengthLunch, reservationWindowLengthMeeting, reservationWindowLengthVisitor and reservationWindowLengthResource in user object instead
     * @type {number}
     * @memberof CompanyBasicWithoutIdAllOf
     * @deprecated
     */
    reservationWindowLength: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBasicWithoutIdAllOf
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * 
     * @type {Language}
     * @memberof CompanyBasicWithoutIdAllOf
     */
    language: Language;
}
/**
 * 
 * @export
 * @interface CompanyProperties
 */
export interface CompanyProperties {
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    name?: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyProperties
     */
    language?: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    parkingSpotsAsResourceEnabled?: boolean;
    /**
     * This field is deprecated. Use the reservationWindowLength(desk/meeting/lunch/visitor) properties of the User instead.
     * @type {number}
     * @memberof CompanyProperties
     * @deprecated
     */
    reservationWindowLength?: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyProperties
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyProperties
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyProperties
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyProperties
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyProperties
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyProperties
     */
    created?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    contactFirstname?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    contactLastname?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    contactPhone?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    billingPricePerCustomer?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    billingId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    billingStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    billingEndDate?: string | null;
    /**
     * 
     * @type {BillingType}
     * @memberof CompanyProperties
     */
    billingType?: BillingType;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    billingVoucherCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    autoProvisioningDomain?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    compensationCutOffDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyProperties
     */
    compensationMaxDailyDistance?: number | null;
    /**
     * 
     * @type {DistanceMetric}
     * @memberof CompanyProperties
     */
    compensationDistanceMetric?: DistanceMetric;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyProperties
     */
    compensationDistanceUnitCompensation?: number | null;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyProperties
     */
    compensationDailyHomeCompensation?: number | null;
    /**
     * ISO_4217 format: https://en.wikipedia.org/wiki/ISO_4217
     * @type {string}
     * @memberof CompanyProperties
     */
    compensationCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    compensationExportEmail?: string | null;
    /**
     * Make visitors anonymous after X days
     * @type {number}
     * @memberof CompanyProperties
     */
    visitorAnonymousDays?: number | null;
    /**
     * Make reservations anonymous after X days
     * @type {number}
     * @memberof CompanyProperties
     */
    reservationAnonymousDays?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    ssoDomain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyProperties
     */
    surfconextSsoEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    saltoClientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    saltoClientSecret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    saltoUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyProperties
     */
    saltoPassword?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyPropertiesAllOf
 */
export interface CompanyPropertiesAllOf {
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    contactFirstname?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    contactLastname?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    contactPhone?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    billingPricePerCustomer?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    billingId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    billingStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    billingEndDate?: string | null;
    /**
     * 
     * @type {BillingType}
     * @memberof CompanyPropertiesAllOf
     */
    billingType?: BillingType;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    billingVoucherCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    autoProvisioningDomain?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    compensationCutOffDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    compensationMaxDailyDistance?: number | null;
    /**
     * 
     * @type {DistanceMetric}
     * @memberof CompanyPropertiesAllOf
     */
    compensationDistanceMetric?: DistanceMetric;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    compensationDistanceUnitCompensation?: number | null;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    compensationDailyHomeCompensation?: number | null;
    /**
     * ISO_4217 format: https://en.wikipedia.org/wiki/ISO_4217
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    compensationCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    compensationExportEmail?: string | null;
    /**
     * Make visitors anonymous after X days
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    visitorAnonymousDays?: number | null;
    /**
     * Make reservations anonymous after X days
     * @type {number}
     * @memberof CompanyPropertiesAllOf
     */
    reservationAnonymousDays?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    ssoDomain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyPropertiesAllOf
     */
    surfconextSsoEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    saltoClientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    saltoClientSecret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    saltoUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyPropertiesAllOf
     */
    saltoPassword?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CompanyTokenType {
    Admin = 'microsoft_graph_admin',
    Rooms = 'microsoft_graph_rooms'
}

/**
 * 
 * @export
 * @interface CompanyTransportOption
 */
export interface CompanyTransportOption {
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOption
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOption
     */
    companyId: string;
    /**
     * 
     * @type {TransportType}
     * @memberof CompanyTransportOption
     */
    type: TransportType;
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOption
     */
    name: string;
    /**
     * 
     * @type {any}
     * @memberof CompanyTransportOption
     */
    updatedAt?: any | null;
    /**
     * 
     * @type {any}
     * @memberof CompanyTransportOption
     */
    created?: any | null;
}
/**
 * 
 * @export
 * @interface CompanyTransportOptionProperties
 */
export interface CompanyTransportOptionProperties {
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOptionProperties
     */
    companyId?: string;
    /**
     * 
     * @type {TransportType}
     * @memberof CompanyTransportOptionProperties
     */
    type?: TransportType;
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOptionProperties
     */
    name?: string;
    /**
     * 
     * @type {any}
     * @memberof CompanyTransportOptionProperties
     */
    updatedAt?: any | null;
    /**
     * 
     * @type {any}
     * @memberof CompanyTransportOptionProperties
     */
    created?: any | null;
}
/**
 * 
 * @export
 * @interface CompanyTransportOptionWithoutId
 */
export interface CompanyTransportOptionWithoutId {
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOptionWithoutId
     */
    companyId: string;
    /**
     * 
     * @type {TransportType}
     * @memberof CompanyTransportOptionWithoutId
     */
    type: TransportType;
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOptionWithoutId
     */
    name: string;
    /**
     * 
     * @type {any}
     * @memberof CompanyTransportOptionWithoutId
     */
    updatedAt?: any | null;
    /**
     * 
     * @type {any}
     * @memberof CompanyTransportOptionWithoutId
     */
    created?: any | null;
}
/**
 * 
 * @export
 * @interface CompanyTransportOptionWithoutIdAllOf
 */
export interface CompanyTransportOptionWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOptionWithoutIdAllOf
     */
    companyId: string;
    /**
     * 
     * @type {TransportType}
     * @memberof CompanyTransportOptionWithoutIdAllOf
     */
    type: TransportType;
    /**
     * 
     * @type {string}
     * @memberof CompanyTransportOptionWithoutIdAllOf
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CompanyWithCounts
 */
export interface CompanyWithCounts {
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    name: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyWithCounts
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationWindowLength: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyWithCounts
     */
    created?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    contactFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    contactLastname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    contactPhone: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    billingPricePerCustomer: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    billingId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    billingStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    billingEndDate?: string | null;
    /**
     * 
     * @type {BillingType}
     * @memberof CompanyWithCounts
     */
    billingType: BillingType;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    billingVoucherCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    autoProvisioningDomain?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    compensationCutOffDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    compensationMaxDailyDistance?: number | null;
    /**
     * 
     * @type {DistanceMetric}
     * @memberof CompanyWithCounts
     */
    compensationDistanceMetric?: DistanceMetric;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyWithCounts
     */
    compensationDistanceUnitCompensation?: number | null;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyWithCounts
     */
    compensationDailyHomeCompensation?: number | null;
    /**
     * ISO_4217 format: https://en.wikipedia.org/wiki/ISO_4217
     * @type {string}
     * @memberof CompanyWithCounts
     */
    compensationCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    compensationExportEmail?: string | null;
    /**
     * Make visitors anonymous after X days
     * @type {number}
     * @memberof CompanyWithCounts
     */
    visitorAnonymousDays?: number | null;
    /**
     * Make reservations anonymous after X days
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationAnonymousDays?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    ssoDomain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithCounts
     */
    surfconextSsoEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    saltoClientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    saltoClientSecret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    saltoUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithCounts
     */
    saltoPassword?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    locationCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    mapCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    userCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    activeUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCounts
     */
    reservationCount: number;
}
/**
 * 
 * @export
 * @interface CompanyWithCountsAllOf
 */
export interface CompanyWithCountsAllOf {
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCountsAllOf
     */
    locationCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCountsAllOf
     */
    mapCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCountsAllOf
     */
    userCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCountsAllOf
     */
    activeUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithCountsAllOf
     */
    reservationCount: number;
}
/**
 * 
 * @export
 * @interface CompanyWithRelations
 */
export interface CompanyWithRelations {
    /**
     * 
     * @type {Array<CompanyWithRelationsAllOfTokens>}
     * @memberof CompanyWithRelations
     */
    tokens: Array<CompanyWithRelationsAllOfTokens>;
    /**
     * 
     * @type {Array<Location>}
     * @memberof CompanyWithRelations
     */
    locations: Array<Location>;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    name: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyWithRelations
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    reservationWindowLength: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithRelations
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithRelations
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithRelations
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithRelations
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithRelations
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyWithRelations
     */
    created?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    contactFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    contactLastname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    contactPhone: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    billingPricePerCustomer: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    billingId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    billingStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    billingEndDate?: string | null;
    /**
     * 
     * @type {BillingType}
     * @memberof CompanyWithRelations
     */
    billingType: BillingType;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    billingVoucherCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    autoProvisioningDomain?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    compensationCutOffDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithRelations
     */
    compensationMaxDailyDistance?: number | null;
    /**
     * 
     * @type {DistanceMetric}
     * @memberof CompanyWithRelations
     */
    compensationDistanceMetric?: DistanceMetric;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyWithRelations
     */
    compensationDistanceUnitCompensation?: number | null;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyWithRelations
     */
    compensationDailyHomeCompensation?: number | null;
    /**
     * ISO_4217 format: https://en.wikipedia.org/wiki/ISO_4217
     * @type {string}
     * @memberof CompanyWithRelations
     */
    compensationCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    compensationExportEmail?: string | null;
    /**
     * Make visitors anonymous after X days
     * @type {number}
     * @memberof CompanyWithRelations
     */
    visitorAnonymousDays?: number | null;
    /**
     * Make reservations anonymous after X days
     * @type {number}
     * @memberof CompanyWithRelations
     */
    reservationAnonymousDays?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    ssoDomain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithRelations
     */
    surfconextSsoEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    saltoClientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    saltoClientSecret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    saltoUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithRelations
     */
    saltoPassword?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyWithRelationsAllOf
 */
export interface CompanyWithRelationsAllOf {
    /**
     * 
     * @type {Array<CompanyWithRelationsAllOfTokens>}
     * @memberof CompanyWithRelationsAllOf
     */
    tokens: Array<CompanyWithRelationsAllOfTokens>;
    /**
     * 
     * @type {Array<Location>}
     * @memberof CompanyWithRelationsAllOf
     */
    locations: Array<Location>;
}
/**
 * 
 * @export
 * @interface CompanyWithRelationsAllOfTokens
 */
export interface CompanyWithRelationsAllOfTokens {
    /**
     * 
     * @type {CompanyTokenType}
     * @memberof CompanyWithRelationsAllOfTokens
     */
    token_type: CompanyTokenType;
}
/**
 * 
 * @export
 * @interface CompanyWithoutId
 */
export interface CompanyWithoutId {
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    name: string;
    /**
     * 
     * @type {Language}
     * @memberof CompanyWithoutId
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    forceGeofence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    mandatoryCheckIn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    reservationWindowLength: number;
    /**
     * The length of the reservation window in days for desk reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithoutId
     */
    reservationWindowLengthDesk?: number;
    /**
     * The length of the reservation window in days for lunch reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithoutId
     */
    reservationWindowLengthLunch?: number;
    /**
     * The length of the reservation window in days for meeting reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithoutId
     */
    reservationWindowLengthMeeting?: number;
    /**
     * The length of the reservation window in days for visitor reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithoutId
     */
    reservationWindowLengthVisitor?: number;
    /**
     * The length of the reservation window in days for resource reservations. Can be overwritten for users with the property in the userGroup
     * @type {number}
     * @memberof CompanyWithoutId
     */
    reservationWindowLengthResource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    forceTimeslotUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    checkInEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    lunchEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    meetingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    visitorsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    homeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    recurringResourceReservationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    overlappingUserReservationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    mapEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    presentpaneEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    multiSelectResourceEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    facilitymanagementEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    deskAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    deskExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    lunchAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    lunchExpirationTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    meetingAutoCheckoutTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    meetingExpirationTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    reservationForUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    hideCheckInTimeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    microsoftSsoEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    microsoftSyncEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    microsoftSyncGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    visitorReservationConfirmEmailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    compensationEnabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof CompanyWithoutId
     */
    created?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    contactFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    contactLastname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    contactPhone: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    billingPricePerCustomer: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    billingId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    billingStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    billingEndDate?: string | null;
    /**
     * 
     * @type {BillingType}
     * @memberof CompanyWithoutId
     */
    billingType: BillingType;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    billingVoucherCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    autoProvisioningDomain?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    compensationCutOffDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutId
     */
    compensationMaxDailyDistance?: number | null;
    /**
     * 
     * @type {DistanceMetric}
     * @memberof CompanyWithoutId
     */
    compensationDistanceMetric?: DistanceMetric;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyWithoutId
     */
    compensationDistanceUnitCompensation?: number | null;
    /**
     * Amount in cents
     * @type {number}
     * @memberof CompanyWithoutId
     */
    compensationDailyHomeCompensation?: number | null;
    /**
     * ISO_4217 format: https://en.wikipedia.org/wiki/ISO_4217
     * @type {string}
     * @memberof CompanyWithoutId
     */
    compensationCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    compensationExportEmail?: string | null;
    /**
     * Make visitors anonymous after X days
     * @type {number}
     * @memberof CompanyWithoutId
     */
    visitorAnonymousDays?: number | null;
    /**
     * Make reservations anonymous after X days
     * @type {number}
     * @memberof CompanyWithoutId
     */
    reservationAnonymousDays?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    ssoDomain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutId
     */
    surfconextSsoEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    saltoClientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    saltoClientSecret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    saltoUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutId
     */
    saltoPassword?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyWithoutIdAllOf
 */
export interface CompanyWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutIdAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutIdAllOf
     */
    contactFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutIdAllOf
     */
    contactLastname: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutIdAllOf
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutIdAllOf
     */
    contactPhone: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyWithoutIdAllOf
     */
    parkingSpotsAsResourceEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutIdAllOf
     */
    reservationWindowLength: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutIdAllOf
     */
    billingPricePerCustomer: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyWithoutIdAllOf
     */
    billingId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutIdAllOf
     */
    billingStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyWithoutIdAllOf
     */
    billingEndDate?: string | null;
    /**
     * 
     * @type {BillingType}
     * @memberof CompanyWithoutIdAllOf
     */
    billingType: BillingType;
    /**
     * 
     * @type {Language}
     * @memberof CompanyWithoutIdAllOf
     */
    language: Language;
}
/**
 * 
 * @export
 * @interface CustomField
 */
export interface CustomField {
    /**
     * 
     * @type {string}
     * @memberof CustomField
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomField
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomField
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CustomFieldProperties
 */
export interface CustomFieldProperties {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldProperties
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFieldProperties
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface CustomFieldUpdate
 */
export interface CustomFieldUpdate {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldUpdate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFieldUpdate
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomFieldUpdate
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface CustomFieldUpdateAllOf
 */
export interface CustomFieldUpdateAllOf {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldUpdateAllOf
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface CustomFieldValue
 */
export interface CustomFieldValue {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValue
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValue
     */
    id: string;
    /**
     * 
     * @type {CustomField}
     * @memberof CustomFieldValue
     */
    customField: CustomField;
}
/**
 * 
 * @export
 * @interface CustomFieldValueAllOf
 */
export interface CustomFieldValueAllOf {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValueAllOf
     */
    id: string;
    /**
     * 
     * @type {CustomField}
     * @memberof CustomFieldValueAllOf
     */
    customField: CustomField;
}
/**
 * 
 * @export
 * @interface CustomFieldValueProperties
 */
export interface CustomFieldValueProperties {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValueProperties
     */
    value: string;
}
/**
 * 
 * @export
 * @interface CustomFieldValueUpdate
 */
export interface CustomFieldValueUpdate {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValueUpdate
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValueUpdate
     */
    customFieldId: string;
}
/**
 * 
 * @export
 * @interface CustomFieldValueUpdateAllOf
 */
export interface CustomFieldValueUpdateAllOf {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValueUpdateAllOf
     */
    customFieldId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DistanceMetric {
    Kilometer = 'kilometer',
    Mile = 'mile'
}

/**
 * 
 * @export
 * @interface Door
 */
export interface Door {
    /**
     * 
     * @type {string}
     * @memberof Door
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Door
     */
    saltoIqId: string;
    /**
     * 
     * @type {string}
     * @memberof Door
     */
    saltoLockId: string;
    /**
     * 
     * @type {string}
     * @memberof Door
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Door
     */
    mapId?: string;
    /**
     * 
     * @type {string}
     * @memberof Door
     */
    locationId: string;
}
/**
 * 
 * @export
 * @interface DoorAllOf
 */
export interface DoorAllOf {
    /**
     * 
     * @type {string}
     * @memberof DoorAllOf
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DoorAllOf
     */
    saltoIqId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorAllOf
     */
    saltoLockId: string;
}
/**
 * 
 * @export
 * @interface DoorProperties
 */
export interface DoorProperties {
    /**
     * 
     * @type {string}
     * @memberof DoorProperties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DoorProperties
     */
    saltoIqId?: string;
    /**
     * 
     * @type {string}
     * @memberof DoorProperties
     */
    saltoLockId?: string;
    /**
     * 
     * @type {string}
     * @memberof DoorProperties
     */
    mapId?: string;
    /**
     * 
     * @type {string}
     * @memberof DoorProperties
     */
    locationId?: string;
}
/**
 * 
 * @export
 * @interface DoorWithRelations
 */
export interface DoorWithRelations {
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelations
     */
    saltoIqId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelations
     */
    saltoLockId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelations
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelations
     */
    mapId?: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelations
     */
    locationId: string;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof DoorWithRelations
     */
    map?: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof DoorWithRelations
     */
    location: DoorWithRelationsAllOfMap;
}
/**
 * 
 * @export
 * @interface DoorWithRelationsAllOf
 */
export interface DoorWithRelationsAllOf {
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof DoorWithRelationsAllOf
     */
    map?: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof DoorWithRelationsAllOf
     */
    location: DoorWithRelationsAllOfMap;
}
/**
 * 
 * @export
 * @interface DoorWithRelationsAllOfMap
 */
export interface DoorWithRelationsAllOfMap {
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelationsAllOfMap
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithRelationsAllOfMap
     */
    name: string;
}
/**
 * 
 * @export
 * @interface DoorWithoutId
 */
export interface DoorWithoutId {
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutId
     */
    saltoIqId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutId
     */
    saltoLockId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutId
     */
    mapId?: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutId
     */
    locationId: string;
}
/**
 * 
 * @export
 * @interface DoorWithoutIdAllOf
 */
export interface DoorWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutIdAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutIdAllOf
     */
    saltoIqId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutIdAllOf
     */
    saltoLockId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutIdAllOf
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof DoorWithoutIdAllOf
     */
    mapId?: string;
}
/**
 * 
 * @export
 * @interface ExportFile
 */
export interface ExportFile {
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    id: string;
    /**
     * 
     * @type {ExportFileType}
     * @memberof ExportFile
     */
    type: ExportFileType;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    created: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ExportFileType {
    Compensation = 'compensation'
}

/**
 * 
 * @export
 * @interface FavoriteUserWithoutId
 */
export interface FavoriteUserWithoutId {
    /**
     * ID of the other user
     * @type {string}
     * @memberof FavoriteUserWithoutId
     */
    favoriteUserId: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    email: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    email: string;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    seat_id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    rrule: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject10
     */
    include_parking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    type: InlineObject10TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    visitor_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    visitor_email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    visitor_phone?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject10TypeEnum {
    Normal = 'normal',
    Visitor = 'visitor'
}

/**
 * 
 * @export
 * @interface InlineObject13
 */
export interface InlineObject13 {
    /**
     * 
     * @type {OrderDirection}
     * @memberof InlineObject13
     */
    direction: OrderDirection;
}
/**
 * 
 * @export
 * @interface InlineObject14
 */
export interface InlineObject14 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject14
     */
    microsoftGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject14
     */
    microsoftGroupName: string;
}
/**
 * 
 * @export
 * @interface InlineObject15
 */
export interface InlineObject15 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject15
     */
    userIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject17
 */
export interface InlineObject17 {
    /**
     * 
     * @type {OrderDirection}
     * @memberof InlineObject17
     */
    direction: OrderDirection;
}
/**
 * 
 * @export
 * @interface InlineObject18
 */
export interface InlineObject18 {
    /**
     * 
     * @type {CheckInType}
     * @memberof InlineObject18
     */
    type: CheckInType;
}
/**
 * 
 * @export
 * @interface InlineObject19
 */
export interface InlineObject19 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    type: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    selector: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    resetToken: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    password: string;
}
/**
 * 
 * @export
 * @interface InlineObject20
 */
export interface InlineObject20 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject20
     */
    reservationIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject20
     */
    reason?: string;
}
/**
 * 
 * @export
 * @interface InlineObject21
 */
export interface InlineObject21 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject21
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject21
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject21
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject21
     */
    title?: string | null;
    /**
     * 
     * @type {Language}
     * @memberof InlineObject21
     */
    language?: Language;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject21
     */
    locationTrackingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject21
     */
    privacyModeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject21
     */
    pushEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject21
     */
    checkInPushEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject21
     */
    defaultIncludeParking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineObject21
     */
    iCalId?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject21
     */
    defaultTransportOptionId?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    app_version: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    platform: string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    location_id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    report_reason_id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    details: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject5
     */
    employee_count?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    lastname: string;
    /**
     * 
     * @type {Language}
     * @memberof InlineObject5
     */
    language: Language;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    privacy?: InlineObject5PrivacyEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    remarks?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    subscription: InlineObject5SubscriptionEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    updates?: InlineObject5UpdatesEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject5PrivacyEnum {
    _1 = '1'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject5SubscriptionEnum {
    Free = 'free',
    Month = 'month',
    Year = 'year',
    Enterprise = 'enterprise',
    ContactForm = 'contact-form'
}
/**
    * @export
    * @enum {string}
    */
export enum InlineObject5UpdatesEnum {
    _1 = '1'
}

/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    last_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    language?: InlineObject6LanguageEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject6
     */
    location_tracking_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject6
     */
    privacy_mode_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject6
     */
    push_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject6
     */
    check_in_push_enabled?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject6LanguageEnum {
    Nl = 'nl',
    De = 'de',
    En = 'en'
}

/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    seat_id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    date: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject8
     */
    include_parking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    type: InlineObject8TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    visitor_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    visitor_email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    visitor_phone?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject8TypeEnum {
    Normal = 'normal',
    Visitor = 'visitor'
}

/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    seat_id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    date: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject9
     */
    include_parking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    type: InlineObject9TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    visitor_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    visitor_email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    visitor_phone?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObject9TypeEnum {
    Normal = 'normal',
    Visitor = 'visitor'
}

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    version?: string;
    /**
     * Current head
     * @type {string}
     * @memberof InlineResponse200
     */
    head?: string;
    /**
     * Current latest commit in master
     * @type {string}
     * @memberof InlineResponse200
     */
    master?: string;
    /**
     * Current branch
     * @type {string}
     * @memberof InlineResponse200
     */
    branch?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20010
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20010Result}
     * @memberof InlineResponse20010
     */
    result: InlineResponse20010Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20010Result
 */
export interface InlineResponse20010Result {
    /**
     * 
     * @type {CompanyWithRelations | CompanyBasicWithRelations}
     * @memberof InlineResponse20010Result
     */
    company: CompanyWithRelations | CompanyBasicWithRelations;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20011
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20011Result}
     * @memberof InlineResponse20011
     */
    result: InlineResponse20011Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20011Result
 */
export interface InlineResponse20011Result {
    /**
     * 
     * @type {Company}
     * @memberof InlineResponse20011Result
     */
    company?: Company;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20012
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20012Result}
     * @memberof InlineResponse20012
     */
    result: InlineResponse20012Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20012Result
 */
export interface InlineResponse20012Result {
    /**
     * 
     * @type {MicrosoftGroup}
     * @memberof InlineResponse20012Result
     */
    group: MicrosoftGroup;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20013
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20013Result}
     * @memberof InlineResponse20013
     */
    result: InlineResponse20013Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20013Result
 */
export interface InlineResponse20013Result {
    /**
     * 
     * @type {Array<MicrosoftGroup>}
     * @memberof InlineResponse20013Result
     */
    groups: Array<MicrosoftGroup>;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20014
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20014Result}
     * @memberof InlineResponse20014
     */
    result: InlineResponse20014Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20014Result
 */
export interface InlineResponse20014Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20014Result
     */
    total: number;
    /**
     * 
     * @type {Array<ReportReasonWithRelations & Operations>}
     * @memberof InlineResponse20014Result
     */
    items: Array<ReportReasonWithRelations & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20015
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20015Result}
     * @memberof InlineResponse20015
     */
    result: InlineResponse20015Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20015Result
 */
export interface InlineResponse20015Result {
    /**
     * 
     * @type {ReportReason}
     * @memberof InlineResponse20015Result
     */
    reportReason: ReportReason;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20016
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20016Result}
     * @memberof InlineResponse20016
     */
    result: InlineResponse20016Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20016Result
 */
export interface InlineResponse20016Result {
    /**
     * 
     * @type {ReportReasonWithRelations}
     * @memberof InlineResponse20016Result
     */
    reportReason?: ReportReasonWithRelations;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20017
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20017Result}
     * @memberof InlineResponse20017
     */
    result: InlineResponse20017Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20017Result
 */
export interface InlineResponse20017Result {
    /**
     * 
     * @type {ReportReason}
     * @memberof InlineResponse20017Result
     */
    reportReason?: ReportReason;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20018
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20018Result}
     * @memberof InlineResponse20018
     */
    result: InlineResponse20018Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20018Result
 */
export interface InlineResponse20018Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20018Result
     */
    total: number;
    /**
     * 
     * @type {Array<ReportWithRelations>}
     * @memberof InlineResponse20018Result
     */
    items: Array<ReportWithRelations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20019
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20019Result}
     * @memberof InlineResponse20019
     */
    result: InlineResponse20019Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20019Result
 */
export interface InlineResponse20019Result {
    /**
     * 
     * @type {Report}
     * @memberof InlineResponse20019Result
     */
    report: Report;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse2002
     */
    created?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse2002
     */
    failed?: Array<object>;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20020
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20020Result}
     * @memberof InlineResponse20020
     */
    result: InlineResponse20020Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20020Result
 */
export interface InlineResponse20020Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20020Result
     */
    total: number;
    /**
     * 
     * @type {Array<AdminUserWithRelations & Operations & object>}
     * @memberof InlineResponse20020Result
     */
    items: Array<AdminUserWithRelations & Operations & object>;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20021
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20021Result}
     * @memberof InlineResponse20021
     */
    result: InlineResponse20021Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20021Result
 */
export interface InlineResponse20021Result {
    /**
     * 
     * @type {AdminUser & Operations}
     * @memberof InlineResponse20021Result
     */
    adminUser: AdminUser & Operations;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20022
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20022Result}
     * @memberof InlineResponse20022
     */
    result: InlineResponse20022Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20022Result
 */
export interface InlineResponse20022Result {
    /**
     * 
     * @type {AdminUser}
     * @memberof InlineResponse20022Result
     */
    adminUser?: AdminUser;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20023
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {OAuthPasswordGrantResponse}
     * @memberof InlineResponse20023
     */
    result: OAuthPasswordGrantResponse;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20024
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20024Result}
     * @memberof InlineResponse20024
     */
    result: InlineResponse20024Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20024Result
 */
export interface InlineResponse20024Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20024Result
     */
    total: number;
    /**
     * 
     * @type {Array<UserWithRelations & Operations>}
     * @memberof InlineResponse20024Result
     */
    items: Array<UserWithRelations & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20025
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20025Result}
     * @memberof InlineResponse20025
     */
    result: InlineResponse20025Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20025Result
 */
export interface InlineResponse20025Result {
    /**
     * 
     * @type {UserWithRelations & Operations}
     * @memberof InlineResponse20025Result
     */
    user: UserWithRelations & Operations;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20026
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20026Result}
     * @memberof InlineResponse20026
     */
    result: InlineResponse20026Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20026Result
 */
export interface InlineResponse20026Result {
    /**
     * 
     * @type {UserWithRelations}
     * @memberof InlineResponse20026Result
     */
    user?: UserWithRelations;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20027
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20027Result}
     * @memberof InlineResponse20027
     */
    result: InlineResponse20027Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20027Result
 */
export interface InlineResponse20027Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20027Result
     */
    total: number;
    /**
     * 
     * @type {Array<Location & Operations>}
     * @memberof InlineResponse20027Result
     */
    items: Array<Location & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20028
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20028Result}
     * @memberof InlineResponse20028
     */
    result: InlineResponse20028Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20028Result
 */
export interface InlineResponse20028Result {
    /**
     * 
     * @type {Location & Operations}
     * @memberof InlineResponse20028Result
     */
    location: Location & Operations;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20029
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20029Result}
     * @memberof InlineResponse20029
     */
    result: InlineResponse20029Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20029Result
 */
export interface InlineResponse20029Result {
    /**
     * 
     * @type {Location}
     * @memberof InlineResponse20029Result
     */
    location?: Location;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse2003
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse2003Result}
     * @memberof InlineResponse2003
     */
    result: InlineResponse2003Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20030
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20030Result}
     * @memberof InlineResponse20030
     */
    result: InlineResponse20030Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20030Result
 */
export interface InlineResponse20030Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Result
     */
    total: number;
    /**
     * 
     * @type {Array<Door & Operations>}
     * @memberof InlineResponse20030Result
     */
    items: Array<Door & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20031
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20031Result}
     * @memberof InlineResponse20031
     */
    result: InlineResponse20031Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20031Result
 */
export interface InlineResponse20031Result {
    /**
     * 
     * @type {Door & Operations}
     * @memberof InlineResponse20031Result
     */
    door: Door & Operations;
}
/**
 * 
 * @export
 * @interface InlineResponse20032
 */
export interface InlineResponse20032 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20032
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20032Result}
     * @memberof InlineResponse20032
     */
    result: InlineResponse20032Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20032Result
 */
export interface InlineResponse20032Result {
    /**
     * 
     * @type {Door}
     * @memberof InlineResponse20032Result
     */
    door?: Door;
}
/**
 * 
 * @export
 * @interface InlineResponse20033
 */
export interface InlineResponse20033 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20033
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20033Result}
     * @memberof InlineResponse20033
     */
    result: InlineResponse20033Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20033Result
 */
export interface InlineResponse20033Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20033Result
     */
    total: number;
    /**
     * 
     * @type {Array<Resource & Operations & object>}
     * @memberof InlineResponse20033Result
     */
    items: Array<Resource & Operations & object>;
}
/**
 * 
 * @export
 * @interface InlineResponse20034
 */
export interface InlineResponse20034 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20034
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20034Result}
     * @memberof InlineResponse20034
     */
    result: InlineResponse20034Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20034Result
 */
export interface InlineResponse20034Result {
    /**
     * 
     * @type {Resource & Operations}
     * @memberof InlineResponse20034Result
     */
    resource: Resource & Operations;
}
/**
 * 
 * @export
 * @interface InlineResponse20035
 */
export interface InlineResponse20035 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20035
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20035Result}
     * @memberof InlineResponse20035
     */
    result: InlineResponse20035Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20035Result
 */
export interface InlineResponse20035Result {
    /**
     * 
     * @type {Resource}
     * @memberof InlineResponse20035Result
     */
    resource?: Resource;
}
/**
 * 
 * @export
 * @interface InlineResponse20036
 */
export interface InlineResponse20036 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20036
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20036Result}
     * @memberof InlineResponse20036
     */
    result: InlineResponse20036Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20036Result
 */
export interface InlineResponse20036Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20036Result
     */
    total: number;
    /**
     * 
     * @type {Array<ResourceReservation & Operations & object>}
     * @memberof InlineResponse20036Result
     */
    items: Array<ResourceReservation & Operations & object>;
}
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20037
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20037Result}
     * @memberof InlineResponse20037
     */
    result: InlineResponse20037Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20037Result
 */
export interface InlineResponse20037Result {
    /**
     * 
     * @type {ResourceReservation & Operations}
     * @memberof InlineResponse20037Result
     */
    resourceReservation: ResourceReservation & Operations;
}
/**
 * 
 * @export
 * @interface InlineResponse20038
 */
export interface InlineResponse20038 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20038
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20038Result}
     * @memberof InlineResponse20038
     */
    result: InlineResponse20038Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20038Result
 */
export interface InlineResponse20038Result {
    /**
     * 
     * @type {ResourceReservation}
     * @memberof InlineResponse20038Result
     */
    resourceReservation?: ResourceReservation;
}
/**
 * 
 * @export
 * @interface InlineResponse20039
 */
export interface InlineResponse20039 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20039
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20039Result}
     * @memberof InlineResponse20039
     */
    result: InlineResponse20039Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20039Result
 */
export interface InlineResponse20039Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20039Result
     */
    total: number;
    /**
     * 
     * @type {Array<Saltolock>}
     * @memberof InlineResponse20039Result
     */
    items: Array<Saltolock>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Result
 */
export interface InlineResponse2003Result {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2003Result
     */
    isCompatible: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse2004
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse2004Result}
     * @memberof InlineResponse2004
     */
    result: InlineResponse2004Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20040
 */
export interface InlineResponse20040 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20040
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20040Result}
     * @memberof InlineResponse20040
     */
    result: InlineResponse20040Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20040Result
 */
export interface InlineResponse20040Result {
    /**
     * 
     * @type {Array<MapObject>}
     * @memberof InlineResponse20040Result
     */
    items: Array<MapObject>;
}
/**
 * 
 * @export
 * @interface InlineResponse20041
 */
export interface InlineResponse20041 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20041
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20041Result}
     * @memberof InlineResponse20041
     */
    result: InlineResponse20041Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20041Result
 */
export interface InlineResponse20041Result {
    /**
     * 
     * @type {MapObject}
     * @memberof InlineResponse20041Result
     */
    mapObject: MapObject;
}
/**
 * 
 * @export
 * @interface InlineResponse20042
 */
export interface InlineResponse20042 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20042
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20042Result}
     * @memberof InlineResponse20042
     */
    result: InlineResponse20042Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20042Result
 */
export interface InlineResponse20042Result {
    /**
     * 
     * @type {MapObject}
     * @memberof InlineResponse20042Result
     */
    mapObject?: MapObject;
}
/**
 * 
 * @export
 * @interface InlineResponse20043
 */
export interface InlineResponse20043 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20043
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20043Result}
     * @memberof InlineResponse20043
     */
    result: InlineResponse20043Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20043Result
 */
export interface InlineResponse20043Result {
    /**
     * 
     * @type {Array<SeatWithRelations>}
     * @memberof InlineResponse20043Result
     */
    items: Array<SeatWithRelations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20044
 */
export interface InlineResponse20044 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20044
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20044Result}
     * @memberof InlineResponse20044
     */
    result: InlineResponse20044Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20044Result
 */
export interface InlineResponse20044Result {
    /**
     * 
     * @type {Array<UsedSeat>}
     * @memberof InlineResponse20044Result
     */
    items: Array<UsedSeat>;
}
/**
 * 
 * @export
 * @interface InlineResponse20045
 */
export interface InlineResponse20045 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20045
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {Array<Array<MicrosoftRoom>>}
     * @memberof InlineResponse20045
     */
    result: Array<Array<MicrosoftRoom>>;
}
/**
 * 
 * @export
 * @interface InlineResponse20046
 */
export interface InlineResponse20046 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20046
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20046Result}
     * @memberof InlineResponse20046
     */
    result: InlineResponse20046Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20046Result
 */
export interface InlineResponse20046Result {
    /**
     * 
     * @type {SeatWithRelations}
     * @memberof InlineResponse20046Result
     */
    seat: SeatWithRelations;
}
/**
 * 
 * @export
 * @interface InlineResponse20047
 */
export interface InlineResponse20047 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20047
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20047Result}
     * @memberof InlineResponse20047
     */
    result: InlineResponse20047Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20047Result
 */
export interface InlineResponse20047Result {
    /**
     * 
     * @type {SeatWithRelations}
     * @memberof InlineResponse20047Result
     */
    seat?: SeatWithRelations;
}
/**
 * 
 * @export
 * @interface InlineResponse20048
 */
export interface InlineResponse20048 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20048
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20048Result}
     * @memberof InlineResponse20048
     */
    result: InlineResponse20048Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20048Result
 */
export interface InlineResponse20048Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20048Result
     */
    total: number;
    /**
     * 
     * @type {Array<SeatTag & Operations>}
     * @memberof InlineResponse20048Result
     */
    items: Array<SeatTag & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20049
 */
export interface InlineResponse20049 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20049
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20049Result}
     * @memberof InlineResponse20049
     */
    result: InlineResponse20049Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20049Result
 */
export interface InlineResponse20049Result {
    /**
     * 
     * @type {SeatTag}
     * @memberof InlineResponse20049Result
     */
    seatTag: SeatTag;
}
/**
 * 
 * @export
 * @interface InlineResponse2004Result
 */
export interface InlineResponse2004Result {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004Result
     */
    url: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse2005
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse2005Result}
     * @memberof InlineResponse2005
     */
    result: InlineResponse2005Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20050
 */
export interface InlineResponse20050 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20050
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20050Result}
     * @memberof InlineResponse20050
     */
    result: InlineResponse20050Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20050Result
 */
export interface InlineResponse20050Result {
    /**
     * 
     * @type {SeatTag}
     * @memberof InlineResponse20050Result
     */
    seatTag?: SeatTag;
}
/**
 * 
 * @export
 * @interface InlineResponse20051
 */
export interface InlineResponse20051 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20051
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20051Result}
     * @memberof InlineResponse20051
     */
    result: InlineResponse20051Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20051Result
 */
export interface InlineResponse20051Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20051Result
     */
    total: number;
    /**
     * 
     * @type {Array<UserGroup & Operations>}
     * @memberof InlineResponse20051Result
     */
    items: Array<UserGroup & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20052
 */
export interface InlineResponse20052 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20052
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20052Result}
     * @memberof InlineResponse20052
     */
    result: InlineResponse20052Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20052Result
 */
export interface InlineResponse20052Result {
    /**
     * 
     * @type {UserGroup}
     * @memberof InlineResponse20052Result
     */
    userGroup: UserGroup;
}
/**
 * 
 * @export
 * @interface InlineResponse20053
 */
export interface InlineResponse20053 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20053
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20053Result}
     * @memberof InlineResponse20053
     */
    result: InlineResponse20053Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20053Result
 */
export interface InlineResponse20053Result {
    /**
     * 
     * @type {UserGroup}
     * @memberof InlineResponse20053Result
     */
    userGroup?: UserGroup;
}
/**
 * 
 * @export
 * @interface InlineResponse20054
 */
export interface InlineResponse20054 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20054
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20054Result}
     * @memberof InlineResponse20054
     */
    result: InlineResponse20054Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20054Result
 */
export interface InlineResponse20054Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20054Result
     */
    total: number;
    /**
     * 
     * @type {Array<Timeslot & Operations>}
     * @memberof InlineResponse20054Result
     */
    items: Array<Timeslot & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20055
 */
export interface InlineResponse20055 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20055
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20055Result}
     * @memberof InlineResponse20055
     */
    result: InlineResponse20055Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20055Result
 */
export interface InlineResponse20055Result {
    /**
     * 
     * @type {Timeslot}
     * @memberof InlineResponse20055Result
     */
    timeslot: Timeslot;
}
/**
 * 
 * @export
 * @interface InlineResponse20056
 */
export interface InlineResponse20056 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20056
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20056Result}
     * @memberof InlineResponse20056
     */
    result: InlineResponse20056Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20056Result
 */
export interface InlineResponse20056Result {
    /**
     * 
     * @type {Timeslot}
     * @memberof InlineResponse20056Result
     */
    timeslot?: Timeslot;
}
/**
 * 
 * @export
 * @interface InlineResponse20057
 */
export interface InlineResponse20057 {
    /**
     * 
     * @type {InlineResponse20057Result}
     * @memberof InlineResponse20057
     */
    result: InlineResponse20057Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20057Result
 */
export interface InlineResponse20057Result {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057Result
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057Result
     */
    reservation_id: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057Result
     */
    check_in_time: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057Result
     */
    check_out_time: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057Result
     */
    type: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20058
 */
export interface InlineResponse20058 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20058
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20058Result}
     * @memberof InlineResponse20058
     */
    result: InlineResponse20058Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20058Result
 */
export interface InlineResponse20058Result {
    /**
     * 
     * @type {ReservationWithRelations}
     * @memberof InlineResponse20058Result
     */
    reservation?: ReservationWithRelations;
}
/**
 * 
 * @export
 * @interface InlineResponse20059
 */
export interface InlineResponse20059 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20059
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {object}
     * @memberof InlineResponse20059
     */
    result: object;
}
/**
 * 
 * @export
 * @interface InlineResponse2005Result
 */
export interface InlineResponse2005Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2005Result
     */
    total: number;
    /**
     * 
     * @type {Array<MapWithRelations & Operations>}
     * @memberof InlineResponse2005Result
     */
    items: Array<MapWithRelations & Operations>;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse2006
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse2006Result}
     * @memberof InlineResponse2006
     */
    result: InlineResponse2006Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20060
 */
export interface InlineResponse20060 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20060
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20060Result}
     * @memberof InlineResponse20060
     */
    result: InlineResponse20060Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20060Result
 */
export interface InlineResponse20060Result {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20060Result
     */
    allowed: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse20061
 */
export interface InlineResponse20061 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20061
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20061Result}
     * @memberof InlineResponse20061
     */
    result: InlineResponse20061Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20061Result
 */
export interface InlineResponse20061Result {
    /**
     * 
     * @type {Array<ReservationWithRelations>}
     * @memberof InlineResponse20061Result
     */
    updatedReservations?: Array<ReservationWithRelations>;
    /**
     * 
     * @type {Array<InlineResponse20061ResultFailedReservations>}
     * @memberof InlineResponse20061Result
     */
    failedReservations?: Array<InlineResponse20061ResultFailedReservations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20061ResultFailedReservations
 */
export interface InlineResponse20061ResultFailedReservations {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20061ResultFailedReservations
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20061ResultFailedReservations
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20061ResultFailedReservations
     */
    date: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20062
 */
export interface InlineResponse20062 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20062
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20062Result}
     * @memberof InlineResponse20062
     */
    result: InlineResponse20062Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20062Result
 */
export interface InlineResponse20062Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20062Result
     */
    total: number;
    /**
     * 
     * @type {Array<ReservationWithRelations>}
     * @memberof InlineResponse20062Result
     */
    items: Array<ReservationWithRelations>;
}
/**
 * 
 * @export
 * @interface InlineResponse20063
 */
export interface InlineResponse20063 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20063
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20063Result}
     * @memberof InlineResponse20063
     */
    result: InlineResponse20063Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20063Result
 */
export interface InlineResponse20063Result {
    /**
     * 
     * @type {Array<InlineResponse20063ResultItems>}
     * @memberof InlineResponse20063Result
     */
    items: Array<InlineResponse20063ResultItems>;
    /**
     * Moved to /v3/reservations/statistics/today
     * @type {number}
     * @memberof InlineResponse20063Result
     * @deprecated
     */
    seatCount: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20063Result
     */
    parkingCount: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20063ResultItems
 */
export interface InlineResponse20063ResultItems {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20063ResultItems
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20063ResultItems
     */
    minTimeslot?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20063ResultItems
     */
    maxTimeslot?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20063ResultItems
     */
    seatReservationsCount: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20063ResultItems
     */
    parkingSpotsClaimed: number;
    /**
     * 
     * @type {Array<InlineResponse20063ResultSeatTagLeaderboard>}
     * @memberof InlineResponse20063ResultItems
     */
    seatTagLeaderboard?: Array<InlineResponse20063ResultSeatTagLeaderboard>;
    /**
     * 
     * @type {Array<InlineResponse20063ResultMostUsed>}
     * @memberof InlineResponse20063ResultItems
     */
    mostUsed?: Array<InlineResponse20063ResultMostUsed>;
}
/**
 * 
 * @export
 * @interface InlineResponse20063ResultMostUsed
 */
export interface InlineResponse20063ResultMostUsed {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20063ResultMostUsed
     */
    percentage: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20063ResultMostUsed
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20063ResultMostUsed
     */
    reservations: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20063ResultSeatTagLeaderboard
 */
export interface InlineResponse20063ResultSeatTagLeaderboard {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20063ResultSeatTagLeaderboard
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20063ResultSeatTagLeaderboard
     */
    percentage: number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20063ResultSeatTagLeaderboard
     */
    active: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse20064
 */
export interface InlineResponse20064 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20064
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20064Result}
     * @memberof InlineResponse20064
     */
    result: InlineResponse20064Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20064Result
 */
export interface InlineResponse20064Result {
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse20064Result
     */
    stats: Array<object>;
}
/**
 * 
 * @export
 * @interface InlineResponse20065
 */
export interface InlineResponse20065 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20065
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20065Result}
     * @memberof InlineResponse20065
     */
    result: InlineResponse20065Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20065Result
 */
export interface InlineResponse20065Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065Result
     */
    officeReservationCount: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065Result
     */
    homeReservationCount: number;
    /**
     * 
     * @type {InlineResponse20065ResultSeatTypeReservationCount}
     * @memberof InlineResponse20065Result
     */
    seatTypeReservationCount: InlineResponse20065ResultSeatTypeReservationCount;
    /**
     * 
     * @type {InlineResponse20065ResultSeatTypeReservationCount}
     * @memberof InlineResponse20065Result
     */
    seatTypeTotalCount: InlineResponse20065ResultSeatTypeReservationCount;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065Result
     */
    parkingSpotReservationCount: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065Result
     */
    parkingSpotTotalCount: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20065ResultSeatTypeReservationCount
 */
export interface InlineResponse20065ResultSeatTypeReservationCount {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065ResultSeatTypeReservationCount
     */
    desk: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065ResultSeatTypeReservationCount
     */
    meeting: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065ResultSeatTypeReservationCount
     */
    lunch: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20066
 */
export interface InlineResponse20066 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20066
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20066Result}
     * @memberof InlineResponse20066
     */
    result: InlineResponse20066Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20066Result
 */
export interface InlineResponse20066Result {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20066Result
     */
    authUrl: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20067
 */
export interface InlineResponse20067 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20067
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20067Result}
     * @memberof InlineResponse20067
     */
    result: InlineResponse20067Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20067Result
 */
export interface InlineResponse20067Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067Result
     */
    currentMonthRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067Result
     */
    billedCompanyCount: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067Result
     */
    billedUserCount: number;
    /**
     * 
     * @type {InlineResponse20067ResultBillingTypeCount}
     * @memberof InlineResponse20067Result
     */
    billingTypeCount: InlineResponse20067ResultBillingTypeCount;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067Result
     */
    reservationCount: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067Result
     */
    todayReservationCount: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20067ResultBillingTypeCount
 */
export interface InlineResponse20067ResultBillingTypeCount {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067ResultBillingTypeCount
     */
    enterprise: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067ResultBillingTypeCount
     */
    free: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067ResultBillingTypeCount
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067ResultBillingTypeCount
     */
    month: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20068
 */
export interface InlineResponse20068 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20068
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20068
     */
    result: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20069
 */
export interface InlineResponse20069 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20069
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {MeUser | MeAdmin}
     * @memberof InlineResponse20069
     */
    result: MeUser | MeAdmin;
}
/**
 * 
 * @export
 * @interface InlineResponse2006Result
 */
export interface InlineResponse2006Result {
    /**
     * 
     * @type {MapWithRelations & Operations}
     * @memberof InlineResponse2006Result
     */
    map: MapWithRelations & Operations;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse2007
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse2007Result}
     * @memberof InlineResponse2007
     */
    result: InlineResponse2007Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20070
 */
export interface InlineResponse20070 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20070
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {MeUser}
     * @memberof InlineResponse20070
     */
    result: MeUser;
}
/**
 * 
 * @export
 * @interface InlineResponse20071
 */
export interface InlineResponse20071 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20071
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20071Result}
     * @memberof InlineResponse20071
     */
    result: InlineResponse20071Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20071Result
 */
export interface InlineResponse20071Result {
    /**
     * 
     * @type {CompanyTransportOption}
     * @memberof InlineResponse20071Result
     */
    companyTransportOption: CompanyTransportOption;
}
/**
 * 
 * @export
 * @interface InlineResponse20072
 */
export interface InlineResponse20072 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20072
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20072Result}
     * @memberof InlineResponse20072
     */
    result: InlineResponse20072Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20072Result
 */
export interface InlineResponse20072Result {
    /**
     * 
     * @type {Array<CompanyTransportOption>}
     * @memberof InlineResponse20072Result
     */
    items: Array<CompanyTransportOption>;
}
/**
 * 
 * @export
 * @interface InlineResponse20073
 */
export interface InlineResponse20073 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20073
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse20073Result}
     * @memberof InlineResponse20073
     */
    result: InlineResponse20073Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20073Result
 */
export interface InlineResponse20073Result {
    /**
     * 
     * @type {Array<ExportFile>}
     * @memberof InlineResponse20073Result
     */
    items: Array<ExportFile>;
}
/**
 * 
 * @export
 * @interface InlineResponse20074
 */
export interface InlineResponse20074 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse20074
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {Analytics}
     * @memberof InlineResponse20074
     */
    result: Analytics;
}
/**
 * 
 * @export
 * @interface InlineResponse2007Result
 */
export interface InlineResponse2007Result {
    /**
     * 
     * @type {MapWithRelations}
     * @memberof InlineResponse2007Result
     */
    map?: MapWithRelations;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse2008
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse2008Result}
     * @memberof InlineResponse2008
     */
    result: InlineResponse2008Result;
}
/**
 * 
 * @export
 * @interface InlineResponse2008Result
 */
export interface InlineResponse2008Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2008Result
     */
    total: number;
    /**
     * 
     * @type {Array<CompanyWithCounts>}
     * @memberof InlineResponse2008Result
     */
    items: Array<CompanyWithCounts>;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse2009
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse2009Result}
     * @memberof InlineResponse2009
     */
    result: InlineResponse2009Result;
}
/**
 * 
 * @export
 * @interface InlineResponse2009Result
 */
export interface InlineResponse2009Result {
    /**
     * 
     * @type {CompanyBasic & object}
     * @memberof InlineResponse2009Result
     */
    company: CompanyBasic & object;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof InlineResponse201
     */
    meta: ResponseMeta;
    /**
     * 
     * @type {InlineResponse201Result}
     * @memberof InlineResponse201
     */
    result: InlineResponse201Result;
}
/**
 * 
 * @export
 * @interface InlineResponse201Result
 */
export interface InlineResponse201Result {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse201Result
     */
    errors: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse201Result
     */
    successes: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {Array<InlineResponse400Errors>}
     * @memberof InlineResponse400
     */
    errors: Array<InlineResponse400Errors>;
}
/**
 * 
 * @export
 * @interface InlineResponse4001
 */
export interface InlineResponse4001 {
    /**
     * 
     * @type {InlineResponse4001Error}
     * @memberof InlineResponse4001
     */
    error: InlineResponse4001Error;
}
/**
 * 
 * @export
 * @interface InlineResponse4001Error
 */
export interface InlineResponse4001Error {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse4001Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse4001Error
     */
    message: string;
}
/**
 * 
 * @export
 * @interface InlineResponse400Errors
 */
export interface InlineResponse400Errors {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400Errors
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400Errors
     */
    message: string;
}
/**
 * 
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse404
     */
    error?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse409
 */
export interface InlineResponse409 {
    /**
     * 
     * @type {InlineResponse409Error}
     * @memberof InlineResponse409
     */
    error: InlineResponse409Error;
}
/**
 * 
 * @export
 * @interface InlineResponse409Error
 */
export interface InlineResponse409Error {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse409Error
     */
    code: InlineResponse409ErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse409Error
     */
    message: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse409ErrorCodeEnum {
    NUMBER_1004 = 1004
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Language {
    Nl = 'NL',
    De = 'DE',
    En = 'EN'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum LanguageLowercase {
    Nl = 'nl',
    De = 'de',
    En = 'en'
}

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name: string;
    /**
     * 
     * @type {LocationAddress}
     * @memberof Location
     */
    address: LocationAddress;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    parkingCount: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    visitorReservationEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    bookingLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    visible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    centerLat?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    centerLng?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    proximityRadius?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    id: string;
    /**
     * 
     * @type {LocationHours}
     * @memberof Location
     */
    businessHours?: LocationHours;
}
/**
 * 
 * @export
 * @interface LocationAddress
 */
export interface LocationAddress {
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    street: string;
    /**
     * 
     * @type {number}
     * @memberof LocationAddress
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    addition: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    city: string;
    /**
     * ISO 3166-1 Alpha-2 country codes https://en.wikipedia.org/wiki/ISO_3166-1#Officially_assigned_code_elements
     * @type {string}
     * @memberof LocationAddress
     */
    countryCode?: string;
}
/**
 * 
 * @export
 * @interface LocationAllOf
 */
export interface LocationAllOf {
    /**
     * 
     * @type {string}
     * @memberof LocationAllOf
     */
    id: string;
    /**
     * 
     * @type {LocationHours}
     * @memberof LocationAllOf
     */
    businessHours?: LocationHours;
}
/**
 * 
 * @export
 * @interface LocationHours
 */
export interface LocationHours {
    /**
     * 
     * @type {LocationHoursMonday}
     * @memberof LocationHours
     */
    monday?: LocationHoursMonday;
    /**
     * 
     * @type {LocationHoursMonday}
     * @memberof LocationHours
     */
    tuesday?: LocationHoursMonday;
    /**
     * 
     * @type {LocationHoursMonday}
     * @memberof LocationHours
     */
    wednesday?: LocationHoursMonday;
    /**
     * 
     * @type {LocationHoursMonday}
     * @memberof LocationHours
     */
    thursday?: LocationHoursMonday;
    /**
     * 
     * @type {LocationHoursMonday}
     * @memberof LocationHours
     */
    friday?: LocationHoursMonday;
    /**
     * 
     * @type {LocationHoursMonday}
     * @memberof LocationHours
     */
    saturday?: LocationHoursMonday;
    /**
     * 
     * @type {LocationHoursMonday}
     * @memberof LocationHours
     */
    sunday?: LocationHoursMonday;
    /**
     * 
     * @type {Array<LocationHoursException>}
     * @memberof LocationHours
     */
    exceptions?: Array<LocationHoursException> | null;
}
/**
 * 
 * @export
 * @interface LocationHoursException
 */
export interface LocationHoursException {
    /**
     * 
     * @type {string}
     * @memberof LocationHoursException
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LocationHoursException
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationHoursException
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationHoursException
     */
    end?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationHoursException
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationHoursException
     */
    isWholeDay?: boolean;
}
/**
 * 
 * @export
 * @interface LocationHoursMonday
 */
export interface LocationHoursMonday {
    /**
     * 
     * @type {string}
     * @memberof LocationHoursMonday
     */
    start?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationHoursMonday
     */
    end?: string | null;
}
/**
 * 
 * @export
 * @interface LocationProperties
 */
export interface LocationProperties {
    /**
     * 
     * @type {string}
     * @memberof LocationProperties
     */
    name?: string;
    /**
     * 
     * @type {LocationAddress}
     * @memberof LocationProperties
     */
    address?: LocationAddress;
    /**
     * 
     * @type {number}
     * @memberof LocationProperties
     */
    parkingCount?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationProperties
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationProperties
     */
    visitorReservationEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LocationProperties
     */
    bookingLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LocationProperties
     */
    visible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LocationProperties
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationProperties
     */
    centerLat?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationProperties
     */
    centerLng?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationProperties
     */
    proximityRadius?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationProperties
     */
    timezone?: string;
}
/**
 * 
 * @export
 * @interface LocationWithoutId
 */
export interface LocationWithoutId {
    /**
     * 
     * @type {string}
     * @memberof LocationWithoutId
     */
    name: string;
    /**
     * 
     * @type {LocationAddress}
     * @memberof LocationWithoutId
     */
    address: LocationAddress;
    /**
     * 
     * @type {number}
     * @memberof LocationWithoutId
     */
    parkingCount: number;
    /**
     * 
     * @type {string}
     * @memberof LocationWithoutId
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof LocationWithoutId
     */
    visitorReservationEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LocationWithoutId
     */
    bookingLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LocationWithoutId
     */
    visible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LocationWithoutId
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationWithoutId
     */
    centerLat?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationWithoutId
     */
    centerLng?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationWithoutId
     */
    proximityRadius?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationWithoutId
     */
    timezone: string;
}
/**
 * 
 * @export
 * @interface LocationWithoutIdAllOf
 */
export interface LocationWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof LocationWithoutIdAllOf
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LocationWithoutIdAllOf
     */
    parkingCount: number;
    /**
     * 
     * @type {string}
     * @memberof LocationWithoutIdAllOf
     */
    contactEmail: string;
    /**
     * 
     * @type {LocationAddress}
     * @memberof LocationWithoutIdAllOf
     */
    address: LocationAddress;
    /**
     * 
     * @type {string}
     * @memberof LocationWithoutIdAllOf
     */
    timezone: string;
}
/**
 * 
 * @export
 * @interface MapAllOf
 */
export interface MapAllOf {
    /**
     * 
     * @type {string}
     * @memberof MapAllOf
     */
    id: string;
}
/**
 * 
 * @export
 * @interface MapObject
 */
export interface MapObject {
    /**
     * 
     * @type {string}
     * @memberof MapObject
     */
    mapId: string;
    /**
     * 
     * @type {string}
     * @memberof MapObject
     */
    layer: string;
    /**
     * 
     * @type {string}
     * @memberof MapObject
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof MapObject
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof MapObject
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof MapObject
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObject
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObject
     */
    scaleX?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObject
     */
    scaleY?: number;
    /**
     * 
     * @type {string}
     * @memberof MapObject
     */
    typeName: string;
    /**
     * 
     * @type {string}
     * @memberof MapObject
     */
    subTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof MapObject
     */
    rotation: number;
    /**
     * 
     * @type {string}
     * @memberof MapObject
     */
    id: string;
}
/**
 * 
 * @export
 * @interface MapObjectProperties
 */
export interface MapObjectProperties {
    /**
     * 
     * @type {string}
     * @memberof MapObjectProperties
     */
    mapId?: string;
    /**
     * 
     * @type {string}
     * @memberof MapObjectProperties
     */
    layer?: string;
    /**
     * 
     * @type {string}
     * @memberof MapObjectProperties
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof MapObjectProperties
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectProperties
     */
    y?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectProperties
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectProperties
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectProperties
     */
    scaleX?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectProperties
     */
    scaleY?: number;
    /**
     * 
     * @type {string}
     * @memberof MapObjectProperties
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MapObjectProperties
     */
    subTypeName?: string;
    /**
     * 
     * @type {number}
     * @memberof MapObjectProperties
     */
    rotation?: number;
}
/**
 * 
 * @export
 * @interface MapObjectWithoutId
 */
export interface MapObjectWithoutId {
    /**
     * 
     * @type {string}
     * @memberof MapObjectWithoutId
     */
    mapId: string;
    /**
     * 
     * @type {string}
     * @memberof MapObjectWithoutId
     */
    layer: string;
    /**
     * 
     * @type {string}
     * @memberof MapObjectWithoutId
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof MapObjectWithoutId
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectWithoutId
     */
    y: number;
    /**
     * 
     * @type {string}
     * @memberof MapObjectWithoutId
     */
    typeName: string;
    /**
     * 
     * @type {number}
     * @memberof MapObjectWithoutId
     */
    rotation: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectWithoutId
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectWithoutId
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectWithoutId
     */
    scaleX?: number;
    /**
     * 
     * @type {number}
     * @memberof MapObjectWithoutId
     */
    scaleY?: number;
    /**
     * 
     * @type {string}
     * @memberof MapObjectWithoutId
     */
    subTypeName?: string;
}
/**
 * 
 * @export
 * @interface MapProperties
 */
export interface MapProperties {
    /**
     * 
     * @type {string}
     * @memberof MapProperties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MapProperties
     */
    floor?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MapProperties
     */
    visible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapProperties
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof MapProperties
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MapProperties
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MapProperties
     */
    limitMaximumBookedResources?: number;
    /**
     * 
     * @type {string}
     * @memberof MapProperties
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MapProperties
     */
    botUserEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof MapProperties
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof MapProperties
     */
    locationId?: string;
}
/**
 * 
 * @export
 * @interface MapWithRelations
 */
export interface MapWithRelations {
    /**
     * 
     * @type {string}
     * @memberof MapWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MapWithRelations
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MapWithRelations
     */
    floor?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MapWithRelations
     */
    visible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWithRelations
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof MapWithRelations
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWithRelations
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWithRelations
     */
    limitMaximumBookedResources?: number;
    /**
     * 
     * @type {string}
     * @memberof MapWithRelations
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWithRelations
     */
    botUserEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof MapWithRelations
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof MapWithRelations
     */
    locationId: string;
    /**
     * 
     * @type {MapWithRelationsAllOfLocation}
     * @memberof MapWithRelations
     */
    location: MapWithRelationsAllOfLocation;
}
/**
 * 
 * @export
 * @interface MapWithRelationsAllOf
 */
export interface MapWithRelationsAllOf {
    /**
     * 
     * @type {MapWithRelationsAllOfLocation}
     * @memberof MapWithRelationsAllOf
     */
    location: MapWithRelationsAllOfLocation;
}
/**
 * 
 * @export
 * @interface MapWithRelationsAllOfLocation
 */
export interface MapWithRelationsAllOfLocation {
    /**
     * 
     * @type {string}
     * @memberof MapWithRelationsAllOfLocation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MapWithRelationsAllOfLocation
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof MapWithRelationsAllOfLocation
     */
    visible?: boolean;
}
/**
 * 
 * @export
 * @interface MapWithoutId
 */
export interface MapWithoutId {
    /**
     * 
     * @type {string}
     * @memberof MapWithoutId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MapWithoutId
     */
    floor?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MapWithoutId
     */
    visible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapWithoutId
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof MapWithoutId
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWithoutId
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MapWithoutId
     */
    limitMaximumBookedResources?: number;
    /**
     * 
     * @type {string}
     * @memberof MapWithoutId
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MapWithoutId
     */
    botUserEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof MapWithoutId
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof MapWithoutId
     */
    locationId: string;
}
/**
 * 
 * @export
 * @interface MapWithoutIdAllOf
 */
export interface MapWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof MapWithoutIdAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MapWithoutIdAllOf
     */
    locationId: string;
}
/**
 * 
 * @export
 * @interface MeAdmin
 */
export interface MeAdmin {
    /**
     * 
     * @type {number}
     * @memberof MeAdmin
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MeAdmin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MeAdmin
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof MeAdmin
     */
    lastName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MeAdmin
     */
    roles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MeAdmin
     */
    companyIds: Array<string>;
    /**
     * 
     * @type {BillingType}
     * @memberof MeAdmin
     */
    billingType?: BillingType;
    /**
     * 
     * @type {Language}
     * @memberof MeAdmin
     */
    language: Language | null;
    /**
     * 
     * @type {MeAdminOperations}
     * @memberof MeAdmin
     */
    _operations: MeAdminOperations;
}
/**
 * 
 * @export
 * @interface MeAdminOperations
 */
export interface MeAdminOperations {
    /**
     * 
     * @type {MeAdminOperationsScreens}
     * @memberof MeAdminOperations
     */
    _screens: MeAdminOperationsScreens;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadCompany?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListAllCompanies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListAdminUsers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListLocations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadLocation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateLocation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateLocation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListDoors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadDoor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateDoor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateDoor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListResources?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadResource?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateResource?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateResource?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListMaps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadMap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateMap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateMap?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUploadMapImage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canDeactivateSeat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListReportReasons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadReport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadReportReason?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateReportReason?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateReportReason?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListReservations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadReservationsStatistics?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canExportReservations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListSeatTags?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadSeatTag?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateSeatTag?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateSeatTag?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListSeats?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListDirtySeats?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadSeat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateSeat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateSeat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListMapObjects?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadMapObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateMapObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateMapObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canDeleteMapObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListTimeslots?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadTimeslot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateTimeslot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateTimeslot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canDeleteTimeslot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListUserGroups?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadUserGroup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateUserGroup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateUserGroup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListICal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListUsers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canImportUsers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canSendUserInvitations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadLocationStats?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListReport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadStatistics?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canReadCompanyTransportOption?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListCompanyTransportOptions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canCreateCompanyTransportOption?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canUpdateCompanyTransportOption?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canListExportFiles?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canDeleteReport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeAdminOperations
     */
    canExportUsers?: boolean;
}
/**
 * 
 * @export
 * @interface MeAdminOperationsScreens
 */
export interface MeAdminOperationsScreens {
    /**
     * 
     * @type {Screen}
     * @memberof MeAdminOperationsScreens
     */
    dashboard: Screen;
    /**
     * 
     * @type {Screen}
     * @memberof MeAdminOperationsScreens
     */
    settings: Screen;
    /**
     * 
     * @type {Screen}
     * @memberof MeAdminOperationsScreens
     */
    offices: Screen;
    /**
     * 
     * @type {Screen}
     * @memberof MeAdminOperationsScreens
     */
    users: Screen;
    /**
     * 
     * @type {Screen}
     * @memberof MeAdminOperationsScreens
     */
    facilityManagement: Screen;
    /**
     * 
     * @type {Screen}
     * @memberof MeAdminOperationsScreens
     */
    reservations: Screen;
}
/**
 * 
 * @export
 * @interface MeUser
 */
export interface MeUser {
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    firstName: string;
    /**
     * Depricated, part of lastName now
     * @type {string}
     * @memberof MeUser
     * @deprecated
     */
    infix?: string;
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    lastName: string;
    /**
     * (job) Title of the user
     * @type {string}
     * @memberof MeUser
     */
    title: string | null;
    /**
     * 
     * @type {Language}
     * @memberof MeUser
     */
    language: Language;
    /**
     * 
     * @type {boolean}
     * @memberof MeUser
     */
    locationTrackingEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeUser
     */
    privacyModeEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeUser
     */
    pushEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeUser
     */
    checkInPushEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    profileImageUrl: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MeUser
     */
    defaultIncludeParking: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    iCalId: string;
    /**
     * 
     * @type {string}
     * @memberof MeUser
     */
    defaultTransportOptionId: string | null;
    /**
     * 
     * @type {CompanyTransportOption}
     * @memberof MeUser
     */
    defaultTransportOption: CompanyTransportOption | null;
    /**
     * 
     * @type {number}
     * @memberof MeUser
     */
    reservationWindowLengthDesk: number;
    /**
     * 
     * @type {number}
     * @memberof MeUser
     */
    reservationWindowLengthLunch: number;
    /**
     * 
     * @type {number}
     * @memberof MeUser
     */
    reservationWindowLengthMeeting: number;
    /**
     * 
     * @type {number}
     * @memberof MeUser
     */
    reservationWindowLengthVisitor: number;
    /**
     * 
     * @type {number}
     * @memberof MeUser
     */
    reservationWindowLengthResource: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof MeUser
     */
    companies: Array<object>;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof MeUser
     */
    userGroups: Array<UserGroup>;
    /**
     * 
     * @type {V2MeUserOperations}
     * @memberof MeUser
     */
    _operations?: V2MeUserOperations;
}
/**
 * Information about a group in Microsoft
 * @export
 * @interface MicrosoftGroup
 */
export interface MicrosoftGroup {
    /**
     * 
     * @type {string}
     * @memberof MicrosoftGroup
     */
    microsoftGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftGroup
     */
    microsoftGroupName: string;
}
/**
 * Information about a room in Microsoft
 * @export
 * @interface MicrosoftRoom
 */
export interface MicrosoftRoom {
    /**
     * 
     * @type {string}
     * @memberof MicrosoftRoom
     */
    microsoftRoomId: string;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftRoom
     */
    microsoftRoomName: string;
}
/**
 * 
 * @export
 * @interface ModelMap
 */
export interface ModelMap {
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    floor?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMap
     */
    visible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    limitMaximumBookedResources?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    botUserEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    locationId: string;
}
/**
 * 
 * @export
 * @interface OAuthPasswordGrantResponse
 */
export interface OAuthPasswordGrantResponse {
    /**
     * 
     * @type {string}
     * @memberof OAuthPasswordGrantResponse
     */
    access_token: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthPasswordGrantResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthPasswordGrantResponse
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthPasswordGrantResponse
     */
    token_type: string;
}
/**
 * 
 * @export
 * @interface Operations
 */
export interface Operations {
    /**
     * 
     * @type {OperationsOperations}
     * @memberof Operations
     */
    _operations?: OperationsOperations;
}
/**
 * 
 * @export
 * @interface OperationsOperations
 */
export interface OperationsOperations {
    /**
     * Can delete this entity
     * @type {boolean}
     * @memberof OperationsOperations
     */
    canDelete?: boolean;
    /**
     * Reason why the entity cannot be deleted
     * @type {string}
     * @memberof OperationsOperations
     */
    cannotDeleteDescription?: string;
    /**
     * Can update name for this user
     * @type {boolean}
     * @memberof OperationsOperations
     */
    canUpdateName?: boolean;
    /**
     * Can update email for this user
     * @type {boolean}
     * @memberof OperationsOperations
     */
    canUpdateEmail?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderDirection {
    Up = 'up',
    Down = 'down'
}

/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    details: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    created: string;
}
/**
 * 
 * @export
 * @interface ReportProperties
 */
export interface ReportProperties {
    /**
     * 
     * @type {string}
     * @memberof ReportProperties
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportProperties
     */
    details?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportProperties
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface ReportReason
 */
export interface ReportReason {
    /**
     * 
     * @type {string}
     * @memberof ReportReason
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReason
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReason
     */
    reason: string;
}
/**
 * 
 * @export
 * @interface ReportReasonProperties
 */
export interface ReportReasonProperties {
    /**
     * 
     * @type {string}
     * @memberof ReportReasonProperties
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReasonProperties
     */
    reason?: string;
}
/**
 * 
 * @export
 * @interface ReportReasonWithRelations
 */
export interface ReportReasonWithRelations {
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithRelations
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithRelations
     */
    reason: string;
    /**
     * 
     * @type {ReportReasonWithRelationsAllOfLocation}
     * @memberof ReportReasonWithRelations
     */
    location: ReportReasonWithRelationsAllOfLocation;
}
/**
 * 
 * @export
 * @interface ReportReasonWithRelationsAllOf
 */
export interface ReportReasonWithRelationsAllOf {
    /**
     * 
     * @type {ReportReasonWithRelationsAllOfLocation}
     * @memberof ReportReasonWithRelationsAllOf
     */
    location: ReportReasonWithRelationsAllOfLocation;
}
/**
 * 
 * @export
 * @interface ReportReasonWithRelationsAllOfLocation
 */
export interface ReportReasonWithRelationsAllOfLocation {
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithRelationsAllOfLocation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithRelationsAllOfLocation
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ReportReasonWithoutId
 */
export interface ReportReasonWithoutId {
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithoutId
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithoutId
     */
    reason: string;
}
/**
 * 
 * @export
 * @interface ReportReasonWithoutIdAllOf
 */
export interface ReportReasonWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithoutIdAllOf
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReasonWithoutIdAllOf
     */
    reason: string;
}
/**
 * 
 * @export
 * @interface ReportWithRelations
 */
export interface ReportWithRelations {
    /**
     * 
     * @type {string}
     * @memberof ReportWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReportWithRelations
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportWithRelations
     */
    details: string;
    /**
     * 
     * @type {string}
     * @memberof ReportWithRelations
     */
    created: string;
    /**
     * 
     * @type {ReportReasonWithRelationsAllOfLocation}
     * @memberof ReportWithRelations
     */
    location: ReportReasonWithRelationsAllOfLocation;
    /**
     * 
     * @type {ReportReason}
     * @memberof ReportWithRelations
     */
    reason: ReportReason;
    /**
     * 
     * @type {User}
     * @memberof ReportWithRelations
     */
    user: User;
}
/**
 * 
 * @export
 * @interface ReportWithRelationsAllOf
 */
export interface ReportWithRelationsAllOf {
    /**
     * 
     * @type {ReportReasonWithRelationsAllOfLocation}
     * @memberof ReportWithRelationsAllOf
     */
    location: ReportReasonWithRelationsAllOfLocation;
    /**
     * 
     * @type {ReportReason}
     * @memberof ReportWithRelationsAllOf
     */
    reason: ReportReason;
    /**
     * 
     * @type {User}
     * @memberof ReportWithRelationsAllOf
     */
    user: User;
}
/**
 * 
 * @export
 * @interface ReportWithoutId
 */
export interface ReportWithoutId {
    /**
     * 
     * @type {string}
     * @memberof ReportWithoutId
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportWithoutId
     */
    details: string;
    /**
     * 
     * @type {string}
     * @memberof ReportWithoutId
     */
    created: string;
}
/**
 * 
 * @export
 * @interface ReportWithoutIdAllOf
 */
export interface ReportWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportWithoutIdAllOf
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportWithoutIdAllOf
     */
    details: string;
    /**
     * 
     * @type {string}
     * @memberof ReportWithoutIdAllOf
     */
    created: string;
}
/**
 * 
 * @export
 * @interface Reservation
 */
export interface Reservation {
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     * @deprecated
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     * @deprecated
     */
    date: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof Reservation
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof Reservation
     */
    dateEnd: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof Reservation
     */
    includeParking: boolean;
    /**
     * undefined if not checked in Or if check in times are hidden (company setting)
     * @type {string}
     * @memberof Reservation
     */
    checkedInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    checkedOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    visitorName: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    visitorEmail: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    visitorPhone: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    expiresAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    expiredAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Reservation
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    cancelledReason?: string;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof Reservation
     */
    status: ReservationStatus;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    reoccurReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    companyTransportOptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    microsoftId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    reoccurMicrosoftId?: string | null;
    /**
     * Reservation title
     * @type {string}
     * @memberof Reservation
     */
    title?: string | null;
    /**
     * Reservation description
     * @type {string}
     * @memberof Reservation
     */
    description?: string | null;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof Reservation
     */
    created: string;
}
/**
 * 
 * @export
 * @interface ReservationAdd
 */
export interface ReservationAdd {
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    seatId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     * @deprecated
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     * @deprecated
     */
    date?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationAdd
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationAdd
     */
    dateEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationAdd
     */
    includeParking?: boolean;
    /**
     * 
     * @type {ReservationType}
     * @memberof ReservationAdd
     */
    type: ReservationType;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    visitorName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    visitorEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    visitorPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    companyTransportOptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAdd
     */
    rrule?: string;
}
/**
 * 
 * @export
 * @interface ReservationAddAllOf
 */
export interface ReservationAddAllOf {
    /**
     * 
     * @type {ReservationType}
     * @memberof ReservationAddAllOf
     */
    type: ReservationType;
    /**
     * 
     * @type {string}
     * @memberof ReservationAddAllOf
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationAddAllOf
     */
    rrule?: string;
}
/**
 * 
 * @export
 * @interface ReservationCanCreate
 */
export interface ReservationCanCreate {
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     */
    locationId?: string;
    /**
     * 
     * @type {SeatType}
     * @memberof ReservationCanCreate
     */
    seatType: SeatType;
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     */
    seatId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     * @deprecated
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     * @deprecated
     */
    date: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationCanCreate
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationCanCreate
     */
    dateEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationCanCreate
     */
    includeParking?: boolean;
    /**
     * 
     * @type {ReservationType}
     * @memberof ReservationCanCreate
     */
    type: ReservationType;
    /**
     * 
     * @type {string}
     * @memberof ReservationCanCreate
     */
    rrule?: string;
}
/**
 * 
 * @export
 * @interface ReservationOperations
 */
export interface ReservationOperations {
    /**
     * 
     * @type {ReservationOperationsOperations}
     * @memberof ReservationOperations
     */
    _operations?: ReservationOperationsOperations;
}
/**
 * 
 * @export
 * @interface ReservationOperationsOperations
 */
export interface ReservationOperationsOperations {
    /**
     * Can cancel this reservation
     * @type {boolean}
     * @memberof ReservationOperationsOperations
     */
    canCancel?: boolean;
}
/**
 * 
 * @export
 * @interface ReservationProperties
 */
export interface ReservationProperties {
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     * @deprecated
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     * @deprecated
     */
    date?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationProperties
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationProperties
     */
    dateEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationProperties
     */
    includeParking?: boolean;
    /**
     * undefined if not checked in Or if check in times are hidden (company setting)
     * @type {string}
     * @memberof ReservationProperties
     */
    checkedInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    checkedOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    visitorName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    visitorEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    visitorPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    expiresAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    expiredAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationProperties
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    cancelledReason?: string;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ReservationProperties
     */
    status?: ReservationStatus;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    reoccurReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    companyTransportOptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    microsoftId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationProperties
     */
    reoccurMicrosoftId?: string | null;
    /**
     * Reservation title
     * @type {string}
     * @memberof ReservationProperties
     */
    title?: string | null;
    /**
     * Reservation description
     * @type {string}
     * @memberof ReservationProperties
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReservationStatus {
    Expired = 'expired',
    Cancelled = 'cancelled',
    CheckedOut = 'checkedOut',
    CheckedIn = 'checkedIn',
    Reserved = 'reserved'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReservationType {
    Normal = 'normal',
    Visitor = 'visitor',
    Home = 'home',
    Resource = 'resource'
}

/**
 * 
 * @export
 * @interface ReservationUpdate
 */
export interface ReservationUpdate {
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     */
    seatId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     * @deprecated
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     * @deprecated
     */
    date?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationUpdate
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationUpdate
     */
    dateEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationUpdate
     */
    includeParking?: boolean;
    /**
     * 
     * @type {ReservationType}
     * @memberof ReservationUpdate
     */
    type?: ReservationType;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     */
    visitorName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     */
    visitorEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     */
    visitorPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     */
    companyTransportOptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdate
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface ReservationUpdateReoccurring
 */
export interface ReservationUpdateReoccurring {
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    seatId: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     * @deprecated
     */
    to?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    dateEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationUpdateReoccurring
     */
    includeParking?: boolean;
    /**
     * 
     * @type {ReservationType}
     * @memberof ReservationUpdateReoccurring
     */
    type: ReservationType;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    visitorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    visitorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    visitorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    companyTransportOptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationUpdateReoccurring
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface ReservationWithRelations
 */
export interface ReservationWithRelations {
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     * @deprecated
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     * @deprecated
     */
    date: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithRelations
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithRelations
     */
    dateEnd: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationWithRelations
     */
    includeParking: boolean;
    /**
     * undefined if not checked in Or if check in times are hidden (company setting)
     * @type {string}
     * @memberof ReservationWithRelations
     */
    checkedInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    checkedOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    visitorName: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    visitorEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    visitorPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    expiresAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    expiredAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationWithRelations
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    cancelledReason?: string;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ReservationWithRelations
     */
    status: ReservationStatus;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    reoccurReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    companyTransportOptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    microsoftId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithRelations
     */
    reoccurMicrosoftId?: string | null;
    /**
     * Reservation title
     * @type {string}
     * @memberof ReservationWithRelations
     */
    title?: string | null;
    /**
     * Reservation description
     * @type {string}
     * @memberof ReservationWithRelations
     */
    description?: string | null;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithRelations
     */
    created: string;
    /**
     * 
     * @type {ReservationOperationsOperations}
     * @memberof ReservationWithRelations
     */
    _operations?: ReservationOperationsOperations;
    /**
     * 
     * @type {Seat & object}
     * @memberof ReservationWithRelations
     */
    seat: Seat & object | null;
    /**
     * 
     * @type {User}
     * @memberof ReservationWithRelations
     */
    user?: User;
    /**
     * 
     * @type {User}
     * @memberof ReservationWithRelations
     */
    createdByUser?: User;
}
/**
 * 
 * @export
 * @interface ReservationWithRelationsAllOf
 */
export interface ReservationWithRelationsAllOf {
    /**
     * 
     * @type {Seat & object}
     * @memberof ReservationWithRelationsAllOf
     */
    seat: Seat & object | null;
    /**
     * 
     * @type {User}
     * @memberof ReservationWithRelationsAllOf
     */
    user?: User;
    /**
     * 
     * @type {User}
     * @memberof ReservationWithRelationsAllOf
     */
    createdByUser?: User;
}
/**
 * 
 * @export
 * @interface ReservationWithoutId
 */
export interface ReservationWithoutId {
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     * @deprecated
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     * @deprecated
     */
    date: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithoutId
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithoutId
     */
    dateEnd: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationWithoutId
     */
    includeParking: boolean;
    /**
     * undefined if not checked in Or if check in times are hidden (company setting)
     * @type {string}
     * @memberof ReservationWithoutId
     */
    checkedInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    checkedOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    visitorName: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    visitorEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    visitorPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    expiresAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    expiredAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationWithoutId
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    cancelledReason?: string;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ReservationWithoutId
     */
    status: ReservationStatus;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    reoccurReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    companyTransportOptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    microsoftId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutId
     */
    reoccurMicrosoftId?: string | null;
    /**
     * Reservation title
     * @type {string}
     * @memberof ReservationWithoutId
     */
    title?: string | null;
    /**
     * Reservation description
     * @type {string}
     * @memberof ReservationWithoutId
     */
    description?: string | null;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithoutId
     */
    created: string;
}
/**
 * 
 * @export
 * @interface ReservationWithoutIdAllOf
 */
export interface ReservationWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     * @deprecated
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     * @deprecated
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     * @deprecated
     */
    date: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    dateStart?: string;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    dateEnd: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationWithoutIdAllOf
     */
    includeParking: boolean;
    /**
     * undefined if not checked in Or if check in times are hidden (company setting)
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    checkedInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    checkedOutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    visitorName: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    visitorEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    visitorPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    expiredAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationWithoutIdAllOf
     */
    cancelled?: boolean;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ReservationWithoutIdAllOf
     */
    status: ReservationStatus;
    /**
     * https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
     * @type {string}
     * @memberof ReservationWithoutIdAllOf
     */
    created: string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    resourceType: string;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    reservationConnectionEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    reservationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    dateTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldUpdate>}
     * @memberof Resource
     */
    customFields: Array<CustomFieldUpdate>;
}
/**
 * 
 * @export
 * @interface ResourceProperties
 */
export interface ResourceProperties {
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceProperties
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    resourceType?: ResourcePropertiesResourceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceProperties
     */
    reservationConnectionEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    reservationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceProperties
     */
    dateTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceProperties
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceProperties
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldUpdate>}
     * @memberof ResourceProperties
     */
    customFields?: Array<CustomFieldUpdate>;
}

/**
    * @export
    * @enum {string}
    */
export enum ResourcePropertiesResourceTypeEnum {
    Parkingspot = 'parkingspot',
    Equipment = 'equipment',
    Food = 'food'
}

/**
 * 
 * @export
 * @interface ResourceReservation
 */
export interface ResourceReservation {
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    reservationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    dateTo: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    visitorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    visitorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    visitorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservation
     */
    reoccurReferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceReservation
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceReservation
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldValueUpdate>}
     * @memberof ResourceReservation
     */
    customFieldValues?: Array<CustomFieldValueUpdate>;
}
/**
 * 
 * @export
 * @interface ResourceReservationProperties
 */
export interface ResourceReservationProperties {
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    reservationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    resourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    dateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    visitorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    visitorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    visitorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationProperties
     */
    reoccurReferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceReservationProperties
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceReservationProperties
     */
    amountAvailable?: number | null;
}
/**
 * 
 * @export
 * @interface ResourceReservationWithRelations
 */
export interface ResourceReservationWithRelations {
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    reservationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    dateTo: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    visitorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    visitorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    visitorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithRelations
     */
    reoccurReferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceReservationWithRelations
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceReservationWithRelations
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldValueUpdate>}
     * @memberof ResourceReservationWithRelations
     */
    customFieldValues?: Array<CustomFieldValueUpdate>;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceReservationWithRelations
     */
    user: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceReservationWithRelations
     */
    resource: DoorWithRelationsAllOfMap;
}
/**
 * 
 * @export
 * @interface ResourceReservationWithRelationsAllOf
 */
export interface ResourceReservationWithRelationsAllOf {
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceReservationWithRelationsAllOf
     */
    user: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceReservationWithRelationsAllOf
     */
    resource: DoorWithRelationsAllOfMap;
}
/**
 * 
 * @export
 * @interface ResourceReservationWithoutId
 */
export interface ResourceReservationWithoutId {
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    reservationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    dateTo: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    visitorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    visitorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    visitorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutId
     */
    reoccurReferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceReservationWithoutId
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceReservationWithoutId
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldValueUpdate>}
     * @memberof ResourceReservationWithoutId
     */
    customFieldValues?: Array<CustomFieldValueUpdate>;
}
/**
 * 
 * @export
 * @interface ResourceReservationWithoutIdAllOf
 */
export interface ResourceReservationWithoutIdAllOf {
    /**
     * 
     * @type {Array<CustomFieldValueUpdate>}
     * @memberof ResourceReservationWithoutIdAllOf
     */
    customFieldValues?: Array<CustomFieldValueUpdate>;
}
/**
 * 
 * @export
 * @interface ResourceReservationWithoutIdAndCustomFieldValues
 */
export interface ResourceReservationWithoutIdAndCustomFieldValues {
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    reservationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    dateTo: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    visitorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    visitorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    visitorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    reoccurReferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValues
     */
    amountAvailable?: number | null;
}
/**
 * 
 * @export
 * @interface ResourceReservationWithoutIdAndCustomFieldValuesAllOf
 */
export interface ResourceReservationWithoutIdAndCustomFieldValuesAllOf {
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    reservationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    dateTo: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    visitorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    visitorEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    visitorPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    reoccurReferenceId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceReservationWithoutIdAndCustomFieldValuesAllOf
     */
    amountAvailable?: number | null;
}
/**
 * 
 * @export
 * @interface ResourceWithRelations
 */
export interface ResourceWithRelations {
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithRelations
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    resourceType: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithRelations
     */
    reservationConnectionEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    reservationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelations
     */
    dateTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithRelations
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceWithRelations
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldUpdate>}
     * @memberof ResourceWithRelations
     */
    customFields: Array<CustomFieldUpdate>;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceWithRelations
     */
    location: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceWithRelations
     */
    user?: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {ResourceWithRelationsAllOfReservation}
     * @memberof ResourceWithRelations
     */
    reservation?: ResourceWithRelationsAllOfReservation;
}
/**
 * 
 * @export
 * @interface ResourceWithRelationsAllOf
 */
export interface ResourceWithRelationsAllOf {
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceWithRelationsAllOf
     */
    location: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {DoorWithRelationsAllOfMap}
     * @memberof ResourceWithRelationsAllOf
     */
    user?: DoorWithRelationsAllOfMap;
    /**
     * 
     * @type {ResourceWithRelationsAllOfReservation}
     * @memberof ResourceWithRelationsAllOf
     */
    reservation?: ResourceWithRelationsAllOfReservation;
}
/**
 * 
 * @export
 * @interface ResourceWithRelationsAllOfReservation
 */
export interface ResourceWithRelationsAllOfReservation {
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelationsAllOfReservation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelationsAllOfReservation
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithRelationsAllOfReservation
     */
    dateTo: string;
}
/**
 * 
 * @export
 * @interface ResourceWithoutId
 */
export interface ResourceWithoutId {
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithoutId
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    resourceType: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithoutId
     */
    reservationConnectionEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    reservationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    createdByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutId
     */
    dateTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithoutId
     */
    repeatedReservation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceWithoutId
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldUpdate>}
     * @memberof ResourceWithoutId
     */
    customFields: Array<CustomFieldUpdate>;
}
/**
 * 
 * @export
 * @interface ResourceWithoutIdAllOf
 */
export interface ResourceWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutIdAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutIdAllOf
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithoutIdAllOf
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceWithoutIdAllOf
     */
    resourceType: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceWithoutIdAllOf
     */
    reservationConnectionEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceWithoutIdAllOf
     */
    amountAvailable?: number | null;
    /**
     * 
     * @type {Array<CustomFieldUpdate>}
     * @memberof ResourceWithoutIdAllOf
     */
    customFields: Array<CustomFieldUpdate>;
}
/**
 * Information about this response (response time and status)
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    status: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    took: number;
}
/**
 * 
 * @export
 * @interface Saltolock
 */
export interface Saltolock {
    /**
     * 
     * @type {string}
     * @memberof Saltolock
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Saltolock
     */
    saltoIqId: string;
    /**
     * 
     * @type {string}
     * @memberof Saltolock
     */
    saltoIqName: string;
    /**
     * 
     * @type {string}
     * @memberof Saltolock
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SaltolockAllOf
 */
export interface SaltolockAllOf {
    /**
     * 
     * @type {string}
     * @memberof SaltolockAllOf
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SaltolockAllOf
     */
    saltoIqId: string;
    /**
     * 
     * @type {string}
     * @memberof SaltolockAllOf
     */
    saltoIqName: string;
    /**
     * 
     * @type {string}
     * @memberof SaltolockAllOf
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Screen
 */
export interface Screen {
    /**
     * if the admin should show this screen
     * @type {boolean}
     * @memberof Screen
     */
    show: boolean;
}
/**
 * 
 * @export
 * @interface Seat
 */
export interface Seat {
    /**
     * 
     * @type {string}
     * @memberof Seat
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Seat
     */
    mapId: string;
    /**
     * 
     * @type {string}
     * @memberof Seat
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Seat
     */
    coordX: number | null;
    /**
     * 
     * @type {number}
     * @memberof Seat
     */
    coordY: number | null;
    /**
     * 
     * @type {string}
     * @memberof Seat
     */
    blockfilter?: string;
    /**
     * 
     * @type {SeatType}
     * @memberof Seat
     */
    seatType: SeatType;
    /**
     * 
     * @type {boolean}
     * @memberof Seat
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seat
     */
    isZone?: boolean;
    /**
     * The maximum number of (concurrent) reservations that can be made for this seat.
     * @type {number}
     * @memberof Seat
     */
    reservationCapacity?: number;
    /**
     * space separated list of x,y coordinates, format from points: https://www.w3schools.com/graphics/svg_polygon.asp
     * @type {string}
     * @memberof Seat
     */
    zoneCoordinates?: string | null;
    /**
     * this is a seat that can only be booked in the future
     * @type {boolean}
     * @memberof Seat
     */
    notFutureReservable?: boolean;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof Seat
     */
    userGroups?: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatSeatTag>}
     * @memberof Seat
     */
    seatTags?: Array<SeatSeatTag>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Seat
     */
    barcodes?: Array<object>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof Seat
     */
    seatExternals?: Array<SeatExternal>;
}
/**
 * 
 * @export
 * @interface SeatExternal
 */
export interface SeatExternal {
    /**
     * 
     * @type {string}
     * @memberof SeatExternal
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof SeatExternal
     */
    type: string;
}
/**
 * 
 * @export
 * @interface SeatOperations
 */
export interface SeatOperations {
    /**
     * 
     * @type {SeatOperationsOperations}
     * @memberof SeatOperations
     */
    _operations?: SeatOperationsOperations;
}
/**
 * 
 * @export
 * @interface SeatOperationsOperations
 */
export interface SeatOperationsOperations {
    /**
     * Can deactivate this entity
     * @type {boolean}
     * @memberof SeatOperationsOperations
     */
    canDeactivate?: boolean;
    /**
     * Can deactivate this entity
     * @type {boolean}
     * @memberof SeatOperationsOperations
     */
    canDelete?: boolean;
}
/**
 * 
 * @export
 * @interface SeatProperties
 */
export interface SeatProperties {
    /**
     * 
     * @type {string}
     * @memberof SeatProperties
     */
    mapId?: string;
    /**
     * 
     * @type {string}
     * @memberof SeatProperties
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SeatProperties
     */
    coordX?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SeatProperties
     */
    coordY?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SeatProperties
     */
    blockfilter?: string;
    /**
     * 
     * @type {string}
     * @memberof SeatProperties
     */
    seatType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SeatProperties
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SeatProperties
     */
    isZone?: boolean;
    /**
     * The maximum number of (concurrent) reservations that can be made for this seat.
     * @type {number}
     * @memberof SeatProperties
     */
    reservationCapacity?: number;
    /**
     * space separated list of x,y coordinates, format from points: https://www.w3schools.com/graphics/svg_polygon.asp
     * @type {string}
     * @memberof SeatProperties
     */
    zoneCoordinates?: string | null;
    /**
     * this is a seat that can only be booked in the future
     * @type {boolean}
     * @memberof SeatProperties
     */
    notFutureReservable?: boolean;
}
/**
 * 
 * @export
 * @interface SeatSeatTag
 */
export interface SeatSeatTag {
    /**
     * 
     * @type {string}
     * @memberof SeatSeatTag
     */
    id: string;
}
/**
 * 
 * @export
 * @interface SeatTag
 */
export interface SeatTag {
    /**
     * 
     * @type {string}
     * @memberof SeatTag
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SeatTag
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SeatTag
     */
    id: string;
}
/**
 * 
 * @export
 * @interface SeatTagProperties
 */
export interface SeatTagProperties {
    /**
     * 
     * @type {string}
     * @memberof SeatTagProperties
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof SeatTagProperties
     */
    icon?: string | null;
}
/**
 * 
 * @export
 * @interface SeatTagWithoutId
 */
export interface SeatTagWithoutId {
    /**
     * 
     * @type {string}
     * @memberof SeatTagWithoutId
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SeatTagWithoutId
     */
    icon?: string | null;
}
/**
 * 
 * @export
 * @interface SeatTagWithoutIdAllOf
 */
export interface SeatTagWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof SeatTagWithoutIdAllOf
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SeatTagWithoutIdAllOf
     */
    icon?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SeatType {
    Desk = 'desk',
    Lunch = 'lunch',
    Meeting = 'meeting'
}

/**
 * 
 * @export
 * @interface SeatTypeStatistic
 */
export interface SeatTypeStatistic {
    /**
     * 
     * @type {number}
     * @memberof SeatTypeStatistic
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SeatTypeStatistic
     */
    used: number;
    /**
     * 
     * @type {number}
     * @memberof SeatTypeStatistic
     */
    max: number;
}
/**
 * 
 * @export
 * @interface SeatUpdate
 */
export interface SeatUpdate {
    /**
     * 
     * @type {string}
     * @memberof SeatUpdate
     */
    mapId?: string;
    /**
     * 
     * @type {string}
     * @memberof SeatUpdate
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SeatUpdate
     */
    coordX?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SeatUpdate
     */
    coordY?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SeatUpdate
     */
    blockfilter?: string;
    /**
     * 
     * @type {SeatType}
     * @memberof SeatUpdate
     */
    seatType?: SeatType;
    /**
     * 
     * @type {boolean}
     * @memberof SeatUpdate
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SeatUpdate
     */
    isZone?: boolean;
    /**
     * The maximum number of (concurrent) reservations that can be made for this seat.
     * @type {number}
     * @memberof SeatUpdate
     */
    reservationCapacity?: number;
    /**
     * space separated list of x,y coordinates, format from points: https://www.w3schools.com/graphics/svg_polygon.asp
     * @type {string}
     * @memberof SeatUpdate
     */
    zoneCoordinates?: string | null;
    /**
     * this is a seat that can only be booked in the future
     * @type {boolean}
     * @memberof SeatUpdate
     */
    notFutureReservable?: boolean;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof SeatUpdate
     */
    userGroups?: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof SeatUpdate
     */
    seatExternals?: Array<SeatExternal>;
    /**
     * 
     * @type {Array<SeatSeatTag>}
     * @memberof SeatUpdate
     */
    seatTags?: Array<SeatSeatTag>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SeatUpdate
     */
    barcodes?: Array<object>;
}
/**
 * 
 * @export
 * @interface SeatUpdateAllOf
 */
export interface SeatUpdateAllOf {
    /**
     * 
     * @type {SeatType}
     * @memberof SeatUpdateAllOf
     */
    seatType?: SeatType;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof SeatUpdateAllOf
     */
    userGroups?: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof SeatUpdateAllOf
     */
    seatExternals?: Array<SeatExternal>;
    /**
     * 
     * @type {Array<SeatSeatTag>}
     * @memberof SeatUpdateAllOf
     */
    seatTags?: Array<SeatSeatTag>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SeatUpdateAllOf
     */
    barcodes?: Array<object>;
}
/**
 * 
 * @export
 * @interface SeatUserGroup
 */
export interface SeatUserGroup {
    /**
     * 
     * @type {string}
     * @memberof SeatUserGroup
     */
    id: string;
    /**
     * 
     * @type {Array<SeatUserGroupConditions>}
     * @memberof SeatUserGroup
     */
    conditions?: Array<SeatUserGroupConditions>;
}
/**
 * 
 * @export
 * @interface SeatUserGroupConditions
 */
export interface SeatUserGroupConditions {
    /**
     * Zero based weekday, Sunday - Saturday = 0 - 6
     * @type {number}
     * @memberof SeatUserGroupConditions
     */
    weekday: number;
    /**
     * 
     * @type {string}
     * @memberof SeatUserGroupConditions
     */
    timeFrom: string;
    /**
     * 
     * @type {string}
     * @memberof SeatUserGroupConditions
     */
    timeTo: string;
}
/**
 * 
 * @export
 * @interface SeatWithRelations
 */
export interface SeatWithRelations {
    /**
     * 
     * @type {string}
     * @memberof SeatWithRelations
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SeatWithRelations
     */
    mapId: string;
    /**
     * 
     * @type {string}
     * @memberof SeatWithRelations
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SeatWithRelations
     */
    coordX: number | null;
    /**
     * 
     * @type {number}
     * @memberof SeatWithRelations
     */
    coordY: number | null;
    /**
     * 
     * @type {string}
     * @memberof SeatWithRelations
     */
    blockfilter?: string;
    /**
     * 
     * @type {SeatType}
     * @memberof SeatWithRelations
     */
    seatType: SeatType;
    /**
     * 
     * @type {boolean}
     * @memberof SeatWithRelations
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SeatWithRelations
     */
    isZone?: boolean;
    /**
     * The maximum number of (concurrent) reservations that can be made for this seat.
     * @type {number}
     * @memberof SeatWithRelations
     */
    reservationCapacity?: number;
    /**
     * space separated list of x,y coordinates, format from points: https://www.w3schools.com/graphics/svg_polygon.asp
     * @type {string}
     * @memberof SeatWithRelations
     */
    zoneCoordinates?: string | null;
    /**
     * this is a seat that can only be booked in the future
     * @type {boolean}
     * @memberof SeatWithRelations
     */
    notFutureReservable?: boolean;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof SeatWithRelations
     */
    userGroups: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatTag>}
     * @memberof SeatWithRelations
     */
    seatTags: Array<SeatTag>;
    /**
     * 
     * @type {Array<SeatWithRelationsAllOfBarcodes>}
     * @memberof SeatWithRelations
     */
    barcodes: Array<SeatWithRelationsAllOfBarcodes>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof SeatWithRelations
     */
    seatExternals?: Array<SeatExternal>;
    /**
     * 
     * @type {SeatOperationsOperations}
     * @memberof SeatWithRelations
     */
    _operations?: SeatOperationsOperations;
}
/**
 * 
 * @export
 * @interface SeatWithRelationsAllOf
 */
export interface SeatWithRelationsAllOf {
    /**
     * 
     * @type {Array<SeatTag>}
     * @memberof SeatWithRelationsAllOf
     */
    seatTags: Array<SeatTag>;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof SeatWithRelationsAllOf
     */
    userGroups: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof SeatWithRelationsAllOf
     */
    seatExternals?: Array<SeatExternal>;
    /**
     * 
     * @type {Array<SeatWithRelationsAllOfBarcodes>}
     * @memberof SeatWithRelationsAllOf
     */
    barcodes: Array<SeatWithRelationsAllOfBarcodes>;
}
/**
 * 
 * @export
 * @interface SeatWithRelationsAllOfBarcodes
 */
export interface SeatWithRelationsAllOfBarcodes {
    /**
     * 
     * @type {string}
     * @memberof SeatWithRelationsAllOfBarcodes
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SeatWithRelationsAllOfBarcodes
     */
    code: string;
}
/**
 * 
 * @export
 * @interface SeatWithoutId
 */
export interface SeatWithoutId {
    /**
     * 
     * @type {string}
     * @memberof SeatWithoutId
     */
    mapId: string;
    /**
     * 
     * @type {string}
     * @memberof SeatWithoutId
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SeatWithoutId
     */
    coordX: number | null;
    /**
     * 
     * @type {number}
     * @memberof SeatWithoutId
     */
    coordY: number | null;
    /**
     * 
     * @type {string}
     * @memberof SeatWithoutId
     */
    blockfilter?: string;
    /**
     * 
     * @type {SeatType}
     * @memberof SeatWithoutId
     */
    seatType: SeatType;
    /**
     * 
     * @type {boolean}
     * @memberof SeatWithoutId
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SeatWithoutId
     */
    isZone?: boolean;
    /**
     * The maximum number of (concurrent) reservations that can be made for this seat.
     * @type {number}
     * @memberof SeatWithoutId
     */
    reservationCapacity?: number;
    /**
     * space separated list of x,y coordinates, format from points: https://www.w3schools.com/graphics/svg_polygon.asp
     * @type {string}
     * @memberof SeatWithoutId
     */
    zoneCoordinates?: string | null;
    /**
     * this is a seat that can only be booked in the future
     * @type {boolean}
     * @memberof SeatWithoutId
     */
    notFutureReservable?: boolean;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof SeatWithoutId
     */
    userGroups?: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatSeatTag>}
     * @memberof SeatWithoutId
     */
    seatTags?: Array<SeatSeatTag>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SeatWithoutId
     */
    barcodes?: Array<object>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof SeatWithoutId
     */
    seatExternals?: Array<SeatExternal>;
}
/**
 * 
 * @export
 * @interface SeatWithoutIdAllOf
 */
export interface SeatWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof SeatWithoutIdAllOf
     */
    mapId: string;
    /**
     * 
     * @type {string}
     * @memberof SeatWithoutIdAllOf
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SeatWithoutIdAllOf
     */
    coordX: number | null;
    /**
     * 
     * @type {number}
     * @memberof SeatWithoutIdAllOf
     */
    coordY: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SeatWithoutIdAllOf
     */
    isZone?: boolean;
    /**
     * The maximum number of (concurrent) reservations that can be made for this seat.
     * @type {number}
     * @memberof SeatWithoutIdAllOf
     */
    reservationCapacity?: number;
    /**
     * space separated list of x,y coordinates, format from points: https://www.w3schools.com/graphics/svg_polygon.asp
     * @type {string}
     * @memberof SeatWithoutIdAllOf
     */
    zoneCoordinates?: string | null;
    /**
     * 
     * @type {SeatType}
     * @memberof SeatWithoutIdAllOf
     */
    seatType: SeatType;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof SeatWithoutIdAllOf
     */
    userGroups?: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatSeatTag>}
     * @memberof SeatWithoutIdAllOf
     */
    seatTags?: Array<SeatSeatTag>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SeatWithoutIdAllOf
     */
    barcodes?: Array<object>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof SeatWithoutIdAllOf
     */
    seatExternals?: Array<SeatExternal>;
}
/**
 * 
 * @export
 * @interface Timeslot
 */
export interface Timeslot {
    /**
     * 
     * @type {string}
     * @memberof Timeslot
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Timeslot
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Timeslot
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof Timeslot
     */
    to: string;
    /**
     * 
     * @type {TimeslotType}
     * @memberof Timeslot
     */
    type: TimeslotType;
}
/**
 * 
 * @export
 * @interface TimeslotProperties
 */
export interface TimeslotProperties {
    /**
     * 
     * @type {string}
     * @memberof TimeslotProperties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeslotProperties
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeslotProperties
     */
    to?: string;
    /**
     * 
     * @type {TimeslotType}
     * @memberof TimeslotProperties
     */
    type?: TimeslotType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeslotType {
    Desk = 'desk',
    Lunch = 'lunch',
    Visitor = 'visitor',
    Meeting = 'meeting'
}

/**
 * 
 * @export
 * @interface TimeslotWithoutId
 */
export interface TimeslotWithoutId {
    /**
     * 
     * @type {string}
     * @memberof TimeslotWithoutId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TimeslotWithoutId
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof TimeslotWithoutId
     */
    to: string;
    /**
     * 
     * @type {TimeslotType}
     * @memberof TimeslotWithoutId
     */
    type: TimeslotType;
}
/**
 * 
 * @export
 * @interface TimeslotWithoutIdAllOf
 */
export interface TimeslotWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof TimeslotWithoutIdAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TimeslotWithoutIdAllOf
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof TimeslotWithoutIdAllOf
     */
    to: string;
    /**
     * 
     * @type {TimeslotType}
     * @memberof TimeslotWithoutIdAllOf
     */
    type: TimeslotType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransportType {
    UserCar = 'user_car',
    CompanyCar = 'company_car',
    PublicTransport = 'public_transport',
    Walk = 'walk',
    Bicycle = 'bicycle'
}

/**
 * 
 * @export
 * @interface UsedSeat
 */
export interface UsedSeat {
    /**
     * 
     * @type {string}
     * @memberof UsedSeat
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UsedSeat
     */
    mapId: string;
    /**
     * 
     * @type {string}
     * @memberof UsedSeat
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UsedSeat
     */
    coordX: number | null;
    /**
     * 
     * @type {number}
     * @memberof UsedSeat
     */
    coordY: number | null;
    /**
     * 
     * @type {string}
     * @memberof UsedSeat
     */
    blockfilter?: string;
    /**
     * 
     * @type {SeatType}
     * @memberof UsedSeat
     */
    seatType: SeatType;
    /**
     * 
     * @type {boolean}
     * @memberof UsedSeat
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsedSeat
     */
    isZone?: boolean;
    /**
     * The maximum number of (concurrent) reservations that can be made for this seat.
     * @type {number}
     * @memberof UsedSeat
     */
    reservationCapacity?: number;
    /**
     * space separated list of x,y coordinates, format from points: https://www.w3schools.com/graphics/svg_polygon.asp
     * @type {string}
     * @memberof UsedSeat
     */
    zoneCoordinates?: string | null;
    /**
     * this is a seat that can only be booked in the future
     * @type {boolean}
     * @memberof UsedSeat
     */
    notFutureReservable?: boolean;
    /**
     * 
     * @type {Array<SeatUserGroup>}
     * @memberof UsedSeat
     */
    userGroups?: Array<SeatUserGroup>;
    /**
     * 
     * @type {Array<SeatSeatTag>}
     * @memberof UsedSeat
     */
    seatTags?: Array<SeatSeatTag>;
    /**
     * 
     * @type {Array<object>}
     * @memberof UsedSeat
     */
    barcodes?: Array<object>;
    /**
     * 
     * @type {Array<SeatExternal>}
     * @memberof UsedSeat
     */
    seatExternals?: Array<SeatExternal>;
    /**
     * 
     * @type {boolean}
     * @memberof UsedSeat
     */
    dirty: boolean;
}
/**
 * 
 * @export
 * @interface UsedSeatAllOf
 */
export interface UsedSeatAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof UsedSeatAllOf
     */
    dirty: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    activeSince?: string;
    /**
     * 
     * @type {Language}
     * @memberof User
     */
    language: Language;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    status?: UserStatus;
    /**
     * Is this user favorite of the calling user?
     * @type {boolean}
     * @memberof User
     */
    isFavorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    externalReference?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    compensationDailyDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    defaultTransportOptionId?: string | null;
    /**
     * 
     * @type {Array<UserGroupLink>}
     * @memberof User
     */
    userGroups: Array<UserGroupLink>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string | null;
}
/**
 * 
 * @export
 * @interface UserAllOf
 */
export interface UserAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserAllOf
     */
    id: string | null;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    email: string;
    /**
     * 
     * @type {Language}
     * @memberof UserCreate
     */
    language: Language;
    /**
     * 
     * @type {Array<UserGroupLink>}
     * @memberof UserCreate
     */
    userGroups: Array<UserGroupLink>;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserCreate
     */
    status?: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    externalReference?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCreate
     */
    compensationDailyDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    defaultTransportOptionId?: string | null;
}
/**
 * 
 * @export
 * @interface UserGroup
 */
export interface UserGroup {
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    label: string;
    /**
     * The length of the reservation window in days. This is deprecated and will be removed in future versions. Use the specific reservationWindowLength properties (desk/meeting/lunch/visitor) instead.
     * @type {number}
     * @memberof UserGroup
     * @deprecated
     */
    reservationWindowLength?: number | null;
    /**
     * If true, the users in this group are emergency response officer (BHV\'er).
     * @type {boolean}
     * @memberof UserGroup
     */
    isEmergencyResponseOfficer?: boolean;
    /**
     * The length of the reservation window in days for desk reservations.
     * @type {number}
     * @memberof UserGroup
     */
    reservationWindowLengthDesk?: number | null;
    /**
     * The length of the reservation window in days for lunch reservations.
     * @type {number}
     * @memberof UserGroup
     */
    reservationWindowLengthLunch?: number | null;
    /**
     * The length of the reservation window in days for meeting reservations.
     * @type {number}
     * @memberof UserGroup
     */
    reservationWindowLengthMeeting?: number | null;
    /**
     * The length of the reservation window in days for visitor reservations.
     * @type {number}
     * @memberof UserGroup
     */
    reservationWindowLengthVisitor?: number | null;
    /**
     * The length of the reservation window in days for resource reservations.
     * @type {number}
     * @memberof UserGroup
     */
    reservationWindowLengthResource?: number | null;
    /**
     * Is this group a department? (shown in some lists)
     * @type {boolean}
     * @memberof UserGroup
     */
    isDepartment?: boolean;
    /**
     * The number of hours before the reservation start time that the reservation will be released (bookable by everybody).
     * @type {number}
     * @memberof UserGroup
     */
    releaseHours?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserGroup
     */
    id: string;
}
/**
 * 
 * @export
 * @interface UserGroupAllOf
 */
export interface UserGroupAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserGroupAllOf
     */
    id: string;
}
/**
 * 
 * @export
 * @interface UserGroupLink
 */
export interface UserGroupLink {
    /**
     * 
     * @type {string}
     * @memberof UserGroupLink
     */
    id: string;
}
/**
 * 
 * @export
 * @interface UserGroupProperties
 */
export interface UserGroupProperties {
    /**
     * 
     * @type {string}
     * @memberof UserGroupProperties
     */
    label?: string;
    /**
     * The length of the reservation window in days. This is deprecated and will be removed in future versions. Use the specific reservationWindowLength properties (desk/meeting/lunch/visitor) instead.
     * @type {number}
     * @memberof UserGroupProperties
     * @deprecated
     */
    reservationWindowLength?: number | null;
    /**
     * If true, the users in this group are emergency response officer (BHV\'er).
     * @type {boolean}
     * @memberof UserGroupProperties
     */
    isEmergencyResponseOfficer?: boolean;
    /**
     * The length of the reservation window in days for desk reservations.
     * @type {number}
     * @memberof UserGroupProperties
     */
    reservationWindowLengthDesk?: number | null;
    /**
     * The length of the reservation window in days for lunch reservations.
     * @type {number}
     * @memberof UserGroupProperties
     */
    reservationWindowLengthLunch?: number | null;
    /**
     * The length of the reservation window in days for meeting reservations.
     * @type {number}
     * @memberof UserGroupProperties
     */
    reservationWindowLengthMeeting?: number | null;
    /**
     * The length of the reservation window in days for visitor reservations.
     * @type {number}
     * @memberof UserGroupProperties
     */
    reservationWindowLengthVisitor?: number | null;
    /**
     * The length of the reservation window in days for resource reservations.
     * @type {number}
     * @memberof UserGroupProperties
     */
    reservationWindowLengthResource?: number | null;
    /**
     * Is this group a department? (shown in some lists)
     * @type {boolean}
     * @memberof UserGroupProperties
     */
    isDepartment?: boolean;
    /**
     * The number of hours before the reservation start time that the reservation will be released (bookable by everybody).
     * @type {number}
     * @memberof UserGroupProperties
     */
    releaseHours?: number | null;
}
/**
 * 
 * @export
 * @interface UserGroupWithoutId
 */
export interface UserGroupWithoutId {
    /**
     * 
     * @type {string}
     * @memberof UserGroupWithoutId
     */
    label: string;
    /**
     * The length of the reservation window in days. This is deprecated and will be removed in future versions. Use the specific reservationWindowLength properties (desk/meeting/lunch/visitor) instead.
     * @type {number}
     * @memberof UserGroupWithoutId
     * @deprecated
     */
    reservationWindowLength?: number | null;
    /**
     * If true, the users in this group are emergency response officer (BHV\'er).
     * @type {boolean}
     * @memberof UserGroupWithoutId
     */
    isEmergencyResponseOfficer?: boolean;
    /**
     * The length of the reservation window in days for desk reservations.
     * @type {number}
     * @memberof UserGroupWithoutId
     */
    reservationWindowLengthDesk?: number | null;
    /**
     * The length of the reservation window in days for lunch reservations.
     * @type {number}
     * @memberof UserGroupWithoutId
     */
    reservationWindowLengthLunch?: number | null;
    /**
     * The length of the reservation window in days for meeting reservations.
     * @type {number}
     * @memberof UserGroupWithoutId
     */
    reservationWindowLengthMeeting?: number | null;
    /**
     * The length of the reservation window in days for visitor reservations.
     * @type {number}
     * @memberof UserGroupWithoutId
     */
    reservationWindowLengthVisitor?: number | null;
    /**
     * The length of the reservation window in days for resource reservations.
     * @type {number}
     * @memberof UserGroupWithoutId
     */
    reservationWindowLengthResource?: number | null;
    /**
     * Is this group a department? (shown in some lists)
     * @type {boolean}
     * @memberof UserGroupWithoutId
     */
    isDepartment?: boolean;
    /**
     * The number of hours before the reservation start time that the reservation will be released (bookable by everybody).
     * @type {number}
     * @memberof UserGroupWithoutId
     */
    releaseHours?: number | null;
}
/**
 * 
 * @export
 * @interface UserGroupWithoutIdAllOf
 */
export interface UserGroupWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserGroupWithoutIdAllOf
     */
    label: string;
}
/**
 * 
 * @export
 * @interface UserProperties
 */
export interface UserProperties {
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    activeSince?: string;
    /**
     * 
     * @type {Language}
     * @memberof UserProperties
     */
    language?: Language;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserProperties
     */
    status?: UserStatus;
    /**
     * Is this user favorite of the calling user?
     * @type {boolean}
     * @memberof UserProperties
     */
    isFavorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    externalReference?: string;
    /**
     * 
     * @type {number}
     * @memberof UserProperties
     */
    compensationDailyDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    defaultTransportOptionId?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserStatus {
    Active = 'active',
    Inactive = 'inactive'
}

/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email?: string;
    /**
     * 
     * @type {Language}
     * @memberof UserUpdate
     */
    language?: Language;
    /**
     * 
     * @type {Array<UserGroupLink>}
     * @memberof UserUpdate
     */
    userGroups?: Array<UserGroupLink>;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserUpdate
     */
    status?: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    externalReference?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    compensationDailyDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    defaultTransportOptionId?: string | null;
}
/**
 * 
 * @export
 * @interface UserWithRelations
 */
export interface UserWithRelations {
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    lastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    activeSince?: string;
    /**
     * 
     * @type {Language}
     * @memberof UserWithRelations
     */
    language: Language;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserWithRelations
     */
    status?: UserStatus;
    /**
     * Is this user favorite of the calling user?
     * @type {boolean}
     * @memberof UserWithRelations
     */
    isFavorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    externalReference?: string;
    /**
     * 
     * @type {number}
     * @memberof UserWithRelations
     */
    compensationDailyDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    defaultTransportOptionId?: string | null;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof UserWithRelations
     */
    userGroups: Array<UserGroup>;
    /**
     * 
     * @type {string}
     * @memberof UserWithRelations
     */
    id: string | null;
    /**
     * 
     * @type {Array<UserWithRelationsAllOfSsoProviders>}
     * @memberof UserWithRelations
     */
    ssoProviders: Array<UserWithRelationsAllOfSsoProviders>;
}
/**
 * 
 * @export
 * @interface UserWithRelationsAllOf
 */
export interface UserWithRelationsAllOf {
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof UserWithRelationsAllOf
     */
    userGroups: Array<UserGroup>;
    /**
     * 
     * @type {Array<UserWithRelationsAllOfSsoProviders>}
     * @memberof UserWithRelationsAllOf
     */
    ssoProviders: Array<UserWithRelationsAllOfSsoProviders>;
}
/**
 * 
 * @export
 * @interface UserWithRelationsAllOfSsoProviders
 */
export interface UserWithRelationsAllOfSsoProviders {
    /**
     * 
     * @type {string}
     * @memberof UserWithRelationsAllOfSsoProviders
     */
    provider?: UserWithRelationsAllOfSsoProvidersProviderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserWithRelationsAllOfSsoProvidersProviderEnum {
    MicrosoftGraph = 'microsoft_graph',
    Surfconext = 'surfconext'
}

/**
 * 
 * @export
 * @interface UserWithoutId
 */
export interface UserWithoutId {
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    lastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    activeSince?: string;
    /**
     * 
     * @type {Language}
     * @memberof UserWithoutId
     */
    language: Language;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserWithoutId
     */
    status?: UserStatus;
    /**
     * Is this user favorite of the calling user?
     * @type {boolean}
     * @memberof UserWithoutId
     */
    isFavorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    externalReference?: string;
    /**
     * 
     * @type {number}
     * @memberof UserWithoutId
     */
    compensationDailyDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutId
     */
    defaultTransportOptionId?: string | null;
    /**
     * 
     * @type {Array<UserGroupLink>}
     * @memberof UserWithoutId
     */
    userGroups: Array<UserGroupLink>;
}
/**
 * 
 * @export
 * @interface UserWithoutIdAllOf
 */
export interface UserWithoutIdAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserWithoutIdAllOf
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutIdAllOf
     */
    lastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithoutIdAllOf
     */
    email: string | null;
    /**
     * 
     * @type {Language}
     * @memberof UserWithoutIdAllOf
     */
    language: Language;
    /**
     * 
     * @type {Array<UserGroupLink>}
     * @memberof UserWithoutIdAllOf
     */
    userGroups: Array<UserGroupLink>;
}
/**
 * 
 * @export
 * @interface V2MeUser
 */
export interface V2MeUser {
    /**
     * 
     * @type {string}
     * @memberof V2MeUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V2MeUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof V2MeUser
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof V2MeUser
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof V2MeUser
     */
    infix: string;
    /**
     * 
     * @type {string}
     * @memberof V2MeUser
     */
    last_name: string;
    /**
     * 
     * @type {LanguageLowercase}
     * @memberof V2MeUser
     */
    language: LanguageLowercase;
    /**
     * 
     * @type {boolean}
     * @memberof V2MeUser
     */
    location_tracking_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2MeUser
     */
    privacy_mode_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2MeUser
     */
    push_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V2MeUser
     */
    check_in_push_enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof V2MeUser
     */
    profileImageUrl: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof V2MeUser
     */
    companies: Array<object>;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof V2MeUser
     */
    userGroups: Array<UserGroup>;
    /**
     * 
     * @type {V2MeUserOperations}
     * @memberof V2MeUser
     */
    _operations?: V2MeUserOperations;
}
/**
 * 
 * @export
 * @interface V2MeUserOperations
 */
export interface V2MeUserOperations {
    /**
     * Can update name fields (first, infix, last)
     * @type {boolean}
     * @memberof V2MeUserOperations
     */
    canUpdateName?: boolean;
    /**
     * Must (force) the user to update their name fields (first, infix, last)
     * @type {boolean}
     * @memberof V2MeUserOperations
     */
    mustUpdateName?: boolean;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Verify the SAML response and redirect to frontend url with OTP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acsPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/acs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminUserCreate} adminUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminUser: async (adminUserCreate: AdminUserCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserCreate' is not null or undefined
            assertParamExists('addAdminUser', 'adminUserCreate', adminUserCreate)
            const localVarPath = `/v3/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyWithoutId} companyWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompany: async (companyWithoutId: CompanyWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyWithoutId' is not null or undefined
            assertParamExists('addCompany', 'companyWithoutId', companyWithoutId)
            const localVarPath = `/v3/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyTransportOptionWithoutId} companyTransportOptionWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyTransportOption: async (companyTransportOptionWithoutId: CompanyTransportOptionWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyTransportOptionWithoutId' is not null or undefined
            assertParamExists('addCompanyTransportOption', 'companyTransportOptionWithoutId', companyTransportOptionWithoutId)
            const localVarPath = `/v3/company-transport-option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyTransportOptionWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DoorWithoutId} doorWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoor: async (doorWithoutId: DoorWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorWithoutId' is not null or undefined
            assertParamExists('addDoor', 'doorWithoutId', doorWithoutId)
            const localVarPath = `/v4/door`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doorWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a favorite user
         * @param {FavoriteUserWithoutId} favoriteUserWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteUser: async (favoriteUserWithoutId: FavoriteUserWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteUserWithoutId' is not null or undefined
            assertParamExists('addFavoriteUser', 'favoriteUserWithoutId', favoriteUserWithoutId)
            const localVarPath = `/v3/favoriteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoriteUserWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LocationWithoutId} locationWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocation: async (locationWithoutId: LocationWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationWithoutId' is not null or undefined
            assertParamExists('addLocation', 'locationWithoutId', locationWithoutId)
            const localVarPath = `/v3/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MapWithoutId} mapWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMap: async (mapWithoutId: MapWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapWithoutId' is not null or undefined
            assertParamExists('addMap', 'mapWithoutId', mapWithoutId)
            const localVarPath = `/v3/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMapFile: async (mapId: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('addMapFile', 'mapId', mapId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addMapFile', 'file', file)
            const localVarPath = `/v3/map/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MapObjectWithoutId} mapObjectWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMapObject: async (mapObjectWithoutId: MapObjectWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapObjectWithoutId' is not null or undefined
            assertParamExists('addMapObject', 'mapObjectWithoutId', mapObjectWithoutId)
            const localVarPath = `/v3/mapobject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapObjectWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOriginalMapFile: async (mapId: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('addOriginalMapFile', 'mapId', mapId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addOriginalMapFile', 'file', file)
            const localVarPath = `/v3/map/originalImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProfileImage: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addProfileImage', 'file', file)
            const localVarPath = `/v3/me/profileImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportReasonWithoutId} reportReasonWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReportReason: async (reportReasonWithoutId: ReportReasonWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportReasonWithoutId' is not null or undefined
            assertParamExists('addReportReason', 'reportReasonWithoutId', reportReasonWithoutId)
            const localVarPath = `/v3/report-reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportReasonWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReservationAdd} reservationAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReservation: async (reservationAdd: ReservationAdd, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationAdd' is not null or undefined
            assertParamExists('addReservation', 'reservationAdd', reservationAdd)
            const localVarPath = `/v3/reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationAdd, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceWithoutId} resourceWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResource: async (resourceWithoutId: ResourceWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceWithoutId' is not null or undefined
            assertParamExists('addResource', 'resourceWithoutId', resourceWithoutId)
            const localVarPath = `/v4/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourceReservation: async (uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uNKNOWNBASETYPE' is not null or undefined
            assertParamExists('addResourceReservation', 'uNKNOWNBASETYPE', uNKNOWNBASETYPE)
            const localVarPath = `/v4/resourceReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uNKNOWNBASETYPE, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SeatWithoutId} seatWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeat: async (seatWithoutId: SeatWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seatWithoutId' is not null or undefined
            assertParamExists('addSeat', 'seatWithoutId', seatWithoutId)
            const localVarPath = `/v3/seat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seatWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SeatTagWithoutId} seatTagWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeatTag: async (seatTagWithoutId: SeatTagWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seatTagWithoutId' is not null or undefined
            assertParamExists('addSeatTag', 'seatTagWithoutId', seatTagWithoutId)
            const localVarPath = `/v3/seattag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seatTagWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TimeslotWithoutId} timeslotWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTimeslot: async (timeslotWithoutId: TimeslotWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeslotWithoutId' is not null or undefined
            assertParamExists('addTimeslot', 'timeslotWithoutId', timeslotWithoutId)
            const localVarPath = `/v3/timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeslotWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (userCreate: UserCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('addUser', 'userCreate', userCreate)
            const localVarPath = `/v3/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserGroupWithoutId} userGroupWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserGroup: async (userGroupWithoutId: UserGroupWithoutId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroupWithoutId' is not null or undefined
            assertParamExists('addUserGroup', 'userGroupWithoutId', userGroupWithoutId)
            const localVarPath = `/v3/usergroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupWithoutId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to test if a reservation with the provided properties is allowed to be created
         * @param {ReservationCanCreate} reservationCanCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCreateReservation: async (reservationCanCreate: ReservationCanCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationCanCreate' is not null or undefined
            assertParamExists('canCreateReservation', 'reservationCanCreate', reservationCanCreate)
            const localVarPath = `/v3/reservation/canCreate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationCanCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to test if a reservation with the provided properties is allowed to be updated
         * @param {string} reservationId Reservation ID
         * @param {ReservationUpdate} reservationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canUpdateReservation: async (reservationId: string, reservationUpdate: ReservationUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('canUpdateReservation', 'reservationId', reservationId)
            // verify required parameter 'reservationUpdate' is not null or undefined
            assertParamExists('canUpdateReservation', 'reservationUpdate', reservationUpdate)
            const localVarPath = `/v3/reservation/{reservationId}/canUpdate`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject20} inlineObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReservation: async (inlineObject20: InlineObject20, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject20' is not null or undefined
            assertParamExists('cancelReservation', 'inlineObject20', inlineObject20)
            const localVarPath = `/v3/reservation/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject20, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {InlineObject17} inlineObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLocationOrder: async (locationId: string, inlineObject17: InlineObject17, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('changeLocationOrder', 'locationId', locationId)
            // verify required parameter 'inlineObject17' is not null or undefined
            assertParamExists('changeLocationOrder', 'inlineObject17', inlineObject17)
            const localVarPath = `/v3/location/{locationId}/changeOrder`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject17, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {InlineObject13} inlineObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMapOrder: async (mapId: string, inlineObject13: InlineObject13, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('changeMapOrder', 'mapId', mapId)
            // verify required parameter 'inlineObject13' is not null or undefined
            assertParamExists('changeMapOrder', 'inlineObject13', inlineObject13)
            const localVarPath = `/v3/map/{mapId}/changeOrder`
                .replace(`{${"mapId"}}`, encodeURIComponent(String(mapId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject13, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUser: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteAdminUser', 'userId', userId)
            const localVarPath = `/v3/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompany', 'companyId', companyId)
            const localVarPath = `/v3/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyTransportOption: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompanyTransportOption', 'id', id)
            const localVarPath = `/v3/company-transport-option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoor: async (doorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('deleteDoor', 'doorId', doorId)
            const localVarPath = `/v4/door`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (doorId !== undefined) {
                localVarQueryParameter['doorId'] = doorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id ExportFile ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExportFile: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExportFile', 'id', id)
            const localVarPath = `/v3/export-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a favorite user
         * @param {string} favoriteUserId ID of the other user to remove from favorites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteUser: async (favoriteUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteUserId' is not null or undefined
            assertParamExists('deleteFavoriteUser', 'favoriteUserId', favoriteUserId)
            const localVarPath = `/v3/favoriteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (favoriteUserId !== undefined) {
                localVarQueryParameter['favoriteUserId'] = favoriteUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (locationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('deleteLocation', 'locationId', locationId)
            const localVarPath = `/v3/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {string} exceptionId Exception ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationHours: async (locationId: string, exceptionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('deleteLocationHours', 'locationId', locationId)
            // verify required parameter 'exceptionId' is not null or undefined
            assertParamExists('deleteLocationHours', 'exceptionId', exceptionId)
            const localVarPath = `/v4/location/{locationId}/hours/{exceptionId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"exceptionId"}}`, encodeURIComponent(String(exceptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMap: async (mapId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('deleteMap', 'mapId', mapId)
            const localVarPath = `/v3/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMapObject: async (mapObjectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapObjectId' is not null or undefined
            assertParamExists('deleteMapObject', 'mapObjectId', mapObjectId)
            const localVarPath = `/v3/mapobject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapObjectId !== undefined) {
                localVarQueryParameter['mapObjectId'] = mapObjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete all future reservations with the same reoccurReferenceId
         * @param {string} reoccurReferenceId Reoccur reference ID
         * @param {string} [fromReservationId] Delete this reservation and all future reservations from this date with the same reoccurReferenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReoccurringReservation: async (reoccurReferenceId: string, fromReservationId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reoccurReferenceId' is not null or undefined
            assertParamExists('deleteReoccurringReservation', 'reoccurReferenceId', reoccurReferenceId)
            const localVarPath = `/v3/reservation/reoccurring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reoccurReferenceId !== undefined) {
                localVarQueryParameter['reoccurReferenceId'] = reoccurReferenceId;
            }

            if (fromReservationId !== undefined) {
                localVarQueryParameter['fromReservationId'] = fromReservationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReport', 'reportId', reportId)
            const localVarPath = `/v3/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportReason: async (reportReasonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportReasonId' is not null or undefined
            assertParamExists('deleteReportReason', 'reportReasonId', reportReasonId)
            const localVarPath = `/v3/report-reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportReasonId !== undefined) {
                localVarQueryParameter['reportReasonId'] = reportReasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation: async (reservationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('deleteReservation', 'reservationId', reservationId)
            const localVarPath = `/v3/reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reservationId !== undefined) {
                localVarQueryParameter['reservationId'] = reservationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Reservation
         * @summary Delete Reservation
         * @param {string} reservationId Reservation to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservationForApp: async (reservationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('deleteReservationForApp', 'reservationId', reservationId)
            const localVarPath = `/reservation/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResource: async (resourceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('deleteResource', 'resourceId', resourceId)
            const localVarPath = `/v4/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceReservation: async (resourceReservationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceReservationId' is not null or undefined
            assertParamExists('deleteResourceReservation', 'resourceReservationId', resourceReservationId)
            const localVarPath = `/v4/resourceReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceReservationId !== undefined) {
                localVarQueryParameter['resourceReservationId'] = resourceReservationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} seatId Seat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeat: async (seatId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seatId' is not null or undefined
            assertParamExists('deleteSeat', 'seatId', seatId)
            const localVarPath = `/v3/seat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (seatId !== undefined) {
                localVarQueryParameter['seatId'] = seatId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeatTag: async (tagId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteSeatTag', 'tagId', tagId)
            const localVarPath = `/v3/seattag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tagId !== undefined) {
                localVarQueryParameter['tagId'] = tagId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeslot: async (timeslotId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('deleteTimeslot', 'timeslotId', timeslotId)
            const localVarPath = `/v3/timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeslotId !== undefined) {
                localVarQueryParameter['timeslotId'] = timeslotId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/v3/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('deleteUserGroup', 'groupId', groupId)
            const localVarPath = `/v3/usergroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAdminUserEmails: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/export/adminUserEmails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCompanyUserCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/export/companyUserCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [userId] User ID
         * @param {ReservationStatus} [status] ReservationStatus
         * @param {string} [locationId] ReservationLocationId
         * @param {string} [mapId] ReservationMapId
         * @param {SeatType} [seatType] ReservationSeatType
         * @param {ReservationType} [type] ReservationType
         * @param {boolean} [includeAnonymous] includeAnonymous in reservations
         * @param {string} [companyTransportId] Filter reservations by company transport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReservations: async (companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, companyTransportId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('exportReservations', 'companyId', companyId)
            const localVarPath = `/v3/reservations/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (fromOperator !== undefined) {
                localVarQueryParameter['fromOperator'] = fromOperator;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }

            if (seatType !== undefined) {
                localVarQueryParameter['seatType'] = seatType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (includeAnonymous !== undefined) {
                localVarQueryParameter['includeAnonymous'] = includeAnonymous;
            }

            if (companyTransportId !== undefined) {
                localVarQueryParameter['companyTransportId'] = companyTransportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * throw api error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUser: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAdminUser', 'userId', userId)
            const localVarPath = `/v3/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalytics: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAnalytics', 'companyId', companyId)
            const localVarPath = `/v4/analytics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsForLocation: async (locationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getAnalyticsForLocation', 'locationId', locationId)
            const localVarPath = `/v4/analytics/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookMyDeskStatistics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/bookmydesk/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompany', 'companyId', companyId)
            const localVarPath = `/v3/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany4App: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompany4App', 'companyId', companyId)
            const localVarPath = `/company/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyExtended: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyExtended', 'companyId', companyId)
            const localVarPath = `/v3/companyExtended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMSGroup: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyMSGroup', 'companyId', companyId)
            const localVarPath = `/v3/companyMSGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMSGroupAvailable: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyMSGroupAvailable', 'companyId', companyId)
            const localVarPath = `/v3/companyMSGroup/availableGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get my reservations Company
         * @summary Get reservations for Company
         * @param {string} companyId Company ID to get reservations for
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [locationId] LocationId to get reservations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReservations: async (companyId: string, from?: string, to?: string, locationId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyReservations', 'companyId', companyId)
            const localVarPath = `/v2/company/{company_id}/reservation`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['location_id'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTransportOption: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyTransportOption', 'id', id)
            const localVarPath = `/v3/company-transport-option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTransportOptions: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyTransportOptions', 'companyId', companyId)
            const localVarPath = `/v3/company-transport-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataExport: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getDataExport', 'companyId', companyId)
            const localVarPath = `/v4/data-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoor: async (doorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('getDoor', 'doorId', doorId)
            const localVarPath = `/v4/door`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (doorId !== undefined) {
                localVarQueryParameter['doorId'] = doorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'created'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportFiles: async (companyId: string, sortField?: 'created', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getExportFiles', 'companyId', companyId)
            const localVarPath = `/v3/export-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get me iCal
         * @param {string} icalUuid UUID of the ical
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcal: async (icalUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'icalUuid' is not null or undefined
            assertParamExists('getIcal', 'icalUuid', icalUuid)
            const localVarPath = `/v3/ical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (icalUuid !== undefined) {
                localVarQueryParameter['ical_uuid'] = icalUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation: async (locationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getLocation', 'locationId', locationId)
            const localVarPath = `/v3/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get stats for location
         * @summary Get stats for location
         * @param {string} locationId Location ID to get stats for
         * @param {string} date Date to get stats for
         * @param {string} from Start time to get stats for
         * @param {string} to End of time range
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Deprecated, use seatStatistics instead
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationStats: async (locationId: string, date: string, from: string, to: string, seatType?: 'desk' | 'lunch' | 'meeting', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getLocationStats', 'locationId', locationId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getLocationStats', 'date', date)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getLocationStats', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getLocationStats', 'to', to)
            const localVarPath = `/v2/location/{location_id}/stats`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (seatType !== undefined) {
                localVarQueryParameter['seat_type'] = seatType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMap: async (mapId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('getMap', 'mapId', mapId)
            const localVarPath = `/v3/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapImage: async (mapId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('getMapImage', 'mapId', mapId)
            const localVarPath = `/map/{mapId}/image`
                .replace(`{${"mapId"}}`, encodeURIComponent(String(mapId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapImageUrl: async (mapId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('getMapImageUrl', 'mapId', mapId)
            const localVarPath = `/map/{mapId}/imageUrl`
                .replace(`{${"mapId"}}`, encodeURIComponent(String(mapId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapObject: async (mapObjectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapObjectId' is not null or undefined
            assertParamExists('getMapObject', 'mapObjectId', mapObjectId)
            const localVarPath = `/v3/mapobject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapObjectId !== undefined) {
                localVarQueryParameter['mapObjectId'] = mapObjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get requestion user
         * @summary Get  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get me user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe_1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get my reservations user
         * @summary Get reservations for user
         * @param {string} [companyId] Company ID to get reservations for
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReservations: async (companyId?: string, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/me/reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOriginalMapImageUrl: async (mapId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('getOriginalMapImageUrl', 'mapId', mapId)
            const localVarPath = `/v3/map/originalImageUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/v3/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportReason: async (reportReasonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportReasonId' is not null or undefined
            assertParamExists('getReportReason', 'reportReasonId', reportReasonId)
            const localVarPath = `/v3/report-reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportReasonId !== undefined) {
                localVarQueryParameter['reportReasonId'] = reportReasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {string} [dateFrom] Start date for getting amount available of resources
         * @param {string} [dateTo] End date for getting amount available of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResource: async (resourceId: string, dateFrom?: string, dateTo?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getResource', 'resourceId', resourceId)
            const localVarPath = `/v4/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceReservation: async (resourceReservationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceReservationId' is not null or undefined
            assertParamExists('getResourceReservation', 'resourceReservationId', resourceReservationId)
            const localVarPath = `/v4/resourceReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceReservationId !== undefined) {
                localVarQueryParameter['resourceReservationId'] = resourceReservationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [seatId] Seat ID
         * @param {string} [barcode] Barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeat: async (seatId?: string, barcode?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/seat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (seatId !== undefined) {
                localVarQueryParameter['seatId'] = seatId;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTag: async (tagId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('getSeatTag', 'tagId', tagId)
            const localVarPath = `/v3/seattag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tagId !== undefined) {
                localVarQueryParameter['tagId'] = tagId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatsAvailableMSRooms: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getSeatsAvailableMSRooms', 'companyId', companyId)
            const localVarPath = `/v3/seats/availableMSRooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeslot: async (timeslotId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('getTimeslot', 'timeslotId', timeslotId)
            const localVarPath = `/v3/timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeslotId !== undefined) {
                localVarQueryParameter['timeslotId'] = timeslotId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/v3/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getUserGroup', 'groupId', groupId)
            const localVarPath = `/v3/usergroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check API health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import users by uploading a CSV (; as delimiter) containing e-mail addresses in the first column, or with columns firstname;infix;lastName;email;userGroups (userGroups are the user group labels separated by a ,)
         * @param {string} companyId Company ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUsers: async (companyId: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('importUsers', 'companyId', companyId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importUsers', 'file', file)
            const localVarPath = `/v3/users/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'email'} [sortField] field to sort on
         * @param {string} [companyId] Company ID, required for company admin, optional for super admin
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminUsers: async (sortField?: 'email', companyId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/admin-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCompanies: async (sortField?: 'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created', sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDoors: async (companyId: string, take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listDoors', 'companyId', companyId)
            const localVarPath = `/v4/doors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations: async (companyId: string, take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listLocations', 'companyId', companyId)
            const localVarPath = `/v3/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMapObjects: async (companyId: string, mapId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listMapObjects', 'companyId', companyId)
            const localVarPath = `/v3/mapobjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMaps: async (companyId: string, take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listMaps', 'companyId', companyId)
            const localVarPath = `/v3/maps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'reason'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportReasons: async (companyId: string, sortField?: 'reason', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listReportReasons', 'companyId', companyId)
            const localVarPath = `/v3/report-reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'details'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (companyId: string, sortField?: 'details', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listReports', 'companyId', companyId)
            const localVarPath = `/v3/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {boolean} [allForCompany] Get all for company
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [userId] User ID
         * @param {ReservationStatus} [status] ReservationStatus
         * @param {string} [locationId] ReservationLocationId
         * @param {string} [mapId] ReservationMapId
         * @param {SeatType} [seatType] ReservationSeatType
         * @param {ReservationType} [type] ReservationType
         * @param {boolean} [includeAnonymous] includeAnonymous in reservations
         * @param {string} [userGroupId] Filter reservations by user group
         * @param {string} [companyTransportId] Filter reservations by company transport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReservations: async (companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', allForCompany?: boolean, take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, userGroupId?: string, companyTransportId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listReservations', 'companyId', companyId)
            const localVarPath = `/v3/reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (fromOperator !== undefined) {
                localVarQueryParameter['fromOperator'] = fromOperator;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (allForCompany !== undefined) {
                localVarQueryParameter['allForCompany'] = allForCompany;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }

            if (seatType !== undefined) {
                localVarQueryParameter['seatType'] = seatType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (includeAnonymous !== undefined) {
                localVarQueryParameter['includeAnonymous'] = includeAnonymous;
            }

            if (userGroupId !== undefined) {
                localVarQueryParameter['userGroupId'] = userGroupId;
            }

            if (companyTransportId !== undefined) {
                localVarQueryParameter['companyTransportId'] = companyTransportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {boolean} [isRepeatReservationParam] Filter reservations by repeat reservation
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [dateFrom] Start date for getting amount available of resource
         * @param {string} [dateTo] End date for getting amount available of resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResourceReservations: async (companyId: string, filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', from?: string, to?: string, isRepeatReservationParam?: boolean, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listResourceReservations', 'companyId', companyId)
            const localVarPath = `/v4/resourceReservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (fromOperator !== undefined) {
                localVarQueryParameter['fromOperator'] = fromOperator;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (isRepeatReservationParam !== undefined) {
                localVarQueryParameter['isRepeatReservationParam'] = isRepeatReservationParam;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [dateFrom] Start date for getting amount available of resources
         * @param {string} [dateTo] End date for getting amount available of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResources: async (companyId: string, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listResources', 'companyId', companyId)
            const localVarPath = `/v4/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSaltolocks: async (companyId: string, take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listSaltolocks', 'companyId', companyId)
            const localVarPath = `/v4/saltolocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'label'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSeatTags: async (companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listSeatTags', 'companyId', companyId)
            const localVarPath = `/v3/seattags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSeats: async (companyId: string, mapId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listSeats', 'companyId', companyId)
            const localVarPath = `/v3/seats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSeatsUsage: async (companyId: string, mapId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listSeatsUsage', 'companyId', companyId)
            const localVarPath = `/v3/seats/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'name' | 'from' | 'to'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimeslots: async (companyId: string, sortField?: 'name' | 'from' | 'to', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listTimeslots', 'companyId', companyId)
            const localVarPath = `/v3/timeslots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'label'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserGroups: async (companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listUserGroups', 'companyId', companyId)
            const localVarPath = `/v3/usergroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status'} [sortField] field to sort on
         * @param {boolean} [inviteSent] filter on whether an invite has been sent
         * @param {UserStatus} [status] filter on status
         * @param {Language} [language] filter on language
         * @param {string} [userGroupId] filter on userGroup
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (companyId: string, sortField?: 'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status', inviteSent?: boolean, status?: UserStatus, language?: Language, userGroupId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listUsers', 'companyId', companyId)
            const localVarPath = `/v3/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (inviteSent !== undefined) {
                localVarQueryParameter['inviteSent'] = inviteSent;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (userGroupId !== undefined) {
                localVarQueryParameter['userGroupId'] = userGroupId;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersExport: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listUsersExport', 'companyId', companyId)
            const localVarPath = `/v3/users/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsAdminUser: async (userId: number, clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loginAsAdminUser', 'userId', userId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loginAsAdminUser', 'clientId', clientId)
            const localVarPath = `/v3/admin-user/login-as`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout user
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [adminConsent] admin_consent from MS
         * @param {string} [state] State send in step one
         * @param {string} [tenant] Tennant of the admin user (company ID)
         * @param {string} [scope] Scope of the gotten access
         * @param {string} [error] MS error Code
         * @param {string} [errorDescription] error description by microsoft
         * @param {string} [errorUri] error description by microsoft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mSAdminCallback: async (adminConsent?: string, state?: string, tenant?: string, scope?: string, error?: string, errorDescription?: string, errorUri?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/auth/microsoft/adminCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (adminConsent !== undefined) {
                localVarQueryParameter['admin_consent'] = adminConsent;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['error_description'] = errorDescription;
            }

            if (errorUri !== undefined) {
                localVarQueryParameter['error_uri'] = errorUri;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] Code from MS
         * @param {string} [state] State send in step one
         * @param {string} [sessionState] State of session - what is difference?
         * @param {string} [error] MS error Code
         * @param {string} [errorDescription] error description by microsoft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mSCallback: async (code?: string, state?: string, sessionState?: string, error?: string, errorDescription?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/auth/microsoft/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (sessionState !== undefined) {
                localVarQueryParameter['session_state'] = sessionState;
            }

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }

            if (errorDescription !== undefined) {
                localVarQueryParameter['error_description'] = errorDescription;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SAML2 metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metadataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Basic API metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * oAuth routes, eg password grant or refresh
         * @summary Oauth password grant
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [refreshToken] 
         * @param {string} [grantType] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuthPasswordGrant: async (clientId?: string, clientSecret?: string, refreshToken?: string, grantType?: string, username?: string, password?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
            if (refreshToken !== undefined) { 
                localVarFormParams.set('refresh_token', refreshToken as any);
            }
    
            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openDoor: async (doorId: string, reservationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('openDoor', 'doorId', doorId)
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('openDoor', 'reservationId', reservationId)
            const localVarPath = `/v4/door/{doorId}/open`
                .replace(`{${"doorId"}}`, encodeURIComponent(String(doorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reservationId !== undefined) {
                localVarQueryParameter['reservationId'] = reservationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post new reservation
         * @summary Post new reservation
         * @param {string} companyId Company ID to create reservations for - not used by api but for backwards compatibility
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyReservation: async (companyId: string, inlineObject8?: InlineObject8, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompanyReservation', 'companyId', companyId)
            const localVarPath = `/v2/company/{company_id}/reservation`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject8, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post new Reocurring reservation
         * @summary Post new Reocurring reservation
         * @param {string} companyId Company ID to create reservations for - not needed as queried via seat
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyReservationReocurring: async (companyId: string, inlineObject10?: InlineObject10, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompanyReservationReocurring', 'companyId', companyId)
            const localVarPath = `/company/{company_id}/recurring-reservation`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject10, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post device token
         * @summary post Device
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDevice: async (inlineObject3: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject3' is not null or undefined
            assertParamExists('postDevice', 'inlineObject3', inlineObject3)
            const localVarPath = `/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post new report
         * @summary post report
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReport: async (inlineObject4: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject4' is not null or undefined
            assertParamExists('postReport', 'inlineObject4', inlineObject4)
            const localVarPath = `/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId ClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirToMS: async (clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('redirToMS', 'clientId', clientId)
            const localVarPath = `/v3/auth/microsoft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [type] Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirToMSAdmin: async (type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/auth/adminMicrosoft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new company
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCompany: async (inlineObject5: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject5' is not null or undefined
            assertParamExists('registerCompany', 'inlineObject5', inlineObject5)
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {CompanyTokenType} tokenType type of token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanyTokens: async (companyId: string, tokenType: CompanyTokenType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('removeCompanyTokens', 'companyId', companyId)
            // verify required parameter 'tokenType' is not null or undefined
            assertParamExists('removeCompanyTokens', 'tokenType', tokenType)
            const localVarPath = `/v3/company/removeCompanyTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (tokenType !== undefined) {
                localVarQueryParameter['tokenType'] = tokenType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request login by post\'ing email for user
         * @summary Request Login
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestLogin: async (inlineObject: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('requestLogin', 'inlineObject', inlineObject)
            const localVarPath = `/request-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId Reservation ID to checkin for
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationCheckIn: async (reservationId: string, inlineObject18?: InlineObject18, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('reservationCheckIn', 'reservationId', reservationId)
            const localVarPath = `/reservation/{reservationId}/checkin`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject18, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId Reservation ID to checkout for
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationCheckout: async (reservationId: string, inlineObject19?: InlineObject19, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('reservationCheckout', 'reservationId', reservationId)
            const localVarPath = `/reservation/{reservationId}/checkout`
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject19, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} from date
         * @param {string} to date
         * @param {SeatType} seatType seat type
         * @param {string} companyId Company ID
         * @param {string} [locationId] location
         * @param {string} [mapId] map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationsStatistics: async (from: string, to: string, seatType: SeatType, companyId: string, locationId?: string, mapId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('reservationsStatistics', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('reservationsStatistics', 'to', to)
            // verify required parameter 'seatType' is not null or undefined
            assertParamExists('reservationsStatistics', 'seatType', seatType)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('reservationsStatistics', 'companyId', companyId)
            const localVarPath = `/v3/reservations/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (seatType !== undefined) {
                localVarQueryParameter['seatType'] = seatType;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [locationId] location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationsStatisticsToday: async (companyId: string, locationId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('reservationsStatisticsToday', 'companyId', companyId)
            const localVarPath = `/v3/reservations/statistics/today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset user\'s password
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            assertParamExists('resetPassword', 'inlineObject1', inlineObject1)
            const localVarPath = `/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Redirect the browser to the SAML IDP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        samlRedirectGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/saml-redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send invitations to multiple users
         * @param {InlineObject15} inlineObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUsersInvitations: async (inlineObject15: InlineObject15, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject15' is not null or undefined
            assertParamExists('sendUsersInvitations', 'inlineObject15', inlineObject15)
            const localVarPath = `/v3/users/send-invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject15, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [locationId] ReservationLocationId
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsToday: async (companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('statisticsToday', 'companyId', companyId)
            const localVarPath = `/v3/statistics/today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (seatType !== undefined) {
                localVarQueryParameter['seatType'] = seatType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {AdminUserUpdate} adminUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUser: async (userId: number, adminUserUpdate: AdminUserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateAdminUser', 'userId', userId)
            // verify required parameter 'adminUserUpdate' is not null or undefined
            assertParamExists('updateAdminUser', 'adminUserUpdate', adminUserUpdate)
            const localVarPath = `/v3/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {CompanyProperties} companyProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (companyId: string, companyProperties: CompanyProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany', 'companyId', companyId)
            // verify required parameter 'companyProperties' is not null or undefined
            assertParamExists('updateCompany', 'companyProperties', companyProperties)
            const localVarPath = `/v3/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {InlineObject14} inlineObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyMSGroup: async (companyId: string, inlineObject14: InlineObject14, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyMSGroup', 'companyId', companyId)
            // verify required parameter 'inlineObject14' is not null or undefined
            assertParamExists('updateCompanyMSGroup', 'inlineObject14', inlineObject14)
            const localVarPath = `/v3/companyMSGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject14, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {CompanyTransportOptionProperties} companyTransportOptionProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyTransportOption: async (id: string, companyTransportOptionProperties: CompanyTransportOptionProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyTransportOption', 'id', id)
            // verify required parameter 'companyTransportOptionProperties' is not null or undefined
            assertParamExists('updateCompanyTransportOption', 'companyTransportOptionProperties', companyTransportOptionProperties)
            const localVarPath = `/v3/company-transport-option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyTransportOptionProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {DoorProperties} doorProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoor: async (doorId: string, doorProperties: DoorProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorId' is not null or undefined
            assertParamExists('updateDoor', 'doorId', doorId)
            // verify required parameter 'doorProperties' is not null or undefined
            assertParamExists('updateDoor', 'doorProperties', doorProperties)
            const localVarPath = `/v4/door`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (doorId !== undefined) {
                localVarQueryParameter['doorId'] = doorId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doorProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {LocationProperties} locationProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (locationId: string, locationProperties: LocationProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('updateLocation', 'locationId', locationId)
            // verify required parameter 'locationProperties' is not null or undefined
            assertParamExists('updateLocation', 'locationProperties', locationProperties)
            const localVarPath = `/v3/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {LocationHours} locationHours 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationHours: async (locationId: string, locationHours: LocationHours, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('updateLocationHours', 'locationId', locationId)
            // verify required parameter 'locationHours' is not null or undefined
            assertParamExists('updateLocationHours', 'locationHours', locationHours)
            const localVarPath = `/v4/location/{locationId}/hours`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationHours, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {MapProperties} mapProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMap: async (mapId: string, mapProperties: MapProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapId' is not null or undefined
            assertParamExists('updateMap', 'mapId', mapId)
            // verify required parameter 'mapProperties' is not null or undefined
            assertParamExists('updateMap', 'mapProperties', mapProperties)
            const localVarPath = `/v3/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapId !== undefined) {
                localVarQueryParameter['mapId'] = mapId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {MapObjectProperties} mapObjectProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMapObject: async (mapObjectId: string, mapObjectProperties: MapObjectProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mapObjectId' is not null or undefined
            assertParamExists('updateMapObject', 'mapObjectId', mapObjectId)
            // verify required parameter 'mapObjectProperties' is not null or undefined
            assertParamExists('updateMapObject', 'mapObjectProperties', mapObjectProperties)
            const localVarPath = `/v3/mapobject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mapObjectId !== undefined) {
                localVarQueryParameter['mapObjectId'] = mapObjectId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapObjectProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject21} inlineObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe: async (inlineObject21: InlineObject21, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject21' is not null or undefined
            assertParamExists('updateMe', 'inlineObject21', inlineObject21)
            const localVarPath = `/v3/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject21, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user\'s password
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (inlineObject2: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject2' is not null or undefined
            assertParamExists('updatePassword', 'inlineObject2', inlineObject2)
            const localVarPath = `/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update all future reservations with the same reoccurReferenceId
         * @param {string} reoccurReferenceId Reoccur reference ID
         * @param {ReservationUpdateReoccurring} reservationUpdateReoccurring 
         * @param {string} [referenceId] Reference ID
         * @param {string} [fromReservationId] Update this reservation and all future reservations from this date with the same reoccurReferenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReoccurringReservation: async (reoccurReferenceId: string, reservationUpdateReoccurring: ReservationUpdateReoccurring, referenceId?: string, fromReservationId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reoccurReferenceId' is not null or undefined
            assertParamExists('updateReoccurringReservation', 'reoccurReferenceId', reoccurReferenceId)
            // verify required parameter 'reservationUpdateReoccurring' is not null or undefined
            assertParamExists('updateReoccurringReservation', 'reservationUpdateReoccurring', reservationUpdateReoccurring)
            const localVarPath = `/v3/reservation/reoccurring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reoccurReferenceId !== undefined) {
                localVarQueryParameter['reoccurReferenceId'] = reoccurReferenceId;
            }

            if (referenceId !== undefined) {
                localVarQueryParameter['referenceId'] = referenceId;
            }

            if (fromReservationId !== undefined) {
                localVarQueryParameter['fromReservationId'] = fromReservationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationUpdateReoccurring, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {ReportReasonProperties} reportReasonProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportReason: async (reportReasonId: string, reportReasonProperties: ReportReasonProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportReasonId' is not null or undefined
            assertParamExists('updateReportReason', 'reportReasonId', reportReasonId)
            // verify required parameter 'reportReasonProperties' is not null or undefined
            assertParamExists('updateReportReason', 'reportReasonProperties', reportReasonProperties)
            const localVarPath = `/v3/report-reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportReasonId !== undefined) {
                localVarQueryParameter['reportReasonId'] = reportReasonId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportReasonProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update reservation
         * @summary Updatereservation
         * @param {string} reservationId reservation_id to update
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservation: async (reservationId: string, inlineObject9?: InlineObject9, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('updateReservation', 'reservationId', reservationId)
            const localVarPath = `/v2/reservation/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject9, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId Reservation ID
         * @param {ReservationUpdate} reservationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservation_2: async (reservationId: string, reservationUpdate: ReservationUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('updateReservation_2', 'reservationId', reservationId)
            // verify required parameter 'reservationUpdate' is not null or undefined
            assertParamExists('updateReservation_2', 'reservationUpdate', reservationUpdate)
            const localVarPath = `/v3/reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reservationId !== undefined) {
                localVarQueryParameter['reservationId'] = reservationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {ResourceProperties} resourceProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResource: async (resourceId: string, resourceProperties: ResourceProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('updateResource', 'resourceId', resourceId)
            // verify required parameter 'resourceProperties' is not null or undefined
            assertParamExists('updateResource', 'resourceProperties', resourceProperties)
            const localVarPath = `/v4/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {ResourceReservationProperties} resourceReservationProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceReservation: async (resourceReservationId: string, resourceReservationProperties: ResourceReservationProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceReservationId' is not null or undefined
            assertParamExists('updateResourceReservation', 'resourceReservationId', resourceReservationId)
            // verify required parameter 'resourceReservationProperties' is not null or undefined
            assertParamExists('updateResourceReservation', 'resourceReservationProperties', resourceReservationProperties)
            const localVarPath = `/v4/resourceReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceReservationId !== undefined) {
                localVarQueryParameter['resourceReservationId'] = resourceReservationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceReservationProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} seatId Seat ID
         * @param {SeatUpdate} seatUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeat: async (seatId: string, seatUpdate: SeatUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seatId' is not null or undefined
            assertParamExists('updateSeat', 'seatId', seatId)
            // verify required parameter 'seatUpdate' is not null or undefined
            assertParamExists('updateSeat', 'seatUpdate', seatUpdate)
            const localVarPath = `/v3/seat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (seatId !== undefined) {
                localVarQueryParameter['seatId'] = seatId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seatUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {SeatTagProperties} seatTagProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeatTag: async (tagId: string, seatTagProperties: SeatTagProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('updateSeatTag', 'tagId', tagId)
            // verify required parameter 'seatTagProperties' is not null or undefined
            assertParamExists('updateSeatTag', 'seatTagProperties', seatTagProperties)
            const localVarPath = `/v3/seattag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tagId !== undefined) {
                localVarQueryParameter['tagId'] = tagId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seatTagProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {TimeslotProperties} timeslotProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeslot: async (timeslotId: string, timeslotProperties: TimeslotProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('updateTimeslot', 'timeslotId', timeslotId)
            // verify required parameter 'timeslotProperties' is not null or undefined
            assertParamExists('updateTimeslot', 'timeslotProperties', timeslotProperties)
            const localVarPath = `/v3/timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeslotId !== undefined) {
                localVarQueryParameter['timeslotId'] = timeslotId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeslotProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, userUpdate: UserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('updateUser', 'userUpdate', userUpdate)
            const localVarPath = `/v3/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {UserGroupProperties} userGroupProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGroup: async (groupId: string, userGroupProperties: UserGroupProperties, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateUserGroup', 'groupId', groupId)
            // verify required parameter 'userGroupProperties' is not null or undefined
            assertParamExists('updateUserGroup', 'userGroupProperties', userGroupProperties)
            const localVarPath = `/v3/usergroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AddProfileImage: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('v2AddProfileImage', 'file', file)
            const localVarPath = `/me/profileImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UpdateMe: async (inlineObject6: InlineObject6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject6' is not null or undefined
            assertParamExists('v2UpdateMe', 'inlineObject6', inlineObject6)
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copy of saml-redirect
         * @param {string} [clientId] Not needed, but exists in frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3AuthSurfconextGet: async (clientId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/auth/surfconext`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Client compatibility
         * @param {string} clientId The oAuth client identifier
         * @param {string} version The current version of the client application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3CompatibilityGet: async (clientId: string, version: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('v3CompatibilityGet', 'clientId', clientId)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('v3CompatibilityGet', 'version', version)
            const localVarPath = `/v3/compatibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} from date
         * @param {string} to date
         * @param {string} companyId Company ID
         * @param {string} [locationId] ReservationLocationId
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3Statistics: async (from: string, to: string, companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('v3Statistics', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('v3Statistics', 'to', to)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('v3Statistics', 'companyId', companyId)
            const localVarPath = `/v3/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (seatType !== undefined) {
                localVarQueryParameter['seatType'] = seatType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Verify the SAML response and redirect to frontend url with OTP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acsPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminUserCreate} adminUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAdminUser(adminUserCreate: AdminUserCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAdminUser(adminUserCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyWithoutId} companyWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompany(companyWithoutId: CompanyWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompany(companyWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyTransportOptionWithoutId} companyTransportOptionWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanyTransportOption(companyTransportOptionWithoutId: CompanyTransportOptionWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20071>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanyTransportOption(companyTransportOptionWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DoorWithoutId} doorWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDoor(doorWithoutId: DoorWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20032>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDoor(doorWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a favorite user
         * @param {FavoriteUserWithoutId} favoriteUserWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavoriteUser(favoriteUserWithoutId: FavoriteUserWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavoriteUser(favoriteUserWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LocationWithoutId} locationWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLocation(locationWithoutId: LocationWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLocation(locationWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MapWithoutId} mapWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMap(mapWithoutId: MapWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMap(mapWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMapFile(mapId: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMapFile(mapId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MapObjectWithoutId} mapObjectWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMapObject(mapObjectWithoutId: MapObjectWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMapObject(mapObjectWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOriginalMapFile(mapId: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOriginalMapFile(mapId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProfileImage(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20070>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProfileImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportReasonWithoutId} reportReasonWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReportReason(reportReasonWithoutId: ReportReasonWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReportReason(reportReasonWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReservationAdd} reservationAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReservation(reservationAdd: ReservationAdd, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20059>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReservation(reservationAdd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResourceWithoutId} resourceWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addResource(resourceWithoutId: ResourceWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addResource(resourceWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addResourceReservation(uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addResourceReservation(uNKNOWNBASETYPE, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SeatWithoutId} seatWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSeat(seatWithoutId: SeatWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20047>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSeat(seatWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SeatTagWithoutId} seatTagWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSeatTag(seatTagWithoutId: SeatTagWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20050>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSeatTag(seatTagWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TimeslotWithoutId} timeslotWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTimeslot(timeslotWithoutId: TimeslotWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20056>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTimeslot(timeslotWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(userCreate: UserCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20026>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserGroupWithoutId} userGroupWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserGroup(userGroupWithoutId: UserGroupWithoutId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20053>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserGroup(userGroupWithoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to test if a reservation with the provided properties is allowed to be created
         * @param {ReservationCanCreate} reservationCanCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canCreateReservation(reservationCanCreate: ReservationCanCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20060>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canCreateReservation(reservationCanCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to test if a reservation with the provided properties is allowed to be updated
         * @param {string} reservationId Reservation ID
         * @param {ReservationUpdate} reservationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canUpdateReservation(reservationId: string, reservationUpdate: ReservationUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20060>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canUpdateReservation(reservationId, reservationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject20} inlineObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReservation(inlineObject20: InlineObject20, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReservation(inlineObject20, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {InlineObject17} inlineObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLocationOrder(locationId: string, inlineObject17: InlineObject17, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLocationOrder(locationId, inlineObject17, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {InlineObject13} inlineObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeMapOrder(mapId: string, inlineObject13: InlineObject13, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeMapOrder(mapId, inlineObject13, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminUser(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompany(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyTransportOption(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyTransportOption(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDoor(doorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDoor(doorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id ExportFile ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExportFile(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExportFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a favorite user
         * @param {string} favoriteUserId ID of the other user to remove from favorites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoriteUser(favoriteUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteUser(favoriteUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(locationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {string} exceptionId Exception ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocationHours(locationId: string, exceptionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocationHours(locationId, exceptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMap(mapId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMap(mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMapObject(mapObjectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMapObject(mapObjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete all future reservations with the same reoccurReferenceId
         * @param {string} reoccurReferenceId Reoccur reference ID
         * @param {string} [fromReservationId] Delete this reservation and all future reservations from this date with the same reoccurReferenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReoccurringReservation(reoccurReferenceId: string, fromReservationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReoccurringReservation(reoccurReferenceId, fromReservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportReason(reportReasonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportReason(reportReasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReservation(reservationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReservation(reservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Reservation
         * @summary Delete Reservation
         * @param {string} reservationId Reservation to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReservationForApp(reservationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReservationForApp(reservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResource(resourceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResource(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResourceReservation(resourceReservationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResourceReservation(resourceReservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} seatId Seat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSeat(seatId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSeat(seatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSeatTag(tagId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSeatTag(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimeslot(timeslotId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimeslot(timeslotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserGroup(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAdminUserEmails(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAdminUserEmails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCompanyUserCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCompanyUserCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [userId] User ID
         * @param {ReservationStatus} [status] ReservationStatus
         * @param {string} [locationId] ReservationLocationId
         * @param {string} [mapId] ReservationMapId
         * @param {SeatType} [seatType] ReservationSeatType
         * @param {ReservationType} [type] ReservationType
         * @param {boolean} [includeAnonymous] includeAnonymous in reservations
         * @param {string} [companyTransportId] Filter reservations by company transport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportReservations(companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, companyTransportId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportReservations(companyId, sortField, sortDirection, filter, fromOperator, take, skip, from, to, userId, status, locationId, mapId, seatType, type, includeAnonymous, companyTransportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * throw api error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUser(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalytics(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20074>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalytics(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsForLocation(locationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20074>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsForLocation(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookMyDeskStatistics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20067>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookMyDeskStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany4App(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany4App(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyExtended(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyExtended(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyMSGroup(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyMSGroup(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyMSGroupAvailable(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyMSGroupAvailable(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get my reservations Company
         * @summary Get reservations for Company
         * @param {string} companyId Company ID to get reservations for
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [locationId] LocationId to get reservations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyReservations(companyId: string, from?: string, to?: string, locationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyReservations(companyId, from, to, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTransportOption(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20071>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTransportOption(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTransportOptions(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20072>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTransportOptions(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataExport(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataExport(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoor(doorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoor(doorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'created'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportFiles(companyId: string, sortField?: 'created', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20073>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportFiles(companyId, sortField, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get me iCal
         * @param {string} icalUuid UUID of the ical
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIcal(icalUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20068>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIcal(icalUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocation(locationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocation(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get stats for location
         * @summary Get stats for location
         * @param {string} locationId Location ID to get stats for
         * @param {string} date Date to get stats for
         * @param {string} from Start time to get stats for
         * @param {string} to End of time range
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Deprecated, use seatStatistics instead
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationStats(locationId: string, date: string, from: string, to: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationStats(locationId, date, from, to, seatType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMap(mapId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMap(mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapImage(mapId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapImage(mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapImageUrl(mapId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapImageUrl(mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapObject(mapObjectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapObject(mapObjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get requestion user
         * @summary Get  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2MeUser | MeAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get me user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe_1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20069>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe_1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get my reservations user
         * @summary Get reservations for user
         * @param {string} [companyId] Company ID to get reservations for
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyReservations(companyId?: string, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyReservations(companyId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOriginalMapImageUrl(mapId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOriginalMapImageUrl(mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportReason(reportReasonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportReason(reportReasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {string} [dateFrom] Start date for getting amount available of resources
         * @param {string} [dateTo] End date for getting amount available of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResource(resourceId: string, dateFrom?: string, dateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResource(resourceId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceReservation(resourceReservationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceReservation(resourceReservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [seatId] Seat ID
         * @param {string} [barcode] Barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeat(seatId?: string, barcode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeat(seatId, barcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeatTag(tagId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20049>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeatTag(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeatsAvailableMSRooms(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeatsAvailableMSRooms(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeslot(timeslotId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20055>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeslot(timeslotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20025>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroup(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20052>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check API health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Import users by uploading a CSV (; as delimiter) containing e-mail addresses in the first column, or with columns firstname;infix;lastName;email;userGroups (userGroups are the user group labels separated by a ,)
         * @param {string} companyId Company ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importUsers(companyId: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importUsers(companyId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'email'} [sortField] field to sort on
         * @param {string} [companyId] Company ID, required for company admin, optional for super admin
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdminUsers(sortField?: 'email', companyId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdminUsers(sortField, companyId, sortDirection, filter, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCompanies(sortField?: 'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created', sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCompanies(sortField, sortDirection, filter, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDoors(companyId: string, take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20030>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDoors(companyId, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLocations(companyId: string, take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20027>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLocations(companyId, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMapObjects(companyId: string, mapId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMapObjects(companyId, mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMaps(companyId: string, take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMaps(companyId, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'reason'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportReasons(companyId: string, sortField?: 'reason', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportReasons(companyId, sortField, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'details'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(companyId: string, sortField?: 'details', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(companyId, sortField, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {boolean} [allForCompany] Get all for company
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [userId] User ID
         * @param {ReservationStatus} [status] ReservationStatus
         * @param {string} [locationId] ReservationLocationId
         * @param {string} [mapId] ReservationMapId
         * @param {SeatType} [seatType] ReservationSeatType
         * @param {ReservationType} [type] ReservationType
         * @param {boolean} [includeAnonymous] includeAnonymous in reservations
         * @param {string} [userGroupId] Filter reservations by user group
         * @param {string} [companyTransportId] Filter reservations by company transport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReservations(companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', allForCompany?: boolean, take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, userGroupId?: string, companyTransportId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20062>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReservations(companyId, sortField, sortDirection, filter, fromOperator, allForCompany, take, skip, from, to, userId, status, locationId, mapId, seatType, type, includeAnonymous, userGroupId, companyTransportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {boolean} [isRepeatReservationParam] Filter reservations by repeat reservation
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [dateFrom] Start date for getting amount available of resource
         * @param {string} [dateTo] End date for getting amount available of resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listResourceReservations(companyId: string, filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', from?: string, to?: string, isRepeatReservationParam?: boolean, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listResourceReservations(companyId, filter, fromOperator, from, to, isRepeatReservationParam, take, skip, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [dateFrom] Start date for getting amount available of resources
         * @param {string} [dateTo] End date for getting amount available of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listResources(companyId: string, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listResources(companyId, take, skip, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSaltolocks(companyId: string, take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSaltolocks(companyId, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'label'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSeatTags(companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20048>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSeatTags(companyId, sortField, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSeats(companyId: string, mapId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSeats(companyId, mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSeatsUsage(companyId: string, mapId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSeatsUsage(companyId, mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'name' | 'from' | 'to'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTimeslots(companyId: string, sortField?: 'name' | 'from' | 'to', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20054>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTimeslots(companyId, sortField, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'label'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserGroups(companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20051>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserGroups(companyId, sortField, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status'} [sortField] field to sort on
         * @param {boolean} [inviteSent] filter on whether an invite has been sent
         * @param {UserStatus} [status] filter on status
         * @param {Language} [language] filter on language
         * @param {string} [userGroupId] filter on userGroup
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(companyId: string, sortField?: 'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status', inviteSent?: boolean, status?: UserStatus, language?: Language, userGroupId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(companyId, sortField, inviteSent, status, language, userGroupId, sortDirection, filter, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersExport(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersExport(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAsAdminUser(userId: number, clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAsAdminUser(userId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logout user
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [adminConsent] admin_consent from MS
         * @param {string} [state] State send in step one
         * @param {string} [tenant] Tennant of the admin user (company ID)
         * @param {string} [scope] Scope of the gotten access
         * @param {string} [error] MS error Code
         * @param {string} [errorDescription] error description by microsoft
         * @param {string} [errorUri] error description by microsoft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mSAdminCallback(adminConsent?: string, state?: string, tenant?: string, scope?: string, error?: string, errorDescription?: string, errorUri?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mSAdminCallback(adminConsent, state, tenant, scope, error, errorDescription, errorUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [code] Code from MS
         * @param {string} [state] State send in step one
         * @param {string} [sessionState] State of session - what is difference?
         * @param {string} [error] MS error Code
         * @param {string} [errorDescription] error description by microsoft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mSCallback(code?: string, state?: string, sessionState?: string, error?: string, errorDescription?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mSCallback(code, state, sessionState, error, errorDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SAML2 metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metadataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metadataGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Basic API metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * oAuth routes, eg password grant or refresh
         * @summary Oauth password grant
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [refreshToken] 
         * @param {string} [grantType] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oAuthPasswordGrant(clientId?: string, clientSecret?: string, refreshToken?: string, grantType?: string, username?: string, password?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthPasswordGrantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oAuthPasswordGrant(clientId, clientSecret, refreshToken, grantType, username, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openDoor(doorId: string, reservationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20032>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openDoor(doorId, reservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post new reservation
         * @summary Post new reservation
         * @param {string} companyId Company ID to create reservations for - not used by api but for backwards compatibility
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyReservation(companyId: string, inlineObject8?: InlineObject8, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyReservation(companyId, inlineObject8, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post new Reocurring reservation
         * @summary Post new Reocurring reservation
         * @param {string} companyId Company ID to create reservations for - not needed as queried via seat
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyReservationReocurring(companyId: string, inlineObject10?: InlineObject10, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyReservationReocurring(companyId, inlineObject10, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post device token
         * @summary post Device
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDevice(inlineObject3: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDevice(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post new report
         * @summary post report
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReport(inlineObject4: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReport(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId ClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirToMS(clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirToMS(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [type] Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirToMSAdmin(type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20066>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirToMSAdmin(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register a new company
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCompany(inlineObject5: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCompany(inlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {CompanyTokenType} tokenType type of token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCompanyTokens(companyId: string, tokenType: CompanyTokenType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCompanyTokens(companyId, tokenType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request login by post\'ing email for user
         * @summary Request Login
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestLogin(inlineObject: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestLogin(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationId Reservation ID to checkin for
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationCheckIn(reservationId: string, inlineObject18?: InlineObject18, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20057>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationCheckIn(reservationId, inlineObject18, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationId Reservation ID to checkout for
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationCheckout(reservationId: string, inlineObject19?: InlineObject19, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationCheckout(reservationId, inlineObject19, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} from date
         * @param {string} to date
         * @param {SeatType} seatType seat type
         * @param {string} companyId Company ID
         * @param {string} [locationId] location
         * @param {string} [mapId] map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationsStatistics(from: string, to: string, seatType: SeatType, companyId: string, locationId?: string, mapId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20063>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationsStatistics(from, to, seatType, companyId, locationId, mapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [locationId] location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationsStatisticsToday(companyId: string, locationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20065>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationsStatisticsToday(companyId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reset user\'s password
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(inlineObject1: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Redirect the browser to the SAML IDP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async samlRedirectGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.samlRedirectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send invitations to multiple users
         * @param {InlineObject15} inlineObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendUsersInvitations(inlineObject15: InlineObject15, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendUsersInvitations(inlineObject15, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [locationId] ReservationLocationId
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsToday(companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20064>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsToday(companyId, locationId, seatType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {AdminUserUpdate} adminUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminUser(userId: number, adminUserUpdate: AdminUserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminUser(userId, adminUserUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {CompanyProperties} companyProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(companyId: string, companyProperties: CompanyProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(companyId, companyProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {InlineObject14} inlineObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyMSGroup(companyId: string, inlineObject14: InlineObject14, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyMSGroup(companyId, inlineObject14, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {CompanyTransportOptionProperties} companyTransportOptionProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyTransportOption(id: string, companyTransportOptionProperties: CompanyTransportOptionProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20071>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyTransportOption(id, companyTransportOptionProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {DoorProperties} doorProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDoor(doorId: string, doorProperties: DoorProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDoor(doorId, doorProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {LocationProperties} locationProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(locationId: string, locationProperties: LocationProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(locationId, locationProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {LocationHours} locationHours 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocationHours(locationId: string, locationHours: LocationHours, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocationHours(locationId, locationHours, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {MapProperties} mapProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMap(mapId: string, mapProperties: MapProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMap(mapId, mapProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {MapObjectProperties} mapObjectProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMapObject(mapObjectId: string, mapObjectProperties: MapObjectProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMapObject(mapObjectId, mapObjectProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject21} inlineObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMe(inlineObject21: InlineObject21, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20070>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMe(inlineObject21, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user\'s password
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(inlineObject2: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update all future reservations with the same reoccurReferenceId
         * @param {string} reoccurReferenceId Reoccur reference ID
         * @param {ReservationUpdateReoccurring} reservationUpdateReoccurring 
         * @param {string} [referenceId] Reference ID
         * @param {string} [fromReservationId] Update this reservation and all future reservations from this date with the same reoccurReferenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReoccurringReservation(reoccurReferenceId: string, reservationUpdateReoccurring: ReservationUpdateReoccurring, referenceId?: string, fromReservationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20061>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReoccurringReservation(reoccurReferenceId, reservationUpdateReoccurring, referenceId, fromReservationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {ReportReasonProperties} reportReasonProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportReason(reportReasonId: string, reportReasonProperties: ReportReasonProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportReason(reportReasonId, reportReasonProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update reservation
         * @summary Updatereservation
         * @param {string} reservationId reservation_id to update
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReservation(reservationId: string, inlineObject9?: InlineObject9, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReservation(reservationId, inlineObject9, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationId Reservation ID
         * @param {ReservationUpdate} reservationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReservation_2(reservationId: string, reservationUpdate: ReservationUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20058>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReservation_2(reservationId, reservationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {ResourceProperties} resourceProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResource(resourceId: string, resourceProperties: ResourceProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResource(resourceId, resourceProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {ResourceReservationProperties} resourceReservationProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResourceReservation(resourceReservationId: string, resourceReservationProperties: ResourceReservationProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResourceReservation(resourceReservationId, resourceReservationProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} seatId Seat ID
         * @param {SeatUpdate} seatUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSeat(seatId: string, seatUpdate: SeatUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20047>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSeat(seatId, seatUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {SeatTagProperties} seatTagProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSeatTag(tagId: string, seatTagProperties: SeatTagProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20050>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSeatTag(tagId, seatTagProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {TimeslotProperties} timeslotProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeslot(timeslotId: string, timeslotProperties: TimeslotProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20056>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeslot(timeslotId, timeslotProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, userUpdate: UserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20026>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {UserGroupProperties} userGroupProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserGroup(groupId: string, userGroupProperties: UserGroupProperties, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20053>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserGroup(groupId, userGroupProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AddProfileImage(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AddProfileImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UpdateMe(inlineObject6: InlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UpdateMe(inlineObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copy of saml-redirect
         * @param {string} [clientId] Not needed, but exists in frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3AuthSurfconextGet(clientId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v3AuthSurfconextGet(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Client compatibility
         * @param {string} clientId The oAuth client identifier
         * @param {string} version The current version of the client application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3CompatibilityGet(clientId: string, version: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v3CompatibilityGet(clientId, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} from date
         * @param {string} to date
         * @param {string} companyId Company ID
         * @param {string} [locationId] ReservationLocationId
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v3Statistics(from: string, to: string, companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20064>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v3Statistics(from, to, companyId, locationId, seatType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Verify the SAML response and redirect to frontend url with OTP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acsPost(options?: any): AxiosPromise<void> {
            return localVarFp.acsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserCreate} adminUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminUser(adminUserCreate: AdminUserCreate, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.addAdminUser(adminUserCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyWithoutId} companyWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompany(companyWithoutId: CompanyWithoutId, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.addCompany(companyWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyTransportOptionWithoutId} companyTransportOptionWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyTransportOption(companyTransportOptionWithoutId: CompanyTransportOptionWithoutId, options?: any): AxiosPromise<InlineResponse20071> {
            return localVarFp.addCompanyTransportOption(companyTransportOptionWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DoorWithoutId} doorWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoor(doorWithoutId: DoorWithoutId, options?: any): AxiosPromise<InlineResponse20032> {
            return localVarFp.addDoor(doorWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a favorite user
         * @param {FavoriteUserWithoutId} favoriteUserWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteUser(favoriteUserWithoutId: FavoriteUserWithoutId, options?: any): AxiosPromise<void> {
            return localVarFp.addFavoriteUser(favoriteUserWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationWithoutId} locationWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocation(locationWithoutId: LocationWithoutId, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.addLocation(locationWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MapWithoutId} mapWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMap(mapWithoutId: MapWithoutId, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.addMap(mapWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMapFile(mapId: string, file: any, options?: any): AxiosPromise<void> {
            return localVarFp.addMapFile(mapId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MapObjectWithoutId} mapObjectWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMapObject(mapObjectWithoutId: MapObjectWithoutId, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.addMapObject(mapObjectWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOriginalMapFile(mapId: string, file: any, options?: any): AxiosPromise<void> {
            return localVarFp.addOriginalMapFile(mapId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProfileImage(file: any, options?: any): AxiosPromise<InlineResponse20070> {
            return localVarFp.addProfileImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportReasonWithoutId} reportReasonWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReportReason(reportReasonWithoutId: ReportReasonWithoutId, options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.addReportReason(reportReasonWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReservationAdd} reservationAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReservation(reservationAdd: ReservationAdd, options?: any): AxiosPromise<InlineResponse20059> {
            return localVarFp.addReservation(reservationAdd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResourceWithoutId} resourceWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResource(resourceWithoutId: ResourceWithoutId, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.addResource(resourceWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addResourceReservation(uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.addResourceReservation(uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SeatWithoutId} seatWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeat(seatWithoutId: SeatWithoutId, options?: any): AxiosPromise<InlineResponse20047> {
            return localVarFp.addSeat(seatWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SeatTagWithoutId} seatTagWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeatTag(seatTagWithoutId: SeatTagWithoutId, options?: any): AxiosPromise<InlineResponse20050> {
            return localVarFp.addSeatTag(seatTagWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TimeslotWithoutId} timeslotWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTimeslot(timeslotWithoutId: TimeslotWithoutId, options?: any): AxiosPromise<InlineResponse20056> {
            return localVarFp.addTimeslot(timeslotWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(userCreate: UserCreate, options?: any): AxiosPromise<InlineResponse20026> {
            return localVarFp.addUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserGroupWithoutId} userGroupWithoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserGroup(userGroupWithoutId: UserGroupWithoutId, options?: any): AxiosPromise<InlineResponse20053> {
            return localVarFp.addUserGroup(userGroupWithoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to test if a reservation with the provided properties is allowed to be created
         * @param {ReservationCanCreate} reservationCanCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCreateReservation(reservationCanCreate: ReservationCanCreate, options?: any): AxiosPromise<InlineResponse20060> {
            return localVarFp.canCreateReservation(reservationCanCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to test if a reservation with the provided properties is allowed to be updated
         * @param {string} reservationId Reservation ID
         * @param {ReservationUpdate} reservationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canUpdateReservation(reservationId: string, reservationUpdate: ReservationUpdate, options?: any): AxiosPromise<InlineResponse20060> {
            return localVarFp.canUpdateReservation(reservationId, reservationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject20} inlineObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReservation(inlineObject20: InlineObject20, options?: any): AxiosPromise<void> {
            return localVarFp.cancelReservation(inlineObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {InlineObject17} inlineObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLocationOrder(locationId: string, inlineObject17: InlineObject17, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.changeLocationOrder(locationId, inlineObject17, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {InlineObject13} inlineObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMapOrder(mapId: string, inlineObject13: InlineObject13, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.changeMapOrder(mapId, inlineObject13, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUser(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdminUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(companyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyTransportOption(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompanyTransportOption(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoor(doorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDoor(doorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id ExportFile ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExportFile(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteExportFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a favorite user
         * @param {string} favoriteUserId ID of the other user to remove from favorites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteUser(favoriteUserId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFavoriteUser(favoriteUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(locationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLocation(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {string} exceptionId Exception ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationHours(locationId: string, exceptionId: string, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.deleteLocationHours(locationId, exceptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMap(mapId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMap(mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMapObject(mapObjectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMapObject(mapObjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete all future reservations with the same reoccurReferenceId
         * @param {string} reoccurReferenceId Reoccur reference ID
         * @param {string} [fromReservationId] Delete this reservation and all future reservations from this date with the same reoccurReferenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReoccurringReservation(reoccurReferenceId: string, fromReservationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReoccurringReservation(reoccurReferenceId, fromReservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportReason(reportReasonId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportReason(reportReasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation(reservationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReservation(reservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Reservation
         * @summary Delete Reservation
         * @param {string} reservationId Reservation to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservationForApp(reservationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteReservationForApp(reservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResource(resourceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteResource(resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceReservation(resourceReservationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteResourceReservation(resourceReservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} seatId Seat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeat(seatId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSeat(seatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeatTag(tagId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSeatTag(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeslot(timeslotId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTimeslot(timeslotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup(groupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAdminUserEmails(options?: any): AxiosPromise<any> {
            return localVarFp.exportAdminUserEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCompanyUserCount(options?: any): AxiosPromise<any> {
            return localVarFp.exportCompanyUserCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [userId] User ID
         * @param {ReservationStatus} [status] ReservationStatus
         * @param {string} [locationId] ReservationLocationId
         * @param {string} [mapId] ReservationMapId
         * @param {SeatType} [seatType] ReservationSeatType
         * @param {ReservationType} [type] ReservationType
         * @param {boolean} [includeAnonymous] includeAnonymous in reservations
         * @param {string} [companyTransportId] Filter reservations by company transport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReservations(companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, companyTransportId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportReservations(companyId, sortField, sortDirection, filter, fromOperator, take, skip, from, to, userId, status, locationId, mapId, seatType, type, includeAnonymous, companyTransportId, options).then((request) => request(axios, basePath));
        },
        /**
         * throw api error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failGet(options?: any): AxiosPromise<void> {
            return localVarFp.failGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUser(userId: number, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.getAdminUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalytics(companyId: string, options?: any): AxiosPromise<InlineResponse20074> {
            return localVarFp.getAnalytics(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsForLocation(locationId: string, options?: any): AxiosPromise<InlineResponse20074> {
            return localVarFp.getAnalyticsForLocation(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookMyDeskStatistics(options?: any): AxiosPromise<InlineResponse20067> {
            return localVarFp.getBookMyDeskStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(companyId: string, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.getCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany4App(companyId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getCompany4App(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyExtended(companyId: string, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.getCompanyExtended(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMSGroup(companyId: string, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.getCompanyMSGroup(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMSGroupAvailable(companyId: string, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.getCompanyMSGroupAvailable(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get my reservations Company
         * @summary Get reservations for Company
         * @param {string} companyId Company ID to get reservations for
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [locationId] LocationId to get reservations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReservations(companyId: string, from?: string, to?: string, locationId?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getCompanyReservations(companyId, from, to, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTransportOption(id: string, options?: any): AxiosPromise<InlineResponse20071> {
            return localVarFp.getCompanyTransportOption(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTransportOptions(companyId: string, options?: any): AxiosPromise<InlineResponse20072> {
            return localVarFp.getCompanyTransportOptions(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataExport(companyId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getDataExport(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoor(doorId: string, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.getDoor(doorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'created'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportFiles(companyId: string, sortField?: 'created', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20073> {
            return localVarFp.getExportFiles(companyId, sortField, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * Get me iCal
         * @param {string} icalUuid UUID of the ical
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcal(icalUuid: string, options?: any): AxiosPromise<InlineResponse20068> {
            return localVarFp.getIcal(icalUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(locationId: string, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.getLocation(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get stats for location
         * @summary Get stats for location
         * @param {string} locationId Location ID to get stats for
         * @param {string} date Date to get stats for
         * @param {string} from Start time to get stats for
         * @param {string} to End of time range
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Deprecated, use seatStatistics instead
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationStats(locationId: string, date: string, from: string, to: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any): AxiosPromise<object> {
            return localVarFp.getLocationStats(locationId, date, from, to, seatType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMap(mapId: string, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.getMap(mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapImage(mapId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getMapImage(mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapImageUrl(mapId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getMapImageUrl(mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapObject(mapObjectId: string, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.getMapObject(mapObjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get requestion user
         * @summary Get  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<V2MeUser | MeAdmin> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * Get me user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe_1(options?: any): AxiosPromise<InlineResponse20069> {
            return localVarFp.getMe_1(options).then((request) => request(axios, basePath));
        },
        /**
         * Get my reservations user
         * @summary Get reservations for user
         * @param {string} [companyId] Company ID to get reservations for
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReservations(companyId?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getMyReservations(companyId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOriginalMapImageUrl(mapId: string, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.getOriginalMapImageUrl(mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportReason(reportReasonId: string, options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.getReportReason(reportReasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {string} [dateFrom] Start date for getting amount available of resources
         * @param {string} [dateTo] End date for getting amount available of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResource(resourceId: string, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.getResource(resourceId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceReservation(resourceReservationId: string, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.getResourceReservation(resourceReservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [seatId] Seat ID
         * @param {string} [barcode] Barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeat(seatId?: string, barcode?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.getSeat(seatId, barcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatTag(tagId: string, options?: any): AxiosPromise<InlineResponse20049> {
            return localVarFp.getSeatTag(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatsAvailableMSRooms(companyId: string, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.getSeatsAvailableMSRooms(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeslot(timeslotId: string, options?: any): AxiosPromise<InlineResponse20055> {
            return localVarFp.getTimeslot(timeslotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<InlineResponse20025> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroup(groupId: string, options?: any): AxiosPromise<InlineResponse20052> {
            return localVarFp.getUserGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Check API health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: any): AxiosPromise<void> {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Import users by uploading a CSV (; as delimiter) containing e-mail addresses in the first column, or with columns firstname;infix;lastName;email;userGroups (userGroups are the user group labels separated by a ,)
         * @param {string} companyId Company ID
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUsers(companyId: string, file: any, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.importUsers(companyId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'email'} [sortField] field to sort on
         * @param {string} [companyId] Company ID, required for company admin, optional for super admin
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminUsers(sortField?: 'email', companyId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.listAdminUsers(sortField, companyId, sortDirection, filter, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCompanies(sortField?: 'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created', sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.listCompanies(sortField, sortDirection, filter, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDoors(companyId: string, take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20030> {
            return localVarFp.listDoors(companyId, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations(companyId: string, take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20027> {
            return localVarFp.listLocations(companyId, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMapObjects(companyId: string, mapId?: string, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.listMapObjects(companyId, mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMaps(companyId: string, take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.listMaps(companyId, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'reason'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportReasons(companyId: string, sortField?: 'reason', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.listReportReasons(companyId, sortField, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'details'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(companyId: string, sortField?: 'details', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20018> {
            return localVarFp.listReports(companyId, sortField, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {boolean} [allForCompany] Get all for company
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {string} [userId] User ID
         * @param {ReservationStatus} [status] ReservationStatus
         * @param {string} [locationId] ReservationLocationId
         * @param {string} [mapId] ReservationMapId
         * @param {SeatType} [seatType] ReservationSeatType
         * @param {ReservationType} [type] ReservationType
         * @param {boolean} [includeAnonymous] includeAnonymous in reservations
         * @param {string} [userGroupId] Filter reservations by user group
         * @param {string} [companyTransportId] Filter reservations by company transport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReservations(companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', allForCompany?: boolean, take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, userGroupId?: string, companyTransportId?: string, options?: any): AxiosPromise<InlineResponse20062> {
            return localVarFp.listReservations(companyId, sortField, sortDirection, filter, fromOperator, allForCompany, take, skip, from, to, userId, status, locationId, mapId, seatType, type, includeAnonymous, userGroupId, companyTransportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [filter] value to filter with
         * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
         * @param {string} [from] Start of daterange
         * @param {string} [to] End of daterange
         * @param {boolean} [isRepeatReservationParam] Filter reservations by repeat reservation
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [dateFrom] Start date for getting amount available of resource
         * @param {string} [dateTo] End date for getting amount available of resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResourceReservations(companyId: string, filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', from?: string, to?: string, isRepeatReservationParam?: boolean, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.listResourceReservations(companyId, filter, fromOperator, from, to, isRepeatReservationParam, take, skip, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {string} [dateFrom] Start date for getting amount available of resources
         * @param {string} [dateTo] End date for getting amount available of resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listResources(companyId: string, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.listResources(companyId, take, skip, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSaltolocks(companyId: string, take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.listSaltolocks(companyId, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'label'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSeatTags(companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20048> {
            return localVarFp.listSeatTags(companyId, sortField, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSeats(companyId: string, mapId?: string, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.listSeats(companyId, mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [mapId] Map ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSeatsUsage(companyId: string, mapId?: string, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.listSeatsUsage(companyId, mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'name' | 'from' | 'to'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTimeslots(companyId: string, sortField?: 'name' | 'from' | 'to', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20054> {
            return localVarFp.listTimeslots(companyId, sortField, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'label'} [sortField] field to sort on
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserGroups(companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20051> {
            return localVarFp.listUserGroups(companyId, sortField, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status'} [sortField] field to sort on
         * @param {boolean} [inviteSent] filter on whether an invite has been sent
         * @param {UserStatus} [status] filter on status
         * @param {Language} [language] filter on language
         * @param {string} [userGroupId] filter on userGroup
         * @param {'asc' | 'desc'} [sortDirection] direction to sort to
         * @param {string} [filter] value to filter with
         * @param {number} [take] Amount of items to take
         * @param {number} [skip] Amount of items to skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(companyId: string, sortField?: 'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status', inviteSent?: boolean, status?: UserStatus, language?: Language, userGroupId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any): AxiosPromise<InlineResponse20024> {
            return localVarFp.listUsers(companyId, sortField, inviteSent, status, language, userGroupId, sortDirection, filter, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersExport(companyId: string, options?: any): AxiosPromise<any> {
            return localVarFp.listUsersExport(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {string} clientId Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsAdminUser(userId: number, clientId: string, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.loginAsAdminUser(userId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout user
         * @summary logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<string> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [adminConsent] admin_consent from MS
         * @param {string} [state] State send in step one
         * @param {string} [tenant] Tennant of the admin user (company ID)
         * @param {string} [scope] Scope of the gotten access
         * @param {string} [error] MS error Code
         * @param {string} [errorDescription] error description by microsoft
         * @param {string} [errorUri] error description by microsoft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mSAdminCallback(adminConsent?: string, state?: string, tenant?: string, scope?: string, error?: string, errorDescription?: string, errorUri?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mSAdminCallback(adminConsent, state, tenant, scope, error, errorDescription, errorUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [code] Code from MS
         * @param {string} [state] State send in step one
         * @param {string} [sessionState] State of session - what is difference?
         * @param {string} [error] MS error Code
         * @param {string} [errorDescription] error description by microsoft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mSCallback(code?: string, state?: string, sessionState?: string, error?: string, errorDescription?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mSCallback(code, state, sessionState, error, errorDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * SAML2 metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metadataGet(options?: any): AxiosPromise<string> {
            return localVarFp.metadataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Basic API metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsGet(options?: any): AxiosPromise<void> {
            return localVarFp.metricsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * oAuth routes, eg password grant or refresh
         * @summary Oauth password grant
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [refreshToken] 
         * @param {string} [grantType] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuthPasswordGrant(clientId?: string, clientSecret?: string, refreshToken?: string, grantType?: string, username?: string, password?: string, options?: any): AxiosPromise<OAuthPasswordGrantResponse> {
            return localVarFp.oAuthPasswordGrant(clientId, clientSecret, refreshToken, grantType, username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openDoor(doorId: string, reservationId: string, options?: any): AxiosPromise<InlineResponse20032> {
            return localVarFp.openDoor(doorId, reservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Post new reservation
         * @summary Post new reservation
         * @param {string} companyId Company ID to create reservations for - not used by api but for backwards compatibility
         * @param {InlineObject8} [inlineObject8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyReservation(companyId: string, inlineObject8?: InlineObject8, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.postCompanyReservation(companyId, inlineObject8, options).then((request) => request(axios, basePath));
        },
        /**
         * Post new Reocurring reservation
         * @summary Post new Reocurring reservation
         * @param {string} companyId Company ID to create reservations for - not needed as queried via seat
         * @param {InlineObject10} [inlineObject10] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyReservationReocurring(companyId: string, inlineObject10?: InlineObject10, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.postCompanyReservationReocurring(companyId, inlineObject10, options).then((request) => request(axios, basePath));
        },
        /**
         * Post device token
         * @summary post Device
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDevice(inlineObject3: InlineObject3, options?: any): AxiosPromise<string> {
            return localVarFp.postDevice(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * Post new report
         * @summary post report
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReport(inlineObject4: InlineObject4, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.postReport(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId ClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirToMS(clientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.redirToMS(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [type] Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirToMSAdmin(type?: string, options?: any): AxiosPromise<InlineResponse20066> {
            return localVarFp.redirToMSAdmin(type, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new company
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCompany(inlineObject5: InlineObject5, options?: any): AxiosPromise<void> {
            return localVarFp.registerCompany(inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {CompanyTokenType} tokenType type of token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanyTokens(companyId: string, tokenType: CompanyTokenType, options?: any): AxiosPromise<void> {
            return localVarFp.removeCompanyTokens(companyId, tokenType, options).then((request) => request(axios, basePath));
        },
        /**
         * Request login by post\'ing email for user
         * @summary Request Login
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestLogin(inlineObject: InlineObject, options?: any): AxiosPromise<void> {
            return localVarFp.requestLogin(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationId Reservation ID to checkin for
         * @param {InlineObject18} [inlineObject18] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationCheckIn(reservationId: string, inlineObject18?: InlineObject18, options?: any): AxiosPromise<InlineResponse20057> {
            return localVarFp.reservationCheckIn(reservationId, inlineObject18, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationId Reservation ID to checkout for
         * @param {InlineObject19} [inlineObject19] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationCheckout(reservationId: string, inlineObject19?: InlineObject19, options?: any): AxiosPromise<void> {
            return localVarFp.reservationCheckout(reservationId, inlineObject19, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} from date
         * @param {string} to date
         * @param {SeatType} seatType seat type
         * @param {string} companyId Company ID
         * @param {string} [locationId] location
         * @param {string} [mapId] map
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationsStatistics(from: string, to: string, seatType: SeatType, companyId: string, locationId?: string, mapId?: string, options?: any): AxiosPromise<InlineResponse20063> {
            return localVarFp.reservationsStatistics(from, to, seatType, companyId, locationId, mapId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [locationId] location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationsStatisticsToday(companyId: string, locationId?: string, options?: any): AxiosPromise<InlineResponse20065> {
            return localVarFp.reservationsStatisticsToday(companyId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset user\'s password
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(inlineObject1: InlineObject1, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * Redirect the browser to the SAML IDP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        samlRedirectGet(options?: any): AxiosPromise<void> {
            return localVarFp.samlRedirectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Send invitations to multiple users
         * @param {InlineObject15} inlineObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUsersInvitations(inlineObject15: InlineObject15, options?: any): AxiosPromise<void> {
            return localVarFp.sendUsersInvitations(inlineObject15, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {string} [locationId] ReservationLocationId
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsToday(companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any): AxiosPromise<InlineResponse20064> {
            return localVarFp.statisticsToday(companyId, locationId, seatType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId User ID
         * @param {AdminUserUpdate} adminUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUser(userId: number, adminUserUpdate: AdminUserUpdate, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.updateAdminUser(userId, adminUserUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {CompanyProperties} companyProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(companyId: string, companyProperties: CompanyProperties, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.updateCompany(companyId, companyProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId Company ID
         * @param {InlineObject14} inlineObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyMSGroup(companyId: string, inlineObject14: InlineObject14, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.updateCompanyMSGroup(companyId, inlineObject14, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id CompanyTransportOption ID
         * @param {CompanyTransportOptionProperties} companyTransportOptionProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyTransportOption(id: string, companyTransportOptionProperties: CompanyTransportOptionProperties, options?: any): AxiosPromise<InlineResponse20071> {
            return localVarFp.updateCompanyTransportOption(id, companyTransportOptionProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} doorId Door ID
         * @param {DoorProperties} doorProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoor(doorId: string, doorProperties: DoorProperties, options?: any): AxiosPromise<object> {
            return localVarFp.updateDoor(doorId, doorProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {LocationProperties} locationProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(locationId: string, locationProperties: LocationProperties, options?: any): AxiosPromise<object> {
            return localVarFp.updateLocation(locationId, locationProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId Location ID
         * @param {LocationHours} locationHours 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationHours(locationId: string, locationHours: LocationHours, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.updateLocationHours(locationId, locationHours, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapId Map ID
         * @param {MapProperties} mapProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMap(mapId: string, mapProperties: MapProperties, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.updateMap(mapId, mapProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mapObjectId MapObject ID
         * @param {MapObjectProperties} mapObjectProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMapObject(mapObjectId: string, mapObjectProperties: MapObjectProperties, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.updateMapObject(mapObjectId, mapObjectProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject21} inlineObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe(inlineObject21: InlineObject21, options?: any): AxiosPromise<InlineResponse20070> {
            return localVarFp.updateMe(inlineObject21, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user\'s password
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(inlineObject2: InlineObject2, options?: any): AxiosPromise<void> {
            return localVarFp.updatePassword(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * Update all future reservations with the same reoccurReferenceId
         * @param {string} reoccurReferenceId Reoccur reference ID
         * @param {ReservationUpdateReoccurring} reservationUpdateReoccurring 
         * @param {string} [referenceId] Reference ID
         * @param {string} [fromReservationId] Update this reservation and all future reservations from this date with the same reoccurReferenceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReoccurringReservation(reoccurReferenceId: string, reservationUpdateReoccurring: ReservationUpdateReoccurring, referenceId?: string, fromReservationId?: string, options?: any): AxiosPromise<InlineResponse20061> {
            return localVarFp.updateReoccurringReservation(reoccurReferenceId, reservationUpdateReoccurring, referenceId, fromReservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportReasonId Report reason ID
         * @param {ReportReasonProperties} reportReasonProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportReason(reportReasonId: string, reportReasonProperties: ReportReasonProperties, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.updateReportReason(reportReasonId, reportReasonProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * Update reservation
         * @summary Updatereservation
         * @param {string} reservationId reservation_id to update
         * @param {InlineObject9} [inlineObject9] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservation(reservationId: string, inlineObject9?: InlineObject9, options?: any): AxiosPromise<void> {
            return localVarFp.updateReservation(reservationId, inlineObject9, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationId Reservation ID
         * @param {ReservationUpdate} reservationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservation_2(reservationId: string, reservationUpdate: ReservationUpdate, options?: any): AxiosPromise<InlineResponse20058> {
            return localVarFp.updateReservation_2(reservationId, reservationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} resourceId Resource ID
         * @param {ResourceProperties} resourceProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResource(resourceId: string, resourceProperties: ResourceProperties, options?: any): AxiosPromise<object> {
            return localVarFp.updateResource(resourceId, resourceProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} resourceReservationId Resource Reservation ID
         * @param {ResourceReservationProperties} resourceReservationProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceReservation(resourceReservationId: string, resourceReservationProperties: ResourceReservationProperties, options?: any): AxiosPromise<object> {
            return localVarFp.updateResourceReservation(resourceReservationId, resourceReservationProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} seatId Seat ID
         * @param {SeatUpdate} seatUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeat(seatId: string, seatUpdate: SeatUpdate, options?: any): AxiosPromise<InlineResponse20047> {
            return localVarFp.updateSeat(seatId, seatUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tagId Tag ID
         * @param {SeatTagProperties} seatTagProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeatTag(tagId: string, seatTagProperties: SeatTagProperties, options?: any): AxiosPromise<InlineResponse20050> {
            return localVarFp.updateSeatTag(tagId, seatTagProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timeslotId Timeslot ID
         * @param {TimeslotProperties} timeslotProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeslot(timeslotId: string, timeslotProperties: TimeslotProperties, options?: any): AxiosPromise<InlineResponse20056> {
            return localVarFp.updateTimeslot(timeslotId, timeslotProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId User ID
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, userUpdate: UserUpdate, options?: any): AxiosPromise<InlineResponse20026> {
            return localVarFp.updateUser(userId, userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId Group ID
         * @param {UserGroupProperties} userGroupProperties 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGroup(groupId: string, userGroupProperties: UserGroupProperties, options?: any): AxiosPromise<InlineResponse20053> {
            return localVarFp.updateUserGroup(groupId, userGroupProperties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AddProfileImage(file: any, options?: any): AxiosPromise<void> {
            return localVarFp.v2AddProfileImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UpdateMe(inlineObject6: InlineObject6, options?: any): AxiosPromise<string> {
            return localVarFp.v2UpdateMe(inlineObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * Copy of saml-redirect
         * @param {string} [clientId] Not needed, but exists in frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3AuthSurfconextGet(clientId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.v3AuthSurfconextGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Client compatibility
         * @param {string} clientId The oAuth client identifier
         * @param {string} version The current version of the client application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3CompatibilityGet(clientId: string, version: string, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v3CompatibilityGet(clientId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} from date
         * @param {string} to date
         * @param {string} companyId Company ID
         * @param {string} [locationId] ReservationLocationId
         * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v3Statistics(from: string, to: string, companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any): AxiosPromise<InlineResponse20064> {
            return localVarFp.v3Statistics(from, to, companyId, locationId, seatType, options).then((request) => request(axios, basePath));
        },
        /**
         * API version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionGet(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.versionGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Verify the SAML response and redirect to frontend url with OTP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public acsPost(options?: any) {
        return DefaultApiFp(this.configuration).acsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserCreate} adminUserCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addAdminUser(adminUserCreate: AdminUserCreate, options?: any) {
        return DefaultApiFp(this.configuration).addAdminUser(adminUserCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyWithoutId} companyWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addCompany(companyWithoutId: CompanyWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addCompany(companyWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyTransportOptionWithoutId} companyTransportOptionWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addCompanyTransportOption(companyTransportOptionWithoutId: CompanyTransportOptionWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addCompanyTransportOption(companyTransportOptionWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DoorWithoutId} doorWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addDoor(doorWithoutId: DoorWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addDoor(doorWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a favorite user
     * @param {FavoriteUserWithoutId} favoriteUserWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addFavoriteUser(favoriteUserWithoutId: FavoriteUserWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addFavoriteUser(favoriteUserWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationWithoutId} locationWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addLocation(locationWithoutId: LocationWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addLocation(locationWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MapWithoutId} mapWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addMap(mapWithoutId: MapWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addMap(mapWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addMapFile(mapId: string, file: any, options?: any) {
        return DefaultApiFp(this.configuration).addMapFile(mapId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MapObjectWithoutId} mapObjectWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addMapObject(mapObjectWithoutId: MapObjectWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addMapObject(mapObjectWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addOriginalMapFile(mapId: string, file: any, options?: any) {
        return DefaultApiFp(this.configuration).addOriginalMapFile(mapId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addProfileImage(file: any, options?: any) {
        return DefaultApiFp(this.configuration).addProfileImage(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportReasonWithoutId} reportReasonWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addReportReason(reportReasonWithoutId: ReportReasonWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addReportReason(reportReasonWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReservationAdd} reservationAdd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addReservation(reservationAdd: ReservationAdd, options?: any) {
        return DefaultApiFp(this.configuration).addReservation(reservationAdd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResourceWithoutId} resourceWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addResource(resourceWithoutId: ResourceWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addResource(resourceWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addResourceReservation(uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any) {
        return DefaultApiFp(this.configuration).addResourceReservation(uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SeatWithoutId} seatWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addSeat(seatWithoutId: SeatWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addSeat(seatWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SeatTagWithoutId} seatTagWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addSeatTag(seatTagWithoutId: SeatTagWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addSeatTag(seatTagWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimeslotWithoutId} timeslotWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addTimeslot(timeslotWithoutId: TimeslotWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addTimeslot(timeslotWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addUser(userCreate: UserCreate, options?: any) {
        return DefaultApiFp(this.configuration).addUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGroupWithoutId} userGroupWithoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addUserGroup(userGroupWithoutId: UserGroupWithoutId, options?: any) {
        return DefaultApiFp(this.configuration).addUserGroup(userGroupWithoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to test if a reservation with the provided properties is allowed to be created
     * @param {ReservationCanCreate} reservationCanCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public canCreateReservation(reservationCanCreate: ReservationCanCreate, options?: any) {
        return DefaultApiFp(this.configuration).canCreateReservation(reservationCanCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to test if a reservation with the provided properties is allowed to be updated
     * @param {string} reservationId Reservation ID
     * @param {ReservationUpdate} reservationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public canUpdateReservation(reservationId: string, reservationUpdate: ReservationUpdate, options?: any) {
        return DefaultApiFp(this.configuration).canUpdateReservation(reservationId, reservationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject20} inlineObject20 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cancelReservation(inlineObject20: InlineObject20, options?: any) {
        return DefaultApiFp(this.configuration).cancelReservation(inlineObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId Location ID
     * @param {InlineObject17} inlineObject17 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeLocationOrder(locationId: string, inlineObject17: InlineObject17, options?: any) {
        return DefaultApiFp(this.configuration).changeLocationOrder(locationId, inlineObject17, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID
     * @param {InlineObject13} inlineObject13 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeMapOrder(mapId: string, inlineObject13: InlineObject13, options?: any) {
        return DefaultApiFp(this.configuration).changeMapOrder(mapId, inlineObject13, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAdminUser(userId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteAdminUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCompany(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id CompanyTransportOption ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCompanyTransportOption(id: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteCompanyTransportOption(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} doorId Door ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDoor(doorId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteDoor(doorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id ExportFile ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteExportFile(id: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteExportFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a favorite user
     * @param {string} favoriteUserId ID of the other user to remove from favorites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteFavoriteUser(favoriteUserId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteFavoriteUser(favoriteUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId Location ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLocation(locationId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteLocation(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId Location ID
     * @param {string} exceptionId Exception ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLocationHours(locationId: string, exceptionId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteLocationHours(locationId, exceptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMap(mapId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteMap(mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapObjectId MapObject ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMapObject(mapObjectId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteMapObject(mapObjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete all future reservations with the same reoccurReferenceId
     * @param {string} reoccurReferenceId Reoccur reference ID
     * @param {string} [fromReservationId] Delete this reservation and all future reservations from this date with the same reoccurReferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReoccurringReservation(reoccurReferenceId: string, fromReservationId?: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteReoccurringReservation(reoccurReferenceId, fromReservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportId Report ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReport(reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportReasonId Report reason ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReportReason(reportReasonId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteReportReason(reportReasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationId Reservation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReservation(reservationId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteReservation(reservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Reservation
     * @summary Delete Reservation
     * @param {string} reservationId Reservation to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReservationForApp(reservationId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteReservationForApp(reservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} resourceId Resource ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteResource(resourceId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteResource(resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} resourceReservationId Resource Reservation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteResourceReservation(resourceReservationId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteResourceReservation(resourceReservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} seatId Seat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSeat(seatId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteSeat(seatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tagId Tag ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSeatTag(tagId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteSeatTag(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timeslotId Timeslot ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTimeslot(timeslotId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteTimeslot(timeslotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUser(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId Group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUserGroup(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteUserGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportAdminUserEmails(options?: any) {
        return DefaultApiFp(this.configuration).exportAdminUserEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportCompanyUserCount(options?: any) {
        return DefaultApiFp(this.configuration).exportCompanyUserCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {string} [filter] value to filter with
     * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {string} [from] Start of daterange
     * @param {string} [to] End of daterange
     * @param {string} [userId] User ID
     * @param {ReservationStatus} [status] ReservationStatus
     * @param {string} [locationId] ReservationLocationId
     * @param {string} [mapId] ReservationMapId
     * @param {SeatType} [seatType] ReservationSeatType
     * @param {ReservationType} [type] ReservationType
     * @param {boolean} [includeAnonymous] includeAnonymous in reservations
     * @param {string} [companyTransportId] Filter reservations by company transport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportReservations(companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, companyTransportId?: string, options?: any) {
        return DefaultApiFp(this.configuration).exportReservations(companyId, sortField, sortDirection, filter, fromOperator, take, skip, from, to, userId, status, locationId, mapId, seatType, type, includeAnonymous, companyTransportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * throw api error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public failGet(options?: any) {
        return DefaultApiFp(this.configuration).failGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminUser(userId: number, options?: any) {
        return DefaultApiFp(this.configuration).getAdminUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnalytics(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getAnalytics(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId Location ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnalyticsForLocation(locationId: string, options?: any) {
        return DefaultApiFp(this.configuration).getAnalyticsForLocation(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBookMyDeskStatistics(options?: any) {
        return DefaultApiFp(this.configuration).getBookMyDeskStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompany(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompany4App(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompany4App(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompanyExtended(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompanyExtended(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompanyMSGroup(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompanyMSGroup(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompanyMSGroupAvailable(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompanyMSGroupAvailable(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get my reservations Company
     * @summary Get reservations for Company
     * @param {string} companyId Company ID to get reservations for
     * @param {string} [from] Start of daterange
     * @param {string} [to] End of daterange
     * @param {string} [locationId] LocationId to get reservations for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompanyReservations(companyId: string, from?: string, to?: string, locationId?: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompanyReservations(companyId, from, to, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id CompanyTransportOption ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompanyTransportOption(id: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompanyTransportOption(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCompanyTransportOptions(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCompanyTransportOptions(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDataExport(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getDataExport(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} doorId Door ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDoor(doorId: string, options?: any) {
        return DefaultApiFp(this.configuration).getDoor(doorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'created'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getExportFiles(companyId: string, sortField?: 'created', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).getExportFiles(companyId, sortField, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get me iCal
     * @param {string} icalUuid UUID of the ical
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getIcal(icalUuid: string, options?: any) {
        return DefaultApiFp(this.configuration).getIcal(icalUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId Location ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLocation(locationId: string, options?: any) {
        return DefaultApiFp(this.configuration).getLocation(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get stats for location
     * @summary Get stats for location
     * @param {string} locationId Location ID to get stats for
     * @param {string} date Date to get stats for
     * @param {string} from Start time to get stats for
     * @param {string} to End of time range
     * @param {'desk' | 'lunch' | 'meeting'} [seatType] Deprecated, use seatStatistics instead
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLocationStats(locationId: string, date: string, from: string, to: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any) {
        return DefaultApiFp(this.configuration).getLocationStats(locationId, date, from, to, seatType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMap(mapId: string, options?: any) {
        return DefaultApiFp(this.configuration).getMap(mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMapImage(mapId: string, options?: any) {
        return DefaultApiFp(this.configuration).getMapImage(mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMapImageUrl(mapId: string, options?: any) {
        return DefaultApiFp(this.configuration).getMapImageUrl(mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapObjectId MapObject ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMapObject(mapObjectId: string, options?: any) {
        return DefaultApiFp(this.configuration).getMapObject(mapObjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get requestion user
     * @summary Get  user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMe(options?: any) {
        return DefaultApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get me user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMe_1(options?: any) {
        return DefaultApiFp(this.configuration).getMe_1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get my reservations user
     * @summary Get reservations for user
     * @param {string} [companyId] Company ID to get reservations for
     * @param {string} [from] Start of daterange
     * @param {string} [to] End of daterange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyReservations(companyId?: string, from?: string, to?: string, options?: any) {
        return DefaultApiFp(this.configuration).getMyReservations(companyId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOriginalMapImageUrl(mapId: string, options?: any) {
        return DefaultApiFp(this.configuration).getOriginalMapImageUrl(mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportId Report ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getReport(reportId: string, options?: any) {
        return DefaultApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportReasonId Report reason ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getReportReason(reportReasonId: string, options?: any) {
        return DefaultApiFp(this.configuration).getReportReason(reportReasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} resourceId Resource ID
     * @param {string} [dateFrom] Start date for getting amount available of resources
     * @param {string} [dateTo] End date for getting amount available of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getResource(resourceId: string, dateFrom?: string, dateTo?: string, options?: any) {
        return DefaultApiFp(this.configuration).getResource(resourceId, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} resourceReservationId Resource Reservation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getResourceReservation(resourceReservationId: string, options?: any) {
        return DefaultApiFp(this.configuration).getResourceReservation(resourceReservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [seatId] Seat ID
     * @param {string} [barcode] Barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSeat(seatId?: string, barcode?: string, options?: any) {
        return DefaultApiFp(this.configuration).getSeat(seatId, barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tagId Tag ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSeatTag(tagId: string, options?: any) {
        return DefaultApiFp(this.configuration).getSeatTag(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSeatsAvailableMSRooms(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getSeatsAvailableMSRooms(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timeslotId Timeslot ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTimeslot(timeslotId: string, options?: any) {
        return DefaultApiFp(this.configuration).getTimeslot(timeslotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId Group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserGroup(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).getUserGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check API health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthGet(options?: any) {
        return DefaultApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Import users by uploading a CSV (; as delimiter) containing e-mail addresses in the first column, or with columns firstname;infix;lastName;email;userGroups (userGroups are the user group labels separated by a ,)
     * @param {string} companyId Company ID
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public importUsers(companyId: string, file: any, options?: any) {
        return DefaultApiFp(this.configuration).importUsers(companyId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'email'} [sortField] field to sort on
     * @param {string} [companyId] Company ID, required for company admin, optional for super admin
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {string} [filter] value to filter with
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listAdminUsers(sortField?: 'email', companyId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listAdminUsers(sortField, companyId, sortDirection, filter, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {string} [filter] value to filter with
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCompanies(sortField?: 'name' | 'billingPricePerCustomer' | 'billingStartDate' | 'language' | 'created', sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listCompanies(sortField, sortDirection, filter, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listDoors(companyId: string, take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listDoors(companyId, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listLocations(companyId: string, take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listLocations(companyId, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {string} [mapId] Map ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listMapObjects(companyId: string, mapId?: string, options?: any) {
        return DefaultApiFp(this.configuration).listMapObjects(companyId, mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listMaps(companyId: string, take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listMaps(companyId, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'reason'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listReportReasons(companyId: string, sortField?: 'reason', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listReportReasons(companyId, sortField, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'details'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listReports(companyId: string, sortField?: 'details', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listReports(companyId, sortField, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {string} [filter] value to filter with
     * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
     * @param {boolean} [allForCompany] Get all for company
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {string} [from] Start of daterange
     * @param {string} [to] End of daterange
     * @param {string} [userId] User ID
     * @param {ReservationStatus} [status] ReservationStatus
     * @param {string} [locationId] ReservationLocationId
     * @param {string} [mapId] ReservationMapId
     * @param {SeatType} [seatType] ReservationSeatType
     * @param {ReservationType} [type] ReservationType
     * @param {boolean} [includeAnonymous] includeAnonymous in reservations
     * @param {string} [userGroupId] Filter reservations by user group
     * @param {string} [companyTransportId] Filter reservations by company transport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listReservations(companyId: string, sortField?: 'date' | 'from' | 'to' | 'cancelled' | 'includeParking' | 'visitorName' | 'visitorEmail' | 'visitorPhone' | 'user.firstName' | 'user.lastName' | 'user.email' | 'location.name' | 'map.name' | 'seat.name' | 'type', sortDirection?: 'asc' | 'desc', filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', allForCompany?: boolean, take?: number, skip?: number, from?: string, to?: string, userId?: string, status?: ReservationStatus, locationId?: string, mapId?: string, seatType?: SeatType, type?: ReservationType, includeAnonymous?: boolean, userGroupId?: string, companyTransportId?: string, options?: any) {
        return DefaultApiFp(this.configuration).listReservations(companyId, sortField, sortDirection, filter, fromOperator, allForCompany, take, skip, from, to, userId, status, locationId, mapId, seatType, type, includeAnonymous, userGroupId, companyTransportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {string} [filter] value to filter with
     * @param {'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte'} [fromOperator] Operator to apply to the \&#39;from\&#39; field
     * @param {string} [from] Start of daterange
     * @param {string} [to] End of daterange
     * @param {boolean} [isRepeatReservationParam] Filter reservations by repeat reservation
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {string} [dateFrom] Start date for getting amount available of resource
     * @param {string} [dateTo] End date for getting amount available of resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listResourceReservations(companyId: string, filter?: string, fromOperator?: 'equals' | 'not' | 'gt' | 'gte' | 'lt' | 'lte', from?: string, to?: string, isRepeatReservationParam?: boolean, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options?: any) {
        return DefaultApiFp(this.configuration).listResourceReservations(companyId, filter, fromOperator, from, to, isRepeatReservationParam, take, skip, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {string} [dateFrom] Start date for getting amount available of resources
     * @param {string} [dateTo] End date for getting amount available of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listResources(companyId: string, take?: number, skip?: number, dateFrom?: string, dateTo?: string, options?: any) {
        return DefaultApiFp(this.configuration).listResources(companyId, take, skip, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSaltolocks(companyId: string, take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listSaltolocks(companyId, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'label'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSeatTags(companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listSeatTags(companyId, sortField, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {string} [mapId] Map ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSeats(companyId: string, mapId?: string, options?: any) {
        return DefaultApiFp(this.configuration).listSeats(companyId, mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {string} [mapId] Map ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSeatsUsage(companyId: string, mapId?: string, options?: any) {
        return DefaultApiFp(this.configuration).listSeatsUsage(companyId, mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'name' | 'from' | 'to'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTimeslots(companyId: string, sortField?: 'name' | 'from' | 'to', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listTimeslots(companyId, sortField, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'label'} [sortField] field to sort on
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUserGroups(companyId: string, sortField?: 'label', sortDirection?: 'asc' | 'desc', take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listUserGroups(companyId, sortField, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status'} [sortField] field to sort on
     * @param {boolean} [inviteSent] filter on whether an invite has been sent
     * @param {UserStatus} [status] filter on status
     * @param {Language} [language] filter on language
     * @param {string} [userGroupId] filter on userGroup
     * @param {'asc' | 'desc'} [sortDirection] direction to sort to
     * @param {string} [filter] value to filter with
     * @param {number} [take] Amount of items to take
     * @param {number} [skip] Amount of items to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUsers(companyId: string, sortField?: 'firstName' | 'infix' | 'lastName' | 'email' | 'language' | 'status', inviteSent?: boolean, status?: UserStatus, language?: Language, userGroupId?: string, sortDirection?: 'asc' | 'desc', filter?: string, take?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).listUsers(companyId, sortField, inviteSent, status, language, userGroupId, sortDirection, filter, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUsersExport(companyId: string, options?: any) {
        return DefaultApiFp(this.configuration).listUsersExport(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId User ID
     * @param {string} clientId Client ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginAsAdminUser(userId: number, clientId: string, options?: any) {
        return DefaultApiFp(this.configuration).loginAsAdminUser(userId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout user
     * @summary logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logout(options?: any) {
        return DefaultApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [adminConsent] admin_consent from MS
     * @param {string} [state] State send in step one
     * @param {string} [tenant] Tennant of the admin user (company ID)
     * @param {string} [scope] Scope of the gotten access
     * @param {string} [error] MS error Code
     * @param {string} [errorDescription] error description by microsoft
     * @param {string} [errorUri] error description by microsoft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mSAdminCallback(adminConsent?: string, state?: string, tenant?: string, scope?: string, error?: string, errorDescription?: string, errorUri?: string, options?: any) {
        return DefaultApiFp(this.configuration).mSAdminCallback(adminConsent, state, tenant, scope, error, errorDescription, errorUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [code] Code from MS
     * @param {string} [state] State send in step one
     * @param {string} [sessionState] State of session - what is difference?
     * @param {string} [error] MS error Code
     * @param {string} [errorDescription] error description by microsoft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mSCallback(code?: string, state?: string, sessionState?: string, error?: string, errorDescription?: string, options?: any) {
        return DefaultApiFp(this.configuration).mSCallback(code, state, sessionState, error, errorDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SAML2 metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metadataGet(options?: any) {
        return DefaultApiFp(this.configuration).metadataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Basic API metrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metricsGet(options?: any) {
        return DefaultApiFp(this.configuration).metricsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * oAuth routes, eg password grant or refresh
     * @summary Oauth password grant
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {string} [refreshToken] 
     * @param {string} [grantType] 
     * @param {string} [username] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public oAuthPasswordGrant(clientId?: string, clientSecret?: string, refreshToken?: string, grantType?: string, username?: string, password?: string, options?: any) {
        return DefaultApiFp(this.configuration).oAuthPasswordGrant(clientId, clientSecret, refreshToken, grantType, username, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} doorId Door ID
     * @param {string} reservationId Reservation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public openDoor(doorId: string, reservationId: string, options?: any) {
        return DefaultApiFp(this.configuration).openDoor(doorId, reservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post new reservation
     * @summary Post new reservation
     * @param {string} companyId Company ID to create reservations for - not used by api but for backwards compatibility
     * @param {InlineObject8} [inlineObject8] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCompanyReservation(companyId: string, inlineObject8?: InlineObject8, options?: any) {
        return DefaultApiFp(this.configuration).postCompanyReservation(companyId, inlineObject8, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post new Reocurring reservation
     * @summary Post new Reocurring reservation
     * @param {string} companyId Company ID to create reservations for - not needed as queried via seat
     * @param {InlineObject10} [inlineObject10] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCompanyReservationReocurring(companyId: string, inlineObject10?: InlineObject10, options?: any) {
        return DefaultApiFp(this.configuration).postCompanyReservationReocurring(companyId, inlineObject10, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post device token
     * @summary post Device
     * @param {InlineObject3} inlineObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postDevice(inlineObject3: InlineObject3, options?: any) {
        return DefaultApiFp(this.configuration).postDevice(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post new report
     * @summary post report
     * @param {InlineObject4} inlineObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postReport(inlineObject4: InlineObject4, options?: any) {
        return DefaultApiFp(this.configuration).postReport(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId ClientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public redirToMS(clientId: string, options?: any) {
        return DefaultApiFp(this.configuration).redirToMS(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [type] Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public redirToMSAdmin(type?: string, options?: any) {
        return DefaultApiFp(this.configuration).redirToMSAdmin(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new company
     * @param {InlineObject5} inlineObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registerCompany(inlineObject5: InlineObject5, options?: any) {
        return DefaultApiFp(this.configuration).registerCompany(inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {CompanyTokenType} tokenType type of token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeCompanyTokens(companyId: string, tokenType: CompanyTokenType, options?: any) {
        return DefaultApiFp(this.configuration).removeCompanyTokens(companyId, tokenType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request login by post\'ing email for user
     * @summary Request Login
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public requestLogin(inlineObject: InlineObject, options?: any) {
        return DefaultApiFp(this.configuration).requestLogin(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationId Reservation ID to checkin for
     * @param {InlineObject18} [inlineObject18] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reservationCheckIn(reservationId: string, inlineObject18?: InlineObject18, options?: any) {
        return DefaultApiFp(this.configuration).reservationCheckIn(reservationId, inlineObject18, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationId Reservation ID to checkout for
     * @param {InlineObject19} [inlineObject19] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reservationCheckout(reservationId: string, inlineObject19?: InlineObject19, options?: any) {
        return DefaultApiFp(this.configuration).reservationCheckout(reservationId, inlineObject19, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} from date
     * @param {string} to date
     * @param {SeatType} seatType seat type
     * @param {string} companyId Company ID
     * @param {string} [locationId] location
     * @param {string} [mapId] map
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reservationsStatistics(from: string, to: string, seatType: SeatType, companyId: string, locationId?: string, mapId?: string, options?: any) {
        return DefaultApiFp(this.configuration).reservationsStatistics(from, to, seatType, companyId, locationId, mapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {string} [locationId] location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reservationsStatisticsToday(companyId: string, locationId?: string, options?: any) {
        return DefaultApiFp(this.configuration).reservationsStatisticsToday(companyId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset user\'s password
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetPassword(inlineObject1: InlineObject1, options?: any) {
        return DefaultApiFp(this.configuration).resetPassword(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Redirect the browser to the SAML IDP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public samlRedirectGet(options?: any) {
        return DefaultApiFp(this.configuration).samlRedirectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send invitations to multiple users
     * @param {InlineObject15} inlineObject15 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendUsersInvitations(inlineObject15: InlineObject15, options?: any) {
        return DefaultApiFp(this.configuration).sendUsersInvitations(inlineObject15, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {string} [locationId] ReservationLocationId
     * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statisticsToday(companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any) {
        return DefaultApiFp(this.configuration).statisticsToday(companyId, locationId, seatType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId User ID
     * @param {AdminUserUpdate} adminUserUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAdminUser(userId: number, adminUserUpdate: AdminUserUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateAdminUser(userId, adminUserUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {CompanyProperties} companyProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCompany(companyId: string, companyProperties: CompanyProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateCompany(companyId, companyProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId Company ID
     * @param {InlineObject14} inlineObject14 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCompanyMSGroup(companyId: string, inlineObject14: InlineObject14, options?: any) {
        return DefaultApiFp(this.configuration).updateCompanyMSGroup(companyId, inlineObject14, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id CompanyTransportOption ID
     * @param {CompanyTransportOptionProperties} companyTransportOptionProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCompanyTransportOption(id: string, companyTransportOptionProperties: CompanyTransportOptionProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateCompanyTransportOption(id, companyTransportOptionProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} doorId Door ID
     * @param {DoorProperties} doorProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDoor(doorId: string, doorProperties: DoorProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateDoor(doorId, doorProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId Location ID
     * @param {LocationProperties} locationProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateLocation(locationId: string, locationProperties: LocationProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateLocation(locationId, locationProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId Location ID
     * @param {LocationHours} locationHours 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateLocationHours(locationId: string, locationHours: LocationHours, options?: any) {
        return DefaultApiFp(this.configuration).updateLocationHours(locationId, locationHours, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapId Map ID
     * @param {MapProperties} mapProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateMap(mapId: string, mapProperties: MapProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateMap(mapId, mapProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mapObjectId MapObject ID
     * @param {MapObjectProperties} mapObjectProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateMapObject(mapObjectId: string, mapObjectProperties: MapObjectProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateMapObject(mapObjectId, mapObjectProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject21} inlineObject21 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateMe(inlineObject21: InlineObject21, options?: any) {
        return DefaultApiFp(this.configuration).updateMe(inlineObject21, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user\'s password
     * @param {InlineObject2} inlineObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePassword(inlineObject2: InlineObject2, options?: any) {
        return DefaultApiFp(this.configuration).updatePassword(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update all future reservations with the same reoccurReferenceId
     * @param {string} reoccurReferenceId Reoccur reference ID
     * @param {ReservationUpdateReoccurring} reservationUpdateReoccurring 
     * @param {string} [referenceId] Reference ID
     * @param {string} [fromReservationId] Update this reservation and all future reservations from this date with the same reoccurReferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReoccurringReservation(reoccurReferenceId: string, reservationUpdateReoccurring: ReservationUpdateReoccurring, referenceId?: string, fromReservationId?: string, options?: any) {
        return DefaultApiFp(this.configuration).updateReoccurringReservation(reoccurReferenceId, reservationUpdateReoccurring, referenceId, fromReservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportReasonId Report reason ID
     * @param {ReportReasonProperties} reportReasonProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReportReason(reportReasonId: string, reportReasonProperties: ReportReasonProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateReportReason(reportReasonId, reportReasonProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update reservation
     * @summary Updatereservation
     * @param {string} reservationId reservation_id to update
     * @param {InlineObject9} [inlineObject9] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReservation(reservationId: string, inlineObject9?: InlineObject9, options?: any) {
        return DefaultApiFp(this.configuration).updateReservation(reservationId, inlineObject9, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationId Reservation ID
     * @param {ReservationUpdate} reservationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReservation_2(reservationId: string, reservationUpdate: ReservationUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateReservation_2(reservationId, reservationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} resourceId Resource ID
     * @param {ResourceProperties} resourceProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateResource(resourceId: string, resourceProperties: ResourceProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateResource(resourceId, resourceProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} resourceReservationId Resource Reservation ID
     * @param {ResourceReservationProperties} resourceReservationProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateResourceReservation(resourceReservationId: string, resourceReservationProperties: ResourceReservationProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateResourceReservation(resourceReservationId, resourceReservationProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} seatId Seat ID
     * @param {SeatUpdate} seatUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSeat(seatId: string, seatUpdate: SeatUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateSeat(seatId, seatUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tagId Tag ID
     * @param {SeatTagProperties} seatTagProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSeatTag(tagId: string, seatTagProperties: SeatTagProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateSeatTag(tagId, seatTagProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timeslotId Timeslot ID
     * @param {TimeslotProperties} timeslotProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTimeslot(timeslotId: string, timeslotProperties: TimeslotProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateTimeslot(timeslotId, timeslotProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId User ID
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUser(userId: string, userUpdate: UserUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateUser(userId, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId Group ID
     * @param {UserGroupProperties} userGroupProperties 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserGroup(groupId: string, userGroupProperties: UserGroupProperties, options?: any) {
        return DefaultApiFp(this.configuration).updateUserGroup(groupId, userGroupProperties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v2AddProfileImage(file: any, options?: any) {
        return DefaultApiFp(this.configuration).v2AddProfileImage(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject6} inlineObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v2UpdateMe(inlineObject6: InlineObject6, options?: any) {
        return DefaultApiFp(this.configuration).v2UpdateMe(inlineObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copy of saml-redirect
     * @param {string} [clientId] Not needed, but exists in frontend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v3AuthSurfconextGet(clientId?: string, options?: any) {
        return DefaultApiFp(this.configuration).v3AuthSurfconextGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Client compatibility
     * @param {string} clientId The oAuth client identifier
     * @param {string} version The current version of the client application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v3CompatibilityGet(clientId: string, version: string, options?: any) {
        return DefaultApiFp(this.configuration).v3CompatibilityGet(clientId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} from date
     * @param {string} to date
     * @param {string} companyId Company ID
     * @param {string} [locationId] ReservationLocationId
     * @param {'desk' | 'lunch' | 'meeting'} [seatType] Seat type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v3Statistics(from: string, to: string, companyId: string, locationId?: string, seatType?: 'desk' | 'lunch' | 'meeting', options?: any) {
        return DefaultApiFp(this.configuration).v3Statistics(from, to, companyId, locationId, seatType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public versionGet(options?: any) {
        return DefaultApiFp(this.configuration).versionGet(options).then((request) => request(this.axios, this.basePath));
    }
}


