module.exports = [
  "Ada",
  "Adelajda",
  "Agata",
  "Agnieszka",
  "Agrypina",
  "Aida",
  "Aleksandra",
  "Alicja",
  "Alina",
  "Amanda",
  "Anastazja",
  "Angela",
  "Andżelika",
  "Angelina",
  "Anna",
  "Hanna",
  "Antonina",
  "Ariadna",
  "Aurora",
  "Barbara",
  "Beatrycze",
  "Berta",
  "Brygida",
  "Kamila",
  "Karolina",
  "Karolina",
  "Kornelia",
  "Katarzyna",
  "Cecylia",
  "Karolina",
  "Chloe",
  "Krystyna",
  "Klara",
  "Klaudia",
  "Klementyna",
  "Konstancja",
  "Koralia",
  "Daria",
  "Diana",
  "Dina",
  "Dorota",
  "Edyta",
  "Eleonora",
  "Eliza",
  "Elżbieta",
  "Izabela",
  "Elwira",
  "Emilia",
  "Estera",
  "Eudoksja",
  "Eudokia",
  "Eugenia",
  "Ewa",
  "Ewelina",
  "Ferdynanda",
  "Florencja",
  "Franciszka",
  "Gabriela",
  "Gertruda",
  "Gloria",
  "Gracja",
  "Jadwiga",
  "Helena",
  "Henryka",
  "Nadzieja",
  "Ida",
  "Ilona",
  "Helena",
  "Irena",
  "Irma",
  "Izabela",
  "Izolda",
  "Jakubina",
  "Joanna",
  "Janina",
  "Żaneta",
  "Joanna",
  "Ginewra",
  "Józefina",
  "Judyta",
  "Julia",
  "Julia",
  "Julita",
  "Justyna",
  "Kira",
  "Cyra",
  "Kleopatra",
  "Larysa",
  "Laura",
  "Laurencja",
  "Laurentyna",
  "Lea",
  "Leila",
  "Eleonora",
  "Liliana",
  "Lilianna",
  "Lilia",
  "Lilla",
  "Liza",
  "Eliza",
  "Laura",
  "Ludwika",
  "Luiza",
  "Łucja",
  "Lucja",
  "Lidia",
  "Amabela",
  "Magdalena",
  "Malwina",
  "Małgorzata",
  "Greta",
  "Marianna",
  "Maryna",
  "Marta",
  "Martyna",
  "Maria",
  "Matylda",
  "Maja",
  "Maja",
  "Melania",
  "Michalina",
  "Monika",
  "Nadzieja",
  "Noemi",
  "Natalia",
  "Nikola",
  "Nina",
  "Olga",
  "Olimpia",
  "Oliwia",
  "Ofelia",
  "Patrycja",
  "Paula",
  "Pelagia",
  "Penelopa",
  "Filipa",
  "Paulina",
  "Rachela",
  "Rebeka",
  "Regina",
  "Renata",
  "Rozalia",
  "Róża",
  "Roksana",
  "Rufina",
  "Ruta",
  "Sabina",
  "Sara",
  "Serafina",
  "Sybilla",
  "Sylwia",
  "Zofia",
  "Stella",
  "Stefania",
  "Zuzanna",
  "Tamara",
  "Tacjana",
  "Tekla",
  "Teodora",
  "Teresa",
  "Walentyna",
  "Waleria",
  "Wanesa",
  "Wiara",
  "Weronika",
  "Wiktoria",
  "Wirginia",
  "Bibiana",
  "Bibianna",
  "Wanda",
  "Wilhelmina",
  "Ksawera",
  "Ksenia",
  "Zoe"
]
