import styled, { css } from "styled-components/native";

import Text, { FontWeightProps } from "./text";
import { text, font } from "../theme";

export const SmallText = styled(Text)<FontWeightProps>`
  font-size: ${text.small.size};
  ${({ mediumWeight }) =>
    mediumWeight &&
    css`
    font-family: ${font.defaultMedium}};
  `}
`;
// TODO Change this to ExtraSmallText, good refactor is too big of a scope right now since the large line-heights are used throughout the whole app
export const SmallTextGood = styled(Text)<FontWeightProps>`
  font-size: ${text.small.size};
  line-height: ${text.small.lineHeight};
  ${({ mediumWeight }) =>
    mediumWeight &&
    css`
    font-family: ${font.defaultMedium}};
  `}
`;
