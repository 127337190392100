module["exports"] = [
  "###/######",
  "###/## ## ##", 
  "### ## ## ##",
  "###/### ###",
  "##########",
  "04##/### ###",
  "04## ## ## ##",
  "00324 ## ## ##",
  "+324 ## ## ## ##"
];
