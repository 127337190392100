module["exports"] = [
  "ИП",
  "ООО",
  "ЗАО",
  "ОАО",
  "ПАО",
  "НКО",
  "ТСЖ",
  "ОП"
];
