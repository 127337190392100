import React, { FC } from "react";
import { useHistory } from "../../routing";
import AdminNavBarContent from "./AdminNavBarContent";
import styled from "styled-components/native";
import { MarginBottomProps } from "../../styles/helpers/marginBottom";
import { boxShadow, color, spacing, nav } from "../../styles/theme";
import logo from "../../assets/logo.svg";
import Container from "../Container";

const NavbarWrapper = styled.View<MarginBottomProps>`
  display: flex;
  flex-direction: row;
  background-color: ${color.white};
  ${boxShadow.small};
`;

const LogoContainer = styled.TouchableOpacity`
  position: relative;
  margin-right: ${spacing.medium};
`;

const Logo = styled.Image`
  height: ${nav.logoHeight};
  width: ${nav.logoWidth};
`;

const InnerNavbarContainer = styled.View`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const NavbarSwitch: FC = () => {
  const history = useHistory();

  return (
    <NavbarWrapper>
      <Container isNoScrollView={true}>
        <InnerNavbarContainer>
          <LogoContainer onPress={() => history.push("/")}>
            <Logo source={logo} />
          </LogoContainer>
          <AdminNavBarContent />
        </InnerNavbarContainer>
      </Container>
    </NavbarWrapper>
  );
};

export default NavbarSwitch;
