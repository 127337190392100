module['exports'] = [
  'Bakar',
  'Beli Manastir',
  'Belišće',
  'Benkovac',
  'Biograd na Moru',
  'Bjelovar',
  'Buje',
  'Buzet',
  'Čabar',
  'Čakovec',
  'Čazma',
  'Cres',
  'Crikvenica',
  'Daruvar',
  'Delnice',
  'Donja Stubica',
  'Donji Miholjac',
  'Drniš',
  'Dubrovnik',
  'Duga Resa',
  'Dugo Selo',
  'Đakovo',
  'Đurđevac',
  'Garešnica',
  'Glina',
  'Gospić',
  'Grubišno Polje',
  'Hrvatska Kostajnica',
  'Hvar',
  'Ilok',
  'Imotski',
  'Ivanec',
  'Ivanić-Grad',
  'Jastrebarsko',
  'Karlovac',
  'Kastav',
  'Kaštela',
  'Klanjec',
  'Knin',
  'Komiža',
  'Koprivnica',
  'Korčula',
  'Kraljevica',
  'Krapina',
  'Križevci',
  'Krk',
  'Kutina',
  'Kutjevo',
  'Labin',
  'Lepoglava',
  'Lipik',
  'Ludbreg',
  'Makarska',
  'Mali Lošinj',
  'Metković',
  'Mursko Središce',
  'Našice',
  'Nin',
  'Nova Gradiška',
  'Novalja',
  'Novi Marof',
  'Novi Vinodolski',
  'Novigrad-Cittanova',
  'Novska',
  'Obrovac',
  'Ogulin',
  'Omiš',
  'Opatija',
  'Opuzen',
  'Orahovica',
  'Oroslavje',
  'Osijek',
  'Otočac',
  'Otok',
  'Ozalj',
  'Pag',
  'Pakrac',
  'Pazin',
  'Petrinja',
  'Pleternica',
  'Ploče',
  'Popovača',
  'Poreč',
  'Požega',
  'Pregrada',
  'Prelog',
  'Pula',
  'Rab',
  'Rijeka',
  'Rovinj',
  'Samobor',
  'Senj',
  'Šibenik',
  'Sinj',
  'Sisak',
  'Skradin',
  'Slatina',
  'Slavonski Brod',
  'Slunj',
  'Solin',
  'Split',
  'Stari Grad',
  'Supetar',
  'Sveta Nedelja',
  'Sveti Ivan Zelina',
  'Trilj',
  'Trogir',
  'Umag',
  'Valpovo',
  'Varaždin',
  'Varaždinske Toplice',
  'Velika Gorica',
  'Vinkovci',
  'Virovitica',
  'Vis',
  'Vodice',
  'Vodnjan',
  'Vrbovec',
  'Vrbovsko',
  'Vrgorac',
  'Vrlika',
  'Vukovar',
  'Zabok',
  'Zadar',
  'Zagreb',
  'Zaprešic',
  'Zlatar',
  'Županja'
];



