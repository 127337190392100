module["exports"] = [
  "БИОС",
  "Гб",
  "ИИ",
  "ОС",
  "ОЗУ",
  "ПО",
  "СУБД",
  "ХЗ",
  "ЭВМ",
  "HTTP",
  "SDD",
  "CSS",
  "SSL",
  "AGP",
  "FTP",
  "PCI",
  "ADP",
  "RSS",
  "XML",
  "EXE",
  "COM",
  "НМЖД",
  "ПЗУ",
  "SMTP",
  "SMS",
  "USB",
  "PNG",
  "SAS",
  "JSON",
  "XSS"
];
