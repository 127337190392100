import { TextStyle } from "react-native";
import styled ,{ css } from "styled-components/native";
import { font, text, color as colors } from "../theme";
import marginBottom, { MarginBottomProps } from "../helpers/marginBottom";
import { convertObjectToStyle } from "../../utils";

export interface TextProps extends MarginBottomProps, FontWeightProps, TextStyle {
  color?: string;
  fontSize?: number;
}
export interface FontWeightProps {
  mediumWeight?: boolean;
}

export interface FontItalicProps {
  italic?: boolean;
}

export default styled.Text<TextProps>`
  font-family: ${(props) =>
    props.mediumWeight ? font.defaultMedium : font.defaultRegular};
  ${(props) => {
    return css`
      font-size: ${props.fontSize || text.medium.size};
      line-height: ${props.lineHeight || text.medium.lineHeight};
      color: ${props.color || colors.darker};
      opacity: ${props.opacity || 1};
    `
  }}
  ${(props) => marginBottom(props)}
  ${(props) => convertObjectToStyle(
    props,
    ['border', 'outline', 'width', 'marginLeft', 'opacity']
  )};
`;
