import React, { FC, useRef } from "react";
import { useFocus, useHover } from "react-native-web-hooks";
import styled, { css } from "styled-components/native";
import { ButtonOptions } from "./index";
import { ImageSourcePropType, TouchableOpacityProps } from "react-native";
import {
  borderRadius,
  borderWidth,
  color,
  iconSize,
  spacing,
} from "../../styles/theme";

const Button = styled.TouchableOpacity<ButtonOptions>`
  width: ${spacing.large};
  height: ${spacing.large};
  border-radius: ${borderRadius.full};
  border-width: ${borderWidth.small};
  border-color: ${color.greyLighter};
  position: relative;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ backgroundHoverColor, isHovering }) =>
    backgroundHoverColor &&
    isHovering &&
    css`
      background-color: ${backgroundHoverColor};
    `}
`;

interface IconProps {
  isHovering: boolean;
  hasHoverIcon: boolean;
}

const Icon = styled.Image<IconProps>`
  width: ${iconSize.medium};
  height: ${iconSize.medium};
  position: absolute;
  flex: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  ${({ isHovering, hasHoverIcon }) =>
    isHovering &&
    hasHoverIcon &&
    css`
      display: none;
    `}
`;

const IconOnHover = styled(Icon)`
  display: none;

  ${({ isHovering, hasHoverIcon }) =>
    isHovering &&
    hasHoverIcon &&
    css`
      display: flex;
    `}
`;

interface IconButtonSmallProps extends TouchableOpacityProps {
  icon: ImageSourcePropType;
  backgroundColor?: string;
  backgroundHoverColor?: string;
  hoverIcon?: ImageSourcePropType;
}

const IconButtonSmall: FC<IconButtonSmallProps> = ({
  icon,
  backgroundColor,
  backgroundHoverColor,
  hoverIcon,
  ...rest
}) => {
  const element = useRef(null);
  const isHovering = useHover(element);
  const isFocussing = useFocus(element);

  const showingIcon = isHovering && hoverIcon ? hoverIcon : icon;

  return (
    <Button
      ref={element}
      backgroundColor={backgroundColor}
      backgroundHoverColor={backgroundHoverColor}
      isHovering={isHovering || isFocussing}
      {...rest}
    >
      <Icon
        source={showingIcon}
        isHovering={isHovering}
        hasHoverIcon={Boolean(hoverIcon)}
        resizeMode="center"
      />
      {hoverIcon && (
        <IconOnHover
          source={hoverIcon}
          isHovering={isHovering}
          hasHoverIcon={Boolean(hoverIcon)}
          resizeMode="center"
        />
      )}
    </Button>
  );
};

export default IconButtonSmall;
