import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import Container from "../../../components/Container";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useClient } from "../../../hooks/useClient";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps } from "../../../routing";
import { OverviewHeader, OverviewHeaderInnerRight } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import Title from "../../../styles/Title";
import MediumTitle from "../../../styles/Title";
import SmallTitle from "../../../styles/Title";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { MicrosoftGroup } from "../../../../bookmydesk-api-sdk-typescript-axios";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import RadioButton from "../../../components/RadioButton";
import Button, { ButtonsWrapper, ButtonWrapper } from "../../../components/Button";
import PageSelector from "../../../components/PageSelector";
import Pill from '../../../components/Pill';
import { color, spacing } from '../../../styles/theme';

const columnSizes = [0.35, 5, 1.5];

const RadioWrapper = styled.View`
  margin-left: auto;
  margin-right: auto;
`;

const ActiveDirectoryOverview: FC<RouterProps> = () => {
  const { companyPages, userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const tableHeaders: HeaderItem[] = [
    createHeaderItem("", false),
    createHeaderItem(t("admin.activeDirectory.table.name"), false, "microsoftGroupName"),
    createHeaderItem(t("admin.activeDirectory.table.id"), false, "microsoftGroupId"),
  ];
  const { headerItems, onSortList } =
    useSort<Parameters<typeof client.listReservations>[1]>(tableHeaders);

  const [data, setData] = useState<MicrosoftGroup[]>([]);
  const [tableData, setTableData] = useState<TableContent[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<MicrosoftGroup | undefined>();
  const [noGroupSync, setNoGroupSync] = useState(false);

  const onChangeSelectedGroup = useCallback((selectedId: string) => {
    const selectedGroup = data.find(({ microsoftGroupId }) => microsoftGroupId === selectedId);
    const userCompany = userState?.companyIds[0];
    if (userCompany && selectedGroup) {
      handleRequest(client.updateCompanyMSGroup(userCompany, selectedGroup)).then((result) => {
        if (result && result?.data?.result) {
          const { group } = result.data.result;
          if (group) {
            setSelectedGroupId(group.microsoftGroupId);
            setSelectedGroupName(group.microsoftGroupName);
            setSelectedGroup(group);
            setNoGroupSync(true);
          }
        }
      });
    }
  }, [client, data, handleRequest, userState?.companyIds]);

  const fetchListItems = useCallback(async (userCompany) => {
    return handleRequest(client.getCompanyMSGroupAvailable(userCompany));
  }, [client, handleRequest]);

  const fetchSelectedItem = useCallback(async (userCompany) => {
    return handleRequest(client.getCompanyMSGroup(userCompany)).then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      if (result.data.result) {
        const { group } = result.data.result;
        if (group) {
          setSelectedGroupId(group.microsoftGroupId);
          setSelectedGroupName(group.microsoftGroupName);
          setSelectedGroup(group);
          setNoGroupSync(true);
        }
      }
    });
  }, [client, handleRequest]);

  const createTableData = useCallback((items: MicrosoftGroup[]) => {
    const tableData: TableContent[] = items.map(
      ({
         microsoftGroupId,
         microsoftGroupName,
       }) => {
        const data: ReactElement[] = [
          <RadioWrapper key="selector">
            <RadioButton
              marginLeft="auto"
              marginRight="auto"
              checked={microsoftGroupId === selectedGroupId}
              onSelect={() => setSelectedGroupId(microsoftGroupId)}
            />
          </RadioWrapper>,
          <ExtraSmallText key="displayName" mediumWeight style={{ fontSize: 13 }}>{microsoftGroupName}</ExtraSmallText>,
          <ExtraSmallText key="id" style={{ color: color.grey, fontSize: 11  }}>{microsoftGroupId}</ExtraSmallText>,
        ];
        return {
          data,
          props: [{ fullWidth: true }, {}, {}],
          id: microsoftGroupId,
          canDelete: false,
          canEdit: false,
          hideEditButton: true,
          hideDeleteButton: true,
        };
      }
    );
    return tableData;
  }, [selectedGroupId]);

  useEffect(() => {
    setTableData(createTableData(data));
  }, [createTableData, data]);

  useEffect(() => {
    const userCompany = userState?.companyIds[0];
    if (userCompany) {
      fetchSelectedItem(userCompany).then(() => {
        fetchListItems(userCompany)
          .then((result) => {
            if (!result || !result.data.result) {
              return;
            }

            if (result.data.result) {
              const { groups } = result.data.result;
              if (groups) {
                setData(groups);
              }
            }
          });
      });
    }
  }, [fetchListItems, fetchSelectedItem, userState?.companyIds]);

  return (
    <>
      {isLoading && <LoadOverlay/>}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.activeDirectory.overview.title")}</Title>
          {noGroupSync && (
          <OverviewHeaderInnerRight>
            {selectedGroupName && (
              <>
            <Pill
              backgroundColor={color.transparent}
              textColor={color.grey}
            >
              {t("admin.activeDirectory.table.aadSelected")}
            </Pill>
            <Pill
              backgroundColor={color.bmdPurpleLight}
              textColor={color.bmdPurleDark}
              style={{ marginLeft: "auto" }}
            >
              {selectedGroupName}
            </Pill>
            </>
            )}
            <ButtonsWrapper>
              <ButtonWrapper>
                {selectedGroup?.microsoftGroupId === selectedGroupId && (
                <Button
                  disabled={true}
                >
                  {t("admin.activeDirectory.table.save")}
                </Button>
                )}
                {selectedGroup?.microsoftGroupId !== selectedGroupId && (
                  <Button onPress={() => onChangeSelectedGroup(selectedGroupId)}>
                    {t("admin.activeDirectory.table.save")} →
                  </Button>
                )}
              </ButtonWrapper>
            </ButtonsWrapper>
          </OverviewHeaderInnerRight>
          )}
        </OverviewHeader>
        <PageSelector pages={companyPages} />
        <View>
          {!isLoading && !!error && noGroupSync && (
            <Notification closeNotification={dismissError}>
              {error}
            </Notification>
          )}
          {!isLoading && (
            <Table
              tableId="active_directory"
              headerItems={headerItems}
              tableContent={tableData}
              columnSizes={columnSizes}
              sortList={onSortList}
            />
          )}
        </View>
      </Container>
    </>
  );
};

export default ActiveDirectoryOverview;
