import React, { FC } from "react";
import { TouchableWithoutFeedback, ViewStyle } from "react-native";
import styled, { css } from "styled-components/native";
import marginBottom, { MarginBottomProps } from "../../styles/helpers/marginBottom";
import { ExtraSmallText } from "../../styles/Text";
import {
  borderRadius,
  borderWidth,
  color,
  iconSize,
  spacing,
} from "../../styles/theme";
import { convertObjectToStyle } from "../../utils";

import checkMark from "../../assets/icons/icon_checkbox_check.svg";
import subCheckMark from "../../assets/icons/icon_subcheckmark.svg";

const Wrapper = styled.View<MarginBottomProps & ViewStyle>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => marginBottom(props)};
  ${(props) => convertObjectToStyle(props, ['marginRight'])};
`;

interface BoxProps {
  checked: boolean;
}

const Box = styled.View<BoxProps>`
  ${iconSize.small};
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border-radius: ${borderRadius.small};
  border-width: ${borderWidth.small};
  border-color: ${color.greySelected};
  background-color: ${color.white};

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${color.secondary};
      border-color: ${color.secondary};
    `}
`;

const CheckIcon = styled.Image`
  ${iconSize.extraSmall};
`;

const CheckboxLabel = styled(ExtraSmallText)`
  display: flex;
  margin-left: ${spacing.small};
`;

interface CheckboxProps extends MarginBottomProps, ViewStyle {
  checked: boolean;
  subChecked?: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  subChecked,
  onChange,
  label,
  hasMarginBottom,
  ...props
}) => {
  const onCheckmarkPress = () => {
    onChange(!checked);
  };

  return (
    <TouchableWithoutFeedback onPress={onCheckmarkPress}>
      <Wrapper
        hasMarginBottom={
          typeof hasMarginBottom === "undefined" ? true : hasMarginBottom
        }
        {...props}
      >
        <Box checked={checked || !!subChecked}>
          {(checked || subChecked) && <CheckIcon source={checked ? checkMark : subCheckMark} />}
        </Box>
        {label && <CheckboxLabel mediumWeight>{label}</CheckboxLabel>}
      </Wrapper>
    </TouchableWithoutFeedback>
  );
};

export default Checkbox;
