module["exports"] = [
  "A#? #?#",
  "B#? #?#",
  "C#? #?#",
  "E#? #?#",
  "G#? #?#",
  "H#? #?#",
  "J#? #?#",
  "K#? #?#",
  "L#? #?#",
  "M#? #?#",
  "N#? #?#",
  "P#? #?#",
  "R#? #?#",
  "S#? #?#",
  "T#? #?#",
  "V#? #?#",
  "X#? #?#",
  "Y#? #?#",
];
