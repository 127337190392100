import styled from "styled-components/native";
import { SmallTextGood } from "../Text/smallText";
import { color, spacing, text } from "../theme";

interface Props {
  noRevertMargin?: boolean;
}

export const InputHelpText = styled(SmallTextGood)<Props>`
  color: ${color.grey};
  font-size: ${text.extraSmall.size};
  margin-top: ${({ noRevertMargin }) =>
    noRevertMargin ? 0 : `-${spacing.medium}`};
  margin-bottom: ${spacing.medium};
`;
