module["exports"] = [
  "Boeken",
  "Films",
  "Muziek",
  "Spelletjes",
  "Elektronica",
  "Computers",
  "Huis",
  "Tuin",
  "Gereedschap",
  "Kruiden",
  "Gezondheid",
  "Beauty",
  "Speelgoed",
  "Kinderen",
  "Baby",
  "Kleding",
  "Schoenen",
  "Sieraden",
  "Sport",
  "Verzorging",
  "Auto",
  "Industrieel"
];
