import React, { FC } from "react";
import Text from "../../styles/Text";
import { Backdrop } from "../Dialog";
import Card from "../../styles/Card";
import styled from "styled-components/native";
import { color, iconSize, spacing } from "../../styles/theme";
import loadIcon from "./loadIcon.svg";
import { t } from "../../i18n";

const LoadCard = styled(Card)`
  margin: auto;
  background-color: ${color.darker};
  padding: ${spacing.large};
`;

const StyledText = styled(Text)`
  color: ${color.white};
`;

const LoadIcon = styled.Image`
  ${iconSize.large};
  margin: 0 auto ${spacing.small};
`;

const LoadOverlay: FC = () => (
  <Backdrop>
    <LoadCard>
      <LoadIcon source={loadIcon} />
      <StyledText>{t("general.loading")}</StyledText>
    </LoadCard>
  </Backdrop>
);

export default LoadOverlay;
