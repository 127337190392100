import React, { FC, useCallback, useState } from "react";

import { t } from "../../../i18n";
import {
  UserGroupWithoutId,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LabeledInput from "../../../components/LabeledInput";
import Checkbox from "../../../components/Checkbox";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";

import { SmallTitle } from "../../../styles/Title";
import { MediumTitle } from "../../../styles/Title";
import { SmallText } from "../../../styles/Text";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import Text from "../../../styles/Text";

import { useHistory } from "../../../routing";
import { useClient } from "../../../hooks/useClient";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const CardTitle = styled(Text)`
  font-size: 14px;
  margin-bottom: 1.2rem;
  line-height: 28px;
  color: #161616;
`;

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [reservationWindowLength, setReservationWindowLength] = useState("");

  const [deskLength, setDesksLength] = useState("");
  const [lunchLength, setLunchLength] = useState("");
  const [meetingLength, setMeetingLength] = useState("");
  const [visitorLength, setVisitorLength] = useState("");
  const [resourceLength, setResourceLength] = useState("");

  const [isEmergencyResponder, setEmergencyResponder] = useState<boolean>(false);
  const [isDepartment, setDepartment] = useState<boolean>(true);

  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();
  const isFormValid = useCallback(() => Boolean(name), [name]);

  const submitForm = useCallback(async () => {
    const userGroup: UserGroupWithoutId = {
      label: name,
      reservationWindowLength: reservationWindowLength
        ? parseInt(reservationWindowLength, 10)
        : null,
      reservationWindowLengthDesk: deskLength
        ? parseInt(deskLength, 10)
        : null,
      reservationWindowLengthLunch: lunchLength
        ? parseInt(lunchLength, 10)
        : null,
      reservationWindowLengthMeeting: meetingLength
        ? parseInt(meetingLength)
        : null,
      reservationWindowLengthVisitor: visitorLength
        ? parseInt(visitorLength, 10)
        : null,
      reservationWindowLengthResource: resourceLength
        ? parseInt(resourceLength, 10)
        : null,
      isEmergencyResponseOfficer: isEmergencyResponder,
      isDepartment: isDepartment
    };
    try {
      const success = await handleRequest(client.addUserGroup(userGroup));

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [handleRequest, client, name, deskLength, lunchLength, meetingLength, visitorLength, resourceLength, reservationWindowLength, isEmergencyResponder, isDepartment, history]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}

      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <SmallTitle hasMarginBottom>
            <Text
              onPress={() => history.push("/admin/user-group-overview")}
              color="grey"
            >
              ← {t("menu.item.userGroup")}
            </Text> / {t("admin.userGroup.form.addTitle")}
          </SmallTitle>

          <LabeledInput label={t("admin.userGroup.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.userGroup.form.name") + " *"}
            />
          </LabeledInput>

          <SmallTitle hasMarginBottom>
            {t("admin.userGroup.form.reservationWidowTitle")}
          </SmallTitle>
          <CardTitle>{t("admin.company.form.reservationSettingsTitle")}</CardTitle>


          <LabeledInput
            label={t("admin.company.form.desks")}
            helperText={t(
              "admin.userGroup.form.helper.reservationWindowLength"
            )}
          >
            <Input
              onChangeText={(value) =>
                setDesksLength(value.replace(/[^0-9]/g, ""))
              }
              value={deskLength}
              keyboardType="numeric"
              placeholder={t("admin.userGroup.form.reservationWindowLength")}
            />
          </LabeledInput>

          <LabeledInput
            label={t("admin.company.form.meetingRooms")}
            helperText={t(
              "admin.userGroup.form.helper.reservationWindowLength"
            )}
          >
            <Input
              onChangeText={(value) =>
                setMeetingLength(value.replace(/[^0-9]/g, ""))
              }
              value={meetingLength}
              keyboardType="numeric"
              placeholder={t("admin.userGroup.form.reservationWindowLength")}
            />
          </LabeledInput>

          <LabeledInput
            label={t("admin.company.form.lunches")}
            helperText={t(
              "admin.userGroup.form.helper.reservationWindowLength"
            )}
          >
            <Input
              onChangeText={(value) =>
                setLunchLength(value.replace(/[^0-9]/g, ""))
              }
              value={lunchLength}
              keyboardType="numeric"
              placeholder={t("admin.userGroup.form.reservationWindowLength")}
            />
          </LabeledInput>

          <LabeledInput
            label={t("admin.company.form.forVisitors")}
            helperText={t(
              "admin.userGroup.form.helper.reservationWindowLength"
            )}
          >
            <Input
              onChangeText={(value) =>
                setVisitorLength(value.replace(/[^0-9]/g, ""))
              }
              value={visitorLength}
              keyboardType="numeric"
              placeholder={t("admin.userGroup.form.reservationWindowLength")}
            />
          </LabeledInput>

          <LabeledInput
            label={t("admin.company.form.forResourcesParking")}
            helperText={t(
              "admin.userGroup.form.helper.reservationWindowLength"
            )}
          >
            <Input
              onChangeText={(value) =>
                setResourceLength(value.replace(/[^0-9]/g, ""))
              }
              value={resourceLength}
              keyboardType="numeric"
              placeholder={t("admin.userGroup.form.resourcesLengthParking")}
              // placeholder={t("admin.userGroup.form.resourcesLength")}
            />
          </LabeledInput>

          <LabeledInput>
            <Checkbox
              label={t("admin.userGroup.form.isEmergencyResponder")}
              onChange={setEmergencyResponder}
              checked={isEmergencyResponder}
            />
          </LabeledInput>
          <LabeledInput helperText={t("admin.userGroup.form.helper.isDepartmentHelp")}>
            <Checkbox
              label={t("admin.userGroup.form.isDepartment")}
              onChange={setDepartment}
              checked={isDepartment}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.userGroup.form.createSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
