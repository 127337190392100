module["exports"] = [
  "com",
  "biz",
  "info",
  "name",
  "net",
  "org",
  // Romanian
  "ro",
  "com.ro",
  "org.ro",
  "tm.ro",
  "store.ro",
  "info.ro",
  "nom.ro",
  "nt.ro",
  "firm.ro",
  "www.ro",
  "arts.ro",
  "rec.ro"
];
