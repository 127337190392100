module["exports"] = [
  "Adán",
  "Agustín",
  "Alberto",
  "Alejandro",
  "Alfonso",
  "Alfredo",
  "Andrés",
  "Antonio",
  "Armando",
  "Arturo",
  "Benito",
  "Benjamín",
  "Bernardo",
  "Carlos",
  "César",
  "Claudio",
  "Clemente",
  "Cristián",
  "Cristóbal",
  "Daniel",
  "David",
  "Diego",
  "Eduardo",
  "Emilio",
  "Enrique",
  "Ernesto",
  "Esteban",
  "Federico",
  "Felipe",
  "Fernando",
  "Francisco",
  "Gabriel",
  "Gerardo",
  "Germán",
  "Gilberto",
  "Gonzalo",
  "Gregorio",
  "Guillermo",
  "Gustavo",
  "Hernán",
  "Homero",
  "Horacio",
  "Hugo",
  "Ignacio",
  "Jacobo",
  "Jaime",
  "Javier",
  "Jerónimo",
  "Jesús",
  "Joaquín",
  "Jorge",
  "Jorge Luis",
  "José",
  "José Eduardo",
  "José Emilio",
  "José Luis",
  "José María",
  "Juan",
  "Juan Carlos",
  "Julio",
  "Julio César",
  "Lorenzo",
  "Lucas",
  "Luis",
  "Luis Miguel",
  "Manuel",
  "Marco Antonio",
  "Marcos",
  "Mariano",
  "Mario",
  "Martín",
  "Mateo",
  "Miguel",
  "Miguel Ángel",
  "Nicolás",
  "Octavio",
  "Óscar",
  "Pablo",
  "Patricio",
  "Pedro",
  "Rafael",
  "Ramiro",
  "Ramón",
  "Raúl",
  "Ricardo",
  "Roberto",
  "Rodrigo",
  "Rubén",
  "Salvador",
  "Samuel",
  "Sancho",
  "Santiago",
  "Sergio",
  "Teodoro",
  "Timoteo",
  "Tomás",
  "Vicente",
  "Víctor",
  "Adela",
  "Adriana",
  "Alejandra",
  "Alicia",
  "Amalia",
  "Ana",
  "Ana Luisa",
  "Ana María",
  "Andrea",
  "Anita",
  "Ángela",
  "Antonia",
  "Ariadna",
  "Barbara",
  "Beatriz",
  "Berta",
  "Blanca",
  "Caridad",
  "Carla",
  "Carlota",
  "Carmen",
  "Carolina",
  "Catalina",
  "Cecilia",
  "Clara",
  "Claudia",
  "Concepción",
  "Conchita",
  "Cristina",
  "Daniela",
  "Débora",
  "Diana",
  "Dolores",
  "Lola",
  "Dorotea",
  "Elena",
  "Elisa",
  "Eloisa",
  "Elsa",
  "Elvira",
  "Emilia",
  "Esperanza",
  "Estela",
  "Ester",
  "Eva",
  "Florencia",
  "Francisca",
  "Gabriela",
  "Gloria",
  "Graciela",
  "Guadalupe",
  "Guillermina",
  "Inés",
  "Irene",
  "Isabel",
  "Isabela",
  "Josefina",
  "Juana",
  "Julia",
  "Laura",
  "Leonor",
  "Leticia",
  "Lilia",
  "Lorena",
  "Lourdes",
  "Lucía",
  "Luisa",
  "Luz",
  "Magdalena",
  "Manuela",
  "Marcela",
  "Margarita",
  "María",
  "María del Carmen",
  "María Cristina",
  "María Elena",
  "María Eugenia",
  "María José",
  "María Luisa",
  "María Soledad",
  "María Teresa",
  "Mariana",
  "Maricarmen",
  "Marilú",
  "Marisol",
  "Marta",
  "Mayte",
  "Mercedes",
  "Micaela",
  "Mónica",
  "Natalia",
  "Norma",
  "Olivia",
  "Patricia",
  "Pilar",
  "Ramona",
  "Raquel",
  "Rebeca",
  "Reina",
  "Rocío",
  "Rosa",
  "Rosalia",
  "Rosario",
  "Sara",
  "Silvia",
  "Sofía",
  "Soledad",
  "Sonia",
  "Susana",
  "Teresa",
  "Verónica",
  "Victoria",
  "Virginia",
  "Yolanda"
];
