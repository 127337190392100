module["exports"] = {
  "adjective": [
    "Маленький",
    "Эргономичный",
    "Грубый",
    "Интеллектуальный",
    "Великолепный",
    "Невероятный",
    "Свободный",
    "Большой",
    "Фантастический",
    "Практичный",
    "Лоснящийся",
    "Потрясающий"
  ],
  "material": [
    "Стальной",
    "Деревянный",
    "Бетонный",
    "Пластиковый",
    "Хлопковый",
    "Гранитный",
    "Кожанный",
    "Неодимовый",
    "Меховой",
    "Натуральный",
    "Резиновый"
  ],
  "product": [
    "Стул",
    "Автомобиль",
    "Компьютер",
    "Берет",
    "Кулон",
    "Стол",
    "Свитер",
    "Ремень",
    "Ножницы",
    "Носки",
    "Майка",
    "Кепка",
    "Куртка",
    "Плащ",
    "Сабо",
    "Шарф",
    "Клатч",
    "Кошелек",
    "Портмоне",
    "Ботинок"
  ]
};
