module["exports"] = {
  "adjective": [
    "קטן",
    "ארגונומי",
    "כפרי",
    "אינטליגנטי",
    "מאוד יפה",
    "מדהים",
    "פנטסטי",
    "מעשי",
    "מלוטש",
    "מדהים",
    "גנרית",
    "עבודת יד",
    "עבודת יד",
    "מורשה",
    "מְזוּקָק",
    "לא ממותג",
    "טעים"
  ],
  "material": [
    "פלדה",
    "עץ",
    "בטון",
    "פלסטי",
    "כותנה",
    "גרניט",
    "גומי",
    "מתכת",
    "רך",
    "צַח",
    "קפוא"
  ],
  "product": [
    "כיסא",
    "אוטו",
    "מחשב",
    "מקלדת",
    "עכבר",
    "אופניים",
    "כדור",
    "כפפות",
    "מכנסיים",
    "חולצה",
    "שולחן",
    "נעליים",
    "כובע",
    "מגבות",
    "סבון",
    "טונה",
    "עוף",
    "דג",
    "גבינה",
    "בייקון",
    "פיצה",
    "סלט",
    "נקניקיות",
    "צ'יפס"
  ]
};
