import {
  CompanyProperties,
  InlineResponse400Errors,
} from '../../../../../bookmydesk-api-sdk-typescript-axios';
import azureActiveDirectoryIcon from '../../../../assets/icons/azure-active-directory.svg';
import lunchIcon from '../../../../assets/icons/ic_reservation_lunch.svg';
import buildingIcon from '../../../../assets/icons/icon_company_admin.svg';
import settingIcon from '../../../../assets/icons/icon_settings_admin.svg';
import meetingRoomIcon from '../../../../assets/icons/meeting_room.svg';
import microsoftOutlookIcon from '../../../../assets/icons/microsoft-outlook-icon.svg';
import microsoftIcon from '../../../../assets/icons/microsoft.svg';
import officeChairIcon from '../../../../assets/icons/office_chair.svg';
import visitorIcon from '../../../../assets/icons/icon_visitors_admin.svg';
import checkinIcon from '../../../../assets/icons/icon_checkin_admin.svg';
import reservationsIcon from '../../../../assets/icons/icon_reservations_admin.svg';
import allowanceIcon from '../../../../assets/icons/icon_allowance_admin.svg';
import facilityIcon from '../../../../assets/icons/icon_fm_admin.svg';
import workingFromHomeIcon from '../../../../assets/icons/icon_home_admin.svg';
import Button from '../../../../components/Button';
import Container from '../../../../components/Container';
import Grid, { Column } from '../../../../components/Grid';
import HeaderCard from '../../../../components/HeaderCard';
import InputValidationErrors from '../../../../components/InputValidationErrors';
import LabeledInput from '../../../../components/LabeledInput';
import LoadOverlay from '../../../../components/LoadOverlay';
import Notification from '../../../../components/Notification';
import PageSelector from '../../../../components/PageSelector';
import PickerInput from '../../../../components/PickerInput';
import Pill from '../../../../components/Pill';
import Toggle from '../../../../components/Toggle';
import { useAuth } from '../../../../context/authContext';
import { InputValidationError, useApi } from '../../../../hooks/useApi';
import { useClient } from '../../../../hooks/useClient';
import useQuery from '../../../../hooks/useQuery';
import { t } from '../../../../i18n';
import InnerCard from '../../../../styles/Card/InnerCard';
import Input from '../../../../styles/Input';
import { OverviewHeader } from '../../../../styles/Overview';
import Text, { SmallText, ExtraSmallText } from '../../../../styles/Text';
import { ExtraSmallTextGood } from '../../../../styles/Text/extraSmallText';
import { color, spacing } from '../../../../styles/theme';
import { SmallTitle } from '../../../../styles/Title';
import Title from '../../../../styles/Title/title';
import Link from "../../components/Link";
import { isValidEmail } from '../../../../utils';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Linking, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
const Spacer = styled.View`
  margin-bottom: 10px;
`;

const SeatSettingRow = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const PickerInputWrapper = styled(View)`
  width: 45%;
  margin-right: ${spacing.medium};
`;

const HeaderCardTitleSuffixWrapper = styled.View`
  margin-left: auto;
  margin-right: -${spacing.medium};
`;

const expirationItems = [
  ...[0, 5, 10, 15, 20, 25, 30, 45, 60, 90].map((minutes) => ({
    label: `${minutes} ${t("general.minutes")}`,
    value: `${minutes}`,
  })),
];

const CardTitle = styled(Text)`
  font-size: 14px;
  margin-bottom: 1.2rem;
  line-height: 28px;
  color: #161616;
`;

const ArchiveSettings: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const { companyPages, userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const query = useQuery();
  const [
    showAzureActiveDirectoryConnectedMessage,
    setShowAzureActiveDirectoryConnectedMessage,
  ] = useState(query.get("success") === "azure-active-directory-connected");
  const [
    showAzureActiveDirectoryNotConnectedMessage,
    setShowAzureActiveDirectoryNotConnectedMessage,
  ] = useState(query.get("error") === "azure-active-directory-not-connected");
  const [
    showAzurePlacesConnectedMessage,
    setShowAzurePlacesConnectedMessage,
  ] = useState(query.get("success") === "azure-places-connected");
  const [
    showAzurePlacesNotConnectedMessage,
    setShowAzurePlacesNotConnectedMessage,
  ] = useState(query.get("error") === "azure-places-not-connected");

  /* anonimizing and archiving of reservation data */
  const [reservationAnonymousDays, setReservationAnonymousDays] = useState("");
  const [visitorAnonymousDays, setVisitorAnonymousDays] = useState("");

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [daysAdvance, setDaysAdvance] = useState("");
  const [deskLength, setDesksLength] = useState("");
  const [lunchLength, setLunchLength] = useState("");
  const [meetingLength, setMeetingLength] = useState("");
  const [visitorLength, setVisitorLength] = useState("");
  const [forceTimeSlotsFeature, setForceTimeSlotsFeature] = useState(false);
  const [hideCheckInTimeEnabled, setHideCheckInTimeEnabled] = useState(false);
  const [checkInFeature, setCheckInFeature] = useState(false);
  const [lunchFeature, setLunchFeature] = useState(false);
  /* compensation module */
  const [compensationEnabled, setCompensationEnabled] = useState(false);
  const [compensationCutOffDay, setCompensationCutOffDay] = useState("");
  const [compensationDailyHomeCompensation, setCompensationDailyHomeCompensation] = useState("");
  const [compensationDistanceUnitCompensation, setCompensationDistanceUnitCompensation] = useState("");
  const [compensationExportEmail, setCompensationExportEmail] = useState("");
  const [compensationMaxDailyDistance, setCompensationMaxDailyDistance] = useState("");
  /* visitor module */
  const [visitorFeature, setVisitorFeature] = useState(false);
  const [homeEnabled, setHomeEnabled] = useState(false);
  const [
    visitorReservationConfirmEmailEnabled,
    setVisitorReservationConfirmEmailEnabled,
  ] = useState(false);
  const [facilitymanagementFeature, setFacilitymanagementFeature] =
    useState(false);
  const [reservationForUserEnabled, setReservationForUserEnabled] =
    useState(false);
  const [meetingFeature, setMeetingFeature] = useState(false);
  const [recurringReservationFeature, setRecurringReservationFeature] =
    useState(false);
  const [geofenceFeature, setGeofenceFeature] = useState(false);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [deskExpirationTime, setDeskExpirationTime] = useState("");
  const [deskAutoCheckoutTime, setDeskAutoCheckoutTime] = useState("");
  const [lunchExpirationTime, setLunchExpirationTime] = useState("");
  const [lunchAutoCheckoutTime, setLunchAutoCheckoutTime] = useState("");
  const [meetingExpirationTime, setMeetingExpirationTime] = useState("");
  const [meetingAutoCheckoutTime, setMeetingAutoCheckoutTime] = useState("");

  const [microsoftSsoEnabled, setMicrosoftSsoEnabled] = useState(false);
  const [microsoftSyncEnabled, setMicrosoftSyncEnabled] = useState(false);
  const [microsoftPlacesEnabled, setMicrosoftPlacesEnabled] = useState(false);
  const [azureActiveDirectoryActive, setAzureActiveDirectoryActive] =
    useState(false);
  const [azurePlacesActive, setAzurePlacesActive] =
    useState(false);

  const userCompany = userState?.companyIds[0];

  /* set maximum days ahead */
  const maxDaysAhead = 730;
  const maxDaysAheadMeetings = 365;

  /* set maximum days of the month */
  const minMonthDay = 1;
  const maxMonthDay = 31;

  useEffect(() => {
    if (!userCompany) {
      return;
    }

    const fetchCompany = async () =>
      await handleRequest(client.getCompany(userCompany));

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { company } = result.data.result;

      setReservationAnonymousDays(company.reservationAnonymousDays?.toString());
      setVisitorAnonymousDays(company.visitorAnonymousDays?.toString());


    ;
    });
  }, [client, handleRequest, userCompany]);

  const isFormValid = useCallback(
    () =>

    []
  );

  const submitForm = useCallback(async () => {
    const company: CompanyProperties = {

      reservationAnonymousDays: parseInt(reservationAnonymousDays),
      reservationVisitorDays: parseInt(reservationVisitorDays),

    };
    try {
      const success = await handleRequest(
        client.updateCompany(userCompany, company)
      );

      if (success) {
        setShowUpdateMessage(true);
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [reservationAnonymousDays, visitorAnonymousDays]);

  const updateCompensationCutOffDay = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxMonthDay, Number(days));
      setCompensationCutOffDay(daysMax);
    },
    [setCompensationCutOffDay]
  );

  const updateDaysAdvance = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setDaysAdvance(days);
    },
    [setDaysAdvance]
  );

  const updateDeskLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxDaysAhead, Number(days));
      setDesksLength(daysMax);
    },
    [setDesksLength]
  );


  const provideAzureActiveDirectoryAccess = useCallback(async () => {
    try {
      const response = await handleRequest(client.redirToMSAdmin());
      const authUrl = response?.data.result.authUrl;
      if (!authUrl) {
        throw new Error("No authUrl retrieved");
      }

      window.location.assign(authUrl);
    } catch (error) {
      console.error(error);
      setShowAzureActiveDirectoryNotConnectedMessage(true);
    }
  }, [handleRequest, client]);

  const provideAzurePlacesAccess = useCallback(async () => {
    try {
      const response = await handleRequest(client.redirToMSAdmin('places'));
      const authUrl = response?.data.result.authUrl;
      if (!authUrl) {
        throw new Error("No authUrl retrieved");
      }

      window.location.assign(authUrl);
    } catch (error) {
      console.error(error);
      setShowAzurePlacesNotConnectedMessage(true);
    }
  }, [handleRequest, client]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.company.form.editTitle", { name })}</Title>
          <Button disabled={!isFormValid()} onPress={submitForm}>
            {t("admin.company.form.editSubmit")}
          </Button>
        </OverviewHeader>
        <PageSelector pages={companyPages} />
        {Boolean(error) && (
          <InputValidationErrors
            errors={errors}
            closeNotification={dismissError}
          />
        )}
        {showUpdateMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowUpdateMessage(false)}
          >
            {t("general.savedSuccess")}
          </Notification>
        ) : null}

        <Grid>
          <Column columns={3}>
            <SmallText>Scheduled exports</SmallText>
          </Column>
          <Column columns={3}>

          </Column>
          <Column columns={3}>

          </Column>
        </Grid>
      </Container>
    </>
  );
};

export default ArchiveSettings;
