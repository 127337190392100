import styled from "styled-components/native";
import { spacing } from "../theme";

export const OverviewHeaderInnerRight = styled.View`
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-left: ${spacing.medium};
`;
