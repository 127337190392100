import { saveAs } from "file-saver";
import moment from "moment";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Linking, ScrollView, View, Clipboard } from "react-native";
import styled from "styled-components/native";
import {
  Reservation,
  ReservationStatus,
  ReservationWithRelations,
  ResourceReservationWithRelations,
  User,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import home from "../../../assets/icons/home.svg";
import other from "../../../assets/icons/other.svg";
import employee from "../../../assets/icons/user.svg";
import copyEmailIcon from "../../../assets/icons/icon-copy-email.svg";
import visitor from "../../../assets/icons/userPlus.svg";
import Button, {
  ButtonsWrapper,
  ButtonText,
  ButtonWrapper,
  DangerButton,
} from "../../../components/Button";
import Container from "../../../components/Container";
import Dialog from "../../../components/Dialog";
import ReservationsFilter, {
  ReservationFilter,
} from "../../../components/Filter/ReservationFilter";
import { DateComparisor } from "../../../components/Filter/reservationFilter.constant";
import Link from "../../../components/Link";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Paginator, { takeSize } from "../../../components/Paginator";
import Pill from "../../../components/Pill";
import SearchInput from "../../../components/SearchInput";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSearch } from "../../../hooks/useSearch";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps } from "../../../routing";
import { AmountIndicatorSecondary } from "../../../styles/AmountIndicator";
import Input from "../../../styles/Input";
import {
  OverviewDeleteModalButtonWrapper,
  OverviewHeader,
  OverviewHeaderInnerRight,
} from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import { ExtraSmallTextGood } from "../../../styles/Text/extraSmallText";
import { color, spacing } from "../../../styles/theme";
import Title from "../../../styles/Title";
import { CancelIcon, DeleteIcon, GridIcon, MailIcon } from "../../../components/Icons";
import Select, { SelectItemInterface } from "../../../components/Select";
import useCompanyTransportOptions from "../../../hooks/useCompanyTransportOptions";

const ListWrapper = styled.View`
  width: 200%;
`;

const ReservationType = styled(ExtraSmallText)`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const UploadIconWrapper = styled.View`
  width: 30px;
`;

const UploadIcon = styled.Image`
  margin-right: ${spacing.small};
  color: ${color.grey};
`;

const IconWrapper = styled.View`
  margin-right: 10px;
`;

enum ViewModes {
  grid = 'grid',
  list = 'list',
}

const columnSizes = [3, 3, 3, 2, 3, 1, 3, 3, 2, 1, 1, 1, 2, 1.15, 1.2, 2, 2, 2, 1.5, 1.5];

const ReservationOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(
      t("admin.reservation.overview.name"),
      true,
      "user.lastName"
    ),
    createHeaderItem(t("admin.reservation.overview.email"), true, "user.email"),
    createHeaderItem(t("admin.user.overview.department"), false),
    createHeaderItem(t("admin.reservation.overview.type"), true, "type"),
    createHeaderItem(t("admin.reservation.overview.resourceReservation"), false),
    createHeaderItem(
      t("admin.reservation.overview.parking"),
      true,
      "includeParking"
    ),
    createHeaderItem(
      t("admin.reservation.overview.visitorName"),
      true,
      "visitorName"
    ),
    createHeaderItem(
      t("admin.reservation.overview.visitorEmail"),
      true,
      "visitorEmail"
    ),
    createHeaderItem(
      t("admin.reservation.overview.visitorPhone"),
      true,
      "visitorPhone"
    ),
    createHeaderItem(t("admin.reservation.overview.date"), true, "date"),
    createHeaderItem(t("admin.reservation.overview.from"), true, "from"),
    //createHeaderItem("", false),
    createHeaderItem(t("admin.reservation.overview.to"), true, "to"),
    createHeaderItem(t("admin.reservation.overview.status"), false, "status"),
    createHeaderItem(t("admin.reservation.overview.checkedIn"), false),
    createHeaderItem(t("admin.reservation.overview.checkedOut"), false),
    createHeaderItem(
      t("admin.reservation.overview.location"),
      true,
      "location.name"
    ),
    createHeaderItem(t("admin.reservation.overview.map"), true, "map.name"),
    createHeaderItem(t("admin.reservation.overview.seat"), true, "seat.name"),
    createHeaderItem(t("admin.reservation.overview.seatType"), false),
    createHeaderItem(t("admin.reservation.overview.companyTransport"), false),
    createHeaderItem(t("admin.reservation.overview.created"), false),
    //createHeaderItem("", false),
  ];
  const { userState } = useAuth();
  const client = useClient();
  const { debouncedSearchTerm, setDebouncedSearchTerm } = useSearch();
  const companyTransportOptions = useCompanyTransportOptions({ companyId: userState?.companyIds[0] });

  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listReservations>[1]>(tableHeaders);

  const userCompany = userState?.companyIds[0];

  const [data, setData] = useState<Array<ReservationWithRelations>>([]);
  const [resourceReservations, setResourceReservations] = useState<Array<ResourceReservationWithRelations>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelMessage, setShowCancelMessage] = useState(false);
  const [showCancelMultipleMessage, setShowCancelMultipleMessage] =
    useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteMultipleModal, setShowDeleteMultipleModal] = useState(false);
  const [showDeleteMultipleMessage, setShowDeleteMultipleMessage] =
    useState(false);
  const [showNotAllDeletedMessage, setShowNotAllDeletedMessage] =
    useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ReservationFilter>({});
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [itemSelected, setItemSelected] = useState<Reservation>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [currentViewMode, setCurrentViewMode] = useState<SelectItemInterface>([]);

  const [viewModes] = useState<SelectItemInterface[]>([
    {
      icon: <GridIcon />,
      label: t("table.showHide"),
      isActive: true,
      value: ViewModes.grid,
    }
  ]);

  const [cancelReason, setCancelReason] = useState("");

  const filterOperator = useMemo(() => {
    // no specific fromOperator in the backend for IS_BETWEEN
    // but 'gt' value can accept from and to value
    return filter.dateOperator === DateComparisor.IS_BETWEEN
      ? "gt"
      : filter.dateOperator;
  }, [filter.dateOperator]);

  const fetchResourceReservations = useCallback(async () => {
    if (
      userCompany &&
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(client.listResourceReservations(userCompany));
    }
  }, [debouncedSearchTerm, searchTerm, userCompany, client, handleRequest]);


  const fetchListItems = useCallback(async () => {
    if (
      userCompany &&
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(
        client.listReservations(
          userCompany,
          sortField,
          sortOrder,
          debouncedSearchTerm,
          filterOperator,
          undefined,
          takeSize,
          skipParam,
          filter.from,
          filter.to,
          undefined,
          filter.status,
          filter.locationId,
          filter.mapId,
          filter.seatType,
          filter.type,
          filter.includeAnonymous,
          filter.userGroupId,
          filter.companyTransportOptionId
        )
      );
    }
  }, [
    debouncedSearchTerm,
    searchTerm,
    filter,
    skipParam,
    sortOrder,
    sortField,
    userCompany,
    filterOperator,
    client,
    handleRequest,
  ]);

  const fetch = useCallback(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setData(items);
        setTotalItems(total);
      }
    });

    fetchResourceReservations().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      if (items) {
        setResourceReservations(items);
      }
    });

  }, [fetchListItems, fetchResourceReservations]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setTableData(createTableData(data, resourceReservations));
  }, [data, resourceReservations])

  function getFullName(user: User | undefined) {
    if (!user) return "-";
    const fullName = user?.infix
      ? `${user.firstName} ${user.infix} ${user.lastName}`
      : `${user?.firstName} ${user?.lastName}`;

    return fullName;
  }

  // function getFullNameOrganizer(createdByUser: User | undefined) {
  //   if (!createdByUser) return "-";
  //   const fullNameOrganizer = "jan";
  //   return fullNameOrganizer;
  // }

  const createTableData = (items: ReservationWithRelations[], resourceReservationItems: ResourceReservationWithRelations[]) => {
    const tableData: TableContent[] = items.map(
      ({
        user,
        createdByUserId,
        createdByUser,
        seat,
        type,
        visitorName,
        visitorEmail,
        visitorPhone,
        includeParking,
        date,
        from,
        to,
        checkedInTime,
        checkedOutTime,
        id,
        status,
        cancelledReason,
        companyTransportOptionId,
        created,
        _operations,
      }) => {
        console.log('🚀 ~ file: index.tsx:332 ~ createTableData ~ id:', id)
        const fullName = getFullName(user);
        //const fullNameOrganizer = getFullNameOrganizer(user);
        console.log('resourceReservationItems', resourceReservationItems)
        const resourceReservationWithCurrentReservations = resourceReservationItems.filter((resourceReservationItem) => resourceReservationItem.reservationId === id);
        console.log('🚀 ~ file: index.tsx:335 ~ createTableData ~ resourceReservationWithCurrentReservations:', resourceReservationWithCurrentReservations)
        let statusTextColor = color.quaternary;
        let statusBackgroundColor = color.quaternaryLight;
        
        switch (status) {
          case ReservationStatus.Cancelled:
            statusTextColor = color.tertiaryDark;
            statusBackgroundColor = color.tertiaryLight;
            break;
          case ReservationStatus.Expired:
            statusTextColor = color.primary;
            statusBackgroundColor = color.primaryLight;
            break;
          case ReservationStatus.CheckedIn:
          case ReservationStatus.CheckedOut:
            statusTextColor = color.quinary;
            statusBackgroundColor = color.quinaryLight;
            break;
        }

        const userEmail = user ? user?.email : "-";
        const copyEmail = ()=> Clipboard.setString(userEmail);
        const userGroups = user ? user?.userGroups : "-";
        const reservationMadeBy = createdByUser ? `${createdByUser?.firstName} ${createdByUser?.lastName}` : "-";

        const data: ReactElement[] = [
          <ExtraSmallText key="fullName" mediumWeight>
            {fullName}
          </ExtraSmallText>,
          <ExtraSmallText
            onPress={userEmail !== '-' &&  (
              (copyEmail)
            )}
          >
            {userEmail}
            {userEmail !== '-' &&  (
            <UploadIcon
              style={{ width: 14, height: 16, marginLeft: 5, top: 4 }}
              source={copyEmailIcon}
              IconOnHover
            />
          )}
          </ExtraSmallText>,
          <ExtraSmallText key="department" mediumWeight>
          {userEmail === '-' &&  (
            "-"
          )}
          {userEmail !== '-' &&  (
            userGroups.map((group) => group.label).join(", ")
          )}
          </ExtraSmallText>,
          <>
            {type === "normal" &&
              (!createdByUserId || user?.id === createdByUserId) && (
                <ReservationType key="reservationNormal">
                  <UploadIconWrapper>
                    <UploadIcon
                      style={{ width: 16, height: 13 }}
                      source={employee}
                    />
                  </UploadIconWrapper>
                  {t(`admin.reservation.type.normal`)}
                </ReservationType>
              )}
            {type === "normal" &&
              !!createdByUserId &&
              user?.id !== createdByUserId && (
                <ReservationType
                  key="reservationNormalOther"
                  color={color.reservationOther}
                >
                  <UploadIconWrapper>
                    <UploadIcon
                      style={{ width: 22, height: 14 }}
                      source={other}
                    />
                  </UploadIconWrapper>
                  {t(`admin.reservation.type.normalOther`)} {reservationMadeBy}
                </ReservationType>
              )}
            {type === "visitor" && (
              <ReservationType
                key="reservationVisitor"
                color={color.reservationVisitor}
              >
                <UploadIconWrapper>
                  <UploadIcon
                    style={{ width: 22, height: 15 }}
                    source={visitor}
                  />
                </UploadIconWrapper>
                {t(`admin.reservation.type.visitor`)}
              </ReservationType>
            )}
            {type === "home" && (
              <ReservationType
                key="reservationHome"
                color={color.reservationHome}
              >
                <UploadIconWrapper>
                  <UploadIcon style={{ width: 15, height: 15 }} source={home} />
                </UploadIconWrapper>
                {t(`admin.reservation.type.home`)}
              </ReservationType>
            )}
          </>,
         <ExtraSmallText key="resourceReservationParking" mediumWeight>
                    {
                      resourceReservationWithCurrentReservations.length > 0 ? (
                        resourceReservationWithCurrentReservations.map((resourceReservationItem) => {
                          return (
                            <p key={resourceReservationItem.id}>
                              {resourceReservationItem.resource.name} - {resourceReservationItem.user.first_name} {resourceReservationItem.user.last_name}
                            </p>
                          )
                        }
                        )
                      ) : (
                        "-"
                      )
                    }
                  </ExtraSmallText>,
          includeParking ? (
            <Pill
              key="parking"
              textColor={color.quaternary}
              backgroundColor={color.quaternaryLight}
            >
              {t("general.yes")}
            </Pill>
          ) : (
            <Pill
              key="parking"
              textColor={color.grey}
              backgroundColor={color.greyLightest}
            >
              {t("general.no")}
            </Pill>
          ),
          <ExtraSmallText key="visitorName">
            {visitorName ? visitorName : "-"}
          </ExtraSmallText>,
          visitorEmail ? (
            <Link
              key="visitorEmail"
              onPress={() => {
                Linking.openURL(`mailto:${visitorEmail}`);
              }}
              isActive
            >
              <ExtraSmallText>{visitorEmail}</ExtraSmallText>
            </Link>
          ) : (
            <ExtraSmallText key="noVisitorEmail">{"-"}</ExtraSmallText>
          ),
          visitorPhone ? (
            <Link
              onPress={() => {
                Linking.openURL(`tel://${visitorPhone}`);
              }}
              isActive
            >
              <ExtraSmallText>{visitorPhone}</ExtraSmallText>
            </Link>
          ) : (
            <ExtraSmallText>{"-"}</ExtraSmallText>
          ),
          <ExtraSmallText key="date">
            {moment.utc(date).format("DD-MM-YYYY")}
          </ExtraSmallText>,
          <ExtraSmallText key="from">{from}</ExtraSmallText>,
          //<ExtraSmallText key="seperator">{"-"}</ExtraSmallText>,
          <ExtraSmallText key="to">{to}</ExtraSmallText>,
          <View key="status">
            <ExtraSmallText>
              <Pill
                backgroundColor={statusBackgroundColor}
                textColor={statusTextColor}
                key="status"
              >
                {t(`admin.reservation.status.${status}`)}
              </Pill>
            </ExtraSmallText>
            {status === "cancelled" && cancelledReason && (
              <ExtraSmallText>{cancelledReason}</ExtraSmallText>
            )}
          </View>,
          <ExtraSmallText key="checkedInTime">{checkedInTime}</ExtraSmallText>,
          <ExtraSmallText key="checkedOutTime">
            {checkedOutTime}
          </ExtraSmallText>,
          <ExtraSmallText key="location">
            {seat?.map?.location.name}
          </ExtraSmallText>,
          <ExtraSmallText key="map">{seat?.map?.name}</ExtraSmallText>,
          <ExtraSmallText key="seat">{seat?.name}</ExtraSmallText>,
          <ExtraSmallText key="seatType">
            {seat ? t(`admin.map.seatType.${seat?.seatType}`) : ""}
          </ExtraSmallText>,          
          <ExtraSmallText key="companyTransportOptions">
            {companyTransportOptionId ? companyTransportOptions?.find(option => option.id === companyTransportOptionId)?.name : ""}
          </ExtraSmallText>,
          <ExtraSmallText key="created">
            {moment.utc(created).format("DD-MM-YYYY")} - {moment.utc(created).format("HH:mm")}
          </ExtraSmallText>,
        ];
        return {
          data,
          id: id,
          canDelete: true,
          canEdit: false,
          hideEditButton: true,
          hideDeleteButton: false,
        };
      }
    );
    return tableData;
  };

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteReservation(itemSelected!.id)
    );

    if (response && response.status === 204) {
      fetch();
      setShowDeleteMessage(true);
    }
  }, [handleRequest, client, itemSelected, fetch]);

  const deleteSelected = useCallback(async () => {
    setShowDeleteMultipleModal(false);
    const errors = (
      await Promise.all(
        selectedIds.map(async (selectedId) => {
          const response = await handleRequest(
            client.deleteReservation(selectedId)
          );

          if (!response || response.status !== 204) {
            return selectedId;
          }
          return undefined;
        })
      )
    ).filter((error) => typeof error !== "undefined");

    fetch();
    if (errors.length > 0) {
      setShowNotAllDeletedMessage(true);
    } else {
      setShowDeleteMultipleMessage(true);
    }
  }, [client, fetch, handleRequest, selectedIds]);

  const cancelSelected = useCallback(async () => {
    setShowCancelModal(false);
    try {
      const response = await handleRequest(
        client.cancelReservation({
          reservationIds: selectedIds,
          reason: cancelReason,
        })
      );

      setCancelReason("");

      if (response?.status !== 204) {
        return;
      }

      if (selectedIds.length === 1) {
        setShowCancelMessage(true);
      } else {
        setShowCancelMultipleMessage(true);
      }
      fetch();
    } catch (error) {
      console.error(error);
    }
  }, [client, fetch, handleRequest, selectedIds, cancelReason]);

  const exportReservations = useCallback(async () => {
    if (!userCompany) {
      return;
    }

    const response = await handleRequest(
      client.exportReservations(
        userCompany,
        sortField,
        sortOrder,
        debouncedSearchTerm,
        filterOperator,
        undefined,
        undefined,
        filter.from,
        filter.to,
        undefined,
        filter.status,
        filter.locationId,
        filter.mapId,
        filter.seatType,
        filter.type,
        true,
        filter.companyTransportOptionId,
        { responseType: "blob" },
      )
    );

    if (!response) {
      return;
    }

    saveAs(response.data, "reservations.xlsx");
  }, [
    debouncedSearchTerm,
    filter,
    sortOrder,
    sortField,
    userCompany,
    filterOperator,
    client,
    handleRequest,
  ]);

  const updateSearch = (text: string) => {
    setSearchTerm(text);
    setDebouncedSearchTerm(text);
    setSkipParam(0);
  };

  const canCancelAllSelectedIds =
    selectedIds.filter((id) =>
      data.find(
        (reservation) =>
          reservation.id === id && !reservation._operations?.canCancel
      )
    ).length === 0;

  const handleRowSelection = useCallback((ids: string[], isAllSelected?: boolean) => {
    setIsAllSelected(!!isAllSelected);
    if(isAllSelected) {
      const ids = data.map((item) => item.id).filter((id) => id !== null) as string[];
      setSelectedIds(ids);
    } else {
      setSelectedIds(ids);
    }
  }, [setSelectedIds, data]);

  const handleViewModeChange = useCallback((selectedViewMode: SelectItemInterface[]) => {
    setCurrentViewMode(selectedViewMode[0]);
  }, [setCurrentViewMode]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.reservation.overview.title")}</Title>
          <OverviewHeaderInnerRight>
            <SearchInput
              wrapperStyle={`margin-right: ${spacing.medium};`}
              placeholder={t("general.filter.search")}
              onChangeText={updateSearch}
              value={searchTerm}
            />
            <ButtonsWrapper>
              <ButtonWrapper>
                <Button
                  hasShadow
                  backgroundColor={color.white}
                  backgroundHoverColor={color.white}
                  color={color.darker}
                  onPress={() => setShowFilterModal(true)}
                  indicator={
                    <AmountIndicatorSecondary>
                      {numberOfFilters}
                    </AmountIndicatorSecondary>
                  }
                >
                  {t("general.filter.filter")}
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Button onPress={exportReservations}>
                  {t("admin.reservation.overview.export")}
                </Button>
              </ButtonWrapper>
            </ButtonsWrapper>
          </OverviewHeaderInnerRight>
        </OverviewHeader>
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showCancelMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowCancelMessage(false)}
          >
            {t("general.cancelSuccess")}
          </Notification>
        )}
        {showCancelMultipleMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowCancelMultipleMessage(false)}
          >
            {t("general.cancelMultipleSuccess")}
          </Notification>
        )}
        {showDeleteMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        )}
        {showDeleteMultipleMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMultipleMessage(false)}
          >
            {t("general.deleteMultipleSuccess")}
          </Notification>
        )}
        {showNotAllDeletedMessage && (
          <Notification
            type="danger"
            closeNotification={() => setShowNotAllDeletedMessage(false)}
          >
            {t("general.notAllDeletedWarning")}
          </Notification>
        )}
        <ScrollView horizontal={true}>
          <ListWrapper>
            {isLoading ? null : (
              <Table
                tableId="reservation_overview"
                hideColumn
                selectable
                headerItems={headerItems}
                tableContent={tableData}
                columnSizes={columnSizes}
                sortList={onSortList}
                sortOrder={sortOrder}
                sortField={sortField}
                onDeleteItem={openDeleteModal}
                onChangeSelectedIds={handleRowSelection}
                recordsCount={totalItems}
                selectToggle={(
                  <>
                    <IconWrapper><GridIcon /></IconWrapper>
                    {t("table.showHide")}
                  </>
                )}
                skipParam={skipParam}
                setSkipParam={setSkipParam}
              >
                {selectedIds.length > 0 && (
                  <>
                    <ExtraSmallText>
                      {t("table.rowsSelected", { count: isAllSelected ? totalItems : selectedIds.length})}
                    </ExtraSmallText>

                    <Link
                      isActive={selectedIds.length > 0}
                      disabled={selectedIds.length === 0}
                      marginLeft={20}
                      onPress={() => selectedIds.length > 0 && setShowDeleteMultipleModal(true)}
                    >
                      <DeleteIcon /> {t('general.delete')}
                    </Link>
                    <Link
                      isActive={selectedIds.length > 0}
                      disabled={selectedIds.length === 0}
                      marginLeft={20}
                      onPress={() => selectedIds.length > 0 && setShowCancelModal(true)}
                    >
                      <CancelIcon /> {t('admin.reservation.overview.cancelReservation')}
                    </Link>
                  </>
                )}
              </Table>
            )}
          </ListWrapper>
        </ScrollView>

        {totalItems && !isLoading ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>

      <Dialog show={showDeleteModal} setShow={setShowDeleteModal}>
        <Title hasMarginBottom>{t("admin.reservation.delete.warning")}</Title>
        <OverviewDeleteModalButtonWrapper>
          <Button onPress={() => setShowDeleteModal(false)}>
            <ButtonText>{t("general.cancel")}</ButtonText>
          </Button>
          <ButtonWrapper>
            <Button
              backgroundHoverColor={color.primary}
              backgroundColor={color.primaryLight}
              onPress={deleteFromList}
              color={color.primary}
              textHoverColor={color.primaryLight}
            >
              {t("general.yes")}
            </Button>
          </ButtonWrapper>
        </OverviewDeleteModalButtonWrapper>
      </Dialog>

      <Dialog
        show={showDeleteMultipleModal}
        setShow={setShowDeleteMultipleModal}
      >
        <Title hasMarginBottom>
          {selectedIds.length === 1 && t("admin.reservation.delete.warning")}
          {selectedIds.length !== 1 &&
            t("admin.reservation.delete.multipleWarning")}
        </Title>
        <OverviewDeleteModalButtonWrapper>
          <Button onPress={() => setShowDeleteMultipleModal(false)}>
            <ButtonText>{t("general.cancel")}</ButtonText>
          </Button>
          <ButtonWrapper>
            <Button
              backgroundHoverColor={color.primary}
              backgroundColor={color.primaryLight}
              onPress={deleteSelected}
              color={color.primary}
              textHoverColor={color.primaryLight}
            >
              {t("general.yes")}
            </Button>
          </ButtonWrapper>
        </OverviewDeleteModalButtonWrapper>
      </Dialog>

      <Dialog show={showCancelModal} setShow={setShowCancelModal}>
        <Title hasMarginBottom>
          {canCancelAllSelectedIds &&
            selectedIds.length === 1 &&
            t("admin.reservation.cancel.warning")}
          {canCancelAllSelectedIds &&
            selectedIds.length !== 1 &&
            t("admin.reservation.cancel.multipleWarning")}
          {!canCancelAllSelectedIds &&
            t("admin.reservation.cancel.cannotCancel")}
        </Title>
        {canCancelAllSelectedIds && (
          <Input
            value={cancelReason}
            onChangeText={setCancelReason}
            placeholder={t("admin.reservation.cancel.cancelReason")}
          />
        )}
        {!canCancelAllSelectedIds && (
          <ExtraSmallTextGood>
            {t("admin.reservation.cancel.cannotCancelDescription")}
          </ExtraSmallTextGood>
        )}
        <OverviewDeleteModalButtonWrapper>
          <Button onPress={() => setShowCancelModal(false)}>
            <ButtonText>{t("general.cancelSelection")}</ButtonText>
          </Button>
          {canCancelAllSelectedIds && (
            <ButtonWrapper>
              <Button
                backgroundHoverColor={color.primary}
                backgroundColor={color.primaryLight}
                onPress={cancelSelected}
                color={color.primary}
                textHoverColor={color.primaryLight}
              >
                {t("general.confirmCancelation")}
              </Button>
            </ButtonWrapper>
          )}
        </OverviewDeleteModalButtonWrapper>
      </Dialog>

      <ReservationsFilter
        showFilter={showFilterModal}
        setShowFilter={setShowFilterModal}
        onFilter={(filter, size) => {
          setNumberOfFilters(size);
          setFilter(filter);
        }}
      />
    </>
  );
};

export default ReservationOverview;
