module["exports"] = [
  "Astrid",
  "Anna",
  "Alice",
  "Amanda",
  "Ann",
  "Agneta",
  "Anette",
  "Anneli",
  "Alexandra",
  "Agnes",
  "Anne",
  "Alva",	
  "Alma",	
  "Angelica",	
  "Ann-Marie",
  "Maria",
  "Margareta",
  "Elisabeth",
  "Eva",
  "Birgitta",
  "Kristina",
  "Karin",
  "Elisabet",
  "Marie",
  "Lotta",
  "Ronja",
  "Veronica"
];