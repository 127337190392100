import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Company, OrderDirection } from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button, {
  ButtonsWrapper,
  ButtonWrapper,
} from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import LoadOverlay from "../../../components/LoadOverlay";
import {
  getFacilityPages,
  getOfficePages,
  getSaltoPage,
} from "../../../components/Navbar/AdminNavBarContent";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import Toggle from "../../../components/Toggle";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import useQuery from "../../../hooks/useQuery";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { OverviewHeader } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import { color } from "../../../styles/theme";
import Title from "../../../styles/Title";
import { ArrayElement, ThenArg } from "../../../types";

const columnSizes = [2, 1, 1, 1, 3];

interface Props extends RouterProps {
  isCleaningMap?: boolean;
}

const MapOverview: FC<Props> = ({ isCleaningMap }) => {
  //console.log("isCleaningMap", isCleaningMap);
  const getTableHeaders = useCallback((): HeaderItem[] => [
    createHeaderItem(t("admin.map.overview.name"), false),
    createHeaderItem(t("admin.map.overview.location"), false),
    createHeaderItem(t("admin.map.overview.floor"), false),
    createHeaderItem(
      isCleaningMap ? "" : t("admin.map.overview.visible"),
      false
    ),
    createHeaderItem("", false),
    //createHeaderItem("", false),
  ], [isCleaningMap]);
  const { userState } = useAuth();
  const client = useClient();
  const history = useHistory();
  const query = useQuery();

  type MapItem = ArrayElement<
    ThenArg<ReturnType<typeof client.listMaps>>["data"]["result"]["items"]
  >;

  const { handleRequest, isLoading, error, dismissError } = useApi();

  const userCompany = userState?.companyIds[0];
  const [data, setData] = useState<MapItem[]>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [company, setCompany] = useState<Company | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(
    query.get("success") === "map-saved"
  );

  //console.log(isLoading, totalItems, 'is loading');


  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<MapItem>();

  const fetchListItems = useCallback(async () => {
    if (userCompany) {
      return await handleRequest(
        client.listMaps(userCompany, takeSize, skipParam)
      );
    }
  }, [userCompany, handleRequest, client, skipParam]);

  const fetchCompany = useCallback(async () => {
    if (userCompany) {
      return await handleRequest(
        client.getCompany(userCompany)
      );
    }
  }, [userCompany, handleRequest, client]);


  const initialize = useCallback(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setData(result.data.result.items);
      setTotalItems(result.data.result.total);
    });

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { company} = result.data.result;
      if (company) {
        setCompany(company as Company);
      }
    });
    
  }, [fetchCompany, fetchListItems]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const setMapVisibility = useCallback(
    async (newValue, id) => {
      await handleRequest(
        client.updateMap(id, {
          visible: newValue,
        }),
        false
      );
      await initialize();
      //console.log(`new value: ${newValue}, id: ${id}`);
    },
    [client, handleRequest, initialize]
  );

  const move = useCallback(
    async (map: MapItem, direction: OrderDirection) => {
      await handleRequest(
        client.changeMapOrder(map.id, {
          direction,
        }),
        false
      );
      await initialize();
    },
    [client, handleRequest, initialize]
  );

  const renderTablePages = useCallback(() => {
    if (company?.saltoUsername && company?.saltoPassword) {
    return [...getOfficePages(), ...getSaltoPage()];
    }    
    return getOfficePages();

  },[company]);

  const createTableData = useCallback(
    (items: MapItem[]) => {
      const isLastPage = takeSize + skipParam + 1 > totalItems;
      const tableData: TableContent[] = items.map(
        (
          { name, id, location, floor, visible, _operations },
          index,
          currentArray
        ) => {
          const data: ReactElement[] = [
            <ExtraSmallText key="name" mediumWeight>
              {name}
            </ExtraSmallText>,
            <ExtraSmallText key="location">{location.name}</ExtraSmallText>,
            <ExtraSmallText key="floor">{floor}</ExtraSmallText>,
            <>
              {!isCleaningMap && (
                <Toggle
                  key="visible"
                  toggleValue={Boolean(visible)}
                  onChange={(newValue) => setMapVisibility(newValue, id)}
                />
              )}
            </>,
            <ButtonsWrapper key="buttons">
              {!isCleaningMap && (
                <ButtonWrapper>
                  <Button
                    backgroundColor={color.white}
                    backgroundHoverColor={color.secondary}
                    borderColor={color.secondary}
                    textHoverColor={color.white}
                    onPress={() => history.push(`/admin/map-detail/${id}`)}
                  >
                    {t("admin.map.overview.plotButton")}
                  </Button>
                </ButtonWrapper>
              )}
              {isCleaningMap && (
                <ButtonWrapper>
                  <Button
                    backgroundColor={color.white}
                    backgroundHoverColor={color.secondary}
                    borderColor={color.secondary}
                    textHoverColor={color.white}
                    onPress={() =>
                      history.push(`/admin/cleaning-map-detail/${id}`)
                    }
                  >
                    {t("admin.map.overview.cleanButton")}
                  </Button>
                </ButtonWrapper>
              )}
              {!isCleaningMap &&
                totalItems !== 1 &&
                ((skipParam === 0 && index !== 0) || skipParam !== 0) && (
                  <ButtonWrapper>
                    <Button
                      backgroundColor={color.white}
                      backgroundHoverColor={color.secondary}
                      borderColor={color.secondary}
                      textHoverColor={color.white}
                      onPress={() => move(items[index], OrderDirection.Up)}
                    >
                      {t("general.up")}
                    </Button>
                  </ButtonWrapper>
                )}
              {!isCleaningMap &&
                totalItems !== 1 &&
                (!isLastPage ||
                  (isLastPage && index !== currentArray.length - 1)) && (
                  <ButtonWrapper>
                    <Button
                      backgroundColor={color.white}
                      backgroundHoverColor={color.secondary}
                      borderColor={color.secondary}
                      textHoverColor={color.white}
                      onPress={() => move(items[index], OrderDirection.Down)}
                    >
                      {t("general.down")}
                    </Button>
                  </ButtonWrapper>
                )}
            </ButtonsWrapper>,
          ];
          return {
            data,
            id,
            canDelete: _operations?.canDelete,
            cannotDeleteDescription: _operations?.cannotDeleteDescription,
            canEdit: true,
            hideDeleteButton: isCleaningMap,
            hideEditButton: isCleaningMap,
          };
        }
      );
      return tableData;
    },
    [skipParam, totalItems, isCleaningMap, setMapVisibility, history, move]
  );

  const setListItems = useCallback(
    (items: MapItem[]) => {
      if (items) {
        setTableData(createTableData(items));
      }
    },
    [setTableData, createTableData]
  );

  useEffect(() => {
    setListItems(data);
  }, [data, setListItems]);

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    if (!itemSelected) {
      return;
    }
    setShowDeleteModal(false);

    const response = await handleRequest(client.deleteMap(itemSelected.id));

    if (response && response.status === 204) {
      await fetchListItems().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        setListItems(result.data.result.items);
      });

      setShowDeleteMessage(true);
    }
  }, [
    itemSelected,
    setShowDeleteModal,
    handleRequest,
    client,
    fetchListItems,
    setListItems,
  ]);

  return (
    <>
      {isLoading ? <LoadOverlay /> :
      <Container>
        <OverviewHeader>
          <Title>{t("admin.map.overview.title")}</Title>
          {!isCleaningMap && (
            <Button onPress={() => history.push("/admin/map-overview/create")}>
              {t("admin.map.overview.addButton")}
            </Button>
          )}

        </OverviewHeader>
        {!isCleaningMap && <PageSelector pages={renderTablePages()}/>}
        {isCleaningMap && <PageSelector pages={getFacilityPages()} />}
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        )}
        {showSavedMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowSavedMessage(false)}
          >
            {t("general.savedSuccess")}
          </Notification>
        )}
        {!isLoading && (
          <Table
            tableId="map_overview"
            headerItems={getTableHeaders()}
            tableContent={tableData}
            columnSizes={columnSizes}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/map-overview/update/${id}`)
            }
            onDeleteItem={openDeleteModal}
          />
        )}
        {(totalItems && !isLoading ) && (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        )}
      </Container>
      }

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.map.delete.warning", {
          name: itemSelected?.name,
        })}
      />
    </>
  );
};

export default MapOverview;
