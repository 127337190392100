import React, { FC } from "react";

import styled from "styled-components/native";

import { ExtraSmallText } from "../../styles/Text";
import { borderRadius, color, spacing } from "../../styles/theme";

const pillLineHeight = "20px";
const smallPillLineHeight = "16px";

interface PillTextProps {
  textColor?: string;
}
const PillText = styled(ExtraSmallText)<PillTextProps>`
  line-height: ${pillLineHeight};
  ${({ textColor }) => `color: ${textColor ? textColor : color.secondary};`}
`;

interface StyledPillProps {
  backgroundColor?: string;
}

const StyledPill = styled.View<StyledPillProps>`
  padding: ${spacing.extraSmall} ${spacing.medium};
  border-radius: ${borderRadius.full};
  ${({ backgroundColor }) =>
    `background-color: ${
      backgroundColor ? backgroundColor : color.secondaryLight
    };`}
`;

interface PillProps {
  textColor?: string;
  backgroundColor?: string;
}
const Pill: FC<PillProps> = ({ children, textColor, backgroundColor }) => {
  return (
    <StyledPill backgroundColor={backgroundColor}>
      <PillText mediumWeight textColor={textColor}>
        {children}
      </PillText>
    </StyledPill>
  );
};

export default Pill;
