module["exports"] = [
  "Դավիթ",
  "Նարեկ",
  "Ալեքս",
  "Տիգրան",
  "Հայկ",
  "Գոռ",
  "Արթուր",
  "Արմեն",
  "Մարկ",
  "Էրիկ",
  "Արամ",
  "Սամվել",
  "Արման",
  "Ալեն",
  "Աշոտ",
  "Արեն",
  "Գագիկ",
  "Դանիել",
  "Արեգ",
  "Ռոբերտ",
  "Արտյոմ",
  "Արսեն",
  "Միքայել",
  "Սարգիս",
  "Հովհաննես",
  "Վահե",
  "Կարեն",
  "Գևորգ",
  "Լևոն",
  "Վարդան",
  "Ռուբեն",
  "Սուրեն",
  "Հակոբ",
  "Մհեր",
  "Անդրանիկ",
  "Ավետ",
  "Գրիգոր",
  "Ռաֆայել",
  "Ալեքսանդր",
  "Մոնթե",
  "Հարություն",
  "Էդուարդ",
  "Ալբերտ",
  "Գարիկ",
  "Համլետ"
];