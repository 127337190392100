var name = {};
module['exports'] = name;
name.first_name = require("./first_name");
name.last_name = require("./last_name");
name.male_first_name = require("./male_first_name");
name.female_first_name = require("./female_first_name");
name.prefix = require("./prefix");
name.suffix = require("./suffix");
name.title = require("./title");
name.name = require("./name");
