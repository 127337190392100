import AsyncStorage from "@react-native-async-storage/async-storage";

export enum storageKeys {
  refreshToken = "refreshToken",
  impersonationRefreshToken = "impersonationRefreshToken",
  userLanguage = "userLanguage",
  tableConfig = "tableConfig",
}

export const saveInStorage = async (
  key: string,
  value: string
): Promise<boolean> => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

export const getFromStorage = async (key: string) => {
  try {
    return await AsyncStorage.getItem(key);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const removeFromStorage = async (key: string): Promise<boolean> => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};
