var animal = {};
module['exports'] = animal;
animal.dog = require("./dog");
animal.cat = require("./cat");
animal.snake = require("./snake");
animal.horse = require("./horse");
animal.cetacean = require("./cetacean");
animal.rabbit = require("./rabbit");
animal.insect = require("./insect");
animal.bear = require("./bear");
animal.lion = require("./lion");
animal.cow = require("./cow");
animal.bird = require("./bird");
animal.fish = require("./fish");
animal.crocodilia = require("./crocodilia");
animal.type = require("./type");