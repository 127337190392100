import React, { FC } from "react";
import { VictoryPie } from "./chart";
import { color } from "../../styles/theme";
import styled from "styled-components/native";
import { Svg } from "react-native-svg";

const PieWidth = 200;

const PieWrapper = styled.View`
  width: 100%;
  display: flex;
  align-items: center;
`;

const PieWrapperInner = styled.View`
  max-width: 300px;
  padding-left: 20px;
  padding-right: 20px;
`;

interface PieProps {
  data: any[];
}

const PadAngle: FC<PieProps> = ({ data }) => {
  return (
    <PieWrapper>
      <PieWrapperInner>
        <VictoryPie
          colorScale={[color.primary, color.secondary, color.tertiary, color.quaternary, color.reservationHome ]}
          padAngle={0}
          innerRadius={115}
          labels={() => null}
          data={data.slice(0, 5)} />
      </PieWrapperInner>
    </PieWrapper>
  );
};

export default PadAngle;
