import React, { FC, useEffect, useState } from "react";
import { SeatOnMap } from "./index";
import {
  borderRadius,
  boxShadow,
  color,
  font,
  Overflow,
  Position,
  spacing,
  text,
} from "../../styles/theme";
import styled, { css } from "styled-components/native";
import workspace from "./mapIcons/workspace.png";
import lunch from "./mapIcons/lunch.png";
import meetingRoom from "./mapIcons/meetingRoom.png";
import { t } from "../../i18n";
import Select, { SelectItemInterface } from "../Select";
import * as faker from "faker";
import Toggle from "../Toggle";
import Link from "../Link";
import AvailabilityInput, { AvailabilityInterface } from "./AvailabilityInput";
import {
  AmountIndicatorDark,
  indicatorSize,
} from "../../styles/AmountIndicator";
import Title from "../../styles/Title/title";
import Text from "../../styles/Text/text";
import Input from "../../styles/Input/input";
import {
  SeatTag,
  SeatType,
  SeatUserGroup,
  UserGroup,
} from "../../../bookmydesk-api-sdk-typescript-axios";
import { SmallText } from "../../styles/Text";
import { View, ViewStyle } from "react-native";
import { convertObjectToStyle } from "../../utils";

interface CallbackInterface {
  (seat: SeatOnMap): void;
}

interface OnChangeCallbackInterface {
  (seat: SeatOnMap, nextItemCallback: (nextItem: number) => void): void;
}

interface Style extends Omit<ViewStyle, "overflow" | "position"> {
  overflow?: Overflow;
  position?: Position;
}

const formWidth = "570px";

type PositionType = "left" | "right";

interface OverlayProps {
  positionType?: PositionType;
}

const Overlay = styled.View<OverlayProps & Style>`
  width: ${formWidth};
  min-height: calc(
    100% + ${spacing.large} + ${spacing.medium} + ${spacing.small}
  );
  background-color: ${color.white};
  position: ${Position.absolute};
  height: 100vh;
  z-index: 99;
  top: 0;
  border-top-right-radius: ${borderRadius.medium};
  padding: ${spacing.large} ${spacing.medium} ${spacing.large} ${spacing.larger};
  ${boxShadow.small}
  ${({ positionType }) =>
    positionType &&
    css`
      ${positionType}: 0
    `};

  ${(props) =>
    convertObjectToStyle(props, [
      "position",
      "top",
      "left",
      "height",
      "minHeight",
      "overflow",
      "borderTopLeftRadius",
      "borderTopRightRadius",
    ])}
`;

const SelectWrapper = styled.View`
  display: flex;
  flex-direction: column-reverse;
  z-index: 3;
`;

const FormTitle = styled(Title)`
  font-size: ${text.large.size};
  line-height: ${text.medium.lineHeight};
`;

const FormClose = styled(FormTitle)`
  height: 0;
  margin-top: -${spacing.extraSmall};
`;

const FormCloseWrapper = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
`;

const FormTitleWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.medium};
`;

const FormTitleWrapperRight = styled.View`
  align-items: center;
  flex-direction: row;
  position: relative;
  padding-right: ${spacing.large};
`;

const FormLabel = styled(Text)`
  font-family: ${font.defaultMedium};
  margin-right: ${spacing.small};
`;

export interface TypeButtonProps {
  last?: boolean;
}

export const TypeButton = styled.TouchableOpacity<TypeButtonProps>`
  margin: 0 ${spacing.medium} ${spacing.medium} 0;

  ${({ last }) =>
    last &&
    css`
      margin: 0 0 ${spacing.medium} 0;
    `};
`;

export interface SeatTypeWithImage {
  name: SeatType;
  image: string;
}

export const seatTypes: Array<SeatTypeWithImage> = [
  {
    name: SeatType.Desk,
    image: workspace,
  },
  {
    name: SeatType.Lunch,
    image: lunch,
  },
  {
    name: SeatType.Meeting,
    image: meetingRoom,
  },
];

export const seatDropdownTypes: SelectItemInterface[] = seatTypes.map(
  ({ name }: SeatTypeWithImage) => ({
    label: t(`admin.map.seatType.${name}`),
    value: name,
  })
);

const StyledInput = styled(Input)`
  background-color: ${color.white};
`;

const NotificationTitleWrapper = styled.View`
  flex-direction: row;
  height: ${indicatorSize};
  align-items: center;
  margin: ${spacing.medium} 0 ${spacing.small};
`;

const ItemWrapper = styled.View<ViewStyle>`
  flex-direction: column;
  flex: 1;
  ${(props) => convertObjectToStyle(props, ["marginLeft", "marginRight"])};
`;

const ItemsWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

interface SeatForm extends Style {
  seat?: SeatOnMap;
  onSubmit: CallbackInterface;
  onChange: OnChangeCallbackInterface;
  closeForm: () => void;
  seatsAmount: number;
  mapId: string;
  seatTags: SeatTag[];
  userGroups: UserGroup[];
  positionType?: PositionType;
  isAdding?: boolean;
  getMSRooms?: () => Promise<any[]>;
  getSeatInfo?: (key: string, value: string | Array<unknown> | boolean | number) => void;
  onAddTag?: () => void;
}

const SeatForm: FC<SeatForm> = ({
  seat,
  onSubmit,
  onChange,
  closeForm,
  seatsAmount,
  mapId,
  seatTags,
  barcodes,
  userGroups,
  positionType = "left",
  getMSRooms,
  getSeatInfo,
  isAdding,
  onAddTag,
  ...style
}) => {
  const isEditing = Boolean(seat);
  const tagsData = seatTags.map((seatTag) => ({
    label: seatTag.label,
    value: seatTag.id,
  }));

  function getCurrentMsRoom(seat?: SeatOnMap) {
    return seat?.seatExternals?.find(({ type }) => type === "microsoft");
  }

  const [name, setName] = useState<string>(
    seat?.name || t("admin.map.seat.defaultName", { number: seatsAmount })
  );
  const [, setHasChangedName] = useState<boolean>(false);
  const [type, setType] = useState<SeatType>(seat?.seatType || SeatType.Desk);
  const [msRoom, setMsRoom] = useState<string>(
    getCurrentMsRoom(seat)?.identifier ?? ""
  );
  const [isActive, setIsActive] = useState<boolean>(
    !seat || typeof seat.isActive === "undefined" ? true : seat.isActive
  );
  const [availability, setAvailability] = useState<AvailabilityInterface[]>(
    seat?.availability || []
  );
  const [notFutureReservable, setNotFutureReservable] = useState(
    seat?.notFutureReservable
  );

  const [isZone, setIsZone] = useState<boolean>(seat?.isZone || false);
  const [capacity, setCapacity] = useState<number>(seat?.reservationCapacity || 1);

  const [selectedSeatTags, setSelectedSeatTags] = useState<
    SelectItemInterface[]
  >(
    seat?.seatTags.map((seatTag) => ({
      label: seatTag.label,
      value: seatTag.id,
      active: true,
    })) || []
  );

  const [barcode, setBarcode] = useState<string>(
    !seat?.barcodes[0] ? "" : seat?.barcodes[0].code
  );
  const [barcodesArray, setBarcodesArray] = useState([]);

  // selected seat tags : selectedSeatTags, tagsData
  const [selectedUserGroups, setSelectedUserGroups] = useState<SeatUserGroup[]>(
    (seat?.userGroups || []).map((item) => ({
      ...item,
      key: faker.datatype.uuid(),
      conditions: (item.conditions || []).map((condition) => ({
        ...condition,
        key: faker.datatype.uuid(),
      })),
    }))
  );
  // high selectedUserGroups : selectedUserGroups
  const [tagsDropdownData, setTagsDropdownData] =
    useState<SelectItemInterface[]>(tagsData);
  const [typeDropdownData, setTypeDropdownData] =
    useState<SelectItemInterface[]>(seatDropdownTypes);
  const [msRooms, setMsRooms] = useState<SelectItemInterface<string>[]>([]);

  const getMaxCapacity = (isAZone: boolean, seatType: SeatType) => {
    if (!isAZone) return 1;
    if (seatType === SeatType.Lunch) {
      return 1;
    }
    return Number.MAX_SAFE_INTEGER;
  }

  const getActiveItems = (
    fullArray: SelectItemInterface[],
    activeIds: string[]
  ) =>
    fullArray.map((item) => ({
      ...item,
      isActive: activeIds.includes(item.value),
    }));

  const resetActiveItems = (fullArray: SelectItemInterface[]) =>
    fullArray.map((item) => {
      item.isActive = false;
      return item;
    });

  useEffect(() => {
    if (!getMSRooms || type !== SeatType.Meeting) return setMsRooms([]);
    let mounted = true;

    getMSRooms()
      .then((rooms) => {
        if (mounted) {
          setMsRooms(
            rooms.map(({ id, displayName }) => ({
              value: id,
              label: displayName,
              isActive: id === getCurrentMsRoom(seat)?.identifier,
            }))
          );
        }
      })
      .catch(() => {
        if (mounted) setMsRooms([]);
      });

    return () => {
      mounted = false;
    };
  }, [!!getMSRooms, type]);

  useEffect(() => {
    if (isEditing && seat) {
      setTagsDropdownData([
        ...getActiveItems(
          tagsData,
          seat.seatTags.map((seatTag) => seatTag.id)
        ),
      ]);

      if (seat.name) {
        setName(seat.name);
      }
      setTypeDropdownData(
        seatDropdownTypes.map((item) => ({
          ...item,
          isActive: item.value === type,
        }))
      );

      const msRoom = getCurrentMsRoom(seat);
      if (msRoom) {
        setMsRooms(
          msRooms.map((room) => ({
            ...room,
            isActive: room.value === msRoom.identifier,
          }))
        );
      }
    } else {
      if (!seat?.name) {
        setName(t("admin.map.seat.defaultName", { number: seatsAmount }));
      }

      setTagsDropdownData([...resetActiveItems(tagsData)]);
      setTypeDropdownData(
        seatDropdownTypes.map((item) => ({
          ...item,
          isActive: item.value === "desk",
        }))
      );
    }
    // eslint-disable-next-line
  }, [seat]);

  const id = seat?.id || faker.datatype.uuid();

  const createCallback = (nextSeat: number) => {
    setName(t("admin.map.seat.defaultName", { number: nextSeat }));
  };

  const updateNotFutureReservable = (value: boolean) => {
    setNotFutureReservable(value);
    if (getSeatInfo) {
      getSeatInfo("notFutureReservable", value);
    }
  };

  const updateSeatsTags = (value: SelectItemInterface[]) => {
    setSelectedSeatTags(value);
    if (getSeatInfo) {
      getSeatInfo("seatTags", value);
    }
  };

  const updateSeatType = (newValue: Array<{ value: SeatType }>) => {
    setType(newValue[0].value!);
    setCapacity(Math.min(capacity, getMaxCapacity(isZone, newValue[0].value!)));
    if (getSeatInfo) {
      getSeatInfo("seatType", newValue[0].value!);
    }
  };

  const updateCapacity = (value: number) => {
    setCapacity(Math.min(value, getMaxCapacity(isZone, type)));
    if (getSeatInfo) {
      getSeatInfo("reservationCapacity", value);
    }
  };

  const updateIsZone = (value: boolean) => {
    setIsZone(value);
    if (!value) {
      updateCapacity(1);
    }
    if (getSeatInfo) {
      getSeatInfo("isZone", value);
    }
  }

  const updateMsRoom = (newValue: Array<{ value: SeatType }>) => {
    setMsRoom(newValue[0].value!);
    if (getSeatInfo) {
      getSeatInfo("microsoftId", newValue[0].value!);
    }
  };

  const updateName = (value: string) => {
    setHasChangedName(true);
    setName(value);
    if (getSeatInfo) {
      getSeatInfo("name", value);
    }
  };

  const updateBarcode = (value: string) => {
    setBarcode(value.toUpperCase());
  };

  const updateIsActive = (status: boolean) => {
    setIsActive(status);
    if (getSeatInfo) {
      getSeatInfo("isActive", status);
    }
  };

  useEffect(() => {
    const item: SeatOnMap = {
      mapId,
      barcodes: !barcode ? [] : [{id: id, code: barcode}],
      coordX: isZone ? null : seat?.coordX || 0,
      coordY: isZone ? null : seat?.coordY || 0,
      seatType: type,
      name,
      isActive,
      seatExternals: [
        ...(seat?.seatExternals?.filter(({ type }) => type !== "microsoft") ??
          []),
        (msRoom ? { identifier: msRoom, type: "microsoft" } : undefined)!,
      ].filter(Boolean),
      seatTags: seatTags.filter((seatTag) =>
        selectedSeatTags.map((tag) => tag.value).includes(seatTag.id)
      ),
      userGroups: selectedUserGroups,
      id,
      isZone,
      reservationCapacity: capacity,
      zoneCoordinates: isZone ? seat?.zoneCoordinates || `${seat?.coordX || 0},${seat?.coordY || 0}` : undefined,
      morningBlocked: [],
      afternoonBlocked: [],
      availability,
      notFutureReservable,
    };

    onChange(item, createCallback);
  }, [
    isActive,
    mapId,
    type,
    msRoom,
    name,
    selectedSeatTags,
    barcodes,
    userGroups,
    selectedUserGroups,
    id,
    onChange,
    availability,
    seatTags,
    notFutureReservable,
    seat?.coordX,
    seat?.coordY,
    isZone,
    seat?.zoneCoordinates,
  ]);

  useEffect(() => {
    const item: SeatOnMap = {
      ...seat,
      mapId,
      coordX: isZone ? null : seat?.coordX || 0,
      coordY: isZone ? null : seat?.coordY || 0,
      seatType: type,
      name,
      isActive,
      seatExternals: [
        ...(seat?.seatExternals?.filter(({ type }) => type !== "microsoft") ??
          []),
        (msRoom ? { identifier: msRoom, type: "microsoft" } : undefined)!,
      ].filter(Boolean),
      seatTags: seatTags.filter((seatTag) =>
        selectedSeatTags.map((tag) => tag.value).includes(seatTag.id)
      ),
      userGroups: selectedUserGroups,
      barcodes: !barcode ? [] : [{id: id, code: barcode}],
      id,
      isZone,
      reservationCapacity: capacity,
      zoneCoordinates: isZone ? seat?.zoneCoordinates || `${seat?.coordX || 0},${seat?.coordY || 0}` : undefined,
      morningBlocked: [],
      afternoonBlocked: [],
      availability,
      notFutureReservable,
    };
    onSubmit(item);
  }, [
    mapId,
    type,
    msRoom,
    name,
    selectedSeatTags,
    barcodes,
    userGroups,
    selectedUserGroups,
    id,
    availability,
    onSubmit,
    seat,
    seatTags,
    isActive,
    notFutureReservable,
  ]);

  const canDeactivate =
    !seat || seat?.id.indexOf("new") === 0 || seat._operations?.canDeactivate;

  const hasUserGroups = userGroups.length > 0;

  return (
    <Overlay positionType={positionType} {...style}>
      <FormTitleWrapper>
        <FormTitle>
          {isAdding ? "Add" : "Edit"} &quot;{name}&quot;
        </FormTitle>
        <FormTitleWrapperRight>
          {!canDeactivate && (
            <SmallText>{t("admin.map.seat.cannotDeactivate")}</SmallText>
          )}
          {canDeactivate && (
            <Toggle
              onChange={updateIsActive}
              toggleValue={isActive}
              label={
                isActive
                  ? t("admin.map.seat.active")
                  : t("admin.map.seat.inactive")
              }
            />
          )}
          <FormCloseWrapper onPress={closeForm}>
            <FormClose>⨯</FormClose>
          </FormCloseWrapper>
        </FormTitleWrapperRight>
      </FormTitleWrapper>
      <SelectWrapper>
        {/* This is in reversed order. No idea why but this should be refactored */}
        {hasUserGroups && (
          <Link
            isActive
            onPress={() => {
              setSelectedUserGroups((oldValue) => {
                const newData = {
                  weekday: 1,
                  timeFrom: "08:00",
                  timeTo: "18:00",
                  key: faker.datatype.uuid(),
                };
                const userGroupId = userGroups[0].id;
                const userGroupIndex = oldValue.findIndex(
                  (selectedUserGroup) => selectedUserGroup.id === userGroupId
                );

                // push data
                if (userGroupIndex !== -1) {
                  oldValue[userGroupIndex].conditions?.push(newData);
                  return [...oldValue];
                }

                return [
                  ...oldValue,
                  {
                    id: userGroupId,
                    conditions: [newData],
                    key: faker.datatype.uuid(),
                  },
                ];
              });
            }}
          >
            {t("admin.map.seat.addTime")}
          </Link>
        )}
        {selectedUserGroups
          .sort((a, b) => {
            const aLabel =
              userGroups.find((userGroup) => userGroup.id === a.id)?.label ||
              "";
            const bLabel =
              userGroups.find((userGroup) => userGroup.id === b.id)?.label ||
              "";
            return aLabel > bLabel ? 1 : -1;
          })
          .map((userGroup, userGroupIndex) => (
            <View key={userGroup.key}>
              {(userGroup.conditions || [])
                .sort((a, b) => {
                  if (a.weekday !== b.weekday) {
                    return a.weekday > b.weekday ? 1 : -1;
                  }
                  return a.timeFrom > b.timeFrom ? 1 : -1;
                })
                .map((condition, conditionIndex) => (
                  <View key={condition.key}>
                    {/* <Text>{JSON.stringify(condition)}</Text> */}
                    <AvailabilityInput
                      onChange={(data) => {
                        setSelectedUserGroups((oldValue) => {
                          const newValue = oldValue;
                          let newUserGroupIndex = newValue.findIndex(
                            (item) => item.id === data.userGroupId
                          );
                          let push = false;
                          if (data.userGroupId !== userGroup.id) {
                            push = true;
                            newValue[userGroupIndex].conditions = newValue[
                              userGroupIndex
                            ].conditions?.filter(
                              (item, index) => index !== conditionIndex
                            );
                          }

                          if (!newValue[newUserGroupIndex]) {
                            newUserGroupIndex = newValue.length;
                            newValue[newUserGroupIndex] = {
                              id: data.userGroupId,
                              conditions: [],
                            };
                          }

                          if (push) {
                            newValue[newUserGroupIndex].conditions.push(
                              condition
                            );
                          } else {
                            newValue[newUserGroupIndex].conditions[
                              conditionIndex
                            ] = { ...data.condition, key: condition.key };
                          }
                          return newValue.filter(
                            (item) => (item.conditions?.length || 0) > 0
                          );
                        });
                      }}
                      onDelete={() =>
                        setSelectedUserGroups((oldValue) => {
                          const newValue = oldValue;
                          newValue[userGroupIndex].conditions = newValue[
                            userGroupIndex
                          ].conditions?.filter(
                            (item, index) => index !== conditionIndex
                          );
                          return newValue.filter(
                            (item) => (item.conditions?.length || 0) > 0
                          );
                        })
                      }
                      userGroupId={userGroup.id}
                      seatUserGroupCondition={condition}
                      userGroups={userGroups}
                    />
                  </View>
                ))}
            </View>
          ))
          .reverse()}
        {hasUserGroups && (
          <NotificationTitleWrapper>
            <FormLabel> {t("admin.map.seat.NotAvailable")}:</FormLabel>
          </NotificationTitleWrapper>
        )}
        <Toggle
          onChange={updateNotFutureReservable}
          toggleValue={!!notFutureReservable}
        />
        <NotificationTitleWrapper>
          <FormLabel> {t("admin.map.seat.notFutureReservable")}:</FormLabel>
        </NotificationTitleWrapper>

        {isZone && (
        <ItemsWrapper>
          <ItemWrapper>
              <NotificationTitleWrapper>
                <FormLabel>{t("admin.map.seat.capacity")}:</FormLabel>
              </NotificationTitleWrapper>
              <StyledInput
                placeholder={t("admin.map.seat.capacity")}
                onChangeText={updateCapacity}
                value={capacity.toString()}
                type='number'
                disabled={isZone ? type !== SeatType.Desk : true}
                style={{ backgroundColor: isZone ? type !== SeatType.Desk ? color.greyLightest : color.white : color.greyLightest }}
              />
          </ItemWrapper>
        </ItemsWrapper>
        )}
        {isZone && (
        <Toggle
          onChange={updateIsZone}
          toggleValue={!!isZone}
        />
        )}
        {isZone && (
        <NotificationTitleWrapper>
          <FormLabel> {t("admin.map.seat.isZone")}:</FormLabel>
        </NotificationTitleWrapper>
        )}
        {!!msRooms.length && (
          <ItemsWrapper>
            <ItemWrapper>
              <NotificationTitleWrapper>
                <FormLabel>{t("admin.map.seat.ms_room")}:</FormLabel>
              </NotificationTitleWrapper>
              <Select required items={msRooms} onChange={updateMsRoom} />
            </ItemWrapper>
          </ItemsWrapper>
        )}
        <ItemsWrapper>
        {isAdding && (
          <ItemWrapper marginRight={5}>
            <NotificationTitleWrapper>
              <FormLabel> {t("admin.map.seat.type")}:</FormLabel>
            </NotificationTitleWrapper>
            <Select
              required
              items={typeDropdownData}
              onChange={updateSeatType}
            />
          </ItemWrapper>
        )}
          <ItemWrapper marginLeft={5}>
            <NotificationTitleWrapper>
              <FormLabel>{t("admin.map.seat.tag")}:</FormLabel>
              <AmountIndicatorDark>
                {selectedSeatTags.length}
              </AmountIndicatorDark>
              <Link
                underline
                marginLeft="auto"
                color={color.secondary}
                textStyle={{ fontSize: 10 }}
                onPress={onAddTag}
              >
                {t("admin.seatTag.overview.addButton")}
              </Link>
            </NotificationTitleWrapper>
            <Select
              items={tagsDropdownData}
              onChange={updateSeatsTags}
              placeholder={t("admin.map.seat.tag")}
              isMultiSelect={true}
            />
          </ItemWrapper>
        </ItemsWrapper>
        <ItemsWrapper>
          <ItemWrapper marginRight={10}>
            <NotificationTitleWrapper>
              <FormLabel>{t("admin.map.seat.name")}:</FormLabel>
            </NotificationTitleWrapper>
            <StyledInput
              placeholder={t("admin.map.seat.name")}
              onChangeText={updateName}
              value={name}
            />
          </ItemWrapper>
          <ItemWrapper>
            <NotificationTitleWrapper>
              <FormLabel
              >{t("admin.map.seat.barcode")}:</FormLabel>
            </NotificationTitleWrapper>
            <StyledInput
              maxLength={4}
              placeholder={t("admin.map.seat.barcode")}
              onChangeText={updateBarcode}
              value={barcode}
              disabled={false}
            />
          </ItemWrapper>
        </ItemsWrapper>
      </SelectWrapper>
    </Overlay>
  );
};

export default SeatForm;
