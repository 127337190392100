import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components/native";
import {
  AdminUserUpdate,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import PickerInput from "../../../components/PickerInput";
import Select, { SelectItemInterface } from "../../../components/Select";
import { useAuth } from "../../../context/authContext";
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import i18n, { t } from "../../../i18n";
import { useHistory, useParams } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { color, spacing } from "../../../styles/theme";
import { MediumTitle } from "../../../styles/Title";
import { isValidEmail, isValidPassword } from "../../../utils";
import { getLanguageItems } from "../CompanyOverview/Create";
import { adminRoles } from "./Create";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Update: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [firstname, setFirstname] = useState("");
  const [infix, setInfix] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState<Language | null>(
    i18n.locale as Language
  );
  const [roles, setRoles] = useState<SelectItemInterface[]>(adminRoles);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { userState } = useAuth();

  useEffect(() => {
    const fetchAdminUser = async () =>
      await handleRequest(client.getAdminUser(id));

    fetchAdminUser().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { firstName, infix, lastName, email, newsletter, roles, language } =
        result.data.result.adminUser;
      setFirstname(firstName);
      setInfix(infix || "");
      setLastname(lastName);
      setEmail(email);
      setLanguage(language);
      setSubscribeToNewsletter(Boolean(newsletter));
      setSelectedRole(roles[0]);
      const updatedRoles = adminRoles.map((role) => ({
        ...role,
        isActive: role.value === roles[0],
      }));
      setRoles(updatedRoles);
    });
  }, [id, client, handleRequest]);

  const updateRole = useCallback(
    (newValue) => {
      setSelectedRole(newValue[0].value);
    },
    [setSelectedRole]
  );

  const isFormValid = useCallback(
    () =>
      Boolean(firstname) &&
      Boolean(lastname) &&
      isValidEmail(email) &&
      (Boolean(password) ? isValidPassword(password) : true),
    [firstname, lastname, email, password]
  );

  const submitForm = useCallback(async () => {
    try {
      const user: AdminUserUpdate = {
        firstName: firstname,
        infix,
        lastName: lastname,
        email,
        newsletter: subscribeToNewsletter,
        roles: [selectedRole],
        language: language || null,
        ...(password && { password }),
      };

      const success = await handleRequest(client.updateAdminUser(id, user));

      if (success) {
        history.goBack();
        if (
          parseInt(id) === userState?.id &&
          language !== userState?.language
        ) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    id,
    history,
    firstname,
    infix,
    lastname,
    email,
    selectedRole,
    password,
    subscribeToNewsletter,
    language,
    client,
    handleRequest,
    userState,
  ]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.user.edit.title")}
          </MediumTitle>
          <LabeledInput required label={t("admin.user.create.form.firstname")}>
            <Input
              onChangeText={setFirstname}
              value={firstname}
              placeholder={t("admin.user.create.form.firstname") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.infix")}>
            <Input
              value={infix}
              placeholder={t("admin.user.create.form.infix")}
              onChangeText={setInfix}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.lastname")} required>
            <Input
              onChangeText={setLastname}
              value={lastname}
              placeholder={t("admin.user.create.form.lastname") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.email")} required>
            <Input
              onChangeText={setEmail}
              value={email}
              placeholder={t("admin.user.create.form.email") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.user.create.form.password")}
            helperText={t("admin.user.create.form.helper.password")}
            required
          >
            <Input
              onChangeText={setPassword}
              value={password}
              secureTextEntry
              placeholder={t("admin.user.create.form.password") + " *"}
              autoCompleteType="new-password"
            />
          </LabeledInput>
          <LabeledInput
            required
            label={t("admin.user.create.form.role")}
            style={{ zIndex: 3 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={roles}
              onChange={updateRole}
              placeholder={t("admin.user.create.form.role")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.language")}>
            <PickerInput
              items={[
                {
                  label: t("general.choose"),
                  value: "",
                },
                ...getLanguageItems().map((item) => ({
                  label: item.label,
                  value: item.value,
                })),
              ]}
              onValueChange={setLanguage}
              selectedValue={language || ""}
            />
          </LabeledInput>
          <LabeledInput>
            <Checkbox
              label={t("admin.user.create.form.sendMail")}
              onChange={setSubscribeToNewsletter}
              checked={subscribeToNewsletter}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.user.edit.submit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Update;
