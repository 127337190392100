module["exports"] = [
  "Abay",
  "Abidin",
  "Acar",
  "Acun",
  "Adem",
  "Adil",
  "Adnân",
  "Afşin",
  "Affan",
  "Afi",
  "Agâh",
  "Ahmet",
  "Ahsen",
  "Akalp",
  "Akbatur",
  "Akın",
  "Akın Alp",
  "Akıner",
  "Aktekin",
  "Aktimur",
  "Ali",
  "Alican",
  "Alişan",
  "Alişir",
  "Alp",
  "Alparslan",
  "Alpay",
  "Alper",
  "Alperen",
  "Alphan",
  "Altan",
  "Altemur",
  "Âmir",
  "Ammâr",
  "Ata",
  "Atalay",
  "Atâullah",
  "Avşar",
  "Aykan",
  "Ayvaz",
  "Anıl",
  "Ant",
  "Arda",
  "Arif",
  "Arslan",
  "Asım",
  "Asil",
  "Aşkın",
  "Atakan",
  "Atıf",
  "Atilla",
  "Avni",
  "Aydemir",
  "Aydın",
  "Ayhan",
  "Aykut",
  "Aytaç",
  "Aytekin",
  "Aytuğ",
  "Azer",
  "Aziz",
  "Azizhan",
  "Azmi",
  "Babacan",
  "Barın",
  "Battal",
  "Behram",
  "Behzat",
  "Bekir",
  "Bektaş",
  "Bera",
  "Berkan",
  "Berkin",
  "Beşer",
  "Baha",
  "Bahadır",
  "Bahri",
  "Bahtiyar",
  "Bâki",
  "Barış",
  "Barkın",
  "Barlas",
  "Bartu",
  "Batu",
  "Batuhan",
  "Batur",
  "Baturalp",
  "Baykal",
  "Bayram",
  "Bedir",
  "Bedirhan",
  "Bedreddin",
  "Bedri",
  "Behçet",
  "Behiç",
  "Behlül",
  "Behzat",
  "Bekir",
  "Bera",
  "Berat",
  "Berk",
  "Berkay",
  "Besim",
  "Beşir",
  "Bilâl",
  "Birol",
  "Bora",
  "Buğra",
  "Bulut",
  "Burak",
  "Burhan",
  "Bülent",
  "Bünyamin",
  "Cafer",
  "Cahid",
  "Can",
  "Canalp",
  "Cândar",
  "Caner",
  "Cankan",
  "Cavid",
  "Celal",
  "Celâleddin",
  "Celasun",
  "Celil",
  "Cem",
  "Cemal",
  "Cemali",
  "Cemil",
  "Cenk",
  "Cevat",
  "Cevahir",
  "Ceyhun",
  "Cezmi",
  "Cihan",
  "Coşkun",
  "Coşkuner",
  "Cumhur",
  "Cübeyr",
  "Cüneyt",
  "Cezmi",
  "Cihad",
  "Cihangir",
  "Civan",
  "Cihanşah",
  "Çağan",
  "Çağatay",
  "Çağdaş",
  "Çağlar",
  "Çağrı",
  "Çağrıhan",
  "Çelen",
  "Çelik",
  "Çetin",
  "Çetinel",
  "Çetiner",
  "Dağhan",
  "Dâver",
  "Davud",
  "Demiralp",
  "Demirhan",
  "Denizalp",
  "Devran",
  "Dikmen",
  "Dilaver",
  "Dildâr",
  "Dilhan",
  "Dinç",
  "Dâver",
  "Derviş",
  "Dilhan",
  "Dinçer",
  "Direnç",
  "Doğan",
  "Doğanay",
  "Doğaner",
  "Doğu",
  "Dora",
  "Durmuş",
  "Dündar",
  "Ebuzer",
  "Ecevit",
  "Ecmel",
  "Ediz",
  "Efe",
  "Efsun",
  "Egemen",
  "Ekrem",
  "Elgin",
  "Emin",
  "Emir",
  "Emrah",
  "Emre",
  "Emrullah",
  "Ender",
  "Enderun",
  "Enes",
  "Engin",
  "Enis",
  "Ensar",
  "Enver",
  "Eralp",
  "Eray",
  "Erberk",
  "Ercan",
  "Ercüment",
  "Erçin",
  "Erda",
  "Erdal",
  "Erdem",
  "Erdinç",
  "Erel",
  "Eren",
  "Ergin",
  "Ergün",
  "Ergüner",
  "Erhan",
  "Erkâm",
  "Erkân",
  "Erman",
  "Erol",
  "Ersin",
  "Erşan",
  "Ertan",
  "Ertuğrul",
  "Esat",
  "Eşref",
  "Ethem",
  "Evren",
  "Ecehan",
  "Ecmel",
  "Ecvet",
  "Ede",
  "Edhem",
  "Efken",
  "Eflah",
  "Ekmel",
  "Elvan",
  "Emced",
  "Emir",
  "Evran",
  "Eymen",
  "Eyüp",
  "Ezrak",
  "Fadıl",
  "Fahrettin",
  "Fahri",
  "Faik",
  "Faris",
  "Faruk",
  "Fatih",
  "Faysal",
  "Fazıl",
  "Fehmi",
  "Ferda",
  "Ferdi",
  "Ferhat",
  "Ferhan",
  "Ferhat",
  "Ferit",
  "Feridun",
  "Ferruh",
  "Fevzi",
  "Feyyaz",
  "Feyzullah",
  "Fikret",
  "Fikri",
  "Fuat",
  "Furkân",
  "Fazlı",
  "Fâlih",
  "Faris",
  "Fasih",
  "Fatin",
  "Fazullah",
  "Feda",
  "Fedai",
  "Feramuz",
  "Feramuş",
  "Ferhan",
  "Ferman",
  "Ferruh",
  "Fuzuli",
  "Gaffar",
  "Gazanfer",
  "Gencer",
  "Gökalp",
  "Gökben",
  "Gökmen",
  "Gönenç",
  "Görkem",
  "Gülhan",
  "Gültekin",
  "Günay",
  "Güner",
  "Göngör",
  "Gürdal",
  "Gürhan",
  "Gürkan",
  "Gürol",
  "Gürsel",
  "Güven",
  "Güvenalp",
  "Gazi",
  "Gevheri",
  "Gıyas",
  "Giray",
  "Gürbüz",
  "Habib",
  "Hâdi",
  "Hafız",
  "Hakan",
  "Hakkı",
  "Haldun",
  "Halit",
  "Halil",
  "Halim",
  "Hâlis",
  "Haluk",
  "Hamdi",
  "Hami",
  "Hamit",
  "Hamza",
  "Harun",
  "Hasan",
  "Haşim",
  "Haşmet",
  "Hayati",
  "Haydar",
  "Hazar",
  "Hıfzı",
  "Hikmet",
  "Hilmi",
  "Himmet",
  "Hulki",
  "Hulusi",
  "Hurşit",
  "Hüma",
  "Hürkan",
  "Hüsamettin",
  "Hüseyin",
  "Hüsnü",
  "Hüsrev",
  "Habbab",
  "Habil",
  "Hacib",
  "Hafi",
  "Hafid",
  "Hakem",
  "Haki",
  "Hamdullah",
  "Hammâd",
  "Hanefi",
  "Hani",
  "Haseki",
  "Hasibi",
  "Hasin",
  "Hâtem",
  "Hattâb",
  "Hatip",
  "Hayali",
  "Haşim",
  "Hazım",
  "Hızır",
  "Hicabi",
  "Himmet",
  "Hişam",
  "Hud",
  "Huzeyfe",
  "Hüccet",
  "Hüdâvendigâr",
  "Hüdayi",
  "Hümayun",
  "Hüsameddin",
  "Ilgın",
  "Işıner",
  "Itri",
  "İbrahim",
  "İdris",
  "İhsan",
  "İkbal",
  "İlbey",
  "İlhami",
  "İlhan",
  "İlkay",
  "İlker",
  "İlyas",
  "İrfan",
  "İsmail",
  "İsmet",
  "İzzettin",
  "İzzet",
  "İhvan",
  "İkrime",
  "Kadir",
  "Kadrican",
  "Kağan",
  "Kamber",
  "Kâmil",
  "Karahan",
  "Kayaalp",
  "Kâzım",
  "Kemâl",
  "Kemalettin",
  "Kenan",
  "Kerami",
  "Kerem",
  "Kerim",
  "Koray",
  "Korkut",
  "Köksal",
  "Kubat",
  "Kubilay",
  "Kutbettin",
  "Kürşad",
  "Kabil",
  "Kadem",
  "Kadı",
  "Kalender",
  "Kamran",
  "Kasım",
  "Keleş",
  "Keramet",
  "Kerami",
  "Keremşah",
  "Keşşaf",
  "Kuddusi",
  "Lamih",
  "Latif",
  "Levent",
  "Lütfullah",
  "Lütfi",
  "Maad",
  "Mahir",
  "Mahmut",
  "Mahzun",
  "Maksud",
  "Mansur",
  "Mazhar",
  "Mehmet",
  "Melih",
  "Melikşah",
  "Memduh",
  "Mert",
  "Mesut",
  "Mete",
  "Metin",
  "Mevlüt",
  "Mithat",
  "Muammer",
  "Muaviye",
  "Muhammed",
  "Muharrem",
  "Muhsin",
  "Muhyiddin",
  "Mukadder",
  "Murat",
  "Musa",
  "Mustafa",
  "Muzaffer",
  "Müfid",
  "Müjdat",
  "Mümtaz",
  "Münir",
  "Mestan",
  "Mir",
  "Miraç",
  "Mirkelam",
  "Mirza",
  "Misbah",
  "Muaz",
  "Muhtar",
  "Muhterem",
  "Muhteşem",
  "Muktedi",
  "Muktedir",
  "Muktefi",
  "Muslih",
  "Mutahhar",
  "Mutasım",
  "Muteber",
  "Mutemed",
  "Muttalib",
  "Mükerrem",
  "Müren",
  "Müşir",
  "Müzdad",
  "Nabi",
  "Naci",
  "Nadi",
  "Nadir",
  "Nafiz",
  "Nahid",
  "Nahil",
  "Nail",
  "Naim",
  "Namık",
  "Nasrullah",
  "Nazım",
  "Nazif",
  "Nazmi",
  "Necat",
  "Necati",
  "Necdet",
  "Necib",
  "Necmettin",
  "Nedim",
  "Nejat",
  "Nesim",
  "Neşet",
  "Nevzat",
  "Nihat",
  "Niyazi",
  "Nizamettin",
  "Numan",
  "Nurettin",
  "Nurullah",
  "Nusret",
  "Oğuz",
  "Oğuzhan",
  "Okan",
  "Oktay",
  "Onur",
  "Orhan",
  "Osman",
  "Ozan",
  "Ömer",
  "Önder",
  "Özcan",
  "Özden",
  "Özer",
  "Özgür",
  "Özhan",
  "Özkan",
  "Payidar",
  "Pertev",
  "Perver",
  "Peyami",
  "Raci",
  "Rafet",
  "Rahim",
  "Rahmi",
  "Raif",
  "Rakıp",
  "Ramazan",
  "Ramiz",
  "Rasim",
  "Raşit",
  "Rauf",
  "Recai",
  "Recep",
  "Refik",
  "Reha",
  "Remzi",
  "Resûl",
  "Reşat",
  "Rıdvan",
  "Rıfat",
  "Rıfkı",
  "Rıza",
  "Rüçhan",
  "Rükneddin",
  "Rüştü",
  "Refah",
  "Refet",
  "Reis",
  "Resül",
  "Rifat",
  "Rüçhan",
  "Sabri",
  "Sacid",
  "Sadberk",
  "Sadettin",
  "Sadık",
  "Sadi",
  "Sadri",
  "Sadullah",
  "Safa",
  "Saffet",
  "Said",
  "Saim",
  "Sâkıp",
  "Salih",
  "Salim",
  "Samed",
  "Sami",
  "Samih",
  "Samim",
  "Savaş",
  "Seçkin",
  "Sedat",
  "Sefer",
  "Selahattin",
  "Selami",
  "Selçuk",
  "Selim",
  "Semih",
  "Serbülent",
  "Sergen",
  "Serhat",
  "Sermet",
  "Sertaç",
  "Server",
  "Settar",
  "Seyfettin",
  "Seyfi",
  "Seyfullah",
  "Seyyit",
  "Sıdkı",
  "Sırrı",
  "Sinan",
  "Suat",
  "Subhi",
  "Sudi",
  "Sururi",
  "Süheyl",
  "Süleyman",
  "Şaban",
  "Şadan",
  "Şahap",
  "Şahin",
  "Şahsuvar",
  "Şakir",
  "Şamil",
  "Şayan",
  "Şefik",
  "Şemsi",
  "Şener",
  "Şenol",
  "Şerafettin",
  "Şeref",
  "Şerif",
  "Şevket",
  "Şevki",
  "Şinasi",
  "Şükrü",
  "Şahinalp",
  "Şahsüvar",
  "Şâfi",
  "Şarani",
  "Şecaeddin",
  "Şehlevent",
  "Şemsi",
  "Şeyban",
  "Şihab",
  "Şihabeddin",
  "Şir",
  "Taceddin",
  "Tahir",
  "Tahsin",
  "Taib",
  "Talat",
  "Talay",
  "Talha",
  "Talip",
  "Tamer",
  "Taner",
  "Tanju",
  "Tarık",
  "Tarkan",
  "Taşkın",
  "Tayfun",
  "Tayyar",
  "Tekin",
  "Tekinalp",
  "Temel",
  "Teoman",
  "Tevfik",
  "Tevhid",
  "Tezcan",
  "Tezel",
  "Tınas",
  "Timur",
  "Tolga",
  "Tolunay",
  "Tufan",
  "Tugay",
  "Tuğrul",
  "Tuğtekin",
  "Tuhfe",
  "Tunahan",
  "Tunç",
  "Tunçer",
  "Turan",
  "Turgay",
  "Turgut",
  "Tümer",
  "Türkay",
  "Türkeş",
  "Türker",
  "Ufuk",
  "Uğur",
  "Ukbe",
  "Ulvi",
  "Umur",
  "Umuralp",
  "Umut",
  "Usame",
  "Utku",
  "Ülgen",
  "Ülker",
  "Ümit",
  "Ünal",
  "Üzeyir",
  "Vahâ",
  "Vahdet",
  "Vahid",
  "Valid",
  "Vecdi",
  "Vedat",
  "Vefa",
  "Vefik",
  "Vehbi",
  "Veli",
  "Veysel",
  "Veysi",
  "Volkan",
  "Yağız",
  "Yahya",
  "Yalçın",
  "Yalgın",
  "Yaman",
  "Yasin",
  "Yaşar",
  "Yavuz",
  "Yekta",
  "Yener",
  "Yetkin",
  "Yıldırım",
  "Yılmaz",
  "Yiğit",
  "Yunus",
  "Yusuf",
  "Yüce",
  "Yücel",
  "Yüksel",
  "Yümni",
  "Zafer",
  "Zâhit",
  "Zekeriyyâ",
  "Zeyd",
  "Zihnî",
  "Ziyâd",
  "Zülfikâr",
  "Zâfir",
  "Zamir",
  "Zekai",
  "Zeynel",
  "Ziver",
  "Ziya",
  "Ziyad",
  "Zübeyr"
];