import React, { FC } from "react";
import styled from "styled-components/native";
import { spacing } from "../../styles/theme";
import { css } from "styled-components";
import isDesktop from "../../hooks/useIsDesktop";
import { ViewProps, ViewStyle } from "react-native";

interface WrapperProps extends ViewProps {
  isDesktop: boolean;
}

const containerPadding = "40px";

export const Wrapper = styled.View<WrapperProps>`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  align-self: stretch;
  flex-direction: column;
  align-content: stretch;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
    `}
`;

const StyledScrollView = styled.ScrollView`
  max-height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const OuterWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  z-index: -1;
`;

interface OuterWrapperInterface {
  backgroundColor?: string;
}

const ContainerOuterWrapper = styled(OuterWrapper)<OuterWrapperInterface>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`;

export const InnerWrapper = styled.View<ViewProps & ViewStyle>`
  width: 100%;
  padding-top: ${({ paddingTop }) => paddingTop ?? spacing.medium};
  padding-bottom: ${spacing.medium};
  padding-left: ${containerPadding};
  padding-right: ${containerPadding};
`;

interface ContainerInterface {
  isNoScrollView?: boolean;
  large?: boolean;
  backgroundColor?: string;
  paddingTop?: string;
}

const Container: FC<ContainerInterface> = ({
  children,
  isNoScrollView,
  backgroundColor,
  paddingTop,
}) => {
  const wrapperProps = {
    isDesktop: isDesktop(),
  };

  const content = (
    <Wrapper {...wrapperProps}>
      <InnerWrapper paddingTop={paddingTop}>{children}</InnerWrapper>
    </Wrapper>
  );

  return (
    <ContainerOuterWrapper backgroundColor={backgroundColor}>
      {!isNoScrollView ? (
        <StyledScrollView>{content}</StyledScrollView>
      ) : (
        content
      )}
    </ContainerOuterWrapper>
  );
};

export default Container;
