module["exports"] = {
  "adjective": [
    "کوچک",
    "ارگونومیک",
    "باهوش",
    "جذاب",
    "باور نکردنی",
    "خارق العاده",
    "کاربردی",
    "براق",
    "عالی",
    "معمولی",
    "دست ساز",
    "لطیف",
    "بدون محدودیت",
    "خوش طعم"
  ],
  "material": [
    "استیل",
    "چوبی",
    "بتنی",
    "پلاستیکی",
    "کتان",
    "گرانیتی",
    "فلزی",
    "نرم",
    "تازه",
    "یخ زده"
  ],
  "product": [
    "صندلی",
    "ماشین",
    "کامپیوتر",
    "کیبورد",
    "ماوس",
    "دوچرخه",
    "توپ",
    "دستکش",
    "شلوار",
    "پیراهن",
    "میز",
    "کفش",
    "کلاه",
    "حوله",
    "صابون",
    "تن ماهی",
    "مرغ",
    "ماهی",
    "پنیر",
    "بیکن",
    "پیتزا",
    "سالاد",
    "سوسیس",
    "چیپس"
  ]
};
