import styled, { css } from "styled-components/native";
import marginBottom, { MarginBottomProps } from "../helpers/marginBottom";
import { borderRadius, color, spacing } from "../theme";

interface BoxProps extends MarginBottomProps {
  last?: boolean;
  backgroundColor?: string;
}

export default styled.View<BoxProps>`
  padding: ${spacing.medium} ${spacing.large};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || color.background};
  border-radius: ${borderRadius.small};

  ${(props) => marginBottom(props)};

  ${({ last }) =>
    last &&
    css`
      margin-bottom: 0;
    `};
`;
