import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components/native";
import { v4 as uuid } from "uuid";
import {
  InlineResponse400Errors, Location, Operations,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LabeledInput, { LabeledInputWrapper } from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { t } from "../../../i18n";
import { useHistory, useParams } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { SmallText } from '../../../styles/Text';
import { color, spacing } from "../../../styles/theme";
import { MediumTitle } from "../../../styles/Title";
import { ExceptionDay, ExceptionHours } from '../../../components/exceptionHours';

const Hours: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<(Location & Operations) | undefined>();
  // All the start and end times for the opening hours
  const [mondayStart, setMondayStart] = useState<string | undefined>();
  const [mondayEnd, setMondayEnd] = useState<string | undefined>();
  const [tuesdayStart, setTuesdayStart] = useState<string | undefined>();
  const [tuesdayEnd, setTuesdayEnd] = useState<string | undefined>();
  const [wednesdayStart, setWednesdayStart] = useState<string | undefined>();
  const [wednesdayEnd, setWednesdayEnd] = useState<string | undefined>();
  const [thursdayStart, setThursdayStart] = useState<string | undefined>();
  const [thursdayEnd, setThursdayEnd] = useState<string | undefined>();
  const [fridayStart, setFridayStart] = useState<string | undefined>();
  const [fridayEnd, setFridayEnd] = useState<string | undefined>();
  const [saturdayStart, setSaturdayStart] = useState<string | undefined>();
  const [saturdayEnd, setSaturdayEnd] = useState<string | undefined>();
  const [sundayStart, setSundayStart] = useState<string | undefined>();
  const [sundayEnd, setSundayEnd] = useState<string | undefined>();
  const [exceptionalDays, setExceptionalDays] = useState<ExceptionDay[] | undefined>();

  const history = useHistory();

  const { id } = useParams();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();

  const updateFields = (location: (Location & Operations) | undefined) => {
    setLocation(location);
    // Set all the opening hours
    setMondayStart(location?.businessHours?.monday?.start ?? undefined);
    setMondayEnd(location?.businessHours?.monday?.end ?? undefined);
    setTuesdayStart(location?.businessHours?.tuesday?.start ?? undefined);
    setTuesdayEnd(location?.businessHours?.tuesday?.end ?? undefined);
    setWednesdayStart(location?.businessHours?.wednesday?.start ?? undefined);
    setWednesdayEnd(location?.businessHours?.wednesday?.end ?? undefined);
    setThursdayStart(location?.businessHours?.thursday?.start ?? undefined);
    setThursdayEnd(location?.businessHours?.thursday?.end ?? undefined);
    setFridayStart(location?.businessHours?.friday?.start ?? undefined);
    setFridayEnd(location?.businessHours?.friday?.end ?? undefined);
    setSaturdayStart(location?.businessHours?.saturday?.start ?? undefined);
    setSaturdayEnd(location?.businessHours?.saturday?.end ?? undefined);
    setSundayStart(location?.businessHours?.sunday?.start ?? undefined);
    setSundayEnd(location?.businessHours?.sunday?.end ?? undefined);
    const exceptionalDays = (location?.businessHours?.exceptions ?? [])?.map?.((exp) => ({
      id: exp.id,
      date: exp.date ? new Date(exp.date) : undefined,
      start: exp.start,
      end: exp.end,
      name: exp.name,
      isWholeDay: exp.isWholeDay,
    }));
    setExceptionalDays(exceptionalDays ?? undefined);
  }

  useEffect(() => {
    void (async () => {
      handleRequest(client.getLocation(id))
        .then((data) => {
          updateFields(data?.data.result.location);
        })
        .catch((err) => console.log(err))
        .finally(() => setLocationLoading(false));
    })();
  }, [client, handleRequest, id]);

  // Make sure if a day has a start OR end time, the other one is also set
  const isFormValid = useCallback(
    () =>
      Boolean(!((mondayStart && !mondayEnd) || (!mondayStart && mondayEnd))) &&
      Boolean(!((tuesdayStart && !tuesdayEnd) || (!tuesdayStart && tuesdayEnd))) &&
      Boolean(!((wednesdayStart && !wednesdayEnd) || (!wednesdayStart && wednesdayEnd))) &&
      Boolean(!((thursdayStart && !thursdayEnd) || (!thursdayStart && thursdayEnd))) &&
      Boolean(!((fridayStart && !fridayEnd) || (!fridayStart && fridayEnd))) &&
      Boolean(!((saturdayStart && !saturdayEnd) || (!saturdayStart && saturdayEnd))) &&
      Boolean(!((sundayStart && !sundayEnd) || (!sundayStart && sundayEnd))),
    [
      mondayStart,
      mondayEnd,
      tuesdayStart,
      tuesdayEnd,
      wednesdayStart,
      wednesdayEnd,
      thursdayStart,
      thursdayEnd,
      fridayStart,
      fridayEnd,
      saturdayStart,
      saturdayEnd,
      sundayStart,
      sundayEnd,
    ]
  );

  // Submit the new opening and closing hours
  const submitForm = useCallback(async () => {
    const businessHours = {
      monday: { start: mondayStart, end: mondayEnd },
      tuesday: { start: tuesdayStart, end: tuesdayEnd },
      wednesday: { start: wednesdayStart, end: wednesdayEnd },
      thursday: { start: thursdayStart, end: thursdayEnd },
      friday: { start: fridayStart, end: fridayEnd },
      saturday: { start: saturdayStart, end: saturdayEnd },
      sunday: { start: sundayStart, end: sundayEnd },
      exceptions: exceptionalDays?.map?.((exp) => ({
        id: exp.id,
        date: exp.date?.toISOString(),
        start: exp.start,
        end: exp.end,
        name: exp.name,
        isWholeDay: exp.isWholeDay,
      })),
    }
    try {
      await handleRequest(client.updateLocationHours(id, businessHours)).then((data) => {
        updateFields(data?.data.result?.location);
      });
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [mondayStart, mondayEnd, tuesdayStart, tuesdayEnd, wednesdayStart, wednesdayEnd, thursdayStart, thursdayEnd, fridayStart, fridayEnd, saturdayStart, saturdayEnd, sundayStart, sundayEnd, exceptionalDays, handleRequest, client, id]);

  return (
    <>
      {isLoading || locationLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle>
            {t("admin.location.hours.form.editTitle")}
          </MediumTitle>
          <SmallText hasMarginBottom>
            {t("admin.location.hours.form.editDescription", { timezone: location?.timezone })}
          </SmallText>
          {/* Show opening and closing hours for every normal day */}
          <LabeledInput
            label={t("general.weekDays.monday")}
            >
            <LabeledInputWrapper>
              <StyledInput
                value={mondayStart}
                onChangeText={setMondayStart}
                placeholder={t("admin.timeslot.form.start")}
              />
              <StyledInput
                value={mondayEnd}
                onChangeText={setMondayEnd}
                placeholder={t("admin.timeslot.form.end")}
              />
            </LabeledInputWrapper>
          </LabeledInput>
          <LabeledInput
            label={t("general.weekDays.tuesday")}
            >
            <LabeledInputWrapper>
              <StyledInput
                value={tuesdayStart}
                onChangeText={setTuesdayStart}
                placeholder={t("admin.timeslot.form.start")}
              />
              <StyledInput
                value={tuesdayEnd}
                onChangeText={setTuesdayEnd}
                placeholder={t("admin.timeslot.form.end")}
              />
            </LabeledInputWrapper>
          </LabeledInput>
          <LabeledInput
            label={t("general.weekDays.wednesday")}
            >
            <LabeledInputWrapper>
              <StyledInput
                value={wednesdayStart}
                onChangeText={setWednesdayStart}
                placeholder={t("admin.timeslot.form.start")}
              />
              <StyledInput
                value={wednesdayEnd}
                onChangeText={setWednesdayEnd}
                placeholder={t("admin.timeslot.form.end")}
              />
            </LabeledInputWrapper>
          </LabeledInput>
          <LabeledInput
            label={t("general.weekDays.thursday")}
            >
            <LabeledInputWrapper>
              <StyledInput
                value={thursdayStart}
                onChangeText={setThursdayStart}
                placeholder={t("admin.timeslot.form.start")}
              />
              <StyledInput
                value={thursdayEnd}
                onChangeText={setThursdayEnd}
                placeholder={t("admin.timeslot.form.end")}
              />
            </LabeledInputWrapper>
          </LabeledInput>
          <LabeledInput
            label={t("general.weekDays.friday")}
            >
            <LabeledInputWrapper>
              <StyledInput
                value={fridayStart}
                onChangeText={setFridayStart}
                placeholder={t("admin.timeslot.form.start")}
              />
              <StyledInput
                value={fridayEnd}
                onChangeText={setFridayEnd}
                placeholder={t("admin.timeslot.form.end")}
              />
            </LabeledInputWrapper>
          </LabeledInput>
          <LabeledInput
            label={t("general.weekDays.saturday")}
            >
            <LabeledInputWrapper>
              <StyledInput
                value={saturdayStart}
                onChangeText={setSaturdayStart}
                placeholder={t("admin.timeslot.form.start")}
              />
              <StyledInput
                value={saturdayEnd}
                onChangeText={setSaturdayEnd}
                placeholder={t("admin.timeslot.form.end")}
              />
            </LabeledInputWrapper>
          </LabeledInput>
          <LabeledInput
            label={t("general.weekDays.sunday")}
          >
            <LabeledInputWrapper>
              <StyledInput
                value={sundayStart}
                onChangeText={setSundayStart}
                placeholder={t("admin.timeslot.form.start")}
              />
              <StyledInput
                value={sundayEnd}
                onChangeText={setSundayEnd}
                placeholder={t("admin.timeslot.form.end")}
              />
            </LabeledInputWrapper>
          </LabeledInput>

          {/* All the custom exceptions */}
          <SmallText hasMarginBottom>
            {t("admin.location.hours.form.exceptions.title")}
          </SmallText>

          {exceptionalDays?.map?.((exception) => (
            <ExceptionHours
              key={exception?.id}
              values={exception}
              onChange={(id, newValues) => {
                setExceptionalDays((prev) =>
                  prev?.map?.((exception) =>
                    exception?.id === id ? newValues : exception
                  )
                );
              }}
              onDeleted={(id) => {
                setExceptionalDays((prev) =>
                  prev?.filter?.((exception) => exception?.id !== id)
                );
              }}
            />
          ))}
          <Button
            hasMarginBottom
            onPress={() => {
              setExceptionalDays((prev) => [
                ...(prev ?? []),
                {
                  id: `new_${uuid()}`,
                  date: new Date(),
                  start: undefined,
                  end: undefined,
                },
              ]);
            }}
          >
            {t("admin.location.hours.form.exceptions.add")}
          </Button>

          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.location.hours.form.editSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Hours;

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const StyledInput = styled(Input)`
  width: 50%;
  margin: 0 5px;
  margin-bottom: 10px;
`;

const StyledDateInput = styled(Input)`
  width: 50%;
  margin: 0 5px;
  margin-bottom: 10px;
`;

const StyledDatePickerInput = styled(Input)`
  width: 100%;
  margin-bottom: 10px;
`;

const DateInputWrapper = styled.View`
  width: 40%;
  z-index: 100;
  margin-right: 5px;
`;

const ExceptionalDay = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DatesWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1.5;
  flex-shrink: 1;
`;
