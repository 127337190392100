import React, { FC, useCallback, useState } from "react";
import Card from "../../styles/Card";
import Input from "../../styles/Input";
import { SeatType } from "./index";
import styled, { css } from "styled-components/native";
import { color, spacing } from "../../styles/theme";
import Button from "../Button";
import { seatTypes } from "./SeatForm";
import { t } from "../../i18n";
import { ZoneInterface } from "../../../fake-data/mapData";

const TypeButtonWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface ButtonProps {
  last?: boolean;
}

const TypeButton = styled.TouchableOpacity<ButtonProps>`
  margin: 0 ${spacing.medium} ${spacing.medium} 0;

  ${({ last }) =>
    last &&
    css`
      margin: 0 0 ${spacing.medium} 0;
    `};
`;

interface ImageProps {
  active?: boolean;
}

const Wrapper = styled(Card)`
  margin-bottom: ${spacing.medium};
`;

const StyledImage = styled.Image<ImageProps>`
  width: 40px;
  height: 40px;
  opacity: 0.4;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}
`;

const FormWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

const FullWidthInput = styled(Input)`
  width: 100%;
  margin-bottom: 0;
`;

const InputWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: ${spacing.medium};
`;

interface CallbackInterface {
  (zone: ZoneInterface): void;
}

interface ZoneProps {
  onUpdate: CallbackInterface;
  onDelete: CallbackInterface;
  zone: ZoneInterface;
}

const EditZoneCard: FC<ZoneProps> = ({ zone, onUpdate, onDelete }) => {
  const [name, setName] = useState<string>(zone.name);
  const [seats, setSeats] = useState<number | undefined>(zone.seats);
  const [type, setType] = useState<SeatType>(zone.type);

  const updateType = useCallback((newValue) => setType(newValue), [setType]);

  const updateSeats = useCallback(
    (event) => {
      const newValue = parseInt(event.target.value);
      if (newValue) setSeats(newValue);
      else setSeats(undefined);
    },
    [setSeats]
  );

  const isFilledIn = useCallback(() => type && name, [type, name]);

  const editZone = useCallback(() => {
    const editedZone: ZoneInterface = {
      ...zone,
      type,
      name,
      seats,
    };

    onUpdate(editedZone);
  }, [type, name, zone, seats, onUpdate]);

  return (
    <Wrapper>
      <FormWrapper>
        <InputWrapper>
          <FullWidthInput
            placeholder={t("admin.map.zone.name")}
            value={name}
            onChangeText={setName}
          />
        </InputWrapper>
        <InputWrapper>
          <TypeButtonWrapper>
            {seatTypes.map(({ name, image }, index, array) => (
              <TypeButton
                key={name}
                onPress={() => updateType(name)}
                last={index === array.length - 1}
              >
                <StyledImage source={{ uri: image }} active={name === type} />
              </TypeButton>
            ))}
          </TypeButtonWrapper>
        </InputWrapper>
        <InputWrapper>
          <FullWidthInput
            placeholder={t("admin.map.zone.seats")}
            value={seats?.toString()}
            onChange={updateSeats}
          />
        </InputWrapper>
        <InputWrapper>
          <Button disabled={!isFilledIn()} onPress={editZone}>
            {t("general.update")}
          </Button>
          <Button
            style={{ marginLeft: spacing.medium }}
            onPress={() => onDelete(zone)}
            backgroundColor={color.primary}
            backgroundHoverColor={color.primaryLight}
            textHoverColor={color.darker}
          >
            {t("general.delete")}
          </Button>
        </InputWrapper>
      </FormWrapper>
    </Wrapper>
  );
};

export default EditZoneCard;
