import { t } from "../../i18n";
import moment from "moment";

import { SelectItemInterface } from "../Select";

export enum DateComparisor {
  IS_EQUAL = "equals",
  IS_NOT_EQUAL = "not",
  IS_GREATER = "gt",
  IS_GREATER_OR_EQUAL = "gte",
  IS_LESS = "lt",
  IS_LESS_OR_EQUAL = "lte",
  IS_BETWEEN = "bt",
}

export const comparisonValues = (): SelectItemInterface[] => [
  {
    label: t("admin.reservation.filter.isEqual"),
    value: DateComparisor.IS_EQUAL,
    isActive: true,
  },
  {
    label: t("admin.reservation.filter.isNotEqual"),
    value: DateComparisor.IS_NOT_EQUAL,
  },
  {
    label: t("admin.reservation.filter.isAfter"),
    value: DateComparisor.IS_GREATER,
  },
  {
    label: t("admin.reservation.filter.isAfterOrEqual"),
    value: DateComparisor.IS_GREATER_OR_EQUAL,
  },
  {
    label: t("admin.reservation.filter.isBefore"),
    value: DateComparisor.IS_LESS,
  },
  {
    label: t("admin.reservation.filter.isBeforeOrEqual"),
    value: DateComparisor.IS_LESS_OR_EQUAL,
  },
  {
    label: t("admin.reservation.filter.isBetween"),
    value: DateComparisor.IS_BETWEEN,
  },
];

export const today = moment().format("YYYY-MM-DD");
