module["exports"] = [
  "Limpopo",
  "Gauteng",
  "Free State,",
  "North West",
  "Northern Cape",
  "Western Cape,",
  "KwaZulu-Natal",
  "Mpumalanga",
  "Eastern Cape"
];
