export {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  BrowserRouterProps as RouterProps,
  useHistory,
  Redirect,
  RouteProps,
  useParams,
} from "react-router-dom";
