module["exports"] = [
  "Abendam",
  "Açela",
  "Açelya",
  "Açılay",
  "Adel",
  "Adelya",
  "Adile",
  "Afitap",
  "Afra",
  "Ağça",
  "Ahenk",
  "Ahlem",
  "Alisa",
  "Almila",
  "Alvina",
  "Amelya",
  "Amara",
  "Andaç",
  "Anar",
  "Anise",
  "Anita",
  "Anka",
  "Alpike",
  "Altın",
  "Arın",
  "Arya",
  "Asuela",
  "Aslım",
  "Ayren",
  "Aykal",
  "Aysar",
  "Ayşıl",
  "Bade",
  "Bağdagül",
  "Balın",
  "Bediz",
  "Bedran",
  "Behrem",
  "Belçim",
  "Belma",
  "Beltun",
  "Belemir",
  "Benice",
  "Benli",
  "Berceste",
  "Berçin",
  "Berinay",
  "Berran",
  "Berre",
  "Berva",
  "Besra",
  "Çağıl",
  "Cangül",
  "Cannur",
  "Cansel",
  "Cansın",
  "Canel",
  "Ceren",
  "Ceyda",
  "Cilvenaz",
  "Ceylinaz",
  "Ceylin",
  "Ceyla",
  "Ceylan",
  "Çağla",
  "Çeşminaz",
  "Çıgıl",
  "Çiçek",
  "Çilay",
  "Çiler",
  "Çimen",
  "Çise",
  "Çişem",
  "Çisil",
  "Damla",
  "Defne",
  "Demet",
  "Deniz",
  "Derya",
  "Destan",
  "Deste",
  "Didem",
  "Dilan",
  "Dilara",
  "Dilay",
  "Diler",
  "Dilhan",
  "Dilek",
  "Dilruba",
  "Döndü",
  "Duygu",
  "Dünya",
  "Dürdane",
  "Dürriye",
  "Dicle",
  "Dolunay",
  "Destan",
  "Derin",
  "Diclehan",
  "Dilberay",
  "Ebru",
  "Ece",
  "Eda",
  "Ekin",
  "Ela",
  "Elçin",
  "Elif",
  "Elmas",
  "Elvan",
  "Emel",
  "Emine",
  "Enise",
  "Esen",
  "Eser",
  "Esin",
  "Esmeray",
  "Eylül",
  "Evşen",
  "Eftalya",
  "Ecem",
  "Eyşan",
  "Fadime",
  "Fahriye",
  "Fahrünissa",
  "Fatma",
  "Fatoş",
  "Fazilet",
  "Fehime",
  "Ferah",
  "Feray",
  "Ferda",
  "Feride",
  "Feriha",
  "Feyza",
  "Fidan",
  "Figen",
  "Fikriye",
  "Filiz",
  "Firdevs",
  "Fulya",
  "Fuldem",
  "Fulden",
  "Funda",
  "Füruzan",
  "Füsun",
  "Füreyya",
  "Gamze",
  "Gaye",
  "Gizem",
  "Gonca",
  "Gökben",
  "Gökçe",
  "Gökşin",
  "Gönül",
  "Gözde",
  "Güher",
  "Gül",
  "Gülbahar",
  "Gülben",
  "Gülçin",
  "Güldem",
  "Gülden",
  "Güldeste",
  "Gülen",
  "Gülşen",
  "Gülgün",
  "Gülnaz",
  "Gülpembe",
  "Gülriz",
  "Gülsen",
  "Günay",
  "Güneş",
  "Güner",
  "Güngör",
  "Güniz",
  "Günsel",
  "Günseli",
  "Gürcan",
  "Güven",
  "Göknur",
  "Günnur",
  "Hale",
  "Handan",
  "Hande",
  "Hayal",
  "Hayat",
  "Hazan",
  "Hilal",
  "Hülya",
  "Hümeyra",
  "Hüner",
  "Ilgın",
  "Işık",
  "Işıl",
  "Işılay",
  "Işın",
  "Itır",
  "İclal",
  "İdil",
  "İffet",
  "İkbal",
  "İlayda",
  "İlkben",
  "İlke",
  "İlknur",
  "İlksen",
  "İlkyaz",
  "İmge",
  "İmran",
  "İnci",
  "İpek",
  "İrem",
  "İzel",
  "Yaprak",
  "Yasemin",
  "Yağmur",
  "Yelda",
  "Yeliz",
  "Yeşim",
  "Yıldız",
  "Yonca",
  "Yosun",
  "Jale",
  "Julide",
  "Kader",
  "Kadriye",
  "Kamelya",
  "Kamile",
  "Kamuran",
  "Kevser",
  "Kumru",
  "Kısmet",
  "Kıymet",
  "Kiraz",
  "Lale",
  "Lamia",
  "Latife",
  "Leman",
  "Lemide",
  "Lerzan",
  "Leyla",
  "Lida",
  "Mehtap",
  "Melda",
  "Melek",
  "Melike",
  "Melis",
  "Melisa",
  "Melodi",
  "Meltem",
  "Meral",
  "Meriç",
  "Merih",
  "Merve",
  "Meryem",
  "Mihriban",
  "Mine",
  "Miray",
  "Müesser",
  "Münevver",
  "Müge",
  "Müjde",
  "Müjgan",
  "Mukaddes",
  "Mısra",
  "Nalan",
  "Naz",
  "Nazan",
  "Nazlı",
  "Necla",
  "Nehir",
  "Nergis",
  "Neslişah",
  "Nesrin",
  "Nevin",
  "Nevra",
  "Nida",
  "Nigar",
  "Nihal",
  "Nihan",
  "Nil",
  "Nilgün",
  "Nisa",
  "Nisan",
  "Nükhet",
  "Nur",
  "Nural",
  "Nuran",
  "Nurgül",
  "Nursel",
  "Nurseli",
  "Okşan",
  "Olcay",
  "Oya",
  "Öykü",
  "Özden",
  "Özge",
  "Özlem",
  "Özlen",
  "Öznur",
  "Parla",
  "Pakize",
  "Pelin",
  "Pelinsu",
  "Pembe",
  "Peri",
  "Perihan",
  "Perran",
  "Pervin",
  "Petek",
  "Pınar",
  "Piraye",
  "Rabia",
  "Rahime",
  "Rahşan",
  "Rana",
  "Rengin",
  "Reyhan",
  "Rezzan",
  "Rüya",
  "Ruhsar",
  "Sanem",
  "Seçil",
  "Seda",
  "Sedef",
  "Seden",
  "Seher",
  "Selda",
  "Selen",
  "Selin",
  "Selma",
  "Selvi",
  "Sema",
  "Semra",
  "Senay",
  "Serap",
  "Sertap",
  "Seren",
  "Serin",
  "Serpil",
  "Sevda",
  "Sevgi",
  "Sevil",
  "Sevim",
  "Sevinç",
  "Sevtap",
  "Seval",
  "Sıla",
  "Sibel",
  "Simge",
  "Sinem",
  "Songül",
  "Su",
  "Sunay",
  "Suzan",
  "Şebnem",
  "Şehrazat",
  "Şelale",
  "Şenay",
  "Şengül",
  "Şennur",
  "Şermin",
  "Şeyda",
  "Şeyma",
  "Şevval",
  "Şiir",
  "Şule",
  "Tanyeli",
  "Tezer",
  "Tuğba",
  "Turna",
  "Tutku",
  "Tülay",
  "Tülin",
  "Türkan",
  "Tunay",
  "Utku",
  "Ulus",
  "Uhra",
  "Uygu",
  "Ulviye",
  "Ülfet",
  "Ülker",
  "Ülkü",
  "Ümmiye",
  "Ümran",
  "Ünsel",
  "Ünseli",
  "Vahide",
  "Verda",
  "Vesile",
  "Vicdan",
  "Vildan",
  "Vuslat",
  "Yaprak",
  "Yasemin",
  "Yağmur",
  "Yelda",
  "Yeliz",
  "Yeşim",
  "Yıldız",
  "Yonca",
  "Yosun",
  "Zahide",
  "Zehra",
  "Zekiye",
  "Zerrin",
  "Zeynep",
  "Zübeyde",
  "Zühal",
  "Zülal",
  "Züleyha",
  "Zeliha",
  "Zümrüt"
];
