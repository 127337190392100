import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Timeslot } from "../../../../bookmydesk-api-sdk-typescript-axios"
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import { takeSize } from "../../../components/Paginator";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { OverviewHeader } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import Title from "../../../styles/Title";
import { getCompanyPages } from "../../../components/Navbar/AdminNavBarContent";

const columnSizes = [2, 1, 1, 0.5];

const TimeslotOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.timeslot.overview.name"), true, "name"),
    createHeaderItem(t("admin.timeslot.overview.start"), true, "start"),
    createHeaderItem(t("admin.timeslot.overview.end"), true, "end"),
    createHeaderItem(t("admin.timeslot.overview.type"), false),
  ];
  const { companyPages, userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listTimeslots>[1]>(tableHeaders);

  const userCompany = userState?.companyIds[0];

  const [data, setData] = useState<Array<Timeslot>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<Timeslot>();

  const history = useHistory();

  const fetchData = useCallback(async () => {
    if (userCompany) {
      return handleRequest(
        client.listTimeslots(userCompany, sortField, sortOrder, takeSize)
      );
    }
  }, [client, userCompany, handleRequest, sortField, sortOrder]);

  useEffect(() => {
    fetchData().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      if (items) {
        setTableData(createTableData(items));
        setData(items || []);
      }
    });
  }, [fetchData, userCompany]);

  const createTableData = useCallback((items: Timeslot[]) => {
    const tableData: TableContent[] = items.map(
      ({ name, from, to, type, id }) => {
        const data: ReactElement[] = [
          <ExtraSmallText key="name" mediumWeight>
            {name}
          </ExtraSmallText>,
          <ExtraSmallText key="from">{from}</ExtraSmallText>,
          <ExtraSmallText key="to">{to}</ExtraSmallText>,
          <ExtraSmallText key="type">{type}</ExtraSmallText>,
        ];
        return {
          data,
          id,
          canDelete: true,
          canEdit: true,
        };
      }
    );
    return tableData;
  }, []);

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteTimeslot(itemSelected!.id)
    );

    if (response && response.status === 204) {
      await fetchData().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items } = result.data.result;
        if (items) {
          setTableData(createTableData(items));
          setData(items);
        }
      });

      setShowDeleteMessage(true);
    }
  }, [
    itemSelected,
    setShowDeleteModal,
    client,
    fetchData,
    setTableData,
    createTableData,
    setData,
  ]);

  return (
    <>
      <Container>
        <OverviewHeader>
          <Title>{t("admin.timeslot.overview.title")}</Title>
          <Button
            onPress={() => history.push("/admin/timeslot-overview/create")}
          >
            {t("admin.timeslot.overview.addButton")}
          </Button>
        </OverviewHeader>
        <PageSelector pages={companyPages} />
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        )}
        {!isLoading && (
          <Table
            tableId="timeslot_overview"
            headerItems={headerItems}
            tableContent={tableData}
            columnSizes={columnSizes}
            sortList={onSortList}
            sortOrder={sortOrder}
            sortField={sortField}
            onEditItem={(id) =>
              history.push(`/admin/timeslot-overview/update/${id}`)
            }
            onDeleteItem={openDeleteModal}
          />
        )}
      </Container>

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.timeslot.delete.warning", {
          name: itemSelected?.name,
        })}
      />
    </>
  );
};

export default TimeslotOverview;
