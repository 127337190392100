import React, { FC, useCallback, useState } from "react";

import { t } from "../../../i18n";
import {
  SeatTagWithoutId,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";

import Button from "../../../components/Button";
import LoadOverlay from "../../../components/LoadOverlay";
import LabeledInput from "../../../components/LabeledInput";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";

import { useHistory } from "../../../routing";
import { useClient } from "../../../hooks/useClient";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
  shadow-radius: 0px;
  shadow-offset: 0 0px;
  shadow-color: black;
  shadow-opacity: 0;
`;

interface CreateProps {
  onSuccess?: () => void;
  onFailure?: () => void;
  onCancel?: () => void;
}

const Create: FC<CreateProps> = ({ onSuccess, onFailure, onCancel }) => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");

  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();
  const isFormValid = useCallback(() => Boolean(name), [name]);

  const submitForm = useCallback(async () => {
    const seatTag: SeatTagWithoutId = { label: name };
    try {
      const success = await handleRequest(client.addSeatTag(seatTag));

      if (success && onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
        if (onFailure) {
          onFailure();
        }
      }
    }
  }, [handleRequest, client, name, history]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}

      <StyledFormCard>
        {Boolean(error) && (
          <InputValidationErrors
            errors={errors}
            closeNotification={dismissError}
          />
        )}
        <LabeledInput label={t("admin.seatTag.form.name")} required>
          <Input
            onChangeText={setName}
            value={name}
            placeholder={t("admin.seatTag.form.name") + " *"}
          />
        </LabeledInput>
        <Button hasMarginBottom disabled={!isFormValid()} onPress={submitForm}>
          {t("admin.seatTag.form.createSubmit")}
        </Button>
        <Button
          backgroundColor="transparent"
          backgroundHoverColor={color.primary}
          borderColor={color.primary}
          color={color.primary}
          textHoverColor={color.white}
          onPress={() => onCancel()}
        >
          {t("general.cancel")}
        </Button>
      </StyledFormCard>
    </>
  );
};

export default Create;
