module["exports"] = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "live.com",
  "portugalmail.pt",
  "mail.pt",
  "sapo.pt",
  "aeiou.pt"
];
