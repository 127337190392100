import React, { FC } from "react";
import styled from "styled-components/native";
import { color, font, text, borderRadius, spacing } from "../../styles/theme";
import Text, { SmallText } from "../../styles/Text";
import { StatusIndicatorStatisticInterface } from "../../../fake-data/dashboardData";
import StatusIndicator from "../StatusIndicator";

const StatisticView = styled.View`
  padding: ${spacing.extraSmall} 0;
  flex: 1;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;

const StatisticTextContainer = styled.View`
  display: flex;
  margin-left: ${spacing.medium};
`;

const Number = styled(Text)`
  font-size: ${text.extraLarge.size};
  font-family: ${font.defaultSemiBold};
  flex: 1;
  line-height: 100%;
`;

const Badge = styled.View`
  background-color: ${color.secondaryLight};
  border-radius: ${borderRadius.full};
  padding: 0 ${spacing.small};
  position: absolute;
  top: 0;
  right: 0;
`;

const BadgeText = styled(SmallText)`
  color: ${color.secondary};
  font-family: ${font.defaultSemiBold};
`;

const StatusIndicatorStatistic: FC<StatusIndicatorStatisticInterface> = ({
  number,
  label,
  dataColor,
}) => (
  <StatisticView>
    <StatusIndicator dataColor={dataColor} />
    <StatisticTextContainer>
      <Number>{number}</Number>
      <SmallText style={{
        fontSize: text.small.size,
        lineHeight: "auto",
      }}>{label}</SmallText>
    </StatisticTextContainer>
  </StatisticView>
);

export default StatusIndicatorStatistic;
