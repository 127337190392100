module["exports"] = [
  "Ada",
  "Adina",
  "Adriana",
  "Adrijana",
  "Agata",
  "Alemka",
  "Alenka",
  "Alisa",
  "Alka",
  "Alma",
  "Amalija",
  "Ana",
  "Ančica",
  "Anamarija",
  "Anastazija",
  "Anci",
  "Anči",
  "Ancica",
  "Anda",
  "Anđela",
  "Anđelika",
  "Anđelina",
  "Andi",
  "Andrea",
  "Andreja",
  "Andrina",
  "Anesa",
  "Aneta",
  "Ani",
  "Anica",
  "Anja",
  "Anka",
  "Antea",
  "Antonija",
  "Asija",
  "Asja",
  "Bara",
  "Barbara",
  "Barica",
  "Beata",
  "Bela",
  "Biserka",
  "Borka",
  "Borna",
  "Božena",
  "Božica",
  "Branimira",
  "Branka",
  "Brankica",
  "Brigita",
  "Dada",
  "Dagmar",
  "Dalibora",
  "Daliborka",
  "Danica",
  "Danijela",
  "Danka",
  "Dara",
  "Darinka",
  "Dea",
  "Ðina",
  "Dona",
  "Donka",
  "Dora",
  "Dorana",
  "Dorica",
  "Draga",
  "Ela",
  "Eli",
  "Elka",
  "Evelin",
  "Ina",
  "Inga",
  "Inge",
  "Inja",
  "Inka",
  "Ita",
  "Iva",
  "Ivančica",
  "Ivanka",
  "Ivica",
  "Ivna",
  "Jagica",
  "Jana",
  "Janica",
  "Janka",
  "Jasna",
  "Jela",
  "Jelica",
  "Ješa",
  "Kata",
  "Kate",
  "Katica",
  "Katija",
  "Katina",
  "Kika",
  "Lara",
  "Laura",
  "Lea",
  "Lela",
  "Lena",
  "Leona",
  "Lola",
  "Maca",
  "Magda",
  "Maja",
  "Majda",
  "Manda",
  "Mara",
  "Mare",
  "Marena",
  "Marica",
  "Marta",
  "Maša",
  "Mica",
  "Mika",
  "Mila",
  "Milica",
  "Milka",
  "Mima",
  "Mina",
  "Mira",
  "Mirka",
  "Naja",
  "Nana",
  "Natalija",
  "Nataša",
  "Natka",
  "Neda",
  "Nela",
  "Neli",
  "Nena",
  "Nera",
  "Nesa",
  "Neva",
  "Nevenka",
  "Nina",
  "Nuša",
  "Paulina",
  "Pava",
  "Pave",
  "Pavica",
  "Rajna",
  "Rija",
  "Rina",
  "Ružica",
  "Staša",
  "Tasa",
  "Teja",
  "Tija",
  "Tijana",
  "Tina",
  "Tončica",
  "Tonija",
  "Tonka",
  "Vala",
  "Valera",
  "Vali",
  "Vela",
  "Velana",
  "Velina",
  "Vera",
  "Verica",
  "Vinka",
  "Zlatica",
  "Zlatka",
  "Darija",
  "Darina",
  "Divna",
  "Donata",
  "Doroteja",
  "Dragica",
  "Dubravka",
  "Dunja",
  "Ðurda",
  "Edita",
  "Elena",
  "Ema",
  "Eva",
  "Gabrijela",
  "Goranka",
  "Gordana",
  "Grozdana",
  "Helena",
  "Hrvojka",
  "Ira",
  "Irena",
  "Irma",
  "Ivana",
  "Jadranka",
  "Jaga",
  "Jasmina",
  "Josipa",
  "Karmela",
  "Katarina",
  "Kristina",
  "Leda",
  "Leonida",
  "Lidija",
  "Ljiljana",
  "Ljubica",
  "Lovorka",
  "Lucija",
  "Magdalena",
  "Marija",
  "Marijana",
  "Marina",
  "Martina",
  "Miranda",
  "Mirna",
  "Monika",
  "Nada",
  "Nadalina",
  "Nedjeljka",
  "Nevena",
  "Nora",
  "Paula",
  "Petra",
  "Renata",
  "Romana",
  "Rosana",
  "Ruža",
  "Sanja",
  "Silvija",
  "Slavica",
  "Snježana",
  "Tereza",
  "Tomislava",
  "Valentina",
  "Valerija",
  "Vedrana",
  "Velimira",
  "Veronika",
  "Vesna",
  "Vladimira",
  "Zdenka",
  "Zdeslava",
  "Zlata"
];
