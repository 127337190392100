import React, { FC, useEffect, useState } from "react";
import styled from "styled-components/native";
import {
  SeatUserGroupConditions,
  UserGroup,
} from "../../../bookmydesk-api-sdk-typescript-axios";
import { t } from "../../i18n";
import Input from "../../styles/Input";
import Text, { ExtraSmallText } from "../../styles/Text";
import { chevron, color, spacing, text } from "../../styles/theme";
import Title from "../../styles/Title";
import PickerInput from "../PickerInput";
import { SelectItemInterface } from "../Select";
import { isValidTime } from "../../utils";

const Icon = styled.Image`
  width: ${chevron.width};
  height: ${chevron.height};
  margin-left: ${spacing.small};
`;

const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-grow: 0;
  margin-bottom: ${spacing.medium};
`;

const SmallWrapper = styled.View`
  flex: 1;
  align-items: center;
`;

const MediumWrapper = styled.View`
  flex: 2;
  align-items: center;
`;

const LargeWrapper = styled.View`
  flex: 4.5;
`;

const StyledInput = styled(Input)`
  width: 100%;
  padding-left: ${spacing.medium};
  padding-right: ${spacing.medium};
  margin-bottom: 0;
`;

const InputLabel = styled(Text)`
  font-size: ${text.tiny.size};
`;

const TouchableCloseIcon = styled.TouchableOpacity`
  /* height: ${spacing.medium}; */
`;

const CloseIcon = styled(Title)`
  font-size: ${text.large.size};
  line-height: 1;
  height: 0;
  /* margin-top: -${spacing.medium}; */
  margin-left: ${spacing.small};
`;

export interface AvailabilityInterface {
  weekday?: string;
  from?: string;
  to?: string;
  id?: string;
}

interface AvailabilityInputProps {
  onChange: (seatUserGroupConditions: {
    userGroupId: string;
    condition: SeatUserGroupConditions;
  }) => void;
  onDelete: () => void;
  userGroupId: UserGroup["id"];
  userGroups: UserGroup[];
  seatUserGroupCondition: SeatUserGroupConditions;
}

const AvailabilityInput: FC<AvailabilityInputProps> = ({
  onChange,
  onDelete,
  userGroupId,
  userGroups,
  seatUserGroupCondition,
}) => {
  const [invalidTimeFormat, setInvalidTimeFormat] = useState(false);
  const weekdaysData: SelectItemInterface[] = [
    {
      label: t("general.weekDays.monday"),
      value: 1,
    },
    {
      label: t("general.weekDays.tuesday"),
      value: 2,
    },
    {
      label: t("general.weekDays.wednesday"),
      value: 3,
    },
    {
      label: t("general.weekDays.thursday"),
      value: 4,
    },
    {
      label: t("general.weekDays.friday"),
      value: 5,
    },
    {
      label: t("general.weekDays.saturday"),
      value: 6,
    },
    {
      label: t("general.weekDays.sunday"),
      value: 0,
    },
  ];
  const [selectedUserGroupId, setSelectedUserGroupId] = useState(userGroupId);
  const [from, setFrom] = useState(seatUserGroupCondition.timeFrom || "08:00");
  const [to, setUntil] = useState(seatUserGroupCondition.timeTo || "17:00");
  const [weekday, setWeekday] = useState(
    typeof seatUserGroupCondition.weekday === "number"
      ? seatUserGroupCondition.weekday
      : 1
  );

  useEffect(() => {
    setInvalidTimeFormat(!isValidTime(from) || !isValidTime(to));
    onChange({
      userGroupId: selectedUserGroupId,
      condition: {
        weekday,
        timeFrom: from,
        timeTo: to,
      },
    });
  }, [selectedUserGroupId, weekday, from, to]);

  return (
    <>
      <Wrapper>
        <LargeWrapper
          style={{
            marginRight: spacing.small,
          }}
        >
          <PickerInput
            noMarginBottom
            selectedValue={selectedUserGroupId}
            onValueChange={(itemValue, itemIndex) =>
              setSelectedUserGroupId(itemValue)
            }
            items={userGroups.map((userGroup) => ({
              label: userGroup.label,
              value: userGroup.id,
            }))}
          />
        </LargeWrapper>
        <LargeWrapper style={{}}>
          <PickerInput
            noMarginBottom
            selectedValue={weekday}
            onValueChange={(itemValue, itemIndex) =>
              setWeekday(parseInt(itemValue, 10))
            }
            items={weekdaysData}
          />
        </LargeWrapper>
        <MediumWrapper
          style={{
            // minWidth: 70,
            marginLeft: spacing.small,
          }}
        >
          <StyledInput
            value={from}
            onChangeText={(text) => {
              setFrom(text);
            }}
            placeholder="09:00"
          />
        </MediumWrapper>
        <ExtraSmallText
          style={{
            paddingHorizontal: spacing.small,
          }}
        >
          -
        </ExtraSmallText>
        <MediumWrapper
          style={
            {
              // minWidth: 70,
            }
          }
        >
          <StyledInput
            value={to}
            onChangeText={(text) => {
              setUntil(text);
            }}
            placeholder="13:00"
          />
        </MediumWrapper>
        <TouchableCloseIcon>
          <CloseIcon onPress={() => onDelete()}>⨯</CloseIcon>
        </TouchableCloseIcon>
      </Wrapper>
      {invalidTimeFormat && (
        <ExtraSmallText style={{ marginTop: -20 }} color={color.primary}>
          {t("general.invalidTimeFormat")}
        </ExtraSmallText>
      )}
    </>
  );
};

export default AvailabilityInput;
