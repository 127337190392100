module["exports"] = [
  "אדום",
  "ירוק",
  "כחול",
  "צהוב",
  "סגול",
  "ירוק מנטה",
  "ירוק כחלחל",
  "לבן",
  "שחור",
  "כתום",
  "ורוד",
  "אפור",
  "חוּם עֲרמוֹנִי",
  "סָגוֹל",
  "טורקיז",
  "שזוף",
  "שמים כחולים",
  "סלמון",
  "שזיף",
  "סחלב",
  "זית",
  "מגנטה",
  "ליים",
  "שנהב",
  "אינדיגו",
  "זהב",
  "ורוד פוקסיה",
  "טורקיז",
  "צבע תכלת",
  "לבנדר",
  "כסף"
];
