import React, { FC, useCallback, useState } from "react";
import styled, { css } from "styled-components/native";
import Card from "../../styles/Card";
import Input from "../../styles/Input";
import Title from "../../styles/Title";
import { color, spacing } from "../../styles/theme";
import Button from "../Button";
import { SeatType } from "./index";
import { seatTypes } from "./SeatForm";
import { t } from "../../i18n";

const FormWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

const FullWidthInput = styled(Input)`
  width: 100%;
  margin-bottom: 0;
`;

const InputWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: ${spacing.medium};
`;

interface ButtonProps {
  last?: boolean;
}

const TypeButton = styled.TouchableOpacity<ButtonProps>`
  margin-right: ${spacing.medium}
    ${({ last }) =>
      last &&
      css`
        margin-right: 0;
      `};
`;

interface ImageProps {
  active?: boolean;
}

const StyledImage = styled.Image<ImageProps>`
  width: 40px;
  height: 40px;
  opacity: 0.4;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}
`;

interface CreateCallbackInterface {
  (name: string, type: SeatType, amount?: number): void;
}

interface CancelCallbackInterface {
  (): void;
}

interface CreateZoneFormProps {
  onCreate: CreateCallbackInterface;
  onCancel: CancelCallbackInterface;
}

const CreateZoneForm: FC<CreateZoneFormProps> = ({ onCreate, onCancel }) => {
  const [type, setType] = useState<SeatType>("meeting");
  const [name, setName] = useState<string>("");
  const [seats, setSeats] = useState<number | undefined>(undefined);
  const updateType = useCallback((newType) => setType(newType), [setType]);

  const updateSeats = useCallback(
    (event) => {
      const newValue = parseInt(event.target.value);
      if (newValue) setSeats(newValue);
      else setSeats(undefined);
    },
    [setSeats]
  );

  const isFilledIn = useCallback(() => name && type, [name, type]);

  return (
    <Card>
      <Title>{t("admin.map.zone.createTitle")}</Title>
      <FormWrapper>
        <InputWrapper>
          <FullWidthInput
            value={name}
            onChangeText={setName}
            placeholder={t("admin.map.zone.name")}
          />
        </InputWrapper>
        <InputWrapper>
          {seatTypes.map(({ name, image }, index, array) => (
            <TypeButton
              key={name}
              onPress={() => updateType(name)}
              last={index === array.length - 1}
            >
              <StyledImage source={{ uri: image }} active={name === type} />
            </TypeButton>
          ))}
        </InputWrapper>
        <InputWrapper>
          <FullWidthInput
            value={seats?.toString()}
            onChange={updateSeats}
            placeholder={t("admin.map.zone.seats")}
          />
        </InputWrapper>
        <InputWrapper>
          <Button
            disabled={!isFilledIn()}
            onPress={() => onCreate(name, type, seats)}
          >
            {t("general.create")}
          </Button>
          <Button
            style={{ marginLeft: spacing.medium }}
            onPress={onCancel}
            backgroundColor={color.primary}
            backgroundHoverColor={color.primaryLight}
            textHoverColor={color.darker}
          >
            {t("general.cancel")}
          </Button>
        </InputWrapper>
      </FormWrapper>
    </Card>
  );
};

export default CreateZoneForm;
