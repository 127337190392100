import React, { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { ViewProps, ViewStyle } from "react-native";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import styled, { css } from "styled-components/native";

import { color, display, flex, font, Position, spacing, text } from "../../styles/theme";
import { HoverableListRow, List, ListHeaderRow } from "../../styles/List";

import { ButtonWrapper } from "../Button";
import IconButtonSmall from "../../components/Button/IconButtonSmall";
import NoResults from "../../components/NoResults";
import TableHeaderItem, { HeaderItem } from "../../components/Table/TableHeaderItem";

import editScheduleIcon from "../../assets/icons/timeslotInactive.svg";
import editScheduleIconActive from "../../assets/icons/timeslotActive.svg";
import editIcon from "../../assets/icons/editIconInactive.svg";
import editIconActive from "../../assets/icons/editIconActive.svg";
import deleteIcon from "../../assets/icons/deleteIconInactive.svg";
import deleteIconActive from "../../assets/icons/deleteIconActive.svg";
import customFieldIcon from "../../assets/icons/customFieldIconInactive.svg";
import customFieldIconActive from "../../assets/icons/customFieldIconActive.svg";
import Checkbox from "../Checkbox";
import DismissAbleOverlay from "../Map/DismissAbleOverlay";
import OverlayRightWrapper from "../OverlayRightWrapper";
import { SmallText } from "../../styles/Text";
import { t } from "../../i18n";
import Select, { SelectItemInterface } from "../Select";
import TableDataCell from "./TableDataCell";
import { convertTranslate } from "../../utils/autoStyling";
import { getFromStorage, saveInStorage, storageKeys } from "../../services/asyncStorageService";
import { SortOrder } from "../../hooks/useSort";
import Link from '../Link';

const TableHeaderItemsWrapper = styled.View<ViewProps & ViewStyle & { isDragging: boolean }>`
  ${({ flex }) =>
  css`
      flex: ${flex} 1 0;
    `};
  ${({ isDragging }) =>
  isDragging ? css`
      background-color: ${color.primaryLight};
    ` : ''};
  flex-direction: row;
  position: ${Position.relative};
`;

const SelectWrapper = styled.View<ViewProps & ViewStyle>`
  max-width: 350px;
  margin-right: 20px;
`;

const HeaderWrapper = styled.View<ViewProps & ViewStyle>`
  position: ${Position.sticky};
  top: ${spacing.medium};
  left: ${spacing.large};
  z-index: 100;
  width: fit-content;
  margin-bottom: ${spacing.medium};
  display: ${display.flex};
  flex-direction: ${flex.direction.row};
  align-items: center;
`;

const SelectLabelWrapper = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectedDescription = styled.Text`
  padding-left: 1rem;
  padding-right: 0.5rem;
  font-family: ${font.defaultMedium};
  font-size: ${text.small.size};
`;

export interface TableConfig {
  skipParam?: number;
  sortConfig?: {
    sortField?: string;
    sortOrder?: SortOrder;
  };
  headerConfig: {
    showConfig: SelectItemInterface<number>[];
    orderConfig: number[];
  };
}

export interface TableContent {
  data: ReactElement[];
  id: string;
  canDelete?: boolean;
  cannotDeleteDescription?: string;
  canEdit?: boolean;
  hideDeleteButton?: boolean;
  hideEditButton?: boolean;
  hideEditScheduleButton?: boolean;
}

interface TableProps {
  tableId: string;
  headerItems: HeaderItem[];
  tableContent: TableContent[];
  columnSizes: number[];
  sortList?: (sortField: string, order?: SortOrder) => void;
  onEditItem?: (id: string) => void;
  onEditSchedule?: (id: string) => void;
  onDeleteItem?: (id: string) => void;
  onShowCustomFields?: (id: string) => void;
  selectable?: boolean;
  onChangeSelectedIds?: (selectedIds: string[], isAllSelected?: boolean) => void;
  onLineClick?: (id: string) => void;
  rowIsActive?: boolean;
  activeRowId?: string;
  noShadow?: boolean;
  noPadding?: boolean;
  noDrag?: boolean;
  hideActions?: boolean;
  headerPaddingTop?: number;
  headerPaddingBottom?: number;
  rowPaddingBottom?: number;
  rowPaddingTop?: number;
  preserveHeaderBorderTop?: boolean;
  hideColumn?: boolean;
  sortOrder?: SortOrder;
  sortField?: string;
  recordsCount?: number;
  selectToggle?: ReactNode;
  skipParam?: number;
  setSkipParam?(newValue: number): void;
}

const Table: FC<TableProps> = ({
  tableId,
  headerItems,
  tableContent,
  columnSizes,
  sortList,
  onEditItem,
  onEditSchedule,
  onDeleteItem,
  onShowCustomFields,
  selectable,
  onChangeSelectedIds,
  onLineClick,
  activeRowId = "",
  noShadow = false,
  noPadding = false,
  noDrag = false,
  hideActions = false,
  headerPaddingTop,
  headerPaddingBottom,
  rowPaddingBottom,
  rowPaddingTop,
  preserveHeaderBorderTop,
  hideColumn,
  sortOrder,
  sortField,
  recordsCount,
  selectToggle,
  skipParam,
  setSkipParam,
  children,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [headerSelection, setHeaderSelection] = useState<SelectItemInterface<number>[]>([]);
  const [headerIndexes, setHeaderIndexes] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [dismissibleCannotDeleteMessage, setDismissibleCannotDeleteMessage] =
    useState<string | null>(null);
  const tableContentIds = useMemo(() => {
    return tableContent.map((item) => item.id);
  }, [tableContent]);

  const [, setCurrentActiveRow] = useState<number | null>(null);

  const flexSize = useMemo(() => headerSelection
      .reduce((sum, item) => item.isActive ? (columnSizes[item.value] || 0) + sum : sum, 0)
    , [columnSizes, headerSelection]);

  useEffect(() => {
    setHeaderIndexes(headerItems.map((item, index) => index));
    const selection = headerItems.map((item, index) => ({
      label: item.headerLabel as string,
      value: index,
      isActive: true,
    }));
    setHeaderSelection(selection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerItems.length]);

  useEffect(() => {
    if (!headerSelection.length && !headerIndexes.length) {
      (async () => {
        const tableDataJsonString = await getFromStorage(`${storageKeys.tableConfig}_${tableId}`);

        if (typeof tableDataJsonString === "string") {
          const { headerConfig, sortConfig, skipParam } = JSON.parse(tableDataJsonString) as TableConfig;

          if (skipParam) {
            setSkipParam && setSkipParam(skipParam);
          }

          /*
           This is the problem the list view keeps sorting itself indefinitely. Needs to be investigated as to why this occurs.
           */
          // if (sortList && sortConfig?.sortField && sortConfig?.sortOrder) {
          //   sortList(sortConfig?.sortField, sortConfig?.sortOrder);
          // }

          const { showConfig, orderConfig } = headerConfig;

          if (!showConfig) return;

          const columnNamesFromStore = showConfig.reduce((names, item) => `${names},${item.label}`, '');
          const columnNames = headerItems.reduce((names, item) => `${names},${item.headerLabel}`, '');

          if (columnNamesFromStore === columnNames) {
            setHeaderSelection(showConfig);
            setHeaderIndexes(orderConfig);
          }
        }
      })();
    }

    return () => {
      if (headerSelection.length && headerIndexes.length) {
        const config: TableConfig = {
          skipParam,
          sortConfig: {
            sortOrder,
            sortField,
          },
          headerConfig: {
            showConfig: headerSelection,
            orderConfig: headerIndexes,
          }
        };

        saveInStorage(`${storageKeys.tableConfig}_${tableId}`, JSON.stringify(config));
      }
    }
  }, [headerIndexes, headerItems, headerSelection, sortField, sortList, sortOrder, skipParam, setSkipParam]);

  useEffect(() => {
    setSelectedIds([]);
  }, [tableContentIds, setSelectedIds]);

  useEffect(() => {
    if (onChangeSelectedIds) {
      onChangeSelectedIds(selectedIds, isAllSelected);
    }
  }, [selectedIds, isAllSelected, onChangeSelectedIds]);

  const onChangeSelection = useCallback(
    (id: string) => (checked: boolean) => {
      setSelectedIds((oldValue) => {
        if (checked && !oldValue.includes(id)) {
          return [...oldValue, id];
        }
        if (!checked) {
          return oldValue.filter((item) => item !== id);
        }
        return oldValue;
      });
    },
    [setSelectedIds],
  );

  const onChangeSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedIds(tableContentIds);
      } else {
        setSelectedIds([]);
        setIsAllSelected(false);
      }
    },
    [tableContentIds],
  );

  const handleDrop = (dropRes: DropResult) => {
    const destination = dropRes.destination?.index;
    const source = dropRes.source?.index;

    if (destination !== undefined && source !== undefined && destination !== source) {
      const extracted = headerIndexes.splice(source, 1);
      headerIndexes.splice(destination, 0, ...extracted);
      setHeaderIndexes([...headerIndexes]);
    }
  };

  const handleHeaderSelectionChange = (values: SelectItemInterface<number>[]) => {
    const hiddenIndexes = values.map(({ value }) => value);
    // values.forEach(({ value }) => headerSelection[value] = )
    setHeaderSelection(headerSelection.map((item) => ({
      ...item,
      isActive: hiddenIndexes.includes(item.value),
    })));
  };

  return (
    <>
      <List noShadow={noShadow} noPadding={noPadding}>
        <HeaderWrapper>
          {hideColumn && (
            <SelectWrapper>
              <Select
                items={headerSelection}
                onChange={handleHeaderSelectionChange}
                placeholder={t("table.selectShowColumn")}
                isMultiSelect={true}
              >
                {selectToggle}
              </Select>
            </SelectWrapper>
          )}
          {children}
        </HeaderWrapper>
        <ListHeaderRow
          preserveHeaderBorderTop={preserveHeaderBorderTop}
          headerPaddingTop={headerPaddingTop}
          headerPaddingBottom={headerPaddingBottom}
        >
          {selectable && (
            <TableHeaderItem
              headerItem={{
                headerContent: (
                  <Checkbox
                    onChange={onChangeSelectAll}
                    checked={selectedIds.length === tableContent.length}
                    subChecked={selectedIds.length > 0}
                    hasMarginBottom={false}
                  />
                ),
                isSortable: false,
              }}
              columnSize={0.25}
              noMargin={true}
              position={flex.justifyContent.center}
            />
          )}
          {noDrag ? (
            <TableHeaderItemsWrapper flex={flexSize} isDragging={false}>
              {headerIndexes.map((headerIndex) => (
                headerSelection[headerIndex].isActive && (
                  <TableHeaderItem
                    noDrag={noDrag}
                    headerItem={headerItems[headerIndex]}
                    columnSize={columnSizes[headerIndex]}
                    sortList={(field) =>
                      sortList && sortList(field, sortOrder ? (sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC) : undefined)
                    }
                  />
                )
              ))}
            </TableHeaderItemsWrapper>
          ) : (
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="table-header" direction="horizontal">
                {(provided, snapshot) => (
                  <TableHeaderItemsWrapper
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    flex={flexSize}
                    isDragging={snapshot.isDraggingOver}
                  >
                    {headerIndexes.map((headerIndex, index) => (
                      headerSelection[headerIndex].isActive && (
                        <Draggable
                          key={headerItems[headerIndex].headerLabel || headerIndex.toString()}
                          draggableId={headerItems[headerIndex].headerLabel || headerIndex.toString()}
                          index={index}
                        >
                          {({ draggableProps: { style, ...draggableProps }, dragHandleProps, innerRef }) => (
                            <TableHeaderItem
                              ref={innerRef}
                              style={{
                                ...style,
                                position: style?.position === Position.fixed ? Position.absolute : Position.relative,
                                transform: convertTranslate(style?.transform || "", [50, 0]),
                              }}
                              {...draggableProps}
                              {...dragHandleProps}
                              headerItem={headerItems[headerIndex]}
                              columnSize={columnSizes[headerIndex]}
                              sortList={(field) =>
                                sortList && sortList(field, sortOrder ? (sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC) : undefined)
                              }
                            />
                          )}
                        </Draggable>
                      )
                    ))}
                  </TableHeaderItemsWrapper>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {!hideActions && (
            <TableHeaderItem
              headerItem={{ headerContent: <></>, isSortable: false }}
              columnSize={1}
            />
          )}
        </ListHeaderRow>
        {!!tableContent.length ? (
          <>
            {selectedIds.length === tableContent.length && (
              <HoverableListRow
                paddingTop={rowPaddingTop}
                paddingBottom={rowPaddingBottom}
                isActive
              >
                <SelectLabelWrapper>
                  {isAllSelected ? (
                    <>
                      <SelectedDescription>
                        {t('table.allTableRowSelected', { count: recordsCount || '' })}
                      </SelectedDescription>
                      <Link
                        isActive
                        onPress={() => {
                          setIsAllSelected(false);
                          setSelectedIds([]);
                        }}
                      >
                        {t('table.cancelSelection')}
                      </Link>
                    </>
                  ) : (
                    <>
                      <SelectedDescription>
                        {t('table.allRowSelected', { count: tableContent.length })}
                      </SelectedDescription>
                      <Link isActive onPress={() => setIsAllSelected(true)}>
                        {t('table.selectAll', { count: recordsCount || '' })}
                      </Link>
                    </>
                  )}
                </SelectLabelWrapper>
              </HoverableListRow>
            )}
            {tableContent.map(
              (
                {
                  data,
                  id,
                  canDelete,
                  cannotDeleteDescription,
                  canEdit,
                  hideEditButton,
                  hideEditScheduleButton,
                  hideDeleteButton,
                },
                index,
              ) => (
                <HoverableListRow
                  paddingTop={rowPaddingTop}
                  paddingBottom={rowPaddingBottom}
                  isActive={activeRowId === id}
                  onClick={() => {
                    if (onLineClick) {
                      onLineClick(id);
                      setCurrentActiveRow(index);
                    }
                  }}
                  key={index}
                >
                  {selectable && (
                    <TableDataCell columnSize={0.25} noMargin={true} position={flex.justifyContent.center}>
                      <Checkbox
                        onChange={onChangeSelection(id)}
                        checked={selectedIds.includes(id)}
                        hasMarginBottom={false}
                      />
                    </TableDataCell>
                  )}
                  {headerIndexes.map((headerIndex) =>
                    headerSelection[headerIndex].isActive && (
                      <TableDataCell key={headerIndex} columnSize={columnSizes[headerIndex]}>
                        {data[headerIndex]}
                      </TableDataCell>
                    ),
                  )}
                  {!hideActions && id && (
                    <TableDataCell isAction={true}>
                      {!!onShowCustomFields && (
                        <ButtonWrapper>
                          <IconButtonSmall
                            icon={customFieldIcon}
                            hoverIcon={customFieldIconActive}
                            onPress={() => onShowCustomFields(id)}
                            backgroundHoverColor={color.secondaryLight}
                          />
                        </ButtonWrapper>
                      )}
                      {hideEditScheduleButton !== true && !!onEditSchedule && (
                        <ButtonWrapper>
                          <IconButtonSmall
                            disabled={!canEdit}
                            onPress={() => {
                              if (!canEdit) {
                                return;
                              }
                              onEditSchedule(id);
                            }}
                            icon={editScheduleIcon}
                            hoverIcon={canEdit ? editScheduleIconActive : undefined}
                            backgroundHoverColor={
                              canEdit ? color.tertiaryLight : undefined
                            }
                          />
                        </ButtonWrapper>
                      )}
                      {hideEditButton !== true && (
                        <ButtonWrapper>
                          <IconButtonSmall
                            disabled={!canEdit}
                            onPress={() => {
                              if (!canEdit) {
                                return;
                              }
                              if (onEditItem) onEditItem(id);
                            }}
                            icon={editIcon}
                            hoverIcon={canEdit ? editIconActive : undefined}
                            backgroundHoverColor={
                              canEdit ? color.tertiaryLight : undefined
                            }
                          />
                        </ButtonWrapper>
                      )}
                      {!hideDeleteButton && (
                        <ButtonWrapper>
                          <IconButtonSmall
                            disabled={!canDelete && !cannotDeleteDescription}
                            icon={deleteIcon}
                            hoverIcon={
                              canDelete || cannotDeleteDescription
                                ? deleteIconActive
                                : undefined
                            }
                            onPress={() => {
                              if (!canDelete) {
                                cannotDeleteDescription &&
                                setDismissibleCannotDeleteMessage(
                                  cannotDeleteDescription,
                                );
                                return;
                              }
                              if (onDeleteItem) onDeleteItem(id);
                            }}
                            backgroundHoverColor={
                              canDelete ? color.primaryLight : undefined
                            }
                          />
                        </ButtonWrapper>
                      )}
                    </TableDataCell>
                  )}
                </HoverableListRow>
              ),
            )}
          </>
          ) : (
          <NoResults />
          )}
      </List>
      <OverlayRightWrapper>
        {dismissibleCannotDeleteMessage && (
          <DismissAbleOverlay
            title={t("table.cannotDeleteTitle")}
            onCloseCallback={() => setDismissibleCannotDeleteMessage(null)}
            backgroundColor={color.tertiary}
          >
            <SmallText>{dismissibleCannotDeleteMessage}</SmallText>
          </DismissAbleOverlay>
        )}
      </OverlayRightWrapper>
    </>
  );
};

export default Table;
