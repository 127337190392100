import React, { FC, useRef } from "react";
import { useHover } from "react-native-web-hooks";
import styled, { css } from "styled-components/native";
import { spacing, color, font, text } from "../../styles/theme";
import { TouchableOpacityProps } from "react-native";
import Text from "../../styles/Text";
import { convertObjectToStyle } from "../../utils";
import { TextProps } from "../../styles/Text/text";

interface LinkElementProps extends TouchableOpacityProps {
  hasPadding?: boolean;
  style?: any;
}

const StyledLink = styled.TouchableOpacity<LinkElementProps>`
  ${({ hasPadding }) =>
    hasPadding &&
    css`
      padding: 0 ${spacing.medium};
    `}

  ${({ style }) =>
    style &&
    css`
      ${style}
    `}
  ${(props) => convertObjectToStyle(props, ['marginLeft', 'color'])};
`;

interface LinkTextProps {
  isActive?: boolean;
  isHovering: boolean;
  isDisabled?: any;
  underline?: boolean;
}

const StyledText = styled(Text)<LinkTextProps>`
  font-family: ${font.defaultMedium};
  font-size: ${text.small.size};
  line-height: ${text.medium.lineHeight};
  ${({ isActive, isHovering, isDisabled, underline }) =>
    isDisabled
      ? css`opacity: 0.5;`
      : css`
        color: ${isActive || isHovering ? color.secondary : "inherit"};
        text-decoration: ${isHovering || underline ? "underline" : "none"};
        text-decoration-color: ${color.secondary};
      `
    };
  ${(props) => convertObjectToStyle(props, ['fontSize'])};
`;

interface LinkProps extends TouchableOpacityProps {
  isActive?: boolean;
  hasPadding?: boolean;
  marginLeft?: string | number;
  underline?: boolean;
  textStyle?: TextProps;
  color?: string;
}

const Link: FC<LinkProps> = ({ children, isActive, textStyle, underline, ...rest }) => {
  const element = useRef<any>(null);
  const isHovering = useHover(element);

  return (
    <StyledLink ref={element} {...rest}>
      <StyledText
        {...textStyle}
        underline={underline}
        numberOfLines={1}
        isActive={isActive}
        isHovering={isHovering}
        isDisabled={rest.disabled}
      >
        {children}
      </StyledText>
    </StyledLink>
  );
};

export default Link;
