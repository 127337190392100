module["exports"] = [
  "Մարիա",
  "Նարե",
  "Մանե",
  "Մարի",
  "Մարիամ",
  "Անի",
  "Անգելինա",
  "Անահիտ",
  "Էլեն",
  "Միլենա",
  "Եվա",
  "Աննա",
  "Արփի",
  "Գայանե",
  "Լիլիթ",
  "Մերի",
  "Վիկտորյա",
  "Արինա",
  "Յանա",
  "Սոնա",
  "Նատալի",
  "Գոհար",
  "Հասմիկ",
  "Սոֆի",
  "Էմիլի",
  "Սուսաննա",
  "Արևիկ",
  "Կարինե",
  "Լյուսի",
  "Արիանա",
  "Տաթև",
  "Լիկա",
  "Ռուզաննա",
  "Լիլի",
  "Սոֆյա",
  "Անուշ",
  "Էլինա",
  "Սառա",
  "Նանե",
  "Էվա",
  "Լիանա",
  "Մոնիկա",
  "Կարինա",
  "Լիա",
  "Նարինե",
  "Լուսինե"
];