import { useEffect, useState } from "react";
import { CompanyTransportOption } from "../../bookmydesk-api-sdk-typescript-axios";
import { useApi } from "./useApi";
import { useClient } from "./useClient";

const useCompanyTransportOptions = ({ companyId }: { companyId?: string }): CompanyTransportOption[] => {
  const client = useClient();
  const { handleRequest } = useApi();

  const [companyTransportOptions, setCompanyTransportOptions] = useState<CompanyTransportOption[]>([]);

  useEffect(() => {
    const init = async () => {
      if (!companyId) {
        return setCompanyTransportOptions([]);
      }
      const response = await handleRequest(client.getCompanyTransportOptions(companyId));

      setCompanyTransportOptions(response?.data.result.items || []);
    };
    init();
  }, [handleRequest, companyId, client]);

  return companyTransportOptions;
};

export default useCompanyTransportOptions;
