module["exports"] = [
  "کتاب ها",
  "فیلم ها",
  "موزیک",
  "بازی ها",
  "الکترونیک",
  "رایانه",
  "خانه",
  "باغ",
  "ابزار",
  "خواربار",
  "سلامتی",
  "زیبایی",
  "اسباب بازی",
  "بچه ها",
  "بچه",
  "تن پوش",
  "کفش",
  "زیور آلات",
  "ورزش ها",
  "بیرون از خانه",
  "اتومبیل",
  "صنعتی"
];
