module["exports"] = [
  "Alessandra",
  "Alice",
  "Aline",
  "Alícia",
  "Ana Clara",
  "Ana Júlia",
  "Ana Laura",
  "Ana Luiza",
  "Antonella",
  "Beatriz",
  "Bruna",
  "Carla",
  "Cecília",
  "Clara",
  "Célia",
  "Dalila",
  "Eduarda",
  "Elisa",
  "Eloá",
  "Emanuelly",
  "Esther",
  "Fabrícia",
  "Felícia",
  "Giovanna",
  "Helena",
  "Heloísa",
  "Isabel",
  "Isabela",
  "Isabella",
  "Isabelly",
  "Isis",
  "Janaína",
  "Joana",
  "Júlia",
  "Karla",
  "Lara",
  "Larissa",
  "Laura",
  "Lavínia",
  "Liz",
  "Lorena",
  "Lorraine",
  "Luiza",
  "Lívia",
  "Maitê",
  "Manuela",
  "Marcela",
  "Margarida",
  "Maria",
  "Maria Alice",
  "Maria Cecília",
  "Maria Clara",
  "Maria Eduarda",
  "Maria Helena",
  "Maria Júlia",
  "Maria Luiza",
  "Mariana",
  "Marina",
  "Marli",
  "Meire",
  "Melissa",
  "Morgana",
  "Márcia",
  "Mércia",
  "Natália",
  "Núbia",
  "Ofélia",
  "Paula",
  "Rafaela",
  "Rebeca",
  "Roberta",
  "Sara",
  "Sarah",
  "Sophia",
  "Suélen",
  "Sílvia",
  "Talita",
  "Valentina",
  "Vitória",
  "Yasmin"
];