import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components/native";
import {
  InlineResponse400Errors,
  Language,
  UserGroupLink,
  UserUpdate,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select, { SelectItemInterface } from "../../../components/Select";
import { useAuth } from "../../../context/authContext";
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { t } from "../../../i18n";
import { useHistory, useParams } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { color, spacing } from "../../../styles/theme";
import { SmallTitle, MediumTitle } from "../../../styles/Title";
import { ThenArg } from "../../../types";
import { isValidEmail } from "../../../utils";
import { getLanguageItems } from "../CompanyOverview/Create";
import { compensationEnabled } from "../CompanySettings";


const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Update: FC = () => {
  type UserItem = ThenArg<
    ReturnType<typeof client.getUser>
  >["data"]["result"]["user"];
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const [user, setUser] = useState<UserItem | null>(null);
  const [firstname, setFirstname] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  //Option for the compensation module
  const [compensationDailyDistance, setCompensationDailyDistance] = useState("");
  const [defaultTransportOptions, setDefaultTransportOption] = useState<SelectItemInterface[]>(
  );
  const [selectedTransportOption, setSelectedTransportOption] = useState<UserGroupLink[]>(
    []
  );

  const [languages, setLanguages] = useState<SelectItemInterface[]>(
    getLanguageItems()
  );
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    Language.Nl
  );
  const [userGroups, setUserGroups] = useState<SelectItemInterface[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroupLink[]>(
    []
  );
  const [sentInvitationMail, setSentInvitationMail] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  const userCompany = userState?.companyIds[0];

  useEffect(() => {
    if (userCompany) {
      const fetchData = async () => {
        const userGroupsResponse = await handleRequest(
          client.listUserGroups(userCompany)
        );
        if (!userGroupsResponse || !userGroupsResponse.data.result) {
          return;
        }

        const userResponse = await handleRequest(client.getUser(id));
        if (!userResponse || !userResponse.data.result) {
          return;
        }

        setUser(userResponse.data.result.user);

        const { items: groups } = userGroupsResponse.data.result;
        const { firstName, title, lastName, email, compensationDailyDistance, language, userGroups } =
          userResponse.data.result.user;
        setFirstname(firstName || "");
        setJobTitle(title || "");
        setLastname(lastName || "");
        setEmail(email || "");
        setSelectedLanguage(language);
        setLanguages(
          languages.map((l) => ({
            ...l,
            isActive: l.value === language,
          }))
        );
        setCompensationDailyDistance(compensationDailyDistance || "");
        setDefaultTransportOption(defaultTransportOptions);

        setSelectedUserGroups(
          userGroups.map((g) => ({
            id: g.id,
          }))
        );
        const userGroupItems = groups.map((i) => ({
          label: i.label,
          value: i.id,
          isActive: userGroups.some((u) => u.id === i.id),
        }));
        setUserGroups(userGroupItems);
      };

      fetchData();
    }
  }, [id, client, handleRequest]);

  const updateLanguage = useCallback(
    (newValue) => {
      setSelectedLanguage(newValue[0].value);
    },
    [setSelectedLanguage]
  );

  const updateDefaultTransportOption = useCallback(
    (newValue) => {
      setDefaultTransportOption(newValue[0].value);
    },
    [setSelectedTransportOption]
  );

  const updateUserGroups = useCallback(
    (newValue) => {
      console.log("new value", newValue);
      const userGroups: UserGroupLink[] = newValue.map(
        (group: SelectItemInterface) => ({
          id: group.value,
        })
      );
      setSelectedUserGroups(userGroups);
    },
    [setSelectedUserGroups]
  );

  const isFormValid = useCallback(
    () =>
      Boolean(firstname) &&
      Boolean(lastname) &&
      isValidEmail(email) &&
      Boolean(selectedLanguage),
    [firstname, lastname, email, selectedLanguage]
  );

  const submitForm = useCallback(async () => {
    const data: UserUpdate = {
      firstName:
        user?._operations?.canUpdateName !== false ? firstname : undefined,
      title: user?._operations?.canUpdateName !== false ? jobTitle : undefined,
      lastName:
        user?._operations?.canUpdateName !== false ? lastname : undefined,
      email: user?._operations?.canUpdateEmail !== false ? email : undefined,
      language: selectedLanguage,
      compensationDailyDistance: compensationDailyDistance
        ? parseInt(compensationDailyDistance, 10)
        : 0,
      userGroups: selectedUserGroups,
    };
    try {
      const success = await handleRequest(client.updateUser(id, data));

      if (success) {
        if (sentInvitationMail) {
          await handleRequest(client.sendUsersInvitations({ userIds: [id] }));
        }
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    user?._operations?.canUpdateName,
    user?._operations?.canUpdateEmail,
    firstname,
    jobTitle,
    lastname,
    email,
    selectedLanguage,
    compensationDailyDistance,
    selectedTransportOption,
    selectedUserGroups,
    handleRequest,
    client,
    id,
    sentInvitationMail,
    history,
  ]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {(user?._operations?.canUpdateName === false ||
            user?._operations?.canUpdateEmail === false) && (
              <Notification type="warning">
                {t("admin.user.edit.ssoCannotUpdateAllFields")}
              </Notification>
            )}
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.user.edit.title")}
          </MediumTitle>
          <LabeledInput required label={t("admin.user.create.form.firstname")}>
            <Input
              onChangeText={setFirstname}
              value={firstname}
              placeholder={t("admin.user.create.form.firstname")}
              editable={user?._operations?.canUpdateName !== false}
            />
          </LabeledInput>
          <LabeledInput required label={t("admin.user.create.form.lastname")}>
            <Input
              onChangeText={setLastname}
              value={lastname}
              placeholder={t("admin.user.create.form.lastname")}
              editable={user?._operations?.canUpdateName !== false}
            />
          </LabeledInput>
          <LabeledInput required label={t("admin.user.create.form.email")}>
            <Input
              onChangeText={setEmail}
              value={email}
              placeholder={t("admin.user.create.form.email")}
              editable={user?._operations?.canUpdateEmail !== false}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.jobTitle")}>
            <Input
              onChangeText={setJobTitle}
              value={jobTitle}
              placeholder={t("admin.user.create.form.jobTitle")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.user.create.form.userGroups")}
            style={{ zIndex: 5 }}
          >
            <Select
              isMultiSelect={true}
              items={userGroups}
              onChange={updateUserGroups}
              placeholder={t("admin.user.create.form.userGroups")}
            />
          </LabeledInput>
          <SmallTitle hasMarginBottom>
            {t("admin.user.edit.userGeneral")}
          </SmallTitle>
          <LabeledInput
            required
            label={t("admin.user.create.form.language")}
            style={{ zIndex: 4 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={languages}
              onChange={updateLanguage}
              placeholder={t("admin.user.create.form.language")}
            />
          </LabeledInput>
          <SmallTitle hasMarginBottom>
            {t("admin.user.edit.userCompensation")}
          </SmallTitle>
          <LabeledInput
            label={t("admin.user.create.form.dailyCompensation")}
            helperText={t("admin.user.create.form.helper.dailyCompensation")}
          >
            <Input
              onChangeText={setCompensationDailyDistance}
              value={compensationDailyDistance}
              placeholder={t("admin.user.create.form.dailyCompensation")}
            />
          </LabeledInput>
          <LabeledInput>
            <Checkbox
              label={t("admin.user.create.form.sendInvite")}
              onChange={setSentInvitationMail}
              checked={sentInvitationMail}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.user.edit.submit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Update;
