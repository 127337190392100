import React, { FC, useCallback, useState } from "react";

import styled from "styled-components/native";
import { t } from "../../../i18n";
import {
  TimeslotWithoutId,
  TimeslotType,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select, { SelectItemInterface } from "../../../components/Select";

import { MediumTitle } from "../../../styles/Title";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";

import { useHistory } from "../../../routing";
import { useClient } from "../../../hooks/useClient";
import { InputValidationError, useApi } from "../../../hooks/useApi";

import { isValidTime } from "../../../utils";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

export const timeslotTypes = [
  {
    label: t(`admin.timeslot.types.${TimeslotType.Desk}`),
    value: TimeslotType.Desk,
  },
  {
    label: t(`admin.timeslot.types.${TimeslotType.Lunch}`),
    value: TimeslotType.Lunch,
  },
  {
    label: t(`admin.timeslot.types.${TimeslotType.Meeting}`),
    value: TimeslotType.Meeting,
  },
  {
    label: t(`admin.timeslot.types.${TimeslotType.Visitor}`),
    value: TimeslotType.Visitor,
  },
];

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [selectedType, setSelectedType] = useState<TimeslotType | null>(null);
  const [types] = useState<SelectItemInterface[]>(timeslotTypes);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  const isFormValid = useCallback(
    () =>
      Boolean(name) &&
      Boolean(selectedType) &&
      isValidTime(from) &&
      isValidTime(to),
    [name, selectedType, from, to]
  );

  const submitForm = useCallback(async () => {
    const timeslot: TimeslotWithoutId = { name, type: selectedType!, from, to };

    try {
      const success = await handleRequest(client.addTimeslot(timeslot));
      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [handleRequest, client, name, selectedType, from, to, history]);

  const updateType = useCallback(
    (newValue) => {
      setSelectedType(newValue[0].value);
    },
    [setSelectedType]
  );

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.timeslot.form.addTitle")}
          </MediumTitle>
          <LabeledInput label={t("admin.timeslot.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.timeslot.form.name")}
            />
          </LabeledInput>
          <LabeledInput
            style={{ zIndex: 2 }}
            required
            label={t("admin.timeslot.form.type")}
          >
            <Select
              isMultiSelect={false}
              required
              items={types}
              onChange={updateType}
              placeholder={t("admin.timeslot.form.type")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.timeslot.form.start")}
            helperText={t("admin.timeslot.form.helper.from")}
            required
          >
            <Input
              onChangeText={setFrom}
              value={from}
              placeholder={t("admin.timeslot.form.start")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.timeslot.form.end")}
            helperText={t("admin.timeslot.form.helper.to")}
            required
          >
            <Input
              onChangeText={setTo}
              value={to}
              placeholder={t("admin.timeslot.form.end")}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.timeslot.form.createSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
