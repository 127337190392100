import React, { FC, useCallback } from 'react';
import { View } from "react-native";
import styled from "styled-components/native";
import HeaderCard from '../../../../components/HeaderCard';
import Toggle from "../../../../components/Toggle";
import { HorizontalCardDivider } from "../../../../styles/Card";
import Input from "../../../../styles/Input";
import {
  color,
  font,
  margin,
  text,
  display,
  flex,
  borderRadius, spacing,
} from '../../../../styles/theme';
import Text from "../../../../styles/Text";
import { t } from "../../../../i18n";
import generalSettingIcon from '../../../../assets/icons/icon-general-setting.svg';
import LabeledInput from '../../../../components/LabeledInput';

export const CardInGeneralTitle = styled.Text`
  font-family: ${font.defaultSemiBold};
  font-size: ${text.medium.size};
`;

export const CardInGeneral = styled.View`
  margin-bottom: ${spacing.medium};
  padding: ${margin.large};
  display: ${display.flex};
  flex-direction: ${flex.direction.column};
  justify-content: ${flex.justifyContent.spaceBetween};
  border: solid rgb(239, 239, 239) 1px;
  border-radius: ${borderRadius.medium};
`;

export const FlexRow = styled.View`
  display: ${display.flex};
  flex-direction: ${flex.direction.row};
  justify-content: ${flex.justifyContent.spaceBetween};
  width: 100%;
`;

interface GeneralCardProps {
  daysAdvance: string;
  forceTimeSlotsFeature: boolean;
  parkingSpotsAsResourceEnabled: boolean;
  checkInFeature: boolean;
  recurringReservationFeature: boolean;
  geofenceFeature: boolean;
  updateDaysAdvance: (value: any) => void;
  setForceTimeSlotsFeature: (value: boolean) => void;
  setParkingSpotsAsResourceEnabled: (value: boolean) => void;
  setCheckInFeature: (value: boolean) => void;
  setRecurringReservationFeature: (value: boolean) => void;
  setGeofenceFeature: (value: boolean) => void;
}

const GeneralCard: FC<GeneralCardProps> = ({
  daysAdvance,
  forceTimeSlotsFeature,
  parkingSpotsAsResourceEnabled,
  checkInFeature,
  recurringReservationFeature,
  geofenceFeature,
  updateDaysAdvance,
  setForceTimeSlotsFeature,
  setParkingSpotsAsResourceEnabled,
  setCheckInFeature,
  setRecurringReservationFeature,
  setGeofenceFeature,
}) => {
  const card = useCallback((
    label: string,
    isActive: boolean,
    setIsActive: (value: boolean) => void,
  ) => (
    <CardInGeneral>
      <FlexRow>
        <CardInGeneralTitle>
          {t(label)}
        </CardInGeneralTitle>

        <View style={{ marginTop: "-16px" }}>
          <Toggle toggleValue={isActive} onChange={setIsActive} />
        </View>
      </FlexRow>
      <HorizontalCardDivider margin="4px 0px" />
      <View>
        <Text color={color.darker} opacity={0.6}>
          {t('admin.settings.generalDescription')}
        </Text>
      </View>
    </CardInGeneral>
  ), []);

  return (
    <HeaderCard
      badgeColor="transparent"
      icon={generalSettingIcon}
      title={t("admin.settings.sidebar.generalSettings")}
    >
      <LabeledInput label={t("admin.company.form.days")} required>
        <Input
          onChangeText={updateDaysAdvance}
          value={daysAdvance}
          placeholder={t("admin.company.form.days") + " *"}
        />
      </LabeledInput>
      {card("admin.company.form.forceTimeslots", forceTimeSlotsFeature, setForceTimeSlotsFeature)}
      {card("admin.company.form.parkingSpotsAsResources", parkingSpotsAsResourceEnabled, setParkingSpotsAsResourceEnabled)}
      {card("admin.company.form.checkIn", checkInFeature, setCheckInFeature)}
      {card("admin.company.form.recurring", recurringReservationFeature, setRecurringReservationFeature)}
      {card("admin.company.form.geofence", geofenceFeature, setGeofenceFeature)}
    </HeaderCard>
  );
};

export default GeneralCard;
