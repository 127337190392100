import React, { FC } from 'react';
import HeaderCard from '../../../../components/HeaderCard';
import Input from "../../../../styles/Input";
import { t } from "../../../../i18n";
import LabeledInput from '../../../../components/LabeledInput';
import companyIcon from '../../../../assets/icons/icon-company.svg';
import userIcon from '../../../../assets/icons/icon-user.svg';

interface GeneralCardProps {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  setName: (value: string) => void;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setPhoneNumber: (value: string) => void;
}

const CompanyCard: FC<GeneralCardProps> = ({
  name,
  firstName,
  lastName,
  email,
  phoneNumber,
  setName,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
}) => {
  return (
    <>
      <HeaderCard
        badgeColor="transparent"
        icon={companyIcon}
        title={t("admin.company.form.companyInformation")}
      >
        <LabeledInput label={t("admin.company.form.name")} required>
          <Input
            onChangeText={setName}
            value={name}
            placeholder={t("admin.company.form.name") + " *"}
          />
        </LabeledInput>
        <LabeledInput
          label={t("admin.company.form.address")}
          required
        >
          <Input
            value=""
            placeholder={t("admin.company.form.address") + " *"}
          />
        </LabeledInput>
        <LabeledInput label={t("admin.company.form.zipCode")} required>
          <Input
            value=""
            placeholder={t("admin.company.form.zipCode") + " *"}
          />
        </LabeledInput>
        <LabeledInput label={t("admin.company.form.tookPlace")} required>
          <Input
            value=""
            placeholder={t("admin.company.form.tookPlace") + " *"}
          />
        </LabeledInput>
      </HeaderCard>
      <HeaderCard
        badgeColor="transparent"
        icon={userIcon}
        title={`${t("admin.company.form.general")} / ${t("admin.company.form.contactHeader")}`}
      >
        <LabeledInput label={t("admin.company.form.personName")} required>
          <Input
            onChangeText={setFirstName}
            value={firstName}
            placeholder={t("admin.company.form.personName") + " *"}
          />
        </LabeledInput>
        <LabeledInput label={t("admin.company.form.personLastname")} required>
          <Input
            onChangeText={setLastName}
            value={lastName}
            placeholder={t("admin.company.form.personLastname") + " *"}
          />
        </LabeledInput>
        <LabeledInput
          label={t("admin.company.form.email")}
          required
        >
          <Input
            onChangeText={setEmail}
            value={email}
            placeholder={t("admin.company.form.email") + " *"}
          />
        </LabeledInput>
        <LabeledInput label={t("admin.company.form.phone")} required>
          <Input
            onChangeText={setPhoneNumber}
            value={phoneNumber}
            placeholder={t("admin.company.form.phone") + " *"}
          />
        </LabeledInput>
      </HeaderCard>
    </>
  );
};

export default CompanyCard;
