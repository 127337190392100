import React, { FC, useRef } from "react";
import { color, borderRadius, text, font } from "../../styles/theme";
import styled, { css } from "styled-components/native";
import { useHover, useFocus } from "react-native-web-hooks";
import { TouchableOpacityProps } from "react-native";
import Text from "../../styles/Text";

const buttonSize = "36px";

interface DefaultButtonOptions extends TouchableOpacityProps {
  isActive?: boolean;
  isHovering?: boolean;
  style?: any;
}

export const PaginatorButton = styled.TouchableOpacity<DefaultButtonOptions>`
  width: ${buttonSize};
  height: ${buttonSize};
  border-radius: ${borderRadius.full};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  overflow: hidden;
  position: relative;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${color.darker};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

interface LinkTextProps {
  isActive?: boolean;
  isHovering: boolean;
}

const StyledPaginatorText = styled(Text)<LinkTextProps>`
  font-family: ${font.defaultMedium};
  font-size: ${text.small.size};
  ${({ isActive, isHovering }) =>
    isActive
      ? css`
          color: ${color.white};
        `
      : css`
          color: ${isHovering ? color.secondary : color.dark};
          text-decoration: ${isHovering ? "underline" : "none"};
          text-decoration-color: ${color.secondary};
        `};
`;

interface LinkButtonInterface extends TouchableOpacityProps {
  isActive?: boolean;
}

const PaginatorLink: FC<LinkButtonInterface> = ({
  children,
  isActive,
  ...rest
}) => {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const hasFocus = useFocus(ref);

  return (
    <PaginatorButton
      isActive={isActive}
      ref={ref}
      isHovering={isHovering || hasFocus}
      {...rest}
    >
      <StyledPaginatorText isActive={isActive} isHovering={isHovering}>
        {children}
      </StyledPaginatorText>
    </PaginatorButton>
  );
};

export default PaginatorLink;
