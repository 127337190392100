import React, { FC, useState, useCallback, useEffect } from "react";
import Card from "../../styles/Card";
import styled, { css } from "styled-components/native";
import Text, { SmallText } from "../../styles/Text";
import IconBadge from "../IconBadge";
import { font, spacing, chevron, dot, borderRadius, text } from "../../styles/theme";
import { HorizontalCardDivider } from "../../styles/Card";
import chevronDownIcon from "../../assets/icons/chevron_down_secondary.svg";
import chevronRightIcon from "../../assets/icons/chevron_right.svg";
import Dropdown from "../Dropdown";
import Link from "../Link";
import { OccupationChartProps } from "../../../fake-data/dashboardData";
import { Image } from "react-native";
import { t } from "../../i18n";

const cardHeaderStyle = `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`;

const TouchableCardHeader = styled.TouchableOpacity`
  ${cardHeaderStyle}
`;

const CardHeader = styled.View`
  ${cardHeaderStyle}
`;

const Spacer = styled.View`
  margin-bottom: 15px;
`;

const HeaderCardWrapper = styled(Card)`
  padding: ${spacing.medium} ${spacing.large};
  margin-bottom: ${spacing.large};
`;

export const CardTitle = styled(Text)`
  font-family: ${font.defaultMedium};
  margin-left: ${({ noMarginleft }) => (!noMarginleft ? spacing.medium : "0")};
`;

const CardTitleNoMarginLeft = styled(Text)`
  font-family: ${font.defaultMedium};
  font-size: ${text.medium.size};
`;

const Icon = styled.Image`
  width: ${chevron.width};
  height: ${chevron.width};
  margin-left: 30px;
`;

const CardTitleWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
`;

export interface DropdownItemProps {
  label: string;
  id: string;
}

export interface HeaderCardLegend {
  isActive: boolean;
  id: string;
}

export interface HeaderCardProps {
  icon?: string;
  iconAsBadge?: boolean;
  iconWidth?: number;
  iconHeight?: number;
  title: string;
  badgeColor?: string;
  last?: boolean;
  onPress?: () => void;
  dropdownItems?: Array<DropdownItemProps>;
  text?: string;
  legends?: Array<OccupationChartProps>;
  disableHorizontalDivider?: boolean;
  backgroundColor?: string;
  titleColor?: string;
  isAccordion?: boolean;
  titleSuffix?: JSX.Element;
  onChangeActiveLegends?: (legends: HeaderCardLegend[]) => void;
  onFilterChange?: (item: DropdownItemProps) => void;
}

const LegendList = styled.View`
  margin-left: ${spacing.large};
  display: flex;
  flex-direction: row;
`;

interface LegendListItemProps {
  isActive: boolean;
}

const LegendListItem = styled.TouchableOpacity<LegendListItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.2;
  margin-left: ${spacing.medium};
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}
`;

interface UsageItemDotProps {
  color: string;
}

const UsageItemDot = styled.View<UsageItemDotProps>`
  resize-mode: center;
  height: ${dot.small.size};
  width: ${dot.small.size};
  border-radius: ${borderRadius.full};

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `};
`;

const LegendListItemText = styled(SmallText)`
  margin-left: ${spacing.small};
`;

const HeaderCard: FC<HeaderCardProps> = ({
  icon,
  iconAsBadge = true,
  iconWidth,
  iconHeight,
  title,
  badgeColor,
  children,
  last,
  onPress,
  dropdownItems,
  legends,
  disableHorizontalDivider,
  backgroundColor,
  titleColor,
  titleSuffix,
  isAccordion,
  onChangeActiveLegends,
  onFilterChange,
}) => {
  const defaultFilter = dropdownItems ? dropdownItems[0] : null;
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const changeFilter = useCallback(
    (id: string) => {
      const item = dropdownItems?.find(
        (item: DropdownItemProps) => item.id === id
      );
      setSelectedFilter(item ? item : null);
      setIsOpen(false);
      onFilterChange && item ? onFilterChange(item) : null
    },
    [setSelectedFilter, setIsOpen, dropdownItems]
  );

  const getActiveLegends = useCallback(
    (legends?: Array<OccupationChartProps>) =>
      legends?.map((dataObject) => ({
        id: dataObject.id,
        color: dataObject.dataColor,
        isActive: true,
      })),
    []
  );

  const [activeLegends, setActiveLegends] = useState(getActiveLegends(legends));

  const updateActiveLegends = useCallback(
    (id: string) => {
      const updatedActiveLegends = activeLegends?.map((legend) => ({
        ...legend,
        isActive: legend.id === id ? !legend.isActive : legend.isActive,
      }));

      setActiveLegends(updatedActiveLegends);
      if (onChangeActiveLegends) {
        onChangeActiveLegends(updatedActiveLegends || []);
      }
    },
    [activeLegends, onChangeActiveLegends]
  );

  const handleClickHeader = () => {
    onPress && onPress();
    setIsCollapsed(!isCollapsed);
  }

  useEffect(() => {
    if(dropdownItems && !selectedFilter) {
      setSelectedFilter(dropdownItems[0])
    }
  })

  return (
    <HeaderCardWrapper last={last} backgroundColor={backgroundColor}>
      {(onPress || isAccordion) ? (
        <TouchableCardHeader onPress={handleClickHeader}>
          <CardTitleWrapper>
            {icon && iconAsBadge && (
              <IconBadge icon={icon} badgeColor="{badgeColor}" />
            )}
            {icon && !iconAsBadge && (
              <Image
                source={{
                  uri: icon.toString(),
                  width: iconWidth,
                  height: iconHeight,
                }}
              />
            )}
            {!icon && (
              <CardTitleNoMarginLeft color={titleColor}>
                {title}
              </CardTitleNoMarginLeft>
            )}
            {!!icon && <CardTitle color={titleColor}>{title}</CardTitle>}
            {titleSuffix}
          </CardTitleWrapper>
          {isAccordion
            ? <Icon width={10} source={{ uri: chevronDownIcon.toString() }}/>
            : <Icon width={10} source={{ uri: chevronRightIcon.toString() }}/>
          }
        </TouchableCardHeader>
      ) : (
        <CardHeader>
          <CardTitleWrapper>
            {icon && iconAsBadge && (
              <IconBadge icon={icon} badgeColor={badgeColor} />
            )}
            {icon && !iconAsBadge && (
              <Image
                source={{
                  uri: icon.toString(),
                  width: iconWidth,
                  height: iconHeight,
                }}
              />
            )}
            {!icon && (
              <CardTitleNoMarginLeft color={titleColor}>
                {title}
              </CardTitleNoMarginLeft>
            )}
            {!!icon && <CardTitle color={titleColor}>{title}</CardTitle>}
            {activeLegends && (
              <LegendList>
                {activeLegends?.map(({ id, color, isActive }, index) => (
                  <LegendListItem
                    key={index}
                    isActive={isActive}
                    onPress={() => updateActiveLegends(id)}
                  >
                    <UsageItemDot color={color} />
                    <LegendListItemText>{id}</LegendListItemText>
                  </LegendListItem>
                ))}
              </LegendList>
            )}
            {titleSuffix}
          </CardTitleWrapper>

          {dropdownItems && (
            <Dropdown
              text={selectedFilter?.label || ''}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            >
              {dropdownItems?.map(({ label, id }, index) => (
                <Link
                  hasPadding
                  key={index}
                  isActive={selectedFilter?.id === id}
                  onPress={() => changeFilter(id)}
                >
                  {label}
                </Link>
              ))}
            </Dropdown>
          )}
        </CardHeader>
      )}

      {!isCollapsed && (
        <>
          {!disableHorizontalDivider && <Spacer />}
          {!disableHorizontalDivider && <HorizontalCardDivider marginTop={0} />}
          {children}
        </>
      )}
    </HeaderCardWrapper>
  );
};

export default HeaderCard;
