import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ReportWithRelations } from "../../../../bookmydesk-api-sdk-typescript-axios";
import Container from "../../../components/Container";
import { getFacilityPages } from "../../../components/Navbar/AdminNavBarContent";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { OverviewHeader } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import Title from "../../../styles/Title";
import moment from "moment";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import LoadOverlay from "../../../components/LoadOverlay";

const columnSizes = [1, 1, 1, 1, 1];

const ReportOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.report.overview.location"), false),
    createHeaderItem(t("admin.report.overview.reason"), false),
    createHeaderItem(t("admin.report.overview.details"), false),
    createHeaderItem(t("admin.report.overview.user"), false),
    createHeaderItem(t("admin.report.overview.date"), false),
  ];
  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listReports>[1]>(tableHeaders);

  const userCompany = userState?.companyIds[0];

  const [data, setData] = useState<Array<ReportWithRelations>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [currentReportId, setCurrentReportId] = useState<string>("");
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const history = useHistory();

  const fetchListItems = useCallback(async () => {
    if (userCompany) {
      return handleRequest(
        client.listReports(
          userCompany,
          sortField,
          sortOrder,
          takeSize,
          skipParam
        )
      );
    }
  }, [userCompany, handleRequest, client, sortField, sortOrder, skipParam]);

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);
    const response = await handleRequest(client.deleteReport(currentReportId));

    if (response && response.status === 204) {
      fetchListItems().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items, total } = result.data.result;
        if (items) {
          setTableData(createTableData(items));
          setData(items || []);
          setTotalItems(total);
        }
      });

      setShowDeleteMessage(true);
    }
  }, [currentReportId, handleRequest, client, fetchListItems]);

  useEffect(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setTableData(createTableData(items));
        setData(items || []);
        setTotalItems(total);
      }
    });
  }, [skipParam, sortOrder, sortField, userCompany]);

  const createTableData = useCallback((items: ReportWithRelations[]) => {
    const tableData: TableContent[] = items.map(
      ({ reason, location, details, user, id, created }) => {
        const data: ReactElement[] = [
          <ExtraSmallText key="name">{location.name}</ExtraSmallText>,
          <ExtraSmallText key="reason">{reason.reason}</ExtraSmallText>,
          <ExtraSmallText key="details">{details}</ExtraSmallText>,
          <ExtraSmallText key="user">
            {user.firstName} {user.infix}
            {user.infix && user.infix.length > 0 ? " " : ""}
            {user.lastName}
          </ExtraSmallText>,
          <ExtraSmallText key="date">
            {moment.utc(created).format("DD-MM-YYYY")} - {moment.utc(created).format("HH:mm")}
          </ExtraSmallText>,
        ];
        return {
          data,
          id,
          canDelete: true,
          canEdit: false,
          hideDeleteButton: false,
          hideEditButton: true,
        };
      }
    );
    return tableData;
  }, []);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.report.overview.title")}</Title>
        </OverviewHeader>
        <PageSelector pages={getFacilityPages()} />
        {showDeleteMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        ) : null}
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {isLoading ? null : (
          <Table
            tableId="report_overview"
            onDeleteItem={(id) => {
              setCurrentReportId(id);
              setShowDeleteModal(true);
            }}
            headerItems={headerItems}
            tableContent={tableData}
            columnSizes={columnSizes}
            sortList={onSortList}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/notification-overview/update/${id}`)
            }
          />
        )}
        {totalItems ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.report.overview.delete_warning")}
      />
    </>
  );
};

export default ReportOverview;
