module["exports"] = [
  "Ackermann",
  "Aebi",
  "Albrecht",
  "Ammann",
  "Amrein",
  "Arnold",
  "Bachmann",
  "Bader",
  "Bär",
  "Bättig",
  "Bauer",
  "Baumann",
  "Baumgartner",
  "Baur",
  "Beck",
  "Benz",
  "Berger",
  "Bernasconi",
  "Betschart",
  "Bianchi",
  "Bieri",
  "Blaser",
  "Blum",
  "Bolliger",
  "Bosshard",
  "Braun",
  "Brun",
  "Brunner",
  "Bucher",
  "Bühler",
  "Bühlmann",
  "Burri",
  "Christen",
  "Egger",
  "Egli",
  "Eichenberger",
  "Erni",
  "Ernst",
  "Eugster",
  "Fankhauser",
  "Favre",
  "Fehr",
  "Felber",
  "Felder",
  "Ferrari",
  "Fischer",
  "Flückiger",
  "Forster",
  "Frei",
  "Frey",
  "Frick",
  "Friedli",
  "Fuchs",
  "Furrer",
  "Gasser",
  "Geiger",
  "Gerber",
  "Gfeller",
  "Giger",
  "Gloor",
  "Graf",
  "Grob",
  "Gross",
  "Gut",
  "Haas",
  "Häfliger",
  "Hafner",
  "Hartmann",
  "Hasler",
  "Hauser",
  "Hermann",
  "Herzog",
  "Hess",
  "Hirt",
  "Hodel",
  "Hofer",
  "Hoffmann",
  "Hofmann",
  "Hofstetter",
  "Hotz",
  "Huber",
  "Hug",
  "Hunziker",
  "Hürlimann",
  "Imhof",
  "Isler",
  "Iten",
  "Jäggi",
  "Jenni",
  "Jost",
  "Kägi",
  "Kaiser",
  "Kälin",
  "Käser",
  "Kaufmann",
  "Keller",
  "Kern",
  "Kessler",
  "Knecht",
  "Koch",
  "Kohler",
  "Kuhn",
  "Küng",
  "Kunz",
  "Lang",
  "Lanz",
  "Lehmann",
  "Leu",
  "Leunberger",
  "Lüscher",
  "Lustenberger",
  "Lüthi",
  "Lutz",
  "Mäder",
  "Maier",
  "Marti",
  "Martin",
  "Maurer",
  "Mayer",
  "Meier",
  "Meili",
  "Meister",
  "Merz",
  "Mettler",
  "Meyer",
  "Michel",
  "Moser",
  "Müller",
  "Näf",
  "Ott",
  "Peter",
  "Pfister",
  "Portmann",
  "Probst",
  "Rey",
  "Ritter",
  "Roos",
  "Roth",
  "Rüegg",
  "Schäfer",
  "Schaller",
  "Schär",
  "Schärer",
  "Schaub",
  "Scheidegger",
  "Schenk",
  "Scherrer",
  "Schlatter",
  "Schmid",
  "Schmidt",
  "Schneider",
  "Schnyder",
  "Schoch",
  "Schuler",
  "Schumacher",
  "Schürch",
  "Schwab",
  "Schwarz",
  "Schweizer",
  "Seiler",
  "Senn",
  "Sidler",
  "Siegrist",
  "Sigrist",
  "Spörri",
  "Stadelmann",
  "Stalder",
  "Staub",
  "Stauffer",
  "Steffen",
  "Steiger",
  "Steiner",
  "Steinmann",
  "Stettler",
  "Stocker",
  "Stöckli",
  "Stucki",
  "Studer",
  "Stutz",
  "Suter",
  "Sutter",
  "Tanner",
  "Thommen",
  "Tobler",
  "Vogel",
  "Vogt",
  "Wagner",
  "Walder",
  "Walter",
  "Weber",
  "Wegmann",
  "Wehrli",
  "Weibel",
  "Wenger",
  "Wettstein",
  "Widmer",
  "Winkler",
  "Wirth",
  "Wirz",
  "Wolf",
  "Wüthrich",
  "Wyss",
  "Zbinden",
  "Zehnder",
  "Ziegler",
  "Zimmermann",
  "Zingg",
  "Zollinger",
  "Zürcher"
];
