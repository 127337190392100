module["exports"] = [
  "القدس",
  "تل الربيع",
  "صفد",
  "أريحا",
  "بئر السبع",
  "غزة",
  "نابلس",
  "الناصرة",
  "بيت لحم",
  "الخليل",
  "يافا",
  "حيفا",
  "اللد",
  "الرملة",
  "عكا",
  "سخنين",
  "قلنسوة",
  "طيرة",
  "عرابة",
  "رام الله",
  "طولكرم",
  "بئر السبع ",
  "تونس",
  "بن عروس",
  "أريانة",
  "باجة",
  "بنزرت",
  "قابس",
  "قفصة",
  "جندوبة",
  "القيروان",
  "القصرين",
  "قبلي",
  "الكاف",
  "المهدية",
  "منوبة",
  "مدنين",
  "المنستير",
  "نابل",
  "صفاقس",
  "بوزيد",
  "سليانة",
  "سوسة",
  "تطاوين",
  "توزر",
  "زغوان",
  "أدرار",
  "الشلف",
  "الأغواط",
  "أم البواقي",
  "باتنة",
  "بجاية",
  "بسكرة",
  "بشار",
  "البليدة",
  "البويرة",
  "تمنراست",
  "تبسة",
  "تلمسان",
  "تيارت",
  "تيزي وزو",
  "الجزائر",
  "الجلفة",
  "جيجل",
  "سطيف",
  "سعيدة",
  "سكيكدة",
  "بلعباس",
  "عنابة",
  "قالمة",
  "قسنطينة",
  "المدية",
  "عمان",
  "الزرقاء",
  "إربد",
  "العقبة",
  "السلط",
  "المفرق",
  "جرش",
  "معان",
  "عجلون",
  "حلب",
  "دمشق",
  "حمص",
  "اللاذقية",
  "حماة",
  "طرطوس",
  "دير الزور",
  "السويداء",
  "الحسكة",
  "درعا",
  "إدلب",
  "بيروت",
  "طرابلس",
  "صيدا",
  "صور",
  "النبطية",
  "زحلة",
  "جونيه",
  "حبوش",
  "بعلبك",
  "بغداد",
  "البصرة",
  "نينوى",
  "أربيل",
  "النجف",
  "كركوك",
  "المثنى",
  "القادسية",
  " بابل",
  "كربلاء",
];
