import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { ScrollView, TouchableWithoutFeedback, ViewStyle } from "react-native";
import { ExtraSmallText } from "../../styles/Text";
import styled from "styled-components/native";
import {
  borderRadius,
  borderWidth,
  boxShadow,
  color,
  font,
  spacing,
  chevron, display,
} from "../../styles/theme";
import SelectItem, { OnChangeProps } from "./SelectItem";
import { css } from "styled-components";
import chevronDownIcon from "../../assets/icons/chevron_down_secondary.svg";
import Checkbox from "../Checkbox";
import { convertObjectToStyle } from "../../utils";

const selectHeight = "60px;";
const maxSelectHeight = "250px;";

const MainWrapper = styled.View<ViewStyle & { noMargin?: boolean }>`
  position: relative;
  height: ${selectHeight};
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : spacing.medium)};
  ${({ zIndex }) => `z-index: ${zIndex ? zIndex : 1}`}
  ${(props) => convertObjectToStyle(props, ['marginRight'])};
`;

interface OpenProps {
  isOpen: boolean;
}

const SelectWrapper = styled.View<OpenProps>`
  border-color: ${color.greyLighter};
  border-width: ${borderWidth.small};
  min-height: ${selectHeight};
  max-height: ${maxSelectHeight};
  border-radius: ${borderRadius.small};
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
  background-color: ${color.white};

  ${({ isOpen }) => isOpen && boxShadow.small}
`;

const SelectedItemsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: ${spacing.medium};
`;

export const SelectedItems = styled(ExtraSmallText)`
  height: ${selectHeight};
  max-height: ${selectHeight};
  padding: ${spacing.medium} ${spacing.medium} ${spacing.medium}
    ${spacing.medium};
  display: ${display.flex};
  align-items: center;
  font-family: ${font.defaultMedium};
`;

const IconWrapper = styled.View`
  margin-right: 10px;
`;

interface IconProps {
  isOpen?: boolean;
}

const Icon = styled.Image<IconProps>`
  width: ${chevron.width};
  height: ${chevron.height};
  margin: 0 ${spacing.small};

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotateX(180deg);
    `};
`;

const NoSelectedItems = styled(SelectedItems)`
  color: ${color.grey};
`;

const Spacer = styled.View`
  height: ${spacing.medium};
`;

export interface SelectItemInterface<Value = any> {
  label: string;
  value: Value;
  icon?: ReactNode,
  isActive?: boolean;
}

export interface SelectProps {
  items: Array<SelectItemInterface>;
  isMultiSelect?: boolean;
  placeholder?: string;
  onChange([]: Array<SelectItemInterface>): void;
  style?: ViewStyle;
  required?: boolean;
  canEmpty?: boolean;
  noMargin?: boolean;
  checkMark?: boolean;
}

const Select: FC<SelectProps> = ({
  items: selectItems,
  isMultiSelect,
  onChange,
  placeholder = "Select something",
  style,
  required,
  noMargin,
  checkMark = true,
  canEmpty = true,
  children,
}) => {
  const [items, setItems] = useState(selectItems);
  const getActiveItems = useCallback(
    (itemList: Array<SelectItemInterface>) =>
      itemList.filter(({ isActive }) => isActive),
    []
  );

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [activeItems, setActiveItems] = useState(getActiveItems(items));

  const toggleIsOpen = useCallback(
    () => setIsSelectOpen((oldValue) => !oldValue),
    [setIsSelectOpen]
  );

  useEffect(() => {
    setItems([...selectItems]);

    const newActiveItems = getActiveItems(selectItems);
    setActiveItems(newActiveItems);
  }, [selectItems]);

  const updateSelect = useCallback(
    ({ isActive, index }: OnChangeProps) => {
      if (!canEmpty && !isActive && activeItems.length === 1) {
        return;
      }

      let newItems = items;

      if (!isMultiSelect) {
        setIsSelectOpen(false);

        newItems = [
          ...items.map((item) => ({
            ...item,
            isActive: false,
          })),
        ];
      }

      const updatedActiveItems = newItems.map((item, itemIndex) => ({
        ...item,
        isActive: itemIndex === index ? isActive : item.isActive,
      }));

      const newActiveItems = getActiveItems(updatedActiveItems);

      if ((required && newActiveItems.length) || !required) {
        setItems(updatedActiveItems);
        setActiveItems(newActiveItems);
        onChange(newActiveItems);
      }
    },
    [
      required,
      items,
      isMultiSelect,
      setIsSelectOpen,
      setItems,
      getActiveItems,
      setActiveItems,
      onChange,
    ]
  );
  return (
    <MainWrapper noMargin={noMargin} {...style}>
      <SelectWrapper isOpen={isSelectOpen}>
        <TouchableWithoutFeedback onPress={toggleIsOpen}>
          {(children || activeItems.length !== 0) ? (
            <SelectedItemsWrapper>
              <SelectedItems ellipsizeMode="tail" numberOfLines={1}>
                {children ? children
                  : (
                    isMultiSelect
                      ? activeItems.map(({ label }) => label).join(", ")
                      : (
                        <>
                          {activeItems[0].icon && <IconWrapper>{activeItems[0].icon}</IconWrapper>}
                          {activeItems[0].label}
                        </>
                      )
                )}
              </SelectedItems>
              <Icon isOpen={isSelectOpen} source={chevronDownIcon.toString()} />
            </SelectedItemsWrapper>
          ) : (
            <SelectedItemsWrapper>
              <NoSelectedItems>{placeholder}</NoSelectedItems>
              <Icon isOpen={isSelectOpen} source={chevronDownIcon.toString()} />
            </SelectedItemsWrapper>
          )}
        </TouchableWithoutFeedback>
        {isSelectOpen && (
          <>
            <ScrollView>
              {items.map(({ icon, label, isActive }, index) => (
                <SelectItem
                  index={index}
                  key={index}
                  isActive={isActive}
                  onChange={updateSelect}
                >
                  {icon && <IconWrapper>{icon}</IconWrapper>}
                  {checkMark && (
                    <Checkbox
                      checked={!!isActive}
                      onChange={(value) => updateSelect({isActive: value, index})}
                      hasMarginBottom={false}
                      marginRight={5}
                    />
                  )}
                  {label}
                </SelectItem>
              ))}
            </ScrollView>
            <Spacer />
          </>
        )}
      </SelectWrapper>
    </MainWrapper>
  );
};

export default Select;
