import React, { FC } from "react";
import styled from "styled-components/native";
import { color } from "../../styles/theme";
import { StatusIndicatorInterface } from "../../../fake-data/dashboardData";

const StatusIndicatorElementOutside = styled.View`
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 50%;
    background-color: ${props => color[props.dataColor + 'Light']};
    position: relative;
    margin-right: 10px;
    align-self: flex-start;
`;

const StatusIndicatorElementInsideOne = styled.View`
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 50%;
    background-color: ${props => color.white};
    position: absolute;
    left: 5px;
    top: 5px;
`;

const StatusIndicatorElementInsideTwo = styled.View`
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    background-color: ${props => color[props.dataColor]};
    position: absolute;
    left: 5px;
    top: 5px;
`;

const StatusIndicator: FC<StatusIndicatorInterface> = ({
  dataColor,
}) => (
  <StatusIndicatorElementOutside dataColor={dataColor}>
    <StatusIndicatorElementInsideOne dataColor={dataColor}>
        <StatusIndicatorElementInsideTwo dataColor={dataColor} />
    </StatusIndicatorElementInsideOne>
  </StatusIndicatorElementOutside>
);

export default StatusIndicator;
