module["exports"] = [
  "вспомогательный",
  "основной",
  "внутренний",
  "цифровой",
  "свободно-распростроняемый",
  "виртуальный",
  "кросс-платформенный",
  "излишний",
  "онлайн",
  "сенсорный",
  "многобайтный",
  "беспроводной",
  "1080-пиксельный",
  "нейронный",
  "оптический",
  "твердотельный",
  "мобильный"
];
