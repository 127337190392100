import moment from "moment";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ScrollView, Clipboard } from "react-native";
import styled from "styled-components/native";
import {
  CustomFieldValue,
  Reservation,
  ResourceReservationWithRelations,
  User,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import copyEmailIcon from "../../../assets/icons/icon-copy-email.svg";
import Button, {
  ButtonsWrapper,
  ButtonText,
  ButtonWrapper,
} from "../../../components/Button";
import Container from "../../../components/Container";
import Dialog from "../../../components/Dialog";
import ReservationsFilter, {
  ResourceReservationFilter,
} from "../../../components/Filter/ResourceReservationFilter";
import Link from "../../../components/Link";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Paginator, { takeSize } from "../../../components/Paginator";
import Pill from "../../../components/Pill";
import SearchInput from "../../../components/SearchInput";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSearch } from "../../../hooks/useSearch";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps } from "../../../routing";
import { AmountIndicatorSecondary } from "../../../styles/AmountIndicator";
import Input from "../../../styles/Input";
import {
  OverviewDeleteModalButtonWrapper,
  OverviewHeader,
  OverviewHeaderInnerRight,
} from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import { ExtraSmallTextGood } from "../../../styles/Text/extraSmallText";
import { color, spacing } from "../../../styles/theme";
import Title from "../../../styles/Title";
import {
  CancelIcon,
  DeleteIcon,
  GridIcon,
} from "../../../components/Icons";
import { DateComparisor } from "../../../components/Filter/reservationFilter.constant";

const ListWrapper = styled.View`
  width: 200%;
`;

const UploadIcon = styled.Image`
  margin-right: ${spacing.small};
  color: ${color.grey};
`;

const IconWrapper = styled.View`
  margin-right: 10px;
`;

const ResourceListOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.parkingReservation.overview.name"), false),
    createHeaderItem(
      t("admin.parkingReservation.overview.email"),
      false,
      "user.email"
    ),
    createHeaderItem(
      t("admin.parkingReservation.overview.resourceNameParking"),
      false
    ),
    createHeaderItem(
      t("admin.parkingReservation.overview.resourceType"),
      false
    ),
    createHeaderItem(
      t("admin.parkingReservation.overview.reservation"),
      false
    ),
    createHeaderItem(t("admin.parkingReservation.overview.dateFrom"), false),
    createHeaderItem(t("admin.parkingReservation.overview.dateTo"), false),
    createHeaderItem(
      t("admin.parkingReservation.overview.createdByUser"),
      false
    ),
    createHeaderItem(
      t("admin.parkingReservation.overview.repeatedReservation"),
      false
    ),
  ];

  const columnSizes = [1.5,1.5,1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5];

  const { userState } = useAuth();
  const client = useClient();
  const { debouncedSearchTerm, setDebouncedSearchTerm } = useSearch();

  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listReservations>[1]>(tableHeaders);

  const userCompany = userState?.companyIds[0];

  const [data, setData] = useState<Array<ResourceReservationWithRelations>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false);
  const [showCancelMessage, setShowCancelMessage] = useState(false);
  const [showCancelMultipleMessage, setShowCancelMultipleMessage] =
    useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteMultipleModal, setShowDeleteMultipleModal] = useState(false);
  const [showDeleteMultipleMessage, setShowDeleteMultipleMessage] =
    useState(false);
  const [showNotAllDeletedMessage, setShowNotAllDeletedMessage] =
    useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ResourceReservationFilter>({});
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [itemSelected, setItemSelected] = useState<Reservation>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [cancelReason, setCancelReason] = useState("");


  const filterOperator = useMemo(() => {
    // no specific fromOperator in the backend for IS_BETWEEN
    // but 'gt' value can accept from and to value
    return filter.dateOperator === DateComparisor.IS_BETWEEN
      ? "gt"
      : filter.dateOperator;
  }, [filter.dateOperator]);
  
  const fetchListItems = useCallback(async () => {
    if (
      userCompany &&
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(client.listResourceReservations(
        userCompany,
        debouncedSearchTerm,
        filterOperator,
        filter.dateFrom,
        filter.dateTo,
        filter.isRepeatReservation,
        takeSize,
        skipParam,
      ));
    }
  }, [userCompany, debouncedSearchTerm, searchTerm, handleRequest, client, filterOperator, filter.dateFrom, filter.dateTo, filter.isRepeatReservation, skipParam]);

  const fetchUsers = useCallback(async () => {
    if (
      userCompany &&
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(client.listUsers(userCompany));
    }
  }, [debouncedSearchTerm, searchTerm, userCompany, client, handleRequest]);

  const fetch = useCallback(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setData(items);
        setTotalItems(total);
      }
    });
    fetchUsers().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      if (items) {
        setUsers(items);
      }
    });
  }, [fetchListItems, fetchUsers]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setTableData(createTableData(data, users));
  }, [data, users])

  const createTableData = (items: ResourceReservationWithRelations[], userItems: User[]) => {
    if (items.length === 0 || userItems.length === 0) return [];
    const tableData: TableContent[] = items.map(
      ({
        id,
        user,
        userId,
        dateFrom,
        dateTo,
        repeatedReservation,
        reservationId,
        resource,
        createdByUserId,
      }) => {
        const createdByUser = userItems.find((user) => user.id === createdByUserId);
        const foundUser = userItems.find((user) => user.id === userId);

        const userEmail = user ? user?.email : "-";
        const copyEmail = () => Clipboard.setString(userEmail);

        const data: ReactElement[] = [
          <ExtraSmallText key="name" mediumWeight>
            {foundUser?.infix
              ? `${foundUser.firstName} ${foundUser.infix} ${foundUser.lastName}`
              : `${foundUser?.firstName} ${foundUser?.lastName}`}
          </ExtraSmallText>,
          <ExtraSmallText onPress={userEmail !== "-" && copyEmail}>
            {userEmail}
            {userEmail !== "-" && (
              <UploadIcon
                style={{ width: 14, height: 16, marginLeft: 5, top: 4 }}
                source={copyEmailIcon}
                IconOnHover
              />
            )}
          </ExtraSmallText>,

          <ExtraSmallText key="Resource">{resource.name}</ExtraSmallText>,
          <ExtraSmallText key="resourceType">
            {resource.resource_type}
          </ExtraSmallText>,

          !!reservationId ? (
            <Pill
              key="reservaton"
              textColor={color.quaternary}
              backgroundColor={color.quaternaryLight}
            >
              {t("general.yes")}
            </Pill>
          ) : (
            <Pill
              key="reservaton"
              textColor={color.grey}
              backgroundColor={color.greyLightest}
            >
              {t("general.no")}
            </Pill>
          ),

          <ExtraSmallText key="dateFrom">
            {moment.utc(dateFrom).format("DD-MM-YYYY")}
          </ExtraSmallText>,

          <ExtraSmallText key="dateTo">
            {moment.utc(dateTo).format("DD-MM-YYYY")}
          </ExtraSmallText>,

          <ExtraSmallText key="createdByUser" mediumWeight>
            {createdByUser?.infix
              ? `${createdByUser.firstName} ${createdByUser.infix} ${createdByUser.lastName}`
              : `${createdByUser?.firstName} ${createdByUser?.lastName}`}
          </ExtraSmallText>,

          repeatedReservation ? (
            <Pill
              key="repeatedReservation"
              textColor={color.quaternary}
              backgroundColor={color.quaternaryLight}
            >
              {t("general.yes")}
            </Pill>
          ) : (
            <Pill
              key="repeatedReservation"
              textColor={color.grey}
              backgroundColor={color.greyLightest}
            >
              {t("general.no")}
            </Pill>
          ),

        ];
        return {
          data,
          id: id,
          canDelete: true,
          canEdit: false,
          hideEditButton: true,
          hideDeleteButton: false,
        };
      }
    );
    return tableData;
  };

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const openCustomFieldsModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowCustomFieldsModal(true);
    },
    [setItemSelected, getItemById, setShowCustomFieldsModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteReservation(itemSelected!.id)
    );

    if (response && response.status === 204) {
      fetch();
      setShowDeleteMessage(true);
    }
  }, [handleRequest, client, itemSelected, fetch]);

  const deleteSelected = useCallback(async () => {
    setShowDeleteMultipleModal(false);
    const errors = (
      await Promise.all(
        selectedIds.map(async (selectedId) => {
          const response = await handleRequest(
            client.deleteReservation(selectedId)
          );

          if (!response || response.status !== 204) {
            return selectedId;
          }
          return undefined;
        })
      )
    ).filter((error) => typeof error !== "undefined");

    fetch();
    if (errors.length > 0) {
      setShowNotAllDeletedMessage(true);
    } else {
      setShowDeleteMultipleMessage(true);
    }
  }, [client, fetch, handleRequest, selectedIds]);

  const cancelSelected = useCallback(async () => {
    setShowCancelModal(false);
    try {
      const response = await handleRequest(
        client.cancelReservation({
          reservationIds: selectedIds,
          reason: cancelReason,
        })
      );

      setCancelReason("");

      if (response?.status !== 204) {
        return;
      }

      if (selectedIds.length === 1) {
        setShowCancelMessage(true);
      } else {
        setShowCancelMultipleMessage(true);
      }
      fetch();
    } catch (error) {
      console.error(error);
    }
  }, [client, fetch, handleRequest, selectedIds, cancelReason]);

  const updateSearch = (text: string) => {
    setSearchTerm(text);
    setDebouncedSearchTerm(text);
    setSkipParam(0);
  };

  const canCancelAllSelectedIds =
    selectedIds.filter((id) =>
      data.find(
        (reservation) =>
          reservation.id === id && !reservation._operations?.canCancel
      )
    ).length === 0;

  const handleRowSelection = useCallback(
    (ids: string[], isAllSelected?: boolean) => {
      setIsAllSelected(!!isAllSelected);
      if (isAllSelected) {
        const ids = data
          .map((item) => item.id)
          .filter((id) => id !== null) as string[];
        setSelectedIds(ids);
      } else {
        setSelectedIds(ids);
      }
    },
    [setSelectedIds, data]
  );

  const { customFieldValues } = itemSelected
    ? (itemSelected as unknown as { customFieldValues: CustomFieldValue[] })
    : { customFieldValues: [] };

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.reservation.overview.resourcesTitleParking")}</Title>
          <OverviewHeaderInnerRight>
            <SearchInput
              wrapperStyle={`margin-right: ${spacing.medium};`}
              placeholder={t("general.filter.search")}
              onChangeText={updateSearch}
              value={searchTerm}
            />
            <ButtonsWrapper>
              <ButtonWrapper>
                <Button
                  hasShadow
                  backgroundColor={color.white}
                  backgroundHoverColor={color.white}
                  color={color.darker}
                  onPress={() => setShowFilterModal(true)}
                  indicator={
                    <AmountIndicatorSecondary>
                      {numberOfFilters}
                    </AmountIndicatorSecondary>
                  }
                >
                  {t("general.filter.filter")}
                </Button>
              </ButtonWrapper>
            </ButtonsWrapper>
          </OverviewHeaderInnerRight>
        </OverviewHeader>
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showCancelMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowCancelMessage(false)}
          >
            {t("general.cancelSuccess")}
          </Notification>
        )}
        {showCancelMultipleMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowCancelMultipleMessage(false)}
          >
            {t("general.cancelMultipleSuccess")}
          </Notification>
        )}
        {showDeleteMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        )}
        {showDeleteMultipleMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMultipleMessage(false)}
          >
            {t("general.deleteMultipleSuccess")}
          </Notification>
        )}
        {showNotAllDeletedMessage && (
          <Notification
            type="danger"
            closeNotification={() => setShowNotAllDeletedMessage(false)}
          >
            {t("general.notAllDeletedWarning")}
          </Notification>
        )}
        <ScrollView horizontal={true}>
          <ListWrapper>
            {isLoading ? null : (
              <Table
                tableId="resource_list_overview"
                hideColumn
                selectable
                headerItems={headerItems}
                tableContent={tableData}
                columnSizes={columnSizes}
                sortList={onSortList}
                sortOrder={sortOrder}
                sortField={sortField}
                onDeleteItem={openDeleteModal}
                onShowCustomFields={openCustomFieldsModal}
                onChangeSelectedIds={handleRowSelection}
                recordsCount={totalItems}
                selectToggle={
                  <>
                    <IconWrapper>
                      <GridIcon />
                    </IconWrapper>
                    {t("table.showHide")}
                  </>
                }
                skipParam={skipParam}
                setSkipParam={setSkipParam}
              >
                {selectedIds.length > 0 && (
                  <>
                    <ExtraSmallText>
                      {t("table.rowsSelected", {
                        count: isAllSelected ? totalItems : selectedIds.length,
                      })}
                    </ExtraSmallText>

                    <Link
                      isActive={selectedIds.length > 0}
                      disabled={selectedIds.length === 0}
                      marginLeft={20}
                      onPress={() =>
                        selectedIds.length > 0 &&
                        setShowDeleteMultipleModal(true)
                      }
                    >
                      <DeleteIcon /> {t("general.delete")}
                    </Link>
                    <Link
                      isActive={selectedIds.length > 0}
                      disabled={selectedIds.length === 0}
                      marginLeft={20}
                      onPress={() =>
                        selectedIds.length > 0 && setShowCancelModal(true)
                      }
                    >
                      <CancelIcon />{" "}
                      {t("admin.reservation.overview.cancelReservation")}
                    </Link>
                  </>
                )}
              </Table>
            )}
          </ListWrapper>
        </ScrollView>

        {totalItems && !isLoading ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>

      <Dialog show={showCustomFieldsModal} setShow={setShowCustomFieldsModal}>
        <Title style={{ marginBottom: 16 }}>{t("admin.reservation.overview.customFields.title")}</Title>
        <Table
          tableId="custom_fields_list_overview"
          noShadow
          noPadding
          noDrag
          hideActions
          columnSizes={[2, 2, 2]}
          headerItems={[
            { headerLabel: t("admin.reservation.overview.customFields.key"), isSortable: false },
            { headerLabel: t("admin.reservation.overview.customFields.description"), isSortable: false },
            { headerLabel: t("admin.reservation.overview.customFields.value"), isSortable: false },
          ]}
          recordsCount={customFieldValues.length}
          tableContent={customFieldValues.map((item) => ({
            id: item.customField.id,
            data: [(
              <ExtraSmallText key={`${item.customField.id}-key`}>{item.customField.name}</ExtraSmallText>
            ), (
              <ExtraSmallText key={`${item.customField.id}-desc`}>{item.customField.description}</ExtraSmallText>
            ), (
              <ExtraSmallText key={`${item.customField.id}-value`}>{item.value}</ExtraSmallText>
            )],
          }))}
        />
        <OverviewDeleteModalButtonWrapper>
          <ButtonWrapper style={{ marginTop: 10, marginLeft: 0 }}>
            <Button
              color={color.secondary}
              textHoverColor={color.secondaryLight}
              backgroundColor={color.secondaryLight}
              backgroundHoverColor={color.secondary}
              onPress={() => setShowCustomFieldsModal(false)}
            >
              {t("admin.reservation.overview.customFields.close")}
            </Button>
          </ButtonWrapper>
        </OverviewDeleteModalButtonWrapper>
      </Dialog>

      <Dialog show={showDeleteModal} setShow={setShowDeleteModal}>
        <Title hasMarginBottom>{t("admin.reservation.delete.warning")}</Title>
        <OverviewDeleteModalButtonWrapper>
          <Button onPress={() => setShowDeleteModal(false)}>
            <ButtonText>{t("general.cancel")}</ButtonText>
          </Button>
          <ButtonWrapper>
            <Button
              backgroundHoverColor={color.primary}
              backgroundColor={color.primaryLight}
              onPress={deleteFromList}
              color={color.primary}
              textHoverColor={color.primaryLight}
            >
              {t("general.yes")}
            </Button>
          </ButtonWrapper>
        </OverviewDeleteModalButtonWrapper>
      </Dialog>

      <Dialog
        show={showDeleteMultipleModal}
        setShow={setShowDeleteMultipleModal}
      >
        <Title hasMarginBottom>
          {selectedIds.length === 1 && t("admin.reservation.delete.warning")}
          {selectedIds.length !== 1 &&
            t("admin.reservation.delete.multipleWarning")}
        </Title>
        <OverviewDeleteModalButtonWrapper>
          <Button onPress={() => setShowDeleteMultipleModal(false)}>
            <ButtonText>{t("general.cancel")}</ButtonText>
          </Button>
          <ButtonWrapper>
            <Button
              backgroundHoverColor={color.primary}
              backgroundColor={color.primaryLight}
              onPress={deleteSelected}
              color={color.primary}
              textHoverColor={color.primaryLight}
            >
              {t("general.yes")}
            </Button>
          </ButtonWrapper>
        </OverviewDeleteModalButtonWrapper>
      </Dialog>

      <Dialog show={showCancelModal} setShow={setShowCancelModal}>
        <Title hasMarginBottom>
          {canCancelAllSelectedIds &&
            selectedIds.length === 1 &&
            t("admin.reservation.cancel.warning")}
          {canCancelAllSelectedIds &&
            selectedIds.length !== 1 &&
            t("admin.reservation.cancel.multipleWarning")}
          {!canCancelAllSelectedIds &&
            t("admin.reservation.cancel.cannotCancel")}
        </Title>
        {canCancelAllSelectedIds && (
          <Input
            value={cancelReason}
            onChangeText={setCancelReason}
            placeholder={t("admin.reservation.cancel.cancelReason")}
          />
        )}
        {!canCancelAllSelectedIds && (
          <ExtraSmallTextGood>
            {t("admin.reservation.cancel.cannotCancelDescription")}
          </ExtraSmallTextGood>
        )}
        <OverviewDeleteModalButtonWrapper>
          <Button onPress={() => setShowCancelModal(false)}>
            <ButtonText>{t("general.cancelSelection")}</ButtonText>
          </Button>
          {canCancelAllSelectedIds && (
            <ButtonWrapper>
              <Button
                backgroundHoverColor={color.primary}
                backgroundColor={color.primaryLight}
                onPress={cancelSelected}
                color={color.primary}
                textHoverColor={color.primaryLight}
              >
                {t("general.confirmCancelation")}
              </Button>
            </ButtonWrapper>
          )}
        </OverviewDeleteModalButtonWrapper>
      </Dialog>

      <ReservationsFilter
        showFilter={showFilterModal}
        setShowFilter={setShowFilterModal}
        onFilter={(filter, size) => {
          setNumberOfFilters(size);
          setFilter(filter);
        }}
      />
    </>
  );
};

export default ResourceListOverview;
