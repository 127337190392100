import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "../../../routing";

import {
  CustomFieldUpdate,
  InlineResponse400Errors,
  ResourcePropertiesResourceTypeEnum,
  ResourceWithoutId,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";
import { t } from "../../../i18n";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LabeledInput, { InputWrapper, LabeledInputWrapper, LabelWrapper } from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Select, { SelectItemInterface } from "../../../components/Select";

import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Title from "../../../styles/Title";
import Input, { InputHelpText } from "../../../styles/Input";

import { useAuth } from "../../../context/authContext";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import InputValidationErrors from "../../../components/InputValidationErrors";
import Checkbox from "../../../components/Checkbox";
import { CustomFields } from "../../../components/CustomFields";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

export const ResourceCreate: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [customFields, setCustomFields] = useState<CustomFieldUpdate[]>([]);

  const [locationId, setLocationId] = useState("");

  const [locations, setLocations] = useState<SelectItemInterface[]>([]);
  const [resourceType, setResourceType] = useState<string>("parkingspot");
  const [resourceTypes, setResourceTypes] = useState<SelectItemInterface[]>([]);
  const [reservationConnectionEnabled, setReservationConnectionEnabled] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [amountAvailable, setAmountAvailable] = useState<string>('0');
  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  const userCompany = userState?.companyIds[0];
  useEffect(() => {
    if (!userCompany) {
      return;
    }

    setResourceTypes([
      {
        label: t("admin.parking.create.form.parkingSpot"),
        value: "parkingspot",
      },
      {
        label: t("admin.parking.create.form.equipment"),
        value: "equipment",
      },      {
        label: t("admin.parking.create.form.food"),
        value: "food",
      },
    ]);

    const fetchLocations = async () =>
      await handleRequest(client.listLocations(userCompany));

    fetchLocations().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      setLocations(
        items.map((item) => ({
          label: item.name,
          value: item.id,
          isActive: item.id === locationId,
        }))
      );
    });
  }, [userCompany, client, handleRequest, locationId]);

  const updateLocation = useCallback(
    (newValue) => {
      setLocationId(newValue[0].value);
    },
    [setLocationId]
  );

  const updateResourceType = useCallback(
    (newValue) => {
      setResourceType(newValue[0].value);
    },
    [setResourceType]
  );

  const formIsFilledIn = useCallback(
    () => {
      return !!name &&
      !!locationId && !isNaN(parseInt(amountAvailable))
    },
    [name, locationId, amountAvailable]
  );

  const submitForm = useCallback(async () => {
    const data: ResourceWithoutId = {
      name,
      description,
      active,
      locationId,
      resourceType: ResourcePropertiesResourceTypeEnum.Parkingspot,
      reservationConnectionEnabled,
      amountAvailable: parseInt(amountAvailable),
      customFields: customFields.map((customField) => ({ ...customField, id: customField.id?.startsWith('__new__') ? null : customField.id })),
    };
    try {
      const success = await handleRequest(client.addResource(data));
      if (success) {
        history.goBack();
      }
    } catch (error) {
      console.error(error);
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [name, description, active, locationId, reservationConnectionEnabled, amountAvailable, customFields, handleRequest, client, history]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <Title hasMarginBottom>{t("admin.parking.create.form.addTitle")}</Title>
          <LabeledInput label={t("admin.parking.create.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.parking.create.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.parking.create.form.description")} required>
            <Input
              onChangeText={setDescription}
              value={description}
              placeholder={t("admin.parking.create.form.description") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.parking.create.form.location")}
            style={{ zIndex: 113}}
            required
          >
            <Select
              required
              isMultiSelect={false}
              items={locations}
              onChange={updateLocation}
              placeholder={t("admin.parking.create.form.location")}
            />
          </LabeledInput>

           {/* <LabeledInput
            label={t("admin.parking.create.form.resourceType")}
            style={{ zIndex: 13}}
            required
          >
            <Select
              required
              isMultiSelect={false}
              items={resourceTypes}
              onChange={updateResourceType}
              placeholder={t("admin.parking.create.form.resourceType")}
            />
          </LabeledInput> */}

          <LabeledInputWrapper>
            <LabelWrapper />

          <InputWrapper>
              <Checkbox
                label={t("admin.parking.create.form.reservationConnectionEnabled")}
                onChange={setReservationConnectionEnabled}
                checked={reservationConnectionEnabled}
              />
              <InputHelpText>
              {t("admin.parking.create.form.reservationConnectionEnabled")}
              </InputHelpText>
            </InputWrapper>
          </LabeledInputWrapper>

          <LabeledInputWrapper>
            <LabelWrapper />

          <InputWrapper>
              <Checkbox
                label={t("admin.parking.create.form.active")}
                onChange={setActive}
                checked={active}
              />
              <InputHelpText>
              {t("admin.parking.create.form.active")}
              </InputHelpText>
            </InputWrapper>
          </LabeledInputWrapper>

          <LabeledInput label={t("admin.parking.create.form.amountAvailable")} required>
            <Input
              onChangeText={text => setAmountAvailable(text)}
              value={amountAvailable}
              placeholder={t("admin.parking.create.form.amountAvailable") + " *"}
            />
          </LabeledInput>

          <CustomFields
            data={customFields}
            onChange={setCustomFields}
          />

          <Button
            hasMarginBottom
            disabled={!formIsFilledIn()}
            onPress={submitForm}
          >
           {t("admin.parking.create.form.submit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

