import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components/native";
import {
  AdminUserCreate,
  InlineResponse400Errors,
  Language,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import PickerInput from "../../../components/PickerInput";
import Select, { SelectItemInterface } from "../../../components/Select";
import { Roles, useAuth } from "../../../context/authContext";
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import i18n, { t } from "../../../i18n";
import { useHistory } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { color, spacing } from "../../../styles/theme";
import { MediumTitle } from "../../../styles/Title";
import { isValidEmail, isValidPassword } from "../../../utils";
import { getLanguageItems } from "../CompanyOverview/Create";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

export const adminRoles = [
  {
    label: t(`admin.user.roles.${Roles.ROLE_CUSTOMER_ADMIN}`),
    value: Roles.ROLE_CUSTOMER_ADMIN,
  },
  {
    label: t(`admin.user.roles.${Roles.ROLE_OFFICE_MANAGER}`),
    value: Roles.ROLE_OFFICE_MANAGER,
  },
];
const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [sendResetPasswordEmail, setSendResetPasswordEmail] = useState(false);

  const [email, setEmail] = useState("");
  const [infix, setInfix] = useState("");
  const [language, setLanguage] = useState<Language>(
    i18n.locale.toUpperCase() as Language
  );
  const [companies, setCompanies] = useState<SelectItemInterface[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [roles] = useState<SelectItemInterface[]>(adminRoles);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [subscribeToNewsletter, setSubscribeToNewsletter] =
    useState<boolean>(true);

  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  const userCompany = userState?.companyIds[0];
  const isSuperAdmin = userState?.roles?.includes(Roles.ROLE_ADMIN);

  useEffect(() => {
    if (isSuperAdmin) {
      const fetchCompanies = async () =>
        await handleRequest(
          client.listCompanies("name", "asc", undefined, 2000)
        ); // TODO fetch all: ;

      fetchCompanies().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items } = result.data.result;
        setCompanies(
          items.map((company) => ({
            label: company.name!,
            value: company.id,
          }))
        );
      });
    }
  }, []);

  /**
   * Clear the password when the user checks the send reset password option
   */
  useEffect(() => {
    if (sendResetPasswordEmail) {
      setPassword("");
    }
  }, [sendResetPasswordEmail]);

  const updateCompany = useCallback(
    (newValue) => {
      setSelectedCompany(newValue[0].value);
    },
    [setSelectedCompany]
  );

  const updateRole = useCallback(
    (newValue) => {
      setSelectedRole(newValue[0].value);
    },
    [setSelectedRole]
  );

  const isFormValid = useCallback(
    () =>
      Boolean(firstname) &&
      Boolean(lastname) &&
      isValidEmail(email) &&
      Boolean(selectedRole) &&
      (!sendResetPasswordEmail ? isValidPassword(password) : true) &&
      (isSuperAdmin ? Boolean(selectedCompany) : true),
    [
      firstname,
      lastname,
      email,
      selectedRole,
      password,
      sendResetPasswordEmail,
      selectedCompany,
      isSuperAdmin,
    ]
  );

  const submitForm = useCallback(async () => {
    const user: AdminUserCreate = {
      firstName: firstname,
      infix,
      lastName: lastname,
      email,
      roles: [selectedRole],
      password: !sendResetPasswordEmail ? password : undefined,
      sendEmail: sendResetPasswordEmail,
      companyId: selectedCompany ? selectedCompany : userCompany!,
      newsletter: subscribeToNewsletter,
      language: language || null,
    };
    try {
      const success = await handleRequest(client.addAdminUser(user));

      console.log(success);

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    history,
    lastname,
    client,
    email,
    firstname,
    handleRequest,
    password,
    sendResetPasswordEmail,
    selectedCompany,
    selectedRole,
    userCompany,
    subscribeToNewsletter,
    infix,
    language,
  ]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.user.create.form.title")}
          </MediumTitle>
          <LabeledInput required label={t("admin.user.create.form.firstname")}>
            <Input
              onChangeText={setFirstname}
              value={firstname}
              placeholder={t("admin.user.create.form.firstname") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.infix")}>
            <Input
              value={infix}
              placeholder={t("admin.user.create.form.infix")}
              onChangeText={setInfix}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.lastname")} required>
            <Input
              onChangeText={setLastname}
              value={lastname}
              placeholder={t("admin.user.create.form.lastname") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.email")} required>
            <Input
              onChangeText={setEmail}
              value={email}
              placeholder={t("admin.user.create.form.email") + " *"}
            />
          </LabeledInput>
          <LabeledInput helperText={t("admin.user.create.form.helper.sendResetPasswordMail")}>
            <Checkbox
              label={t("admin.user.create.form.sendResetPasswordMail")}
              onChange={setSendResetPasswordEmail}
              checked={sendResetPasswordEmail}
            />
          </LabeledInput>
          {!sendResetPasswordEmail ? (
           <LabeledInput
           label={t("admin.user.create.form.password")}
           helperText={t("admin.user.create.form.helper.password")}
           required
         >
           <Input
             onChangeText={setPassword}
             value={password}
             secureTextEntry
             placeholder={t("admin.user.create.form.password") + " *"}
             autoCompleteType="new-password"
           />
         </LabeledInput>
          ) : null}
          <LabeledInput
            required
            label={t("admin.user.create.form.role")}
            style={{ zIndex: 3 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={roles}
              onChange={updateRole}
              placeholder={t("admin.user.create.form.role")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.user.create.form.language")}>
            <PickerInput
              items={[
                {
                  label: t("general.choose"),
                  value: "",
                },
                ...getLanguageItems().map((item) => ({
                  label: item.label,
                  value: item.value,
                })),
              ]}
              onValueChange={setLanguage}
              selectedValue={language || ""}
            />
          </LabeledInput>
          {isSuperAdmin ? (
            <LabeledInput
              required
              label={t("admin.user.create.form.company")}
              style={{ zIndex: 2 }}
            >
              <Select
                isMultiSelect={false}
                required
                items={companies}
                onChange={updateCompany}
                placeholder={t("admin.user.create.form.company")}
              />
            </LabeledInput>
          ) : null}
          <LabeledInput>
            <Checkbox
              label={t("admin.user.create.form.sendMail")}
              onChange={setSubscribeToNewsletter}
              checked={subscribeToNewsletter}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.user.create.form.submit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
