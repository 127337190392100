import React, { FC, useRef } from "react";
import styled from "styled-components/native";
import { borderWidth, color, spacing } from "../../styles/theme";
import { useHistory } from "../../routing";
import { useFocus, useHover } from "react-native-web-hooks";
import { SmallTitle } from "../../styles/Title";

interface BorderBottomProps {
  isActive?: boolean;
  isHighlighted?: boolean;
}

const ItemWrapper = styled.TouchableOpacity`
  padding: ${spacing.medium} ${spacing.large};
  position: relative;
`;

export const BottomBorder = styled.View<BorderBottomProps>`
  height: ${borderWidth.medium};
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  background-color: ${({ isHighlighted, isActive }) => {
    if (isActive) return color.secondary;
    else if (isHighlighted) return color.greyLighter;
    else return color.greyLightest;
  }};
`;

export interface PageItem {
  label: string;
  path?: string;
  onClick?: () => void;
  roles?: string[];
}

const PageSelectorItem: FC<PageItem> = ({ label, path, onClick }) => {
  const history = useHistory();
  const currentLocation = history.location.pathname;
  const element = useRef(null);
  const isHovering = useHover(element);
  const isFocussing = useFocus(element);

  return (
    <ItemWrapper
      ref={element}
      onPress={() => {
        if (onClick) {
          onClick();
        }
        if (path) {
          history.push(path);
        }
      }}
    >
      <SmallTitle color={(currentLocation === path) ? color.secondary : undefined}>{label}</SmallTitle>
      <BottomBorder
        isActive={currentLocation === path}
        isHighlighted={isHovering || isFocussing}
      />
    </ItemWrapper>
  );
};

export default PageSelectorItem;
