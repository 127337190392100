import moment from "moment";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components/native";
import { Pressable, Linking, Clipboard } from "react-native";
import {
  Company,
  CompanyWithCounts
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import Link from "../../../components/Link";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import SearchInput from "../../../components/SearchInput";
import Table, { TableContent } from "../../../components/Table";
import infoIcon from "../../../assets/icons/icon_admin_info.svg";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSearch } from "../../../hooks/useSearch";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import {
  OverviewHeader,
  OverviewHeaderInnerRight,
} from "../../../styles/Overview";
import { SmallText } from "../../../styles/Text";
import { color, spacing } from "../../../styles/theme";
import Title from "../../../styles/Title";
import Pill from '../../../components/Pill';

const InfoIcon = styled.Image`
  margin-right: ${spacing.small};
  color: ${color.grey};
`;

const IconWrapper = styled.View`
  margin-right: 10px;
`;

const columnSizes = [2.3, 1.5, 1, 1.1, 1.1, 1, 1, 1, 1, 1, 1];
const createTableData = (history: any, items: CompanyWithCounts[]) => {
  const tableData: TableContent[] = items.map(
    ({
      name,
      id,
      billingType,
      billingPricePerCustomer,
      billingStartDate,
      billingEndDate,
      language,
      locationCount,
      mapCount,
      reservationCount,
      created,
      userCount,
      activeUserCount,
      microsoftSyncEnabled,
    }) => {
      let statusTextColor = color.grey;
      let statusBackgroundColor = color.greyLightest;

      switch (billingType) {
        case 'free':
          statusTextColor = color.tertiaryDark;
          statusBackgroundColor = color.tertiaryLight;
          break;
        case 'none':
          statusTextColor = color.grey;
          statusBackgroundColor = color.greyLightest;
          break;
        case 'stopped':
            statusTextColor = color.bmdRedDark;
            statusBackgroundColor = color.bmdRedLight;
            break;
        case 'enterprise':
          statusTextColor = color.bmdPurleDark;
          statusBackgroundColor = color.bmdPurpleLight;
          break;
        case 'month':
          statusTextColor = color.quaternary;
          statusBackgroundColor = color.quaternaryLight;
          break;
        case 'year':
          statusTextColor = color.quinary;
          statusBackgroundColor = color.quinaryLight;
          break;
      }
      const copyCompanyID = ()=> Clipboard.setString(id);

      let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      let billingStartDateLiteral = (new Date(billingStartDate)).getTime(); //userCompany.billingStartDate 10/07/2022
      let today = (new Date()).getTime();

      let trialPeriodDays = 21 * 24 * 60 * 60 * 1000; // 21 days * hours * minutes* seconds* milliseconds
      let numberOfDaysTrial = Math.round(Math.abs(today - billingStartDateLiteral) / oneDay);
      let trialPeriodEnds = 22 - numberOfDaysTrial;

      // <SmallText key="endDate">
      // {billingEndDate !== 'Invalid date' && (
      //   <>{moment.utc(billingEndDate).format("DD-MM-YYYY")}</>
      // )}
      // </SmallText>,

      const data: ReactElement[] = [
        <SmallText>
          <Link
            onPress={() => history.push(`/admin/company-overview/update/` + id) }
          >{name}
          </Link>
          <Link
            onPress={(copyCompanyID)}
          >
            <InfoIcon
              style={{ width: 16, height: 16, marginLeft: 5, top: 4 }}
              source={infoIcon}
            />
          </Link>
        </SmallText>,
        <SmallText key="subscription">
          <Pill
            backgroundColor={statusBackgroundColor}
            textColor={statusTextColor}
            key="subscriptionType"
          >
            {t(`admin.company.overview.subscriptionType.${billingType}`)} {billingType === 'free' ? '(' + trialPeriodEnds + ')' : ''}
          </Pill>
        </SmallText>,
        <SmallText key="amount">{billingPricePerCustomer}</SmallText>,
        <SmallText key="startDate">
          {moment.utc(billingStartDate).format("DD-MM-YYYY")}
        </SmallText>,
        <SmallText key="endDate">
        {billingEndDate !== 'Invalid date' && (
          <>{moment.utc(billingEndDate).format("DD-MM-YYYY")}</>
        )}
        </SmallText>,
        <SmallText key="locations">{locationCount} <SmallText style={{ color: "grey" }}>→ {mapCount}</SmallText></SmallText>,
        <SmallText key="users">{activeUserCount} <SmallText style={{ color: "grey", fontSize: "12px" }}>/{userCount}</SmallText></SmallText>,
        <SmallText key="reservations">{reservationCount}</SmallText>,
        <SmallText key="microsof">{microsoftSyncEnabled ?
          <Pill
            key="microsoft"
            textColor={color.quinary}
            backgroundColor={color.quinaryLight}
          >{t("general.yes")}</Pill>
        :
          <Pill
            key="microsoft"
            textColor={color.grey}
            backgroundColor={color.greyLightest}
          >{t("general.no")}</Pill>
        }</SmallText>,
        <SmallText key="language">{language}</SmallText>,
        <SmallText key="created">
          {moment.utc(created).format("DD-MM-YYYY")}
        </SmallText>,
      ];
      return {
        data,
        id,
        canDelete: false,
        canEdit: true,
        hideEditButton: false,
        hideDeleteButton: true,
      };
    }
  );
  return tableData;
};

const CompanyOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.company.overview.name"), true, "name"),
    createHeaderItem(t("admin.company.overview.subscription"), false),
    createHeaderItem(
      t("admin.company.overview.amount"),
      true,
      "billingPricePerCustomer"
    ),
    createHeaderItem(
      t("admin.company.overview.startDate"),
      true,
      "billingStartDate"
    ),
    createHeaderItem(
      t("admin.company.overview.endDate"),
      false,
      "billingEndDate"
    ),
    createHeaderItem(t("admin.company.overview.office"), false),
    createHeaderItem(t("admin.company.overview.users"), false),
    createHeaderItem(t("admin.company.overview.reservations"), false),
    createHeaderItem("MS", false),
    createHeaderItem(t("admin.company.overview.language"), true, "language"),
    createHeaderItem(
      t("admin.company.overview.created"),
      true,
      "created",
      true,
      false
    ),
  ];
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { companyPages } = useAuth();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listCompanies>[0]>(tableHeaders);

  const [data, setData] = useState<Array<Company>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<Company>();
  const { debouncedSearchTerm, setDebouncedSearchTerm } = useSearch();
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const fetchListItems = useCallback(async () => {
    if (
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(
        client.listCompanies(
          sortField,
          sortOrder,
          debouncedSearchTerm,
          takeSize,
          skipParam
        )
      );
    }
  }, [
    debouncedSearchTerm,
    searchTerm,
    handleRequest,
    client,
    sortField,
    sortOrder,
    skipParam,
  ]);

  useEffect(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setTableData(createTableData(history, items));
        setData(items || []);
        setTotalItems(total);
      }
    });
  }, [history, skipParam, sortOrder, sortField, fetchListItems]);

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteCompany(itemSelected!.id)
    );

    if (response && response.status === 204) {
      await fetchListItems().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items, total } = result.data.result;
        if (items) {
          setTableData(createTableData(history, items));
          setData(items);
          setTotalItems(total);
        }
      });

      setShowDeleteMessage(true);
    }
  }, [history, handleRequest, client, itemSelected, fetchListItems]);

  const updateSearch = (text: string) => {
    setSearchTerm(text);
    setDebouncedSearchTerm(text);
    setSkipParam(0);
  };

  return (
    <>
      <Container>
        <OverviewHeader>
          <Title>{t("admin.company.overview.title")}</Title>
          <OverviewHeaderInnerRight>
            <SearchInput
              wrapperStyle={`margin-right: ${spacing.medium};`}
              placeholder={t("general.filter.search")}
              onChangeText={updateSearch}
              value={searchTerm}
            />
            <Button
              onPress={() => history.push("/admin/company-overview/create")}
            >
              {t("admin.company.overview.addButton")}
            </Button>
          </OverviewHeaderInnerRight>
        </OverviewHeader>
        <PageSelector pages={companyPages} />
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        ) : null}
        {isLoading ? null : (
          <Table
            tableId="company_overview"
            headerItems={headerItems}
            tableContent={tableData}
            columnSizes={columnSizes}
            sortList={onSortList}
            sortOrder={sortOrder}
            sortField={sortField}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/company-overview/update/${id}`)
            }
            onDeleteItem={openDeleteModal}
          />
        )}
        {totalItems && !isLoading ? (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        ) : undefined}
      </Container>

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.company.delete.warning", {
          name: itemSelected?.name,
        })}
      />
    </>
  );
};

export default CompanyOverview;
