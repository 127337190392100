module["exports"] = [
  "مریم",
  "نازنین",
  "غزاله",
  "محدثه",
  "بهناز",
  "زینب",
  "کیمیا",
  "سوسن",
  "لاله",
  "آنا",
  "آناهیتا",
  "آنیتا",
  "آرمیتا",
  "آتوسا",
  "آیدا",
  "بنفشه",
  "بهار",
  "بیتا",
  "پارمیس",
  "پریناز",
  "پریسا",
  "پرنیان",
  "سارا",
  "پگاه",
  "ترانه",
  "چکاوک",
  "دریا",
  "درسا",
  "فرشته",
  "ملیسا",
  "ملیکا",
  "رویا",
  "زیبا",
  "یاسمن",
  "سپیده",
  "سمیرا",
  "سیما",
  "شادی",
  "هانیه",
  "شهرزاد",
  "شکوه",
  "سمیه",
  "شهلا",
  "شیدا",
  "شیوا",
  "فرانک",
  "فرزانه",
  "فرناز",
  "فریبا",
  "فریماه",
  "کتایون",
  "گلاره",
  "گیتی",
  "گیسو",
  "مروارید",
  "مهرناز",
  "مهسا",
  "مینا",
  "مینو",
  "مهوش",
  "میترا",
  "نگین",
  "نگار",
  "نیلوفر",
  "رز",
  "هلیا",
  "هستی"
];
