module["exports"] = [
  "يافع",
  "هذيل",
  "همدان",
  "يافع",
  "الأشراف",
  "الكراي",
  "الرقيق",
  "بودوارة",
  "خنفير",
  "السقا",
  "الطريقي",
  "الشريف",
  "المنيف",
  "النفير",
  "الشتيوي",
  "الشيباني",
  "الخليفي",
  "المولدي",
  "الكافي",
  "العواني",
  "بن عبد السلام",
  "عاشور",
  "النفطي",
  "بن عثمان",
  "بن عاشور",
  "البشيري",
  "السقاط",
  "ادريس",
  "بن حسين",
  ,"القلشاني",
  "الحجيج",
  "بن عبد الكريم",
  "فقوسة",
  "بن حمودة",
  "العنابي",
  "القلال",
  "الدنوني",
  "النقاش",
  "الغربي",
  "نيفر",
  "العطار",
  "المحجوب",
  "زقية",
  "تومي",
  "بن عبدالله",
  "سعيد",
  "النابلي",
  "بن عمر",
  "شنيق",
  "عنون",
  "الرصاع",
  "النخلي",
  "الصفار",
  "الزوابي",
  "العلاني",
  "الع لويني",
  "الحمامي",
  "جاوحدو",
  "شقرون",
  "العلايمي",
  "العواني",
  "الصغير",
  "جابالله",
  "بنسلامة",
  "جراد",
  "بوديبة",
  "الدرقاش",
  "بوهاها",
  "عطالله",
  "سويس",
  "عظومة",
  "المدقي",
  "الوحيشي",
  "لخشين",
  "زروق",
  "النجار",
  "غويلة",
  "الطويل"
];
