module["exports"] = [
  "Claes",
  "Claeys",
  "Declerck",
  "Declercq",
  "Decock",
  "Decoster",
  "Desmet",
  "Devos",
  "Dewilde",
  "Gielen",
  "Goossens",
  "Hermans",
  "Jacobs",
  "Janssen",
  "Janssens",
  "Lemmens",
  "Maes",
  "Martens",
  "Mertens",
  "Michiels",
  "Peeters",
  "Smet",
  "Smets",
  "Thijs",
  "Vandamme",
  "Vandenberghe",
  "Vandenbroeck",
  "Vandevelde",
  "Verhaeghe",
  "Verstraete",
  "Willems",
  "Wouters"
]
