import React, { FC } from "react";
import { TouchableWithoutFeedback, ViewStyle } from "react-native";

import styled from "styled-components/native";
import { MarginBottomProps } from "../../styles/helpers/marginBottom";
import { color } from "../../styles/theme";

const RadioButtonContainer = styled.View`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border-width: 2px;
  border-color: ${color.greySelected};
  align-items: center;
  justify-content: center;
`;

const SelectDot = styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${color.secondary};
`;

interface RadioButtonProps extends MarginBottomProps, ViewStyle {
  checked?: boolean;
  onSelect?: () => void;
}

const RadioButton: FC<RadioButtonProps> = ({
  checked,
  onSelect = () => null,
}) => {
  return (
    <TouchableWithoutFeedback onPress={() => onSelect()}>
      <RadioButtonContainer>
        {checked && <SelectDot />}
      </RadioButtonContainer>
    </TouchableWithoutFeedback>
  );
};

export default RadioButton;
