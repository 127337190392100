import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components/native";
import {
  BillingType,
  CompanyProperties,
  InlineResponse400Errors,
  Language,
  Currency,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import DateSelector from "../../../components/DateSelector";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Select from "../../../components/Select";
import Toggle from "../../../components/Toggle";
import Grid, { Column } from '../../../components/Grid';
import HeaderCard from '../../../components/HeaderCard';
import { OverviewHeader } from '../../../styles/Overview';
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { t } from "../../../i18n";
import { useHistory, useParams } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { color, spacing } from "../../../styles/theme";
import { Title, MediumTitle, SmallTitle } from "../../../styles/Title";
import { isValidEmail } from "../../../utils";
import { getBillingItems, getLanguageItems, getCurrencies, getMetric, today } from "./Create";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Update: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [daysAdvance, setDaysAdvance] = useState("");
  const [forceTimeSlotsFeature, setForceTimeSlotsFeature] = useState(false);
  const [parkingSpotsAsResourceEnabled, setParkingSpotsAsResourceEnabled] = useState(false);
  const [hideCheckInTimeEnabled, setHideCheckInTimeEnabled] = useState(false);
  const [checkInFeature, setCheckInFeature] = useState(false);
  const [lunchFeature, setLunchFeature] = useState(true);
  const [visitorFeature, setVisitorFeature] = useState(true);
  const [homeEnabled, setHomeEnabled] = useState(true);
  /* compensation module */
  const [compensationEnabled, setCompensationEnabled] = useState(false);
  const [compensationCurrencyCode, setCompensationCurrencyCode] = useState(getCurrencies());
  const [compensationCutOffDay, setSompensationCutOffDay] = useState("");
  const [compensationDailyHomeCompensation, setCompensationDailyHomeCompensation] = useState("");
  const [compensationDistanceUnitCompensation, setCompensationDistanceUnitCompensation] = useState("");
  const [compensationExportEmail, setCompensationExportEmail] = useState("");
  const [compensationMaxDailyDistance, setCompensationMaxDailyDistance] = useState("");
  /* visitor module */
  const [
    visitorReservationConfirmEmailEnabled,
    setVisitorReservationConfirmEmailEnabled,
  ] = useState(true);
  const [facilitymanagementFeature, setFacilitymanagementFeature] =
    useState(true);
  const [reservationForUserEnabled, setReservationForUserEnabled] =
    useState(false);
  const [meetingFeature, setMeetingFeature] = useState(true);
  const [surfconextSsoEnabled, setSurfconextSsoEnabled] = useState(false);
  const [microsoftSsoEnabled, setMicrosoftSsoEnabled] = useState(false);
  const [microsoftSyncEnabled, setMicrosoftSyncEnabled] = useState(false);
  const [recurringReservationFeature, setRecurringReservationFeature] =
    useState(true);
  const [geofenceFeature, setGeofenceFeature] = useState(false);
  const [overlappingReservations, setOverlappingReservations] = useState(false);
  const [mapEnabled, setMapEnabled] = useState(true);
  const [presentpaneEnabled, setPresentpaneEnabled] = useState(true);
  const [multiSelectResourceEnabled, setMultiSelectResourceEnabled] = useState(true);
  const [languages, setLanguages] = useState(getLanguageItems());
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    Language.Nl
  );
  const [notes, setNotes] = useState("");
  const [ssoDomain, setSsoDomain] = useState("");

  const [saltoUsername, setSaltoUsername] = useState("");
  const [saltoPassword, setSaltoPassword] = useState("");

  const [provisioningDomain, setProvisioningDomain] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [billingTypes, setBillingTypes] = useState(getBillingItems());
  const [pricePerCustomer, setPricePerCustomer] = useState("");
  const [billingStartDate, setBillingStartDate] = useState(new Date());
  const [billingEndDate, setBillingEndDate] = useState<null | string>(null);
  const [selectedBillingType, setSelectedBillingType] =
    useState<BillingType | null>(null);

  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const fetchCompany = async () => await handleRequest(client.getCompany(id));

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { company } = result.data.result;

      setName(company.name);
      setFirstName(company.contactFirstname);
      setLastName(company.contactLastname);
      setEmail(company.contactEmail);
      setPhoneNumber(company.contactPhone);
      setDaysAdvance(company.reservationWindowLength.toString());
      setCheckInFeature(Boolean(company?.checkInEnabled));
      setForceTimeSlotsFeature(Boolean(company?.forceTimeslotUse));
      setParkingSpotsAsResourceEnabled(Boolean(company?.parkingSpotsAsResourceEnabled));
      setHideCheckInTimeEnabled(Boolean(company?.hideCheckInTimeEnabled));
      setLunchFeature(Boolean(company?.lunchEnabled));
      setVisitorFeature(Boolean(company?.visitorsEnabled));
      setHomeEnabled(Boolean(company?.homeEnabled));
      setCompensationEnabled(Boolean(company?.compensationEnabled));
      setVisitorReservationConfirmEmailEnabled(
        Boolean(company?.visitorReservationConfirmEmailEnabled)
      );
      setFacilitymanagementFeature(Boolean(company?.facilitymanagementEnabled));
      setReservationForUserEnabled(Boolean(company?.reservationForUserEnabled));
      setMeetingFeature(Boolean(company?.meetingEnabled));
      setRecurringReservationFeature(Boolean(company?.recurringEnabled));
      setGeofenceFeature(Boolean(company?.forceGeofence));
      setSelectedLanguage(company.language!);
      const updatedLanguages = languages.map((language) => ({
        ...language,
        isActive: language.value === company.language,
      }));
      setLanguages(updatedLanguages);
      setOverlappingReservations(
        Boolean(company.overlappingUserReservationsEnabled)
      );
      setMapEnabled(Boolean(company.mapEnabled));
      setPresentpaneEnabled(Boolean(company.presentpaneEnabled));
      setMultiSelectResourceEnabled(Boolean(company.multiSelectResourceEnabled));
      setSurfconextSsoEnabled(Boolean(company.surfconextSsoEnabled));
      setMicrosoftSsoEnabled(Boolean(company.microsoftSsoEnabled));
      setMicrosoftSyncEnabled(Boolean(company.microsoftSyncEnabled));
      setVoucherCode(company.billingVoucherCode || "");
      setNotes(company.notes || "");
      setProvisioningDomain(company.autoProvisioningDomain || "");
      setSsoDomain(company.ssoDomain || "");

      setSaltoUsername(company.saltoUsername || "");
      setSaltoPassword(company.saltoPassword || "");

      setPricePerCustomer(company.billingPricePerCustomer.toString());
      setBillingStartDate(company.billingStartDate);
      if(company.billingEndDate !== 'Invalid date') {
        setBillingEndDate(company.billingEndDate);
      } else {
        setBillingEndDate(null);
      }


      setSelectedBillingType(company.billingType);
      setEmployeeCount(`${company.employeeCount || ""}`);
      const updatedTypes = billingTypes.map((type) => ({
        ...type,
        isActive: type.value === company.billingType,
      }));
      setBillingTypes(updatedTypes);
    });
  }, [id, client, handleRequest]);

  const isFormValid = useCallback(
    () =>
      Boolean(name) &&
      Boolean(firstName) &&
      Boolean(lastName) &&
      isValidEmail(email) &&
      Boolean(phoneNumber) &&
      Boolean(daysAdvance) &&
      Boolean(selectedBillingType) &&
      Boolean(selectedLanguage) &&
      Boolean(pricePerCustomer) &&
      Boolean(billingStartDate),
    [
      name,
      firstName,
      lastName,
      email,
      phoneNumber,
      daysAdvance,
      selectedBillingType,
      selectedLanguage,
      pricePerCustomer,
      billingStartDate,
    ]
  );


  const submitForm = useCallback(async () => {
    const billingDate = moment.utc(billingStartDate).toISOString();

    const company: CompanyProperties = {
      name,
      contactFirstname: firstName,
      contactLastname: lastName,
      contactEmail: email,
      contactPhone: phoneNumber,
      reservationWindowLength: parseInt(daysAdvance),
      lunchEnabled: Boolean(lunchFeature),
      visitorsEnabled: Boolean(visitorFeature),
      homeEnabled: Boolean(homeEnabled),
      compensationEnabled: Boolean(compensationEnabled),
      visitorReservationConfirmEmailEnabled: Boolean(
        visitorReservationConfirmEmailEnabled
      ),
      facilitymanagementEnabled: Boolean(facilitymanagementFeature),
      reservationForUserEnabled: Boolean(reservationForUserEnabled),
      meetingEnabled: Boolean(meetingFeature),
      checkInEnabled: Boolean(checkInFeature),
      forceTimeslotUse: Boolean(forceTimeSlotsFeature),
      parkingSpotsAsResourceEnabled: Boolean(parkingSpotsAsResourceEnabled),
      hideCheckInTimeEnabled: Boolean(hideCheckInTimeEnabled),
      forceGeofence: Boolean(geofenceFeature),
      recurringEnabled: Boolean(recurringReservationFeature),
      overlappingUserReservationsEnabled: Boolean(overlappingReservations),
      mapEnabled: Boolean(mapEnabled),
      presentpaneEnabled: Boolean(presentpaneEnabled),
      multiSelectResourceEnabled: Boolean(multiSelectResourceEnabled),
      surfconextSsoEnabled: Boolean(surfconextSsoEnabled),
      microsoftSsoEnabled: Boolean(microsoftSsoEnabled),
      microsoftSyncEnabled: Boolean(microsoftSyncEnabled),
      language: selectedLanguage,
      employeeCount: parseInt(employeeCount) || undefined,
      billingVoucherCode: voucherCode,
      billingType: selectedBillingType!,
      billingPricePerCustomer: parseFloat(pricePerCustomer),
      billingStartDate: billingDate,
      billingEndDate: billingEndDate ? moment.utc(billingEndDate).toISOString() : undefined,
      autoProvisioningDomain: provisioningDomain,
      ssoDomain,
      saltoUsername,
      saltoPassword,
      notes,
    };
    try {
      const success = await handleRequest(client.updateCompany(id, company));

      if (success) {
        history.goBack();
        console.log(endDate);

      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    handleRequest,
    client,
    name,
    firstName,
    lastName,
    email,
    phoneNumber,
    daysAdvance,
    lunchFeature,
    visitorFeature,
    homeEnabled,
    compensationEnabled,
    visitorReservationConfirmEmailEnabled,
    facilitymanagementFeature,
    reservationForUserEnabled,
    meetingFeature,
    checkInFeature,
    forceTimeSlotsFeature,
    parkingSpotsAsResourceEnabled,
    geofenceFeature,
    recurringReservationFeature,
    selectedBillingType,
    pricePerCustomer,
    billingStartDate,
    billingEndDate,
    employeeCount,
    mapEnabled,
    overlappingReservations,
    presentpaneEnabled,
    multiSelectResourceEnabled,
    surfconextSsoEnabled,
    microsoftSsoEnabled,
    microsoftSyncEnabled,
    notes,
    selectedLanguage,
    voucherCode,
    history,
    provisioningDomain,
    ssoDomain,
    saltoUsername,
    saltoPassword,
    id,
    hideCheckInTimeEnabled,
  ]);

  const updateDaysAdvance = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setDaysAdvance(days);
    },
    [setDaysAdvance]
  );

  const updateEmployeeCount = useCallback(
    (newValue) => {
      const count = newValue.replace(/[^0-9]/g, "");
      setEmployeeCount(count);
    },
    [setEmployeeCount]
  );

  const updatePricePerCustomer = useCallback(
    (newValue) => {
      const price = newValue.replace(/[^0-9.]/g, "");
      setPricePerCustomer(price);
    },
    [setPricePerCustomer]
  );

  const updateBillingType = useCallback(
    (newValue) => {
      setSelectedBillingType(newValue[0].value);
    },
    [setSelectedBillingType]
  );

  const updateLanguage = useCallback(
    (newValue) => {
      setSelectedLanguage(newValue[0].value);
    },
    [setSelectedLanguage]
  );

  // <DateSelector
  //   date={billingStartDate}
  //   onSelect={setBillingStartDate}
  // />

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
      <OverviewHeader>
        {Boolean(error) && (
          <InputValidationErrors
            errors={errors}
            closeNotification={dismissError}
          />
        )}
        <MediumTitle>{t("admin.company.form.editTitle")}</MediumTitle>
      </OverviewHeader>
      <Grid>
        <Column columns={3}>
          <StyledFormCard>
            <SmallTitle hasMarginBottom>
              {t("admin.company.form.settingsHeader")}
            </SmallTitle>
            <SmallTitle></SmallTitle>
          <LabeledInput label={t("admin.company.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.company.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.days")} required helperText={t("admin.company.form.helper.days")}>
            <Input
              onChangeText={updateDaysAdvance}
              value={daysAdvance}
              placeholder={t("admin.company.form.days") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.language")}
            required
            style={{ zIndex: 11 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={languages}
              onChange={updateLanguage}
              placeholder={t("admin.company.form.language")}
            />
          </LabeledInput>
          </StyledFormCard>
          <StyledFormCard>
            <Toggle
              label={t("admin.company.form.meeting")}
              onChange={setMeetingFeature}
              toggleValue={meetingFeature}
            />
            <Toggle
              label={t("admin.company.form.home")}
              onChange={setHomeEnabled}
              toggleValue={homeEnabled}
            />
            <Toggle
              label={t("admin.company.form.visitors")}
              onChange={setVisitorFeature}
              toggleValue={visitorFeature}
            />
            <Toggle
              label={t(
                "admin.company.form.visitorReservationConfirmEmailEnabled"
              )}
              onChange={setVisitorReservationConfirmEmailEnabled}
              toggleValue={visitorReservationConfirmEmailEnabled}
            />
            <Toggle
              label={t("admin.company.form.facilitymanagement")}
              onChange={setFacilitymanagementFeature}
              toggleValue={facilitymanagementFeature}
            />
            <Toggle
              label={t("admin.company.form.checkIn")}
              onChange={setCheckInFeature}
              toggleValue={checkInFeature}
            />
            <Toggle
              label={t("admin.company.form.reservationForUser")}
              onChange={setReservationForUserEnabled}
              toggleValue={reservationForUserEnabled}
            />
            <Toggle
              label={t("admin.company.form.lunch")}
              onChange={setLunchFeature}
              toggleValue={lunchFeature}
            />
            <Toggle
              label={t("admin.company.form.compensation")}
              onChange={setCompensationEnabled}
              toggleValue={compensationEnabled}
            />
            <Toggle
              label={t("admin.company.form.recurring")}
              onChange={setRecurringReservationFeature}
              toggleValue={recurringReservationFeature}
            />
          </StyledFormCard>
        </Column>
        <Column columns={3}>
        <StyledFormCard>
          <SmallTitle hasMarginBottom>
            {t("admin.company.form.contactHeader")}
          </SmallTitle>
        <LabeledInput label={t("admin.company.form.personName")} required>
          <Input
            onChangeText={setFirstName}
            value={firstName}
            placeholder={t("admin.company.form.personName") + " *"}
          />
        </LabeledInput>
        <LabeledInput label={t("admin.company.form.personLastname")} required>
          <Input
            onChangeText={setLastName}
            value={lastName}
            placeholder={t("admin.company.form.personLastname") + " *"}
          />
        </LabeledInput>
        <LabeledInput label={t("admin.company.form.email")} required>
          <Input
            onChangeText={setEmail}
            value={email}
            placeholder={t("admin.company.form.email") + " *"}
          />
        </LabeledInput>
        <LabeledInput label={t("admin.company.form.phone")} required>
          <Input
            onChangeText={setPhoneNumber}
            value={phoneNumber}
            placeholder={t("admin.company.form.phone") + " *"}
          />
        </LabeledInput>
        </StyledFormCard>

        <StyledFormCard>
        <LabeledInput label={t("admin.company.form.ssoDomain")}>
          <Input
            onChangeText={setSsoDomain}
            value={ssoDomain}
            placeholder={t("admin.company.form.ssoDomain")}
          />
        </LabeledInput>

        <Toggle
          label={t("admin.company.form.surfconextSsoEnabled")}
          onChange={setSurfconextSsoEnabled}
          toggleValue={surfconextSsoEnabled}
        />
          <Toggle
            label={t("admin.company.form.microsoftSsoEnabled")}
            onChange={setMicrosoftSsoEnabled}
            toggleValue={microsoftSsoEnabled}
          />
          <Toggle
            label={t("admin.company.form.microsoftSyncEnabled")}
            onChange={setMicrosoftSyncEnabled}
            toggleValue={microsoftSyncEnabled}
          />
        <LabeledInput label={t("admin.company.form.provisioningDomain")}>
          <Input
            onChangeText={setProvisioningDomain}
            value={provisioningDomain}
            placeholder={t("admin.company.form.provisioningDomain")}
          />
        </LabeledInput>
        </StyledFormCard>

        <StyledFormCard>
          <Toggle
            label={t("admin.company.form.geofence")}
            onChange={setGeofenceFeature}
            toggleValue={geofenceFeature}
          />
          <Toggle
            label={t("admin.company.form.overlappingReservations")}
            onChange={setOverlappingReservations}
            toggleValue={overlappingReservations}
          />
          <Toggle
            label={t("admin.company.form.forceTimeslots")}
            onChange={setForceTimeSlotsFeature}
            toggleValue={forceTimeSlotsFeature}
          />
          <Toggle
            label={t("admin.company.form.parkingSpotsAsResources")}
            onChange={setParkingSpotsAsResourceEnabled}
            toggleValue={parkingSpotsAsResourceEnabled}
          />
          <Toggle
            label={t("admin.company.form.hideCheckInTimeEnabled")}
            onChange={setHideCheckInTimeEnabled}
            toggleValue={hideCheckInTimeEnabled}
          />
          <Toggle
            label={t("admin.company.form.mapEnabled")}
            onChange={setMapEnabled}
            toggleValue={mapEnabled}
          />
          <Toggle
            label={t("admin.company.form.presentpaneEnabled")}
            onChange={setPresentpaneEnabled}
            toggleValue={presentpaneEnabled}
          />
          <Toggle
            label={t("admin.company.form.multiSelectResourceEnabledParking")}
            onChange={setMultiSelectResourceEnabled}
            toggleValue={multiSelectResourceEnabled}
          />
        </StyledFormCard>
        </Column>
        <Column columns={3}>
        <StyledFormCard style={{ zIndex: 7 }}>
        <LabeledInput label={t("admin.company.form.notes")}></LabeledInput>
          <Input
            onChangeText={setNotes}
            multiline
            numberOfLines={4}
            value={notes}
            placeholder={t("admin.company.form.notes")}
          />
        </StyledFormCard>
          <StyledFormCard>
          <SmallTitle hasMarginBottom>
            {t("admin.company.form.financeHeader")}
          </SmallTitle>
          <LabeledInput
            label={t("admin.company.form.billingType")}
            required
            style={{ zIndex: 10 }}
          >
            <Select
              isMultiSelect={false}
              required
              items={billingTypes}
              onChange={updateBillingType}
              placeholder={t("admin.company.form.billingType") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.employeeCount")}>
            <Input
              onChangeText={updateEmployeeCount}
              value={employeeCount}
              placeholder={t("admin.company.form.employeeCount")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.billingPricePerCustomer")}
            required
          >
            <Input
              onChangeText={updatePricePerCustomer}
              value={pricePerCustomer}
              placeholder={
                t("admin.company.form.billingPricePerCustomer") + " *"
              }
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.billingStartDate")}
            required
            style={{ zIndex: 9 }}
            helperText="YYYY-MM-DD"
          >
            <Input
              onChangeText={setBillingStartDate}
              value={billingStartDate}
              placeholder={t("admin.company.form.billingStartDate")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.company.form.billingEndDate")}
            style={{ zIndex: 8 }}
            helperText="YYYY-MM-DD"
          >
            <Input
              onChangeText={setBillingEndDate}
              value={billingEndDate}
              placeholder={t("admin.company.form.billingEndDate")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.voucherCode")}>
            <Input
              onChangeText={setVoucherCode}
              value={voucherCode}
              placeholder={t("admin.company.form.voucherCode")}
            />
          </LabeledInput>
          </StyledFormCard>

          <StyledFormCard>
          <SmallTitle hasMarginBottom>
          {t(`admin.company.form.saltoHeader`)}
          </SmallTitle>

          <LabeledInput label={t("admin.salto.overview.saltoUsername")}>
            <Input
              onChangeText={setSaltoUsername}
              value={saltoUsername}
              placeholder={t("admin.salto.overview.saltoUsername")}
            />
          </LabeledInput>


          <LabeledInput label={t("admin.salto.overview.saltoPassword")}>
            <Input
              onChangeText={setSaltoPassword}
              value={saltoPassword}
              placeholder={t("admin.salto.overview.saltoPassword")}
            />
          </LabeledInput>
        </StyledFormCard>


        </Column>
      </Grid>
        <StyledFormCard>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.company.form.editSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Update;
