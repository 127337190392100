import React, { FC, useCallback, useState } from "react";
import { Switch, TouchableWithoutFeedback } from "react-native";

import styled from "styled-components/native";

import { color, spacing } from "../../styles/theme";
import { ExtraSmallText } from "../../styles/Text";
import marginBottom, {
  MarginBottomProps,
} from "../../styles/helpers/marginBottom";
import { ExtraSmallTextGood } from "../../styles/Text/extraSmallText";

const Wrapper = styled.View<MarginBottomProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${spacing.medium};

  ${(props) => marginBottom(props)};
`;

const ToggleLabel = styled(ExtraSmallTextGood)`
  display: flex;
  margin-left: ${spacing.small};
`;

interface ToggleTypes extends MarginBottomProps {
  onChange?: (newValue: boolean) => void;
  toggleValue: boolean;
  toggleDisabled?: boolean;
  label?: string;
  sliderWidth?: 10;
}

interface Toggle {
  backgroundColorChecked: '#2193F3',
  backgroundColorUnchecked: '#ccc',
  backgroundColorButton: '#fff',
  sliderWidth: 26,
  sliderHeight: 26,
  width: 60,
  height: 34,
  translate: 26,
}

const Toggle: FC<ToggleTypes> = ({ onChange, toggleValue, label, toggleDisabled }) => {
  const onToggle = () => {
    onChange && onChange(!toggleValue);
  };

  return (
    <TouchableWithoutFeedback onPress={onToggle}>
      <Wrapper hasMarginBottom>
        <Switch
          value={toggleValue}
          trackColor={{ false: color.greyLightest, true: color.quinary }}
          thumbColor={color.white}
          activeThumbColor={color.white}
          thumbTintColor={color.quinary}
          ios_backgroundColor={color.greyLightest}
          style={{ transform:[{ scaleX: 1.0 }, { scaleY: 1.0 }] }}
          disabled={toggleDisabled}
        />
        <ToggleLabel>{label}</ToggleLabel>
      </Wrapper>
    </TouchableWithoutFeedback>
  );
};

export default Toggle;
