module["exports"] = [
  "Norte",
  "Este",
  "Sul",
  "Oeste",
  "Nordeste",
  "Noroeste",
  "Sudeste",
  "Sodoeste"
];
