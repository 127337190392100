import { DefaultApiFactory } from '../../bookmydesk-api-sdk-typescript-axios';
import { getFromStorage, saveInStorage, storageKeys } from './asyncStorageService';
import { REACT_APP_API_URL, REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } from '@env';

export enum GrantType {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}
// Test
export const refreshAccessToken = async () => {
  const client = DefaultApiFactory(undefined, REACT_APP_API_URL);

  const response = await getFromStorage(storageKeys.refreshToken).then(
    (refreshToken) =>
      client.oAuthPasswordGrant(
        REACT_APP_CLIENT_ID,
        REACT_APP_CLIENT_SECRET,
        refreshToken!,
        GrantType.REFRESH_TOKEN
      )
  );
  const {refresh_token, access_token} = response.data;
  await saveInStorage(storageKeys.refreshToken, refresh_token);
  return {refresh_token, access_token};
};
