import React, { FC, useContext } from "react";
import styled from "styled-components/native";
import { useAuth } from "../../context/authContext";
import { spacing,color } from "../../styles/theme";
import PageSelectorItem, { BottomBorder, PageItem } from "./PageSelectorItem";

const OuterWrapper = styled.View`
  position: relative;
  flex-direction: row;
  margin-bottom: calc(${spacing.large} + ${spacing.medium});
  color: ${color.secondary}
`;

interface PageSelectorProps {
  pages: PageItem[];
}

const PageSelector: FC<PageSelectorProps> = ({ pages }) => {
  const { userState } = useAuth();

  return (
    <OuterWrapper>
      <BottomBorder />
      {pages
        .filter(
          (page) =>
            !page.roles ||
            (userState &&
              page.roles.filter((role) => userState.roles.includes(role))
                .length > 0)
        )
        .map((props, index) => (
          <PageSelectorItem key={index} {...props} />
        ))}
    </OuterWrapper>
  );
};
export default PageSelector;
