module["exports"] = [
  "النمل",
  "الخفافيش",
  "تتحمل",
  "النحل",
  "الطيور",
  "الجاموس",
  "القطط",
  "دجاجة",
  "ماشية",
  "الكلاب",
  "الدلافين",
  "البط",
  "الفيلة",
  "الأسماك",
  "الثعالب",
  "الضفادع",
  "أوز",
  "الماعز",
  "خيل",
  "الكنغر",
  "الأسود",
  "القرود",
  "البوم",
  "الثيران",
  "طيور البطريق",
  "اشخاص",
  "الخنازير",
  "الأرانب",
  "خروف",
  "النمور",
  "الحيتان",
  "الذئاب",
  "حمار",
  "الشؤم",
  "الغربان",
  "قطط سوداء",
  "الوهم",
  "أشباح",
  "المتآمرين",
  "التنين",
  "الأقزام",
  "الجان",
  "السحرة",
  "التعويذيون",
  "أبناء",
  "الأعداء",
  "العمالقة",
  "التماثيل",
  "العفاريت",
  "لحم الوز",
  "العنقاء",
  "استذئاب",
  "عدو",
  "الغيلان",
  "أقوال",
  "الأنبياء",
  "السحرة",
  "العناكب",
  "معنويات",
  "مصاصي دماء",
  "السحرة",
  "الثعالب",
  "ذئاب ضارية",
  "السحرة",
  "عبدة",
  "الاموات الاحياء",
  "درويدس"
];
