import React, { FC, useEffect } from "react";
import { Route } from "../routing";
import { Platform } from "react-native";

interface CustomRouteProps {
  screenTitle: string;
  exact: boolean;
  path: string;
  component?: FC;
}

const RouteWithTitle: FC<CustomRouteProps> = ({ screenTitle, ...rest }) => {
  useEffect(() => {
    Platform.OS === "web" && (document.title = screenTitle);
  });
  return <Route {...rest} />;
};

export default RouteWithTitle;
