module["exports"] = [
  "Հարությունյան",
  "Սարգսյան",
  "Հովհաննիսյան",
  "Հովհաննիսյան",
  "Սարգսյան",
  "Հարությունյան",
  "Գրիգորյան",
  "Խաչատրյան",
  "Խաչատրյան",
  "Գրիգորյան",
  "Հակոբյան",
  "Հակոբյան",
  "Վարդանյան",
  "Պետրոսյան",
  "Կարապետյան",
  "Գևորգյան",
  "Գևորգյան",
  "Վարդանյան",
  "Պետրոսյան",
  "Կարապետյան",
  "Մկրտչյան",
  "Մկրտչյան",
  "Ղազարյան",
  "Ղազարյան",
  "Մանուկյան",
  "Մանուկյան",
  "Պողոսյան",
  "Ավետիսյան",
  "Ավետիսյան",
  "Պողոսյան",
  "Մարտիրոսյան",
  "Սահակյան",
  "Սահակյան",
  "Մարտիրոսյան",
  "Դավթյան",
  "Առաքելյան",
  "Մարգարյան",
  "Մարգարյան",
  "Գալստյան",
  "Բաղդասարյան",
  "Առաքելյան",
  "Դավթյան",
  "Ստեփանյան",
  "Գալստյան",
  "Հայրապետյան",
  "Հայրապետյան",
  "Սիմոնյան",
  "Ավագյան",
  "Գասպարյան",
  "Սիմոնյան",
  "Մուրադյան",
  "Մուրադյան",
  "Ավագյան",
  "Գասպարյան",
  "Բաղդասարյան",
  "Ստեփանյան",
  "Ասատրյան",
  "Հովսեփյան",
  "Աբրահամյան",
  "Աբրահամյան",
  "Զաքարյան",
  "Մելքոնյան",
  "Մելքոնյան",
  "Ալեքսանյան",
  "Մխիթարյան",
  "Ասատրյան",
  "Բարսեղյան",
  "Մովսիսյան",
  "Կիրակոսյան",
  "Կիրակոսյան",
  "Եղիազարյան",
  "Բաբայան",
  "Հովսեփյան",
  "Մնացականյան",
  "Թադևոսյան",
  "Զաքարյան",
  "Մինասյան",
  "Մխիթարյան",
  "Բաբայան",
  "Բարսեղյան",
  "Ալեքսանյան",
  "Համբարձումյան",
  "Մովսիսյան",
  "Եղիազարյան",
  "Համբարձումյան",
  "Թադևոսյան",
  "Այվազյան",
  "Այվազյան",
  "Գաբրիելյան",
  "Ներսիսյան",
  "Մնացականյան",
  "Մովսիսյան"
];