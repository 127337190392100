import styled from "styled-components/native";
import { color, borderWidth, spacing } from "../theme";
import { convertObjectToStyle } from "../../utils";
import {ViewStyle} from "react-native";

interface HorizontalCardDividerProps extends ViewStyle {
  margin?: string;
}

export const HorizontalCardDivider = styled.View<HorizontalCardDividerProps>`
  width: 100%;
  margin: ${spacing.medium} 0;
  border-bottom-width: ${borderWidth.small};
  border-bottom-color: ${color.greyLightest};
  ${(props) => convertObjectToStyle(props, ['margin', 'marginTop'])};
`;
