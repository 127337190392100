module["exports"] = [
  "Allée",
  "Voie",
  "Rue",
  "Avenue",
  "Boulevard",
  "Quai",
  "Passage",
  "Impasse",
  "Place"
];
