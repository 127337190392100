import React, { FC, ReactNode } from "react";
import styled from "styled-components/native";

import { ListButtonWrapper, ListItem } from "../../styles/List";
import { css } from "styled-components";
import { spacing } from "../../styles/theme";

const TableCell = styled(ListItem)`
  padding: 0;
  margin: 0;
  justify-content: ${
    // @ts-ignore
    ({ position }) => position || 'flex-start'
  };
  flex-direction: row;
  align-items: center;
`;

const TableInnerWrapper = styled.View<{ noMargin?: boolean; }>`
  ${({ noMargin }) =>
    noMargin ? '' : css`
        margin: 0 ${spacing.small};
    `};
  width: calc(100% - ${(noMargin) => noMargin ? 0 : '16px' });
  flex-wrap: wrap;
`;

const TableActionWrapper = styled(ListButtonWrapper)`
  padding: 0;
  margin: 0;
  flex-direction: row;
  align-items: center;
`;

interface TableDataCellProps {
  columnSize?: number;
  children: ReactNode;
  isAction?: boolean;
  noMargin?: boolean;
  position?: string;
}

const TableDataCell: FC<TableDataCellProps> = ({
  columnSize,
  children,
  isAction = false,
  noMargin = false,
  position,
}) => {
  return (
    <TableCell columnSize={columnSize} position={position}>
      {isAction ? (
        <TableActionWrapper>{children}</TableActionWrapper>
      ) : (
        <TableInnerWrapper noMargin={noMargin}>
          {children}
        </TableInnerWrapper>
      )}
    </TableCell>
  );
};

export default TableDataCell;
