module["exports"] = [
  "ون",
  "کوپه",
  "پیکاپ",
  "مینی ون",
  "ون مسافرتی",
  "شاسی بلند",
  "سدان",
  "استیشن"
];
