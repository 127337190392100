var fr = {};
module['exports'] = fr;
fr.title = "French";
fr.address = require("./address");
fr.company = require("./company");
fr.internet = require("./internet");
fr.lorem = require("./lorem");
fr.name = require("./name");
fr.phone_number = require("./phone_number");
fr.date = require("./date");
