module["exports"] = [
  'Abadžić',
  'Abadžija',
  'Abazi',
  'Abdulahi',
  'Abdulić',
  'Abdurahmanović',
  'Abebe',
  'Abel',
  'Abfal',
  'Abičić',
  'Abičić-Tomac',
  'Abjanić',
  'Abjanović',
  'Abrahamski',
  'Abramić',
  'Abramović',
  'Abrecht',
  'Abrić',
  'Abrišin',
  'Abrlić',
  'Abu El Saoud',
  'Ackermann',
  'Ač',
  'Ačai',
  'Ačaji',
  'Ačanski',
  'Ačić',
  'Ačimović',
  'Ačkar',
  'Aćimov',
  'Aćimović',
  'Adam',
  'Adam Perl',
  'Adamček',
  'Adamčević',
  'Adamec',
  'Adamić',
  'Adamko',
  'Adamović',
  'Adašević',
  'Adelsberger',
  'Ademi',
  'Ademović',
  'Adjić',
  'Adjulović',
  'Adler',
  'Adlešić',
  'Ador',
  'Adrić',
  'Adžaga',
  'Adžaip',
  'Adžamić',
  'Adžić',
  'Adžidović',
  'Adžija',
  'Adžijević',
  'Ađaip',
  'Ađajip',
  'Ađamić',
  'Ađanski',
  'Ađić',
  'Ađin',
  'Ađulović',
  'Afrić',
  'Ag',
  'Agami',
  'Agatić',
  'Agbaba',
  'Ageljić',
  'Agh',
  'Agičić',
  'Agić',
  'Agoč',
  'Agostini',
  'Agotić',
  'Agović',
  'Ahić',
  'Ahmedbašić',
  'Ahmetbašić',
  'Ahmeti',
  'Ahmetović',
  'Ahmetspahić',
  'Ahmić',
  'Ajdari',
  'Ajdišek',
  'Ajdovac',
  'Ajduk',
  'Ajduković',
  'Ajfrid',
  'Ajh',
  'Ajhart',
  'Ajhenberger',
  'Ajhinberger',
  'Ajhinger',
  'Ajhler',
  'Ajhner',
  'Ajhorn',
  'Ajkić',
  'Ajro',
  'Ajtlbez',
  'Ajtman',
  'Ajzele',
  'Ajzeli',
  'Akerman',
  'Akik',
  'Akmadžić',
  'Akrap',
  'Aksentijević',
  'Akšamović',
  'AkšamovićAndrić',
  'Al Khamis',
  'Al Laham',
  'Al Younis',
  'Alaber',
  'Alaber Milić',
  'Aladenić',
  'Aladić',
  'AladićLjubišić',
  'Aladrović',
  'Aladžić',
  'Alagić',
  'Alaica',
  'Alaica Vlasalija',
  'Alajbeg',
  'Alajica',
  'Alapović',
  'Alavanja',
  'Alavuk',
  'Alberković',
  'Albert',
  'Albini',
  'Albrecht',
  'Albreht',
  'Albreht Mihaljević',
  'Alduk',
  'Alebić',
  'Alegić',
  'Aleksa',
  'Aleksandrov',
  'Aleksi',
  'Aleksić',
  'Aleksin',
  'Alempić',
  'Alerić',
  'Alfeldi',
  'Alfermann',
  'Alfort',
  'Alibašić',
  'Alibegović',
  'AlibegovićVašanda',
  'Aličić',
  'Alić',
  'AlićPartić',
  'Alidi',
  'Alih',
  'Alija',
  'Alikavazović',
  'Alili',
  'Alilović',
  'Alimanović',
  'Alimehaj',
  'Alimović',
  'Aliti',
  'Alkaz',
  'Alkhamis',
  'Alković',
  'Almadi',
  'Almaši',
  'Alošinac',
  'Alpeza',
  'Alšić',
  'AlšićMatković',
  'Alt',
  'Alt Higeli',
  'Altmann',
  'Aluga',
  'Alukić',
  'Alvadj',
  'Alvadžić',
  'Alvađ',
  'Aljija',
  'Aman',
  'Aman Krnjajić',
  'Amanović',
  'Ambreuš',
  'Ambrinac',
  'Ambroš',
  'Ambrozić',
  'Ambrožić',
  'Ambruš',
  'Ambrušić',
  'Ambruž',
  'Ament',
  'Ameti',
  'Amić',
  'Amidžić',
  'Amiti',
  'Amrein',
  'Amst',
  'Amšlinger',
  'Anastasović',
  'Ancić',
  'Ančić',
  'Andabak',
  'Andabak Fernandez',
  'Andabaka',
  'Andačić',
  'Andel',
  'Andelfinger',
  'Andelić',
  'Anderluch',
  'Andić',
  'Andjal',
  'Andl',
  'Andoči',
  'Andonovski',
  'Andračenko',
  'Andračić',
  'Andraković',
  'Andraković Štefanac',
  'Andraš',
  'Andrašec',
  'Andrašek',
  'Andrašev',
  'Andrašević',
  'Andraši',
  'Andrašić',
  'Andrašić Živalj',
  'Andrečenko',
  'Andrejek',
  'Andrejev',
  'Andrejević',
  'Andreković',
  'Andres',
  'Andri',
  'Andričević',
  'Andričić',
  'Andrić',
  'Andrić Blažević',
  'Andrić Karavidić',
  'Andrić Knežević',
  'Andrić Tomašević',
  'Andrijanić',
  'Andrijašević',
  'Andrijević',
  'Andrijin',
  'Andrik',
  'Andrin',
  'Andrišek',
  'Andrišić',
  'Andriška',
  'Andrlon',
  'Andročec',
  'Androić',
  'Androjić',
  'Androš',
  'Androšević',
  'Androšević Plevnik',
  'Andrušić',
  'Andučić',
  'Andžaković',
  'Anđal',
  'Anđan',
  'Anđel',
  'Anđelić',
  'Anđelković',
  'Anđić',
  'Anđić Grubešić',
  'Anetić',
  'Angalt',
  'Angebrandt',
  'Angirević',
  'Angster',
  'Aničić',
  'Aničić Viviora',
  'Anić',
  'AnićČabai',
  'AnićIvičić',
  'AnićKaliger',
  'AnićTot',
  'Anitić',
  'Anlauf-Raizner',
  'Anočić',
  'Anočić Valentić',
  'Antal',
  'Antal-Meničanin',
  'Antalašić',
  'Antaruk',
  'Antičević',
  'Antić',
  'Antinac',
  'Antolašić',
  'Antolić',
  'Antolković',
  'Antolović',
  'Antoljak',
  'Anton',
  'Antoni',
  'Antonić',
  'Antonijević',
  'Antonina',
  'Antoniutti',
  'Antonović',
  'Antoš',
  'Antrit',
  'Antukić',
  'Antunić',
  'Antunković',
  'Antunov',
  'Antunović',
  'Anušić',
  'Apalović',
  'Apari',
  'Apati',
  'Apatić',
  'Apčag',
  'Apel',
  'Apro',
  'Arab',
  'Arabadžić',
  'Arabašić',
  'Arači',
  'Aračić',
  'Aralica',
  'Arambašić',
  'Aranbašić',
  'Aranđelović',
  'Arap',
  'Arapović',
  'Arbanas',
  'Arbutina',
  'Arc',
  'Ardalić',
  'Arežina',
  'Arhanić',
  'Arić',
  'Arlavi',
  'Arlov',
  'Arlović',
  'Arman',
  'Armbrust',
  'Arnaut',
  'Arnautović',
  'Arnold',
  'Arnuš',
  'Arpaši',
  'Arsenić',
  'Arsenović',
  'Arsić',
  'Arsov',
  'Arsoy',
  'Artić',
  'Artuković',
  'Aručaj',
  'Arvaj',
  'Arvanitakos',
  'Arz',
  'Arzenšek',
  'Asan',
  'Asanov',
  'Asanović',
  'Asentić',
  'Asić',
  'Askočenski',
  'Aslanidis',
  'Astaloš',
  'Asztalos',
  'Ašađanin',
  'Aščić',
  'AščićLuketina',
  'Ašenbrener',
  'Ašimi',
  'Ašner',
  'Ašner-Boli',
  'Ašperger',
  'Ašvanj',
  'Ašvanji',
  'Atalić',
  'Atanacković',
  'Atanasovski',
  'Atlagić',
  'Atlašević',
  'Atlija',
  'Atmadžić',
  'Atvalić',
  'Aubelj',
  'Aubrecht',
  'Aubreht',
  'Augustić',
  'Augustin',
  'Augustinov',
  'Augustinović',
  'Augustinović Baronji',
  'Auguštanec',
  'Auguštin',
  'Ausec',
  'Ausman',
  'Ausmann',
  'Aust',
  'Aušperger',
  'Avdagić',
  'Avdaković',
  'Avdejev',
  'Avdičević',
  'Avdić',
  'Avdija',
  'Avi',
  'Avlijaš',
  'Avramek',
  'Avramoski',
  'Avramović',
  'Avrelio',
  'Avsec',
  'Axmann',
  'Azap',
  'Azapović',
  'Azaševac',
  'Azenić',
  'Azenić Mikulić',
  'Azinić',
  'Azinović',
  'Aziri',
  'Aždajić',
  'Ažić',
  'AžićVargaš',
  'Babac',
  'Babaj',
  'Babaja',
  'Babajić',
  'Baban',
  'Babatz',
  'Babel',
  'Babi',
  'Babičković',
  'Babić',
  'BabićKrižić',
  'Babić Matovinović',
  'Babić Romić',
  'Babić Štern',
  'Babijanski',
  'Babin',
  'Babli',
  'Babli Šandor',
  'Babočai',
  'Babogredac',
  'Babok',
  'Baborac',
  'Baboselac',
  'Baboš',
  'Babović',
  'Babunović',
  'Baburić',
  'Bacalo',
  'Bacanović',
  'Bacelj',
  'Bach',
  'Bachert',
  'Bachmann',
  'Bacho',
  'Backo',
  'Bač',
  'Bača',
  'Bačak',
  'Bačani',
  'Bačarko',
  'Baček',
  'Bačelić',
  'Bačelić Medić',
  'Bačić',
  'Bačić Panić',
  'Bačinski',
  'Bačko',
  'Bačkonja',
  'Bačković',
  'Bačlija',
  'Bačmaga',
  'Bačun',
  'Bačvanin',
  'Bačvanin-Balog',
  'Baćak',
  'Baćani',
  'Baći',
  'Baćin',
  'Baćinac',
  'Badanjak',
  'Badanjek',
  'Bader',
  'Badinsky',
  'Badjuk',
  'Badnjević',
  'Badovinac',
  'Badovinac-Golinac',
  'Badurina',
  'Badža',
  'Badžek',
  'Bađari',
  'Bađun',
  'Bagarić',
  'Bagarić Barišić',
  'Bagić',
  'Baginac',
  'Baglama',
  'Bago',
  'Bagudić',
  'Bahat',
  'Bahert',
  'Bahmajer',
  'Bahmet',
  'Bahović',
  'Bahun',
  'Baić',
  'Bainhart',
  'Bajac',
  'Bajak',
  'Bajakić',
  'Bajalo',
  'Bajamić',
  'Bajan',
  'Bajčetić',
  'Bajči',
  'Bajčinovci',
  'Bajer',
  'Bajer-Vitner',
  'Bajerle',
  'Bajević',
  'Bajhard',
  'Bajhardt',
  'Bajhart',
  'Bajić',
  'Bajinac',
  'Bajkovec',
  'Bajović',
  'Bajraktarević',
  'Bajraktari',
  'Bajram',
  'Bajrami',
  'Bajramović',
  'Bajrektarević',
  'Bajrić',
  'Bajs',
  'Bajs-Jovanović',
  'Bajsić',
  'Bajsman',
  'Bajt',
  'Bajtal',
  'Bajtl',
  'Bajto',
  'Bajus',
  'Bajušić',
  'Bajz',
  'Bak',
  'Baka',
  'Bakač',
  'Bakai',
  'Bakaj',
  'Bakaran',
  'Bakarec',
  'Bakarić',
  'Baketa',
  'Baketarić',
  'Baki',
  'Bakić',
  'Bakija',
  'Baklaić',
  'Bako',
  'Bakoš',
  'Bakota',
  'Baković',
  'Baković Kanižaj',
  'Bakran',
  'Baksa',
  'Baksa-Aćimović',
  'Bakša',
  'Bakula',
  'Bakunić',
  'Bala',
  'Balaban',
  'Balabanić',
  'Balač',
  'Balać',
  'Balai',
  'Balaić',
  'Balaj',
  'Balajić',
  'Balaković',
  'Balanže',
  'Balas',
  'Balaš',
  'Balašević',
  'Balaško',
  'Balašković',
  'Balat',
  'Balatinac',
  'Balaton',
  'Balaž',
  'Balažev',
  'Balažević',
  'Balaži',
  'Balažić',
  'Balažinec',
  'Balčak',
  'Baldasari',
  'Balekić',
  'Balen',
  'Balen-Domazetović',
  'Balent',
  'Balentić',
  'Balentović',
  'Baleta',
  'Balev',
  'Bali',
  'Baliban',
  'Balicki',
  'Baličević',
  'Balić',
  'BalićViola',
  'Balići',
  'Baligač',
  'Balihodžić',
  'Balikić',
  'Baliko',
  'Balinčić',
  'Balind',
  'Balinović',
  'Balint',
  'Balkić',
  'Balković',
  'Balle',
  'Baller',
  'Balliet',
  'Balog',
  'BalogŠandor',
  'Balogh',
  'Baloković',
  'Balonek',
  'Balonek-Nikolić',
  'Balošić',
  'Balta',
  'Balta-Koller',
  'Baltić',
  'Baltin',
  'Balukčić',
  'Baljak',
  'Bambir',
  'Ban',
  'Ban-Ogrin',
  'Bana',
  'Banac',
  'Banai',
  'Banaj',
  'Banaji',
  'Banak',
  'Bančević',
  'Bančić',
  'Banda',
  'Bandalo',
  'Bandić',
  'BandićŠtimac',
  'Bandjak',
  'Bandl',
  'Bando',
  'Bandov',
  'Bandur',
  'Banek',
  'Baneka',
  'Banfi',
  'Banfić',
  'Bango',
  'Baniček',
  'Baničin',
  'Banić',
  'BanićJokić',
  'Bank',
  'Banov',
  'Banovac',
  'Banović',
  'Banožić',
  'Banja',
  'Banjac',
  'Banjan',
  'Banjanac',
  'Banjanin',
  'Banjari',
  'Banjeglav',
  'Banjeglav-Polgar',
  'Banjeglavić',
  'Baotić',
  'BaotićĐaković',
  'Baraban',
  'Baraban-Glavaš',
  'Baraban-Jurišić',
  'Barabaš',
  'BarabašSeršić',
  'Barac',
  'Barač',
  'Barači',
  'Baračkov',
  'Barać',
  'Barać Ključanin',
  'Barak',
  'Baraković',
  'Baran',
  'Baranašić',
  'Baranić',
  'Baranović',
  'Baranj',
  'Baranja',
  'Baranjac',
  'Baranjec',
  'Baranji',
  'Barat',
  'Barbarić',
  'BarbarićŠehić',
  'Barberić',
  'Barbić',
  'Barbir',
  'Barbirović',
  'Barbulović',
  'Barčan',
  'Barčanac',
  'Barčić',
  'Bardak',
  'Bardek',
  'Bardić',
  'Bardy',
  'Bare',
  'Bareš',
  'Barešić',
  'Baretta',
  'Baričak',
  'Baričević',
  'Baričević Moher',
  'Baričević Nađ',
  'Baričjak',
  'Barić',
  'Barićević',
  'Barinić',
  'Barisić',
  'Barišić',
  'Barišić Dugonjić',
  'Barišić Đurić',
  'Barišić Jaman',
  'Barišić Joskić',
  'Barišin',
  'Barišin-Margaretić',
  'Barjaktarević',
  'Barjaktarić',
  'Barjaktarović',
  'Barkić',
  'Barković',
  'Barleković',
  'Barlović',
  'Barna',
  'Barnjak',
  'Baro',
  'Baroka',
  'Baronica',
  'Baronji',
  'Baronji-Zoltan',
  'Barošević',
  'Barović',
  'Bart',
  'Barta',
  'Bartanić',
  'Bartek',
  'Bartok',
  'Bartolac',
  'Bartolec',
  'Bartolić',
  'BartolićBošnjak',
  'Bartoloti',
  'Bartolotti',
  'Bartolov',
  'Bartolović',
  'Bartoluci',
  'Bartoš',
  'Bartovski',
  'Bartowski',
  'Bartsch',
  'Bartula',
  'Bartulac',
  'Bartulić',
  'Bartulin',
  'Bartulović',
  'BartulovićFranković',
  'BartulovićIvanović',
  'Barudžija',
  'Barukčić',
  'Barun',
  'Barun-Petrović',
  'Barunović',
  'Barušić',
  'Barut',
  'Basa',
  'Basar',
  'Basar-Klapec',
  'Basarić',
  'Basić',
  'Basrak',
  'Bassi',
  'Basta',
  'Basta-Glavaš',
  'Bastajić',
  'Bastalić',
  'Bastijan',
  'Baša',
  'Bašan',
  'Bašek',
  'Bašica',
  'Bašić',
  'BašićKljajić',
  'BašićKunce',
  'Baškarić',
  'Bašnec',
  'Baštijan',
  'Bašura',
  'Bašurić',
  'Bat',
  'Batai',
  'Batalo',
  'Batarilo',
  'BatariloĆerdić',
  'Batarilo-Ismailji',
  'Batas',
  'Batina',
  'Batina-Lemund',
  'Batina-Maksimović',
  'Batinac',
  'Batinić',
  'BatinićŠantić',
  'Batistuta',
  'Batki',
  'Batković',
  'Batljan',
  'Batnožić',
  'Bato',
  'Batona',
  'Bator',
  'Batorek',
  'Batori',
  'Batos',
  'Batovanja',
  'Batrac',
  'Batranović',
  'Batrenik',
  'Batrljan',
  'Batrnek',
  'Batrović',
  'Baturina',
  'Bauer',
  'Bauer-Jurić',
  'Bauernfrajnd',
  'Bauernfreund',
  'Bauković',
  'Baum',
  'Bauman',
  'Baumgartner',
  'Baumgertner',
  'Baumholc',
  'Baumstark',
  'Baus',
  'Baus-Mucić',
  'BausŽiga',
  'Bausch',
  'Bayer',
  'Bayer-Stojanović',
  'Bazijanac',
  'Bazikić',
  'Bazina',
  'Baznik',
  'Bazsika',
  'Bažaj',
  'Bažant',
  'Baždar',
  'Bažok',
  'Beara',
  'Beatović',
  'Beck-Pavlović',
  'Bečaj',
  'Bečanović',
  'Bečarević',
  'Bečeheli-Matijašec',
  'Bečirspahić',
  'Bečka',
  'Bečvardi',
  'Bećarević',
  'Bećvardi',
  'Bednaić',
  'Bednajić',
  'Bednar',
  'Bednjanić',
  'Bedžula',
  'Beđan',
  'Begaj',
  'Beganaj',
  'Beganović',
  'Begonja',
  'Begovac',
  'BegovićMitar',
  'Behadžić',
  'Beissmann',
  'Bek-Kohajda',
  'Bekavac',
  'Bekavac-Dugandžić',
  'Beketinac',
  'Bekina',
  'Bektaš',
  'Bekvalac',
  'Bela',
  'Belac',
  'Belačić',
  'Beladović',
  'Belaj',
  'Belajac',
  'Belajdžić',
  'Belajević',
  'Belak',
  'Belan',
  'Belančić',
  'Belanović',
  'Belanji',
  'Belas',
  'Belašić',
  'Belavić',
  'Belcar',
  'Belenzada',
  'Belina',
  'Bellian',
  'BellianŠčekić',
  'Belobradić',
  'Belobrajdić',
  'Belovari',
  'Belščak',
  'Belunjak',
  'Belvanović',
  'Beljac',
  'Beljak',
  'Beljakov',
  'Beljan',
  'Benac',
  'Benaček',
  'Benak',
  'Benaković',
  'Benašić',
  'BenašićPavlović',
  'Bencak',
  'Bencarić',
  'Benchaim',
  'Benčina',
  'Benda',
  'Bendelja',
  'Bendra',
  'Beneta',
  'BenićKapraljević',
  'BenkoĐaković',
  'BenkotićObradović',
  'Benkovac',
  'Bennani',
  'Benja',
  'Benjak',
  'Berač',
  'Beraj',
  'Berak',
  'Beraković',
  'Beram',
  'Beranić',
  'Beranović',
  'Berda',
  'Berdalović',
  'Berdica',
  'Beretovac',
  'Berger-Mihajlović',
  'Bergman',
  'Bergmann',
  'Beribak',
  'Berka',
  'Berke-Maričić',
  'Berlančić',
  'Bermanec',
  'Bernadić',
  'Bernardić',
  'Bernardis',
  'Bernatović',
  'Bernhard',
  'Bernhard-Zajac',
  'Bernhardt',
  'Bernvald',
  'Bernwald',
  'Beronja',
  'Berta',
  'Bertagnin',
  'Bertalan',
  'Bertalanić',
  'Bertanjoli',
  'Bertolan',
  'Bervida',
  'Besednjak',
  'Beslema',
  'Bestvina',
  'Bestvina-Koller',
  'Bešlaga',
  'BešlićFauth',
  'Beštijanek',
  'Bevanda',
  'Bevrnja',
  'Bezik-Haric',
  'Bezjak',
  'Bezmarević',
  'Bežan',
  'Bibanović',
  'Bicsany',
  'Bičak',
  'Bičanić',
  'Bičanji',
  'Bićan',
  'Bićanić',
  'Biderman',
  'Bidlingmaier',
  'Bigava',
  'Biglbauer',
  'Biglebauer',
  'Bihar',
  'Bijader',
  'Bijelica',
  'Bijuklija',
  'Bikadi',
  'Biketa',
  'BikićMrazović',
  'Bilac',
  'Bilač',
  'Bilać',
  'Bilali',
  'Bilan',
  'Bilandžić',
  'BilandžićVukovac',
  'Bilandžija',
  'Bilandžija-Peranović',
  'Bilanđija',
  'Bilanoska',
  'Bilanoski',
  'Bilanović',
  'Bilaver',
  'BilićMartinčević',
  'BilićSubašić',
  'Bilmajer',
  'Bilogravić',
  'Bilokapić',
  'Bilopavlović',
  'Biljak',
  'Biljan',
  'Biljanović',
  'Biljanovski',
  'Bingula',
  'Bionda',
  'Birač',
  'Birać',
  'Birka',
  'Birkaš',
  'Birkenmayer',
  'Birnbaum',
  'Biro-Datković',
  'Bisaga',
  'Bisaha',
  'Bisatzky',
  'Bistrica',
  'Bistrican',
  'Biščanić',
  'Bišćan',
  'Bišćanić',
  'BiškićMerkaš',
  'Bitanga',
  'Bitunjac',
  'Biuklija',
  'Bizacki',
  'Bizacky',
  'Bizjak',
  'Bizjan',
  'Bižaca',
  'Bjelac',
  'Bjelajac',
  'Bjelanović',
  'Bjelica',
  'BjelićFlaake',
  'Bjelka',
  'Bjelobaba',
  'Bjelokapić',
  'Bjeljac',
  'Bježančević',
  'Blače',
  'Blaće',
  'Blagajac',
  'Blagec',
  'Blago',
  'Blagojević',
  'BlagojevićDamašek',
  'BlagojevićGulaš',
  'Blagojevski',
  'Blagović',
  'BlagovićDžakić',
  'Blagus',
  'Blaguški',
  'Blaha',
  'Blanda',
  'Blank',
  'Blanuša',
  'Blaščanin',
  'Blaškić',
  'Blaško',
  'Blaško-Vidosavljević',
  'Blašković',
  'BlaškovićKokeza',
  'Blatančić',
  'Blatnik',
  'Blazević',
  'Blazi',
  'Blažanin',
  'Blažanović',
  'BlažanovićMatijević',
  'Blažej',
  'Blažek',
  'Blažeka',
  'Blažeković',
  'Blaženčić',
  'Blaženović',
  'Blažeski',
  'Blažetić',
  'Blažetin',
  'Blažev',
  'Blaževac',
  'Blažević',
  'BlaževićGaćeša',
  'BlaževićMešter',
  'BlaževićPoč',
  'BlaževićPrgomet',
  'Blaževski',
  'Blažičević',
  'Blažić',
  'Blažićević',
  'Blažina',
  'Blažinac',
  'Blažinčić',
  'Blažinić',
  'Blažinkov',
  'Blažon',
  'Blažoti',
  'Bliznac',
  'Bliznac-Jelčić',
  'Bluha',
  'Bobalić',
  'Boban',
  'Bobera',
  'Bobeta',
  'Bobinac',
  'Boboćanin',
  'Bobonja',
  'Bobovčan',
  'Boca',
  'Bocka',
  'Bockovac',
  'Bockovac-Kunštek',
  'Boczka',
  'Bočanji',
  'Bočina',
  'Bočkai',
  'Bočkaj',
  'Bočkinac',
  'Boćan',
  'Boda',
  'Bodakoš',
  'Bodalec',
  'Bodiroga',
  'Bodražić',
  'BodrožićDžakić',
  'Boduljak',
  'Bodza',
  'Bođanac',
  'Bogadi',
  'Bogar',
  'Bogataj',
  'Bogati',
  'Bogatić',
  'Bogatin',
  'Bogatirov',
  'Bogavac',
  'Bogdan',
  'Bogdan-Rudeš',
  'Bogdan-Trnka',
  'Bogdanić',
  'Bogdanović',
  'Bognar',
  'Bognar-Miletić',
  'Bogojevac',
  'Bogosanović',
  'Bogosavljević',
  'Bohaček',
  'Bohak',
  'Bohalj',
  'Bojanc',
  'Bojanić',
  'BojanićČalić',
  'Bojanin',
  'Bojanović',
  'Bojat',
  'Boka',
  'Bokan',
  'Bokanović',
  'Bokonja',
  'Bokovac',
  'Bolanča',
  'Bolarić',
  'Boldižar',
  'Bolf-Kovačić',
  'Bolkovac',
  'Bolmanac',
  'Bolmanović',
  'Boloban',
  'Bolot-Salaj',
  'Bolozan',
  'Bolta',
  'Boltadžija',
  'Boltađija',
  'Bolvanac',
  'Bolvar',
  'Bolvari',
  'Boljevac',
  'Boljkovac',
  'Bomeštar',
  'Bonjaj',
  'Boras',
  'Boras-Pintar',
  'Borbaš',
  'Borda',
  'Borevac',
  'Borgudan',
  'Borjan',
  'Borjanić',
  'BorojevićCrevar',
  'Boromisa',
  'Boroša',
  'Borota',
  'Borovac',
  'Borovička',
  'Borovićka',
  'Borozan',
  'Borščak',
  'Borzan',
  'Bosak',
  'Bosanac',
  'Bosanac-Balašević',
  'Bosanac-Hummel',
  'Bosanac-Wilhelm',
  'Bosančić',
  'Bosanić',
  'Bosankić',
  'Bosiljevac',
  'Bosjak',
  'Bosnar',
  'Boščanin',
  'Bošćak',
  'Bošnjak',
  'BošnjakĆirić',
  'BošnjakĐerfi',
  'Bošnjak-Franjo',
  'Bošnjak-Klečina',
  'Bošnjak-Krajšić',
  'Bošnjak-Mikulić',
  'Bošnjak-Pleškov',
  'Bošnjak-Vadkerti',
  'Bošnjaković',
  'BošnjakovićGrgić',
  'BošnjakovićHolik',
  'BošnjakovićZakanji',
  'BošnjakovićZetl',
  'Bota',
  'Botkuljak',
  'Boussa',
  'Bozalo',
  'Bozaroski',
  'Božac',
  'Božak',
  'Božanović',
  'Božičanin',
  'BožićErkalović',
  'BožićKrstanović',
  'Božićek-Panić',
  'Božidar',
  'Božika',
  'BožinovićMađor',
  'Božinovska',
  'Božučanin',
  'Brabec',
  'Brač',
  'Bračevac',
  'Bračevac-Belaj',
  'Bračić',
  'BračićDevald',
  'Bračko',
  'Bračun',
  'Brać',
  'Bradač',
  'Bradarić',
  'BradarićKalkan',
  'Bradašević',
  'Bradašić',
  'Bradek',
  'Bradičić',
  'Bradić',
  'Bradvica',
  'Bradvić',
  'Brađašev',
  'Brađić',
  'Brahaj',
  'Braić',
  'Brainović',
  'Brajčinović',
  'Brajdić',
  'Brajenović',
  'Brajić',
  'Brajinović',
  'Brajko',
  'Brajković',
  'BrajkovićCulek',
  'BrajkovićVican',
  'Brajnović',
  'Brajović',
  'Brajtenbah',
  'Brakić',
  'Bralić',
  'Bralo',
  'Bralj',
  'Brana',
  'Brandafi',
  'Brandalik',
  'Brandeis',
  'Brandeker',
  'Brandibur',
  'Brandić',
  'Brandis',
  'Brandt',
  'Branežac',
  'Branilović',
  'Brankov',
  'Branković',
  'Braović',
  'Braš',
  'Brašnić',
  'BrašnićAdžić',
  'BrašnićEži',
  'Brataljenović',
  'Bratanović',
  'Bratec',
  'Bratelj',
  'Bratić',
  'Bratonja',
  'Bratoš',
  'Bratuševac',
  'Braun',
  'Bravić',
  'Braz',
  'Brazda',
  'Brazdil',
  'Brazzi',
  'Brčina',
  'Brdar',
  'Brdarić',
  'Brđanin',
  'BreberićBradić',
  'Breberina',
  'Bredak',
  'Breitenbach',
  'Brekalo',
  'Breljak',
  'Brenđan',
  'Breskovac',
  'Brestovac',
  'Breščaković',
  'Brezak',
  'Brezenjački',
  'Brezinšak',
  'Brezinščak',
  'Brezinšćak',
  'Brezničar',
  'Brezovac',
  'Brezovnjački',
  'Brežnjak',
  'Brijaček',
  'Brijader',
  'Brindza',
  'Brinjak',
  'Brisuda',
  'Briševac',
  'Brizgala',
  'Brižan',
  'Brkan',
  'Brkanić',
  'BrkanićNekić',
  'Brkanović',
  'BrkićBlažeković',
  'Brkinjač',
  'Brkljač',
  'Brkljačić',
  'BrkovićKrpan',
  'Brlas',
  'Brljačić',
  'Brna',
  'Brnada',
  'Brnadić',
  'Brnas',
  'BrnićLevada',
  'Brnjak',
  'Brnjevarac',
  'Brnjica',
  'Brodar',
  'Brodarac',
  'Brodarić',
  'Brodnjan',
  'Brođanac',
  'Bršadinac',
  'Brtan',
  'Brubnjak',
  'Brumen-Stanojlović',
  'Brumerček-Lukačević',
  'Bruneta',
  'Brunjai',
  'Brunjai-Hihlik',
  'Brusač',
  'Brzaj',
  'Brzica',
  'Brženac',
  'Bubalo',
  'Bubalović',
  'Buban',
  'Bubanović',
  'Bubanj',
  'Bucalo',
  'Bucha',
  'Buchwald',
  'Bucifal',
  'Bučan',
  'Bučanac',
  'Bučanin',
  'Bučanović',
  'BučevićKeran',
  'Bučkalović',
  'Bućan',
  'Bućkalović',
  'Budač',
  'Budai',
  'Budaić',
  'Budaija',
  'Budak',
  'Budalić',
  'Budano',
  'Budija',
  'Budimac',
  'Budimčić-Kovačević',
  'Budimir-Bračevac',
  'Budimir-Pacek',
  'Budimir-Selina',
  'Budimir-Varžić',
  'Budimirac',
  'Budimlija',
  'Budiša',
  'Budrovac',
  'BudrovacŽivić',
  'Budulica',
  'Budžaki',
  'BuđićHalas',
  'Bugarić',
  'Bugarin',
  'Bugarinović',
  'Bugarski',
  'Buha',
  'Buhač',
  'Buhajčuk',
  'Buhinjak',
  'Buinac',
  'Buinjac',
  'Bujadinović',
  'Bujak',
  'Bujan',
  'Bujanović',
  'Bujda',
  'Bujela',
  'Bukal',
  'Bukalo',
  'Bukarica',
  'BukićMilardović',
  'Buklijaš',
  'Bukna',
  'Bukovac',
  'Bukovčak',
  'Bukta',
  'Bukumira',
  'Bukva',
  'Bukvašević',
  'Bukvica',
  'BukvićŠpika',
  'Bula',
  'Bulaić',
  'Bulaja',
  'Bulajić',
  'Bulaku',
  'Bulat',
  'Bulatović',
  'Buletinac',
  'Bulka',
  'Bulka-Barišić',
  'Buljan',
  'Buljanović',
  'Buljat',
  'Buljeta',
  'Buljubašić',
  'BuljubašićDakić',
  'BuljubašićKuzmanović',
  'Bumbak',
  'Bumbar',
  'Bunardžija',
  'Bunarđija',
  'Bunarkić',
  'Bundalo',
  'Buneta',
  'Bunoza',
  'Buntak',
  'Bunjac',
  'Bunjaković',
  'Bunjaku',
  'Bunjevac',
  'Bunjevac-Došen',
  'Bunjevac-Milivojević',
  'Bunjevac-Turalija',
  'Bura',
  'Buranji',
  'Buratović',
  'Burazer',
  'Burazer-Turko',
  'Burazin',
  'Burazor',
  'Burcar',
  'Burcar-Keri',
  'Burcsa',
  'Burča',
  'Burda',
  'Burdina',
  'Burđak',
  'Burian',
  'Burijan',
  'Burivoda',
  'Burja',
  'Burjan',
  'Burnać',
  'Bursać',
  'Burza',
  'Busak',
  'Busanić',
  'Buschbacher',
  'Buschman',
  'Buslaver',
  'Buša',
  'Bušbaher',
  'Bušbaher-Oberkirš',
  'Bušetinčan',
  'Bušljeta',
  'Bušpanović',
  'Butifar',
  'Butina',
  'Butinar',
  'Butorac',
  'Butorajac',
  'Butraković',
  'Butulija',
  'Buturac',
  'Buzadžić',
  'Buzaši',
  'Buzina',
  'Buzinac',
  'Buzjak',
  'Bužalja',
  'Bužančić',
  'Bužanec',
  'Bužanić',
  'Bužbaher',
  'Bužonja',
  'Bužonja-Bodiš',
  'Cabadaj',
  'Cabuha',
  'Cabunac',
  'Cacaj',
  'Cacciamani',
  'Cader',
  'Cafuk',
  'Cafuta',
  'Caga',
  'Cahunek',
  'Cajner',
  'Cajzler',
  'Cakić',
  'Cako',
  'Caktaš',
  'Camai',
  'Candl',
  'Canecki',
  'Cangajst',
  'Cangl',
  'Canjko',
  'Canjko-Novak',
  'Canjuga',
  'Capan',
  'Capek',
  'Capić',
  'Car',
  'Carbone',
  'Carek',
  'Carette',
  'Carević',
  'Carić',
  'Carin',
  'Cartl',
  'Catania',
  'Cats',
  'Cavrić',
  'Cazi',
  'CebićMarković',
  'Cecelja',
  'Cehmajster',
  'Celan',
  'Celjak',
  'Cembauer',
  'Cenbauer',
  'Centa',
  'Cepanec',
  'Ceranić',
  'Cerenko-Maletić',
  'Cergnar',
  'Ceribašić',
  'Cerjak',
  'Cerjan',
  'Ceronja',
  'Cerovac',
  'Cerovečki-Marijić',
  'Cesar',
  'Cesarec',
  'Cestar',
  'Cetina',
  'Cezar',
  'Cezner-Bačić',
  'Chadima',
  'Cibula',
  'Cicak',
  'Cickai',
  'Cickaj',
  'Cickaji',
  'Cicvara',
  'Cicvarić',
  'Cigan',
  'Ciganek',
  'Ciganović',
  'Ciganjik',
  'Cigula',
  'Cikač',
  'Cikoja',
  'Cikota',
  'Cikovac',
  'Cimaš',
  'Cimerman',
  'Cimeša',
  'Cimperšak',
  'Cincar',
  'Cinna',
  'Cipan',
  'Cipar',
  'Ciprijanović',
  'Ciprovac',
  'Cirak',
  'Ciraki',
  'Cirbaj',
  'Civaj',
  'Civalj',
  'Cmiljanić',
  'Cmrečak',
  'Cmrečnjak',
  'Coha',
  'Colarić',
  'Colnarić',
  'Conway',
  'Conjar',
  'Copak',
  'Cota',
  'Crepulja',
  'Crevar',
  'Crljenica',
  'Crnac',
  'Crnadak',
  'Crnajlov',
  'Crnčan',
  'Crnobrnja',
  'Crnobrnja-Bošnjak',
  'Crnobrnja-Vincetić',
  'Crnogaj',
  'Crnogaj-Kozari',
  'Crnogorac',
  'Crnoja',
  'Crnojevac',
  'Crnokraka',
  'Crnomarić',
  'Crnomarković',
  'Crnjac',
  'Crnjak',
  'Crnjaković',
  'Crnjanović',
  'Crnjanski',
  'Crvenka',
  'Csapo',
  'Csicska',
  'Csorba',
  'Csordas',
  'Cugovčan',
  'Culek-Sudar',
  'Culifaj',
  'Culjak',
  'Cunjak',
  'Curač',
  'Cuvaj',
  'Cvančić',
  'Cvelbar',
  'Cvetanić',
  'Cvetanović',
  'Cvetanovski',
  'Cvetanovski-Bundalo',
  'Cvetičan',
  'Cvetičanin',
  'Cvidrak',
  'Cvijančević',
  'Cvijanović',
  'Cvitan',
  'Cvitanović',
  'Cvitanušić',
  'CvitkovićBocka',
  'Cvjetićanin',
  'Cvrtak',
  'Cvrtila',
  'Cvrtnjak',
  'Czersky Hafner',
  'Czickai',
  'Czulan',
  'Čabai',
  'Čabaj',
  'Čabaj-Adam',
  'Čabarkapa',
  'Čabraja',
  'Čabrajac',
  'Čabrić',
  'Čabrilo',
  'Čabro',
  'Čačić',
  'ČačićArapović',
  'ČačićČasar',
  'Čačija',
  'Čačija-Dombik',
  'Čačilo',
  'Čačinović',
  'Čačković',
  'Čačulović',
  'Čaće',
  'Čaćija',
  'Čadek',
  'Čadeš',
  'Čađavčan',
  'Čađo',
  'Čagalj',
  'Čaglić',
  'Čagljević',
  'Čaić',
  'Čajan',
  'Čajić',
  'Čajka',
  'Čajko',
  'Čajkovac',
  'Čajkušić',
  'Čajlaković',
  'Čajo',
  'Čakalić',
  'Čakalo',
  'Čakar',
  'Čakardić',
  'Čakarević',
  'Čakarić',
  'Čakarun',
  'Čakić',
  'Čaklović',
  'Čakmak',
  'Čakvari',
  'Čaldarević',
  'Čale',
  'Čaleta',
  'Čalić',
  'Čališ',
  'Čalo',
  'ČaloČirjak',
  'Čaloš',
  'Čalošević',
  'Čalović',
  'Čalušić',
  'Čaljkušić',
  'ČaljkušićForgić',
  'Čamagajevac',
  'Čamber',
  'Čamdžić',
  'Čamić',
  'Čamilović',
  'Čampara',
  'Čanačić',
  'Čanadi',
  'Čanadić',
  'ČanadićFrelih',
  'Čanadžija',
  'Čanaglić',
  'Čanak',
  'Čanaki',
  'Čanakin',
  'Čančar',
  'Čanda',
  'Čandrlić',
  'ČandrlićDankoš',
  'ČandrlićFalajdžić',
  'ČandrlićMesarić',
  'Čango',
  'Čanić',
  'Čanović',
  'Čanžar',
  'Čanji',
  'Čapai',
  'Čapalija',
  'Čapalski',
  'Čapelj',
  'Čapin',
  'Čaplar',
  'Čapo',
  'Čapo-Milenić',
  'Čar',
  'Čarap',
  'Čarapar',
  'Čarapić',
  'Čarapović',
  'ČarapovićRanogajec',
  'Čardžić',
  'Čarnohorski',
  'Časar',
  'Časar-Lakatoš',
  'Čataj',
  'Čatalinac',
  'Čati-Sabo',
  'Čatić',
  'Čatipović',
  'Čatoš',
  'Čatović',
  'Čauš',
  'Čaušević',
  'Čaušić',
  'Čaut',
  'Čavajda',
  'Čavala',
  'Čavalić',
  'Čavar',
  'Čavara',
  'Čavarović',
  'Čavarušić',
  'Čavčić',
  'Čavić',
  'Čavka',
  'Čavka-Kakaš',
  'Čavlek',
  'Čavlina',
  'Čavlović',
  'Čavrag',
  'Čavužić',
  'Čečavac',
  'Čečura',
  'Čegar',
  'Čehajić',
  'Čehobašić',
  'Čehumbašić',
  'Čeika',
  'Čejka',
  'Čelan',
  'Čelant',
  'Čelar',
  'Čelebija',
  'Čeleda',
  'ČelikovićCvidrak',
  'Čelna',
  'Čeljuska',
  'Čeman',
  'Čemerika',
  'Čenaj',
  'Čenan',
  'Čendak',
  'Čengija',
  'Čeprnja',
  'Čeran',
  'Čerba',
  'Čergar',
  'Čeri-Varga',
  'Čerina',
  'Čerkašin',
  'Černava',
  'Černoga',
  'Černjanski',
  'Černjava',
  'Čertan',
  'Červenjak',
  'Češljar',
  'Češnjaj',
  'Čevrljaković',
  'Čibarić',
  'Čiča',
  'Čičak',
  'ČičinĆapin',
  'Čička',
  'Čičmak',
  'Čičovački',
  'Čičovački-Buzatović',
  'Čikaj',
  'Čikara',
  'Čikvar',
  'Čimbora',
  'Čimbura',
  'Činčak',
  'Činčurak',
  'Činčurak-Kovač',
  'Čipan',
  'Čipanj',
  'Čipanj-Banja',
  'Čirjak',
  'Čisar',
  'Čitari',
  'Čivčija',
  'Čizmadija',
  'Čizmar',
  'Čizmar-Tormaš',
  'Čizmarević',
  'Čizmazija',
  'Čizmešija',
  'Čižmarević',
  'Čižmešija',
  'Čmarec',
  'Čmelak',
  'Čobanović',
  'Čočaj',
  'Čoćaj',
  'Čogelja',
  'Čohodar',
  'Čoka',
  'Čokaš',
  'Čokljat',
  'Čokonai',
  'Čolak',
  'Čolaković',
  'Čolina',
  'Čonda',
  'ČondićBijader',
  'Čonka',
  'ČontošBalog',
  'Čorak',
  'ČorakŠebetić',
  'Čoralić',
  'Čorapović',
  'Čorba',
  'Čorda',
  'Čordaš',
  'Čordašev',
  'Čordašić',
  'Čorluka',
  'Čortan',
  'Čota',
  'Čotar',
  'Čotra',
  'Čovran',
  'Črešnjovnjak',
  'Čubela',
  'Čubra',
  'Čučak',
  'Čudina',
  'Čugalj',
  'Čugura',
  'Čuha',
  'Čuka',
  'Čukalevski',
  'Čukanec',
  'ČukićAladrović',
  'Čukman',
  'Čular',
  'Čulibrk-Walch',
  'Čulin-Lazić',
  'Čulina',
  'Čuljak',
  'Čuljat',
  'Čupurdija',
  'Čuraj',
  'Čurar',
  'Čurčija',
  'Čurila',
  'Čurman',
  'Čustonja',
  'Čutoraš',
  'Čutura',
  'Čuturaš',
  'Čuturilo-Petrač',
  'Čuvalo',
  'Čvagić',
  'Čvangić',
  'Čvarak',
  'Čvarković',
  'Čvorak',
  'Ćaćić',
  'Ćaćulović',
  'Ćajić',
  'Ćakić',
  'Ćale',
  'Ćaleta',
  'Ćalić',
  'Ćališ',
  'Ćalošević',
  'Ćalušić',
  'Ćamili',
  'Ćano',
  'Ćapin',
  'Ćaran',
  'Ćatić',
  'ĆatićKopf',
  'Ćavar',
  'Ćavara',
  'Ćavarović',
  'Ćehajić',
  'Ćelap',
  'Ćemalović',
  'Ćemalović Tompa',
  'Ćenan',
  'Ćeran',
  'Ćerluka',
  'Ćibarić',
  'Ćika',
  'ĆirićLitobac',
  'Ćiško Anić',
  'Ćoja',
  'Ćojbašić',
  'Ćora',
  'Ćorak',
  'Ćoralić',
  'Ćoranov',
  'Ćorda',
  'Ćorluka',
  'ĆosićMihaljević',
  'ĆovićRadojčić',
  'Ćubela',
  'Ćuća',
  'Ćudina',
  'Ćulina',
  'Ćupurdija',
  'Ćuraj',
  'Ćurak',
  'Ćurčija',
  'ĆurićBatić',
  'Ćuruvija',
  'Ćuti Ivanković',
  'Dabac',
  'Dabčević',
  'Dabić',
  'Dabnjak',
  'Dabo',
  'Dabović',
  'Dacić',
  'Dačić',
  'Dadasović',
  'Dadić',
  'DadićFabijanić',
  'Dado',
  'Dafish',
  'Dagen',
  'Dajč',
  'Dajčman',
  'Dajić',
  'Dajnek',
  'Daka',
  'Dakić',
  'DakićKožić',
  'Dako',
  'Daković',
  'Daku',
  'Dalić',
  'Dalip',
  'Dalipi',
  'Dalleaste',
  'Dalmatin',
  'Dalšašo',
  'Damašek',
  'Damiani',
  'Damijanić',
  'Damijanović',
  'Damjanić',
  'Damjanov',
  'Damjanović',
  'Damjenić',
  'Damljanović',
  'Damm',
  'Dananić',
  'Dančo',
  'Dandić',
  'Danenhauer',
  'Dani',
  'Daničić',
  'Danić',
  'Danilčević',
  'Danilović',
  'Dankić',
  'Danko',
  'Dankoš',
  'Danojević',
  'Danojlić',
  'Danji',
  'Darabošić',
  'Daražac',
  'Daraždi',
  'Daraži',
  'Dardai',
  'Dardalić',
  'Dardić',
  'Darić',
  'Darojković',
  'Daskalov',
  'Daskalova',
  'Dasović',
  'Dašić',
  'Datković',
  'Dautbegović',
  'Dautović',
  'David',
  'David Tomaz',
  'Davidović',
  'DavidovićAmić',
  'Davorija',
  'Day',
  'De Angelis',
  'De Brabander',
  'De Conceicao',
  'De La Fosse',
  'De Maio',
  'De Marco',
  'Deak',
  'Dean',
  'Deanković',
  'Deanović',
  'Debelak',
  'Debelja',
  'Debeljački',
  'Debeljak',
  'DebićPavlica',
  'Degač',
  'Degoricija',
  'Dejak',
  'Dejanović',
  'Dekan',
  'Dekanić',
  'Dekany',
  'Dekanj',
  'Deklman',
  'Dela',
  'Delač',
  'Delagić',
  'DelagićKušec',
  'Delalić',
  'Delalle',
  'Delaš',
  'Delbianco',
  'Delbijanko',
  'Delbjanko',
  'Delibašić',
  'DelićBrzica',
  'Delija',
  'Delimar',
  'Delizvani',
  'Deljak',
  'Deljak Franjo',
  'Demetar',
  'Demhart',
  'Demše Plavšić',
  'Denac',
  'Dengoricija',
  'Denša',
  'Dera',
  'Deran',
  'Dernaj',
  'Dernej Janković',
  'Dernik Katalin',
  'Desančić',
  'Desaty',
  'Deskar',
  'Desnica',
  'Despot Slade',
  'Devald',
  'DevčićKatić',
  'Devernaj',
  'Devetak',
  'Devetak Prokeš',
  'Dežmarić',
  'Di Carlo',
  'DidovićJančo',
  'Digula',
  'Digula Biljak',
  'Dijak',
  'Dijaković',
  'Dijanić',
  'Dijanović',
  'Dijkgraaf Larson',
  'Dimjašević',
  'Dimnjašević',
  'Dimovska',
  'Diniz De Carvalho',
  'Dinjar',
  'Divac',
  'Divald',
  'Divić Čanić',
  'Divjak',
  'Divjanović',
  'Divljaković',
  'Dizanov',
  'Dizdar',
  'Dizdarević',
  'Djak',
  'Djak Skeledžija',
  'Djaković',
  'Djambo',
  'Djanić',
  'Djanija',
  'Djapić',
  'Djekanović',
  'Dješka',
  'Djetka',
  'Djetvai',
  'Djeveljekaj',
  'Djevenica',
  'Djomlija',
  'Djurasek',
  'DjurdjevićSanela',
  'Djurita',
  'Dlab',
  'Dmejhal',
  'Dobaj',
  'Dobaj Ristić',
  'Dobo Tivadar',
  'Dobra',
  'Dobranić',
  'Dobraš',
  'Dobreta',
  'Dobričanin',
  'Dobrić Vizentaner',
  'Dobrinić Bogdanović',
  'Dobrosavljević',
  'Dobroslovački',
  'Dobrota',
  'Dobrovac',
  'Dobrovoljac',
  'Dobrovšak',
  'Dobsai',
  'Dočaj',
  'Dofatić',
  'Dogan',
  'Dohnal',
  'Dokletal',
  'Dokmanac',
  'Dokmanović',
  'Doknjaš',
  'Dokonal',
  'Dokoza',
  'Dokšanović',
  'Dolaček',
  'Dolački',
  'Dolančić',
  'DolančićBabić',
  'Dolanski',
  'Doležal',
  'Dolinac',
  'Doljan',
  'Doljanac',
  'Doljanin',
  'Doljankić',
  'Doma',
  'Domac',
  'Domačinović',
  'Domaćinović',
  'Domančić',
  'Domanovac',
  'Domazet',
  'Domazet Bezmalinović',
  'DomazetŠimica',
  'Domazetović',
  'Dombaj',
  'DomesČamagajevac',
  'Domijan',
  'Domjan',
  'DomjanićRuff',
  'Domjanović',
  'Domladovec',
  'Domladovec Ljikar',
  'Domljan',
  'Domnjak',
  'Domorad',
  'Domožvai',
  'Domša',
  'Donard',
  'Doppelhammer',
  'Dopsai',
  'Dopsaj',
  'Dopša',
  'Dora',
  'Dorčak',
  'Dori Vukas',
  'DorićRapaić',
  'Dorinka',
  'Dorman',
  'Dornštauder',
  'Dornjak',
  'Dorušak',
  'DorušakŠeper',
  'Dostal',
  'Dostan',
  'Doša',
  'Došan',
  'Došljanović',
  'Dotlić Plessa',
  'Dovčak',
  'Dovozalović',
  'Dozan',
  'Doža',
  'Drabek',
  'Drabek Milekić',
  'Drač',
  'Drača',
  'Dračić',
  'Dragalić',
  'Draganić',
  'Draganović',
  'Dragaš',
  'Dragčević',
  'Dragelj',
  'Drager',
  'Dragičević',
  'Dragić',
  'Dragila',
  'Dragin',
  'Draginić',
  'Dragišić',
  'Dragojević',
  'Dragojlov',
  'Dragojlović',
  'Dragoljević',
  'Dragomir',
  'Dragomirović',
  'Dragosavac',
  'Dragosavljević',
  'Dragović',
  'DragovićKovač',
  'DragovićZafred',
  'Dragovoljnović',
  'Dragozet',
  'Dragšić',
  'Draguljić',
  'Dragun',
  'Dragunić',
  'Dragušica',
  'Dragušić',
  'Dragušin',
  'Drahokoupil',
  'Drahotuski',
  'Drahotusky',
  'Drakčić',
  'Drakić',
  'Drako',
  'Draksler',
  'Drakšić',
  'Drakulić',
  'Drakulić Karač',
  'Dralić',
  'Dramac',
  'Dramlić',
  'Drapela',
  'Drapela Maravić',
  'Draščić',
  'Drašinac',
  'Drašinac Kurtagić',
  'Drašković',
  'Dravec',
  'Dravinac',
  'Dravinski',
  'Dravoj',
  'Dražan',
  'Draženović',
  'DraženovićPuhar',
  'Dražetić',
  'Dražić',
  'Drča',
  'Drda',
  'Drenovac',
  'Drenjačević',
  'Drenjanac',
  'Drenjančević',
  'Drenjančević Cigler',
  'Drenjančević Perić',
  'Drežnjak',
  'Drga',
  'Drgalić',
  'Drinovac',
  'Drinjak',
  'Drljača',
  'Drljača Božić',
  'Drljanac',
  'Drljepan',
  'Drnasin',
  'Drobac',
  'Drobila',
  'Drobina',
  'Drobnjak',
  'Droca',
  'Drokan',
  'Dropulja',
  'Drpa',
  'Druhar',
  'Družianić',
  'Družijanić',
  'Drvenica',
  'Drvenkar',
  'Držaić',
  'Držajić',
  'Držaljević',
  'Držanić',
  'Dubac',
  'Duba ađ',
  'Dubaić',
  'Dubičanac',
  'Dubovečak',
  'Dubravac',
  'Dubravčić',
  'Dubravec',
  'Dubravkić',
  'Dubroja',
  'Duda',
  'Dudarin',
  'Dudas',
  'Dudaš',
  'Dudašek',
  'Dudjak',
  'Dugac',
  'Dugački',
  'Dugaja',
  'Dugalić',
  'DugalićDelimar',
  'Dugančić',
  'Dugandžić',
  'Dugandžija',
  'Duganđić',
  'Duganđija',
  'Dugina',
  'Dugošija',
  'Duhaček',
  'Dujak',
  'Dujaković',
  'Dujan',
  'Dujanović',
  'DujmovićAquaro',
  'Duka',
  'Dukanović',
  'Dukarić',
  'DukarićPetriš',
  'DukarićSenaši',
  'Dukmanić',
  'Dukovac',
  'Dulemba',
  'Dumančić',
  'Dumandžić',
  'DumenčićBauer',
  'DumenčićJanson',
  'Dunai',
  'Dunaj',
  'Dunjak',
  'Dupan',
  'Dupca',
  'Dupljak',
  'Duraković',
  'Duran',
  'Duranović',
  'Duspara',
  'Dusparić',
  'Dušak',
  'Dušanek',
  'Dušanić',
  'Dutina',
  'Duvančić',
  'Duvnjak',
  'DuvnjakČuljak',
  'Duvnjak Jović',
  'Duvnjak Starčević',
  'Duždagić',
  'Dvizac',
  'Dvojak',
  'Dvorščak',
  'Dvoršćak',
  'Dvoržak',
  'Dvoržak Butković',
  'Džaferovski',
  'Džafić',
  'Džafran',
  'Džaić',
  'Džaja',
  'Džajić',
  'Džaka',
  'Džakić',
  'Džakmić',
  'Džakula',
  'Džal',
  'Džalo',
  'Džalto',
  'Džambas',
  'Džambić',
  'Džambo',
  'Džananović',
  'Džanić',
  'Džanija',
  'Džankić',
  'Džanko',
  'Džapo',
  'Dželadini',
  'Dželajlija',
  'Dželalija',
  'Dželalija Kovačić',
  'Džidžora',
  'Džijan',
  'Džodanović',
  'Džopalić',
  'Đaferi',
  'Đaferovski',
  'Đafić',
  'Đafran',
  'Đaić',
  'Đajić',
  'ĐajićFel',
  'Đakalović',
  'Đakovac',
  'Đaković',
  'Đakulović',
  'Đalić',
  'ĐalićKovačević',
  'Đambić',
  'Đambo',
  'Đana',
  'Đanić',
  'Đanija',
  'Đaniš',
  'Đanković',
  'Đapić',
  'Đebnar',
  'Đekemati',
  'Đelagić',
  'ĐelagićPetek',
  'Đelatović',
  'Đenadić',
  'Đenadija',
  'Đenovljanović',
  'Đerdak',
  'Đeri Krpan',
  'Đeri Sabo',
  'Đermanović',
  'Đetvai',
  'Đetvaj',
  'Đida',
  'Đidara',
  'Điđura',
  'Đipalo Ban',
  'Đipanov',
  'Đogaš',
  'Đomlija',
  'ĐonlagićPrutki',
  'Đopa',
  'Đopar',
  'Đordan',
  'Đošanović',
  'Đuđar',
  'Đuđik Kućan',
  'Đukanović',
  'Đukarić',
  'Đula',
  'Đulabić',
  'Đulinac',
  'Đumija',
  'Đumlija',
  'Đunda',
  'Đunđa',
  'Đuračić',
  'Đurački',
  'ĐuračkiĆosić',
  'Đuračković',
  'Đurak',
  'Đurak Kapetanović',
  'Đuraki',
  'Đurakić',
  'Đuraković',
  'Đuran',
  'Đuranić',
  'Đuranović',
  'Đuras',
  'Đurasek',
  'Đurasević',
  'Đurasinović',
  'Đurasov',
  'Đurasović',
  'Đurašević',
  'Đurašić',
  'Đurašin',
  'Đurašinović',
  'Đurčak',
  'Đurčanski',
  'Đurčianski',
  'Đurđan',
  'Đurđanović',
  'ĐurđevićFabijanić',
  'ĐurđevićMaršić',
  'Đurica',
  'ĐuričićTabaček',
  'ĐurićIvandija',
  'Đurinovac',
  'Đurinjak',
  'Đurita',
  'Đurkan',
  'Đurkas',
  'Đuza',
  'Eberhardt',
  'Eckhard',
  'Eckhardt',
  'Egredžija',
  'Ehman',
  'EiseleŽampach',
  'Ekart',
  'Ekštajn',
  'El Aydy',
  'El Ayedi',
  'El Hag Hassan',
  'Elaković',
  'Elezaj',
  'Eliaš',
  'Elijaš',
  'Elkaz',
  'Elkazović',
  'Elmazovski',
  'Eljuga',
  'Eman',
  'EmićTomac',
  'Emrulai',
  'Engelage',
  'Engelhart',
  'Engelman',
  'Erak',
  'Ercegovac',
  'Ercegovac Jagnjić',
  'Erdutac',
  'Eremija',
  'Ergarac',
  'Ergeđija',
  'Ergelašević',
  'Erhard',
  'Erhardt',
  'Erjavac',
  'Erkalović',
  'Erkapić',
  'Erkapić Maksimović',
  'Erkman',
  'Erman',
  'Erman Vlahović',
  'Erniša',
  'Erslan',
  'Ervačić',
  'Ervaćanin',
  'Esterajher',
  'Etemaj',
  'Eustahio',
  'Ezgeta',
  'Fabčić',
  'Fabekovac',
  'Faber',
  'Fabian',
  'Fabijan',
  'Fabijanac',
  'Fabijančić',
  'Fabijanec',
  'Fabijanić',
  'Fabijanović',
  'Fabik',
  'Fabing',
  'Fabing Megan',
  'Fabjan',
  'Fabo',
  'Faboš',
  'Fabric',
  'Fabrični',
  'Fabulić',
  'Faculjak',
  'Fačko',
  'Faddi',
  'Faddy',
  'Fadi',
  'Fadi Oršić',
  'Fadiga',
  'Fadljević',
  'Fafanđel',
  'Fahham',
  'Faić',
  'Fais',
  'Faist',
  'Faith',
  'Faj',
  'Fajdetić',
  'Fajdić',
  'Fajfer',
  'Fajl',
  'Fajn',
  'Fajrer',
  'Fajt',
  'Fak',
  'Faktor',
  'Faktorik',
  'Falamić',
  'Falanić',
  'Falašta',
  'Faledžić',
  'Faler',
  'Falica',
  'Fališevac',
  'Faller',
  'Faltum',
  'Fanali Gudelj',
  'Fančović',
  'Fanta',
  'Fanton',
  'Fapal',
  'Fapali',
  'Fara',
  'Farac',
  'Faraga',
  'Farago',
  'Faraho',
  'Farčić',
  'FarčićDori',
  'Farkaš',
  'FarkašMiketek',
  'Farkaševac',
  'Farkašić',
  'Faslija',
  'Fašeljak',
  'Fašing',
  'Fatić',
  'Fatušić',
  'Faubel',
  'Faukal',
  'Faussner',
  'Fazekaš',
  'FazekašMilanović',
  'Fazikaš',
  'Fazlić',
  'Fazlija',
  'Fazliji',
  'Fažon',
  'Feglain',
  'Feher Belaj',
  'Feher Delaš',
  'Fehervari',
  'Fehervary',
  'FehirČandrlja',
  'Fejzaj',
  'Fekete Kramar',
  'Fekete Pavković',
  'Feketija',
  'Fekonja',
  'Felcan',
  'Feldvari',
  'Feldvary',
  'FelendešPajcur',
  'Feletar',
  'Felja',
  'Feratović',
  'Ferčak',
  'Ferenac',
  'Ferenčak',
  'Ferenđa',
  'Ferhatović',
  'Ferinac',
  'Ferjanić',
  'Fernandez',
  'Fetahi',
  'Fiala',
  'Fiala Brig',
  'Fica',
  'FicaŠmit',
  'Fijačko',
  'Fijala',
  'FijanŠkrbić',
  'Fila',
  'Fila Paciorek',
  'Filak',
  'Filakov',
  'Filakov Bolšec',
  'Filaković',
  'FiličićParipović',
  'Filipan',
  'Filipašić',
  'FilipovićKerhat',
  'Filisar',
  'Filjak',
  'Findžanović',
  'Fingernagel',
  'Finta',
  'Fintajz',
  'Firanj',
  'Firi Vidaković',
  'Fischbach',
  'Fischbach Novaković',
  'Fistanić',
  'Fišbah',
  'Flac',
  'Flach',
  'Flačer',
  'Flajc',
  'Flajs',
  'Flajšaker',
  'Flajšek',
  'Flajšer',
  'Flam',
  'Flaman',
  'Flanjak',
  'Flaško',
  'Flatscher',
  'Flauder',
  'Flegar',
  'Flegarić',
  'Fletko Vrućina',
  'Flisak',
  'Fliszar',
  'Floreani',
  'Florian',
  'Florijan',
  'Florijančić',
  'Fluhar',
  'Fodora',
  'Fogadić',
  'Foldvary',
  'Folivarski',
  'Forbah',
  'ForgićPolaček',
  'Forgomba',
  'Forjan',
  'Forjanić',
  'Formbaher',
  'Fotak',
  'Fraj',
  'Frajlić',
  'Frajman',
  'Frajman Jakšić',
  'Frajmund',
  'Frajt',
  'Frajtag',
  'Fran',
  'Franc',
  'Francem',
  'Francetić',
  'Francezi',
  'Franchomme Strepački',
  'Francišković',
  'Franculić',
  'Francuz',
  'Francuzović',
  'Frančak',
  'Frančešević',
  'Frančić',
  'Frančina',
  'Franek',
  'Frangeš',
  'Franić',
  'Frank',
  'Frank Peko',
  'Frankić',
  'Franković',
  'Franolić',
  'Franotović',
  'Franov',
  'Franović',
  'Frant',
  'Franz',
  'Franjčević',
  'Franjec',
  'Franjetić',
  'Franjičević',
  'FranjičevićGašparac',
  'Franjić',
  'FranjićAbramić',
  'FranjićAdrić',
  'FranjićBartolović',
  'FranjićGrgić',
  'FranjićZelić',
  'Franjin',
  'Franjišić',
  'Franjkić',
  'Franjković',
  'Franjo',
  'Fras',
  'Fratrić',
  'Fratrović',
  'Frauenheim',
  'Frazi',
  'Freitag',
  'Fričan',
  'Frigan',
  'Frtalj',
  'Fučkalo',
  'Fučkar',
  'Fućkar',
  'Fuga',
  'Fulanović',
  'Funarić',
  'Funda',
  'Fundak',
  'Funtak',
  'Funjak',
  'Furda',
  'Furdi Valkai',
  'Furgala',
  'FurgalaŠuper',
  'Furjan',
  'Furlan',
  'Fury Marković',
  'Fuštar',
  'Futak',
  'Fužinato',
  'Gabaj',
  'Gabajček',
  'Gabela',
  'Gabelica',
  'Gabelić',
  'Gaberšek',
  'Gabina',
  'Gabor',
  'Gabori',
  'Gaborović',
  'Gabrić',
  'Gabud',
  'Gača',
  'Gačanović',
  'Gačar',
  'Gačić',
  'Gačina',
  'Gačpar',
  'Gaća',
  'Gaće',
  'GaćeŠobat',
  'Gaćeša',
  'Gaćina',
  'Gaćinović',
  'Gadanac',
  'Gado',
  'Gadža',
  'Gadžić',
  'Gađa',
  'Gafuri',
  'Gages',
  'Gagić',
  'Gagro',
  'Gagula',
  'Gagulić',
  'Gaić',
  'Gajari',
  'Gajčević',
  'Gajčić',
  'Gajdašević',
  'Gajdašić',
  'Gajdek',
  'Gajdoš',
  'Gajdošik',
  'Gajec',
  'Gajer',
  'Gajger',
  'Gajgić',
  'GajgićMatić',
  'Gajić',
  'GajićGrac',
  'GajićLukin',
  'GajićPope',
  'Gajnok',
  'Gajs',
  'Gajski',
  'Gajšek',
  'Gajtan',
  'Gajvaronski',
  'Gajzer',
  'Gajzinger',
  'Gajzler',
  'Gakić',
  'Gal',
  'Galac',
  'Galamboš',
  'Galba',
  'Gale',
  'Galeković',
  'Galenić',
  'Galešić',
  'Galeta',
  'Galetić',
  'Galgoci',
  'Gali',
  'Galic',
  'Galičić',
  'Galić',
  'GalićBotić',
  'GalićSubašić',
  'Galijašević',
  'Galin',
  'Galinac',
  'Galinec',
  'Galinović',
  'Galiot',
  'Galir',
  'Galkovski',
  'Gall',
  'Gallo',
  'Galo',
  'Galonja',
  'Galošević',
  'Galovac',
  'Galović',
  'Galunić',
  'Galušić',
  'Galjar',
  'Galjer',
  'Gambiraža',
  'Gambiroža',
  'Gamilar',
  'Gamma',
  'Gamoš',
  'Gams',
  'Ganić',
  'Gansberger',
  'Gantar',
  'Gantner',
  'Ganz',
  'GanzŽuparić',
  'Ganzberger',
  'Garac',
  'Garacki',
  'Garača',
  'Garačić',
  'Garai',
  'Garaj',
  'Garajski',
  'Garašić',
  'Garc',
  'Gardaš',
  'GardašKovačić',
  'Gardašanić',
  'Gardijan',
  'Gardilo',
  'Gardlik',
  'Gardlo',
  'Garić',
  'Garmaz',
  'Garonja',
  'Gartner',
  'Garvanović',
  'Gas',
  'Gashi',
  'Gasparović',
  'Gašić',
  'Gašo',
  'Gašpar',
  'Gašparac',
  'Gašparček',
  'Gašparević',
  'Gašparić',
  'GašparićMeter',
  'Gašparlin',
  'Gašparović',
  'Gašpert',
  'Gašpić',
  'GašpićVrtarić',
  'Gatara',
  'Gati',
  'Gaube',
  'Gaukler',
  'Gavenda',
  'Gavić',
  'Gavlek',
  'Gavlik',
  'Gavljak',
  'Gavra',
  'Gavran',
  'Gavranić',
  'Gavranović',
  'Gavranović Vilić',
  'Gavrić',
  'Gavrilović',
  'Gavrovski',
  'Gazdag',
  'Gazdek',
  'Gazdić',
  'Gazdik',
  'Gazdik Ivančić',
  'Gazibara',
  'Gazibarić',
  'Gazić',
  'Gazilj',
  'Gaža',
  'Gažić',
  'Gažo',
  'Gebaj',
  'Gebhardt',
  'Gecan',
  'Gegenbauer',
  'Geiersbach',
  'Gelemanović',
  'Genda',
  'Generalić',
  'GeneralićČok',
  'Genjega',
  'Gerard',
  'Gerard Špišić',
  'Gerasimenko',
  'Gerbač',
  'Gerencser Đelatović',
  'Gerendaj',
  'Gerhard',
  'Gerhardt',
  'Gernhardt',
  'Gerovac',
  'Gerovac Vuković',
  'Geršak',
  'Gerštmajer',
  'Gerštmajer Zelember',
  'Getoš Magdić',
  'Getto Šmit Strapač',
  'Giba',
  'Giber Janša',
  'Gibičar',
  'Gidija',
  'Gilman',
  'Giljanović',
  'Giordani',
  'Gispert Nicolau',
  'Gita',
  'Gitta',
  'Gizešan',
  'Gjaić',
  'Gjalić',
  'Gjelatović',
  'Gjuračić',
  'Gjurakić',
  'Gjurgjan',
  'Gjurinovac',
  'Glad',
  'Gladić',
  'Gladoić',
  'Gladović',
  'Glamočić',
  'Glamuzina',
  'Glas',
  'Glas Puškadija',
  'Glasenhardt',
  'Glaser',
  'Glasnović',
  'Glasovac',
  'Glaurdić',
  'Glavač',
  'Glavačević',
  'Glavačić',
  'Glavačko',
  'Glavak',
  'Glavan',
  'Glavaš',
  'GlavašKonja',
  'GlavašKul',
  'GlavašLilion',
  'GlavašLukić',
  'GlavašNikolić',
  'GlavašObrovac',
  'Glavašević',
  'Glavašić',
  'Glavaški',
  'Glavati',
  'Glavica',
  'Glavić',
  'Glavina',
  'Glavinac',
  'Glavinić',
  'Glavočević',
  'Glavota',
  'Glavurdić',
  'Glazer',
  'Glibanović',
  'Glibanski',
  'Glibota',
  'Gliesmann',
  'Gliša',
  'Glištra',
  'Glogač',
  'Glova',
  'Gluaković',
  'Glück Krstanović',
  'Glück Radičević',
  'Gluhak',
  'Gluhaković',
  'Gluhalić',
  'Glumac',
  'Glumpak',
  'Glušac',
  'Gluvak',
  'Gluvaković',
  'Gljiva',
  'Gljivica',
  'Gmajnić',
  'Gmaz',
  'Gnand Mijatović',
  'Gnant',
  'Gnjaić',
  'Gnjajić',
  'Gnjatović',
  'Gočal',
  'Goda',
  'Godan',
  'Godanj',
  'Godar',
  'Gogojak',
  'Gogolak',
  'Gogoljak',
  'Gogoljak Kesić',
  'Gojaković',
  'Gojtan',
  'Golemac',
  'GolićRonta',
  'GolićSamaržija',
  'Golinac',
  'Goll Barić',
  'Golub Kostolanović',
  'Golubar',
  'Golubovac',
  'Goluska',
  'Goluška',
  'Goluža',
  'Goluža Gregačević',
  'Goljak',
  'Goljevački',
  'Goman',
  'Gombai',
  'Gombaj',
  'Gombar',
  'Gombarović',
  'Gombay',
  'Gongeta',
  'Gorela',
  'Gorenac',
  'Gorenjak',
  'Gorera',
  'Goreta',
  'Gorgioska',
  'Goričanec',
  'Gorjan',
  'Gorjanac',
  'Gorjanac Mitoš',
  'Gorjanc',
  'Gorjanski',
  'Goronja',
  'Gorša',
  'Goršak',
  'Goršćak',
  'Goruševska',
  'Gostić Manojlović',
  'Gošak',
  'Gotal',
  'Gotovac',
  'Gotvald',
  'Gounaris Sučić',
  'Govedarica',
  'Grabar',
  'Grabarić',
  'Grabež',
  'Grabić',
  'Grablović',
  'Grabovac',
  'Grabovački',
  'Grabovčić',
  'Grabović',
  'Grabrovec',
  'Grabrović',
  'GrabrovićBabić',
  'Grabundžija',
  'Grabus',
  'Grabusin',
  'Grac',
  'Gracek',
  'Gracel',
  'Gracer',
  'Gracin',
  'Gračak',
  'Gračan',
  'Gračanić',
  'Gračanin',
  'GradŠtanac',
  'Gradaščević',
  'Gradečak',
  'Gradečak Erdeljić',
  'Gradeček',
  'Gradička',
  'Gradić',
  'Gradinščak',
  'Gradinjan',
  'Gradišar',
  'Gradištanac',
  'Gradvol',
  'Gradvol Krmpotić',
  'Graf',
  'Grafina',
  'Grah',
  'Grahovac',
  'Grahovac Bister',
  'Grahovac Cvenić',
  'Grajner',
  'Gramec',
  'Granat',
  'Granc',
  'Grančić',
  'Grandić',
  'Grandl',
  'Granić',
  'Granoša',
  'Graora',
  'Graovac',
  'Graša',
  'Grašar',
  'Grašarević',
  'Grašić',
  'Grašo',
  'Gratz',
  'Grba',
  'Grbac',
  'Grbac Budin',
  'Grbačić',
  'Grbavac',
  'Grbavica',
  'Grbeša',
  'Grbeša Petrinšak',
  'Grđan',
  'Grebenar',
  'GrebenarŽarmen',
  'Gregačević',
  'Greganić',
  'Greganić Ternaj',
  'Greganović',
  'Gregić Njirjak',
  'Gregorac',
  'Gregoran',
  'Gregoraš',
  'Gregorka',
  'Gregoršanec',
  'Greguraš',
  'Greif Pađen',
  'Grejza',
  'Grekša',
  'Greman',
  'Grgac',
  'Grgačević',
  'Grgan',
  'Grganić',
  'Grganović',
  'Grgas',
  'Grgat',
  'Grgesina',
  'GrgićBabić',
  'GrgićBošnjak',
  'GrgićĐaković',
  'GrgićIvanković',
  'GrgićMarinov',
  'GrgićRajič',
  'GrgićTadić',
  'Grginac',
  'Griesbacher',
  'Grinvald Greganić',
  'Grisbaher',
  'Griva',
  'Grizak',
  'Grizbaher',
  'Grizbaher Subašić',
  'GrizeljŠarčević',
  'Grladinović',
  'Grlica',
  'GrljušićKrampač',
  'Grmoja',
  'Grmuša',
  'Grnja',
  'Grokša',
  'Groman',
  'Grošeta',
  'Grozaj',
  'Grozdanić',
  'Grozdanović',
  'Groznica',
  'Grubač',
  'Grubačević',
  'Grubanović',
  'Grubeša',
  'GrubešićBarbir',
  'Grubina',
  'Grubiša',
  'GrubišićMarković',
  'GrujićTomas',
  'Grunbaum',
  'Grünthaler',
  'Grunwald',
  'Grünwald',
  'Gržan',
  'Guba',
  'Gubeljak',
  'Guberac',
  'Guberina',
  'Gubiani',
  'Gubica',
  'Gubina',
  'Gucunja',
  'Gudinjacki',
  'Guja',
  'Gujaš',
  'Gulam',
  'Gulam Kramarić',
  'Gulaš',
  'Guldan',
  'Gulija',
  'Guljaš',
  'Guljašević',
  'Gumbarević',
  'Gumbas',
  'Guncinam',
  'Gunjača',
  'Gusak',
  'Gushani',
  'Gušani',
  'Gutal',
  'Gutrovac',
  'Guzina',
  'Gužvanj',
  'Gužvinac',
  'Gvajdek',
  'Gvozdanić',
  'Gvozdanović',
  'Gyana',
  'Gyerdak',
  'Gyetvaj',
  'Haas',
  'Habacht Žitnjak',
  'Habada',
  'Haban',
  'Habčak',
  'Habdija',
  'Habek',
  'Habel',
  'Haber',
  'Haber Kiš',
  'Habi',
  'Habib',
  'Habibović',
  'Habicht',
  'Habijan',
  'Habijanec',
  'Habjan',
  'Habjanec',
  'Habjanić',
  'Habjanović',
  'Habl',
  'HabrkaŠimleša',
  'Habschied',
  'Habuda',
  'Habuda Sabo',
  'Habud tanić',
  'Habula',
  'Habulin',
  'Habulinec',
  'Habus',
  'Habuš',
  'HabušOršić',
  'Hac',
  'Hacek',
  'Hack',
  'Hackenberger',
  'Hackenberger Kosturović',
  'Hackenberger Kutuzović',
  'Haček',
  'Hadeljan',
  'Hader',
  'Hadler',
  'Hadrović',
  'Hadzima',
  'Hadži Sejdić',
  'Hadžiahmetović',
  'Hadžiavdić',
  'Hadžibeganović',
  'Hadžibegović',
  'Hadžić',
  'HadžićBošnjaković',
  'Hadžihajdarević',
  'Hadžihaskić',
  'Hadžisejdić',
  'Hađa',
  'Hađalić',
  'Hađikan',
  'Hafner',
  'Hager',
  'Haierbacher',
  'Haj Barakat',
  'Hajba',
  'Hajberger',
  'Hajc',
  'Hajcman',
  'Hajdarević',
  'Hajdari',
  'Hajdarović',
  'Hajden',
  'Hajdić',
  'Hajdin',
  'Hajdinger',
  'Hajdinjak',
  'Hajdu',
  'Hajduk',
  'Hajduković',
  'Hajdžanović',
  'Hajec',
  'Hajek',
  'Hajić',
  'Hajling',
  'Hajmiler',
  'Hajnal',
  'Hajnc',
  'Hajncl',
  'Hajne',
  'Hajnić',
  'Hajoš',
  'Hajpek',
  'Hajrić',
  'Hajrović',
  'Hajsek',
  'Hajtfogl',
  'Hajtl',
  'Hajzelman',
  'Hak',
  'Hakenberger',
  'Halači',
  'Halak',
  'Halapa',
  'Halapir',
  'Halar',
  'Halas',
  'Halasz',
  'Halaši',
  'Halauš',
  'Halavanja',
  'Halavuk',
  'Halbauer',
  'Halec',
  'Haler',
  'Halilović',
  'Haliti',
  'Halitović',
  'Haller',
  'Halt',
  'Halter',
  'Halter Dumančić',
  'Haludek',
  'Halupka',
  'Halusek',
  'Halužan',
  'Halvaks',
  'Ham',
  'Ham Tintor',
  'Hamacher',
  'Haman',
  'Hamarić',
  'Hamaš',
  'Hamberger',
  'Hamdan',
  'Hamer',
  'Hamidi',
  'Hamidović',
  'Hamilton',
  'Hamiti',
  'Hamm',
  'Hampovčan',
  'Hampshire',
  'Hamunajec',
  'Hamzić',
  'Han',
  'Han Pilko',
  'Hanaček',
  'Hanaftaler',
  'Hanak',
  'Hanc',
  'Hancock',
  'Handanović',
  'Handel Lešić',
  'Handraka',
  'Handžić',
  'Hanđal',
  'Haner',
  'Hange',
  'Hangel',
  'Hango',
  'Haničar',
  'Hanić',
  'Hanih',
  'Hanih Falamić',
  'Hanižar',
  'Hanižjar',
  'Hanižjar Berlančić',
  'Hanković',
  'Hanner',
  'Hannich',
  'Hansko',
  'Hanulak',
  'Hanuljak',
  'Hanzen',
  'Hanzer',
  'Hanzl',
  'Hanzlić',
  'Hanž',
  'Hanžek',
  'Hanžić',
  'Hap',
  'Harač',
  'Harambašić',
  'Haramija',
  'Haramina',
  'Haramustek',
  'Harangoza',
  'Harangozi',
  'Harangozo',
  'Harapin',
  'Harasti',
  'Harauzek',
  'Harc',
  'Harci',
  'Harcz',
  'Harča',
  'Hardi',
  'Hardt',
  'Hardy',
  'Harhaj',
  'Haring',
  'Harinski',
  'Harjač',
  'Harjung',
  'Harkanovac',
  'Harmath',
  'Harmina',
  'Harmunt',
  'Harnoš',
  'Haršanji',
  'Harth',
  'Hartl',
  'Hartman',
  'Hartmann',
  'Harun',
  'Harvanček',
  'Hary',
  'Has',
  'Has Schon',
  'Hasan',
  'Hasanac',
  'Hasanbašić',
  'Hasančević',
  'Hasanica',
  'Hasanović',
  'Hasenauer',
  'Hasenay',
  'Hasenbah',
  'Hasić',
  'Hasija',
  'Hasijak',
  'Haslah',
  'Hasnaš',
  'Hašai',
  'Hašček',
  'Hašimi',
  'Hašperger',
  'Hašpraj',
  'Hat',
  'Hata',
  'Hatlak',
  'Hatnik',
  'Hatta',
  'Hatvalić',
  'Hatwagner',
  'Haubrich',
  'Haubrih',
  'Haudek',
  'Hauer',
  'Haumüller',
  'Haupert',
  'Hauptman',
  'Hauselmann',
  'Hauser',
  'Hauska',
  'Hausknecht',
  'Hausman',
  'Hausmaninger',
  'Haussler',
  'Hauzer',
  'Havel',
  'Havelka',
  'Havić',
  'Havidić',
  'Havlek',
  'Havliček',
  'Havliček Babić',
  'Havoić',
  'Havoj',
  'Havojić',
  'Havranek',
  'Hazenauer',
  'Hazenštaub',
  'Hazler',
  'Haznadar',
  'Hazurović',
  'Hebrang',
  'HedlŠubarić',
  'Heđa',
  'Heganović',
  'Heged Kranjčević',
  'Heiman',
  'Heimann',
  'Heinzelman',
  'Heinzelman Kožul',
  'Heinzelmann',
  'Heitzman',
  'Heizelmann',
  'Heka',
  'Heka Marek',
  'Hekman',
  'Helajz',
  'Helebrant',
  'Hell Kurevija',
  'Hemar',
  'Heneka',
  'Henizelman',
  'Herak',
  'Heralić',
  'Herbay',
  'Hercegovac',
  'Hercigonja',
  'Herega',
  'Herjavec',
  'Herjavec Karalić',
  'Herkovac',
  'Herman',
  'Herman Patajac',
  'Hermanić',
  'Hermann',
  'Hermanović',
  'Hermanutz',
  'Hertarić',
  'Heška',
  'Hezajz',
  'Hidanović',
  'Hidošan',
  'Hidošanin',
  'Hihlik Babić',
  'Hilak',
  'Himelrajh',
  'Hinek Zagorščak',
  'Hipp Rausch',
  'Hirnštajn',
  'Hirštajn',
  'Hirštajn Maćo',
  'Hižman',
  'Hlaban',
  'Hlad',
  'Hladek',
  'Hladki',
  'Hlaj',
  'Hlapčić',
  'Hlapec',
  'Hlasni',
  'Hlatki',
  'Hlavač',
  'Hlavaček',
  'Hlavati',
  'Hlavsa',
  'Hlevnjak',
  'Hlubna',
  'Hmelik Bogdan',
  'Hmelina',
  'Hmeljina',
  'Hmura',
  'Hnačuk',
  'Hnatek',
  'Hnatešen',
  'Hočevar',
  'Hodak',
  'Hodak Ninković',
  'Hodan',
  'Hodanovac',
  'Hodanji',
  'Hodap',
  'Hodovanj',
  'Hoffman',
  'Hofmajer Marinković',
  'Hofman',
  'Hofšauer',
  'HohošBabić',
  'Hojsak',
  'Hokman',
  'Hoksa',
  'Holas',
  'Holenda',
  'HoličekŽanko',
  'Holiga',
  'Holjevac',
  'Homa',
  'Homan',
  'Homan Krtić',
  'Homanović',
  'Homolka',
  'Homotar',
  'Hompas',
  'Hora',
  'Hora Jakobčin',
  'Horaček',
  'Horački',
  'Horak',
  'Horjan',
  'Hornjak',
  'Horonitz Stanić',
  'Horvat',
  'Horvat Fabijan',
  'Horvat Hrvoj',
  'Horvat Katalin',
  'Horvat Smeška',
  'Horvatek',
  'Horvath',
  'Horvatiček',
  'Horvatić',
  'Horvatin',
  'Horvatinović',
  'HorvatinovićMalčić',
  'Horvatović',
  'HorvatovićKrstevski',
  'Hosak',
  'Hosi Tomac',
  'HosinovićGlavaški',
  'Hosman',
  'Hossain',
  'Houra',
  'Hovanček',
  'Hovanček Bura',
  'Hovanjec',
  'Hovanjek',
  'Hoymar',
  'Hozijan',
  'Hozjan',
  'Hoža',
  'Hrala',
  'Hranić',
  'Hranilović',
  'Hranueli',
  'Hranj',
  'Hranjec',
  'Hrastinski',
  'Hrastov',
  'Hrastović',
  'Hrašovec',
  'Hrbak',
  'Hrčak',
  'Hrčka',
  'Hrdelja',
  'Hrdlitschka',
  'Hrđavec',
  'Hrenovac',
  'HrešićDubaić',
  'Hrga',
  'Hrgota',
  'Hribar',
  'Hrkač',
  'Hrkać',
  'Hrkalović',
  'Hrnjak',
  'Hrnjkaš',
  'Hromatko',
  'Hrovat',
  'Hrpka',
  'Hrsan',
  'Hršak',
  'Hruba',
  'Hrubenja',
  'Hrupački',
  'Hrustanović',
  'Hruška',
  'Hruškar',
  'Hrvačić',
  'Hrvat',
  'Hrvatin',
  'Hrženjak',
  'Hržica',
  'Hržina',
  'Huba',
  'Hubak',
  'Hubalek',
  'Hucika',
  'Hudoletnjak',
  'Hufnagel',
  'Huhn Gašpar',
  'Huhoja',
  'Hujak',
  'Hulak',
  'Hulala',
  'Humbal',
  'Hunjadi',
  'Hunjak',
  'Hurban',
  'Husagh',
  'Husaina',
  'Husajina',
  'Husak',
  'Husaković',
  'HusakovićHodžić',
  'Husanović',
  'Husar',
  'Husarek',
  'Husnjak',
  'Huszar',
  'Hušnjak',
  'Huzanić',
  'Huzjak',
  'Hvalec',
  'Hvasta',
  'Hvastek',
  'Hvizdak',
  'Hyka',
  'Ibrahimbegović',
  'Ibrahimpašić',
  'Ibranović',
  'Idžanović',
  'Igalac',
  'Igali',
  'Igladić',
  'Ignac',
  'Ignjac',
  'Ignjačić',
  'Ignjatić',
  'Ignjatović',
  'IgnjatovićVračić',
  'Iharoš',
  'Iharoši',
  'Ihas',
  'Ihasz',
  'Ihaz',
  'Ikač',
  'Ikasović',
  'Ikervari',
  'Ikica',
  'Ilak',
  'Ilakovac',
  'Ilakovac Hercog',
  'Ilančić',
  'Ilanić',
  'IlešŠandor',
  'Ilibašić',
  'IlićKapelac',
  'IlićPočučak',
  'IlićPuharić',
  'IlićSolar',
  'Ilija',
  'Ilijaš',
  'Ilijašev',
  'Ilijašević',
  'Ilijavec',
  'Ilin Romanik',
  'Ilovača',
  'Iljazi',
  'Iljazović',
  'IljazovićIlias',
  'IljazovićLindinger',
  'Iljevac',
  'Imamović',
  'Inotai',
  'Ipša',
  'Iraček',
  'Irha',
  'Isabegović',
  'Isajlović',
  'Isaković',
  'Isanović',
  'Ismaili',
  'Ismailji',
  'Išasegi',
  'Išpanov',
  'Išpanović',
  'Išpiranović',
  'Ištoka',
  'Ištvan',
  'Ištvanfi',
  'Ištvanić',
  'Ištvanović',
  'Ivaci',
  'Ivačić',
  'Ivakić',
  'Ivaković',
  'Ivan',
  'Ivanac',
  'Ivanagić',
  'Ivančan',
  'Ivančević',
  'Ivančić',
  'Ivančik',
  'Ivančok',
  'Ivanćević',
  'Ivanda',
  'Ivandić',
  'Ivandija',
  'Ivanek',
  'Ivanetić',
  'Ivanfi',
  'Ivanić',
  'Ivaniš',
  'Ivanišević',
  'Ivanišić',
  'Ivanko',
  'Ivankov',
  'Ivanković',
  'IvankovićBon',
  'IvankovićGrgić',
  'IvankovićStanojević',
  'Ivanov',
  'Ivanova',
  'Ivanovac',
  'Ivanović',
  'IvanovićIžaković',
  'IvanovićPugin',
  'Ivanovski',
  'Ivanšić',
  'Ivanuš',
  'Ivanušec',
  'Ivanušić',
  'Ivanjko',
  'Ivas',
  'Ivaščenko',
  'Ivašković',
  'Ivatin',
  'Ivica',
  'IvićLončar',
  'Ivkovac',
  'IvkovićCickaj',
  'IvkovićPavić',
  'IvkovićSvetina',
  'Ivoševac',
  'Ivšak',
  'Ivšan',
  'IvšićŠlezak',
  'Izairi',
  'Iža',
  'Ižak',
  'Ižaković',
  'IžakovićArtuković',
  'Jabuka',
  'Jacek',
  'Jackanić',
  'Jacoby',
  'Jačka',
  'Jaćimović',
  'Jadadić',
  'Jadan',
  'Jadek',
  'Jadić',
  'Jadrešin',
  'Jadro',
  'Jaganjac',
  'Jagar',
  'Jagarčec',
  'Jagarić',
  'Jagarinec',
  'Jagatić',
  'Jager',
  'Jagetić',
  'Jagica',
  'Jagić',
  'Jagnjić',
  'Jagodić',
  'Jagodin',
  'Jagorinac',
  'Jagrić',
  'Jagunić',
  'Jagustin',
  'Jagušić',
  'Jahaj',
  'Jahić',
  'Jahn',
  'Jajalo',
  'Jajčan',
  'Jajčanin',
  'Jajčinović',
  'Jajetić',
  'Jajić',
  'Jajiš',
  'Jakab',
  'Jakabfi',
  'Jakarić',
  'Jakelić',
  'JakelićŠklebek',
  'Jakeljić',
  'Jakešević',
  'Jakić',
  'Jakim',
  'Jakimov',
  'Jakišić',
  'Jaklić',
  'Jaklin',
  'Jakljević',
  'Jako',
  'Jakob',
  'Jakobčin',
  'Jakobek',
  'Jakobfi',
  'Jakobi',
  'Jakobljević',
  'Jakobovac',
  'Jakobović',
  'Jakofi',
  'Jakolić',
  'Jakopanec',
  'Jakopčić',
  'Jakopec',
  'Jakopiček',
  'Jakopić',
  'Jakoplić',
  'Jakopović',
  'Jakoša',
  'Jakovac',
  'Jakovčević',
  'Jakovčić',
  'Jakovetić',
  'Jakovina',
  'Jakovljević',
  'JakovljevićČrep',
  'Jakovović',
  'Jaksić',
  'Jakša',
  'Jakšetić',
  'Jakšić',
  'JakšićBanjac',
  'JakšićMaček',
  'Jakubec',
  'Jakubovski',
  'Jakulj',
  'Jakumetović',
  'Jakupec',
  'Jakus',
  'Jakuš',
  'JakušMejarec',
  'Jalovičar',
  'Jalšovec',
  'Jalšovečki',
  'Jalžabetić',
  'Jalžbetić',
  'Jalžetić',
  'Jaman',
  'Jaman Barišić',
  'Jaman Galeković',
  'Jambor',
  'Jambrec',
  'Jambrečina',
  'Jambrek',
  'Jambreković',
  'Jambrešić',
  'Jambrošić',
  'Jambrović',
  'Jambrušić',
  'Jamičić',
  'Jamić',
  'Jamnić',
  'Jamuljak',
  'Jan',
  'Janas',
  'Janc',
  'Jancer',
  'Jančarević',
  'Jančec',
  'Janči',
  'Jančić',
  'Jančiga',
  'Jančiga Jakšić',
  'Jančijev',
  'Jančik',
  'Jančikić',
  'Jančo',
  'Jančula',
  'Jančuljak',
  'Janda',
  'Jandračić',
  'Jandrejčić',
  'Jandrić',
  'Jandrijević',
  'Jandrlić',
  'Jandrok',
  'Jandrok Nadj',
  'JandrokŠkugor',
  'Janeček Legradić',
  'Janečić',
  'Janega',
  'Janeković',
  'Janes',
  'Janeska',
  'Janeski',
  'Janeš',
  'JanešTabain',
  'Janevski',
  'Janger',
  'Jani',
  'Janičar',
  'Janiček',
  'Janičijević',
  'Janić',
  'Janićijević',
  'Janka',
  'JankićRalić',
  'Janković',
  'JankovićBešlić',
  'JankovićŠlang',
  'JankovićVidović',
  'Jankovitch',
  'Jankucić',
  'Jano',
  'Janoš',
  'Janošević',
  'JanoševićFranz',
  'Janoši',
  'Janoši Malčić',
  'JanošiŠariri',
  'Janoška',
  'Janović',
  'Janson',
  'Janša',
  'Jantolek Jakšić',
  'Jantoš',
  'Jantošek',
  'Jantošik',
  'Jantula',
  'Janus',
  'Januš',
  'Januško',
  'Januzović',
  'Janz',
  'Janžek',
  'Janžić',
  'Janjac',
  'Janjanin',
  'Janjatović',
  'Janješić',
  'Janjiček',
  'Janjić',
  'JanjićRaiz',
  'Janjiš',
  'Janjošić',
  'Janjušević',
  'Janjušić',
  'Japarić',
  'Japec',
  'Japić',
  'Japunčić',
  'Japundža',
  'Japundžić',
  'Japunđa',
  'Jarabek',
  'Jaramaz',
  'Jarčević',
  'Jardin',
  'Jarebić',
  'Jarić',
  'Jarmek',
  'Jarnec',
  'Jarnević',
  'Jarnjević',
  'Jaroš',
  'Jasenica',
  'Jasenković',
  'Jasenovčanin',
  'Jasenović',
  'JasenovićUtassy',
  'Jasić',
  'Jasnić',
  'Jaša',
  'Jaščur',
  'Jašek',
  'Jašić',
  'Jašinski',
  'Jauch',
  'Jauernig',
  'Jauh',
  'Jauk',
  'Jauševac',
  'Javor',
  'Javor Akik',
  'Javorček',
  'Javorina',
  'Javorović',
  'Jazavac',
  'Jazbec',
  'Jazbinšek',
  'Jazić',
  'JazićDafish',
  'JazićVincer',
  'Jazvac',
  'Jazvanac',
  'Jazvić',
  'Jazvin',
  'Jazvinšak',
  'Jazvo',
  'Jažić',
  'Jedinak',
  'Jedlička',
  'Jednašić',
  'Jedvaj',
  'Jedvajić',
  'Jeger Semialjac',
  'Jelača',
  'Jelačić',
  'Jelaković',
  'Jelaska',
  'Jelaš',
  'Jelavić',
  'Jelečanin',
  'JelenićNikićAccardi',
  'Jelica',
  'JelićBergman',
  'Jelisavac',
  'Jelovac',
  'Jelovina',
  'Jembrišak',
  'Jenkač',
  'Jerab',
  'Jerabek',
  'Jerant',
  'Jeremijaš',
  'Jerman',
  'Jermatski',
  'Jeuša',
  'Jezeraškić',
  'Ježovita',
  'Jina',
  'Jindra',
  'JindraČupić',
  'Johan',
  'Johanović',
  'Joka',
  'Jokanović',
  'Jona',
  'Jonaš',
  'Jordanova',
  'Jordanović',
  'JosićKajzerić',
  'JosipovićPongrac',
  'Jostiak',
  'Jošavac',
  'Jotanović',
  'Jovan',
  'Jovančević',
  'Jovandić',
  'Jovandžikov',
  'Jovandžikova',
  'Jovanić',
  'Jovanovac',
  'Jovanović',
  'JovanovićFiličić',
  'JovanovićŽuža',
  'Jovanovska',
  'Jovanovski',
  'Jovanovski Vlaškalić',
  'Jovašević',
  'Jovičić Šovagović',
  'Jovinac',
  'Jozanović',
  'Jozefčak',
  'Jozepović Topalović',
  'Jozipović Sudar',
  'Jugovac',
  'Juhas',
  'Juhas Koler',
  'Juhas Takač',
  'Juhasz',
  'Juhaz',
  'Juka',
  'Jukica',
  'Jukopila',
  'Jularić',
  'JularićLadocki',
  'Junačko',
  'Junaković',
  'Jung Lončarić',
  'Juračić',
  'Juraić',
  'Jurajić',
  'Jurak',
  'Jurakić',
  'Juran',
  'Juranek',
  'Juranić',
  'Juras',
  'Jurasović',
  'Jurašek',
  'Jurašković',
  'Juratovac',
  'Juratović',
  'Jurca',
  'JurčevićTrbara',
  'JurčićGalović',
  'Jurela',
  'Jurenac',
  'Jureša',
  'Jureta',
  'Jureta Hrganić',
  'Juribašić',
  'Juričan',
  'JuričanŠimunek',
  'JurićKuna',
  'JurićMihačić',
  'JurićNazifović',
  'Jurićan',
  'Jurina',
  'Jurinjak',
  'Juriša',
  'Jurišaga',
  'Jurišak',
  'JurišićSpajić',
  'Jurlina',
  'Jurman',
  'Jurmanović',
  'Jutriša',
  'Juvančić',
  'Juzbaša',
  'Kabashaj',
  'Kabaš',
  'Kabašaj',
  'Kabić',
  'Kablarević',
  'Kacenberg',
  'Kacenberger',
  'Kaceski',
  'Kačar',
  'Kačarik',
  'Kačavenda',
  'Kači',
  'Kačić',
  'Kačunić',
  'Kačunko',
  'Kačunko Tomka',
  'Kaćar',
  'Kaćavenda',
  'Kaćeri',
  'Kaćunić',
  'Kaćunko',
  'Kadar',
  'Kadežabek',
  'Kadić',
  'Kadlec',
  'Kadlec Filipčić',
  'Kadleček',
  'Kadnar',
  'Kadoić',
  'Kadojić',
  'Kadović',
  'Kadri',
  'Kadrić',
  'Kafedžić',
  'Kafon',
  'Kahn',
  'Kahrić',
  'Kaič',
  'Kaikčija',
  'Kailer',
  'Kainz',
  'Kajan',
  'Kajari',
  'Kajba',
  'Kajčevski',
  'Kajčić',
  'Kajević',
  'Kajfeš',
  'Kajgana',
  'Kajganić',
  'Kajganović',
  'Kajgo',
  'Kajičić',
  'Kajić',
  'Kajinić',
  'Kajla',
  'Kajmić',
  'Kajpl',
  'Kajtar',
  'Kajtaz',
  'Kajtazi',
  'Kajunić',
  'Kajušić',
  'Kajzer',
  'Kajzerić',
  'Kakalicis',
  'Kakarigi',
  'Kakaš',
  'Kaksa',
  'Kakuk',
  'Kalaba',
  'Kalabić',
  'Kalac',
  'Kaladić',
  'Kalafatić',
  'Kalajdžić',
  'Kalajdžija',
  'Kalajžić',
  'Kalaković',
  'Kalamari',
  'Kalamiza',
  'Kalamut',
  'Kalanj',
  'Kalanjoš',
  'Kalapoš',
  'Kalauz',
  'Kalazić',
  'KalazićPeić',
  'Kalčev',
  'Kalčić',
  'Kalea',
  'Kaleb',
  'Kalem',
  'Kalember',
  'Kalenić',
  'Kalenik',
  'Kalesić',
  'Kalić',
  'Kaliger',
  'Kalinić',
  'Kalinka',
  'Kalinović',
  'Kalinski',
  'Kalivoda',
  'Kalizan',
  'Kalkan',
  'Kalkan Bogdanović',
  'Kalman',
  'Kalmar',
  'Kaloci',
  'Kaloper',
  'Kalozi',
  'Kalozy',
  'Kalpić',
  'Kalthoff',
  'Kaltnecker',
  'Kaltneker',
  'Kaluđer',
  'Kaluđerović',
  'Kaluđerski',
  'Kalvarešin',
  'Kamba',
  'Kamber',
  'Kamberović',
  'Kamenar',
  'Kamenarova',
  'Kamenicki',
  'Kamenički',
  'Kamenić',
  'KamenićSmrekar',
  'Kamenko',
  'Kamenov',
  'Kamerer',
  'Kaminski',
  'Kammerer',
  'Kamp',
  'Kampić',
  'Kan',
  'Kanaet',
  'Kanajet',
  'Kandecki',
  'Kandić',
  'KandićSplavski',
  'Kandrač',
  'Kandrać',
  'Kanđera',
  'Kanić',
  'Kanis',
  'Kanisek',
  'Kaniški',
  'Kaniža',
  'Kanižai',
  'Kanižaj',
  'Kanski',
  'Kantar',
  'Kantolić',
  'Kantor',
  'Kantor Fabulić',
  'Kantor Hagen',
  'Kanurić',
  'Kanjo',
  'Kap',
  'Kapelac',
  'Kapetan',
  'Kapetančić',
  'Kapetanović',
  'Kapeter',
  'Kapetinić',
  'Kapić',
  'Kapidžić',
  'Kapisoda',
  'Kapitanić',
  'Kapitanović',
  'Kapitanj',
  'Kaplan',
  'Kaplun',
  'Kapoči',
  'Kapor',
  'Kaposta',
  'Kapoši',
  'Kapović',
  'Kappelar',
  'Kapraljević',
  'Kaproncai',
  'Kapronczai',
  'Kapša',
  'Kaptalan',
  'Kaptein',
  'Kaptolić',
  'Kapular',
  'Kapulica',
  'Kapun',
  'Kapusta',
  'Kapuši',
  'Kapušin',
  'Karabegović',
  'Karabojev',
  'Karač',
  'Karača',
  'Karačić',
  'Karačić Šoljić',
  'Karačonji',
  'Karadakova',
  'Karadamis',
  'Karadamis Kir',
  'Karadža',
  'Karadžić',
  'Karaga',
  'Karagača',
  'Karagić',
  'Karaica',
  'Karaj',
  'Karajčić',
  'Karajko',
  'Karajkov',
  'Karajlović',
  'Karak',
  'Karakaš',
  'KarakašPavić',
  'Karalić',
  'Karaman',
  'Karamanović',
  'Karamarko',
  'Karamarko Radić',
  'Karamarković',
  'Karamatić',
  'Karamatraković',
  'Karan',
  'Karančin',
  'Karanov',
  'Karanović',
  'Karapandža',
  'Karapetrić',
  'KarapetrićDiklić',
  'Karas',
  'Karasek',
  'Karasi',
  'Karaševac',
  'Karatović',
  'Karaturović',
  'Karaula',
  'Karavidović',
  'Karavla',
  'Karavukovac',
  'Kardaš',
  'Kardinal',
  'Kardo',
  'Kardoš',
  'Kardov',
  'Kardum',
  'Karežić',
  'Karh',
  'Karić',
  'Karika',
  'Karin',
  'Karjaković',
  'Karkić',
  'Karl',
  'Karl Fero',
  'Karlak',
  'Karlak Mahovlić',
  'Karlica',
  'Karliček',
  'Karlić',
  'Karlinski',
  'Karloci',
  'Karlović',
  'Karlušić',
  'Karnaš',
  'Karner',
  'Karnoš',
  'Karoglan',
  'Karol',
  'Karoli',
  'Karpati',
  'Karpov',
  'Karša',
  'Karšaj',
  'Karšić',
  'Kartelo',
  'Kary',
  'Karzalko',
  'Kasa',
  'Kasabašić',
  'Kasač',
  'Kasak',
  'Kasal',
  'Kasalo',
  'Kasalo Tomić',
  'Kasalo Trajković',
  'Kasami',
  'Kasana',
  'Kasap',
  'Kasapović',
  'Kasavica',
  'Kaselj',
  'Kaselj Varga',
  'Kaser',
  'Kassa',
  'Kassak',
  'Kasser',
  'Kast',
  'Kastel',
  'Kastel Oravec',
  'Kastner',
  'Kastori',
  'Kastratović',
  'Kasum',
  'Kasumović',
  'Kasun',
  'Kasunić',
  'Kašanin',
  'Kašerić',
  'Kašić',
  'Kašnik',
  'Kaštel',
  'Kaštelan',
  'Kašuba',
  'Kata',
  'KataĐaković',
  'Kataj',
  'Katalenac',
  'Katalenac Pirghaye',
  'Katalenić',
  'Katalin',
  'Katalinić',
  'Katana',
  'Katanac',
  'Katančić',
  'Katanica',
  'Katanić',
  'KatanićPopović',
  'Katarin',
  'Katarina',
  'Katarinček',
  'Katarinić',
  'Katava',
  'Katavić',
  'KatavićIvandić',
  'KatavićŠimunić',
  'Katičić',
  'Katić',
  'Katilović',
  'Katinić',
  'Katnić',
  'Katoi',
  'Katona',
  'Katović',
  'Katruša',
  'Katruša Neziraj',
  'Katulić',
  'Katura',
  'Katušić',
  'Katušin',
  'Kaucki',
  'Kaucki Kelemen',
  'Kaučić',
  'Kaurić',
  'Kaurin',
  'Kaurinović',
  'Kaurloto',
  'Kauzlarić',
  'Kavain',
  'Kavaj',
  'Kavali',
  'Kavan',
  'Kavčić',
  'Kavedžić',
  'Kavedžija',
  'Kavelj',
  'Kavgić',
  'Kavicki',
  'Kavran',
  'Kavur',
  'Kazalicki',
  'Kazda',
  'Kazenski',
  'Kazimić',
  'Kazimir',
  'Kazimur',
  'Kaznovac',
  'Kažimir',
  'Keceli Mesaroš',
  'Kecman',
  'Keča',
  'Kečalović',
  'Kedačić',
  'Kefelja',
  'Kegalj',
  'Kekanović',
  'Kela',
  'Kelam',
  'Kelava',
  'Kelbas',
  'Kelbasa',
  'Kelbassa',
  'Kelebarda',
  'Kelebuda',
  'Kelemović Kramer',
  'Kelka',
  'Kelrajter',
  'Kemfelja',
  'Kenda',
  'Kenfelj Čajkovac',
  'Kenfelja',
  'Kenjvašo',
  'Keran',
  'Keranović',
  'Kereši Bennani',
  'Kereta',
  'Kerhač',
  'Kerhat',
  'Kern Martinović',
  'Kerovac',
  'Kervarić',
  'Kesak Ursić',
  'Kešerac',
  'Kešina',
  'Keža',
  'Khail Perasić',
  'Khaznadar',
  'Kichaz',
  'Kickaz',
  'Kihas',
  'Kijac',
  'Kijanica',
  'Kikanović',
  'Kilibarda',
  'Kilmajer',
  'Kinjerovac',
  'Kirac',
  'Kiraly',
  'Kiralj',
  'Kiralj Jarmek',
  'Kirasić',
  'Kirchbaumer',
  'Kirchmayer',
  'Kirčanski',
  'Kireta',
  'Kirhmajer',
  'KirićJunačko',
  'Kiridžija',
  'Kisela',
  'Kiselka',
  'Kiseljak',
  'KišKonja',
  'Kišasondi',
  'Kišćinal',
  'Kišfalubac',
  'Kišmartin',
  'Kišpal',
  'Kišpatić',
  'Kitak',
  'Kitak Borić',
  'Kitak Volar',
  'Kitanov',
  'Kitanović',
  'Kiternaš',
  'Kizivat',
  'Klaban',
  'Klačić',
  'Kladar',
  'Kladarić',
  'Kladarin',
  'Kladnik',
  'Klaiber',
  'Klaić',
  'Klajić',
  'Klajn',
  'Klajn Plavšić',
  'Klajo',
  'Klajzner',
  'Klak',
  'Klanac',
  'Klanac Gyorgy',
  'Klanac Kapša',
  'KlanacŠestić',
  'Klanfar',
  'Klanica',
  'Klanjšček',
  'Klapan',
  'Klapec',
  'Klapirić',
  'Klapka',
  'Klapšić',
  'Klaričić',
  'Klarić',
  'Klarić Kukuz',
  'Klasan',
  'Klasanović',
  'Klasanović Dulić',
  'Klasiček',
  'Klasiček Bencetić',
  'Klasić',
  'KlasićGazibarić',
  'Klasnetić',
  'KlasnetićHyka',
  'Klasović',
  'Klašnja',
  'Klaus',
  'Klausz',
  'Klauz',
  'Klauznicer',
  'Klečina',
  'Klekar',
  'Klepac',
  'Kliba',
  'Klika',
  'Klimczak',
  'Klipa',
  'Klipanović',
  'Klišanin',
  'Klišanin Bogović',
  'Klobučar',
  'Klobučar Radl',
  'Klobučar Vujnić',
  'Kloda',
  'Kloiber Rušanac',
  'Klopan',
  'Klopotan',
  'Kljaić',
  'Kljajić',
  'Klječanin',
  'Kljenak',
  'Ključanin',
  'Ključar',
  'Ključarić',
  'Ključarićek',
  'Knajs',
  'Knajz',
  'Knall',
  'Knapić',
  'Knatek',
  'Knauer',
  'Knauff',
  'KneževićBaić',
  'KneževićFranciška',
  'KneževićGado',
  'KneževićMarinić',
  'KnežićGalić',
  'Kniha',
  'Knjaz',
  'Kobaš',
  'KobašNola',
  'Kobašević',
  'Kobela',
  'Kobeščak',
  'Kobia',
  'Kobija',
  'Kobijak',
  'Kobjak',
  'Koceva',
  'Koceva Komlenić',
  'Koch Mađarić',
  'Kocian',
  'Kocifaj',
  'Kocijan',
  'Kocijančić',
  'Kocijanović',
  'Kockar',
  'Kocman',
  'Kocmanić',
  'KocmanićParavac',
  'Kočaj',
  'Kočar',
  'Kočevar',
  'Kodba',
  'Kodila',
  'Kodrnja',
  'Kodvanj',
  'Kodžoman',
  'Kofranek',
  'Kogan',
  'Kohajda',
  'Kohari',
  'Koharić',
  'Kojadinović',
  'Kojanić',
  'Kojundžija',
  'Kokalović',
  'Kokanović',
  'Kokanović Bartola',
  'Kokar',
  'Kokeza',
  'Kokman',
  'Kokolari',
  'Kokoška',
  'Kola',
  'Kola Farkaš',
  'Kolac',
  'Kolaček',
  'Kolačko',
  'Kolak',
  'KolakĐurinović',
  'Kolaković',
  'Kolar',
  'Kolar Drvoderić',
  'KolarŽižanović',
  'Kolarac',
  'Kolarec',
  'Kolarec Marušić',
  'Kolarević',
  'KolarevićTrišler',
  'Kolarić',
  'KolarićBuconjić',
  'KolarićVukotić',
  'Kolarik',
  'Kolat',
  'Kolauti',
  'Kolbas',
  'Koldjeraj',
  'Koldjeraj Pjetar',
  'Kolđeraj',
  'Kolegjeraj',
  'Kolembus Oklobdžija',
  'Kolenda',
  'Kolendarić',
  'Kolesar',
  'Kolesarić',
  'Koleszar',
  'Koletar',
  'Kolgjeraj',
  'Kolhamer',
  'KolhasČutura',
  'KolićKovačić',
  'Kollar',
  'Kolman',
  'Kolobara',
  'Kolobarić',
  'Koloda',
  'Kolodvorska',
  'Kolojeraj Pjetar',
  'Kolomaz',
  'Kolompar',
  'Kolosar',
  'Kolovrat',
  'KolundžićRadić',
  'Kolundžija',
  'Kolutac',
  'Koljan',
  'Koljanin',
  'Koljdjeraj',
  'Koljđeraj',
  'Komadina',
  'Komak',
  'Komanović',
  'Komar',
  'Komar Međugorac',
  'Komarac',
  'Komarčevski',
  'Komarčić',
  'Komarić',
  'Komaromi',
  'Komaromy',
  'Komazec',
  'Komendanović',
  'Komesarović',
  'Komijat',
  'Komjetan',
  'Komlenac',
  'Komlenac Beloša',
  'Komlenac Uglješić',
  'KomnenićTuta',
  'Kompanijec',
  'Kompanović',
  'Kompesak',
  'Komušanac',
  'Končalović',
  'Končar',
  'Končarević',
  'Kondaš',
  'Kondža',
  'Kondža Staničić',
  'Konopka',
  'Konrad',
  'Konstantinović',
  'Konta',
  'Kontak',
  'Konti Jaman',
  'Kontra',
  'KontraČapo',
  'Konja',
  'Konjarević',
  'Konjarik',
  'Konjušak',
  'Kopačević',
  'Kopačin',
  'Kopanec',
  'Kopas',
  'Kopasić',
  'Kopčalić',
  'Kopecki Balašević',
  'Kopilaš',
  'Kopjar',
  'Kopljar',
  'Koppola',
  'Koprčina',
  'Koprivanac',
  'Koprivica',
  'Koprivnjak',
  'Korač',
  'Korać',
  'KoraćRedžepi',
  'Korade',
  'Korajac',
  'Koralija',
  'Koražija',
  'Korbar',
  'Korda',
  'Korica',
  'Korman',
  'Kormanjec',
  'Kormanjoš',
  'Kornjača',
  'Korodva',
  'Koronsovac',
  'Koroša',
  'Korotaj',
  'Korpak',
  'Korpar',
  'Korpaš',
  'Korponja',
  'Korša',
  'Koruga',
  'Korušac',
  'Kosalec',
  'Kosanović',
  'Kosina',
  'Kosorjanac',
  'Kosovac',
  'KosovićCrvenkova',
  'Kostadinović',
  'KostadinovićKelić',
  'Kostanjevac',
  'Kostanjevec',
  'Kostelac',
  'Kostenac',
  'Kostolanović',
  'Kostolanović Golub',
  'Kostova',
  'Koša',
  'Košak',
  'Košalin',
  'Košanski',
  'Košaroš',
  'Koščak',
  'Košćak',
  'Košćalek Glavak',
  'Koškan',
  'Koškanac',
  'Košta',
  'Košturjak',
  'Kotal',
  'Kotarac',
  'Kotarlić',
  'Kotarski',
  'Kotarščak',
  'Kotiza',
  'Kotolas',
  'Kotorac',
  'Kotrljanović',
  'Kotromanović',
  'Kotvas',
  'Kovacs',
  'Kovač',
  'Kovač Asan',
  'Kovač Grablović',
  'Kovač Ištvan',
  'Kovač Maratović',
  'Kovač Marton',
  'Kovač Retih',
  'Kovač Slepčević',
  'Kovač Svoboda',
  'Kovač Šilipetar',
  'Kovač Vajda',
  'Kovač Vestemar',
  'Kovač Zoltan',
  'Kovačec',
  'Kovaček',
  'Kovačev',
  'Kovačev Matijević',
  'Kovačević',
  'KovačevićArbanas',
  'KovačevićDoroghy',
  'KovačevićGradinščak',
  'KovačevićGrgić',
  'KovačevićLovač',
  'KovačevićLujić',
  'KovačevićSalaj',
  'KovačevićTomić',
  'KovačevićZec',
  'Kovačić',
  'KovačićFarkaš',
  'KovačićLovrinčević',
  'Kovačik',
  'Kovačko',
  'Kovalski',
  'Kovalj',
  'Kovanić',
  'Kovanov',
  'Kovanušić',
  'Kovar',
  'Kovats',
  'Kovbanovski',
  'Kovčalija',
  'Kovjanić',
  'KovjanićKreidl',
  'Kozar',
  'Kozarčanin',
  'Kozarević',
  'Kozari',
  'Kozary',
  'Kozbašić',
  'Kozina',
  'Kozjak',
  'Kozjan',
  'Kozlevčar',
  'Kozlica',
  'Kozma',
  'Kozmar',
  'Kozomara',
  'Kožan',
  'Kožar',
  'Kožarić',
  'Koželj Horvat',
  'Kožoman',
  'Krac',
  'Kracl',
  'Kračun',
  'Kračunov',
  'Kradija',
  'Kradijan',
  'Kraft',
  'Kragl',
  'Kragojević',
  'Kragujević',
  'Kragulj',
  'Kraguljac',
  'Krahovski',
  'Krahulec',
  'Kraiček',
  'Krainović',
  'Krajač',
  'Krajačić',
  'Krajcar',
  'Krajčak',
  'Krajček',
  'Krajčik',
  'Krajčinović',
  'Krajina',
  'Krajinović',
  'Krajišnik',
  'Krajlah',
  'Krajner',
  'Krajnović',
  'Krajpl',
  'Krajs',
  'Krajšić',
  'Krajter',
  'Krajtner',
  'Kraker',
  'Kral',
  'Kralik',
  'Kralovec',
  'Kralj',
  'Kralj Vasilj',
  'Kralja',
  'Kraljčak',
  'Kraljek',
  'Kraljevački',
  'Kraljević',
  'Kraljević Čolić',
  'Kraljević Seričević',
  'Kraljevski',
  'Kraljičak',
  'Kraljić',
  'Kraljik',
  'Kraljik Kovačik',
  'Kraljinović',
  'Kramar',
  'Kramarić',
  'KramarićTariba',
  'Kramer',
  'Kraml',
  'Krampač',
  'Krampelj',
  'Krancpiler',
  'Kranic',
  'Kranić',
  'Kranželić',
  'Kranjac',
  'Kranjc',
  'Kranjčec',
  'Kranjčev',
  'Kranjčev Lončar',
  'Kranjčević',
  'Kranjčić',
  'Kranjec',
  'Kranjski Belovljev',
  'Krapić',
  'Krasić',
  'Krasniči',
  'Krasnić',
  'Krasnići',
  'Krasnik',
  'Krasniqi',
  'Krasnodembsky',
  'Krasulja',
  'Kraš',
  'Krašnjak',
  'Kraštek',
  'Kratil',
  'Kratki',
  'Kratković',
  'Kratofil',
  'Kratzl',
  'Kraucider',
  'Kraus',
  'Kraus Lazić',
  'Krausz',
  'Krautsieder',
  'Krauz',
  'Kravat',
  'Kravec',
  'Kravoščanec',
  'Kravski',
  'Krbanja',
  'Krbavac',
  'Krčmar',
  'Krčmarek',
  'KrdoŠpanić',
  'Kreča',
  'Krečak',
  'Kreća',
  'Krejačić',
  'Kremenjaš',
  'Kremsher Babić',
  'Kresoja',
  'Kresonja',
  'Kreštan',
  'KretićMajer',
  'Krga',
  'Krga Božić',
  'Krha',
  'Krhač',
  'Kričančić',
  'Krička',
  'Krička Mitrović',
  'Krijak',
  'Krijan',
  'Krisbaher',
  'Kristan',
  'Kristman',
  'Kriška',
  'Krivaja',
  'Krivak',
  'Krivanek',
  'Krivanek Spomenka',
  'Krivda',
  'Krivohlavek',
  'Krivokapić',
  'Krivokuća',
  'Krivošija',
  'Krizman',
  'Krizmanić',
  'Krizmanić Zaharov',
  'Križaj',
  'Križan',
  'Križan Stanić',
  'Križanac',
  'Križanec',
  'Križanić',
  'Križanović',
  'Križevac',
  'Križman',
  'Križnjak',
  'Krka',
  'Krkač',
  'Krkalo',
  'Krleska',
  'Krleža',
  'Krljan',
  'Krljar',
  'Krmpota',
  'Krnatić',
  'Krndija',
  'Krneta',
  'Krnjac',
  'Krnjaić',
  'Krnjajić',
  'Krnjak',
  'Krnjaković',
  'Krnjeta',
  'Kroato Vujčić',
  'Krolo Stipan',
  'Krompač',
  'KrompačGolub',
  'Krowas',
  'Krpačić',
  'Krpan',
  'Krpan Trgovčević',
  'Krpata',
  'Krsmanović',
  'Krstanović',
  'KrstanovićIvšić',
  'KrstanovićPočuča',
  'Krsteska',
  'Kršak',
  'Kršan',
  'Kršćanski',
  'Kršinić Radaković',
  'Krtalić',
  'KrtićČejvan',
  'Kručaj',
  'Kruhonja',
  'Kruljac',
  'Krumenacher',
  'Krumerac',
  'Krupa',
  'Krupeša',
  'Krupeša Virag',
  'Krupljan',
  'Krupljanin',
  'Krušarovski',
  'Krvarić',
  'Krvavac',
  'Krvavica',
  'Krzman',
  'Krznar',
  'Krznarić',
  'Kšan',
  'Kuba',
  'Kubaček',
  'Kubala',
  'Kubanović',
  'Kubaša',
  'Kubat',
  'Kubatka',
  'Kubelka',
  'Kubena',
  'Kubica',
  'Kubijak',
  'Kubijan',
  'Kubinac',
  'Kubinčan',
  'Kubjak',
  'Kubula',
  'Kučak',
  'Kučan',
  'Kučančanin',
  'Kučanda',
  'Kučandi',
  'Kučans',
  'Kučar',
  'Kučara',
  'Kučera',
  'Kučica',
  'Kučina',
  'Kučinac',
  'Kučinac Lacković',
  'Kuća',
  'Kućan',
  'Kućančanin',
  'Kućanda',
  'Kućera',
  'Kućina',
  'Kućinac',
  'Kudeljaš',
  'Kudeljnjak',
  'Kudlaček',
  'Kudrna',
  'Kuduz Vrdoljak',
  'Kuhač',
  'Kuhar',
  'Kuharić',
  'KuharićBučević',
  'Kuharski',
  'Kuhta',
  'KuićVadlja',
  'Kujadin',
  'Kujavec',
  'Kujavec Šljivac',
  'Kujavić',
  'Kujundžić Jamnić',
  'Kujundžija',
  'Kukanović',
  'Kukavica',
  'Kukina',
  'Kukmanović',
  'Kukoleča',
  'Kukolj Malbaša',
  'Kukrika',
  'Kukučka',
  'Kukuljica',
  'Kukuružnjak',
  'Kula',
  'Kulačanin',
  'Kulaš',
  'KulašKnežević',
  'Kulašinović',
  'Kulbah',
  'Kulčar',
  'Kulešanin',
  'Kulhanek',
  'Kulhavi',
  'KulišićLončarić',
  'Kulovac',
  'Kuljaj',
  'Kuljak',
  'Kumarić',
  'Kumbara',
  'Kumbrija',
  'Kumparić',
  'Kuna',
  'Kunac',
  'Kuncinam',
  'Kunčak',
  'Kundak',
  'KunićBukovac',
  'Kunišinac',
  'Kunovac',
  'Kunsabo',
  'Kunzinam',
  'Kupanovac',
  'Kupčarić',
  'Kupčarić Trcović',
  'Kupčinovac',
  'Kupina',
  'Kuprešak',
  'Kuprešak Paret',
  'Kuprešanin',
  'Kupreščanin',
  'Kuragić',
  'Kuraja',
  'Kuralić',
  'Kuran',
  'Kuratko',
  'Kuravica',
  'Kurbalić',
  'Kurbalija',
  'Kurbanović',
  'Kurbaša',
  'Kurbatfinski',
  'Kurcinak',
  'Kurdija',
  'Kurelac Karlak',
  'Kurepa',
  'Kurevija',
  'Kuridža',
  'Kurija',
  'Kurikavčić',
  'Kurina',
  'Kurjačić',
  'Kurjakov',
  'Kurlagić',
  'Kurman',
  'Kuronja',
  'Kurpez Vranković',
  'Kursar',
  'Kurtagić',
  'Kurtak',
  'Kurtanjek',
  'Kuruc Sloboda',
  'Kusalić',
  'Kustura',
  'Kusulja',
  'Kusutra',
  'Kušaković',
  'Kušan',
  'Kušica',
  'KušićOmazić',
  'Kutaši',
  'Kuterovac',
  'Kutjevac',
  'Kutleša',
  'Kutlija',
  'Kutnaker',
  'Kutnjak',
  'Kuvač',
  'Kuvačić',
  'Kuveždan',
  'Kuveždanin',
  'Kuzma',
  'KuzmaČordašić',
  'Kuzman',
  'Kuzmančević',
  'Kuzmanić',
  'KuzmanićŠarac',
  'Kuzmanoski',
  'Kuzmanović',
  'KuzmanovićĐorđević',
  'Kuzmanovski',
  'Kužatko',
  'Kvaček',
  'Kvak',
  'Kvaka',
  'Kvakan',
  'Kvakić',
  'Kvas',
  'Kvasina',
  'Kvasnovski',
  'Kvastek',
  'Kvaternik',
  'Kvorka',
  'La Bruna',
  'Labaj',
  'Labak',
  'Laban',
  'Laban Ovčina',
  'Labant',
  'Labar',
  'Labaš',
  'Labavić',
  'Labazan',
  'Labo',
  'Labor',
  'Laboš',
  'Labrović',
  'Labrtić',
  'Labudić',
  'Labudović',
  'Labuhar',
  'Labus',
  'Labuš',
  'Lach',
  'Lachner',
  'Lacić',
  'Lacko',
  'Lacković',
  'Lacmanović',
  'Laco',
  'Lacović',
  'Laczo',
  'Lač',
  'Lačanin',
  'Lačić',
  'Lačman',
  'Ladan',
  'Ladenhauser',
  'Ladenhauser Palijan',
  'Ladić',
  'Ladislaides',
  'Ladišić',
  'Ladnjak',
  'Ladocki',
  'Ladović',
  'Lađarević',
  'Lađaš Špoljarec',
  'Lađević',
  'Laferton',
  'Laforza',
  'Lagančić',
  'Laganela',
  'Laganela Keglević',
  'Lagator',
  'Lagetar',
  'Lagumdžija',
  'Lagundžija',
  'Lah',
  'Lahman Bennani',
  'Lahner',
  'Laister',
  'Laitkam',
  'Lajčak',
  'Lajčo Paštrović',
  'Lajdes',
  'Lajdi',
  'Lajforman',
  'Lajić',
  'Lajk',
  'Lajko',
  'Lajoroš',
  'Lajoš',
  'Lajtkam',
  'Lajtkaum',
  'Lajtman',
  'Lajtner',
  'Lakat',
  'Lakatoš',
  'Laketa',
  'Laketić',
  'Laki',
  'Lakić',
  'Lakićević',
  'Laklija',
  'Lakner',
  'Lakota',
  'Lakotić',
  'Laković',
  'Laksar',
  'Laktić',
  'Laletić',
  'Lalić',
  'Lalić Đurišić',
  'Lalović',
  'Lambreščak',
  'Lamnek',
  'Lamot',
  'Lampek',
  'Lampert',
  'Lamza',
  'Lamza Maronić',
  'Lanc',
  'Lančić',
  'Landeka',
  'Landekić',
  'Landenbach',
  'Landher',
  'Landikušić',
  'Landup',
  'Lang',
  'LangČulin',
  'Lange',
  'Langer',
  'Lanko',
  'Lanjoha',
  'Lapac',
  'Lapanović',
  'Lapanje',
  'Lapaš',
  'Lapčević',
  'Lapčić',
  'Lapenda',
  'Lapić',
  'Lapis',
  'Lapiš',
  'Laptoš',
  'Lapu',
  'Larma',
  'Lasić',
  'Lasinger',
  'Lasker',
  'Lasković',
  'Laslavić',
  'Laslo',
  'Laslo Gregov',
  'Laslovski',
  'Lasović',
  'Lastavica',
  'Lastavica Pantelić',
  'Lastavić',
  'Lastelić',
  'Lastrić',
  'Laszlo',
  'Laszlo Szakary',
  'Lašak',
  'Laškai',
  'Laškarin',
  'Laštović',
  'Laštre',
  'Laštro',
  'Latas',
  'Latić',
  'Latin',
  'Latinčić',
  'Latinović',
  'Latinović Leleta',
  'Latković',
  'Latoš',
  'Lauber',
  'Lauc',
  'Lauc Đaniš',
  'Lauda',
  'Lauda Ljubas',
  'Laufer',
  'Launek',
  'Laurić',
  'Laurović',
  'Lauš',
  'Laušević',
  'Laušić',
  'Laušin',
  'Lautar',
  'Lavada',
  'Lavrek',
  'Lavrnić',
  'Lavrnja',
  'Lavrov',
  'Lay',
  'Lazanin',
  'Lazar',
  'Lazar Korov',
  'Lazarević',
  'LazarevićParadžik',
  'Lazarov',
  'Lazendić',
  'Lazič Švogor',
  'Lazić',
  'Lazić Gorjanski',
  'Lazić Švogor',
  'Lazović',
  'Lažeta',
  'Lebar',
  'Lebinac',
  'Ledenčan',
  'Ledenčan Kuštro',
  'Ledeničan',
  'Ledinščak',
  'Ledinšćak',
  'Leđenac',
  'Lefand',
  'Legat',
  'Legradi',
  'Lehanka',
  'Lehman',
  'Lehmann',
  'Lekaj',
  'Leko Varoščić',
  'Lekšan',
  'Lelas',
  'Lemaić',
  'Lemajić',
  'Lemal',
  'Leman',
  'Lenard',
  'Lenardić',
  'Lenardo',
  'Lenart',
  'Lenc Mandić',
  'Lendarić',
  'Lendvaj',
  'Lengajić',
  'Lengyel Komušanac',
  'Lenhard',
  'Lenhardt',
  'Lenhart',
  'Leovac',
  'Lepac',
  'Lepan',
  'Lepoglavec',
  'Lepoja',
  'Lepoša',
  'Leratić',
  'Lesar',
  'Lesica',
  'Lesičak',
  'Lesjak',
  'Leskovac',
  'Leskovar',
  'Lešćan',
  'Lešina',
  'Leškovar',
  'Lešnjak',
  'Lešnjaković',
  'Letica',
  'Leutar',
  'Leutarević',
  'Levačić',
  'Levak',
  'Levaković',
  'Levanić',
  'Levar',
  'Levnaić',
  'Ležaić',
  'Ležaja',
  'Lichtenthal',
  'Ličanin',
  'Ličar',
  'Ličina',
  'Lidmila',
  'Liermann',
  'Liermann Zeljak',
  'Ligda',
  'Ligenza',
  'Lihtental',
  'Lijak',
  'Likar',
  'Likarić',
  'Lilijan',
  'Linarić',
  'Lindeman',
  'Linta',
  'Lipanović',
  'Lipar',
  'Lipka',
  'Lipnicki Krausen',
  'Lipošćak',
  'Lipovac',
  'Lipovina',
  'Liptak',
  'Lisac',
  'Lisak',
  'Lisica',
  'Lisičak',
  'Lisjak',
  'Lisjak Tvrdorijeka',
  'Lisovac',
  'Liščak',
  'Lišćak',
  'Liška',
  'Litarac',
  'Litarić',
  'LitarićTešić',
  'Litobac',
  'Litra',
  'Litva',
  'Livada',
  'Livaja',
  'Livajić',
  'Livančić',
  'Livazović',
  'Livnjak',
  'Lizačić',
  'Lizačić Vidaković',
  'Lizak',
  'Lizatović',
  'Loboda',
  'Loci Zvocak',
  'Logara',
  'Logarušić',
  'Logožar',
  'Lohajner',
  'Loina',
  'Loinjak',
  'Lokas',
  'Loknar',
  'Lončar',
  'Lončar Kurtović',
  'Lončar Majer',
  'Lončar Tumir',
  'Lončar Vicković',
  'Lončarek',
  'Lončarević',
  'Lončarić',
  'Lončina',
  'Lonjak',
  'Lopar',
  'Loparac',
  'Lopatnik',
  'Lordan',
  'Loša',
  'LošićKrizmanić',
  'Lovač',
  'Lovak',
  'Lovaković',
  'Lovas',
  'Lovasić',
  'Lovaszi',
  'Lovaš',
  'Lovenjak',
  'Lovrenščak',
  'Lovrić Ađić',
  'Lovrić Bonaventura',
  'Lovrić Kurbanović',
  'Lovrić Rimac',
  'Lovrić Varžić',
  'Lozančić',
  'Lozančić Bagarušić',
  'Lozančić Bogarušić',
  'Lozanov',
  'Lozanović',
  'Lozina',
  'Lubar',
  'Lubina',
  'Lubina Jukić',
  'Lucarić',
  'LucićSavić',
  'Lucijak',
  'Lucijanić',
  'Lučan',
  'Lučevnjak',
  'Ludaš',
  'Ludban',
  'Lugarić',
  'Lugonja',
  'Luka',
  'Lukac',
  'Lukac Jurić',
  'Lukacs',
  'Lukač',
  'Lukaček',
  'Lukačev',
  'Lukačević',
  'Lukačić',
  'Lukačić Trifunović',
  'Lukačin',
  'Lukać',
  'Lukaćević',
  'Lukadinović',
  'Lukanić',
  'Lukanović',
  'Lukarova Božičević',
  'Lukašek',
  'Lukavec',
  'Lukavečki',
  'Lukavski',
  'Lukenda',
  'Luketa',
  'Luketina',
  'LukićGaličić',
  'LukićKovanović',
  'LukićMiklaj',
  'Lukićova',
  'Lukinac',
  'Lukjar',
  'Lukman',
  'Lukovica',
  'Lukovnjak',
  'LulićSlipac',
  'Lusavec',
  'Luščak',
  'Lušćak',
  'LušićMarić',
  'Luthar',
  'Lutterbach',
  'Lužaić',
  'Ljagajlo',
  'Ljajić',
  'Ljeljak Jurman',
  'Ljesar',
  'Ljeskovan',
  'Ljevar',
  'Ljevnaić',
  'Ljikar',
  'Ljiljak',
  'Ljiljak Jurman',
  'Ljiljan',
  'Ljiljanić',
  'Ljubanović',
  'Ljubas',
  'Ljubašćik',
  'Ljubica',
  'Ljubisavljević',
  'Ljubljanović',
  'Ljuma',
  'Ljuma Ozeva',
  'Ljušanin',
  'Ljuština',
  'Ljutak',
  'Mabić',
  'Macakanja',
  'Macan',
  'Macanga',
  'Macanić',
  'Macanović',
  'Macašek',
  'Maceković',
  'Macele',
  'Machiedo',
  'Machulec',
  'Machulec Gašić',
  'Mack',
  'Mack Savanović',
  'Mackanić',
  'Macko',
  'Mackuljak',
  'Macokatić',
  'Macura',
  'Mačak',
  'Mačak Borš',
  'Mačar',
  'Mačaš',
  'Mačašić',
  'Maček',
  'Mačešić',
  'Mačik',
  'Mačinković',
  'Mačkić',
  'Mačković',
  'Mačvan',
  'Maćaš',
  'Maćašević',
  'Maćešić',
  'Maćo',
  'Madajček',
  'Madaras',
  'Madarić',
  'Madavić',
  'Madecki',
  'Madi',
  'Madić',
  'Madjar',
  'Madjar Klaić',
  'Madjarac',
  'Madjarić',
  'Madjerčić',
  'Maduna',
  'Madunić',
  'MadunićFlauder',
  'Madunović',
  'MadunovićPavošević',
  'Madžar',
  'Madžarac',
  'Madžarević',
  'Madžarić',
  'Madžić',
  'Mađar',
  'Mađar Babok',
  'Mađarac',
  'Mađarević',
  'MađarevićKubatka',
  'Mađarić',
  'Mađer',
  'Mađerčić',
  'Mađerec',
  'Mađerić',
  'Mađura',
  'Maganić',
  'Maganjić',
  'Magaš',
  'Magazinović',
  'Magda',
  'Magdalenić',
  'Magdić',
  'Magdika',
  'Magečić',
  'Magić',
  'Magjer',
  'Magjer Veg',
  'Maglaić',
  'Maglajić',
  'Maglica',
  'Magličić',
  'Maglić',
  'Maglov',
  'Magoči',
  'Magoći',
  'Magoš',
  'Magovac',
  'Magud',
  'Magura',
  'Magušić',
  'MagušićDumančić',
  'MagušićWilhelm',
  'Magyar',
  'Mahić',
  'Mahmudi',
  'Mahmutović',
  'Mahnić',
  'Mahovlić',
  'Mahovne',
  'Maić',
  'Mainc',
  'Maj',
  'Majača',
  'Majačić',
  'Majbaum',
  'Majcan',
  'Majcan Korkutović',
  'Majcen',
  'Majcenić',
  'Majcenović',
  'Majdak',
  'Majdančić',
  'Majdandžić',
  'MajdandžićLauc',
  'Majdenić',
  'Majdić',
  'Majdiš',
  'Majer',
  'Majer Podgorski',
  'Majera',
  'Majerhof',
  'Majerhoffer',
  'Majerić',
  'Majerski',
  'Majerus',
  'Majetić',
  'MajetićCetina',
  'Majhen',
  'Majher',
  'Majić',
  'MajićMiholić',
  'Majk',
  'Majkić',
  'Majksner',
  'Majlinger',
  'Majlinger Tanocki',
  'Majnarić',
  'Majnarić Prinčevac',
  'Majnić',
  'Majnik',
  'Major',
  'Major Hargita',
  'Major Lukić',
  'Majorić',
  'Majoroš',
  'Majpruz',
  'Majsan',
  'Majsen',
  'Majski',
  'Majsterić',
  'Majstor',
  'Majstorović',
  'Majstorović Gusić',
  'Majstorski',
  'Majstrenko',
  'Majstrović',
  'Majtanić',
  'Majurac',
  'Majurec',
  'Majzik',
  'Majzl',
  'Mak',
  'Mak Horvat',
  'Maka',
  'Makaj',
  'Makar',
  'Makarčuk',
  'Makarević',
  'Makarević Stanojčić',
  'Makarić',
  'Makarijevska',
  'Makarijevski',
  'Makaro',
  'Makarović',
  'Makarum Pirić',
  'Makarun',
  'MakarunŠurina',
  'Makek',
  'Makević',
  'Makić',
  'Makijenko',
  'Makir',
  'Makiš',
  'Makivić',
  'Mako',
  'Makonnen',
  'Makoš',
  'Makoter',
  'Makov',
  'Makovec',
  'Maković',
  'Maksić',
  'Maksimović',
  'Maksimovski',
  'Makšan',
  'Makšan Kramar',
  'Makvić',
  'Malacko',
  'Malarić',
  'Malbaša',
  'Malbaša Varga',
  'Malbašić',
  'Malbašik',
  'Malčić',
  'Maldini',
  'Malečić',
  'Malek',
  'Malekin',
  'Maleković',
  'Malenica',
  'Malenica Dekany',
  'Malenica Marić',
  'Malenić',
  'Malenjak',
  'Maler',
  'Maleš',
  'Maleševac',
  'Malešević',
  'Malešić',
  'Maleta',
  'Maletić',
  'Maletin',
  'Maletović',
  'Malević',
  'Mali',
  'Malić',
  'Malidža',
  'Maligec',
  'Malijurek',
  'Malinar',
  'Malinić',
  'MalinićUglik',
  'Malinović',
  'MalinovićBanaji',
  'Mališ',
  'Mališić',
  'Malivojević',
  'Malivuk',
  'Malizani',
  'Malkoč',
  'Malkoć',
  'Malković',
  'Malnar',
  'Malobabić',
  'Malobović',
  'Maloča',
  'Malogorski',
  'Maloić',
  'Malojčić',
  'Malounek',
  'Malov',
  'Malovan',
  'Malovčak',
  'Malović',
  'Malovoz',
  'Maltar',
  'Maltašić',
  'Malter',
  'Malunjak',
  'Malvić',
  'Maljarić',
  'Maljik',
  'Maljković',
  'MaljkovićBabić',
  'MaljkovićKocevski',
  'Maljur',
  'Mamić',
  'Mamilović',
  'Mamlić',
  'Mamula',
  'Mamuzić',
  'Mamužić',
  'Manc',
  'Mance',
  'Manceta',
  'Manci',
  'Manczi',
  'Mančić',
  'Mandalić',
  'Mandarić',
  'MandarićKolarik',
  'MandarićKurilj',
  'Mandekić',
  'Mandić',
  'Mandir',
  'Mandl',
  'Mandrić',
  'Mandunović',
  'Mandura',
  'Mandurić',
  'Mandušić',
  'Mandžukić',
  'Manestar',
  'Manfred',
  'Mangold',
  'Manić',
  'Manislović',
  'Mann',
  'Mano',
  'Manojlović',
  'Manolio',
  'Manović',
  'Manzer',
  'Manzin',
  'Manjerović',
  'Manjić',
  'Manjok',
  'Maoduš',
  'Mar',
  'Maračić',
  'Maradin',
  'Marak',
  'Maraković',
  'Maran',
  'Maranić',
  'Maras',
  'Marasović',
  'Maraš',
  'Marašek',
  'Maratović',
  'Maravić',
  'Marcić',
  'Marcijuš',
  'Marcikić',
  'Marciuš',
  'MarciušPap',
  'Marczi',
  'Marčec',
  'Marček',
  'Marčeta',
  'Marčetić',
  'Marčičević',
  'Marčić',
  'Marčićević',
  'Marčik',
  'Marčinko',
  'Marčinković',
  'Marčit',
  'Marčok',
  'Marđetko',
  'Marek',
  'Marek Ilić',
  'Marelja',
  'Marendić',
  'Marenić',
  'Marenjak',
  'Mareš',
  'Maretić',
  'Marević',
  'Margaretić',
  'Margeta',
  'Margetić',
  'Margić',
  'Margil',
  'Margit',
  'Margold',
  'Margold Bubalović',
  'Marguš',
  'Maričak',
  'Maričević',
  'Maričić',
  'Marić',
  'MarićMalinić',
  'Marićak',
  'Marijan',
  'Marijanac',
  'Marijančević',
  'Marijanov',
  'Marijanović',
  'MarijanovićZulim',
  'Marijašević',
  'Marijić',
  'Marin',
  'Marina',
  'Marincel',
  'Marincl',
  'Marinclin',
  'Marinčić',
  'Marinić',
  'Marinković',
  'Marino',
  'Marinov',
  'Marinović',
  'MarinovićČačić',
  'MarinovićVlaičević',
  'Marinjak',
  'Marjanac',
  'Marjančević',
  'Marjančić',
  'Marjanov',
  'Marjanović',
  'MarjanovićBaglama',
  'MarjanovićMatić',
  'MarjanovićZulim',
  'MarjanovićZulin',
  'Markač',
  'Markafić',
  'Markan',
  'Markanović',
  'Markanjević',
  'Markasović',
  'Markeljević',
  'Markešić',
  'Marketić',
  'Marki',
  'Markić',
  'Markioli',
  'Markiš',
  'Marko',
  'Markolin',
  'Markoš',
  'Markota',
  'Markotić',
  'Markov',
  'Markovac',
  'Markovanović',
  'Markovčić',
  'Marković',
  'MarkovićBrunčić',
  'MarkovićDudjak',
  'MarkovićĐurin',
  'MarkovićGjurin',
  'MarkovićKovač',
  'MarkovićMandić',
  'MarkovićPernar',
  'MarkovićŠarac',
  'Markovinović',
  'Markovski',
  'Marks',
  'Markser',
  'Markulak',
  'Markulić',
  'Markulin',
  'Markulinčić',
  'Markulj',
  'Markuljević',
  'Markus',
  'Markuš',
  'Markušev',
  'Markušić',
  'Markuz',
  'Marman',
  'Marmeggi',
  'MarmilićCezner',
  'Marod',
  'Marod Scheiber',
  'Marodi',
  'Marodt',
  'Maroević',
  'Marojević',
  'Marokić',
  'Maroković',
  'Marolin',
  'Marolt',
  'Maronić',
  'MaronićFischer',
  'Maros',
  'Maros Batinić',
  'Maroslavac',
  'Maroš',
  'Maroševac',
  'Marošević',
  'Maroši',
  'Marošičević',
  'Marošićević',
  'Marov',
  'Marović',
  'Marsenić',
  'Marsić',
  'Maršal',
  'Maršalek',
  'Maršanić',
  'Maršhauzer',
  'Maršić',
  'Maršo',
  'Mart',
  'Martalić',
  'Martan',
  'Martić',
  'Martin',
  'Martin Rajher',
  'Martinčević',
  'MartinčevićDiačec',
  'MartinčevićKučuk',
  'MartinčevićMikić',
  'Martinčić',
  'Martinćević',
  'Martinek',
  'Martinić',
  'Martinić Perljević',
  'Martinko',
  'Martinko Mrđa',
  'Martinković',
  'Martinov',
  'Martinović',
  'Martinović De Conceicao',
  'MartinovićGrčić',
  'MartinovićPlavšić',
  'Martinuš',
  'Martinušić',
  'Martinjak',
  'Martinjaš',
  'Marton',
  'Martynyuk',
  'Marukić',
  'Marulin',
  'Maruna',
  'Marunica',
  'Marunić',
  'Marunović',
  'Marušić',
  'MarušićHalači',
  'Maržić',
  'Mas',
  'Masić',
  'Maskarić',
  'Maslać',
  'Maslak',
  'Maslarić',
  'Masle',
  'Maslić',
  'Maslov',
  'Maslovara',
  'Masljak',
  'Masnica',
  'Masnić',
  'Masnikosa',
  'Masoničić',
  'Mastanjević',
  'Mastelica',
  'Mastelić',
  'Mašaberg',
  'Mašala',
  'Mašanović',
  'Mašek',
  'Mašić',
  'Mašina',
  'Maširević',
  'Maškov',
  'Mašojević',
  'Mašović',
  'Maštrović',
  'Matačić',
  'MatačićBrodić',
  'Matagić',
  'Mataija',
  'Matais',
  'Matajčević',
  'Matajčić',
  'Matajić',
  'Matajs',
  'Matajz',
  'Matak',
  'Mataković',
  'Mataković Erl',
  'Matan',
  'Matana',
  'Matančić',
  'Matanić',
  'Matanić Petkovski',
  'Matanov',
  'Matanov Bugarić',
  'Matanovac',
  'Matanović',
  'Matas',
  'Matasić',
  'Matasović',
  'Mataušek',
  'Matavulj',
  'Mataz',
  'Mate',
  'Mateašić',
  'Matečko',
  'Matej',
  'Matejak',
  'Matejašić',
  'Matejčić',
  'Matejić',
  'Matek',
  'Matekalo',
  'Mateković',
  'Mateljić',
  'Materni',
  'Mateša',
  'Matešić',
  'Matešković',
  'Matezić',
  'Matezović',
  'Mati',
  'Matics',
  'Matiček',
  'Matičević',
  'Matičić',
  'Matić',
  'MatićMandić',
  'MatićSinković',
  'MatićSpremo',
  'Matija',
  'Matijačić',
  'Matijaković',
  'MatijakovićKrišto',
  'Matijanić',
  'Matijas',
  'Matijaš',
  'Matijašec',
  'Matijašević',
  'Matijašić',
  'Matijaško',
  'Matijatko',
  'Matijevac',
  'Matijević',
  'Matin',
  'Matišić',
  'MatišićKučinić',
  'Matiz',
  'Matjačić',
  'Matjaš',
  'Matjašić',
  'Matko',
  'Matkov',
  'Matkovček',
  'Matković',
  'MatkovićBatalija',
  'MatkovićSubašić',
  'Matkun',
  'Matleković',
  'Matok',
  'Matokić',
  'Matoković',
  'Matorić',
  'Matoš',
  'Matoša',
  'Matošević',
  'Matošić',
  'Matota',
  'Matotek',
  'Matoušek',
  'Matovac',
  'Matović',
  'Matovina',
  'Matovinović',
  'Matrić',
  'Mattei',
  'Matthias',
  'Matthies',
  'Matthies Pepelko',
  'Matulić',
  'Matulin',
  'Maturanac',
  'Maturanec',
  'Maturić',
  'Matus',
  'Matusina',
  'Matusinović',
  'Matuš',
  'Matušenski',
  'Matušin',
  'Matuško',
  'Matušković',
  'Matuzalem',
  'Matuzović',
  'Matvij',
  'Mauhar',
  'Mauk',
  'Mauker',
  'Maul',
  'Maurac',
  'Maurer',
  'Maurić',
  'Maurović',
  'Maurus',
  'Mauser',
  'Mautner',
  'Mauzer',
  'Mavračić',
  'Mavrek',
  'Mavretić',
  'Mavričić',
  'Mavrić',
  'Mavrin',
  'Mayer',
  'Mayer Topalović',
  'Mazalica',
  'Mazalović',
  'Mazanek',
  'Mazić',
  'Maznik',
  'Mazur',
  'Mažar',
  'Mažar Falamić',
  'Mažar Tomeš',
  'Mažuran',
  'Meandžija',
  'Meašić',
  'Meaški',
  'Mecanov',
  'Mecanov Pofuk',
  'Mečanin',
  'Mećava',
  'MedačKraker',
  'Medak',
  'Medaković',
  'Medančić',
  'Medanić',
  'Medarić',
  'Medarski',
  'Medenjak',
  'Medinac',
  'Medjugorac',
  'Medovka',
  'Medvar',
  'Medvedarović',
  'Medvedec Lazarevski',
  'Međaković',
  'Međugorac',
  'Međurečan',
  'Megdanac',
  'Mehak',
  'Mehak Lipanović',
  'Mehanović',
  'MehičićButina',
  'Mejaš',
  'Mejdanac',
  'Mekanj',
  'Mekinda',
  'Mekovec De Carvalho',
  'Mekovec Diniz De Carvalho',
  'Melar',
  'MelegešGamoš',
  'Melmajer',
  'Melman',
  'Memešahović',
  'Menalo',
  'Mendaš',
  'Meničanin',
  'Menićanin',
  'Menkovska',
  'Menrat',
  'Merčinac',
  'Merda',
  'Merkaš',
  'Merunka',
  'Mesar',
  'Mesarek',
  'Mesarić',
  'Mesaroš',
  'MesarošBelanji',
  'MesarošŠandor',
  'MesićMuharemi',
  'Meszaroš',
  'Mešanović',
  'MeščićDziegielewska',
  'MeštrovićJelačić',
  'Metlagel',
  'Mezak',
  'Mezarić',
  'Mezga',
  'Meznarić',
  'Mežnarić',
  'Miačank',
  'Micai',
  'Michalek',
  'Michels Andračić',
  'Mickovska',
  'Micudaj',
  'Mičan',
  'Mička',
  'Mičuda',
  'Mićan',
  'Mićanović',
  'Mićuda',
  'Mifka',
  'Migač',
  'Mihačić',
  'Mihailović',
  'Mihajlov',
  'Mihajlović',
  'Mihalak',
  'Mihalčić',
  'Mihalec',
  'Mihalek',
  'Mihalic',
  'Mihaliček',
  'Mihalička',
  'Mihalić',
  'Mihalik',
  'Mihalik Vidaček',
  'Mihalina',
  'Mihaly',
  'Mihalj',
  'Mihaljčević',
  'Mihaljčić',
  'Mihaljčuk',
  'Mihaljec',
  'Mihaljek',
  'Mihaljenović',
  'Mihaljev',
  'Mihaljević',
  'MihaljevićJurić',
  'MihaljevićŽiroš',
  'Mihaljić',
  'Mihaljik',
  'Mihaljinec',
  'Mihanić',
  'Mihnjak',
  'Miholjac',
  'Mijač',
  'Mijačank',
  'Mijačević',
  'Mijaćank',
  'Mijadinović',
  'Mijadžiković',
  'Mijailović',
  'Mijajlović',
  'Mijakić',
  'Mijakovac',
  'Mijaković',
  'Mijalković',
  'Mijaljević',
  'Mijanović',
  'Mijatov',
  'Mijatović',
  'MijatovićAlošinac',
  'MijićBarišić',
  'Mijota',
  'Mika',
  'Mikac',
  'Mikač',
  'Mikać',
  'MikaćMlađen',
  'Mikala',
  'Mikan',
  'Mikanovac',
  'Mikanović',
  'Mikašek',
  'Mikašinović',
  'MikešićMerda',
  'Miketa',
  'MikićGradaščević',
  'MikićVučak',
  'Mikinac',
  'Mikla',
  'Miklaj',
  'Miklavčić',
  'Mikloška',
  'Mikodanić',
  'Mikola',
  'Mikolac',
  'Mikolaš',
  'Mikolašević',
  'Miksa',
  'Mikša',
  'Mikula',
  'Mikulan',
  'Mikulandra',
  'Mikulandra Tutavac',
  'Mikulaš',
  'Mikulica',
  'MikulićBednar',
  'Mikuša',
  'Mikuška',
  'Mikuta',
  'Mila',
  'Miladić',
  'Miladinović',
  'Milak',
  'Milakić',
  'Milaković',
  'Milaković Španović',
  'Milanko',
  'Milankov',
  'Milanković',
  'Milanović',
  'Milanovski',
  'Milanovski Varga',
  'Milardović',
  'Milas',
  'Milas Nađ',
  'Milaščević',
  'Milašević',
  'Milašin',
  'Milašinčić',
  'Milašinović',
  'Milat',
  'Milat Lendić',
  'Miler Kardinal',
  'Mileta',
  'MiličićJuhas',
  'MilićGregoran',
  'Milidrag',
  'Milijašević',
  'MilinkovićKalić',
  'Milisav',
  'Milisavljević',
  'MilišićKovačević',
  'Milla',
  'Milobara',
  'Milohanić',
  'Milohnoja',
  'Milojica',
  'Miloknaja',
  'Miloknoja',
  'Miloloža',
  'Milorad',
  'Miloradović',
  'Milosavčević',
  'Milosavić',
  'Milosavljević',
  'MiloševićManojlović',
  'Milovac',
  'Milovanov',
  'Milovanović',
  'Miljački',
  'Miljak',
  'Miljaković',
  'Miljan',
  'Miljančić',
  'Miljanović',
  'MiljanovićŠpika',
  'Miljatović',
  'Mimica',
  'Minarek',
  'Minarik',
  'MinkošBubalović',
  'Mioca',
  'Miodanić',
  'Miodrag',
  'Miodragović',
  'Miranović',
  'Mirjačić',
  'Mirjanić',
  'Mirkajlović',
  'MirkovićStipanić',
  'Mirosav',
  'Mirosavljević',
  'Miroslav',
  'Misanović',
  'Misarček',
  'Mišak',
  'Mišanec',
  'Mišanović',
  'Miščančuk Dugac',
  'Mišeta',
  'Miškatović',
  'MiškovićKlarić',
  'Mišljenac',
  'Mištrafović',
  'Mišura',
  'Mitanović',
  'MitanovićGotić',
  'Mitar',
  'Mitermajer',
  'Miterman',
  'Mitka',
  'Mitrekanić',
  'Mitrina',
  'Mittermann',
  'Mizdrak',
  'Mjeda',
  'Mlačić',
  'Mladenović',
  'Mladina',
  'Mladinov',
  'Mlađenović',
  'Mlakar',
  'Mlakić',
  'Mlikota',
  'Mlinac',
  'Mlinaček',
  'Mlinar',
  'Mlinarec',
  'Mlinarević',
  'Mlinarić',
  'Mlinarik',
  'Mlinarik Alimehaj',
  'Mocnaj',
  'Močilac',
  'Močilnikar',
  'Mofardin',
  'Mogušar',
  'Moharić',
  'Moharoš',
  'Mohila',
  'Mohnacki',
  'Moka',
  'Mokran',
  'Moldovan',
  'Molnar',
  'Molnar Toriž',
  'Molodova',
  'Morača',
  'Morača Crnogorac',
  'Morava',
  'Moravac',
  'Moravec',
  'Moravek',
  'Morgental',
  'Morina',
  'Morman',
  'Morman Lukavec',
  'Moro Vladić',
  'Morovičan',
  'Morvai',
  'Morvat',
  'Moržan',
  'Moržanj',
  'Moskalj',
  'Moslavac',
  'Mostarčić',
  'Mostečak',
  'Mostina',
  'Mošnja',
  'Moštak',
  'Mračkovski',
  'Mrak',
  'MrakŠimenić',
  'Mrakodolac',
  'Mraković',
  'Mraović',
  'Mrastinski Jurčec',
  'Mravec',
  'Mravinac',
  'Mravlinčić',
  'Mravunac',
  'Mraz',
  'Mrazik',
  'Mrazović',
  'Mrčela',
  'Mrdalj',
  'Mrdeša',
  'Mrdeža',
  'Mrdjanović',
  'Mrđa',
  'Mrđan',
  'Mrđanović',
  'Mrekaj',
  'Mrganić',
  'Mrkalj',
  'Mrkela',
  'Mrkobrad',
  'Mrkonja',
  'Mrkota',
  'Mrla',
  'Mrmak',
  'Mrnjavčić',
  'Mrša',
  'Mršak',
  'Mršinjak',
  'Mršo Stančić',
  'Mrva',
  'Mrvaljević',
  'Mrzljak',
  'Mrzljak Rado',
  'Mržljak',
  'Muačević',
  'Muaremi',
  'Mucafir',
  'Mucak',
  'Mucalj',
  'Mucavac',
  'Muchaus',
  'MucićMak',
  'Mučalović',
  'Muha',
  'Muhadri',
  'Muhar',
  'Muharemi',
  'Muharemović',
  'Mujadin',
  'Mujagić',
  'Mujakić',
  'Mujaković',
  'Mujanović',
  'Mujarić',
  'Mujkanović',
  'Muk Vida',
  'Mulac',
  'Mulalić',
  'Mulle ranješ',
  'Muljaić',
  'Muljajić',
  'Munćan',
  'Munda',
  'Mundinac',
  'Munđar',
  'Munikravić',
  'Munkačević',
  'Munkaš',
  'Muradbašić',
  'Muraja',
  'Murat',
  'Murati',
  'Muratović',
  'Murgaš',
  'Muržanj',
  'Musa',
  'Musa Balaš',
  'Musa Korov',
  'Musija',
  'Muslija',
  'MusovićJakovljević',
  'Mustač',
  'Mustać',
  'Mustafa',
  'Mustafić',
  'Mustafov',
  'Mustak',
  'Mustapić',
  'Mušac',
  'Mušanić',
  'Mušanović',
  'Mušija',
  'Mušnjak',
  'Muštran',
  'Mušura',
  'Mutabožić',
  'Mutaja',
  'Mutapčić',
  'Mutavdžić',
  'Mutavdžija',
  'Mutavđić',
  'Mutnjaković',
  'Mutzhaus',
  'Mužar',
  'Muždeka',
  'Muženjak',
  'Nacl',
  'Nača',
  'Nad',
  'Nadalin',
  'Nadarević',
  'Nadašdi',
  'Nadaši',
  'Nadić',
  'Nadj',
  'Nadoveza',
  'Nadžvegi',
  'Nađ',
  'NađMihaljević',
  'NađPejaković',
  'NađRađago',
  'Nađorđ',
  'Nađpal',
  'Nađpalfi',
  'Nađsombat',
  'Nađvegi',
  'Nagj',
  'Naglav',
  'Naglav Faisz',
  'Naglav Hardt',
  'Naglić',
  'Nago',
  'Nagy',
  'Nagy Milošević',
  'Nagypalfi',
  'Nagyszombat',
  'Nagyszombaty',
  'NagyszombatyŠarić',
  'Nagyvegi',
  'Nagyy',
  'Najbauer',
  'Najcer',
  'Najcer Bračevac',
  'Najdanović',
  'Najduček',
  'Najduk',
  'Najev',
  'Najman',
  'Najvirt',
  'Nakani',
  'Nakić',
  'Nako',
  'Nalčić',
  'Naletilić',
  'Nalio',
  'Nalo',
  'Namestnik',
  'Namjesnik',
  'Namjesnik Skender',
  'Namjestnik',
  'Nanaić',
  'Nanaj',
  'Nanay',
  'Nanik',
  'Nappholz',
  'Narančić',
  'Narandža',
  'Narandžić',
  'Naranđa',
  'Narenđa',
  'Nasić',
  'Nategov',
  'Natić',
  'Natran',
  'Naumov',
  'Naumovska',
  'Naumovski',
  'Naumovski Bogdanović',
  'NaumovskiČizmar',
  'Navijalić',
  'Navratil',
  'Nazarević',
  'Nazibegović',
  'Nazifović',
  'Nećak',
  'Nedjeljkov Stambolija',
  'Nedoklan',
  'Negovanović',
  'Nelken Bestvina',
  'Nemčanin',
  'Nemet Ivanović',
  'Nemet Marković',
  'Nemet Petriševac',
  'Nemet Radvanji',
  'NemetŠestak',
  'Nenadić',
  'Nenadov',
  'Nenadović',
  'Neogradi',
  'Neral',
  'Neralić',
  'Neretljak',
  'Nesladek',
  'Nestorović Tavić',
  'Nesvanulica',
  'Netroufal',
  'Neuman',
  'Nevajdić',
  'Nevidal',
  'Nevidal Kraljević',
  'Nezaj',
  'Neziraj',
  'Neznanović',
  'Niedoba',
  'Nikačević',
  'NikićČakar',
  'Niklaj',
  'Nikola',
  'NikolaŽaper',
  'Nikolandić',
  'Nikolaš',
  'Nikolašević',
  'Nikolaus',
  'NikolićKovačić',
  'NikolićOdak',
  'Nikolin Horvat',
  'Nikolin Tormaš',
  'Nikolovska',
  'Nikoljačić',
  'Ništ Kokolari',
  'Nižalek',
  'Nižman',
  'Nogalo',
  'Nonveiller Kovač',
  'Nosal',
  'Nospak',
  'Notta',
  'Novačić',
  'Novački',
  'Novak',
  'Novak Belić',
  'Novak Kovač',
  'Novak Lukadinović',
  'Novak Mehić',
  'Novaković',
  'NovakovićGrčić',
  'NovakovićReininger',
  'Novalić',
  'Novina',
  'NovkovićNarančić',
  'Novogradac',
  'Novogradac Kater',
  'Novogradec',
  'Novogradić',
  'Novoselac',
  'NovoselićKazimić',
  'NovoselićNovak',
  'Novota',
  'Nowosadek',
  'Nožina',
  'Numanović',
  'Nuspahić',
  'Nussgraber',
  'Nyarko',
  'Njari',
  'Njary',
  'Njegovan',
  'Njegovanović',
  'Njirjak',
  'Obad',
  'Obad Omeragić',
  'Obadić',
  'Obajdin',
  'Obermajer',
  'Oberman',
  'Oberman Sebenji',
  'Oblačić',
  'Oblak',
  'Obradović',
  'ObradovićViland',
  'Obranić',
  'Obrdalj',
  'Obrežanac',
  'Obrovac',
  'Obšivač',
  'Obućina',
  'Očanček',
  'Očurščak',
  'Očuršćak',
  'Odak',
  'Odak Peran',
  'Odak Smodlaka',
  'Odavić',
  'Odenvald',
  'Odobaša',
  'Odobašić',
  'Odža',
  'Ofentavšek',
  'Ogar',
  'Ognjan',
  'Ognjanovac',
  'Ognjanović',
  'Ojvan',
  'Okanović',
  'Oklobdžija',
  'Okmažić',
  'Okovacki',
  'Okreša',
  'Okštajner',
  'Olah',
  'Olajos',
  'Olajoš',
  'Olbina',
  'Olbina Borić',
  'Olivari',
  'Olman',
  'Oljača',
  'Omanović',
  'Omazić',
  'Omeragić',
  'OmeragićGatara',
  'Omerašević',
  'Omerbašić',
  'OmerbašićŠišić',
  'Ondrašek',
  'Opačak',
  'Opačak Fidel',
  'Opačak Marković',
  'Opačić',
  'OpačićMatijević',
  'Opalić',
  'Opančar',
  'Opat',
  'Opraus',
  'Oprea',
  'Opselka',
  'Opšivač',
  'Opua',
  'Opuvačić',
  'Orak',
  'Oravec',
  'Orban',
  'Orbanić',
  'Ordanić',
  'Orđan',
  'Orehovac',
  'Orešan',
  'Oreščanin',
  'Orgonaš',
  'Orgulan',
  'Orlovac',
  'Ormanac',
  'Orobabić',
  'Orsag',
  'Oršanić',
  'Oršanski',
  'Osbah',
  'Osekovac',
  'Osenjački',
  'Osička',
  'Oslanski',
  'Osmak',
  'Osmakčić',
  'Osman',
  'Osmanagić',
  'Osmanhodžić',
  'Osmani',
  'Osmanlić',
  'Osmanović',
  'Osonjački',
  'Ososlija',
  'Osrečak',
  'Osrečak Hranj',
  'Ostajmer',
  'Osterkamp',
  'Osthajmer',
  'OstojićVukelja',
  'Ostopanj',
  'Ostrijaš',
  'Ostrman',
  'Ostroman',
  'Osvadić',
  'Osvald',
  'Oswald',
  'Ošap',
  'Ošapić',
  'Ošlaj',
  'Oštarjaš',
  'Otartić',
  'Oterbajn',
  'Otrbajn',
  'Ovad',
  'Ovčar',
  'Ovčar Mabić',
  'Ovčarić',
  'Ovčarik',
  'Ovčina',
  'Ovuka',
  'Owais',
  'Ozanjak',
  'Ozdanovac',
  'Ozimec Rikardo',
  'Ozuška',
  'Ožanić',
  'Ožuška',
  'Ožvald',
  'Ožvalt',
  'Ožvatić',
  'Pacanović',
  'Pacek',
  'Pacek Dokuš',
  'Pacher',
  'Pacić',
  'Paciorek',
  'Pacl',
  'Pacovski',
  'Pacovski Erhard',
  'Pačar',
  'Pačarek',
  'Pačarić',
  'PačarićKulešević',
  'Paći',
  'Padar',
  'Padežanin',
  'Padjen',
  'Padoš',
  'Padovan',
  'Padrov',
  'Pađan',
  'Pađen',
  'Pađen Farkaš',
  'Paestka',
  'Page Holmes',
  'Pahanić',
  'Pahčainović',
  'Pahert',
  'Pahić',
  'Pahl',
  'Paić',
  'Pajančić',
  'PajančićBrkić',
  'Pajazetović',
  'Pajc',
  'Pajcur',
  'Pajčić',
  'Pajčin',
  'Pajdić',
  'Pajer',
  'Pajeska',
  'Pajeska Kornel',
  'Pajestka',
  'Pajestka Mihajlović',
  'Pajić',
  'Pajković',
  'Pajkurić',
  'Pajnić',
  'Pajor',
  'Pajrok',
  'Pajtak',
  'Pajtel',
  'Pajtl',
  'Pajtler',
  'Pajur',
  'Pakasin',
  'Pakić',
  'Pakozdi',
  'Pakšec',
  'Pakši',
  'Pakšić',
  'Pal',
  'Palaček',
  'Palada',
  'Palagyi',
  'Palahinjak',
  'Palaić',
  'Palamaš',
  'Palanac',
  'Palančević',
  'Palandžić',
  'Palašić',
  'Palašti',
  'Palatinuš',
  'Palcer',
  'Palčec',
  'Palčić',
  'Palčok',
  'Paleček',
  'Palfi',
  'Palian',
  'Palić',
  'Palijan',
  'Palinić',
  'Palinkaš',
  'PalinkašŠćurla',
  'Palir',
  'Pališ',
  'Paliž',
  'PaližToth',
  'Palko',
  'PalkoČongor',
  'Palla',
  'Palm',
  'Paloc',
  'Palocz',
  'Paloh',
  'Paloš',
  'Palošić',
  'Palovčik',
  'Paluch',
  'Paluh',
  'Paljević',
  'Paljić',
  'Paljušić',
  'Pamer',
  'Pamić',
  'Pamper',
  'Panak',
  'Pancer',
  'Pancić',
  'Pančić',
  'PančićRuška',
  'Pančiško',
  'Pandi',
  'Pandl',
  'Pandur',
  'Pandurević',
  'Pandurić',
  'PandurićKlusko',
  'Pandurović',
  'Pandža',
  'Pandža Babić',
  'Pandžić',
  'Panđa',
  'Panđić',
  'Panella',
  'Panežić',
  'Panico',
  'Panić',
  'PanićBoromisa',
  'Panišić',
  'Pankarić',
  'Panker',
  'Panković',
  'Panović',
  'Panta',
  'Pantelić',
  'Pantelinac',
  'Pantić',
  'Pantner',
  'Pantoš',
  'Panjak',
  'Panjaković',
  'Panjičanin',
  'Panjičko',
  'Panjkov',
  'Panjković',
  'Pap',
  'Papa',
  'Papac',
  'Papak',
  'PapateodoroŠkorić',
  'Papazović',
  'Papeš',
  'Papež',
  'Papić',
  'PapićHerceg',
  'Papišta',
  'Papo',
  'Paponja',
  'Papp',
  'Papratović',
  'Paprić',
  'Papšić',
  'Papučić',
  'Papuga',
  'Par',
  'Parabak',
  'Parabucki',
  'Parač',
  'Parać',
  'Paradinović',
  'Paradžik',
  'Paradžiković',
  'Parađ',
  'Parađiković',
  'Parađina',
  'Paraga',
  'Paragi',
  'Paragović',
  'Paranos',
  'Parat',
  'Paravac',
  'Paravinja',
  'Parčetić',
  'Pardon',
  'Parezanović',
  'Parežanin',
  'Parić',
  'Paripović',
  'Paris',
  'Park Yong',
  'Parlić',
  'Parlov',
  'Parmačević',
  'Paroški',
  'Parsaee',
  'Partić',
  'Partlić',
  'Parun',
  'Pasa',
  'Pasanović',
  'Pasariček',
  'Pasarić',
  'Paska',
  'Paska Katić',
  'Pasler',
  'Passalis',
  'Past',
  'Pastar',
  'Pasti',
  'Pastor',
  'Pastorćić',
  'Pastović',
  'Pastuhović',
  'Pastulović',
  'Pastuović',
  'Pastva',
  'Pastvečka',
  'Pastvečko',
  'Pasza',
  'Pasztor',
  'Pašagić',
  'Pašajlić',
  'Pašalić',
  'Pašić',
  'Paškov',
  'Paškuljević',
  'PaškuljevićTurniški',
  'Paškvan',
  'Pašti',
  'Pašul',
  'Pašuld',
  'Patača',
  'Patačić',
  'Patafta',
  'Patai',
  'Pataj',
  'Patajac',
  'Pataki',
  'Pataran',
  'Patarčić',
  'Patarich',
  'Patarić',
  'Paternoga',
  'Patila',
  'Patkanj',
  'Patko',
  'Patkoš',
  'Patković',
  'Patoki',
  'Patriarch',
  'Patrlj Popović',
  'Patron',
  'Patrun',
  'Patzl',
  'Paudenović',
  'Pauk',
  'Pauković',
  'Paulaj',
  'Paulay',
  'Paulić',
  'Paulik',
  'Paulini',
  'Paulinić',
  'Paulović',
  'Paulus',
  'Paun',
  'Pauna',
  'Paunković',
  'Paunović',
  'Paurević',
  'Paurić',
  'Paus',
  'Paušak',
  'Paušković',
  'Pauzar',
  'Pauzer',
  'Pavasović',
  'Pavec',
  'Pavela',
  'Pavela Bartol',
  'Pavelić',
  'PavelićPišl',
  'Pavelik',
  'Pavelja',
  'Paver',
  'Pavešić',
  'Pavetić',
  'PavetićHodak',
  'Pavičevac',
  'Pavičević',
  'Pavičić',
  'PavičićPribisalić',
  'PavičićZegnal',
  'Pavić',
  'PavićNikolić',
  'PavićPavlović',
  'Pavićević',
  'Pavin',
  'Pavišević',
  'Pavišić',
  'Pavitović',
  'Pavkov',
  'Pavkov Mehandžić',
  'Pavković',
  'Pavlačić',
  'Pavlak',
  'Pavlak Milić',
  'Pavlečić',
  'Pavlek',
  'Pavleković',
  'Pavlešić',
  'Pavletić',
  'Pavlic',
  'Pavlica',
  'Pavličević',
  'Pavličić',
  'Pavlić',
  'Pavlićević',
  'Pavlik',
  'Pavlinek',
  'Pavlinić',
  'Pavlinović',
  'Pavlinušić',
  'Pavlišak',
  'Pavlošić',
  'Pavlov',
  'Pavlović',
  'PavlovićPrekrat',
  'Pavlovsky',
  'Pavlus',
  'Pavljuk',
  'Pavoković',
  'Pavor',
  'Pavošev',
  'Pavošević',
  'PavoševićKrstanović',
  'PavoševićMatijević',
  'Pavrlišak',
  'Pavrlišek',
  'Pavšić',
  'Pavuna',
  'Payerl',
  'PayerlČikara',
  'Payeska',
  'Pazaver',
  'Pazman',
  'Pazmanj',
  'Pažanin',
  'Pažin',
  'Peaković',
  'Pecarski',
  'Pecelja',
  'Pecolaj',
  'Pecollaj',
  'Pečar',
  'Pečenka',
  'Pečujlija',
  'Pečvarac',
  'Pećanac',
  'Pećar',
  'Pećina',
  'Pećušak',
  'Pehar',
  'Peharda',
  'Peharda Božić',
  'Peica',
  'Pejak',
  'Pejakić',
  'Pejaković',
  'Pejanović',
  'Pejazić',
  'PejićBrezak',
  'Pekanov',
  'Pekanović',
  'Pekar',
  'Pekarik',
  'Peko Lončar',
  'Peko Santo',
  'PelešBračun',
  'Pelikan',
  'Pelivan',
  'Pelivanović',
  'PelivanovićVidaković',
  'Peljhan',
  'Pena',
  'Penava',
  'Penavić',
  'Penavin',
  'Penga',
  'Penzar',
  'Penjašković',
  'Peradenić',
  'Peradić',
  'Peradinović',
  'Perajica',
  'Perajin',
  'Perak',
  'Perak Kučinac',
  'Peraković',
  'Peran',
  'Peranec',
  'Peranić',
  'PeranićPrgomet',
  'Peranović',
  'Perasić',
  'Perasović',
  'Pereža',
  'Perhat',
  'Perica',
  'Perija',
  'Periša',
  'Perja',
  'Perjavica',
  'Perkatlić',
  'PerkovićVirovac',
  'PerkušićKomlenac',
  'Pernar',
  'Pernarčić',
  'PeroševićSilađi',
  'Perša',
  'Pervan',
  'Peša',
  'Pešikan',
  'Pešorda',
  'Peštaj',
  'Peštalić',
  'Peštanac',
  'Petak',
  'PetakŠimunović',
  'Petaković',
  'Petanović',
  'Petanjak',
  'Petarac',
  'Petelka',
  'Peterka',
  'Peternai',
  'Petkovska',
  'Petlovac',
  'Petljanski',
  'Petnjarić',
  'PetnjarićKalić',
  'Petovar',
  'Petovari',
  'Petrač',
  'PetračOžeg',
  'Petrak',
  'Petranović',
  'PetranovićCvenić',
  'Petraš',
  'Petrašević',
  'Petrijevčanin',
  'Petrina',
  'PetrinaŠlihgar',
  'Petrinšak',
  'Petrinja',
  'Petrinjac',
  'Petrinjak',
  'Petriševac',
  'PetrlićStrugačevac',
  'Petrošanec',
  'Petrošanec Pišl',
  'Petrov Vondrak',
  'Petrovac',
  'PetrovićPavlović',
  'Peurača',
  'Pfaf',
  'Pfajfer',
  'Pfann',
  'Piacentini',
  'Pichler Milaković',
  'Pichler Sajler',
  'Pifat',
  'Pigac',
  'Piha',
  'Pijanović',
  'Pijevac',
  'Piknjač',
  'Pikulska Pervan',
  'Pilanović',
  'Pilarić',
  'Pilat',
  'Pilav',
  'Pilgermajer',
  'Pilgermajer Dugalić',
  'Pilgermayer',
  'Pilicar',
  'Piližota',
  'Pilnaček',
  'Pilja',
  'Piljaš',
  'Pinčar',
  'Pintar',
  'Pintar Kerovec',
  'Pintarić',
  'Pinter Franjić',
  'PinjušićBabel',
  'Pipaš',
  'Piplica',
  'Pirak',
  'Pisačić',
  'Pisar',
  'Pisarević',
  'Piskač',
  'Piskać',
  'Piščak',
  'Pišćak',
  'Piškorjanac',
  'Pitinac',
  'Pitomac',
  'Pitra',
  'Pivac',
  'Pivač',
  'PivačZeko',
  'Pivar',
  'Pivaš',
  'Pivašević',
  'PivićKovačević',
  'Pivovariček',
  'Pivovarić',
  'Pjanić',
  'Pjevac',
  'Pjevač',
  'Pjevačević',
  'Plac',
  'Placento',
  'Plaček Bereš',
  'Plačko',
  'Plahinek',
  'Plahinek Jurišić',
  'Plahutnik',
  'Plančak',
  'Plander',
  'Planinac',
  'Planinc',
  'Planinić',
  'Plankar',
  'Plano',
  'Plantak',
  'Plantek',
  'Plantić',
  'Plantosar',
  'Plasaj',
  'Plasajac',
  'Plasajec',
  'Plaskur',
  'Plastić',
  'Plaščak',
  'Plaščak Kukučka',
  'Plaščar',
  'Plaščević',
  'Plašćak',
  'Platiša',
  'Platužić',
  'Platz',
  'Plaušić',
  'Plavac',
  'Plavanac',
  'Plavčić',
  'Plavec',
  'Plaveković',
  'Plavljanić',
  'Plavotić',
  'Plavša',
  'Plavšić',
  'Plavšin',
  'Plazanić',
  'Plazanjić',
  'Plazibat',
  'Plazibat Brodarac',
  'Plazonić',
  'Plazonja',
  'Plazonjić',
  'Plažanin',
  'Plečaš',
  'Plećaš',
  'Plemenitaš',
  'Plenča',
  'Plentaj',
  'Plestenjak',
  'Pleša',
  'Pleška',
  'Pletikapić',
  'Plevnik Cvitanušić',
  'Plevnik Dobravec',
  'Ploskonka',
  'Plukavec',
  'Plužarić',
  'PlužarićBaranja',
  'Pljukavec',
  'Pljuskovac',
  'Pnjak',
  'Poc Kovač',
  'Pocrnja',
  'Počanin',
  'Počijak',
  'Počuča',
  'Počuća',
  'Podbarac',
  'Podgajac',
  'Podgajac Albrecht',
  'Podgorac',
  'Podgoračan',
  'Podgorščak',
  'Podgoršćak',
  'Podhraški',
  'Podkrajac',
  'Podlavicki',
  'Podmanicki',
  'Podmanicky',
  'Podnar',
  'Podoljak',
  'Podpadac',
  'Podravac',
  'Podraza',
  'Podrumac',
  'Podunajec',
  'Podunavac',
  'Pogač',
  'Pogačić',
  'Pohiba',
  'Poka',
  'Pokas',
  'Pokaz',
  'Poklar',
  'Pokrajac',
  'Pokrajčić',
  'Pokrivač',
  'Pokrivka',
  'Pokšiva',
  'Polaček',
  'Polak',
  'Polanc',
  'Polančec',
  'Polanec',
  'Polanščak',
  'Poldrugač',
  'Polerman',
  'Polešak',
  'Poletar',
  'Polgar',
  'Polimac',
  'Polimanac',
  'Pollak',
  'Pollak Haring',
  'Pollak Seričević',
  'Polman',
  'Polovina',
  'Polšak',
  'Poljak',
  'Poljak Meković',
  'Poljak Tibor',
  'Poljaković',
  'Poljančec',
  'Poljanik',
  'Poljarević',
  'Poljičak',
  'Pomarčinović',
  'Pongrac',
  'Pongračić',
  'PongračićHorvat',
  'PongračićPejić',
  'Ponikvar',
  'Ponka',
  'Ponjavić',
  'Popadić',
  'Popara',
  'Poparić',
  'Popijač',
  'Popinjač',
  'Poplaša',
  'Poplašen',
  'Popovac',
  'PopovićBasta',
  'PopovićLubina',
  'PopovićSenaši',
  'Popržan',
  'Porga',
  'Poropat',
  'Portnar',
  'Posavac',
  'Posavac Kunovac',
  'Posavčević',
  'Posavec',
  'Posavec Jakobović',
  'Posavec Kovač',
  'Posavec Tušek',
  'Posavi',
  'Pospihalj',
  'Pospišilova',
  'Poša',
  'Poštaš',
  'Potalec',
  'Potfay',
  'Potkonjak',
  'Potkonjak Rakinić',
  'Potkovac',
  'Potkozarac',
  'Potkrajac',
  'Potnar',
  'Potnar Mijić',
  'Potočar',
  'Potočnjak',
  'Poturak',
  'Poturica',
  'Povarnik',
  'Považanj',
  'Povoljnjak',
  'Pozderac',
  'Poznan',
  'Poznanović',
  'PoznićGorjanac',
  'Pozsar',
  'Požar',
  'Požarnik',
  'Požega',
  'Požežanac',
  'Požgain',
  'Požgain Antolović',
  'Požgaj',
  'Požgaj Vinčić',
  'Požnjak',
  'Požnjak Vukovac',
  'Pračić',
  'Prađeno',
  'Prah',
  'Prahin',
  'Praiz',
  'Prajiz',
  'Prakatur',
  'Prakljačić',
  'Pralas',
  'Pralica',
  'Pralija',
  'Praljak',
  'Prančević',
  'Prangart',
  'Pranić',
  'Prankić',
  'Pranješ',
  'Pranjić',
  'Pranjković',
  'Praskalo',
  'Prašnički',
  'Prašnikar',
  'Prašnjak',
  'Pratljačić',
  'Pratnemer',
  'Pravdić',
  'Pravdić Jurišić',
  'Pravica',
  'Pražak',
  'Prebiračević',
  'Prečanica',
  'Predmerski Iharoš',
  'Predovan',
  'Predragović',
  'Predrevac',
  'Predrijevac',
  'Preglej Kovačević',
  'Prekačin',
  'Prekodravac',
  'Prekpalaj',
  'Prekpaljaj',
  'Prekrat',
  'Prekratić',
  'Preksavec',
  'Preloščan',
  'Prendaj',
  'Prenkpalaj',
  'Preradović',
  'Presečan',
  'Presnac',
  'Prespjanin',
  'Prespljanin',
  'Prevendar',
  'Prgomelja',
  'Prgomet Selak',
  'Prhal',
  'Pribanić',
  'Pribisalić',
  'Prica',
  'Prijatelj',
  'Priman',
  'Primorac',
  'Prinčevac',
  'Priselac',
  'Pristav',
  'Pristavnik',
  'Pristojak',
  'Prisuda',
  'Pritisanac',
  'Pritišanac',
  'Privara',
  'Prka',
  'Prkačin',
  'Prnjat',
  'Procaj',
  'Prodan',
  'Prodanić',
  'Prodanović',
  'Prohaska',
  'ProkešGracek',
  'Prokuplja',
  'Proleta',
  'Prološčić Ćaleta',
  'Prološčić Polovina',
  'Prosan',
  'Prostran',
  'Protrka',
  'Prpić Križevac',
  'Prskalo',
  'Prša',
  'Prša Lučenčić',
  'Prtenjača',
  'Prtoljan',
  'Prusac',
  'Prusina',
  'Pružinac',
  'Prvonožac',
  'Psotka',
  'Pšag',
  'Pšak',
  'Pšenica',
  'Ptičar',
  'Ptičar Balajić',
  'Puač',
  'PuačMatešić',
  'Puača',
  'Pualić',
  'Pucan',
  'Pucar',
  'Pucarević',
  'Pucarin',
  'Pucarin Kojić',
  'Pudar',
  'Pudža',
  'Puđa',
  'Pufler Sajler',
  'Pugar Vajdić',
  'Puhač',
  'Puhali',
  'Puhalo',
  'Puhalović',
  'Puhanić',
  'Puhar',
  'Puharić',
  'Pukljak',
  'Puktalović',
  'Pulitika',
  'Pulver Polimac',
  'Pulja',
  'Puljak',
  'Puljek Salai',
  'Punčikar',
  'Punda',
  'Pungrčar',
  'Pupavac',
  'Pupovac',
  'Pupovac Fodor',
  'Purar',
  'Pureta',
  'Purgar',
  'Purgar Kalamari',
  'Purgarić',
  'Purkar',
  'Purkat',
  'Pustai',
  'Pustaj',
  'Pustaji',
  'Pušac',
  'Pušač',
  'Pušelja',
  'PušeljićŠimica',
  'Pušenjak',
  'Pušeta',
  'Puškadija',
  'Puškar',
  'Puškarčuk',
  'Puškarić',
  'Putar',
  'Putarić',
  'Puvača',
  'Puvalić',
  'Puzavac',
  'Pycha',
  'Qenaj',
  'Quast',
  'Rab',
  'Rab Guljaš',
  'Rabac Knezović',
  'Rabađija',
  'Rabar',
  'Rabbi',
  'Rabbi Kalman',
  'Rabel',
  'Rabi',
  'Rabić',
  'Rabl',
  'Rabljenović',
  'Raboteg',
  'Rac',
  'Rac Sabo',
  'Raca',
  'Racić',
  'Rack',
  'Racko',
  'Racz',
  'Raček',
  'Račić',
  'Račimorski',
  'Rački',
  'Računica',
  'Radačić',
  'RadačićBosilj',
  'Radak',
  'Radaković',
  'RadakovićMatovina',
  'Radaljac Jurkin',
  'Radan',
  'Radan Dukić',
  'Radanić',
  'Radanović',
  'RadanovićAmšlinger',
  'RadanovićGrgurić',
  'Radaš',
  'Radašinović',
  'Radat',
  'Rade',
  'Radeka',
  'Radelić',
  'Radeljak',
  'Radeljić',
  'Radenić',
  'Radenković',
  'Radešić',
  'Radetić',
  'Radičanin',
  'Radičev',
  'Radičević',
  'Radičić',
  'Radić',
  'RadićBenki',
  'RadićBilin',
  'RadićČuti',
  'RadićPechaček',
  'RadićPocrnić',
  'RadićStrižak',
  'Radićanin',
  'Radigović',
  'Radijevac',
  'Radiković',
  'Radin',
  'Radinčić',
  'Radinković',
  'Radinovac',
  'Radinović',
  'Radinski',
  'Radiša',
  'Radišević',
  'Radišić',
  'Radivoj',
  'Radivojac',
  'Radivojčević',
  'Radivojević',
  'Radko',
  'Radl',
  'Radl Barjaktarić',
  'Radl Humer',
  'Radlović',
  'Radljević',
  'Radman',
  'Radman Dijkgraaf',
  'Radman Meić',
  'Radmanić',
  'Radmanovac',
  'Radmanović',
  'Radmilović',
  'Radnić',
  'Rado',
  'Radočaj',
  'Radoćaj',
  'Radoja',
  'Radojčić',
  'Radojčin',
  'Radojević',
  'RadojevićBačun',
  'Radojica',
  'Radojković',
  'Radolić',
  'Radolović',
  'Radomirović',
  'Radonić',
  'RadonićVesel',
  'Radonjić',
  'Rados',
  'Radosavac',
  'Radosavljević',
  'Radoš',
  'RadošČatak',
  'Radošević',
  'Radošić',
  'Radotić',
  'Radovac',
  'Radovančević',
  'Radovanić',
  'Radovanović',
  'RadovanovićMiodragović',
  'RadovanovićPetarac',
  'Radović',
  'Radujko',
  'Radujković',
  'Raduka',
  'Radulić',
  'Radulović',
  'RadulovićBrdar',
  'Radun',
  'Radunković',
  'Radunović',
  'Radusin',
  'Radusinović',
  'Radušić',
  'Radvanji',
  'Radzira',
  'Rađa',
  'Rađan',
  'Rađenović',
  'Rafaj',
  'Rafajac',
  'Raff',
  'Rago',
  'Raguš',
  'Raguz',
  'Raguž',
  'RagužBošnjak',
  'RagužLučić',
  'RagužPelić',
  'Raich',
  'Raič',
  'RaičNowosadek',
  'Raičević',
  'Raičković',
  'Raić',
  'Raihl Kir',
  'Railić',
  'Raiz',
  'Raizner',
  'Rajačić',
  'Rajaković',
  'Rajc',
  'Rajci',
  'Rajčan',
  'Rajčanji',
  'Rajčevac',
  'Rajčević',
  'Rajčić',
  'Rajda',
  'Rajh',
  'Rajher',
  'Rajhl',
  'Rajič',
  'Rajić',
  'Rajki',
  'Rajkov',
  'Rajkov Schleininger',
  'Rajkovača',
  'Rajkovačić',
  'Rajković',
  'RajkovićŠpoljarić',
  'Rajle',
  'Rajlić',
  'Rajman',
  'Rajndl',
  'Rajndl Zubčić',
  'Rajnić',
  'Rajninger',
  'Rajnović',
  'Rajs',
  'Rajs Ažić',
  'Rajsz',
  'Rajš',
  'Rajšel',
  'Rajšić',
  'Rajtek',
  'Rajter',
  'Rajter Antić',
  'Rajter Obrovac',
  'Rajzl',
  'Rak',
  'Rak Kovač',
  'RakŠtrbac',
  'Rakanović',
  'Rakarić',
  'Rakas',
  'Rakazović',
  'Raketić',
  'Rakić',
  'Rakin',
  'Rakin Hečko',
  'Rakinić',
  'Rakipi',
  'Rakipović',
  'RakipovićStojanović',
  'Rakitić',
  'Rakitničan',
  'Rakitovac',
  'Rakmić',
  'Raknić',
  'Rako',
  'Rakocija',
  'Rakočević',
  'Rakonca',
  'Rakonić',
  'Rakovac',
  'Raković',
  'Rakušić',
  'Ralašić',
  'Ralbovski',
  'Ralević',
  'Ralić',
  'RalićPopović',
  'Rališ',
  'Rall',
  'Raljević',
  'Raljić',
  'Ramač',
  'Rambusek',
  'Ramčić',
  'Ramić',
  'Ramljak',
  'Rancinger',
  'Rančak',
  'Rančić',
  'Rančilović',
  'Randović',
  'Randunković',
  'Ranđelović',
  'Rangelov',
  'Ranisavljević',
  'Rank',
  'Ranković',
  'Ranogajac',
  'Ranogajec',
  'Ranogajec Böhm',
  'Raos',
  'Rap',
  'Rapaić',
  'Rapajić',
  'Rapan',
  'Rapčak',
  'Rapić',
  'Rasić',
  'Rasonja',
  'Raspasović',
  'Raspopović',
  'Raspor',
  'Raspudić',
  'RaspudićBrekalo',
  'Rastić',
  'Rastija',
  'Rastovac',
  'Rastović',
  'Rastovski',
  'Raščan',
  'Raščanac',
  'Raščanec',
  'Raščanin',
  'Rašeta',
  'Rašetić',
  'Rašić',
  'Raškaj',
  'Rašković',
  'Rašljanin',
  'Rašo',
  'Rašperger',
  'Raštegorac',
  'Rat',
  'Ratajac',
  'Ratajec',
  'Ratar',
  'Ratić',
  'RatićReinspach',
  'Ratinčević',
  'Ratkaj',
  'Ratkajec',
  'Ratko',
  'Ratkovčić',
  'Ratković',
  'Rattinger',
  'Rauch',
  'Raucher',
  'Raucher Josić',
  'Rauher',
  'Raumberger',
  'Rausavljević',
  'Rausch',
  'Rausnitz',
  'Rauš',
  'Raušenberger',
  'Raušević',
  'Rauški',
  'Raužan',
  'RaužanŠibalić',
  'Ravas',
  'Ravlić',
  'RavlićVrbešić',
  'Ravlija',
  'Ravnjak',
  'Razer',
  'Razić',
  'Razl',
  'Razman',
  'Razum',
  'Razumović',
  'Ražić',
  'Ražnatović',
  'Reaček',
  'Reba',
  'Rebrača',
  'Rebrina',
  'Rebrna',
  'Rebronja',
  'Rebula',
  'Rečica',
  'RečićDraksler',
  'Reder Vladić',
  'Redžep Borak',
  'Redžepagić',
  'Ređa',
  'Regajs',
  'Regeljac',
  'Rehak',
  'Rehner Samardžić',
  'Reinhardt',
  'Reinspach',
  'Reintebach',
  'Reitenbach',
  'Rekaš',
  'Reksa',
  'Relatić',
  'Relja',
  'Reljac',
  'Reljan',
  'Reljanović',
  'Remaj',
  'Remenar',
  'Remeta',
  'Remžgar',
  'Remžgar Adam',
  'Renčar',
  'RennichČajko',
  'Rennich Sabri',
  'Repac',
  'Repajić',
  'Repalust',
  'Repar',
  'Repija',
  'Repinac',
  'Repka',
  'Resan',
  'Resanović',
  'Resele Podnar',
  'Resiga',
  'Rešetar',
  'Rešetar Rudić',
  'Rešetarović',
  'Reškovac',
  'Reštar',
  'Retkovac',
  'Revald',
  'Reveland',
  'Revelant',
  'Rezo Bošnjak',
  'Režnjak',
  'Riba',
  'Ribar',
  'Ribar Fekete',
  'Ribarić',
  'RibićGreganić',
  'Rica',
  'Richard',
  'Ridgaser',
  'Rigljan Stanisavljević',
  'Rigo Blažević',
  'Rigo Katanić',
  'Rigo Mihajlović',
  'Rihard',
  'Rihart',
  'Rihtarić',
  'Rikanović',
  'Rimac',
  'RimacČota',
  'Rimac Drlje',
  'Rimai',
  'Rimaj',
  'Rimar',
  'RipićMarjanović',
  'Ristova',
  'Rittgasser',
  'Ritza',
  'Rizvanović',
  'Rkman',
  'Rnjak',
  'Robak',
  'Ročoska',
  'Rodjak',
  'Rođak',
  'Rođaković',
  'Rogač',
  'Rogalo',
  'Rogan',
  'Rogar',
  'Rogić Janković',
  'Rogina',
  'Roglić Stanković',
  'Rogoz Katić',
  'RogozŠola',
  'Rogulja',
  'Rohaček',
  'Roksandić',
  'RoksandićKrižan',
  'Roland',
  'Romac',
  'Roman',
  'Romanić',
  'Romanik',
  'Romanović',
  'Romanjek',
  'Romanjik',
  'Romštajn',
  'RončevićMarković',
  'Rončka',
  'Ronta',
  'Rora',
  'Rorbaher',
  'Rosandić',
  'Rosani',
  'RosoČajo',
  'Rostaš',
  'Roškar',
  'Roško Vrtarić',
  'Rošta',
  'Roterbauer',
  'Rothenbacher',
  'Rothenhausler',
  'Rozmajer',
  'Rozman',
  'Roža',
  'Rožac',
  'Rožajac',
  'Roždijevac',
  'Rožina',
  'Rožman',
  'Rožmarić',
  'Ruda',
  'Rudan',
  'Rudar',
  'Rudaš',
  'Rudinski Grginac',
  'Rudinski Mijatović',
  'Rudman',
  'Rudnjak',
  'Rudvald',
  'RuffŠarić',
  'Rugole Car',
  'Rugole Kocijan',
  'Rujevčan',
  'Rukavina',
  'Rukavina Dobrišek',
  'Rukovanski',
  'Rukovanjski',
  'Ruman',
  'Rumbak',
  'Rumbak Vajak',
  'RumićBogdan',
  'Rumora',
  'Rumštajn',
  'Runac',
  'Runjak',
  'Rupaner',
  'Rupar',
  'Rusan',
  'Ruseva',
  'Rusjan',
  'Rušak',
  'Rušanac',
  'Rušanoski',
  'Ruška',
  'Ruška Krljar',
  'Rušnjak',
  'Rutar',
  'Ružak',
  'Ružanić',
  'Ružička',
  'Ružman',
  'Sabadi',
  'Sabadoš',
  'Sabadžija',
  'Sabelja',
  'Sabić',
  'Sablić',
  'Sabljak',
  'Sabljar',
  'Sabljarić',
  'Sabljić',
  'Sabljić Talucci',
  'Sabljo',
  'Sabo',
  'Sabo Hamidović',
  'Sabočan',
  'Sabočanac',
  'Sabok',
  'Sabol',
  'Sabolčec',
  'Sabolčić',
  'Sabolek',
  'Sabolek Tegzeš',
  'Sabolić',
  'Sabolović',
  'Sabolski',
  'Saborčić',
  'Sabov',
  'Sabow',
  'Sabri',
  'Saček',
  'Sačer',
  'Sačer Grgić',
  'Saćer',
  'Sadaić',
  'Sadiki',
  'Sadiković',
  'Sadiku',
  'Sadlek',
  'Sadović',
  'Sadžak',
  'SadžakovićMatak',
  'Safić',
  'Safkin',
  'Saftić',
  'Sagadi',
  'Sagadin',
  'Sagaj',
  'Saher',
  'Sahula',
  'Saić',
  'Sailović',
  'Saiti',
  'Sajc',
  'Sajda',
  'Sajdl',
  'Sajenković',
  'Sajer',
  'Sajfert',
  'Sajko',
  'Sajler',
  'Sajlović',
  'Sajter',
  'Sak',
  'Sak Bosnar',
  'Sak Vitić',
  'Sakač',
  'Sakačić',
  'Sakal',
  'Sakić',
  'Sakoman',
  'Saković',
  'SakovićVajzenbach',
  'Sakradžija',
  'Saks',
  'Saksinger',
  'Salai',
  'Salaić',
  'Salaj',
  'Salaji',
  'Salajić',
  'Salak',
  'Salak Tonhajzer',
  'Salamaha',
  'Salamon',
  'Salamun',
  'Salapić',
  'Salaški',
  'Salatić',
  'Salčinović',
  'Saler',
  'Salha',
  'Salić',
  'Salihi',
  'Salihović',
  'Salijević',
  'Salimović',
  'Salinger',
  'Salitrežić',
  'Salkanović',
  'Salkić',
  'Salma',
  'Salmadić',
  'Saloki',
  'Salonja',
  'Salopek',
  'Salopek Kotromanović',
  'SalopekŽiha',
  'Saljihi',
  'Saljiji',
  'Samac',
  'Samac Katruša',
  'Samarčić',
  'Samardžić',
  'SamardžićIlić',
  'Samardžija',
  'Samardžija Momčilović',
  'Samarđić',
  'Samarđija',
  'Samaržija',
  'Sambol',
  'Sambolec',
  'Samir',
  'Samohod',
  'Samohodaj',
  'Samson',
  'Sancin',
  'Sandić',
  'Sandukčić',
  'Sanela Milanković',
  'Sanić',
  'Sanković',
  'Sanseović',
  'SanseovićZorica',
  'Santaj',
  'Santin',
  'Santini',
  'Santl',
  'Santo',
  'Santoši',
  'Santovac',
  'Santovac Livada',
  'Santrač',
  'Santrić',
  'Santruček',
  'Santucho Pejin',
  'Sanjarić',
  'Sapanjoš',
  'Saplaić',
  'Sarač',
  'Saraga',
  'Sarajlić',
  'Sarajlija',
  'Sarap',
  'Sarapa',
  'Saratlija',
  'Saravanja',
  'Sardelić',
  'Sarić',
  'SarićŠpiranović',
  'Sarijević',
  'Sarka',
  'Sarkanjac',
  'Sarkić',
  'Sarta',
  'Sarvaš',
  'Sas',
  'Sasi',
  'Sastić',
  'Satek',
  'Satler',
  'Satner',
  'Sauer',
  'Sauerborn',
  'Sauerborn Dorić',
  'Saulić',
  'Sauter',
  'Savadinović',
  'Savanović',
  'Savatić',
  'Savatović',
  'Savelić',
  'Saveljev',
  'Saveljić',
  'Saver',
  'Savevska',
  'Savevski',
  'Savičić',
  'Savić',
  'SavićDrašković',
  'SavićPayerl',
  'SavićTanacković',
  'Savićević',
  'Savin',
  'Savković',
  'Savova Peček',
  'Savović',
  'Scarpa',
  'Scarpa Bugarić',
  'Schapringer',
  'Schasche',
  'Schatzl',
  'Schatzl Bakarec',
  'Scheidsach',
  'Scheimann',
  'Schermann',
  'Schmidt Korman',
  'Schnalzer',
  'Schönhof Kovačević',
  'Schonschadowski',
  'Schornavache',
  'Schteigmajer',
  'Schuessler Mandić',
  'Schumacher',
  'Schutzbach',
  'Schwaller',
  'Schwarzl',
  'Schweizer Nađ',
  'Sebal',
  'Sebauer',
  'Sečak',
  'Sečan',
  'Sečujac',
  'Sedak',
  'Sedarević',
  'Sedlaček',
  'Sedlan',
  'Sedlan König',
  'Sedlar',
  'Sedmak',
  'Segetlija',
  'Sekač',
  'Sekalec',
  'Sekanić',
  'Sekanović',
  'Sekošan',
  'Sekovanić',
  'SekovanićCincar',
  'Sekula',
  'Selak',
  'Selak Zeljko',
  'Selaković',
  'Selan',
  'Selar',
  'Selec Grejza',
  'SelešiĐurica',
  'SelićGnjaić',
  'Seligman',
  'Selman',
  'Selmani',
  'Seljaković',
  'Seljmani',
  'Seman',
  'Semeljac',
  'Semeljac Nikolić',
  'Semialjac',
  'Semijalac',
  'Semijaljac',
  'Senaši',
  'Senčanin',
  'Senčar',
  'Senderak',
  'Senđervari',
  'Seničanin',
  'Senohrabek',
  'Senta',
  'Sentivanac',
  'Sentivanski',
  'Sentkiralj',
  'Sentkiralji',
  'Serbedžija',
  'Serdahelji',
  'Serdar',
  'Serdarević',
  'Serdarušić',
  'Seropian',
  'Seropian Orkić',
  'Ses Gado',
  'Sesar',
  'Sesvečan',
  'Sesvečan Anić',
  'Sesvećan',
  'Sevald',
  'Shala',
  'Shamonky',
  'Shaqiri',
  'Siča',
  'Sičaja',
  'Sičajmov',
  'Sičenica',
  'Sigečan',
  'Sigetac',
  'Sigetlija',
  'Sigurnjak',
  'Sijarto',
  'Sikirica',
  'Sikora',
  'Sikra',
  'Sikra Golemac',
  'Siladi',
  'Siladji',
  'Silađev',
  'Silađi',
  'Silajčik',
  'Silajdžić',
  'Silajdžija',
  'Sildajev',
  'Silika',
  'Silvaš',
  'Silvaši',
  'Siljan',
  'Siljanoska',
  'Siljanoski',
  'Simanić',
  'Simatović',
  'Simjanovski',
  'Sinanović',
  'Sinrajh',
  'Sintai',
  'Sinjaković',
  'Siracki Kopić',
  'Sireta',
  'Sirotanović',
  'SirotanovićŠipoš',
  'Sirovica',
  'Sirovina',
  'Sisan',
  'Sita',
  'Sitar',
  'Sitarić',
  'Sitaš',
  'Sivak',
  'Sjauš',
  'Skakić',
  'SkakićBegić',
  'Skala',
  'Skaličić',
  'Skandović',
  'Skelac',
  'Skeledžija',
  'Skeleđija',
  'Skenderija',
  'Skitarelić',
  'Sklabinski',
  'Sklenar',
  'SkokićAzenić',
  'SkokićVučak',
  'Skopalj',
  'Skopljak',
  'Skorupa',
  'Skorupan',
  'Skračić',
  'Skroban',
  'Skroza',
  'Skukan',
  'Skular',
  'Skupnjak',
  'Slabak',
  'Slabinac',
  'Slačanac',
  'Sladaković',
  'Slade',
  'Sladić',
  'Sladin',
  'Sladoja',
  'Sladojević',
  'Sladović',
  'Slakić',
  'Slaković',
  'Slam',
  'Slamek',
  'Slamić',
  'Slanina',
  'Slaninka',
  'Slanovic',
  'Slanovic Kuzmanović',
  'Slanović',
  'Slatina',
  'Slatinac',
  'Slatki',
  'Slaver',
  'Slavica',
  'Slaviček',
  'Slavić',
  'Slavićek',
  'Slavik',
  'Slavinac',
  'Slavković',
  'Slavnić',
  'Slavnović',
  'Slavujević',
  'Slavulj',
  'Slavuljica',
  'Slipac',
  'SliškovićBartoloti',
  'Slivac',
  'Slivar',
  'Slivka',
  'Slivonja',
  'Sloboda',
  'Slobodan',
  'Slobodjanac',
  'Slobođan',
  'Slobođanac',
  'Slogar',
  'Slokan',
  'Slonka',
  'Slonja',
  'Slota',
  'Slovaček',
  'Sluganović',
  'Slukan',
  'Slutaj',
  'Smaić',
  'Smailbegović',
  'Smajić',
  'Smajlović',
  'Smajo',
  'Smakaj',
  'Smažil',
  'Smeška',
  'Smetanin',
  'Smiljan',
  'Smiljanec',
  'Smiljanić',
  'Smješka',
  'Smodlaka',
  'Smolka',
  'Smoljak',
  'Smoljan',
  'Smoljanac',
  'Smoljanec',
  'Smoljanić',
  'Smoljanović',
  'Smrčak',
  'Smrekar',
  'Sobačić',
  'Sobonja',
  'Sočivica',
  'Sodar',
  'Softa',
  'Softa Majer',
  'Soka',
  'Sokač',
  'SokačBalog',
  'SokačConway',
  'Sokačić',
  'Sokać',
  'Sokol Konrad',
  'Sokolar',
  'SokolićBilandžić',
  'SokolićMihalak',
  'Solak',
  'Solanović',
  'Solanjski',
  'Solar',
  'Soldan',
  'Soldat',
  'Soldo Pastar',
  'Soleša',
  'Solga',
  'Solina',
  'Solona',
  'Solonja',
  'Sombathelji',
  'Somborac',
  'Somljačan',
  'Somolanji',
  'Somrak',
  'Sonnenfeld Kadlec',
  'Sontacchi',
  'Soon Han Soo',
  'Sopjanac',
  'Sopka',
  'Sopta',
  'Sosa',
  'Sotinac',
  'Sotirovska',
  'Sovar',
  'Sovina',
  'Spaček',
  'Spahić',
  'Spahija',
  'Spaić',
  'Spajić',
  'Spaqi',
  'Spasenović',
  'Spasenovska',
  'Spasenovski',
  'Spasić',
  'Spasojević',
  'Spasovski',
  'Splavski',
  'Spoljarić',
  'Sprečaković',
  'Sraga',
  'Sraka',
  'Srakić',
  'Srb Petrinšak',
  'Srbljanin',
  'Srbljanović',
  'Srdanović',
  'Srdar',
  'Srdarević',
  'Srđak',
  'Sremac',
  'Srijemac',
  'Srimac',
  'Srmljanin',
  'Srpak',
  'Srša',
  'Sršan',
  'SršićKovač',
  'Stadler',
  'Stajević',
  'Stajić',
  'Stajin',
  'Stajki',
  'Stajković',
  'Stakić',
  'Staklarević',
  'Staklenac',
  'Stakor',
  'Staković',
  'Stamatoski',
  'Stambolija',
  'Stamenić',
  'Stamenković',
  'Stamenkovski',
  'Stamičar',
  'Stanar',
  'Stanarević',
  'Stančer',
  'Stančić',
  'Stančin',
  'Stančirević',
  'Stande',
  'Stanečić',
  'Stanek',
  'Stanešić',
  'Stanglmayr',
  'Staničić',
  'Stanić',
  'StanićKmoniček',
  'StanićRešicki',
  'StanićŠtampek',
  'Stanimijić',
  'Stanimirović',
  'Stanisavljević',
  'Stanislav',
  'Staniša',
  'Stanišić',
  'Stanivuk',
  'Stanivuković',
  'Stankić',
  'Stanko',
  'Stanković',
  'StankovićKuna',
  'StankovićMarton',
  'Stanojčić',
  'Stanojević',
  'Stanojevljević',
  'Stanojlov',
  'Stanojlović',
  'StanojlovićBotić',
  'Stantić',
  'Stanulov',
  'Stanušić',
  'Stanjković',
  'Staparac',
  'Stapić',
  'Starčević',
  'StarčevićDuvnjak',
  'StarčevićMatijaško',
  'StarčevićŠkarica',
  'Starćević',
  'Starešina',
  'Starijaš',
  'Stark',
  'Staščik',
  'Stašćik',
  'Stavrić',
  'Stazić',
  'Stažić',
  'Stefan',
  'Stefankov',
  'Stefanov',
  'Stefanović',
  'Stefanovski',
  'Stefanovski Šefer',
  'Steiner Brezničar',
  'Stepan',
  'Stepanić',
  'Stepanović',
  'Sternad',
  'Stevanović',
  'Stijelja',
  'Stipac',
  'Stipan',
  'Stipančević',
  'Stipandić',
  'Stipanić',
  'Stipanov',
  'Stipanović',
  'StipićVarvodić',
  'Stivaničević',
  'Stjepan',
  'Stjepanek',
  'Stjepanić',
  'Stjepanović',
  'Stjepanovski',
  'StjepićMandić',
  'Stoisavljević',
  'Stojačić',
  'Stojadinov',
  'Stojadinović',
  'Stojak',
  'Stojaković',
  'Stojan',
  'Stojanac',
  'Stojančić',
  'Stojanov',
  'Stojanović',
  'Stojanovski',
  'Stojavić',
  'Stojisavljević',
  'StojkovićMarčetić',
  'Stojmanović',
  'StojnovićOpačić',
  'Stojsavljević',
  'Stokanov',
  'Stokanović',
  'Stokuća',
  'Stolar',
  'Stolla',
  'Strabić',
  'Stracenski',
  'Strahija',
  'Strahinec',
  'Strahinić',
  'Strahinjić',
  'Strahonja',
  'Strajinić',
  'Strajlović',
  'Strajnić',
  'Strajnović',
  'Straka',
  'Strakoš',
  'Stramput',
  'Stranatić',
  'Stranput',
  'Stranski',
  'Stranjak',
  'Strapač',
  'Strapko',
  'Strašek',
  'Straub',
  'Stravka',
  'Stražanac',
  'Stražanček',
  'Strbad',
  'Strbat',
  'Strehovac',
  'Strepački',
  'Strešnjak',
  'Strganac',
  'Strgar',
  'Strikinac',
  'Strinavić',
  'Strižak',
  'Strmota',
  'Strnad',
  'Strpčanin',
  'Strsoglavec',
  'Strugačevac',
  'Strugalović',
  'Strugar',
  'Struhak',
  'Strukar',
  'Stubičan',
  'Stubičar',
  'Stučka',
  'Stupalo',
  'Stupar',
  'Stuparić',
  'Subanović',
  'Subašić',
  'Subatić',
  'Subjak',
  'Subota',
  'Sucala Mateić',
  'Suchanek',
  'Sučija',
  'Suda',
  'Sudar',
  'Sudarević',
  'Sudarić',
  'Suhalj',
  'Suhanek',
  'Suhina',
  'Suhopoljac',
  'Suk Lamot',
  'Sukačić',
  'Sukalić',
  'SuknaićBrinzej',
  'Sulejmani',
  'Sulejmanović',
  'Sulejmanovski',
  'Sulemanoski',
  'Sulimani',
  'Suljagić',
  'Suljkanović',
  'Suman',
  'Sumarev',
  'Sumrak',
  'Sunarić',
  'Superina',
  'Sureta',
  'Surla',
  'Surota',
  'Surovac',
  'Surovčak',
  'Susetka',
  'Suša',
  'Sušac',
  'Sušac Bubić',
  'Sušac Gajić',
  'Sušak',
  'Sušanj',
  'Sušenka',
  'Sušjenka',
  'Sutarić',
  'Suvajac',
  'Suvala',
  'Suvalj',
  'Suvalj Kušter',
  'Suvaljko',
  'Svalina',
  'Svalina Seletković',
  'Svatić',
  'Svečnjak',
  'SverićPtičar',
  'Svetina',
  'Svilanović',
  'Svilar',
  'Svinjarević',
  'Svirac',
  'Svirać',
  'Svoboda',
  'Svoboda Mitoš',
  'Svorcan',
  'Svoren Uzelac',
  'Svraka',
  'Svrakačić',
  'Svrakić',
  'Svrtan',
  'Svržnjak',
  'Svržnjak Capek',
  'Szabo',
  'Szantosy',
  'Szellar',
  'Szentkiraly',
  'Szentkiralyi',
  'Szikra',
  'Szilagy',
  'Szilagyi',
  'Szilvaši',
  'Szombathelyi',
  'Šabaković',
  'Šabalić',
  'Šaban',
  'Šabanagić',
  'Šabani',
  'Šabani Ljutvi',
  'Šabanov',
  'Šabanović',
  'Šabarić',
  'Šabić',
  'ŠabićDumančić',
  'Šabjan',
  'Šac',
  'Šacl',
  'Šaćiri',
  'Šadl',
  'Šafar',
  'Šafarić',
  'Šafarik',
  'Šafran',
  'Šafranko',
  'Šafranj',
  'Šagi',
  'Šagodić',
  'Šagolj',
  'Šagovac',
  'Šahini',
  'Šahinović',
  'Šain',
  'Šainović',
  'Šajatović',
  'Šajber',
  'Šajbl',
  'Šajdler',
  'Šajer',
  'Šajfar',
  'Šajfar Knežević',
  'Šajgaji',
  'Šajgo',
  'Šajh',
  'Šajin',
  'Šajinović',
  'Šajkaš',
  'Šajkunić',
  'Šajkunović',
  'Šajn',
  'Šajnović',
  'Šajtoš',
  'Šajvan',
  'Šaka',
  'Šakač',
  'Šakanović',
  'Šakić',
  'Šakota',
  'Šakotić',
  'Šala',
  'Šalaj',
  'Šalajić',
  'ŠalajićVukić',
  'Šalamon',
  'Šalamun',
  'Šalavarda',
  'Šalavardić',
  'Šaldić',
  'Šalek',
  'Šalić',
  'Šalika Todorović',
  'Šalindrija',
  'Šalinović',
  'Šalković',
  'Šalov',
  'Šalov Jovandžikov',
  'Šalovac',
  'Šamadan',
  'Šamanović',
  'Šambić',
  'Šamija',
  'Šamonik',
  'Šamota',
  'Šamšalović',
  'Šamu',
  'Šamukić',
  'Šandor',
  'Šandorka',
  'Šandrk',
  'Šandro',
  'Šandrovčan',
  'Šangut',
  'Šanić',
  'Šanko',
  'Šanta',
  'Šantak',
  'Šantalab',
  'Šantar',
  'Šantavec',
  'Šantek',
  'Šantić',
  'Šantor',
  'Šantoši',
  'Šanjug',
  'Šapčanin',
  'Šapić',
  'Šapina',
  'Šaponja',
  'Šaponja Lekšan',
  'Šaponjić',
  'Šarabača',
  'Šarabaća',
  'Šarabok',
  'Šarac',
  'Šaravanja',
  'Šarc',
  'Šarčanin',
  'Šarčević',
  'Šarćević',
  'Šardi',
  'Šare',
  'Šarec',
  'Šarek',
  'Šarengradac',
  'Šaretović',
  'Šargač',
  'Šargavinski',
  'Šargovinski',
  'Šarić',
  'ŠarićBisatzky',
  'Šariri',
  'Šarkez',
  'Šarkezi',
  'Šarko',
  'ŠarlahČačić',
  'Šarlija',
  'Šaronić',
  'Šarović',
  'Šaruga',
  'Šarvari',
  'Šaš',
  'Šaša',
  'Šašek',
  'Šašić',
  'Šaško',
  'Šašlić',
  'Šašlin',
  'Šašlin Stipan',
  'Šašo',
  'Šašvari',
  'Šat',
  'Šatara',
  'Šatlan',
  'Šatović',
  'Šatrović',
  'Šatvar',
  'Šaula',
  'Šaulić',
  'Šavora',
  'Ščavničar',
  'Ščerbakov',
  'Ščerbakova',
  'Ščrbak',
  'Ščukanec',
  'Šćasni',
  'Šćepančević',
  'Šćetar',
  'Šćetka',
  'Šćrbak',
  'Šćukanec',
  'Šćurla',
  'Šeat',
  'Šeatović',
  'ŠeatovićWranka',
  'Šebalj',
  'Šebjanić',
  'Šegan',
  'Šegavčić',
  'Šegota',
  'Šejatović',
  'Šekerija',
  'Šekularac',
  'Šelaj',
  'Šelja',
  'Šencaj',
  'Šenica',
  'Šeničar',
  'Šepac',
  'Šeparević',
  'Šeparović',
  'Šepecan',
  'Šeperac',
  'Šepka',
  'Šerbedžija',
  'Šerbeđija',
  'Šerega',
  'Šereš Taslidžić',
  'Šerf Kardov',
  'Šerman',
  'Šeruga',
  'Šesta',
  'Šestak',
  'Šestan',
  'Šestanović',
  'Šestanj',
  'Šeša',
  'Šetka',
  'Šetula',
  'Ševarac',
  'Ševerdija',
  'Šibalić',
  'Šibila',
  'Šijačić',
  'Šijak',
  'Šijaković',
  'Šijan',
  'Šikac',
  'Šikanja',
  'Šiketanc',
  'Šikman',
  'Šilac',
  'Šilipetar',
  'Šilovinac',
  'Šiljak',
  'Šiljak Trifunović',
  'Šimac',
  'Šimak',
  'Šimanovac',
  'Šimanović',
  'Šimara',
  'Šimašek',
  'Šimatić',
  'Šimatović',
  'ŠimenićKovač',
  'ŠimetićHajberger',
  'ŠimićBogdanović',
  'ŠimićŠebalj',
  'Šimleša',
  'Šimlješa',
  'Šimonka',
  'Šimpraga',
  'Šimudvarac Travica',
  'Šimulija',
  'ŠimunićGašpar',
  'ŠimunovićUljarević',
  'Šimurda',
  'Šimurina',
  'Šinka',
  'Šipalo',
  'Šipek Kraševac',
  'Šipka',
  'Šiplika',
  'Šipoc Princhausz',
  'Šiprak',
  'Šiptar',
  'Šipura',
  'Šira',
  'Širac',
  'Širajčić',
  'Šišan',
  'ŠiškićŠpanjević',
  'Šišljagić',
  'Šišuljak',
  'Šivak',
  'Škala',
  'Škalec',
  'Škalic',
  'Škalić',
  'Škamrala',
  'Škara',
  'Škare',
  'Škarica',
  'Škarica Jovkov',
  'Škaričić',
  'Škarić',
  'Škaro',
  'Škaurin',
  'Škergat',
  'Škerlak',
  'Škeva',
  'Škiljaica',
  'Škiljan',
  'Škoda',
  'Školar',
  'Školka',
  'Školka Banović',
  'Školopija',
  'Škorak',
  'Škorjanc',
  'Škorjanec',
  'Škorvaga',
  'Škraba',
  'Škrabak',
  'Škrabić',
  'Škrapec',
  'Škrapić',
  'Škrbac',
  'Škrilac',
  'Škrinar',
  'Škrinjar',
  'Škrinjarić',
  'Škrinjarić Cincar',
  'Škrljac',
  'Škrnjug Majer',
  'Škrobar',
  'Škrpan',
  'Škulac',
  'Škuranec',
  'Škurla',
  'Škvarić',
  'Šlageter',
  'Šlang',
  'Šlanković',
  'Šlat',
  'Šlezak',
  'Šlibar',
  'Šlingar',
  'Šlogar',
  'Šlopar',
  'Šljivac',
  'Šmaguc',
  'Šmaholc',
  'Šmajdek',
  'Šmajzl',
  'Šmital',
  'Šmudla',
  'Šnajdar',
  'Šnajder',
  'Šnajder Kuburić',
  'Šnajkart',
  'Šnalcer',
  'Šnauc',
  'Šnurkar',
  'Šobačić',
  'Šobak',
  'Šoban',
  'Šoban Božić',
  'Šobar',
  'Šobat',
  'Šobota',
  'Šodolovac',
  'Šoja',
  'Šoja Rikert',
  'Šojat',
  'Šokac',
  'Šokčić Matković',
  'Šokman',
  'Šola',
  'Šolaja',
  'Šolak',
  'Šolak Petković',
  'Šolta',
  'Šolja',
  'Šoljan',
  'Šoma',
  'Šomođvarac',
  'Šomoljanski',
  'Šomorja',
  'Šoponjaj',
  'Šorak',
  'Šorda',
  'Šorman',
  'Šormaz',
  'Šormaz Mitrović',
  'Šorša',
  'Šostar',
  'Šostarec',
  'Šostarko',
  'Šoša',
  'Šoštar',
  'Šoštarec',
  'Šoštarek',
  'Šoštarić',
  'ŠoštarićVarga',
  'Šoštarko',
  'Šota',
  'Šotanji',
  'Šotman',
  'Šotra',
  'Šovag',
  'Šovagović',
  'Šovakov',
  'Špac',
  'Špajh',
  'Špajzer',
  'Špalat',
  'Špalj',
  'Špandler',
  'Španiček',
  'Španić',
  'Španiel',
  'Španović',
  'Španjević',
  'ŠpanjevićBukarica',
  'Španjić',
  'Šparavec',
  'Špehar',
  'Špelac',
  'Šperac',
  'Šperanda',
  'Šperjak',
  'Špernoga',
  'Špernjak',
  'Špica',
  'Špičak',
  'Špika',
  'Špika Polić',
  'Špiljak',
  'Špionjak',
  'Špionjak Marić',
  'Špiranec',
  'Špiranović',
  'ŠpiranovićKanižaj',
  'Šplajt',
  'Špoljar',
  'Špoljar Bosanac',
  'Špoljarec',
  'Špoljarević',
  'ŠpoljarevićTurk',
  'Špoljarić',
  'ŠpoljarićJelić',
  'ŠpoljarićPerhat',
  'ŠpoljarićZlatić',
  'Špoljarinec',
  'Šprajcer',
  'Špralja',
  'Špringer Kovačević',
  'Špringer Margetić',
  'Špringman',
  'Šrajber',
  'Šrajer',
  'Šrajer Hacker',
  'Šrajner',
  'Šram',
  'Šramek',
  'Štaba',
  'ŠtabaŽulj',
  'Štabek',
  'Štadler',
  'Štagljar',
  'Štainer',
  'Štajdohar',
  'Štajduar',
  'Štajduhar',
  'Štajenbrikner',
  'Štajerac',
  'Štajfer',
  'Štajhofer',
  'Štajmaher',
  'Štajmec',
  'Štajnbrikner',
  'Štajnbrinker',
  'Štajner',
  'Štajner Mehičić',
  'Štajnfeld',
  'Štakić',
  'ŠtakićBruić',
  'Štakor',
  'Štalman',
  'Štamberger',
  'Štambuk',
  'Štampalija',
  'Štampar',
  'Štampek',
  'Štampf',
  'Štampfer',
  'Štancer Radić',
  'Štanfel',
  'Štangl',
  'Štargl',
  'Štark',
  'Štasni',
  'Štasny',
  'Štaub',
  'Štaubringer',
  'Štefan',
  'Štefan Ivanišić',
  'Štefan Stipić',
  'Štefanac',
  'Štefančić',
  'Štefanec',
  'Štefanek',
  'Štefanić',
  'Štefanko',
  'Štefanović',
  'Štefičar',
  'Štegmajer',
  'Štembergar',
  'Štepanko',
  'Šteta',
  'Štetka',
  'Štifan',
  'Štimac',
  'Štimac Glaser',
  'Štimac Popić',
  'Štirjan',
  'Štoka',
  'Štokan',
  'Štokbauer',
  'Štola',
  'Štolba',
  'Štolfa',
  'Štorat',
  'Štrajbl',
  'Štrak',
  'Štrak Grgić',
  'Štrasser',
  'Štraub',
  'Štraus',
  'Štrbac',
  'Štrbac Tišma',
  'Štrbenac',
  'Štrekelj Pađen',
  'Štriga',
  'Štrigar',
  'Štrkalj',
  'Štroliga',
  'Štromar',
  'Štuka',
  'Štula',
  'Štulac',
  'Štulina',
  'Šturman',
  'Šuba',
  'Šubara',
  'Šubarić',
  'Šubaša',
  'Šućura',
  'Šuflaj',
  'Šugar',
  'Šugarić',
  'Šuica',
  'Šujica',
  'Šukunda',
  'Šukurma',
  'Šulava',
  'Šulhof Jagodić',
  'Šulina',
  'Šulmajster',
  'Šulovnjak',
  'Šuljak',
  'Šuman',
  'Šumanovac',
  'Šumanović',
  'Šumar',
  'Šumiga',
  'Šumrada',
  'Šunalić',
  'Šunda',
  'Šundalić',
  'Šuniga',
  'Šunjara',
  'Šunjerga',
  'Šupljika',
  'Šuprina',
  'Šuprna',
  'Šura',
  'Šurina',
  'Šurjan',
  'Šurlan',
  'Šustar',
  'Šusterajter',
  'Šuša',
  'Šušak',
  'Šušak Bagarić',
  'Šušnjar',
  'Šušnjara',
  'Šuštar',
  'Šuta',
  'Šutak',
  'Šutak Kosić',
  'Šutalo',
  'Šutalo Ibrahimpašić',
  'Šuvak',
  'Šuvakov',
  'Šuvaković',
  'Šuvarić',
  'ŠuveljakŽuljević',
  'Švab',
  'Švabe',
  'Švabel',
  'Švabelj',
  'Švabić',
  'Švacov',
  'Švaganović',
  'Švagelj',
  'Švager',
  'Švajbert',
  'Švajcer',
  'Švajger',
  'Švajgert',
  'Švajhofer',
  'Švajnbert',
  'Švaljek',
  'Švarc',
  'Švarcer',
  'Švarcmajer',
  'Švarić',
  'Švegar',
  'Švenda',
  'Švetak',
  'Švigač',
  'Švonja',
  'Švraka',
  'Tabaček',
  'Tabain',
  'Tabak',
  'Tabaković',
  'Tabor',
  'Tabori',
  'Taborin',
  'Tabula',
  'Tacko',
  'Tačković',
  'Tadej',
  'Tadić',
  'TadićĆavar',
  'TadićMatić',
  'Tadija',
  'Tadijan',
  'Tadijanov',
  'Tadijanović',
  'Tagliber',
  'Tagliber Modrić',
  'Tahić',
  'Tahiri',
  'Tahirović',
  'Tahler',
  'Tahtler',
  'Tairi',
  'Tajfl',
  'Tajhman',
  'Tajhman Polgar',
  'Tajić',
  'Tajmer',
  'Tajs',
  'Tajz',
  'Takacz',
  'Takač',
  'Takać',
  'Takalić',
  'Takaro',
  'Takov',
  'Talafuk',
  'Talaja',
  'Talajić',
  'Talan',
  'Talapka',
  'Talapko',
  'Talas',
  'Talavanić',
  'Taler',
  'Taletović',
  'Talić',
  'Talijan',
  'Talijanac',
  'Taloš',
  'Talpai',
  'Talvizer',
  'Tamaš',
  'Tambolaš',
  'Tame',
  'Tame Ivaković',
  'Tamindžić',
  'Tanacković',
  'Tanasić',
  'Tanasijević',
  'Tanasković',
  'Tanazović',
  'Tančak',
  'Tančik',
  'Tandara',
  'Tandarić',
  'Tanevska',
  'Tanevski',
  'Tanić',
  'Tankosić',
  'Tanocki',
  'Tanović',
  'Tanurdžić',
  'Tanžera',
  'Tanjer',
  'Tanjga',
  'Tapšanj',
  'Tapšanji',
  'Tar',
  'Taraba',
  'Tarabarić',
  'Taradan',
  'Taradi',
  'Tarakčija',
  'Tarandek',
  'Tarandžić',
  'Taranić',
  'TaranićVagner',
  'Taraš',
  'Tarbuk',
  'TarbukČivić',
  'Tarcal',
  'Tardik',
  'Tarđanin',
  'Targuš',
  'Tariba',
  'Tarle',
  'Tarlošy',
  'Tarnai',
  'Tarnaj',
  'Tarodi',
  'Tasić',
  'Taslak',
  'Taslidžić',
  'Tasovski',
  'Tassone',
  'Tašković',
  'Tatai',
  'Tataj',
  'Tataji',
  'Tatalović',
  'Tatar',
  'Tatarević',
  'Tatarin',
  'Tatarko',
  'Tatarović',
  'Tatić',
  'Tatomir',
  'Tatomirović',
  'Taus',
  'Taušan',
  'Tauzović',
  'Tavas',
  'Taves',
  'Tavić',
  'Tavra',
  'Tavrić',
  'Tegeltija',
  'Tekavčić',
  'Telak',
  'Telarović',
  'Telebar',
  'Telenta',
  'Telišman Krha',
  'Temaj',
  'Teneta',
  'Tenžera',
  'Teofanović',
  'Tepavac',
  'Ternaj',
  'Teskač',
  'Teskera',
  'Tesla',
  'Tešanac',
  'Tešankić',
  'Tešanov',
  'Tešanović',
  'Tešija',
  'Tettmann',
  'Teufel Damjanov',
  'Tevanović',
  'Than',
  'Thellman',
  'Thiel Manfred',
  'Thomajer',
  'Thomas Majdenić',
  'Tiban',
  'Tibinac',
  'Tibljaš',
  'Tibor Kovač',
  'Tiborc Juriša',
  'Tica',
  'Tičac',
  'Tičak',
  'Tićak',
  'Tidlačka',
  'Tiervald',
  'Tierwald',
  'Tigandžin',
  'Tijan',
  'Tijanić',
  'Tijardović',
  'Tikas',
  'Tilman',
  'Tilman Crnov',
  'Timar',
  'Timarac',
  'Timarić',
  'Tinaher',
  'Tipura',
  'Tirvald',
  'Tirvald Vrhovski',
  'Tisai',
  'Tisaj',
  'Tisanić',
  'Tissauer',
  'Tišljar',
  'Tišljarić',
  'Tišma',
  'Tišma Radmanić',
  'Tivadar',
  'Tivanovac',
  'Tkačuk',
  'Tkalac',
  'Tkalčec',
  'Tkalčević',
  'Tkalčić',
  'TkalčićDulić',
  'Tkalec',
  'Tkalec Povoljnjak',
  'Tobijas',
  'Tobijaš',
  'Todanović',
  'Todorovska',
  'Togonal',
  'Tojaga',
  'Tojagić',
  'Tojzan',
  'Toka',
  'Tokai',
  'Tokalić',
  'TokićMajstorović',
  'Tolmačević',
  'Tolnai',
  'Tolnaj',
  'TolušićLacković',
  'Toljan',
  'Toljanić',
  'Tomac',
  'Tomaić',
  'Tomakić',
  'Tomaković',
  'Tomala',
  'Toman',
  'Tomanček',
  'Tomanić',
  'Tomanović',
  'Tomas',
  'Tomasić',
  'Tomasino',
  'Tomasović',
  'Tomasy',
  'Tomaš',
  'Tomašegović',
  'Tomašek',
  'Tomašev',
  'Tomašević',
  'TomaševićGregorović',
  'TomaševićRigo',
  'Tomaši',
  'Tomašić',
  'Tomašićević',
  'Tomaško',
  'Tomašković',
  'Tomaz',
  'TomićGotal',
  'TomićMartinović',
  'TomićObrdalj',
  'TomićSablić',
  'Tominac',
  'Tomka',
  'Tomljanović',
  'Tompa',
  'Tomrecaj',
  'Tončalek',
  'Tonkovac',
  'Topal',
  'Topalić',
  'Topalov',
  'Topalović',
  'Toplak',
  'Topolovac',
  'Topolovčan',
  'Torbarina',
  'Torbica',
  'Tordinac',
  'Torijanac',
  'Torjanac',
  'Torlaković',
  'Torloina',
  'Tormaš',
  'Toskova',
  'Toša',
  'Toša Berečić',
  'Tošanović',
  'Tot Antal',
  'Tot Kaša',
  'Tot Marčik',
  'Tota',
  'Totaj',
  'Totarvaj',
  'Toth Tucak',
  'TotićMatess',
  'Totman',
  'Tovarloža',
  'Tovarović',
  'Tovjanin',
  'Trac',
  'Traffa',
  'Traic',
  'Traičevska',
  'Traičevski',
  'Trajanoski',
  'Trajanović',
  'Trajanovski',
  'Trajber',
  'Trajković',
  'Traljić',
  'Tram',
  'Tramišak',
  'Tramošljanin',
  'Tramposch',
  'Trampus',
  'Tramšek',
  'Trančar',
  'Tratnjak',
  'Trauber',
  'Travančić',
  'Travica',
  'Trazer',
  'Trbara',
  'Trboglav',
  'Trčak',
  'Trčak Ocvirek',
  'Trenka',
  'Trepač',
  'TrepšićBašić',
  'Tresiglavić',
  'Treskanica',
  'Treskavica',
  'Tresoglavić',
  'Tretinjak',
  'Tribuljak',
  'Tripolski Kovačević',
  'Triva',
  'Trivanović',
  'Trkulja',
  'Trlaja',
  'Trlajić',
  'Trnka',
  'Trnjanov',
  'Trobonjača',
  'Troha',
  'Trojan',
  'Trplan',
  'Trputac',
  'Trstenjački',
  'Trstenjak',
  'Trtanj',
  'Trtica',
  'Trubelja',
  'Trubeljak',
  'Truhan',
  'Truhar',
  'Trupeljak',
  'Trupina',
  'Trvalovski',
  'Tuba',
  'Tubanović',
  'Tubanski',
  'Tubanjski',
  'Tucak',
  'Tucak Roguljić',
  'Tucaković',
  'Tučanac',
  'Tučkar',
  'Tudaković',
  'Tudja',
  'Tuđan',
  'Tuđman',
  'Tukara',
  'Tukera',
  'Tuksar',
  'Tulla',
  'Tuma',
  'Tuma Pavlov',
  'Tumbas',
  'Tumpa',
  'Tunanović',
  'Tunkova',
  'Tunja',
  'Tupeljak',
  'Turajlić',
  'Turalija',
  'Turčan',
  'Turda',
  'Turibak',
  'Turičar',
  'Turina',
  'Turina Lončar',
  'Turinski Lazić',
  'Turjak',
  'TurjakČebohin',
  'Turkalj',
  'Turkalj Topal',
  'TurkovićGibanjek',
  'Turnadžić',
  'Turšćak',
  'Turza',
  'Tustonja',
  'Tuškan',
  'Tuškanović',
  'Tušla',
  'Tuta',
  'Tutavac',
  'Tuzlak',
  'Tuzlak Kurtović',
  'Tvarog',
  'Tvrdoreka',
  'Tvrdorjeka',
  'Ubavić',
  'Ubavin',
  'Ucal',
  'Udarević',
  'UdovićLazarević',
  'Udvardi',
  'Udvari',
  'Ugarak',
  'Ugarčić',
  'UgarčićHardi',
  'Ugarković',
  'UgarkovićBiškup',
  'Uglarik',
  'Ugljanac',
  'Ugljar',
  'Ugljarević',
  'Uglješa',
  'Ugrai',
  'Ugrica',
  'Ugrina',
  'Ujhazi',
  'Ujvari',
  'Ujvari Čeh',
  'Ujvary Cseh',
  'Ukaj',
  'Ukmar',
  'Ukropina',
  'UlakovićVojinović',
  'Ulanicki',
  'Uldrijan',
  'Ulman',
  'Uljarević',
  'Umiljanović',
  'Umolac',
  'Unčanin',
  'Ungar',
  'Unterajner',
  'Uranić',
  'Uranjek',
  'Uranjek Varga',
  'Urban',
  'Urban Hering',
  'Urbanek',
  'Urbanović',
  'Urbanovski',
  'Urbanovsky',
  'Urmanac',
  'Ursa',
  'UrsićMolnar',
  'Uršanić',
  'Urtajl',
  'Urukalo',
  'Ustalić',
  'Ušak',
  'Ušćebrka',
  'Uštar',
  'Uštipak',
  'Utješanović',
  'UtrobičićRanđelović',
  'Uvalić',
  'Uvanović',
  'Uzelac',
  'Užanicki',
  'Užanicky',
  'Užanitski',
  'Užar',
  'Užarević',
  'UžarevićTodorović',
  'Vaci',
  'Vaci Lajoš',
  'Vacić',
  'Vacka',
  'Vačora',
  'Vačora Sapanjoš',
  'Vadas',
  'Vadaski',
  'Vadla',
  'Vadlja',
  'Vadnjak',
  'Vađon',
  'Vagenhofer',
  'Vagner',
  'Vagroš',
  'Vahala',
  'Vahl',
  'Vaht',
  'Vaip',
  'Vajagić',
  'Vajak',
  'Vajda',
  'Vajda Mlinaček',
  'Vajdička',
  'Vajdić',
  'Vajdlih',
  'Vajgert',
  'Vajić',
  'Vajing',
  'Vajlandić',
  'Vajler',
  'Vajn',
  'Vajnand',
  'Vajnberger',
  'Vajngerl',
  'Vajngli',
  'Vajnper',
  'Vajs',
  'Vajsenbek',
  'Vajtner',
  'Vajzenbach',
  'Vajzenbac lić',
  'Vajzer',
  'Vakanjac',
  'Vakareskov',
  'Vakoš',
  'Vakula',
  'Val',
  'Valašek',
  'Valcer',
  'Valdec',
  'Valdin',
  'Valečić',
  'Valek',
  'Valenčak',
  'Valenčić',
  'ValenčićBrkić',
  'Valent',
  'Valenta',
  'Valentak',
  'Valenteković',
  'Valentić',
  'ValentićButković',
  'ValentićJasnić',
  'ValentićVukadinović',
  'Valentin',
  'Valentinčić',
  'Valer',
  'Valeri',
  'Valerie',
  'Valešić',
  'Valetić',
  'Valić',
  'ValićMarjanac',
  'Validžić',
  'ValidžićHalak',
  'Valiđić',
  'Valinčić',
  'Valkai',
  'Valkovac',
  'Valković',
  'Valo',
  'Valović',
  'Valpovac',
  'Valter',
  'Valušek',
  'Valjak',
  'ValjakŠtrbac',
  'Valjan',
  'Valjavec',
  'Valjetić',
  'Valjin',
  'Valjkovac',
  'Vampatić',
  'Vampola',
  'Vampovac',
  'Vampula',
  'Vampula Larma',
  'Van',
  'Van Der Linde',
  'Van Genderen',
  'Van Hemert',
  'Van Meijeren',
  'Vancaš',
  'Vanče',
  'Vanček',
  'Vančevski',
  'Vančić',
  'Vančura',
  'Vandić',
  'Vandža',
  'Vanđa',
  'Vanek',
  'Vanevska',
  'Vanevski',
  'Vangelovska',
  'Vangelovski',
  'Vaniček',
  'Vanišević',
  'VanišićPanico',
  'Vanko',
  'Vanković',
  'Vanstone Josipović',
  'Vanstone Mrdeša',
  'Vanjek',
  'Vanjevac',
  'Varadi',
  'Varaždinac',
  'Varda',
  'Vardić',
  'Vareha',
  'Varenica',
  'Varenina',
  'Varešanović',
  'Vareško',
  'Varevac',
  'Varezić',
  'Varga',
  'Varga Betz',
  'Varga Kojić',
  'Varga Kučić',
  'Varga Ljubić',
  'Varganović',
  'Vargaš',
  'Vargek',
  'Vargić',
  'Vargović',
  'Varhold',
  'Varičak',
  'Varjačić',
  'Varkonji',
  'Varlam',
  'Varmuža',
  'Varnai',
  'Varnica',
  'Varošanac',
  'Varoščić',
  'Varšava',
  'Vartušek',
  'Varvir',
  'Varvodić',
  'Varzić',
  'VarzićPavković',
  'Varžić',
  'VaržićBiuklia',
  'VaržićBiuklija',
  'Vasić',
  'VasićĐokić',
  'Vasilić',
  'Vasilijević',
  'Vasilj',
  'Vasiljev',
  'Vasiljević',
  'Vasiljevska',
  'Vasiljevski',
  'Vasko Osim',
  'Vaskrsić',
  'Vasquez',
  'Vastl',
  'Vasung',
  'Vaš',
  'Vašadi',
  'Vašalić',
  'Vašanda',
  'Vašarević',
  'Vašarhelji',
  'Vašaš',
  'Vašek',
  'Vaška',
  'Vaškaj',
  'Vaštag',
  'Vatavuk',
  'Vatovec',
  'Vaupotić',
  'Vavan',
  'Vavetić',
  'Vavidec',
  'Vavra',
  'Vavrikov',
  'Vavroš',
  'Vavžek',
  'Vavžik',
  'Vazdar',
  'Važanić',
  'Važić',
  'Včeva',
  'Vdoviak',
  'Vdovjak',
  'Večanin',
  'Večenaj',
  'Večerina',
  'Vedernjak',
  'Vegar',
  'Vehabović',
  'Veizenbach',
  'Veka',
  'VekićKljaić',
  'Vela',
  'Velat',
  'Veledar',
  'Veličan',
  'Velikanović',
  'Velikinac',
  'Veljanov',
  'Veljanova',
  'Veljanović',
  'Veman',
  'Vemenac',
  'Venžera',
  'Veraja',
  'Verhas',
  'Verhas Alt',
  'Verhaz',
  'Veriga',
  'Verinac',
  'Verkman',
  'Vernački',
  'Vernath',
  'Veršanski',
  'Verzak',
  'VesanovićDvornik',
  'VeselicaČatipović',
  'Veselovac',
  'Vestemar',
  'Vešara',
  'Vešligaj',
  'Veverka',
  'Vezaj',
  'Vezmar',
  'Vezmarović',
  'Viandt',
  'Vice Trlaja',
  'Vichta',
  'Vida',
  'Vidaček',
  'Vidačić',
  'Vidak',
  'Vidakovic',
  'Vidaković',
  'VidakovićBošnjak',
  'VidakovićRomanić',
  'Vidakušić',
  'Vidanec',
  'Vidanović',
  'Vidas',
  'Videčak',
  'Videćak',
  'Viderščak',
  'Videršćak',
  'Vidlak',
  'Vidmar',
  'Vidosavljević',
  'VidošićStrugačevac',
  'VidovićMikac',
  'Vidra',
  'Vidrač',
  'Vidranski',
  'VidranskiŠkorić',
  'Vidranjski',
  'Viduka',
  'Vigand',
  'Viharev Balaić',
  'Vihovanec',
  'Vihta',
  'Vijačkić',
  'Vijant',
  'Vijevac',
  'Vila',
  'Vilagoš',
  'Vilajtović',
  'Viland',
  'Vilanj',
  'Vilenica',
  'Vileta',
  'Viljanac',
  'Viljevac',
  'Vinaj',
  'Vinarić',
  'Vinceljak',
  'Vincenc Spasovski',
  'VincetićSkroban',
  'Vinčak',
  'VinkeševićRadić',
  'Vinketa',
  'Vinogradac',
  'Vinogradac Andraković',
  'Viola',
  'Viola Franz',
  'Virag',
  'ViragŠandor',
  'VirgejĐurašević',
  'Virovac',
  'Visak',
  'Vislavski',
  'Višekruna',
  'Vištica',
  'Vitanović',
  'Vitas',
  'Vitman',
  'Vittman',
  'Viviora',
  'Viviora Rous',
  'Vivjora',
  'Vizentaner',
  'Vizentaner Lendić',
  'Vizjak',
  'Vještica',
  'Vladetić',
  'Vladić',
  'Vladika',
  'Vladimir',
  'Vladisavljević',
  'Vladova',
  'Vladović',
  'Vladušić',
  'Vlah',
  'Vlahek',
  'Vlahinja',
  'Vlaho',
  'Vlahović',
  'Vlahušić',
  'Vlaičević',
  'Vlainić',
  'VlainićVorih',
  'Vlaisavljević',
  'Vlajčić',
  'Vlajić',
  'Vlajinić',
  'Vlajkov',
  'Vlajnić',
  'Vlajsović',
  'Vlaović',
  'Vlasalija',
  'Vlastelić',
  'Vlašić',
  'Vlaškalić',
  'Vlaškovac',
  'Vlatković',
  'Vočanec',
  'Voćanec',
  'Vodanović',
  'Vodeničar',
  'Vodopija',
  'Vođinac',
  'VogronićOpačić',
  'Vojaković',
  'VojakovićFingler',
  'Vojanić',
  'Volak',
  'Volar',
  'Volarev',
  'Volarević',
  'Volarić',
  'Volfand',
  'Vončina',
  'Vondra',
  'Vondrak',
  'Vonjak',
  'Vorkapić',
  'Vosab',
  'Vošanović',
  'Vovra',
  'Vrabac',
  'Vrabec',
  'Vrabel',
  'Vračar',
  'Vračarić',
  'Vračević',
  'Vračić',
  'Vrag',
  'Vragolović',
  'Vragović',
  'Vrajić',
  'Vran',
  'Vranaričić',
  'Vrančić',
  'Vrandečić',
  'Vraneš',
  'Vranešević',
  'Vranešić',
  'Vranić',
  'Vranka',
  'Vrankić',
  'Vranković',
  'Vranović',
  'Vranješ',
  'VranješBrković',
  'VranješMiličić',
  'VranješMilošević',
  'Vranješević',
  'Vranjić',
  'Vranjković',
  'VranjkovićĆenan',
  'VranjkovićKnežević',
  'Vrapčenjak',
  'Vratarić',
  'Vratović',
  'Vrban',
  'Vrban Ružić',
  'Vrbanac',
  'Vrbanc',
  'Vrbanec',
  'Vrbanek',
  'Vrbanić',
  'Vrbanus',
  'Vrbanjac',
  'Vrbas',
  'Vrbaslija',
  'Vrbaški',
  'Vrbica',
  'Vrca',
  'Vrcan',
  'Vrdoljak',
  'Vrđuka',
  'Vrebac',
  'Vrekalić',
  'Vrekalović',
  'Vrga',
  'Vrhar',
  'Vrhovac',
  'Vrkljan',
  'Vrlika',
  'Vrlja',
  'Vrljičak',
  'Vrpoljac',
  'Vrsajković',
  'Vrselja',
  'Vrtačnik',
  'Vrtar',
  'Vrtarić',
  'VrtarićDubrović',
  'VrtarićSabljo',
  'Vrućina',
  'Vučak',
  'Vučak Grunbaum',
  'Vučanović',
  'Vučevac',
  'Vučkovac',
  'Vudrag',
  'Vudrag Buljević',
  'Vudrak',
  'VugdelijaŽižić',
  'Vuglač',
  'Vuica',
  'Vujaklija',
  'Vujaklija Crnomarković',
  'Vujaković',
  'Vujanac',
  'Vujanić',
  'Vujanović',
  'Vujasenović',
  'Vujasin',
  'Vujasinović',
  'Vujatović',
  'Vujeva',
  'Vujica',
  'Vujnovac',
  'Vuka',
  'Vukadin',
  'Vukadin Markulinčić',
  'Vukadin Romić',
  'Vukadinović',
  'Vukajlić',
  'Vukajlija',
  'Vukajlović',
  'Vukalović',
  'Vukaljević',
  'Vukanović',
  'Vukas',
  'Vukasović',
  'VukasovićLončar',
  'VukasovićPejković',
  'Vukašinac',
  'Vukašinec',
  'Vukašinović',
  'Vukelja',
  'Vukman',
  'Vukmanić',
  'Vukmanov',
  'Vukmanović',
  'Vukmirica',
  'Vukobradović',
  'Vukobrat',
  'Vukobratović',
  'Vukoja',
  'Vukoja Matanović',
  'Vukoja Pavličić',
  'Vukojevac',
  'VukojevićBabić',
  'VukojevićTomašić',
  'Vukomanović',
  'Vukosav',
  'Vukosavljević',
  'Vukovac',
  'VukovićDijanović',
  'VukovićMihalić',
  'Vukovljak Kralj',
  'Vuksan',
  'Vuksanić',
  'VuksanićPuljek',
  'Vuksanović',
  'VukšićMihaljević',
  'Vulama',
  'Vuleta',
  'Vuleta Blažević',
  'Vunak',
  'Vunjak',
  'Vuradin',
  'Vurdelja',
  'Wagner',
  'Waldman',
  'Walkiewicz',
  'Walleitner',
  'Waller',
  'Walter',
  'Walz',
  'Walzak',
  'Washington',
  'Wastl',
  'Wayn',
  'Wdoviak',
  'Wdowiak',
  'Wedral',
  'Weigand',
  'Weinand',
  'Werkmann',
  'Wershansky',
  'Wertag',
  'Westermajer',
  'Wiedman',
  'Wiessentaner',
  'Wilhelm Vrbanić',
  'Wilhem Arsić',
  'Wolf Tucaković',
  'Yahia',
  'Yakub',
  'Ylmaz',
  'Zabjan',
  'Zabludski',
  'Zaboš',
  'Zabrdac',
  'Začek',
  'Zadković',
  'Zadnik',
  'Zadol',
  'Zadravec',
  'Zadrić',
  'Zadro',
  'Zafred',
  'Zagorac',
  'Zagorčak',
  'Zagorec',
  'Zagorščak',
  'Zagoršćak',
  'Zagoršek',
  'Zagrajski',
  'Zagvozda',
  'Zagvozda Grahovac',
  'Zaharčev',
  'Zaharov',
  'Zahatek',
  'Zahiri',
  'Zahirović',
  'ZahirovićPoslon',
  'Zahiti',
  'Zahn',
  'Zahora',
  'Zaibet',
  'Zaić',
  'Zajac',
  'Zajačko',
  'Zajček',
  'Zajec',
  'Zajic',
  'Zajic Stojanović',
  'Zajkić',
  'Zajović',
  'Zakany',
  'Zakanj',
  'Zakanji',
  'Zake',
  'Zakić',
  'Zaklan',
  'Zaklanac',
  'Zaklanović',
  'Zaković',
  'Zakšek',
  'Zakupski',
  'Zalaj',
  'Zalaji',
  'Zalar',
  'Zalezina',
  'Zaleznik',
  'Zaležak',
  'Zalogar',
  'Zaloscer',
  'Zalović',
  'Zamaklar',
  'Zambo',
  'Zamečnik',
  'Zamolinski',
  'Zamulinski',
  'Zamuljinski',
  'Zandt',
  'Zaneti',
  'Zaneti Suzić',
  'Zanoški',
  'Zanze',
  'Zanjaši',
  'Zaoral',
  'Zapalac',
  'Zaputil',
  'Zaračko',
  'Zaradić',
  'Zarecki',
  'Zarev',
  'Zarić',
  'Zarka',
  'Zarli',
  'Zaspan',
  'Zaterini',
  'Zatezalo',
  'Zatko',
  'Zattherini',
  'Zavacki',
  'Zavada',
  'Zavagni',
  'Zavišić',
  'Zavođa',
  'Zavor',
  'Zavorski',
  'Završki',
  'Zbanatski',
  'Zbodulja',
  'Zdarilek',
  'Zdelar',
  'Zdelar Anočić',
  'Zdelar Ivić',
  'Zdeličan',
  'Zdenčan',
  'Zdenčanović',
  'Zdilar',
  'Zdjelar',
  'Zdjelar Czigler',
  'Zdjelar Jusup',
  'Zdjelarević',
  'Zdravčević',
  'ZdravčevićVrkić',
  'Zdravković',
  'Zeba',
  'Zec Serdar',
  'Zechmajster',
  'Zegnal',
  'Zekan',
  'ZekićSušac',
  'Zeko Pivač',
  'Zelenika',
  'Zelenka',
  'Zelenjak',
  'ZelićŽeravica',
  'Zelinac',
  'Zeljak',
  'Zeman',
  'Zemljak',
  'Zemljak Spechtenhauser',
  'Zenhausern',
  'Zenuta',
  'Zetaić',
  'Zetajić',
  'Zezula',
  'Zglavnik',
  'Zgonjanin',
  'Zgrablić',
  'Zibar',
  'Zidar',
  'Zidar Milić',
  'Zidarević',
  'Zidarić',
  'Zijan',
  'Zima',
  'Zimaj',
  'Zimmerman',
  'Zimmermann',
  'Zimonja',
  'Zinaić',
  'ZinaićBlažinkov',
  'Zinajić',
  'Zita',
  'Zjakić',
  'Zjalić',
  'Zlatanović',
  'Zlatar',
  'Zlatarek',
  'Zlatarević',
  'Zlatarić',
  'Zlatić',
  'ZlatićKavgić',
  'Zlatković',
  'Zlatović',
  'Zlatunić',
  'Zlokapa',
  'Zlokolica',
  'Zlosa',
  'Zlovarić',
  'Zmaić',
  'Zmajić',
  'Zmajšek',
  'Znaor',
  'Znika',
  'Zobenica',
  'Zobundžija',
  'Zolaj',
  'Zolaji',
  'Zolota',
  'Zoraj',
  'Zoraja',
  'Zorbas',
  'Zorbaz',
  'Zorica',
  'Zorman',
  'Zoroja',
  'Zovak',
  'Zovo Susak',
  'Zrakić',
  'Zrinščak',
  'Zrmanjac',
  'Zrna',
  'Zrno Dimnjašević',
  'Zuanović',
  'Zubac',
  'Zubac Ištuk',
  'Zubaj',
  'Zubak',
  'Zuban',
  'Zubonja',
  'Zukan',
  'Zulbari',
  'Zundanović',
  'Zurak',
  'Zurapović',
  'Zuščak',
  'Zušćak',
  'Zuzjak',
  'Zuzjak Novak',
  'Zvanić',
  'Zvardon',
  'Zvekan',
  'Zvekanov',
  'Zvekanović',
  'Zveržina',
  'Zvocak',
  'Zvonar',
  'Zvonarek',
  'Zvonarević',
  'Zvonarić',
  'Žabarović',
  'Žabčić',
  'Žabić',
  'Žaček',
  'Žada',
  'Žadanji',
  'Žagač',
  'Žagar',
  'Žagarac',
  'Žagarova',
  'Žago',
  'Žaja',
  'Žajdlik',
  'Žajlik',
  'Žaki',
  'Žaknić',
  'Žakula',
  'Žalac',
  'Žalako',
  'Žalik',
  'Žalonkin',
  'Žampak',
  'Žanić',
  'Žanija',
  'Žanko',
  'Žaper',
  'Žaper Radičević',
  'Žarak',
  'Žarić',
  'Žarko',
  'Žarković',
  'ŽarkovićMaršal',
  'Ždrale',
  'Žeđa',
  'Žegarac',
  'Žegura Schonberger',
  'Želka',
  'Željeznak',
  'Željeznjak',
  'Žemla',
  'Žerav',
  'Žeravica',
  'Žeravica Lang',
  'Žerjav',
  'Žganec',
  'Žganjar',
  'Žganjer',
  'Žgela',
  'Žgrabljić',
  'Žibrat',
  'Židar',
  'Žido Lazarević',
  'Žigman',
  'Žiha',
  'Žilavec',
  'Žiljak',
  'Žindarec',
  'Žipac',
  'Žiraj',
  'ŽirošTatalović',
  'Žiška',
  'Žitnjak',
  'Živadinović',
  'Živaković',
  'ŽivakovićKerže',
  'Živalj',
  'Živaljić',
  'Živanić',
  'Živanov',
  'Živanović',
  'Živičnjak',
  'ŽivićĆojbašić',
  'ŽivkovićUbavin',
  'Žiža',
  'Žižek',
  'Žižanović',
  'ŽižanovićŠahini',
  'ŽižićAbramović',
  'Žmegač',
  'Žnidarec',
  'Žnidarić',
  'Žnidaršić',
  'Žohar',
  'Žolja',
  'Žonka',
  'Žulj Raštegorac',
  'ŽuljevićGrafina',
  'Žuna',
  'Žuna Pfeiffer',
  'Župan',
  'Župan Perasić',
  'Župančić',
  'Županić',
  'Županović',
  'Župarić',
  'Žurga',
  'Žuro Tijardović',
  'Žuvela',
  'Žuža',
  'Žužak',
  'Žužanić',
  'ŽužanićKlapan',
  'Žvab'
]
