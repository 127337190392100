import React, { FC, useCallback } from "react";
import * as faker from "faker";
import styled from "styled-components/native";

import { t } from "../../i18n";
import type { CustomFieldUpdate } from "../../../bookmydesk-api-sdk-typescript-axios";

import Button from "../../components/Button";
import Input from "../../styles/Input";
import Text from "../../styles/Text";
import { font } from "../../styles/theme";
import { DeleteIcon } from "../Icons";

interface Props {
  data: CustomFieldUpdate[];
  onChange: React.Dispatch<React.SetStateAction<CustomFieldUpdate[]>>;
}

export const CustomFields: FC<Props> = ({ data, onChange }) => {
  const addCustomField = useCallback(() => {
    onChange((prev) => [
      ...prev,
      {
        id: "__new__" + faker.datatype.uuid(),
        name: "",
        description: "",
      },
    ]);
  }, [onChange]);

  const removeCustomField = useCallback(
    (id: string) => {
      onChange((prev) => prev.filter((customField) => customField.id !== id));
    },
    [onChange]
  );

  return (
    <Container>
      <TitleRow>
        <Title>{t("admin.parking.create.customFields.title")}</Title>
        <Button onPress={addCustomField}>
          {t("admin.parking.create.customFields.add")}
        </Button>
      </TitleRow>
      {data.map((customField, index) => (
        <CustomFieldRow key={index}>
          <RemoveButton onPress={() => removeCustomField(customField.id!)}>
            <DeleteIcon />
          </RemoveButton>
          <CustomFieldColumn>
            <InputLabel>{t("admin.parking.create.customFields.name")}</InputLabel>
            <Input
              onChangeText={(text) => {
                const newCustomFields = [...data];
                newCustomFields[index].name = text;
                onChange(newCustomFields);
              }}
              value={customField.name}
              placeholder={t("admin.parking.create.customFields.namePlaceholder")}
            />
          </CustomFieldColumn>
          <CustomFieldColumn $last>
            <InputLabel>{t("admin.parking.create.customFields.description")}</InputLabel>
            <Input
              onChangeText={(text) => {
                const newCustomFields = [...data];
                newCustomFields[index].description = text;
                onChange(newCustomFields);
              }}
              value={customField.description || ""}
              placeholder={t("admin.parking.create.customFields.descriptionPlaceholder")}
            />
          </CustomFieldColumn>
        </CustomFieldRow>
      ))}
    </Container>
  );
};

const Container = styled.View`
  margin-bottom: 20px;
`;

const TitleRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const Title = styled(Text)`
  font-size: 18px;
  font-family: ${font.defaultMedium}};
  font-weight: 100;
`;

const CustomFieldRow = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CustomFieldColumn = styled.View<{ $last?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: ${p => p.$last ? '0px' : '10px'};
`;

const InputLabel = styled(Text)`
  font-size: 14px;
  font-family: ${font.defaultMedium}};
  font-weight: 100;
`;

const RemoveButton = styled.TouchableOpacity`
  color: red;
  padding: 20px 8px 4px 0px;
  font-family: ${font.defaultMedium}};
  font-size: 20px;
  font-weight: 100;
  margin-right: 10px;
`;
