module["exports"] = [
  "Adventure Road Bicycle",
  "BMX Bicycle",
  "City Bicycle",
  "Cruiser Bicycle",
  "Cyclocross Bicycle",
  "Dual-Sport Bicycle",
  "Fitness Bicycle",
  "Flat-Foot Comfort Bicycle",
  "Folding Bicycle",
  "Hybrid Bicycle",
  "Mountain Bicycle",
  "Recumbent Bicycle",
  "Road Bicycle",
  "Tandem Bicycle",
  "Touring Bicycle",
  "Track/Fixed-Gear Bicycle",
  "Triathlon/Time Trial Bicycle",
  "Tricycle"
];