import React, { FC } from "react";
import { useAuth } from "../../../context/authContext";
import AdminDashboard from "./AdminDashboard";
import CustomerAdminDashboard from "./CustomerAdminDashboard";

const Dashboard: FC = () => {
  const { userState } = useAuth();

  return (
    <>
      {userState && userState.roles.includes("ROLE_ADMIN") && (
        <AdminDashboard />
      )}
      {userState && userState.roles.includes("ROLE_OFFICE_MANAGER") && (
        <CustomerAdminDashboard />
      )}
      {userState && userState.roles.includes("ROLE_CUSTOMER_ADMIN") && (
        <CustomerAdminDashboard />
      )}
    </>
  );
};

export default Dashboard;
