var he = {};
module['exports'] = he;
he.title = "Hebrew";
he.separator = "ו ";
he.address = require("./address");
// he.company = require("./company");
// he.internet = require("./internet");
// he.database = require("./database");
he.lorem = require("./lorem");
he.name = require("./name");
he.phone_number = require("./phone_number");
he.cell_phone = require("./cell_phone");
// he.business = require("./business");
he.commerce = require("./commerce");
// he.team = require("./team");
// he.hacker = require("./hacker");
// he.app = require("./app");
// he.finance = require("./finance");
he.date = require("./date");
// he.system = require("./system");
// he.vehicle = require("./vehicle");
he.music = require("./music");
