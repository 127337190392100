module["exports"] = [
  "ارزش افزوده",
  "عمودی",
  "فعال",
  "قدرتمند",
  "انقلابی",
  "مقیاس پذیر",
  "پیشرو",
  "خلاقانه",
  "شهودی",
  "راهبردی",
  "تجارت الکترونیکی",
  "ماموریت بحرانی",
  "چسبنده",
  "یک به یک",
  "24/7",
  "جهانی",
  "دانه ای",
  "بدون اصطکاک",
  "مجازی",
  "ویروسی",
  "پویا",
  "24/365",
  "مغناطیسی",
  "عقب",
  "موثر",
  "توزیع شده",
  "بدون درز",
  "قابل توسعه",
  "کلاس جهانی",
  "متن باز",
  "هم افزایی",
  "بینایی",
  "سفارشی",
  "مبتنی بر همکاری",
  "متقاعد کننده",
  "جامع",
  "پولدار"
];
