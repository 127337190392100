module["exports"] = [
  "شيفروليه",
  "كاديلاك",
  "فورد",
  "كرايسلر",
  "دودج",
  "جيب",
  "تسلا",
  "تويوتا",
  "هوندا",
  "نيسان",
  "أودي",
  "مرسيدس بنز",
  "بي إم دبليو",
  "فولكس واجن",
  "بورش",
  "جاكوار",
  "استون مارتن",
  "لاند روفر",
  "بنتلي",
  "ميني",
  "رولزرويس",
  "فيات",
  "لامبورغيني",
  "مازيراتي",
  "فيراري",
  "بوجاتي",
  "كيا",
  "هيونداي"
];
