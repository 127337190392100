module["exports"] = [
  "#{prefix} #{male_first_name} #{male_last_name}",
  "#{prefix} #{female_first_name} #{female_last_name}",
  "#{male_first_name} #{male_last_name} #{suffix}",
  "#{female_first_name} #{female_last_name} #{suffix}",
  "#{male_first_name} #{male_last_name}",
  "#{male_first_name} #{male_last_name}",
  "#{male_first_name} #{male_last_name}",
  "#{female_first_name} #{female_last_name}",
  "#{female_first_name} #{female_last_name}",
  "#{female_first_name} #{female_last_name}"
];
