import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import Map from "../../components/Map";
import Container from "../../components/Container";
import { color, iconSize, spacing } from "../../styles/theme";
import { useClient } from "../../hooks/useClient";
import { ThenArg } from "../../types";
import { useApi } from "../../hooks/useApi";
import { useParams } from "../../routing";
import LoadOverlay from "../../components/LoadOverlay/loadOverlay";
import { useAuth } from "../../context/authContext";
import {
  MapObject,
  SeatTag,
  SeatWithRelations,
  UserGroup,
} from "../../../bookmydesk-api-sdk-typescript-axios";
import workspacePng from "../../components/Map/mapIcons/workspace.png";
import workspaceRedPng from "../../components/Map/mapIcons/workspaceRed.png";
import workspaceSelectedPng from "../../components/Map/mapIcons/workspaceSelected.png";
import workspaceInactivePng from "../../components/Map/mapIcons/workspaceInactive.png";
import lunchPng from "../../components/Map/mapIcons/lunch.png";
import lunchRedPng from "../../components/Map/mapIcons/lunchRed.png";
import lunchSelectedPng from "../../components/Map/mapIcons/lunchSelected.png";
import lunchInactivePng from "../../components/Map/mapIcons/lunchInactive.png";
import meetingRoomPng from "../../components/Map/mapIcons/meetingRoom.png";
import meetingRoomRedPng from "../../components/Map/mapIcons/meetingRoomRed.png";
import meetingRoomSelectedPng from "../../components/Map/mapIcons/meetingRoomSelected.png";
import meetingRoomInactivePng from "../../components/Map/mapIcons/meetingRoomInactive.png";
import arrow from "../../components/Map/arrow.png";
import { Asset, useAssets } from "expo-asset";
import * as Print from "expo-print";
import styled from "styled-components/native";
import { Platform, View } from "react-native";
import Title from "../../styles/Title";
import Button from "../../components/Button";
import { t } from "../../i18n";

const TitleWrapper = styled.View`
  margin: ${spacing.large} 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CleaningMap: FunctionComponent = () => {
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { id } = useParams();
  const { userState } = useAuth();
  const userCompany = userState?.companyIds[0];

  type MapItem = ThenArg<
    ReturnType<typeof client.getMap>
  >["data"]["result"]["map"];

  const [map, setMap] = useState<MapItem | undefined>(undefined);
  const [mapImage, setMapImage] = useState<Asset | undefined>(undefined);
  const [mapObjects, setMapObjects] = useState<MapObject[] | undefined>(
    undefined
  );
  const [seats, setSeats] = useState<SeatWithRelations[] | undefined>(
    undefined
  );
  const [seatTags, setSeatTags] = useState<SeatTag[] | undefined>(undefined);
  const [userGroups, setUserGroups] = useState<UserGroup[] | undefined>(
    undefined
  );

  const fetchMap = useCallback(async () => {
    return await handleRequest(client.getMap(id));
  }, [id, handleRequest, client]);

  const fetchMapObjects = useCallback(async () => {
    if (!userCompany) {
      return;
    }
    return await handleRequest(client.listMapObjects(userCompany, id));
  }, [id, handleRequest, client, userCompany]);

  const fetchSeats = useCallback(async () => {
    if (!userCompany) {
      return;
    }
    return await handleRequest(client.listSeatsUsage(userCompany, id));
  }, [id, handleRequest, client, userCompany]);

  const fetchSeatTags = useCallback(async () => {
    if (!userCompany) {
      return;
    }
    return await handleRequest(client.listSeatTags(userCompany));
  }, [handleRequest, client, userCompany]);

  const fetchUserGroups = useCallback(async () => {
    if (!userCompany) {
      return;
    }
    return await handleRequest(client.listUserGroups(userCompany));
  }, [handleRequest, client, userCompany]);

  const fetchMapImage = useCallback(async () => {
    if (!map) {
      return;
    }
    return await handleRequest(
      client.getMapImage(map.id, {
        responseType: "arraybuffer",
      })
    );
  }, [map]);

  useEffect(() => {
    fetchMap().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setMap(result.data.result.map);
    });
    fetchMapObjects().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setMapObjects(result.data.result.items);
    });
    fetchSeats().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setSeats(result.data.result.items);
    });
    fetchSeatTags().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setSeatTags(result.data.result.items);
    });

    fetchUserGroups().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setUserGroups(result.data.result.items);
    });
  }, [
    fetchMap,
    setMap,
    fetchMapObjects,
    fetchSeats,
    fetchSeatTags,
    fetchUserGroups,
  ]);

  useEffect(() => {
    if (map) {
      fetchMapImage().then(async (result) => {
        console.log("result of mapImage!!!", result);
        if (!result?.data) {
          console.log("No data");
          return;
        }
        console.log("Got image");
        const mapImageAsset = await Asset.loadAsync(
          `data:image/png;base64,${Buffer.from(result.data, "binary").toString(
            "base64"
          )}`
        );

        setMapImage(mapImageAsset[0]);

        // setMapImage(
        //   `data:image/png;base64,${Buffer.from(result.data, "binary").toString(
        //     "base64"
        //   )}`
        // );
      });
    }
  }, [map]);

  const [asset, assetsError] = useAssets([
    workspacePng,
    workspaceSelectedPng,
    workspaceRedPng,
    workspaceInactivePng,
    lunchPng,
    lunchSelectedPng,
    lunchRedPng,
    lunchInactivePng,
    meetingRoomPng,
    meetingRoomSelectedPng,
    meetingRoomRedPng,
    meetingRoomInactivePng,
    arrow,
  ]);

  if (
    isLoading ||
    !map ||
    !seats ||
    !mapObjects ||
    !seatTags ||
    !userGroups ||
    !mapImage ||
    !asset ||
    assetsError
  ) {
    return <LoadOverlay />;
  }

  if (!map) {
    return null;
  }

  return (
    <Container backgroundColor={color.white}>
      <Map
        asset={[mapImage, ...asset]}
        isEditable={false}
        mapData={{
          id: map.id,
          name: map.name,
          zones: [],
          locationName: map.location.name,
          arrows: mapObjects,
          seats: seats.map((seat) => ({
            ...seat,
            morningBlocked: [],
            afternoonBlocked: [],
          })),
        }}
        seatTags={seatTags}
        userGroups={userGroups}
        triggerOnSaveAndClose={null}
        triggerOnSave={null}
        isCleaningMap
      />
    </Container>
  );
};

export default CleaningMap;
