import React from "react";

import "../i18n";

// Context
import { useAuth } from "../context/authContext";

// Routes
import UnAuthenticatedRoutes from "./UnAuthenticatedRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";

// Pages
import LoadOverlay from "../components/LoadOverlay";

export default function Routes(): JSX.Element {
  const { authState } = useAuth();

  return authState.isFetching ? (
    <LoadOverlay />
  ) : authState.isLoggedIn ? (
    <AuthenticatedRoutes />
    ) : (
    <UnAuthenticatedRoutes />
  );
}
