module["exports"] = {
  "job": [
    "مشرف",
    "تنفيذي",
    "مدير",
    "مهندس",
    "متخصص",
    "رئيس مؤسسة",
    "منسق",
    "إداري",
    "مخطط",
    "محلل",
    "مصمم",
    "تقني",
    "مبرمج",
    "منتج",
    "مستشار",
    "مساعد",
    "وكيل",
    "متدرب"
  ]
};
