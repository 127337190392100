module["exports"] = [
  "Արագածոտն",
  "Արարատ",
  "Արմավիր",
  "Գեղարքունիք",
  "Կոտայք",
  "Լոռի",
  "Շիրակ",
  "Սյունիք",
  "Տավուշ",
  "Վայոց ձոր",
  "Երևան",
  "Ստեփանակերտ",
  "Ասկերան",
  "Հադրութ",
  "Մարտակերտ",
  "Մարտունի",
  "Շահումյան",
  "Շուշի",
  "Քաշաթաղ"
];
