import React, { FC, useCallback, useEffect, useState } from "react";
import {
  CompanyProperties,
  InlineResponse400Errors,
  CompanyBasicWithRelations,
  CompanyWithRelations,
  Language,
} from '../../../../bookmydesk-api-sdk-typescript-axios';
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import Grid, { Column } from "../../../components/Grid";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LoadOverlay from "../../../components/LoadOverlay";
import { getCompanyPages } from "../../../components/Navbar/AdminNavBarContent";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import { useAuth } from "../../../context/authContext";
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { t } from "../../../i18n";
import { OverviewHeader } from "../../../styles/Overview";
import Title from "../../../styles/Title/title";
import { isValidEmail } from "../../../utils";
import useQuery from "../../../hooks/useQuery";
import SettingsSideBar from './SettingsSidebar';
import DataCard from './SubSettings/DataCard';
import ModuleCard from './SubSettings/ModuleCard';
import GeneralCard from './SubSettings/GeneralCard';
import CompanyCard from './SubSettings/CompanyCard';
import LinksCard from './SubSettings/LinksCard';
import TimeSlotCard from './SubSettings/TimeSlotCard';
import TagsCard from "./SubSettings/TagsCard";


const Index: FC = () => {
  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const query = useQuery();
  const [tabIndex, setTabIndex] = useState(0);
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [
    showAzureActiveDirectoryConnectedMessage,
    setShowAzureActiveDirectoryConnectedMessage,
  ] = useState(query.get("success") === "azure-active-directory-connected");
  const [
    showAzureActiveDirectoryNotConnectedMessage,
    setShowAzureActiveDirectoryNotConnectedMessage,
  ] = useState(query.get("error") === "azure-active-directory-not-connected");
  const [userCompanyData, setUserCompanyData] = useState<CompanyWithRelations | CompanyBasicWithRelations>();
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [daysAdvance, setDaysAdvance] = useState("");
  const [forceTimeSlotsFeature, setForceTimeSlotsFeature] = useState(false);
  const [parkingSpotsAsResourceEnabled, setParkingSpotsAsResourceEnabled] = useState(false);
  const [hideCheckInTimeEnabled, setHideCheckInTimeEnabled] = useState(false);
  const [checkInFeature, setCheckInFeature] = useState(false);
  const [lunchFeature, setLunchFeature] = useState(false);
  const [visitorFeature, setVisitorFeature] = useState(false);
  const [homeEnabled, setHomeEnabled] = useState(false);
  const [
    visitorReservationConfirmEmailEnabled,
    setVisitorReservationConfirmEmailEnabled,
  ] = useState(false);
  const [facilitymanagementFeature, setFacilitymanagementFeature] =
    useState(false);
  const [reservationForUserEnabled, setReservationForUserEnabled] =
    useState(false);
  const [meetingFeature, setMeetingFeature] = useState(false);
  const [recurringReservationFeature, setRecurringReservationFeature] =
    useState(false);
  const [geofenceFeature, setGeofenceFeature] = useState(false);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [deskExpirationTime, setDeskExpirationTime] = useState("");
  const [deskAutoCheckoutTime, setDeskAutoCheckoutTime] = useState("");
  const [lunchExpirationTime, setLunchExpirationTime] = useState("");
  const [lunchAutoCheckoutTime, setLunchAutoCheckoutTime] = useState("");
  const [meetingExpirationTime, setMeetingExpirationTime] = useState("");
  const [meetingAutoCheckoutTime, setMeetingAutoCheckoutTime] = useState("");

  const [microsoftSsoEnabled, setMicrosoftSsoEnabled] = useState(false);
  const [microsoftSyncEnabled, setMicrosoftSyncEnabled] = useState(false);
  const [azureActiveDirectoryActive, setAzureActiveDirectoryActive] =
    useState(false);

  const userCompany = userState?.companyIds[0];

  useEffect(() => {
    if (!userCompany) {
      return;
    }

    const fetchCompany = async () =>
      await handleRequest(client.getCompany(userCompany));

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { company } = result.data.result;

      setUserCompanyData(company);

      setName(company.name);
      setFirstName(company.contactFirstname);
      setLastName(company.contactLastname);
      setEmail(company.contactEmail);
      setPhoneNumber(company.contactPhone);
      setDaysAdvance(company.reservationWindowLength.toString());
      setCheckInFeature(Boolean(company?.checkInEnabled));
      setForceTimeSlotsFeature(Boolean(company?.forceTimeslotUse));
      setParkingSpotsAsResourceEnabled(Boolean(company?.parkingSpotsAsResourceEnabled));
      setHideCheckInTimeEnabled(Boolean(company?.hideCheckInTimeEnabled));
      setLunchFeature(Boolean(company?.lunchEnabled));
      setVisitorFeature(Boolean(company?.visitorsEnabled));
      setHomeEnabled(Boolean(company?.homeEnabled));
      setVisitorReservationConfirmEmailEnabled(
        Boolean(company?.visitorReservationConfirmEmailEnabled)
      );
      setFacilitymanagementFeature(Boolean(company?.facilitymanagementEnabled));
      setReservationForUserEnabled(Boolean(company?.reservationForUserEnabled));
      setMeetingFeature(Boolean(company?.meetingEnabled));
      setRecurringReservationFeature(Boolean(company?.recurringEnabled));
      setGeofenceFeature(Boolean(company?.forceGeofence));
      setDeskExpirationTime(
        typeof company.deskExpirationTime === "undefined"
          ? ""
          : `${company.deskExpirationTime}`
      );
      setDeskAutoCheckoutTime(
        typeof company.deskAutoCheckoutTime === "undefined"
          ? ""
          : `${company.deskAutoCheckoutTime}`
      );
      setLunchExpirationTime(
        typeof company.lunchExpirationTime === "undefined"
          ? ""
          : `${company.lunchExpirationTime}`
      );
      setLunchAutoCheckoutTime(
        typeof company.lunchAutoCheckoutTime === "undefined"
          ? ""
          : `${company.lunchAutoCheckoutTime}`
      );
      setMeetingExpirationTime(
        typeof company.meetingExpirationTime === "undefined"
          ? ""
          : `${company.meetingExpirationTime}`
      );
      setMeetingAutoCheckoutTime(
        typeof company.meetingAutoCheckoutTime === "undefined"
          ? ""
          : `${company.meetingAutoCheckoutTime}`
      );
      setMicrosoftSsoEnabled(Boolean(company.microsoftSsoEnabled));
      setMicrosoftSyncEnabled(Boolean(company.microsoftSyncEnabled));
      setAzureActiveDirectoryActive(
        typeof company.tokens.find(
          (token) => token.token_type === "microsoft_graph_admin"
        ) !== "undefined"
      );
    });
  }, [client, handleRequest, userCompany]);

  const isFormValid = useCallback(
    () =>
      Boolean(name) &&
      Boolean(firstName) &&
      Boolean(lastName) &&
      isValidEmail(email) &&
      Boolean(phoneNumber) &&
      Boolean(daysAdvance),
    [name, firstName, lastName, email, phoneNumber, daysAdvance]
  );

  const submitForm = useCallback(async () => {
    const company: CompanyProperties = {
      name,
      language: userCompanyData?.language || Language.En,
      contactFirstname: firstName,
      contactLastname: lastName,
      contactEmail: email,
      contactPhone: phoneNumber,
      reservationWindowLength: parseInt(daysAdvance),
      lunchEnabled: Boolean(lunchFeature),
      visitorsEnabled: Boolean(visitorFeature),
      homeEnabled: Boolean(homeEnabled),
      visitorReservationConfirmEmailEnabled: Boolean(
        visitorReservationConfirmEmailEnabled
      ),
      facilitymanagementEnabled: Boolean(facilitymanagementFeature),
      reservationForUserEnabled: Boolean(reservationForUserEnabled),
      meetingEnabled: Boolean(meetingFeature),
      checkInEnabled: Boolean(checkInFeature),
      forceTimeslotUse: Boolean(forceTimeSlotsFeature),
      parkingSpotsAsResourceEnabled: Boolean(parkingSpotsAsResourceEnabled),
      hideCheckInTimeEnabled: Boolean(hideCheckInTimeEnabled),
      forceGeofence: Boolean(geofenceFeature),
      recurringEnabled: Boolean(recurringReservationFeature),
      deskExpirationTime:
        deskExpirationTime === "" ? null : Number.parseInt(deskExpirationTime),
      deskAutoCheckoutTime:
        deskAutoCheckoutTime === ""
          ? null
          : Number.parseInt(deskAutoCheckoutTime),
      lunchExpirationTime:
        lunchExpirationTime === ""
          ? null
          : Number.parseInt(lunchExpirationTime),
      lunchAutoCheckoutTime:
        lunchAutoCheckoutTime === ""
          ? null
          : Number.parseInt(lunchAutoCheckoutTime),
      meetingExpirationTime:
        meetingExpirationTime === ""
          ? null
          : Number.parseInt(meetingExpirationTime),
      meetingAutoCheckoutTime:
        meetingAutoCheckoutTime === ""
          ? null
          : Number.parseInt(meetingAutoCheckoutTime),
    };
    try {
      const success = await handleRequest(
        client.updateCompany(userCompany, company)
      );

      if (success) {
        setShowUpdateMessage(true);
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    name,
    firstName,
    lastName,
    email,
    phoneNumber,
    daysAdvance,
    lunchFeature,
    visitorFeature,
    homeEnabled,
    visitorReservationConfirmEmailEnabled,
    facilitymanagementFeature,
    reservationForUserEnabled,
    meetingFeature,
    checkInFeature,
    forceTimeSlotsFeature,
    parkingSpotsAsResourceEnabled,
    geofenceFeature,
    recurringReservationFeature,
    deskExpirationTime,
    deskAutoCheckoutTime,
    lunchExpirationTime,
    lunchAutoCheckoutTime,
    meetingExpirationTime,
    meetingAutoCheckoutTime,
    handleRequest,
    client,
    userCompany,
    userCompanyData,
    hideCheckInTimeEnabled,
  ]);

  const updateDaysAdvance = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setDaysAdvance(days);
    },
    [setDaysAdvance]
  );

  const provideAzureActiveDirectoryAccess = useCallback(async () => {
    try {
      const response = await handleRequest(client.redirToMSAdmin());
      const authUrl = response?.data.result.authUrl;
      if (!authUrl) {
        throw new Error("No authUrl retrieved");
      }
      window.location.assign(authUrl);
    } catch (error) {
      console.error(error);
      setShowAzureActiveDirectoryNotConnectedMessage(true);
    }
  }, [handleRequest, client]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.company.form.editTitle", { name })}</Title>
          <Button disabled={!isFormValid()} onPress={submitForm}>
            {t("admin.company.form.editSubmit")}
          </Button>
        </OverviewHeader>
        {Boolean(error) && (
          <InputValidationErrors
            errors={errors}
            closeNotification={dismissError}
          />
        )}
        {showUpdateMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowUpdateMessage(false)}
          >
            {t("general.savedSuccess")}
          </Notification>
        ) : null}
        {showAzureActiveDirectoryConnectedMessage ? (
          <Notification
            type="success"
            closeNotification={() =>
              setShowAzureActiveDirectoryConnectedMessage(false)
            }
          >
            {t("admin.company.form.azureActiveDirectoryConnectedMessage")}
          </Notification>
        ) : null}
        {showAzureActiveDirectoryNotConnectedMessage ? (
          <Notification
            type="danger"
            closeNotification={() =>
              setShowAzureActiveDirectoryNotConnectedMessage(false)
            }
          >
            {t("admin.company.form.azureActiveDirectoryNotConnectedMessage")}
          </Notification>
        ) : null}
        <Grid>
          <Column columns={3}>
            <SettingsSideBar onChange={(index) => setTabIndex(index)} />
          </Column>
          <Column columns={1.5}>
            {tabIndex === 0 && (
              <GeneralCard
                daysAdvance={daysAdvance}
                forceTimeSlotsFeature={forceTimeSlotsFeature}
                parkingSpotsAsResourceEnabled={parkingSpotsAsResourceEnabled}
                checkInFeature={checkInFeature}
                recurringReservationFeature={recurringReservationFeature}
                geofenceFeature={geofenceFeature}
                setForceTimeSlotsFeature={setForceTimeSlotsFeature}
                setParkingSpotsAsResourceEnabled={setParkingSpotsAsResourceEnabled}
                setCheckInFeature={setCheckInFeature}
                setRecurringReservationFeature={setRecurringReservationFeature}
                setGeofenceFeature={setGeofenceFeature}
                updateDaysAdvance={updateDaysAdvance}
              />
            )}
            {tabIndex === 1 && (
              <ModuleCard
                checkInFeature={checkInFeature}
                meetingFeature={meetingFeature}
                visitorFeature={visitorFeature}
                lunchAutoCheckoutTime={lunchAutoCheckoutTime}
                deskExpirationTime={deskExpirationTime}
                deskAutoCheckoutTime={deskAutoCheckoutTime}
                meetingExpirationTime={meetingExpirationTime}
                meetingAutoCheckoutTime={meetingAutoCheckoutTime}
                lunchFeature={lunchFeature}
                lunchExpirationTime={lunchExpirationTime}
                onVisitorFeatureChange={setVisitorFeature}
                onDeskExpirationTimeChange={(value) => setDeskExpirationTime(value.toString())}
                onDeskAutoCheckoutTimeChange={(value) => setDeskAutoCheckoutTime(value.toString())}
                onMeetingFeatureChange={(value) => setMeetingFeature(value)}
                onMeetingExpirationTimeChange={(value) => setMeetingExpirationTime(value.toString())}
                onMeetingAutoCheckoutTimeChange={(value) => setMeetingAutoCheckoutTime(value.toString())}
                onLunchFeatureChange={(value) => setLunchFeature(value)}
                onLunchExpirationTimeChange={(value) => setLunchExpirationTime(value.toString())}
                onLunchAutoCheckoutTimeChange={(value) => setLunchAutoCheckoutTime(value.toString())}
              />
            )}
            {tabIndex === 2 && (
              <LinksCard
                microsoftSsoEnabled={microsoftSsoEnabled}
                microsoftSyncEnabled={microsoftSyncEnabled}
                azureActiveDirectoryActive={azureActiveDirectoryActive}
                provideAzureActiveDirectoryAccess={provideAzureActiveDirectoryAccess}
              />
            )}
            {tabIndex === 3 && (
              <TimeSlotCard />
            )}
            {tabIndex === 4 && (
              <TagsCard />
            )}
            {tabIndex === 5 && (
              <CompanyCard
                name={name}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phoneNumber={phoneNumber}
                setName={setName}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setPhoneNumber={setPhoneNumber}
              />
            )}
            {tabIndex === 6 && (
              <DataCard />
            )}
          </Column>
        </Grid>
        {/*<Grid>
          <Column columns={3}>
            <HeaderCard
              icon={settingIcon}
              title={t("admin.company.form.settingsHeader")}
            >
              <LabeledInput label={t("admin.company.form.days")} required>
                <Input
                  onChangeText={updateDaysAdvance}
                  value={daysAdvance}
                  placeholder={t("admin.company.form.days") + " *"}
                />
              </LabeledInput>
              <Toggle
                label={t("admin.company.form.forceTimeslots")}
                onChange={setForceTimeSlotsFeature}
                toggleValue={forceTimeSlotsFeature}
              />
              <Toggle
                label={t("admin.company.form.parkingSpotsAsResources")}
                onChange={setParkingSpotsAsResourceEnabled}
                toggleValue={parkingSpotsAsResourceEnabled}
              />
              <Toggle
                label={t("admin.company.form.hideCheckInTimeEnabled")}
                onChange={setHideCheckInTimeEnabled}
                toggleValue={hideCheckInTimeEnabled}
              />
              <Toggle
                label={t("admin.company.form.checkIn")}
                onChange={setCheckInFeature}
                toggleValue={checkInFeature}
              />
              <Toggle
                label={t(
                  "admin.company.form.visitorReservationConfirmEmailEnabled"
                )}
                onChange={setVisitorReservationConfirmEmailEnabled}
                toggleValue={visitorReservationConfirmEmailEnabled}
              />
              <Toggle
                label={t("admin.company.form.visitors")}
                onChange={setVisitorFeature}
                toggleValue={visitorFeature}
              />
              <Toggle
                label={t("admin.company.form.home")}
                onChange={setHomeEnabled}
                toggleValue={homeEnabled}
              />
              <Toggle
                label={t("admin.company.form.facilitymanagement")}
                onChange={setFacilitymanagementFeature}
                toggleValue={facilitymanagementFeature}
              />

              <Toggle
                label={t("admin.company.form.reservationForUser")}
                onChange={setReservationForUserEnabled}
                toggleValue={reservationForUserEnabled}
              />

              <Toggle
                label={t("admin.company.form.recurring")}
                onChange={setRecurringReservationFeature}
                toggleValue={recurringReservationFeature}
              />
              <Toggle
                label={t("admin.company.form.geofence")}
                onChange={setGeofenceFeature}
                toggleValue={geofenceFeature}
              />
            </HeaderCard>
          </Column>
        </Grid>*/}
      </Container>
    </>
  );
};

export default Index;
