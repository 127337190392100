import React, { FC } from "react";
import { ViewStyle } from "react-native";

import styled from "styled-components/native";
import { InputHelpText } from "../../styles/Input";

import Text, { ExtraSmallText } from "../../styles/Text";
import { color, font, spacing, text } from "../../styles/theme";

const Wrapper = styled.View<ViewStyle>`
  ${({ zIndex }) => `z-index: ${zIndex ? zIndex : 1}`}
`;

export const LabeledInputWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const InputLabel = styled(Text)`
  font-size: 14px;
  font-family: ${font.defaultMedium}};
  font-weight: 100;
`;

interface LabelWrapperProps {
  topAlign?: boolean;
}

export const LabelWrapper = styled.View<LabelWrapperProps>`
  margin-right: ${spacing.small};
  flex: 1;
  ${({ topAlign }) =>
    topAlign
      ? `
    align-self: flex-start;
    margin-top: ${spacing.small}
  `
      : ``}
`;

export const InputWrapper = styled.View`
  flex: 1.5;
`;

export const Asterisk = styled(ExtraSmallText)`
  color: ${color.secondary};
`;

interface LabeledInputInterface {
  required?: boolean;
  label?: string;
  helperText?: string;
  style?: ViewStyle;
}

const LabeledInput: FC<LabeledInputInterface> = ({
  children,
  required,
  label,
  helperText,
  style,
}) => {
  return (
    <Wrapper {...style}>
      <LabeledInputWrapper>
        <LabelWrapper>
          {label ? (
            <InputLabel mediumWeight hasMarginBottom>
              {label}
              {required ? <Asterisk mediumWeight>{" *"}</Asterisk> : null}
            </InputLabel>
          ) : undefined}
        </LabelWrapper>
        <InputWrapper>{children}</InputWrapper>
      </LabeledInputWrapper>
      {helperText && (
        <LabeledInputWrapper>
          <LabelWrapper></LabelWrapper>
          <InputWrapper>
            <InputHelpText>{helperText}</InputHelpText>
          </InputWrapper>
        </LabeledInputWrapper>
      )}
    </Wrapper>
  );
};

export default LabeledInput;
