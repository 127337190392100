import styled, { css } from "styled-components/native";
import marginBottom from "../helpers/marginBottom";
import { borderRadius, boxShadow, color, spacing } from "../theme";

interface BoxProps {
  last?: boolean;
  backgroundColor?: string;
}

export default styled.View<BoxProps>`
  padding: ${spacing.large};
  background-color: ${({ backgroundColor }) => backgroundColor || color.white};
  border-radius: ${borderRadius.large};

  ${(props) => marginBottom(props)};

  ${boxShadow.small};

  ${({ last }) =>
    last &&
    css`
      margin-bottom: 0;
    `};
`;
