import React, {
  FC,
  createContext,
  useEffect,
  useContext,
  useReducer,
  useState,
} from "react";
import { AxiosResponse } from "axios";
import {
  DefaultApiFactory,
  Configuration,
} from "../../bookmydesk-api-sdk-typescript-axios";
import { User, mapUser } from "../mappers/userMapper";
import {
  removeFromStorage,
  storageKeys,
  saveInStorage,
  getFromStorage,
} from "../services/asyncStorageService";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GrantType } from "../services/clientService";
import {
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_API_URL,
} from "@env";
import { t } from "../i18n";
import { useCallback } from "react";
import { Platform } from "react-native";
import loadSendInBlueChat from "../services/sendinbluChat";
import { getCompanyPages } from "../components/Navbar/AdminNavBarContent";
import { PageItem } from "../components/PageSelector/PageSelectorItem";
// Tester TODO, this is the real problem
const client = DefaultApiFactory(undefined, REACT_APP_API_URL);

export enum Roles {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_CUSTOMER_ADMIN = "ROLE_CUSTOMER_ADMIN",
  ROLE_OFFICE_MANAGER = "ROLE_OFFICE_MANAGER",
}

type Action =
  | { type: "LOGIN_SUCCESS" }
  | { type: "LOGIN_ERROR"; error: string }
  | { type: "LOGGED_OUT" }
  | { type: "SET_TOKENS"; accessToken: string; refreshToken: string };

type AuthState = {
  isFetching: boolean;
  isLoggedIn: boolean;
  errorMessage: string;
  accessToken: string;
  refreshToken: string;
};

const initialState: AuthState = {
  isFetching: true,
  isLoggedIn: false,
  errorMessage: "",
  accessToken: "",
  refreshToken: "",
};
const reducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isLoggedIn: true,
        errorMessage: "",
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        errorMessage: action.error,
      };
    case "LOGGED_OUT":
      return {
        ...state,
        isLoggedIn: false,
        isFetching: false,
        errorMessage: "",
      };
    case "SET_TOKENS":
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      };
  }
};

interface AuthProviderInterface {
  companyPages: PageItem[];
  userState: User | undefined;
  authState: AuthState;
  setTokens: (accessToken: string, refreshToken: string) => void;
  refreshTokenLogin: (refreshToken: string) => void;
  login: (name: string, password: string, rememberMe?: boolean) => void;
  logOut: () => void;
}

const AuthContext = createContext({} as AuthProviderInterface);

const AuthProvider: FC = (props) => {
  const [userState, setUserState] = useState<User>();
  const [companyPages, setCompanyPages] = useState<PageItem[]>(getCompanyPages());
  const [authState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (Platform.OS === "web" && userState?.email) {
      loadSendInBlueChat(
        userState.email,
        t("chat.welcomeMessage"),
        t("chat.offlineMessage")
      );
    }
  }, [userState]);

  const setAuthTokens = useCallback(
    async (response: AxiosResponse) => {
      const { refresh_token, access_token } = response.data;
      await saveInStorage(storageKeys.refreshToken, refresh_token);
      dispatch({
        type: "SET_TOKENS",
        refreshToken: refresh_token,
        accessToken: access_token,
      });
    },
    [dispatch]
  );

  const refreshTokenLogin = useCallback(
    async (refreshToken: string) => {
      await client
        .oAuthPasswordGrant(
          REACT_APP_CLIENT_ID,
          REACT_APP_CLIENT_SECRET,
          refreshToken,
          GrantType.REFRESH_TOKEN
        )
        .then(setAuthTokens)
        .catch(() => {
          dispatch({ type: "LOGGED_OUT" });
          AsyncStorage.clear();
        });
    },
    [dispatch, setAuthTokens]
  );

  useEffect(() => {
    const loginOnRefreshToken = async () => {
      const refreshToken = await getFromStorage(storageKeys.refreshToken);
      if (refreshToken) {
        await refreshTokenLogin(refreshToken);
      } else {
        dispatch({ type: "LOGGED_OUT" });
      }
    };

    loginOnRefreshToken();
  }, [refreshTokenLogin]);

  useEffect(() => {
    const config = new Configuration({
      accessToken: authState.accessToken,
      basePath: REACT_APP_API_URL,
    });
    const client = DefaultApiFactory(config);
    const getUser = async () => {
      await client
        .getMe()
        .then(async ({ data: me }) => {
          const user = mapUser(me);
          const userLanguage = user.language
            ? user.language.toLowerCase()
            : null;
          const storageUserLanguage = await getFromStorage(
            storageKeys.userLanguage
          );
          if (userLanguage !== storageUserLanguage) {
            if (userLanguage === null) {
              await removeFromStorage(storageKeys.userLanguage);
            } else {
              await saveInStorage(storageKeys.userLanguage, userLanguage);
            }

            window.location.reload();
          }

          setUserState(user);
          dispatch({ type: "LOGIN_SUCCESS" });
        })
        .catch(() => {
          dispatch({
            type: "LOGIN_ERROR",
            error: t("login.failed"),
          });
          AsyncStorage.clear();
        });
    };
    if (authState.accessToken && !userState) {
      getUser();
    }
  }, [authState.accessToken]);

  const login = useCallback(
    async (name: string, password: string) => {
      await client
        .oAuthPasswordGrant(
          REACT_APP_CLIENT_ID,
          REACT_APP_CLIENT_SECRET,
          undefined,
          GrantType.PASSWORD,
          name,
          password
        )
        .then(setAuthTokens)
        .catch((error) => {
          if (error.response?.status === 403) {
            dispatch({
              type: "LOGIN_ERROR",
              error: t("login.invalid"),
            });
          } else {
            dispatch({
              type: "LOGIN_ERROR",
              error: t("login.failed"),
            });
          }
          AsyncStorage.clear();
        });
    },
    [dispatch, setAuthTokens]
  );

  const logOut = useCallback(async () => {
    await removeFromStorage(storageKeys.refreshToken);
    await removeFromStorage(storageKeys.impersonationRefreshToken);

    setUserState(undefined);
    dispatch({ type: "LOGGED_OUT" });
  }, [dispatch, setUserState]);

  const setTokens = useCallback(
    async (accessToken: string, refreshToken: string) => {
      dispatch({
        type: "SET_TOKENS",
        accessToken,
        refreshToken,
      });
    },
    [dispatch]
  );


  useEffect(() => {
    const userCompany = userState?.companyIds[0];
    if (!userCompany) {
      return;
    }

    const config = new Configuration({
      accessToken: authState.accessToken,
      basePath: REACT_APP_API_URL,
    });
    const client = DefaultApiFactory(config);
    client.getCompany(userCompany).then((result) => {
      if (!result || !result.data.result) {
        return;
      }
      const { company } = result.data.result;
      if (company) {
        if (company.microsoftSyncEnabled) {
          setCompanyPages(getCompanyPages());
        } else {
          const [, ...pages] = getCompanyPages().reverse();
          setCompanyPages(pages.reverse());
        }
      }
    });
  }, [authState.accessToken, userState?.companyIds]);

  const authContextValue = {
    companyPages,
    userState,
    authState,
    refreshTokenLogin,
    login,
    logOut,
    setTokens,
  };
  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
