module["exports"] = [
  "つぎつぎ",
  "薬",
  "当て字",
  "しょくん",
  "間隔",
  "飽くまでも",
  "あびる",
  "雇用",
  "かんどうする",
  "じじょでん",
  "超音波",
  "じゅうどう",
  "面",
  "先週",
  "しょうがっこう",
  "避ける",
  "ふそく",
  "まぎらす",
  "閉める",
  "たまご",
  "こわす",
  "底",
  "〜亭",
  "しゃくや",
  "しゃっか",
  "ひきざん",
  "割り箸",
  "こうぞく",
  "果てる",
  "つなひき",
  "漂う",
  "漠然",
  "しょうりゃく",
  "減俸",
  "さいぼう",
  "さいほう",
  "平安",
  "封筒",
  "旧姓",
  "よわよわしい",
  "味噌",
  "ぼきん",
  "くつじょく",
  "絹糸",
  "ちきゅう",
  "かぜ",
  "半額",
  "かんそく",
  "ぶん",
  "ふん",
  "たれる",
  "廉価",
  "ずいぶん",
  "屈む",
  "かんりょうてき",
  "すんか",
  "殻",
  "擬装",
  "うえる",
  "たいさ",
  "あつい",
  "げいひんかん",
  "洋服",
  "大丈夫",
  "察知",
  "しえんする",
  "仕方がない",
  "徳川",
  "ちらかす",
  "こくふくする",
  "ぶそう",
  "こうつう",
  "逆",
  "馬鹿馬鹿しい",
  "切迫",
  "不健康",
  "学院",
  "都合",
  "備える",
  "へいがい",
  "はじめて",
  "輸出",
  "迷路",
  "母",
  "ひかくする",
  "はなぢ",
  "はなじ",
  "むこう",
  "ねんじゅう",
  "自宅",
  "誘惑",
  "ちえん",
  "太る",
  "ちかく",
  "奴ら",
  "堀川",
  "ちがい",
  "たいこうする",
  "運ぶ",
  "独裁",
  "はっぽう",
  "とちょう",
  "先ず",
  "はんそう",
  "せいじょう",
  "しょうじょう",
  "かんじる",
  "体重",
  "上手",
  "原油",
  "ざぜん",
  "既に",
  "華やか",
  "仕事",
  "あらす",
  "ゆるむ",
  "きょうどう",
  "開閉",
  "悲しみ",
  "しゃこ",
  "ねばり",
  "ようい",
  "おりめ",
  "伐採",
  "牛乳",
  "秘める",
  "右翼",
  "伝統",
  "きひん",
  "やさしい",
  "ほ",
  "警官",
  "左手",
  "全日本",
  "むぜい",
  "ごふく",
  "かいたく",
  "撃つ",
  "しあとるし",
  "シアトルし",
  "ちょさくけん",
  "じぎする",
  "譜面",
  "貫く",
  "約する",
  "提案する",
  "哀れむ",
  "迷子",
  "きょうき",
  "ごうけん",
  "せんたくする",
  "しゅしょう",
  "江戸",
  "狂う",
  "助手",
  "新婚旅行",
  "検査",
  "色々",
  "かぶしきしじょう",
  "桜色",
  "普及",
  "零す",
  "ししょく",
  "きゅうりょう",
  "おんとう",
  "しょうゆ",
  "没落",
  "人性",
  "けいかん",
  "電話",
  "お盆",
  "きいろ",
  "やしなう",
  "鍋",
  "遮断",
  "かわかす",
  "寮生",
  "面積",
  "とうき",
  "ふくへい",
  "ないしょばなし",
  "不思議",
  "この頃",
  "おかね",
  "殺人者",
  "かいぞく",
  "歯を磨く",
  "店",
  "はなはだ",
  "フランス語",
  "評価",
  "九日",
  "さいばん",
  "推奨",
  "出版",
  "恨み",
  "気持ちいい",
  "黙る",
  "はりい",
  "凝固",
  "傑作",
  "魅力",
  "ぐん",
  "右利き",
  "まほうつかい",
  "復旧",
  "かくじっけん",
  "じきしょうそう",
  "あれる",
  "きょうはんしゃ",
  "たいりく",
  "げんめつ",
  "俵",
  "むらさきいろ",
  "病床",
  "米兵",
  "まつり",
  "塾生",
  "親切",
  "めいがら",
  "核実験",
  "なおさら",
  "魔術",
  "がいよう",
  "かんぜん",
  "せいかん",
  "果樹",
  "〜系",
  "ほにゅうびん",
  "じょうだん",
  "賢明",
  "みなと",
  "もはん",
  "こうちょく",
  "泳ぐ",
  "重い",
  "日没",
  "碁",
  "かつ",
  "どうけつ",
  "近視",
  "配慮",
  "のき",
  "入江",
  "とめる",
  "暇",
  "書き方",
  "胃",
  "りょうど",
  "難しい",
  "浮世絵",
  "喜劇",
  "とうさん",
  "はんだんする",
  "こうせい",
  "大仏",
  "揺さぶる",
  "いじん",
  "機嫌",
  "黒板",
  "えきびょう",
  "妥協する",
  "つうやく",
  "乗せる",
  "けしき",
  "床",
  "品詞",
  "消す",
  "金縛り",
  "じゅうらい",
  "急騰",
  "十台",
  "窓",
  "雑音",
  "きょだい",
  "鈍器",
  "禍根",
  "かたみち",
  "山葵",
  "店舗",
  "渦巻き",
  "おととい",
  "いっさくじつ",
  "救急車",
  "馬",
  "ころす",
  "ぼくし",
  "せっぷく",
  "たて",
  "おどろく",
  "やさい",
  "じぞう",
  "こはん",
  "いく",
  "自立",
  "かっこう",
  "脱税",
  "始まる",
  "学者",
  "かい",
  "ひきさく",
  "長唄",
  "下着",
  "よくげつ",
  "休日",
  "以下",
  "廃墟",
  "部首",
  "壊す",
  "むく",
  "委員",
  "待合",
  "頂く",
  "よぼう",
  "壮年",
  "斬殺",
  "ちゅうもんする",
  "じっかん",
  "境",
  "施行",
  "つく",
  "活用",
  "ぶき",
  "かいじゅう",
  "人口",
  "ぼうず",
  "そあく",
  "むぼう",
  "白菊",
  "りゃくず",
  "汚す",
  "すいせん",
  "あらそう",
  "高値",
  "あう",
  "せいしん",
  "指紋",
  "超〜",
  "うえる",
  "つまる",
  "靖国神社",
  "とりあえず",
  "とふ",
  "たくす",
  "じょうじゅん",
  "羊毛",
  "浅い",
  "閉じる",
  "戦没",
  "あっとうする",
  "ひがい",
  "量",
  "じょうき",
  "誤用",
  "ほうせき",
  "つばさ",
  "号",
  "けいむしょ",
  "電源",
  "勇気",
  "ふかさ",
  "はだか",
  "たいやく",
  "きょうしつ",
  "退く",
  "さきまわり",
  "こうおつ",
  "無糖",
  "ふさい",
  "とくに",
  "めいし",
  "みつ",
  "ほんらい",
  "まもる",
  "あつかい",
  "日欧",
  "天井",
  "みさき",
  "おきゃくさん",
  "にんい",
  "きょうかい",
  "いつ頃",
  "かん",
  "しきもう",
  "特殊",
  "同僚",
  "血液",
  "じぶん",
  "しょうかする",
  "礎",
  "みなもと",
  "軒",
  "ぶっきょう",
  "しずむ",
  "たらす",
  "憂い",
  "総括",
  "もうす",
  "暴力",
  "しばふ",
  "いたずら",
  "米国",
  "魔法",
  "ことばつき",
  "窒息",
  "唄う",
  "金",
  "きもち",
  "誓い",
  "どろ",
  "話",
  "奇襲",
  "巡回",
  "失う",
  "隆起",
  "投資",
  "芽",
  "あくれい",
  "奉仕",
  "ひんきゃく",
  "ひんかく",
  "まつ",
  "せんりゅう",
  "だいどころ",
  "いう",
  "こいぬ",
  "なんべい",
  "さくにゅう",
  "フランス人",
  "きげんご",
  "こくみん",
  "交錯",
  "好き",
  "一文字",
  "ほうげん",
  "地面",
  "だくりゅう",
  "見当たる",
  "浸す",
  "あしくび",
  "弱虫",
  "送る",
  "遺失",
  "あおい",
  "ちあん",
  "宜しく",
  "あらあらしい",
  "かおつき",
  "かちゅう",
  "がんばる",
  "芸者",
  "陳列室",
  "弥生",
  "明治",
  "めいしょ",
  "ごじゅう",
  "済ます",
  "無駄",
  "終点",
  "ぞくご",
  "掛ける",
  "ほうしゅう",
  "騎兵",
  "液体",
  "下さい",
  "けいじばん",
  "杏",
  "合う",
  "疾走",
  "りゅうこうご",
  "出かける",
  "はちまき",
  "手作り",
  "れつあく",
  "うんがいい",
  "はちのす",
  "ハチのす",
  "色盲",
  "形",
  "希望する",
  "こうせい",
  "いちだい",
  "春休み",
  "縛る",
  "金星",
  "そんざい",
  "防犯",
  "大尉",
  "老齢",
  "差し上げる",
  "犠牲",
  "にる",
  "問題",
  "双",
  "安泰",
  "せんじょうざい",
  "継承",
  "かんしん",
  "丼",
  "ざんぴん",
  "そだてる",
  "たつ",
  "不可欠",
  "報じる",
  "抑制",
  "けいけんしゃ",
  "きょうふ",
  "せいぞう",
  "きんく"
];
