import React, { FC } from "react";
import styled from "styled-components/native";
import {
  borderRadius,
  borderWidth,
  color,
  font,
  spacing,
  text,
} from "../../styles/theme";
import Text, { ErrorText } from "../../styles/Text";

interface DangerStyle {
  danger?: boolean;
  warning?: boolean;
}

const NotificationWrapper = styled.View<DangerStyle>`
  border: ${borderWidth.small};
  border-color: ${({ danger, warning }) =>
    danger ? color.primary : warning ? color.tertiaryDark : color.quinary};
  background-color: ${({ danger, warning }) =>
    danger
      ? color.primaryLight
      : warning
      ? color.tertiaryLight
      : color.quinaryLight};
  border-radius: ${borderRadius.small};
  padding: ${spacing.small} ${spacing.medium};
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: ${spacing.medium};
`;

const Close = styled.TouchableOpacity`
  padding-right: ${spacing.small};
  margin-top: -${spacing.small};
`;

const StyledText = styled(Text)`
  font-family: ${font.defaultBold};
  color: ${color.quinary};
`;

const WarningText = styled(Text)`
  font-family: ${font.defaultBold};
  color: ${color.tertiaryDark};
`;

const CloseText = styled.Text<DangerStyle>`
  color: ${({ danger }) => (danger ? color.primary : color.quinary)};
  font-size: ${text.large.size};
  font-family: ${font.defaultBold};
  position: absolute;
  right: ${spacing.medium};
  font-size: ${text.extraLarge.size};
  line-height: ${text.extraLarge.size};
  top: 0;
  bottom: 0;
  margin: auto;
`;

const dangerType = "danger";

type NotificationTypes = "success" | "danger" | "warning" | "information" ;

interface NotificationInterface {
  closeNotification?: () => void;
  type?: NotificationTypes;
}

const Index: FC<NotificationInterface> = ({
  children,
  closeNotification,
  type = dangerType,
}) => (
  <NotificationWrapper
    danger={type === dangerType}
    warning={type === "warning"}
  >
    {type === dangerType ? (
      <ErrorText>{children}</ErrorText>
    ) : type === "warning" ? (
      <WarningText>{children}</WarningText>
    ) : (
      <StyledText>{children}</StyledText>
    )}
    {closeNotification && (
      <Close onPress={closeNotification}>
        <CloseText danger={type === dangerType}>⨯</CloseText>
      </Close>
    )}
  </NotificationWrapper>
);

export default Index;
