import React, { FC, useCallback, useState } from "react";
import { View, Image } from 'react-native';

import { t } from "../../../i18n";
import {
  SeatTagWithoutId,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LoadOverlay from "../../../components/LoadOverlay";
import LabeledInput from "../../../components/LabeledInput";
import Notification from "../../../components/Notification";
import PickerInput from "../../../components/PickerInput";

import { MediumTitle } from "../../../styles/Title";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { ExtraSmallText } from "../../../styles/Text";

import { useHistory } from "../../../routing";
import { useClient } from "../../../hooks/useClient";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import InputValidationErrors from "../../../components/InputValidationErrors";

import icon_tag_beamer from "../../../assets/tags/icon_tag_beamer.svg";
import icon_tag_briefcase from "../../../assets/tags/icon_tag_briefcase.svg";
import icon_tag_bright from "../../../assets/tags/icon_tag_bright.svg";
import icon_tag_building from "../../../assets/tags/icon_tag_building.svg";
import icon_tag_capacity from "../../../assets/tags/icon_tag_capacity.svg";
import icon_tag_chromecast from "../../../assets/tags/icon_tag_chromecast.svg";
import icon_tag_clock from "../../../assets/tags/icon_tag_clock.svg";
import icon_tag_coffeetea from "../../../assets/tags/icon_tag_coffeetea.svg";
import icon_tag_conference from "../../../assets/tags/icon_tag_conference.svg";
import icon_tag_desk from "../../../assets/tags/icon_tag_desk.svg";
import icon_tag_ergonomic from "../../../assets/tags/icon_tag_ergonomic.svg";
import icon_tag_flipboard from "../../../assets/tags/icon_tag_flipboard.svg";
import icon_tag_focus from "../../../assets/tags/icon_tag_focus.svg";
import icon_tag_hdmi from "../../../assets/tags/icon_tag_hdmi.svg";
import icon_tag_heightadjustable from "../../../assets/tags/icon_tag_heightadjustable.svg";
import icon_tag_info from "../../../assets/tags/icon_tag_info.svg";
import icon_tag_keyboard from "../../../assets/tags/icon_tag_keyboard.svg";
import icon_tag_keyboardcord from "../../../assets/tags/icon_tag_keyboardcord.svg";
import icon_tag_monitor from "../../../assets/tags/icon_tag_monitor.svg";
import icon_tag_nfc from "../../../assets/tags/icon_tag_nfc.svg";
import icon_tag_phone from "../../../assets/tags/icon_tag_phone.svg";
import icon_tag_printer from "../../../assets/tags/icon_tag_printer.svg";
import icon_tag_qr from "../../../assets/tags/icon_tag_qr.svg";
import icon_tag_silence from "../../../assets/tags/icon_tag_silence.svg";
import icon_tag_silent from "../../../assets/tags/icon_tag_silent.svg";
import icon_tag_starred from "../../../assets/tags/icon_tag_starred.svg";
import icon_tag_supplies from "../../../assets/tags/icon_tag_supplies.svg";
import icon_tag_tv from "../../../assets/tags/icon_tag_tv.svg";
import icon_tag_usb from "../../../assets/tags/icon_tag_usb.svg";
import icon_tag_wheelchair from "../../../assets/tags/icon_tag_wheelchair.svg";
import icon_tag_whiteboard from "../../../assets/tags/icon_tag_whiteboard.svg";
import icon_tag_widescreen from "../../../assets/tags/icon_tag_widescreen.svg";
import icon_tag_wifi from "../../../assets/tags/icon_tag_wifi.svg";

export const getIconOptions = () => [
  {
    label: t("admin.seatTag.icon_name.icon_tag_beamer"),
    value: "icon_tag_beamer",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_briefcase"),
    value: "icon_tag_briefcase",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_bright"),
    value: "icon_tag_bright",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_building"),
    value: "icon_tag_building",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_capacity"),
    value: "icon_tag_capacity",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_chromecast"),
    value: "icon_tag_chromecast",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_clock"),
    value: "icon_tag_clock",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_coffeetea"),
    value: "icon_tag_coffeetea",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_conference"),
    value: "icon_tag_conference",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_ergonomic"),
    value: "icon_tag_ergonomic",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_flipboard"),
    value: "icon_tag_flipboard",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_focus"),
    value: "icon_tag_focus",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_hdmi"),
    value: "icon_tag_hdmi",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_heightadjustable"),
    value: "icon_tag_heightadjustable",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_info"),
    value: "icon_tag_info",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_keyboard"),
    value: "icon_tag_keyboard",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_keyboardcord"),
    value: "icon_tag_keyboardcord",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_monitor"),
    value: "icon_tag_monitor",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_nfc"),
    value: "icon_tag_nfc",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_phone"),
    value: "icon_tag_phone",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_printer"),
    value: "icon_tag_printer",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_qr"),
    value: "icon_tag_qr",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_silence"),
    value: "icon_tag_silence",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_silent"),
    value: "icon_tag_silent",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_starred"),
    value: "icon_tag_starred",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_supplies"),
    value: "icon_tag_supplies",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_tv"),
    value: "icon_tag_tv",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_usb"),
    value: "icon_tag_usb",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_wheelchair"),
    value: "icon_tag_wheelchair",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_whiteboard"),
    value: "icon_tag_whiteboard",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_widescreen"),
    value: "icon_tag_widescreen",
  },
  {
    label: t("admin.seatTag.icon_name.icon_tag_wifi"),
    value: "icon_tag_wifi",
  },
];

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");

  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();
  const isFormValid = useCallback(() => Boolean(name), [name]);
  const [icon, setIcon] = useState("");
  const iconOptions = getIconOptions().map((item) => ({
    label: item.label,
    value: item.value,
  }));
  const submitForm = useCallback(async () => {
    const seatTag: SeatTagWithoutId = {
      label: name,
      icon: icon
    };
    try {
      const success = await handleRequest(client.addSeatTag(seatTag, icon));

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [handleRequest, client, name, icon, history]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.seatTag.form.addTitle")}
          </MediumTitle>
          <LabeledInput label={t("admin.seatTag.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.seatTag.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.seatTag.form.icon")}
          >
          <PickerInput
            selectedValue={icon}
            onValueChange={(itemValue) => setIcon(itemValue.toString())}
            items={[
              {
                label: t("admin.reservation.overview.choose"),
                value: "",
              },
              ...iconOptions.sort((a, b) => (a.label > b.label) ? 1 : -1),
            ]}
          />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.seatTag.form.createSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
