module["exports"] = [
  "Adolf",
  "Adrian",
  "Agnes",
  "Alain",
  "Albert",
  "Alberto",
  "Aldo",
  "Alex",
  "Alexander",
  "Alexandre",
  "Alfons",
  "Alfred",
  "Alice",
  "Alois",
  "André",
  "Andrea",
  "Andreas",
  "Angela",
  "Angelo",
  "Anita",
  "Anna",
  "Anne",
  "Anne-Marie",
  "Annemarie",
  "Antoine",
  "Anton",
  "Antonio",
  "Armin",
  "Arnold",
  "Arthur",
  "Astrid",
  "Barbara",
  "Beat",
  "Beatrice",
  "Beatrix",
  "Bernadette",
  "Bernard",
  "Bernhard",
  "Bettina",
  "Brigitta",
  "Brigitte",
  "Bruno",
  "Carlo",
  "Carmen",
  "Caroline",
  "Catherine",
  "Chantal",
  "Charles",
  "Charlotte",
  "Christa",
  "Christian",
  "Christiane",
  "Christina",
  "Christine",
  "Christoph",
  "Christophe",
  "Claire",
  "Claude",
  "Claudia",
  "Claudine",
  "Claudio",
  "Corinne",
  "Cornelia",
  "Daniel",
  "Daniela",
  "Daniele",
  "Danielle",
  "David",
  "Denis",
  "Denise",
  "Didier",
  "Dieter",
  "Dominik",
  "Dominique",
  "Dora",
  "Doris",
  "Edgar",
  "Edith",
  "Eduard",
  "Edwin",
  "Eliane",
  "Elisabeth",
  "Elsa",
  "Elsbeth",
  "Emil",
  "Enrico",
  "Eric",
  "Erica",
  "Erich",
  "Erika",
  "Ernst",
  "Erwin",
  "Esther",
  "Eugen",
  "Eva",
  "Eveline",
  "Evelyne",
  "Fabienne",
  "Felix",
  "Ferdinand",
  "Florence",
  "Francesco",
  "Francis",
  "Franco",
  "François",
  "Françoise",
  "Frank",
  "Franz",
  "Franziska",
  "Frédéric",
  "Fredy",
  "Fridolin",
  "Friedrich",
  "Fritz",
  "Gabriel",
  "Gabriela",
  "Gabrielle",
  "Georg",
  "Georges",
  "Gérald",
  "Gérard",
  "Gerhard",
  "Gertrud",
  "Gianni",
  "Gilbert",
  "Giorgio",
  "Giovanni",
  "Gisela",
  "Giuseppe",
  "Gottfried",
  "Guido",
  "Guy",
  "Hanna",
  "Hans",
  "Hans-Peter",
  "Hans-Rudolf",
  "Hans-Ulrich",
  "Hansjörg",
  "Hanspeter",
  "Hansruedi",
  "Hansueli",
  "Harry",
  "Heidi",
  "Heinrich",
  "Heinz",
  "Helen",
  "Helena",
  "Helene",
  "Helmut",
  "Henri",
  "Herbert",
  "Hermann",
  "Hildegard",
  "Hubert",
  "Hugo",
  "Ingrid",
  "Irene",
  "Iris",
  "Isabelle",
  "Jacqueline",
  "Jacques",
  "Jakob",
  "Jan",
  "Janine",
  "Jean",
  "Jean-Claude",
  "Jean-Daniel",
  "Jean-François",
  "Jean-Jacques",
  "Jean-Louis",
  "Jean-Luc",
  "Jean-Marc",
  "Jean-Marie",
  "Jean-Paul",
  "Jean-Pierre",
  "Johann",
  "Johanna",
  "Johannes",
  "John",
  "Jolanda",
  "Jörg",
  "Josef",
  "Joseph",
  "Josette",
  "Josiane",
  "Judith",
  "Julia",
  "Jürg",
  "Karin",
  "Karl",
  "Katharina",
  "Klaus",
  "Konrad",
  "Kurt",
  "Laura",
  "Laurence",
  "Laurent",
  "Leo",
  "Liliane",
  "Liselotte",
  "Louis",
  "Luca",
  "Luigi",
  "Lukas",
  "Lydia",
  "Madeleine",
  "Maja",
  "Manfred",
  "Manuel",
  "Manuela",
  "Marc",
  "Marcel",
  "Marco",
  "Margrit",
  "Margrith",
  "Maria",
  "Marianne",
  "Mario",
  "Marion",
  "Markus",
  "Marlène",
  "Marlies",
  "Marlis",
  "Martha",
  "Martin",
  "Martina",
  "Martine",
  "Massimo",
  "Matthias",
  "Maurice",
  "Max",
  "Maya",
  "Michael",
  "Michel",
  "Michele",
  "Micheline",
  "Monica",
  "Monika",
  "Monique",
  "Myriam",
  "Nadia",
  "Nadja",
  "Nathalie",
  "Nelly",
  "Nicolas",
  "Nicole",
  "Niklaus",
  "Norbert",
  "Olivier",
  "Oskar",
  "Otto",
  "Paola",
  "Paolo",
  "Pascal",
  "Patricia",
  "Patrick",
  "Paul",
  "Peter",
  "Petra",
  "Philipp",
  "Philippe",
  "Pia",
  "Pierre",
  "Pierre-Alain",
  "Pierre-André",
  "Pius",
  "Priska",
  "Rainer",
  "Raymond",
  "Regina",
  "Regula",
  "Reinhard",
  "Remo",
  "Renata",
  "Renate",
  "Renato",
  "Rene",
  "René",
  "Reto",
  "Richard",
  "Rita",
  "Robert",
  "Roberto",
  "Roger",
  "Roland",
  "Rolf",
  "Roman",
  "Rosa",
  "Rosemarie",
  "Rosmarie",
  "Rudolf",
  "Ruedi",
  "Ruth",
  "Sabine",
  "Samuel",
  "Sandra",
  "Sandro",
  "Serge",
  "Silvia",
  "Silvio",
  "Simon",
  "Simone",
  "Sonia",
  "Sonja",
  "Stefan",
  "Stephan",
  "Stéphane",
  "Stéphanie",
  "Susanna",
  "Susanne",
  "Suzanne",
  "Sylvia",
  "Sylvie",
  "Theo",
  "Theodor",
  "Therese",
  "Thomas",
  "Toni",
  "Ueli",
  "Ulrich",
  "Urs",
  "Ursula",
  "Verena",
  "Véronique",
  "Victor",
  "Viktor",
  "Vreni",
  "Walter",
  "Werner",
  "Willi",
  "Willy",
  "Wolfgang",
  "Yolande",
  "Yves",
  "Yvette",
  "Yvonne",

];
