import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "../../../routing";

import {
  MapWithoutId,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";
import { t } from "../../../i18n";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import Checkbox from "../../../components/Checkbox";
import LabeledInput, {
  LabeledInputWrapper,
  LabelWrapper,
  InputWrapper,
  Asterisk,
} from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select, { SelectItemInterface } from "../../../components/Select";

import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Title from "../../../styles/Title";
import Input, { InputHelpText } from "../../../styles/Input";

import { useAuth } from "../../../context/authContext";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { DocumentResult } from "expo-document-picker";
import FileUpload from "../../../components/FileUpload";
import { SmallText } from "../../../styles/Text";
import { View } from "react-native";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [floor, setFloor] = useState("");
  const [visible, setVisible] = useState(true);
  const [showLimitMaximumBookedResources, setShowLimitMaximumBookedResources] =
    useState(false);
  const [locations, setLocations] = useState<SelectItemInterface[]>([]);
  const [limitMaximumBookedResources, setLimitMaximumBookedResources] =
    useState("-1");
  const [imageFile, setImageFile] = useState<DocumentResult>();

  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  const userCompany = userState?.companyIds[0];
  useEffect(() => {
    if (!userCompany) {
      return;
    }
    const fetchLocations = async () =>
      await handleRequest(client.listLocations(userCompany));

    fetchLocations().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      setLocations(
        items.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    });
  }, [userCompany, client, handleRequest]);

  const updateLocation = useCallback(
    (newValue) => {
      setLocationId(newValue[0].value);
    },
    [setLocationId]
  );

  const formIsFilledIn = useCallback(
    () =>
      Boolean(name) &&
      Boolean(locationId) &&
      Boolean(imageFile) &&
      imageFile?.type === "success" &&
      !!imageFile.file,
    [name, locationId, imageFile]
  );

  const submitForm = useCallback(async () => {
    const data: MapWithoutId = {
      name,
      locationId,
      floor,
      visible,
      limitMaximumBookedResources: parseInt(limitMaximumBookedResources),
    };
    try {
      const success = await handleRequest(client.addMap(data));
      if (success && success.data.result.map && imageFile?.type === "success") {
        await handleRequest(
          client.addMapFile(success.data.result.map.id, imageFile.file)
        );
        await handleRequest(
          client.addOriginalMapFile(success.data.result.map.id, imageFile.file)
        );
      }

      if (success) {
        history.goBack();
      }
    } catch (error) {
      console.error(error);
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    history,
    client,
    handleRequest,
    name,
    locationId,
    floor,
    visible,
    limitMaximumBookedResources,
    imageFile,
  ]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <Title hasMarginBottom>{t("admin.map.create.form.addTitle")}</Title>
          <LabeledInput label={t("admin.map.create.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.map.create.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.map.create.form.location")}
            style={{ zIndex: 2 }}
            required
          >
            <Select
              required
              isMultiSelect={false}
              items={locations}
              onChange={updateLocation}
              placeholder={t("admin.map.create.form.location")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.map.create.form.floor")}>
            <Input
              placeholder={t("admin.map.create.form.floor")}
              onChangeText={setFloor}
              value={floor}
            />
          </LabeledInput>
          <LabeledInputWrapper>
            <LabelWrapper topAlign>
              <SmallText mediumWeight hasMarginBottom>
                {t("admin.map.create.form.image")}
                <Asterisk mediumWeight>{" *"}</Asterisk>
              </SmallText>
            </LabelWrapper>
            <View style={{ flex: 1, flexGrow: 2 }}>
              <FileUpload
                acceptedFileTypes="image/png, image/jpg"
                placeholder={t("admin.map.create.form.imagePlaceholder")}
                onSubmit={setImageFile}
                style={{ marginBottom: spacing.small }}
              />
              <InputHelpText noRevertMargin>
                {t("admin.map.create.form.imageHelper")}
              </InputHelpText>
            </View>
          </LabeledInputWrapper>
          <LabeledInputWrapper>
            <LabelWrapper />
            <InputWrapper>
              <Checkbox
                label={t("admin.map.create.form.visible")}
                onChange={setVisible}
                checked={visible}
              />
              <InputHelpText>
                {t("admin.map.create.form.helper.visible")}
              </InputHelpText>
            </InputWrapper>
          </LabeledInputWrapper>
          <LabeledInputWrapper>
            <LabelWrapper />
            <InputWrapper>
              <Checkbox
                label={t("admin.map.create.form.limitMaximumBookedResources")}
                onChange={() => {
                  if (showLimitMaximumBookedResources) {
                    setLimitMaximumBookedResources("-1");
                  }
                  setShowLimitMaximumBookedResources(
                    !showLimitMaximumBookedResources
                  );
                }}
                checked={showLimitMaximumBookedResources}
              />
              <InputHelpText>
                {t("admin.map.create.form.helper.limitMaximumBookedResources")}
              </InputHelpText>
            </InputWrapper>
          </LabeledInputWrapper>
          {showLimitMaximumBookedResources && (
            <LabeledInput label="">
              <Input
                onChangeText={setLimitMaximumBookedResources}
                value={
                  limitMaximumBookedResources === "-1"
                    ? ""
                    : limitMaximumBookedResources
                }
              />
            </LabeledInput>
          )}
          <Button
            hasMarginBottom
            disabled={!formIsFilledIn()}
            onPress={submitForm}
          >
            {t("admin.map.create.form.createSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
