import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "../../../routing";

import {
  MapProperties,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";
import { t } from "../../../i18n";

import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select from "../../../components/Select";

import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Title from "../../../styles/Title";
import Input from "../../../styles/Input";

import { useApi, InputValidationError } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";

import { SelectItemInterface } from "../../../components/Select";
import { useAuth } from "../../../context/authContext";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Update: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [floor, setFloor] = useState("");
  const [visible, setVisible] = useState(true);
  const [showLimitMaximumBookedResources, setShowLimitMaximumBookedResources] =
    useState(false);
  const [locations, setLocations] = useState<SelectItemInterface[]>([]);
  const [limitMaximumBookedResources, setLimitMaximumBookedResources] =
    useState("-1");

  const { userState } = useAuth();
  const { id } = useParams();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  const userCompany = userState?.companyIds[0];

  useEffect(() => {
    if (userCompany) {
      const fetchData = async () => {
        const mapResponse = await handleRequest(client.getMap(id));
        if (!mapResponse || !mapResponse.data.result) {
          return;
        }
        const locationsResponse = await handleRequest(
          client.listLocations(userCompany)
        );
        if (!locationsResponse || !locationsResponse.data.result) {
          return;
        }

        const { items } = locationsResponse.data.result;
        const {
          name,
          locationId,
          floor,
          visible,
          limitMaximumBookedResources,
        } = mapResponse.data.result.map;
        const locationItems = items.map((location) => ({
          label: location.name,
          value: location.id,
          isActive: location.id === locationId,
        }));
        setLocations(locationItems);
        setName(name);
        setLocationId(locationId);
        setFloor(floor || "");
        setVisible(Boolean(visible));
        setLimitMaximumBookedResources(
          limitMaximumBookedResources && limitMaximumBookedResources > 0
            ? limitMaximumBookedResources.toString()
            : "-1"
        );
        setShowLimitMaximumBookedResources(
          (limitMaximumBookedResources || 0) > 0
        );
      };

      fetchData();
    }
  }, [
    client,
    userCompany,
    handleRequest,
    id,
    setName,
    setLocationId,
    setFloor,
    setVisible,
    setLimitMaximumBookedResources,
    setShowLimitMaximumBookedResources,
  ]);

  useEffect(() => {
    if (!userCompany) {
      return;
    }
    const fetchLocations = async () =>
      await handleRequest(client.listLocations(userCompany));

    fetchLocations().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      setLocations(
        items.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    });
  }, [userCompany, client, handleRequest]);

  const updateLocation = useCallback(
    (newValue) => {
      setLocationId(newValue[0].value);
    },
    [setLocationId]
  );

  const isFormValid = useCallback(
    () => Boolean(name) && Boolean(locationId),
    [name, locationId]
  );

  const submitForm = useCallback(async () => {
    const data: MapProperties = {
      name,
      locationId,
      floor,
      visible,
      limitMaximumBookedResources: parseInt(limitMaximumBookedResources),
    };
    try {
      const success = await handleRequest(client.updateMap(id, data));

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [
    id,
    history,
    client,
    handleRequest,
    name,
    locationId,
    floor,
    visible,
    limitMaximumBookedResources,
  ]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <Title hasMarginBottom>{t("admin.map.create.form.editTitle")}</Title>
          <LabeledInput label={t("admin.map.create.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.map.create.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.map.create.form.location")}
            style={{ zIndex: 2 }}
            required
          >
            <Select
              required
              isMultiSelect={false}
              items={locations}
              onChange={updateLocation}
              placeholder={t("admin.map.create.form.location")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.map.create.form.floor")}>
            <Input
              placeholder={t("admin.map.create.form.floor")}
              onChangeText={setFloor}
              value={floor}
            />
          </LabeledInput>
          <LabeledInput helperText={t("admin.map.create.form.helper.visible")}>
            <Checkbox
              label={t("admin.map.create.form.visible")}
              onChange={setVisible}
              checked={visible}
            />
          </LabeledInput>
          <LabeledInput
            helperText={t(
              "admin.map.create.form.helper.limitMaximumBookedResources"
            )}
          >
            <Checkbox
              label={t("admin.map.create.form.limitMaximumBookedResources")}
              onChange={() => {
                if (showLimitMaximumBookedResources) {
                  setLimitMaximumBookedResources("-1");
                }
                setShowLimitMaximumBookedResources(
                  !showLimitMaximumBookedResources
                );
              }}
              checked={showLimitMaximumBookedResources}
            />
          </LabeledInput>

          {showLimitMaximumBookedResources && (
            <LabeledInput>
              <Input
                onChangeText={(value) => {
                  const limit = value.replace(/[^0-9]/g, "");
                  setLimitMaximumBookedResources(limit);
                }}
                value={
                  limitMaximumBookedResources === "-1"
                    ? ""
                    : limitMaximumBookedResources
                }
              />
            </LabeledInput>
          )}
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.map.create.form.editSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Update;
