module["exports"] = [
  "Ada",
  "Adela",
  "Adelaida",
  "Adelina",
  "Adina",
  "Adriana",
  "Agata",
  "Aglaia",
  "Agripina",
  "Aida",
  "Alberta",
  "Albertina",
  "Alexandra",
  "Alexandrina",
  "Alida",
  "Alina",
  "Alice",
  "Alis",
  "Alma",
  "Amalia",
  "Amelia",
  "Amanda",
  "Ana",
  "Anabela",
  "Anaida",
  "Anamaria",
  "Anastasia",
  "Anca",
  "Ancuța",
  "Anda",
  "Andra",
  "Andrada",
  "Andreea",
  "Anemona",
  "Aneta",
  "Angela",
  "Anghelina",
  "Anica",
  "Anișoara",
  "Antoaneta",
  "Antonia",
  "Antonela",
  "Anuța",
  "Ariadna",
  "Ariana",
  "Arina",
  "Aristița",
  "Artemisa",
  "Astrid",
  "Atena",
  "Augustina",
  "Aura",
  "Aurelia",
  "Aureliana",
  "Aurica",
  "Aurora",
  "Beatrice",
  "Betina",
  "Bianca",
  "Blanduzia",
  "Bogdana",
  "Brândușa",
  "Camelia",
  "Carina",
  "Carla",
  "Carmen",
  "Carmina",
  "Carolina",
  "Casandra",
  "Casiana",
  "Caterina",
  "Catinca",
  "Catrina",
  "Catrinel",
  "Cătălina",
  "Cecilia",
  "Celia",
  "Cerasela",
  "Cezara",
  "Cipriana",
  "Clara",
  "Clarisa",
  "Claudia",
  "Clementina",
  "Cleopatra",
  "Codrina",
  "Codruța",
  "Constantina",
  "Constanța",
  "Consuela",
  "Coralia",
  "Corina",
  "Cornelia",
  "Cosmina",
  "Crenguța",
  "Crina",
  "Cristina",
  "Daciana",
  "Dafina",
  "Daiana",
  "Dalia",
  "Dana",
  "Daniela",
  "Daria",
  "Dariana",
  "Delia",
  "Demetra",
  "Denisa",
  "Despina",
  "Diana",
  "Dida",
  "Didina",
  "Dimitrina",
  "Dina",
  "Dochia",
  "Doina",
  "Domnica",
  "Dora",
  "Doriana",
  "Dorina",
  "Dorli",
  "Draga",
  "Dumitra",
  "Dumitrana",
  "Ecaterina",
  "Eftimia",
  "Elena",
  "Eleonora",
  "Eliana",
  "Elisabeta",
  "Elisaveta",
  "Eliza",
  "Elodia",
  "Elvira",
  "Emilia",
  "Emanuela",
  "Erica",
  "Estera",
  "Eufrosina",
  "Eugenia",
  "Eusebia",
  "Eva",
  "Evanghelina",
  "Evelina",
  "Fabia",
  "Fabiana",
  "Felicia",
  "Filofteia",
  "Fiona",
  "Flavia",
  "Floare",
  "Floarea",
  "Flora",
  "Floriana",
  "Florica",
  "Florina",
  "Florentina",
  "Florența",
  "Francesca",
  "Frusina",
  "Gabriela",
  "Geanina",
  "Gențiana",
  "Georgeta",
  "Georgia",
  "Georgiana",
  "Geta",
  "Gherghina",
  "Gianina",
  "Gina",
  "Giorgiana",
  "Grațiana",
  "Grațiela",
  "Hortensia",
  "Henrieta",
  "Heracleea",
  "Iasmina",
  "Ica",
  "Ileana",
  "Ilinca",
  "Ilona",
  "Ina",
  "Ioana",
  "Ioanina",
  "Iolanda",
  "Ionela",
  "Ionelia",
  "Iosefina",
  "Irina",
  "Iridenta",
  "Iris",
  "Isabela",
  "Iulia",
  "Iuliana",
  "Iustina",
  "Ivona",
  "Izabela",
  "Jana",
  "Janeta",
  "Janina",
  "Jasmina",
  "Jeana",
  "Julia",
  "Julieta",
  "Larisa",
  "Laura",
  "Laurenția",
  "Lavinia",
  "Lăcrămioara",
  "Leana",
  "Lelia",
  "Leontina",
  "Leopoldina",
  "Letiția",
  "Lia",
  "Liana",
  "Lidia",
  "Ligia",
  "Lili",
  "Liliana",
  "Lioara",
  "Livia",
  "Loredana",
  "Lorelei",
  "Lorena",
  "Luana",
  "Lucia",
  "Luciana",
  "Lucreția",
  "Ludovica",
  "Ludmila",
  "Luiza",
  "Luminița",
  "Magdalena",
  "Maia",
  "Manuela",
  "Mara",
  "Marcela",
  "Marga",
  "Margareta",
  "Marcheta",
  "Maria",
  "Mariana",
  "Maricica",
  "Marilena",
  "Marina",
  "Marinela",
  "Marioara",
  "Marta",
  "Matilda",
  "Malvina",
  "Mădălina",
  "Mălina",
  "Mărioara",
  "Măriuca",
  "Melania",
  "Melina",
  "Mihaela",
  "Milena",
  "Mina",
  "Minodora",
  "Mioara",
  "Mirabela",
  "Mirela",
  "Mirona",
  "Miruna",
  "Mona",
  "Monalisa",
  "Monica",
  "Nadia",
  "Narcisa",
  "Natalia",
  "Natașa",
  "Noemi",
  "Nicoleta",
  "Niculina",
  "Nidia",
  "Nora",
  "Norica",
  "Oana",
  "Octavia",
  "Octaviana",
  "Ofelia",
  "Olga",
  "Olimpia",
  "Olivia",
  "Ortansa",
  "Otilia",
  "Ozana",
  "Pamela",
  "Paraschiva",
  "Paula",
  "Paulica",
  "Paulina",
  "Patricia",
  "Petronela",
  "Petruța",
  "Pompilia",
  "Profira",
  "Rada",
  "Rafila",
  "Raluca",
  "Ramona",
  "Rebeca",
  "Renata",
  "Rica",
  "Roberta",
  "Robertina",
  "Rodica",
  "Romanița",
  "Romina",
  "Roza",
  "Rozalia",
  "Roxana",
  "Roxelana",
  "Ruxanda",
  "Ruxandra",
  "Sabina",
  "Sabrina",
  "Safta",
  "Salomea",
  "Sanda",
  "Saveta",
  "Savina",
  "Sânziana",
  "Semenica",
  "Severina",
  "Sidonia",
  "Silvia",
  "Silvana",
  "Silviana",
  "Simina",
  "Simona",
  "Smaranda",
  "Sofia",
  "Sonia",
  "Sorana",
  "Sorina",
  "Speranța",
  "Stana",
  "Stanca",
  "Stela",
  "Steliana",
  "Steluța",
  "Suzana",
  "Svetlana",
  "Ștefana",
  "Ștefania",
  "Tamara",
  "Tania",
  "Tatiana",
  "Teea",
  "Teodora",
  "Teodosia",
  "Teona",
  "Tiberia",
  "Timea",
  "Tinca",
  "Tincuța",
  "Tudora",
  "Tudorița",
  "Tudosia",
  "Valentina",
  "Valeria",
  "Vanesa",
  "Varvara",
  "Vasilica",
  "Venera",
  "Vera",
  "Veronica",
  "Veta",
  "Vicenția",
  "Victoria",
  "Violeta",
  "Viorela",
  "Viorica",
  "Virginia",
  "Viviana",
  "Voichița",
  "Xenia",
  "Zaharia",
  "Zamfira",
  "Zaraza",
  "Zenobia",
  "Zenovia",
  "Zina",
  "Zoe"
];
