import React, {
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  Company,
  CompanyProperties,
  InlineResponse400Errors,
} from '../../../../bookmydesk-api-sdk-typescript-axios';
import azureActiveDirectoryIcon from '../../assets/icons/azure-active-directory.svg';
import lunchIcon from '../../assets/icons/ic_reservation_lunch.svg';
import buildingIcon from '../../assets/icons/icon_company_admin.svg';
import settingIcon from '../../assets/icons/icon_settings_admin.svg';
import meetingRoomIcon from '../../assets/icons/meeting_room.svg';
import microsoftOutlookIcon from '../../assets/icons/microsoft-outlook-icon.svg';
import microsoftIcon from '../../assets/icons/microsoft.svg';
import surfconextIcon from '../../assets/icons/surfconext_logo.svg';
import saltoIcon from '../../assets/icons/salto_logo.svg';
import googleSsoIcon from '../../assets/icons/google_logo.svg';
import googleSyncIcon from '../../assets/icons/google_cloud_logo.svg';
import googleCalendarIcon from '../../assets/icons/google_calendar_logo.svg';
import officeChairIcon from '../../assets/icons/office_chair.svg';
import visitorIcon from '../../assets/icons/icon_visitors_admin.svg';
import checkinIcon from '../../assets/icons/icon_checkin_admin.svg';
import reservationsIcon from '../../assets/icons/icon_reservations_admin.svg';
import allowanceIcon from '../../assets/icons/icon_allowance_admin.svg';
import facilityIcon from '../../assets/icons/icon_fm_admin.svg';
import workingFromHomeIcon from '../../assets/icons/icon_home_admin.svg';
import Button from '../../components/Button';
import Container from '../../components/Container';
import ConfirmDialog from "../../components/Dialog/ConfirmDialog";
import Dialog from "../../components/Dialog";
import Grid, { Column } from '../../components/Grid';
import HeaderCard from '../../components/HeaderCard';
import InputValidationErrors from '../../components/InputValidationErrors';
import LabeledInput from '../../components/LabeledInput';
import LoadOverlay from '../../components/LoadOverlay';
import Notification from '../../components/Notification';
import PageSelector from '../../components/PageSelector';
import PickerInput from '../../components/PickerInput';
import Pill from '../../components/Pill';
import Toggle from '../../components/Toggle';
import { useAuth } from '../../context/authContext';
import { useApi, InputValidationError } from '../../hooks/useApi';
import { useClient } from '../../hooks/useClient';
import useQuery from '../../hooks/useQuery';
import { t } from '../../i18n';
import InnerCard from '../../styles/Card/InnerCard';
import Input from '../../styles/Input';
import { OverviewDeleteModalButtonWrapper, OverviewHeader } from '../../styles/Overview';
import Text, { ExtraSmallText } from '../../styles/Text';
import { ExtraSmallTextGood } from '../../styles/Text/extraSmallText';
import { color, spacing } from '../../styles/theme';
import { SmallTitle } from '../../styles/Title';
import Title from '../../styles/Title/title';
import Link from "../../components/Link";
import { isValidEmail } from '../../utils';
import { Linking, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { ArrayElement, ThenArg } from '../../types';
import { ResourcePropertiesResourceTypeEnum } from '../../../bookmydesk-api-sdk-typescript-axios';
const Spacer = styled.View`
  margin-bottom: 10px;
`;

const SeatSettingRow = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const PickerInputWrapper = styled(View)`
  width: 45%;
  margin-right: ${spacing.medium};
`;

const HeaderCardTitleSuffixWrapper = styled.View`
  margin-left: auto;
  margin-right: -${spacing.medium};
`;

const expirationItems = [
  ...[0, 5, 10, 15, 20, 25, 30, 45, 60, 90].map((minutes) => ({
    label: `${minutes} ${t("general.minutes")}`,
    value: `${minutes}`,
  })),
];

const CardTitle = styled(Text)`
  font-size: 14px;
  margin-bottom: 1.2rem;
  line-height: 28px;
  color: #161616;
`;

const CompanySettings: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const { companyPages, userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const query = useQuery();
  const [
    showAzureActiveDirectoryConnectedMessage,
    setShowAzureActiveDirectoryConnectedMessage,
  ] = useState(query.get("success") === "azure-active-directory-connected");
  const [
    showAzureActiveDirectoryNotConnectedMessage,
    setShowAzureActiveDirectoryNotConnectedMessage,
  ] = useState(query.get("error") === "azure-active-directory-not-connected");
  const [
    showAzurePlacesConnectedMessage,
    setShowAzurePlacesConnectedMessage,
  ] = useState(query.get("success") === "azure-places-connected");
  const [
    showAzurePlacesNotConnectedMessage,
    setShowAzurePlacesNotConnectedMessage,
  ] = useState(query.get("error") === "azure-places-not-connected");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [daysAdvance, setDaysAdvance] = useState("");
  const [deskLength, setDesksLength] = useState("");
  const [lunchLength, setLunchLength] = useState("");
  const [meetingLength, setMeetingLength] = useState("");
  const [visitorLength, setVisitorLength] = useState("");
  const [resourceLength, setResourceLength] = useState("");
  const [forceTimeSlotsFeature, setForceTimeSlotsFeature] = useState(false);
  const [parkingSpotsAsResourceEnabled, setParkingSpotsAsResourceEnabled] = useState(false);
  const [hideCheckInTimeEnabled, setHideCheckInTimeEnabled] = useState(false);
  const [checkInFeature, setCheckInFeature] = useState(false);
  const [lunchFeature, setLunchFeature] = useState(false);
  const [resourceFeature, setResourceFeature] = useState(false);
  const [resources, setResources] = useState<ResourceItem[]>([]);
  /* compensation module */
  const [compensationEnabled, setCompensationEnabled] = useState(false);
  const [compensationCutOffDay, setCompensationCutOffDay] = useState("");
  const [compensationDailyHomeCompensation, setCompensationDailyHomeCompensation] = useState("");
  const [compensationDistanceUnitCompensation, setCompensationDistanceUnitCompensation] = useState("");
  const [compensationExportEmail, setCompensationExportEmail] = useState("");
  const [compensationMaxDailyDistance, setCompensationMaxDailyDistance] = useState("");
  /* visitor module */
  const [visitorFeature, setVisitorFeature] = useState(false);
  const [homeEnabled, setHomeEnabled] = useState(false);
  const [
    visitorReservationConfirmEmailEnabled,
    setVisitorReservationConfirmEmailEnabled,
  ] = useState(false);
  const [facilitymanagementFeature, setFacilitymanagementFeature] =
    useState(false);
  const [reservationForUserEnabled, setReservationForUserEnabled] =
    useState(false);
  const [meetingFeature, setMeetingFeature] = useState(false);
  const [recurringReservationFeature, setRecurringReservationFeature] =
    useState(false); 
  const [recurringResourceReservationFeature, setRecurringResourceReservationFeature] =
    useState(false);
  const [geofenceFeature, setGeofenceFeature] = useState(false);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [deskExpirationTime, setDeskExpirationTime] = useState("");
  const [deskAutoCheckoutTime, setDeskAutoCheckoutTime] = useState("");
  const [lunchExpirationTime, setLunchExpirationTime] = useState("");
  const [lunchAutoCheckoutTime, setLunchAutoCheckoutTime] = useState("");
  const [meetingExpirationTime, setMeetingExpirationTime] = useState("");
  const [meetingAutoCheckoutTime, setMeetingAutoCheckoutTime] = useState("");
  const [ssoDomain, setSsoDomain] = useState("");

  const [saltoUsername, setSaltoUsername] = useState("");
  const [saltoPassword, setSaltoPassword] = useState("");

  const [surfconextSsoEnabled, setSurfconextSsoEnabled] = useState(false);
  const [microsoftSsoEnabled, setMicrosoftSsoEnabled] = useState(false);
  const [microsoftSyncEnabled, setMicrosoftSyncEnabled] = useState(false);
  const [microsoftPlacesEnabled, setMicrosoftPlacesEnabled] = useState(false);
  const [azureActiveDirectoryActive, setAzureActiveDirectoryActive] =
    useState(false);
  const [azurePlacesActive, setAzurePlacesActive] =
    useState(false);

  const userCompany = userState?.companyIds[0];

  type ResourceItem = ArrayElement<
    ThenArg<ReturnType<typeof client.listResources>>["data"]["result"]["items"]
  >;

  /* set maximum days ahead */
  const maxDaysAhead = 730;
  const maxDaysAheadMeetings = 365;

  /* set maximum days of the month */
  const minMonthDay = 1;
  const maxMonthDay = 31;

  /* deleteModal */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const [companyId, setCompanyId] = useState("");
  const [tokenType, setTokenType] = useState("");

  useEffect(() => {
    if (!userCompany) {
      return;
    }

    const fetchCompany = async () =>
      await handleRequest(client.getCompany(userCompany));

    const fetchResources = async () => {
      if (userCompany) {
        return await handleRequest(
          client.listResources(userCompany, 1000, 0)
        );
      }
    };

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { company } = result.data.result;

      setName(company.name);
      setFirstName(company.contactFirstname);
      setLastName(company.contactLastname);
      setEmail(company.contactEmail);
      setPhoneNumber(company.contactPhone);
      /* compensation module */
      setCompensationEnabled(company.compensationEnabled);
      setCompensationCutOffDay(company.compensationCutOffDay?.toString());
      setCompensationDailyHomeCompensation(company.compensationDailyHomeCompensation?.toString());
      setCompensationDistanceUnitCompensation(company.compensationDistanceUnitCompensation?.toString());
      setCompensationExportEmail(company.compensationExportEmail);
      setCompensationMaxDailyDistance(company.compensationMaxDailyDistance);
      /* general settings */
      setDaysAdvance(company.reservationWindowLength.toString());
      setDesksLength(company.reservationWindowLengthDesk?.toString());
      setLunchLength(company.reservationWindowLengthLunch?.toString());
      setMeetingLength(company.reservationWindowLengthMeeting?.toString());
      setVisitorLength(company.reservationWindowLengthVisitor?.toString());
      setResourceLength(company.reservationWindowLengthResource?.toString());
      setCheckInFeature(Boolean(company?.checkInEnabled));
      setForceTimeSlotsFeature(Boolean(company?.forceTimeslotUse));
      setParkingSpotsAsResourceEnabled(Boolean(company?.parkingSpotsAsResourceEnabled));
      setHideCheckInTimeEnabled(Boolean(company?.hideCheckInTimeEnabled));
      setLunchFeature(Boolean(company?.lunchEnabled));
      setVisitorFeature(Boolean(company?.visitorsEnabled));
      setHomeEnabled(Boolean(company?.homeEnabled));
      setVisitorReservationConfirmEmailEnabled(
        Boolean(company?.visitorReservationConfirmEmailEnabled)
      );
      setFacilitymanagementFeature(Boolean(company?.facilitymanagementEnabled));
      setReservationForUserEnabled(Boolean(company?.reservationForUserEnabled));
      setMeetingFeature(Boolean(company?.meetingEnabled));
      setRecurringReservationFeature(Boolean(company?.recurringEnabled));
      setRecurringResourceReservationFeature(Boolean(company?.recurringResourceReservationEnabled));
      setGeofenceFeature(Boolean(company?.forceGeofence));
      setDeskExpirationTime(
        typeof company.deskExpirationTime === "undefined"
          ? ""
          : `${company.deskExpirationTime}`
      );
      setDeskAutoCheckoutTime(
        typeof company.deskAutoCheckoutTime === "undefined"
          ? ""
          : `${company.deskAutoCheckoutTime}`
      );
      setLunchExpirationTime(
        typeof company.lunchExpirationTime === "undefined"
          ? ""
          : `${company.lunchExpirationTime}`
      );
      setLunchAutoCheckoutTime(
        typeof company.lunchAutoCheckoutTime === "undefined"
          ? ""
          : `${company.lunchAutoCheckoutTime}`
      );
      setMeetingExpirationTime(
        typeof company.meetingExpirationTime === "undefined"
          ? ""
          : `${company.meetingExpirationTime}`
      );
      setMeetingAutoCheckoutTime(
        typeof company.meetingAutoCheckoutTime === "undefined"
          ? ""
          : `${company.meetingAutoCheckoutTime}`
      );
      setSsoDomain(company.ssoDomain || "");
      setSaltoUsername(company.saltoUsername || "");
      setSaltoPassword(company.saltoPassword || "");
      setSurfconextSsoEnabled(Boolean(company.surfconextSsoEnabled));
      setMicrosoftSsoEnabled(Boolean(company.microsoftSsoEnabled));
      setMicrosoftSyncEnabled(Boolean(company.microsoftSyncEnabled));
      setMicrosoftPlacesEnabled(Boolean(company.microsoftPlacesEnabled));
      setAzureActiveDirectoryActive(
        typeof company.tokens.find(
          (token) => token.token_type === "microsoft_graph_admin"
        ) !== "undefined"
      );
      setAzurePlacesActive(
        typeof company.tokens.find(
          (token) => token.token_type === "microsoft_graph_rooms"
        ) !== "undefined"
      );
    });

    fetchResources().then((result) => {
      setResources(result?.data.result?.items || []);
    });
  }, [client, handleRequest, userCompany]);

  const isFormValid = useCallback(
    () =>
      Boolean(name) &&
      Boolean(firstName) &&
      Boolean(lastName) &&
      isValidEmail(email) &&
      Boolean(phoneNumber) &&
      Boolean(daysAdvance),
    [name, firstName, lastName, email, phoneNumber, daysAdvance]
  );

   const openDeleteModal = (companyId, tokenType) => () => {
     //setShowDeleteModal(true);
     removeTokens(companyId, tokenType);
   };

  const removeTokens = useCallback(async (companyId, tokenType) => {
    //setShowDeleteModal(false);
    const response = await handleRequest(client.removeCompanyTokens(companyId, tokenType));
    if (response && response.status === 204) {
      //fetch();
      if(tokenType && tokenType === 'microsoft_graph_admin') {
        setAzureActiveDirectoryActive(false);
      }
      if(tokenType && tokenType === 'microsoft_graph_rooms') {
        setAzurePlacesActive(false);
      }
    }

    },
    [handleRequest, client, setShowDeleteModal]
  );

  const submitForm = useCallback(async () => {
    const company: CompanyProperties = {
      name,
      contactFirstname: firstName,
      contactLastname: lastName,
      contactEmail: email,
      contactPhone: phoneNumber,
      reservationWindowLength: parseInt(daysAdvance),
      reservationWindowLengthDesk: parseInt(deskLength),
      reservationWindowLengthLunch: parseInt(lunchLength),
      reservationWindowLengthMeeting: parseInt(meetingLength),
      reservationWindowLengthVisitor: parseInt(visitorLength),
      reservationWindowLengthResource: parseInt(resourceLength),
      compensationCutOffDay: parseInt(compensationCutOffDay),
      compensationEnabled: Boolean(compensationEnabled),
      compensationExportEmail: compensationExportEmail,
      compensationDailyHomeCompensation: parseFloat(compensationDailyHomeCompensation),
      compensationDistanceUnitCompensation: parseFloat(compensationDistanceUnitCompensation),
      compensationMaxDailyDistance: parseInt(compensationMaxDailyDistance),
      lunchEnabled: Boolean(lunchFeature),
      visitorsEnabled: Boolean(visitorFeature),
      homeEnabled: Boolean(homeEnabled),
      visitorReservationConfirmEmailEnabled: Boolean(
        visitorReservationConfirmEmailEnabled
      ),
      facilitymanagementEnabled: Boolean(facilitymanagementFeature),
      reservationForUserEnabled: Boolean(reservationForUserEnabled),
      meetingEnabled: Boolean(meetingFeature),
      checkInEnabled: Boolean(checkInFeature),
      forceTimeslotUse: Boolean(forceTimeSlotsFeature),
      parkingSpotsAsResourceEnabled: Boolean(parkingSpotsAsResourceEnabled),
      hideCheckInTimeEnabled: Boolean(hideCheckInTimeEnabled),
      forceGeofence: Boolean(geofenceFeature),
      recurringEnabled: Boolean(recurringReservationFeature),
      recurringResourceReservationEnabled: Boolean(recurringResourceReservationFeature),
      deskExpirationTime:
        deskExpirationTime === "" ? null : Number.parseInt(deskExpirationTime),
      deskAutoCheckoutTime:
        deskAutoCheckoutTime === ""
          ? null
          : Number.parseInt(deskAutoCheckoutTime),
      lunchExpirationTime:
        lunchExpirationTime === ""
          ? null
          : Number.parseInt(lunchExpirationTime),
      lunchAutoCheckoutTime:
        lunchAutoCheckoutTime === ""
          ? null
          : Number.parseInt(lunchAutoCheckoutTime),
      meetingExpirationTime:
        meetingExpirationTime === ""
          ? null
          : Number.parseInt(meetingExpirationTime),
      meetingAutoCheckoutTime:
        meetingAutoCheckoutTime === ""
          ? null
          : Number.parseInt(meetingAutoCheckoutTime),
    };
    try {
      const success = await handleRequest(
        client.updateCompany(userCompany, company)
      );

      if (success) {
        setShowUpdateMessage(true);
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [name, firstName, lastName, email, phoneNumber, daysAdvance, deskLength, lunchLength, meetingLength, visitorLength, resourceLength, compensationCutOffDay, compensationEnabled, compensationExportEmail, compensationDailyHomeCompensation, compensationDistanceUnitCompensation, compensationMaxDailyDistance, lunchFeature, visitorFeature, homeEnabled, visitorReservationConfirmEmailEnabled, facilitymanagementFeature, reservationForUserEnabled, meetingFeature, checkInFeature, forceTimeSlotsFeature, parkingSpotsAsResourceEnabled, hideCheckInTimeEnabled, geofenceFeature, recurringReservationFeature, recurringResourceReservationFeature, deskExpirationTime, deskAutoCheckoutTime, lunchExpirationTime, lunchAutoCheckoutTime, meetingExpirationTime, meetingAutoCheckoutTime, handleRequest, client, userCompany]);

  const updateCompensationCutOffDay = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxMonthDay, Number(days));
      setCompensationCutOffDay(daysMax);
    },
    [setCompensationCutOffDay]
  );

  const updateCompensationDailyHomeCompensation = useCallback(
    (newValue) => {
      const price = newValue.replace(/[^0-9.]/g, "");
      setCompensationDailyHomeCompensation(price);
    },
    [setCompensationDailyHomeCompensation]
  );

  const updateDaysAdvance = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setDaysAdvance(days);
    },
    [setDaysAdvance]
  );

  const updateDeskLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxDaysAhead, Number(days));
      setDesksLength(daysMax);
    },
    [setDesksLength]
  );

  const updateLunchLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxDaysAhead, Number(days));
      setLunchLength(daysMax);
    },
    [setLunchLength]
  );

  const updateMeetingLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxDaysAheadMeetings, Number(days));
      setMeetingLength(daysMax);
    },
    [setMeetingLength]
  );

  const updateVisitorLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxDaysAhead, Number(days));
      setVisitorLength(daysMax);
    },
    [setVisitorLength]
  );

  const updateResourceLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      const daysMax = Math.min(maxDaysAhead, Number(days));
      setResourceLength(daysMax);
    },
    [setResourceLength]
  );

  const provideAzureActiveDirectoryAccess = useCallback(async () => {
    try {
      const response = await handleRequest(client.redirToMSAdmin());
      const authUrl = response?.data.result.authUrl;
      if (!authUrl) {
        throw new Error("No authUrl retrieved");
      }

      window.location.assign(authUrl);
    } catch (error) {
      console.error(error);
      setShowAzureActiveDirectoryNotConnectedMessage(true);
    }
  }, [handleRequest, client]);

  const provideAzurePlacesAccess = useCallback(async () => {
    try {
      const response = await handleRequest(client.redirToMSAdmin('places'));
      const authUrl = response?.data.result.authUrl;
      if (!authUrl) {
        throw new Error("No authUrl retrieved");
      }

      window.location.assign(authUrl);
    } catch (error) {
      console.error(error);
      setShowAzurePlacesNotConnectedMessage(true);
    }
  }, [handleRequest, client]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <ConfirmDialog
        confirm={removeTokens}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.microsoft.delete.warning")}
      />
      <Container>
        <OverviewHeader>
          <Title>{t("admin.company.form.editTitle", { name })}</Title>
          <Button disabled={!isFormValid()} onPress={submitForm}>
            {t("admin.company.form.editSubmit")}
          </Button>
        </OverviewHeader>
        <PageSelector pages={companyPages} />
        {Boolean(error) && (
          <InputValidationErrors
            errors={errors}
            closeNotification={dismissError}
          />
        )}
        {showUpdateMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowUpdateMessage(false)}
          >
            {t("general.savedSuccess")}
          </Notification>
        ) : null}
        {showAzureActiveDirectoryConnectedMessage ? (
          <Notification
            type="success"
            closeNotification={() =>
              setShowAzureActiveDirectoryConnectedMessage(false)
            }
          >
            {t("admin.company.form.azureActiveDirectoryConnectedMessage")}
          </Notification>
        ) : null}
        {showAzureActiveDirectoryNotConnectedMessage ? (
          <Notification
            type="danger"
            closeNotification={() =>
              setShowAzureActiveDirectoryNotConnectedMessage(false)
            }
          >
            {t("admin.company.form.azureActiveDirectoryNotConnectedMessage")}
          </Notification>
        ) : null}
        {showAzurePlacesConnectedMessage ? (
          <Notification
            type="success"
            closeNotification={() =>
              setShowAzurePlacesConnectedMessage(false)
            }
          >
            {t("admin.company.form.azurePlacesConnectedMessage")}
          </Notification>
        ) : null}
        {showAzurePlacesNotConnectedMessage ? (
          <Notification
            type="danger"
            closeNotification={() =>
              setShowAzurePlacesNotConnectedMessage(false)
            }
          >
            {t("admin.company.form.azurePlacesNotConnectedMessage")}
          </Notification>
        ) : null}
        {showDeleteMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        )}
        <Grid>
          <Column columns={3}>
            <HeaderCard
              icon={checkinIcon}
              title={t("admin.company.form.checkIn")}
              titleSuffix={
                <HeaderCardTitleSuffixWrapper>
                  <Toggle
                    onChange={setCheckInFeature}
                    toggleValue={checkInFeature}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
              disableHorizontalDivider={!checkInFeature}
            >
              {checkInFeature && (
                <InnerCard hasMarginBottom>
                  <SeatSettingRow>
                    <Toggle
                      label={t("admin.company.form.hideCheckInTimeEnabled")}
                      onChange={setHideCheckInTimeEnabled}
                      toggleValue={hideCheckInTimeEnabled}
                    />
                  </SeatSettingRow>
                  <ExtraSmallTextGood
                    italic
                  >
                    {t("admin.company.form.helper.checkinNotShown")}
                  </ExtraSmallTextGood>

                  <Toggle
                    label={t("admin.company.form.geofence")}
                    onChange={setGeofenceFeature}
                    toggleValue={geofenceFeature}
                  />
                </InnerCard>
              )}
            </HeaderCard>
            <HeaderCard
              icon={officeChairIcon}
              badgeColor={color.quinaryLight}
              title={t("admin.company.form.deskTitle")}
              disableHorizontalDivider={!checkInFeature}
            >
              {checkInFeature && (
                <>
                  <InnerCard hasMarginBottom>
                    <Text mediumWeight hasMarginBottom>
                      {t("admin.company.form.reservationsExpire")}
                    </Text>
                    <SeatSettingRow>
                      <PickerInputWrapper>
                        <PickerInput
                          noMarginBottom
                          selectedValue={deskExpirationTime}
                          items={[
                            {
                              label: t("general.not"),
                              value: "",
                            },
                            ...expirationItems,
                          ]}
                          onValueChange={setDeskExpirationTime}
                        />
                      </PickerInputWrapper>
                      <ExtraSmallText>
                        {t("admin.company.form.afterStartTime")}
                      </ExtraSmallText>
                    </SeatSettingRow>
                  </InnerCard>
                  <InnerCard>
                    <Text mediumWeight hasMarginBottom>
                      {t("admin.company.form.autoCheckOut")}
                    </Text>
                    <SeatSettingRow>
                      <PickerInputWrapper>
                        <PickerInput
                          noMarginBottom
                          selectedValue={deskAutoCheckoutTime}
                          items={expirationItems}
                          onValueChange={setDeskAutoCheckoutTime}
                        />
                      </PickerInputWrapper>
                      <ExtraSmallText>
                        {t("admin.company.form.afterEndTime")}
                      </ExtraSmallText>
                    </SeatSettingRow>
                  </InnerCard>
                </>
              )}
            </HeaderCard>
            <HeaderCard
              icon={meetingRoomIcon}
              title={t("admin.company.form.meetingTitle")}
              badgeColor={color.secondaryLight}
              disableHorizontalDivider={!meetingFeature || !checkInFeature}
              titleSuffix={
                <HeaderCardTitleSuffixWrapper>
                  <Toggle
                    onChange={setMeetingFeature}
                    toggleValue={meetingFeature}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
            >
              {checkInFeature && meetingFeature && (
                <>
                  <InnerCard hasMarginBottom>
                    <Text mediumWeight hasMarginBottom>
                      {t("admin.company.form.reservationsExpire")}
                    </Text>
                    <SeatSettingRow>
                      <PickerInputWrapper>
                        <PickerInput
                          noMarginBottom
                          selectedValue={meetingExpirationTime}
                          items={[
                            {
                              label: t("general.not"),
                              value: "",
                            },
                            ...expirationItems,
                          ]}
                          onValueChange={setMeetingExpirationTime}
                        />
                      </PickerInputWrapper>
                      <ExtraSmallText>
                        {t("admin.company.form.afterStartTime")}
                      </ExtraSmallText>
                    </SeatSettingRow>
                  </InnerCard>
                  <InnerCard>
                    <Text mediumWeight hasMarginBottom>
                      {t("admin.company.form.autoCheckOut")}
                    </Text>
                    <SeatSettingRow>
                      <PickerInputWrapper>
                        <PickerInput
                          noMarginBottom
                          selectedValue={meetingAutoCheckoutTime}
                          items={expirationItems}
                          onValueChange={setMeetingAutoCheckoutTime}
                        />
                      </PickerInputWrapper>
                      <ExtraSmallText>
                        {t("admin.company.form.afterEndTime")}
                      </ExtraSmallText>
                    </SeatSettingRow>
                  </InnerCard>
                </>
              )}
            </HeaderCard>
            <HeaderCard
              icon={lunchIcon}
              title={t("admin.company.form.lunchTitle")}
              badgeColor={color.tertiaryLight}
              disableHorizontalDivider={!lunchFeature || !checkInFeature}
              titleSuffix={
                <HeaderCardTitleSuffixWrapper>
                  <Toggle
                    onChange={setLunchFeature}
                    toggleValue={lunchFeature}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
            >
              {lunchFeature && checkInFeature && (
                <>
                  <InnerCard hasMarginBottom>
                    <Text mediumWeight hasMarginBottom>
                      {t("admin.company.form.reservationsExpire")}
                    </Text>
                    <SeatSettingRow>
                      <PickerInputWrapper>
                        <PickerInput
                          noMarginBottom
                          selectedValue={lunchExpirationTime}
                          items={[
                            {
                              label: t("general.not"),
                              value: "",
                            },
                            ...expirationItems,
                          ]}
                          onValueChange={setLunchExpirationTime}
                        />
                      </PickerInputWrapper>
                      <ExtraSmallText>
                        {t("admin.company.form.afterStartTime")}
                      </ExtraSmallText>
                    </SeatSettingRow>
                  </InnerCard>
                  <InnerCard>
                    <Text mediumWeight hasMarginBottom>
                      {t("admin.company.form.autoCheckOut")}
                    </Text>
                    <SeatSettingRow>
                      <PickerInputWrapper>
                        <PickerInput
                          noMarginBottom
                          selectedValue={lunchAutoCheckoutTime}
                          items={expirationItems}
                          onValueChange={setLunchAutoCheckoutTime}
                        />
                      </PickerInputWrapper>
                      <ExtraSmallText>
                        {t("admin.company.form.afterEndTime")}
                      </ExtraSmallText>
                    </SeatSettingRow>
                  </InnerCard>
                </>
              )}
            </HeaderCard>
          </Column>
          <Column columns={3}>
            <HeaderCard
              icon={visitorIcon}
              title={t("admin.company.form.visitors")}
              disableHorizontalDivider={!visitorFeature}
              titleSuffix={
                <HeaderCardTitleSuffixWrapper>
                  <Toggle
                    onChange={setVisitorFeature}
                    toggleValue={visitorFeature}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
            >
              {visitorFeature && (
                <>
                  <InnerCard hasMarginBottom>
                    <SeatSettingRow>
                      <Toggle
                        label={t(
                          "admin.company.form.visitorReservationConfirmEmailEnabled"
                        )}
                        onChange={setVisitorReservationConfirmEmailEnabled}
                        toggleValue={visitorReservationConfirmEmailEnabled}
                      />
                    </SeatSettingRow>
                    <ExtraSmallTextGood
                      italic
                    >
                      {t("admin.company.form.helper.visitorForLocation")}
                    </ExtraSmallTextGood>
                  </InnerCard>
                </>
              )}
            </HeaderCard>
            <HeaderCard
              icon={workingFromHomeIcon}
              title={t("admin.company.form.home")}
              disableHorizontalDivider
              titleSuffix={
                <HeaderCardTitleSuffixWrapper marginTop="10">
                  <Toggle
                    onChange={setHomeEnabled}
                    toggleValue={homeEnabled}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
            >
              <Spacer />
              <ExtraSmallTextGood
                italic
                hasMarginTop
              >
                {t("admin.company.form.helper.workingFromHome")}
              </ExtraSmallTextGood>
            </HeaderCard>

            <HeaderCard
              icon={facilityIcon}
              title={t("admin.company.form.facilitymanagement")}
              disableHorizontalDivider
              titleSuffix={
                <HeaderCardTitleSuffixWrapper marginTop="10">
                  <Toggle
                    onChange={setFacilitymanagementFeature}
                    toggleValue={facilitymanagementFeature}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
            >
              <ExtraSmallTextGood
                italic
                hasMarginTop
              >
                {t("admin.company.form.helper.facilitymanagement")}
              </ExtraSmallTextGood>
            </HeaderCard>

            <HeaderCard
              icon={microsoftIcon}
              iconAsBadge={true}
              badgeColor="#F7F9FD"
              iconWidth={13.5}
              iconHeight={13.5}
              title={t(`admin.company.form.microsoftSSO`)}
              titleSuffix={
                <View style={{ marginLeft: "auto" }}>
                  <Pill
                    backgroundColor={
                      microsoftSsoEnabled ? color.quinaryLight : color.greyLight
                    }
                    textColor={microsoftSsoEnabled ? color.quinary : color.grey}
                  >
                    {microsoftSsoEnabled
                      ? t(`general.enabled`)
                      : t(`general.disabled`)}
                  </Pill>
                </View>
              }
              disableHorizontalDivider
            >
              <ExtraSmallTextGood italic style={{ marginTop: spacing.medium }}>
                {microsoftSsoEnabled ? (
                  t(`admin.company.form.microsoftSSOEnabled`)
                ) : (
                  <>
                    {t(`admin.company.form.microsoftSSODisabledPreLink`)}
                    <TouchableOpacity
                      onPress={() => {
                        Linking.openURL("mailto:sales@bookmydesk.com");
                      }}
                    >
                      <ExtraSmallText
                        italic
                        noMargin
                        style={{ color: color.primary }}
                      >
                        sales@bookmydesk.com
                      </ExtraSmallText>
                    </TouchableOpacity>
                    {t(`admin.company.form.microsoftSSODisabledSufLink`)}
                  </>
                )}
              </ExtraSmallTextGood>
            </HeaderCard>
            <HeaderCard
              icon={azureActiveDirectoryIcon}
              iconAsBadge={true}
              badgeColor="#F7F9FD"
              iconWidth={13.5}
              iconHeight={13.5}
              title={t(`admin.company.form.azureActiveDirectory`)}
              titleSuffix={
                <View style={{ marginLeft: "auto" }}>
                  <Pill
                    backgroundColor={
                      microsoftSyncEnabled
                        ? color.quinaryLight
                        : color.greyLight
                    }
                    textColor={
                      microsoftSyncEnabled ? color.quinary : color.grey
                    }
                  >
                    {microsoftSyncEnabled
                      ? t(`general.enabled`)
                      : t(`general.disabled`)}
                  </Pill>
                </View>
              }
              disableHorizontalDivider
            >
              <ExtraSmallTextGood
                italic
                style={{
                  marginTop: spacing.medium,
                  marginBottom: spacing.medium,
                }}
              >
                {microsoftSyncEnabled ? (
                  t(`admin.company.form.azureActiveDirectoryEnabled`)
                ) : (
                  <>
                    {t(
                      `admin.company.form.azureActiveDirectoryDisabledPreLink`
                    )}
                    <TouchableOpacity
                      onPress={() => {
                        Linking.openURL("mailto:sales@bookmydesk.com");
                      }}
                    >
                      <ExtraSmallTextGood
                        italic
                        noMargin
                        style={{ color: color.primary }}
                      >
                        sales@bookmydesk.com
                      </ExtraSmallTextGood>
                    </TouchableOpacity>
                    {t(
                      `admin.company.form.azureActiveDirectoryDisabledSufLink`
                    )}
                  </>
                )}
              </ExtraSmallTextGood>
              {microsoftSyncEnabled && (
                <View style={{ flexDirection: "row" }}>
                  {!azureActiveDirectoryActive && (
                    <Button onPress={provideAzureActiveDirectoryAccess}>
                      {t("admin.company.form.provideAccess")}
                    </Button>
                  )}
                  {azureActiveDirectoryActive && (
                    <>
                    <Pill
                      backgroundColor={color.quinaryLight}
                      textColor={color.quinary}
                    >
                      {t(`admin.company.form.providedAccess`)}
                    </Pill>
                    <Pill
                      backgroundColor="transparent"
                      textColor={color.bmdRed}
                    >
                      <TouchableOpacity
                        onPress={openDeleteModal(userCompany,`microsoft_graph_admin`)}
                      >
                        <div>{t(`admin.company.form.removeAccess`)}</div>
                      </TouchableOpacity>
                    </Pill>
                    </>
                  )}
                </View>
              )}
            </HeaderCard>
            <HeaderCard
              icon={microsoftOutlookIcon}
              iconAsBadge={true}
              badgeColor="#F7F9FD"
              iconWidth={13.5}
              iconHeight={13.5}
              title={t(`admin.company.form.azurePlaces`)}
              titleSuffix={
                <View style={{ marginLeft: "auto" }}>
                  <Pill
                    backgroundColor={
                      microsoftSyncEnabled
                        ? color.quinaryLight
                        : color.greyLight
                    }
                    textColor={
                      microsoftSyncEnabled ? color.quinary : color.grey
                    }
                  >
                    {microsoftSyncEnabled
                      ? t(`general.enabled`)
                      : t(`general.disabled`)}
                  </Pill>
                </View>
              }
              disableHorizontalDivider
            >
              <ExtraSmallTextGood
                italic
                style={{
                  marginTop: spacing.medium,
                  marginBottom: spacing.medium,
                }}
              >
                {microsoftSyncEnabled ? (
                  t(`admin.company.form.azurePlacesEnabled`)
                ) : (
                  <>
                    {t(
                      `admin.company.form.azurePlacesDisabledPreLink`
                    )}
                    <TouchableOpacity
                      onPress={() => {
                        Linking.openURL("mailto:sales@bookmydesk.com");
                      }}
                    >
                      <ExtraSmallTextGood
                        italic
                        noMargin
                        style={{ color: color.primary }}
                      >
                        sales@bookmydesk.com
                      </ExtraSmallTextGood>
                    </TouchableOpacity>
                    {t(
                      `admin.company.form.azurePlacesDisabledSufLink`
                    )}
                  </>
                )}
              </ExtraSmallTextGood>
              {microsoftSyncEnabled && (
                <View style={{ flexDirection: "row" }}>
                  {!azurePlacesActive && (
                    <Button onPress={provideAzurePlacesAccess}>
                      {t("admin.company.form.provideAccess")}
                    </Button>
                  )}
                  {azurePlacesActive && (
                    <>
                    <Pill
                      backgroundColor={color.quinaryLight}
                      textColor={color.quinary}
                    >
                      {t(`admin.company.form.providedAccess`)}
                    </Pill>
                    <Pill
                      backgroundColor="transparent"
                      textColor={color.bmdRed}
                    >
                      <TouchableOpacity
                        onPress={openDeleteModal(userCompany,`microsoft_graph_rooms`)}
                      >
                        <div>{t(`admin.company.form.removeAccess`)}</div>
                      </TouchableOpacity>
                    </Pill>
                    </>
                  )}
                </View>
              )}
            </HeaderCard>
            <HeaderCard
              icon={surfconextIcon}
              iconAsBadge={true}
              badgeColor="#F7F9FD"
              iconWidth={13.5}
              iconHeight={13.5}
              title={t(`admin.company.form.surfconextSSO`)}
              titleSuffix={
                <View style={{ marginLeft: "auto" }}>
                  <Pill
                    backgroundColor={
                      surfconextSsoEnabled ? color.quinaryLight : color.greyLight
                    }
                    textColor={surfconextSsoEnabled ? color.quinary : color.grey}
                  >
                    {surfconextSsoEnabled
                      ? t(`general.enabled`)
                      : t(`general.disabled`)}
                  </Pill>
                </View>
              }
              disableHorizontalDivider
            />
            <HeaderCard
              icon={saltoIcon}
              iconAsBadge={true}
              badgeColor="#F7F9FD"
              iconWidth={13.5}
              iconHeight={13.5}
              title={t(`admin.company.form.saltoHeader`)}
              titleSuffix={
                <View style={{ marginLeft: "auto" }}>
                  <Pill
                    backgroundColor={
                      (saltoUsername && saltoPassword) ? color.quinaryLight : color.greyLight
                    }
                    textColor={( saltoUsername && saltoPassword) ? color.quinary : color.grey}
                  >
                    {( saltoUsername && saltoPassword)
                      ? t(`general.enabled`)
                      : t(`general.disabled`)}
                  </Pill>
                </View>
              }
              disableHorizontalDivider
            />

          </Column>
          <Column columns={3}>
            <HeaderCard
              icon={reservationsIcon}
              title={t("admin.company.form.reservationSettings")}
            >
              <InnerCard>
                <SeatSettingRow>
                  <Toggle
                    label={t("admin.company.form.reservationForUser")}
                    onChange={setReservationForUserEnabled}
                    toggleValue={reservationForUserEnabled}
                  />
                  </SeatSettingRow>
                <SeatSettingRow>
                  <Toggle
                    label={t("admin.company.form.recurring")}
                    onChange={setRecurringReservationFeature}
                    toggleValue={recurringReservationFeature}
                  />
                </SeatSettingRow>

                <ExtraSmallTextGood italic>
                  {t("admin.company.form.helper.recurring")}
                </ExtraSmallTextGood>
                <SeatSettingRow>
                  <Toggle
                    label={t("admin.company.form.forceTimeslots")}
                    onChange={setForceTimeSlotsFeature}
                    toggleValue={forceTimeSlotsFeature}
                  />
                </SeatSettingRow>
                <SeatSettingRow>
                  <Toggle
                    label={t("admin.company.form.parkingSpotsAsResources")}
                    onChange={setParkingSpotsAsResourceEnabled}
                    toggleValue={parkingSpotsAsResourceEnabled}
                  />
                </SeatSettingRow>
                
                {parkingSpotsAsResourceEnabled && !resources.find((resource) => resource.resourceType === ResourcePropertiesResourceTypeEnum.Parkingspot) && (
                  <ExtraSmallTextGood italic>
                    {t("admin.company.form.helper.noParkingResource")}
                  </ExtraSmallTextGood>
                )}
               <SeatSettingRow>
                  <Toggle
                    label={t("admin.company.form.recurringResourceReservationParking")}
                    onChange={setRecurringResourceReservationFeature}
                    toggleValue={recurringResourceReservationFeature}
                  />
                </SeatSettingRow>
              </InnerCard>
              <Spacer hasMarginTop />
              <SmallTitle hasMarginBottom hasMarginTop>

                {t("admin.company.form.reservationWindowHeader")}
              </SmallTitle>
              <CardTitle>{t("admin.company.form.reservationSettingsTitle")}</CardTitle>
              <LabeledInput label={t("admin.company.form.desks")} required>
                <Input
                  maxLength={3}
                  onChangeText={updateDeskLength}
                  value={deskLength}
                  placeholder={t("admin.company.form.desksLength")}
                />
              </LabeledInput>
              {meetingFeature && (
                <LabeledInput label={t("admin.company.form.meetingRooms")} required>
                  <Input
                    maxLength={3}
                    onChangeText={updateMeetingLength}
                    value={meetingLength}
                    placeholder={t("admin.company.form.roomsLength")}
                  />
                </LabeledInput>
              )}
              {lunchFeature && (
                <LabeledInput label={t("admin.company.form.lunches")} required>
                  <Input
                    maxLength={3}
                    onChangeText={updateLunchLength}
                    value={lunchLength}
                    placeholder={t("admin.company.form.lunchesLength")}
                  />
                </LabeledInput>
              )}
              {visitorFeature && (
                <LabeledInput label={t("admin.company.form.forVisitors")} required>
                  <Input
                    maxLength={3}
                    onChangeText={updateVisitorLength}
                    value={visitorLength}
                    placeholder={t("admin.company.form.visitorsLength")}
                  />
                </LabeledInput>
              )}
              <LabeledInput label={t("admin.company.form.forResourcesParking")} required>
                <Input
                  maxLength={3}
                  onChangeText={updateResourceLength}
                  value={resourceLength}
                  // placeholder={t("admin.company.form.resourcesLength")}
                  placeholder={t("admin.company.form.resourcesLengthParking")}
                />
              </LabeledInput>
            </HeaderCard>

              <HeaderCard
                icon={allowanceIcon}
                title={t("admin.company.form.compensationModuleHeader")}
                disableHorizontalDivider={!compensationEnabled}
                titleSuffix={
                  <HeaderCardTitleSuffixWrapper marginTop="10">
                    <Toggle
                      onChange={setCompensationEnabled}
                      toggleValue={compensationEnabled}
                      toggleDisabled={true}
                    />
                  </HeaderCardTitleSuffixWrapper>
                }
              >
              {compensationEnabled && (
                  <>
                    <LabeledInput
                      label={t("admin.company.form.compensationExportEmail")}
                      helperText={t("admin.company.form.helper.compensationExportEmail")}
                    >
                      <Input
                        onChangeText={setCompensationExportEmail}
                        value={compensationExportEmail}
                        placeholder={t("admin.company.form.compensationExportEmail")}
                      />
                    </LabeledInput>
                    <InnerCard
                      hasMarginTop
                    >
                      <LabeledInput
                        label={t("admin.company.form.compensationCutOffDay")}
                        helperText={t("admin.company.form.helper.compensationCutOffDay")}
                      >
                        <Input
                          maxLength={2}
                          onChangeText={updateCompensationCutOffDay}
                          value={compensationCutOffDay}
                          placeholder={t("admin.company.form.compensationCutOffDay")}
                        />
                      </LabeledInput>
                    </InnerCard>
                    <Spacer/>
                  <LabeledInput
                    label={t("admin.company.form.compensationDistanceUnitCompensation")}
                  >
                    <Input
                      onChangeText={setCompensationDistanceUnitCompensation}
                      value={compensationDistanceUnitCompensation}
                      placeholder={t("admin.company.form.compensationDistanceUnitCompensation")}
                    />
                  </LabeledInput>
                  <LabeledInput
                    label={t("admin.company.form.compensationMaxDailyDistance")}
                  >
                    <Input
                      onChangeText={setCompensationMaxDailyDistance}
                      value={compensationMaxDailyDistance}
                      placeholder={t("admin.company.form.compensationMaxDailyDistance")}
                    />
                  </LabeledInput>
                  <LabeledInput
                    label={t("admin.company.form.compensationDailyHomeCompensation")}
                  >
                    <Input
                      onChangeText={updateCompensationDailyHomeCompensation}
                      value={compensationDailyHomeCompensation}
                      placeholder={t("admin.company.form.compensationDailyHomeCompensation")}
                    />
                  </LabeledInput>
                  </>
                )}
              </HeaderCard>

            <HeaderCard
              icon={buildingIcon}
              title={t("admin.company.form.companySettings")}
            >
              <LabeledInput label={t("admin.company.form.name")} required>
                <Input
                  onChangeText={setName}
                  value={name}
                  placeholder={t("admin.company.form.name") + " *"}
                />
              </LabeledInput>
              <LabeledInput>
                <SmallTitle hasMarginBottom>
                  {t("admin.company.form.contactHeader")}
                </SmallTitle>
              </LabeledInput>
              <LabeledInput label={t("admin.company.form.personName")} required>
                <Input
                  onChangeText={setFirstName}
                  value={firstName}
                  placeholder={t("admin.company.form.personName") + " *"}
                />
              </LabeledInput>
              <LabeledInput
                label={t("admin.company.form.personLastname")}
                required
              >
                <Input
                  onChangeText={setLastName}
                  value={lastName}
                  placeholder={t("admin.company.form.personLastname") + " *"}
                />
              </LabeledInput>
              <LabeledInput label={t("admin.company.form.email")} required>
                <Input
                  onChangeText={setEmail}
                  value={email}
                  placeholder={t("admin.company.form.email") + " *"}
                />
              </LabeledInput>
              <LabeledInput label={t("admin.company.form.phone")} required>
                <Input
                  onChangeText={setPhoneNumber}
                  value={phoneNumber}
                  placeholder={t("admin.company.form.phone") + " *"}
                />
              </LabeledInput>
            </HeaderCard>
          </Column>
        </Grid>
      </Container>
    </>
  );
};

export default CompanySettings;
