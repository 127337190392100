module["exports"] = [
  "AB",
  "AR",
  "AG",
  "BC",
  "BH",
  "BN",
  "BT",
  "BR",
  "BV",
  "B",
  "BZ",
  "CL",
  "CS",
  "CJ",
  "CT",
  "CV",
  "DB",
  "DJ",
  "GL",
  "GR",
  "GJ",
  "HR",
  "HD",
  "IL",
  "IS",
  "IF",
  "MM",
  "MH",
  "MS",
  "NT",
  "OT",
  "PH",
  "SJ",
  "SM",
  "SB",
  "SV",
  "TR",
  "TM",
  "TL",
  "VL",
  "VS",
  "VN"
];