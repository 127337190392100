module["exports"] = [
  "Abacalero",
  "Abacería",
  "Abacero",
  "Abacial",
  "Abaco",
  "Abacora",
  "Abacorar",
  "Abad",
  "Abada",
  "Abadejo",
  "Abadengo",
  "Abadernar",
  "Abadesa",
  "Abadí",
  "Abadía",
  "Abadiado",
  "Abadiato",
  "Abajadero",
  "Abajamiento",
  "Abajar",
  "Abajeño",
  "Abajera",
  "Abajo",
  "Abalada",
  "Abalanzar",
  "Abalar",
  "Abalaustrado",
  "Abaldonadamente",
  "Abaldonamiento",
  "Bastonada",
  "Bastonazo",
  "Bastoncillo",
  "Bastonear",
  "Bastonero",
  "Bástulo",
  "Basura",
  "Basural",
  "Basurear",
  "Basurero",
  "Bata",
  "Batacazo",
  "Batahola",
  "Batalán",
  "Batalla",
  "Batallador",
  "Batallar",
  "Batallaroso",
  "Batallola",
  "Batallón",
  "Batallona",
  "Batalloso",
  "Batán",
  "Batanar",
  "Batanear",
  "Batanero",
  "Batanga",
  "Bataola",
  "Batata",
  "Batatazo",
  "Batato",
  "Batavia",
  "Bátavo",
  "Batayola",
  "Batazo",
  "Bate",
  "Batea",
  "Bateador",
  "Bateaguas",
  "Cenagar",
  "Cenagoso",
  "Cenal",
  "Cenaoscuras",
  "Ceñar",
  "Cenata",
  "Cenca",
  "Cencapa",
  "Cencellada",
  "Cenceñada",
  "Cenceño",
  "Cencero",
  "Cencerra",
  "Cencerrada",
  "Cencerrado",
  "Cencerrear",
  "Cencerreo",
  "Cencerril",
  "Cencerrillas",
  "Cencerro",
  "Cencerrón",
  "Cencha",
  "Cencido",
  "Cencío",
  "Cencivera",
  "Cenco",
  "Cencuate",
  "Cendal",
  "Cendalí",
  "Céndea",
  "Cendolilla",
  "Cendra",
  "Cendrada",
  "Cendradilla",
  "Cendrado",
  "Cendrar",
  "Cendrazo",
  "Cenefa",
  "Cenegar",
  "Ceneque",
  "Cenero",
  "Cenestesia",
  "Desceñir",
  "Descensión",
  "Descenso",
  "Descentrado",
  "Descentralización",
  "Descentralizador",
  "Descentralizar",
  "Descentrar",
  "Descepar",
  "Descerar",
  "Descercado",
  "Descercador",
  "Descercar",
  "Descerco",
  "Descerebración",
  "Descerebrado",
  "Descerebrar",
  "Descerezar",
  "Descerrajado",
  "Descerrajadura",
  "Descerrajar",
  "Descerrar",
  "Descerrumarse",
  "Descervigamiento",
  "Descervigar",
  "Deschapar",
  "Descharchar",
  "Deschavetado",
  "Deschavetarse",
  "Deschuponar",
  "Descifrable",
  "Descifrador",
  "Desciframiento",
  "Descifrar",
  "Descifre",
  "Descimbramiento",
  "Descimbrar",
  "Engarbarse",
  "Engarberar",
  "Engarbullar",
  "Engarce",
  "Engarfiar",
  "Engargantadura",
  "Engargantar",
  "Engargante",
  "Engargolado",
  "Engargolar",
  "Engaritar",
  "Engarmarse",
  "Engarnio",
  "Engarrafador",
  "Engarrafar",
  "Engarrar",
  "Engarro",
  "Engarronar",
  "Engarrotar",
  "Engarzador",
  "Engarzadura",
  "Engarzar",
  "Engasgarse",
  "Engastador",
  "Engastadura",
  "Engastar",
  "Engaste",
  "Ficción",
  "Fice",
  "Ficha",
  "Fichaje",
  "Fichar",
  "Fichero",
  "Ficoideo",
  "Ficticio",
  "Fidalgo",
  "Fidecomiso",
  "Fidedigno",
  "Fideero",
  "Fideicomisario",
  "Fideicomiso",
  "Fideicomitente",
  "Fideísmo",
  "Fidelidad",
  "Fidelísimo",
  "Fideo",
  "Fido",
  "Fiducia",
  "Geminación",
  "Geminado",
  "Geminar",
  "Géminis",
  "Gémino",
  "Gemíparo",
  "Gemiquear",
  "Gemiqueo",
  "Gemir",
  "Gemología",
  "Gemológico",
  "Gemólogo",
  "Gemonias",
  "Gemoso",
  "Gemoterapia",
  "Gen",
  "Genciana",
  "Gencianáceo",
  "Gencianeo",
  "Gendarme",
  "Gendarmería",
  "Genealogía",
  "Genealógico",
  "Genealogista",
  "Genearca",
  "Geneático",
  "Generable",
  "Generación",
  "Generacional",
  "Generador",
  "General",
  "Generala",
  "Generalato",
  "Generalidad",
  "Generalísimo",
  "Incordio",
  "Incorporación",
  "Incorporal",
  "Incorporalmente",
  "Incorporar",
  "Incorporeidad",
  "Incorpóreo",
  "Incorporo",
  "Incorrección",
  "Incorrectamente",
  "Incorrecto",
  "Incorregibilidad",
  "Incorregible",
  "Incorregiblemente",
  "Incorrupción",
  "Incorruptamente",
  "Incorruptibilidad",
  "Incorruptible",
  "Incorrupto",
  "Incrasar",
  "Increado",
  "Incredibilidad",
  "Incrédulamente",
  "Incredulidad",
  "Incrédulo",
  "Increíble",
  "Increíblemente",
  "Incrementar",
  "Incremento",
  "Increpación",
  "Increpador",
  "Increpar",
  "Incriminación",
  "Incriminar",
  "Incristalizable",
  "Incruentamente",
  "Incruento",
  "Incrustación"
];
