import Expo2DContext from "expo-2d-context";
import { Asset } from "expo-asset";
import { DocumentResult } from "expo-document-picker";
import { ExpoWebGLRenderingContext, GLView } from "expo-gl";
import React, {
  FC,
  forwardRef,
  RefObject,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  GestureResponderEvent,
  Image as ImageComponent,
  NativeTouchEvent,
  Platform,
  TouchableOpacityProps,
  useWindowDimensions,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components/native";
import webStyled from "styled-components";
import {
  MapObject,
  Seat,
  SeatTag,
  SeatType,
  SeatWithRelations,
  UserGroup,
} from "../../../bookmydesk-api-sdk-typescript-axios";
import Notification from "../Notification";
import { Cords, MapDataInterface, ZoneInterface } from "../../../fake-data/mapData";
import { useApi } from "../../hooks/useApi";
import { useClient } from "../../hooks/useClient";
import { t } from "../../i18n";
import Text, { ErrorText, SmallText } from "../../styles/Text";
import {
  borderRadius,
  borderWidth,
  color,
  flex,
  font,
  iconSize,
  Overflow,
  Position,
  spacing,
  text,
  TextAlign,
} from "../../styles/theme";
import Title from "../../styles/Title";
import Button from "../Button";
import FileUpload from "../FileUpload";
import { SelectItemInterface } from "../Select";
import { AvailabilityInterface } from "./AvailabilityInput";
import { CleaningMapFunctionality } from "./CleaningMapFunctionality";
import CleaningSeatCard from "./CleaningSeatCard";
import ControlBar from "./ControlBar";
import CreateZoneForm from "./CreateZoneForm";
// import floorPlan from "../../../fake-data/Floor-map-of-Group-Home-Tomarigi.png";
// import floorPlan from "../../../fake-data/office_plan_big.jpg";
import DismissAbleOverlay from "./DismissAbleOverlay";
import EditZoneCard from "./EditZoneCard";
import SeatForm from "./SeatForm";
import LoadOverlay from "../LoadOverlay";
import OverlayRightWrapper from "../OverlayRightWrapper";
import Portal from "../Portal";
import addSpotIcon from "./mapIcons/icon_add_spot.svg";

const cropPlusExport = (
  draw: (context: CanvasRenderingContext2D) => void,
  cropWidth: number,
  cropHeight: number
) => {
  // create a temporary canvas sized to the cropped size
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Failed to get context");
  }
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  draw(context);
  return canvas.toDataURL();
};
const urlToFile = (url: string, filename: string, mimeType: string) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

const canPrint = Platform.OS === "web";

const ZoomButtonWrapper = styled.View`
  position: absolute;
  right: ${spacing.large};
  top: 320px;
`;

const zoomButtonSize = "40px";

/** @var pixelDensity
 *  Some browsers are scaled up. This means that a pixel on screen is not equal to a pixel on the canvas. In order to
 *  counter this, we have this variable which tells us the scale of an pixel on screen.
 **/
const pixelDensity = window.devicePixelRatio;

const mapIconSize = 20 * pixelDensity;
const mapIconOffset = mapIconSize / 2;
const arrowIconSize = 40 * pixelDensity;
const arrowIconOffset = arrowIconSize / 2;
const webGlImageLoadErrorCode = 1281;
const webGlImageUndefinedErrorCode = 1282;

interface ZoomButtonProps extends TouchableOpacityProps {
  last?: boolean;
}

const Wrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const ZoomButton = styled.TouchableOpacity<ZoomButtonProps>`
  width: ${zoomButtonSize};
  max-width: ${zoomButtonSize};
  height: ${zoomButtonSize};
  max-height: ${zoomButtonSize};
  display: flex;
  justify-content: center;
  align-content: center;
  border: ${borderWidth.small} solid ${color.greyLightest};
  background-color: ${color.white};
`;

const CleaningCardWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const ButtonText = styled(Text)`
  text-align: center;
  font-size: ${text.large.size};
  font-family: ${font.defaultSemiBold};
`;

const WrapperRight = styled.View`
  width: 100%;
  flex-direction: column;
`;

const StyledTitle = styled(Title)`
  margin: ${spacing.large} 0;
`;

const StyledTitleTextCenter = styled(Title)`
  text-align: ${TextAlign.center};
  margin: ${spacing.large} 0;
`;

const DeleteButtonWrapper = styled.View`
  flex-grow: 0;
  flex-direction: row;
  margin-top: ${spacing.medium};
  justify-content: space-between;
`;

const OverlayWrapper = styled.View`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.transparentBackground};
`;

const MapDetailWrapper = styled.View`
  position: ${Position.relative};
  margin-bottom: ${spacing.medium};
  margin-top: 65px;
`;

const MapWrapper = webStyled.div<ViewProps & ViewStyle & { isMoving: boolean; isHovering: boolean; isAdding: boolean; }>`
  position: ${Position.relative};
  z-index: ${(props) => props.zIndex || 0};
  ${(props) => props.overflow ? `overflow: ${props.overflow};` : ""}
  ${({ isAdding, isMoving, isHovering }) =>
  isMoving
    ? "cursor: move;"
    : (isAdding
      ? `cursor: url(${addSpotIcon}) 22.5 22.5, auto;`
      : (isHovering ? "cursor: pointer;" : ""))}
`;

const selectColor = "rgba(23, 11, 49, .2)";

// Currently these interfaces only have the selectItemInterface. Will be expanded when the API is present.
export type TagInterface = SelectItemInterface;
export type UserGroupMapInterface = SelectItemInterface;
export type DayInterface = SelectItemInterface;

export interface SeatOnMap extends SeatWithRelations {
  selected?: boolean;
  dirty?: boolean;
  hasReservations?: boolean;
  availability?: AvailabilityInterface[]; // TODO
  morningBlocked: SelectItemInterface[]; // TODO
  afternoonBlocked: SelectItemInterface[]; // TODO
}

export interface ArrowOnMap extends MapObject {
  selected?: boolean;
}

interface MapProps {
  asset: Asset[];
  mapData: MapDataInterface;
  isEditable?: boolean;
  isCleaningMap?: boolean;
  seatTags: SeatTag[];
  userGroups: UserGroup[];
  locationName?: string;
  toggleSeatForm?: (value: boolean) => void;
  getMSRooms?: () => Promise<any>;
  getSeatInfo?: (data: Seat) => void;
  afterSeatFormSubmit?: () => void;
  triggerOnSaveAndClose?: boolean | null;
  triggerOnSave?: boolean | null;
  triggerUpload?: boolean | null;
  currentImage?: DocumentResult;
  triggerActionFromParent: boolean;
  addTag?: () => void;
}

interface OnSaveErrors {
  hasErrors: boolean;
  updatedMapFile: null | File;
  createdSeats: SeatOnMap[];
  updatedSeats: SeatOnMap[];
  deletedSeats: string[];
  createdMapObjects: ArrowOnMap[];
  updatedMapObjects: ArrowOnMap[];
  deletedMapObjects: string[];
}

const Map: FC<MapProps> = forwardRef(({
  asset,
  mapData,
  isEditable,
  isCleaningMap,
  seatTags,
  userGroups,
  toggleSeatForm,
  getMSRooms,
  getSeatInfo,
  afterSeatFormSubmit,
  triggerOnSaveAndClose,
  triggerUpload,
  triggerOnSave,
  currentImage,
  triggerActionFromParent,
  addTag,
}, parentRef) => {
  const ref = useRef(parentRef);
  const mapId = mapData.id;
  const [saving, setSaving] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [isAddingSeat, setIsAddingSeat] = useState<boolean>(false);
  const [isEditSeatFormOpened, setIsEditSeatFormOpened] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [isAddingArrow, setIsAddingArrow] = useState<boolean>(false);
  const [isScreenMoving, setIsScreenMoving] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isCreatingZone, setIsCreatingZone] = useState<boolean>(false);
  const [hasSelectedItems, setHasSelectedItems] = useState<boolean>(false);
  const [hasRotateAbleItems, setHasRotateAbleItems] = useState<boolean>(false);
  const [selectedSeats, setSelectedSeats] = useState<SeatOnMap[]>([]);
  const [selectedArrows, setSelectedArrows] = useState<ArrowOnMap[]>([]);
  const [deletedSeatIds, setDeletedSeatIds] = useState<string[]>([]);
  const [deletedMapObjectIds, setDeletedMapObjectIds] = useState<string[]>([]);
  const [zoneError, setZoneError] = useState<string>("");
  const [isResettable, setIsResettable] = useState<boolean>(false);
  // eslint-disable-next-line
  const ctx = useRef<any>(null);
  const webGl = useRef<ExpoWebGLRenderingContext | null>(null);
  const map = useRef<Asset | null>(null);
  const [updateMapFile, setUpdateMapFile] = useState<File | null>(null);
  const workspaceIllustration = useRef<Asset | null>(null);
  const workspaceSelectedIllustration = useRef<Asset | null>(null);
  const workspaceDangerIllustration = useRef<Asset | null>(null);
  const workspaceInactiveIllustration = useRef<Asset | null>(null);
  const lunchIllustration = useRef<Asset | null>(null);
  const lunchSelectedIllustration = useRef<Asset | null>(null);
  const lunchDangerIllustration = useRef<Asset | null>(null);
  const lunchInactiveIllustration = useRef<Asset | null>(null);
  const meetingRoomIllustration = useRef<Asset | null>(null);
  const meetingRoomSelectedIllustration = useRef<Asset | null>(null);
  const meetingRoomDangerIllustration = useRef<Asset | null>(null);
  const meetingRoomInactiveIllustration = useRef<Asset | null>(null);
  const arrowIllustration = useRef<Asset | null>(null);
  const zoom = useRef<number>(1);
  const initializedX = useRef<number | null>(null);
  const x = useRef<number | null>(null);
  const y = useRef<number | null>(null);
  const dragPosition = useRef<Cords>({ x: 0, y: 0 });
  const isDraggingItems = useRef<boolean>(false);
  const seats = useRef<SeatOnMap[]>(
    (mapData?.seats as SeatOnMap[])?.map((seat: SeatOnMap): SeatOnMap => ({
      ...seat,
      coordX: seat.isZone ? null : (seat.coordX ?? 0) - mapIconOffset,
      coordY: seat.isZone ? null : (seat.coordY ?? 0) - mapIconOffset,
      zoneCoordinates: seat.isZone ? `${Number(seat.zoneCoordinates?.split(',')[0]) - mapIconOffset},${Number(seat.zoneCoordinates?.split(',')[1])  - mapIconOffset}` : undefined
    })) || []
  );
  const arrows = useRef<ArrowOnMap[]>(
    (mapData?.arrows as ArrowOnMap[])?.map((seat: ArrowOnMap): ArrowOnMap => ({
      ...seat,
      x: seat.x - arrowIconOffset,
      y: seat.y - arrowIconOffset,
    })) || []
  );
  const zonePoints = useRef<Cords[]>([]);
  const canvasDrawZones = useRef<ZoneInterface[]>(mapData?.zones || []);
  const [htmlZones, setHtmlZoneState] = useState<ZoneInterface[]>(
    canvasDrawZones.current
  );
  const createCallback = useRef<(nextItem: number) => void>((): void => undefined);
  const [isShowingCantAddSeat, setIsShowingCantAddSeat] =
    useState<boolean>(false);
  const [isShowingDeleteWarning, setIsShowingDeleteWarning] =
    useState<boolean>(false);
  const [isShowingCantDelete, setIsShowingCantDelete] =
    useState<boolean>(false);
  const [selectingSeat, setSelectingSeat] = useState<SeatOnMap>();
  const [selectingArrow, setSelectingArrow] = useState<ArrowOnMap>();
  const [onSaveErrors, setOnSaveErrors] = useState<OnSaveErrors | undefined>(
    undefined
  );
  const client = useClient();
  const { handleRequest } = useApi();
  const history = useHistory();

  const [pdfIconSize, setPdfIconSize] = useState<string>(iconSize.large);
  const [isPrintMode, setIsPrintMode] = useState<boolean>(false);

  const printComponentRef = useRef<View>();
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current ?? null,
    onBeforeGetContent: () => {
      setIsPrintMode(true);
      setPdfIconSize(iconSize.extraLarge);
    },
    onAfterPrint: () => {
      setIsPrintMode(false);
      setPdfIconSize(iconSize.large);
    },
  });
  const [cleaningMapImage, setCleaningMapImage] = useState<null | string>(null);

  const loadImages = async () => {
    const loadImage = async (uri: string) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = uri;
        image.onload = (event) => {
          if (!event) {
            return reject(new Error("No onload event"));
          }
          // @ts-ignore
          if (!event.target || !event.target.src) {
            return reject(new Error("No target event"));
          }
          // @ts-ignore
          URL.revokeObjectURL(event.target.src);
          resolve(event.target);
        };
      });
    };
    return {
      mapImage: await loadImage(map.current?.localUri || ""),
      arrowImage: await loadImage(arrowIllustration.current?.localUri || ""),
      lunchIllustration: await loadImage(
        lunchIllustration.current?.localUri || ""
      ),
      lunchSelectedIllustration: await loadImage(
        lunchSelectedIllustration.current?.localUri || ""
      ),
      lunchInactiveIllustration: await loadImage(
        lunchInactiveIllustration.current?.localUri || ""
      ),
      lunchDangerIllustration: await loadImage(
        lunchDangerIllustration.current?.localUri || ""
      ),
      meetingRoomIllustration: await loadImage(
        meetingRoomIllustration.current?.localUri || ""
      ),
      meetingRoomSelectedIllustration: await loadImage(
        meetingRoomSelectedIllustration.current?.localUri || ""
      ),
      meetingRoomInactiveIllustration: await loadImage(
        meetingRoomInactiveIllustration.current?.localUri || ""
      ),
      meetingRoomDangerIllustration: await loadImage(
        meetingRoomDangerIllustration.current?.localUri || ""
      ),
      workspaceIllustration: await loadImage(
        workspaceIllustration.current?.localUri || ""
      ),
      workspaceSelectedIllustration: await loadImage(
        workspaceSelectedIllustration.current?.localUri || ""
      ),
      workspaceInactiveIllustration: await loadImage(
        workspaceInactiveIllustration.current?.localUri || ""
      ),
      workspaceDangerIllustration: await loadImage(
        workspaceDangerIllustration.current?.localUri || ""
      ),
    };
  };

  const onSave = useCallback(async () => {
    setSaving(true);
    // eslint-disable-next-line
    const promises: Promise<any>[] = [];
    const errors: OnSaveErrors = {
      hasErrors: false,
      updatedMapFile: null,
      createdSeats: [],
      updatedSeats: [],
      deletedSeats: [],
      createdMapObjects: [],
      updatedMapObjects: [],
      deletedMapObjects: [],
    };

    const images = await loadImages() as { [key: string]: HTMLImageElement; };
    const croppedImage = cropPlusExport(
      (context: CanvasRenderingContext2D) => {
        draw(false, context, 0, 0, 1, images);
      },
      map.current?.width || 0,
      map.current?.height || 0
    );
    // Cropped image
    // const croppedImage = cropPlusExport(
    //   draw,
    //   x.current,
    //   y.current,
    //   map.current.width,
    //   map.current.height
    // );
    const croppedImageFile = await urlToFile(
      croppedImage,
      "image.png",
      "image/png"
    );
    promises.push(
      handleRequest(
        client.addMapFile(mapId, croppedImageFile).catch((error) => {
          errors.hasErrors = true;
          console.error(error);
        })
      )
    );
    if (updateMapFile) {
      promises.push(
        handleRequest(
          client.addOriginalMapFile(mapId, updateMapFile).catch((error) => {
            errors.hasErrors = true;
            errors.updatedMapFile = updateMapFile;
            console.error(error);
          })
        )
      );
    }

    promises.push(
      handleRequest(
        client.updateMap(mapId, {
          width: map.current?.width || 0,
          height: map.current?.height || 0,
        })
      )
    );
    seats.current.forEach((seat) => {
      const seatData = {
        ...seat,
        coordX: seat.isZone ? null : Math.floor((seat.coordX ?? 0) + mapIconOffset),
        coordY:seat.isZone ? null : Math.floor((seat.coordY ?? 0) + mapIconOffset),
        zoneCoordinates: seat.isZone ? `${Math.floor((Number(seat.zoneCoordinates?.split?.(',')[0]) ?? 0) + mapIconOffset)},${Math.floor((Number(seat.zoneCoordinates?.split?.(',')[1]) ?? 0) + mapIconOffset)}` : null,
      };
      if (seat.id.indexOf("new") === 0) {
        promises.push(
          handleRequest(client.addSeat(seatData))
            .then((response) => {
              if (!response || !response.data.result.seat) {
                throw new Error("Error saving new seat");
              }
              seat.id = response.data.result.seat.id;
            })
            .catch((error) => {
              errors.hasErrors = true;
              errors.createdSeats.push(seat);
              console.error(error);
            })
        );
      } else {
        promises.push(
          handleRequest(
            client.updateSeat(seat.id, seatData).catch((error) => {
              errors.hasErrors = true;
              errors.updatedSeats.push(seat);
              console.error(error);
            })
          )
        );
      }
    });
    deletedSeatIds
      .filter((deletedSeatId) => deletedSeatId.indexOf("new") !== 0)
      .forEach((deletedSeatId) => {
        promises.push(
          handleRequest(
            client.deleteSeat(deletedSeatId).catch((error) => {
              errors.hasErrors = true;
              errors.deletedSeats.push(deletedSeatId);
              console.error(error);
            })
          )
        );
      });

    arrows.current.forEach((arrow) => {
      const arrowData = {
        ...arrow,
        x: Math.floor(arrow.x + arrowIconOffset),
        y: Math.floor(arrow.y + arrowIconOffset),
      };
      if (arrow.id.indexOf("new") === 0) {
        promises.push(
          handleRequest(client.addMapObject(arrowData))
            .then((response) => {
              if (!response || !response.data.result.mapObject) {
                throw new Error("Error saving new arrow");
              }
              arrow.id = response.data.result.mapObject.id;
            })
            .catch((error) => {
              errors.hasErrors = true;
              errors.createdMapObjects.push(arrow);
              console.error(error);
            })
        );
      } else {
        promises.push(
          handleRequest(
            client.updateMapObject(arrow.id, arrowData).catch((error) => {
              errors.hasErrors = true;
              errors.updatedMapObjects.push(arrow);
              console.error(error);
            })
          )
        );
      }
    });
    deletedMapObjectIds
      .filter((deletedMapObjectId) => deletedMapObjectId.indexOf("new") !== 0)
      .forEach((deletedMapObjectId) => {
        promises.push(
          handleRequest(client.deleteMapObject(deletedMapObjectId)).catch(
            (error) => {
              errors.hasErrors = true;
              errors.deletedMapObjects.push(deletedMapObjectId);
              console.error(error);
            }
          )
        );
      });
    try {
      // Saving
      await Promise.all(promises);

      if (errors.hasErrors) {
        setOnSaveErrors(errors);
      } else {
        if (afterSeatFormSubmit) {
          afterSeatFormSubmit();
        }

        setShowSavedMessage(true);
      }
    } catch (error) {
      console.error(error);
      // TODO show when create/update/delete fails
    }
    setSaving(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleRequest,
    client,
    deletedSeatIds,
    updateMapFile,
    mapId,
    zoom,
    deletedMapObjectIds,
  ]);

  const onClose = useCallback(
    (saved = false) => {
      if (isCleaningMap) {
        history.push("/admin/cleaning-map-overview");
      } else {
        history.push(`/admin/map-overview${saved ? "?success=map-saved" : ""}`);
      }
    },
    [isCleaningMap, history]
  );

  const onSaveAndClose = useCallback(async () => {
    await onSave();
    onClose(true);
  }, [onSave, onClose]);

  const deleteSelection = useCallback(() => {
    setDeletedSeatIds([
      ...deletedSeatIds,
      ...seats.current
        .filter(({ selected, _operations }) => selected && _operations?.canDelete)
        .map((seat) => seat.id),
    ]);

    setDeletedMapObjectIds([
      ...deletedMapObjectIds,
      ...arrows.current
        .filter(({ selected }) => selected)
        .map((arrow) => arrow.id),
    ]);
    arrows.current = arrows.current.filter(({ selected }) => !selected);
    seats.current = seats.current.filter(({ selected }) => !selected);

    setSelectedSeats([]);
    setSelectedArrows([]);
    setHasSelectedItems(false);
    setHasRotateAbleItems(false);
  }, [
    deletedSeatIds,
    setDeletedSeatIds,
    deletedMapObjectIds,
    setDeletedMapObjectIds,
  ]);

  const getDefaultSeatValue: SeatOnMap = useMemo(
    () => ({
      seatType: SeatType.Desk,
      name: "",
      id: "",
      seatTags: [],
      morningBlocked: [],
      afternoonBlocked: [],
      userGroups: [],
      coordX: 0,
      coordY: 0,
      mapId,
      barcodes: [],
    }),
    [mapId]
  );

  const createSeat = useRef<SeatOnMap>(getDefaultSeatValue);

  const mapPositionInitialized = useRef(false);

  const toggleAddSeat = useCallback(() => {
    deselectAllItems();
    setSelectingSeat(undefined);
    setIsAddingSeat((oldValue) => !oldValue);
    setIsCreatingZone(false);
    setIsAddingArrow(false);
    if (toggleSeatForm) {
      toggleSeatForm(!isAddingSeat);
    }

    createSeat.current = getDefaultSeatValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setIsAddingSeat,
    setIsCreatingZone,
    setIsAddingArrow,
    getDefaultSeatValue,
  ]);

  const toggleCreateZone = useCallback(() => {
    setIsCreatingZone((oldValue) => !oldValue);
    setIsAddingSeat(false);
    setIsAddingArrow(false);
  }, [setIsAddingSeat, setIsCreatingZone, setIsAddingArrow]);

  const toggleAddArrow = useCallback(() => {
    setIsAddingArrow((oldValue) => !oldValue);
    setIsCreatingZone(false);
    setIsAddingSeat(false);
  }, [setIsAddingArrow]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Delete" && (selectedSeats.length > 0 || selectedArrows.length > 0)) {
        openDeleteOverlay();
      }
    };
    window.addEventListener("keydown", keyDownHandler);
    return () => window.addEventListener("keydown", keyDownHandler);
  }, [selectedArrows.length, selectedSeats.length]);

  useEffect(() => {
    setIsEditSeatFormOpened(selectedSeats.length === 1);
  }, [selectedSeats.length]);

  useEffect(() => {
    if (triggerOnSaveAndClose !== null) {
      onSaveAndClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerOnSaveAndClose]);

  useEffect(() => {
    if (triggerOnSave !== null) {
      onSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerOnSave]);

  useEffect(() => {
    if (triggerUpload !== null && currentImage !== null) {
      onChangeImage(currentImage as DocumentResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpload]);

  useEffect(() => {
    const init = async () => {
      // Set the cleaning items if it is a cleaning map.
      if (isCleaningMap) {
        CleaningMapFunctionality.setCleaningItems(seats.current);

        const images = await loadImages() as { [key: string]: HTMLImageElement };
        const croppedImage = cropPlusExport(
          (context: CanvasRenderingContext2D) => {
            draw(true, context, 0, 0, 1, images);
          },
          map.current?.width || 0,
          map.current?.height || 0,
        );
        setCleaningMapImage(croppedImage);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO isCleaningMap => draw met context zoals bij pijlen, die image gebruiken en tonen

  const initializeMapPosition = (width: number, height: number) => {
    if (width && height && dragPosition?.current && webGl.current) {
      // Calculate X and Y in order to center the map.
      x.current = 0;
      y.current = 0;

      // Set the default drag position.
      // This must be done here, otherwise the drag position will reset every state update.
      if (webGl.current.drawingBufferWidth > width) {
        // x.current = Dimensions.get("window").width / 2 + width / 2;
        x.current = webGl.current.drawingBufferWidth / 2 - width / 2;
        initializedX.current = x.current;
      }
      dragPosition.current = {
        x: 0,
        y: 0,
      };

      mapPositionInitialized.current = true;
    }
  };

  if (!map.current) {
    map.current = asset[0];
  }
  workspaceIllustration.current = asset[1];
  workspaceSelectedIllustration.current = asset[2];
  workspaceDangerIllustration.current = asset[3];
  workspaceInactiveIllustration.current = asset[4];
  lunchIllustration.current = asset[5];
  lunchSelectedIllustration.current = asset[6];
  lunchDangerIllustration.current = asset[7];
  lunchInactiveIllustration.current = asset[8];
  meetingRoomIllustration.current = asset[9];
  meetingRoomSelectedIllustration.current = asset[10];
  meetingRoomDangerIllustration.current = asset[11];
  meetingRoomInactiveIllustration.current = asset[12];
  arrowIllustration.current = asset[13];

  // Calculate the aspect ratio in order to make the canvas height fit the map width.
  // Calculate map height according to the width and aspect ratio.

  const activeStrokeSize = 10;

  const getIllustrationForType = (
    type: SeatType,
    danger: boolean,
    isActive?: boolean,
    selected?: boolean,
    images?: { [key: string]: HTMLImageElement }
  ) => {
    isActive = typeof isActive === "undefined" ? true : isActive;
    switch (type) {
      case "lunch":
        return !danger && !selected && isActive
          ? images?.lunchIllustration || lunchIllustration.current
          : selected
          ? images?.lunchSelectedIllustration ||
            lunchSelectedIllustration.current
          : !isActive
          ? images?.lunchInactiveIllustration ||
            lunchInactiveIllustration.current
          : images?.lunchDangerIllustration || lunchDangerIllustration.current;
      case "meeting":
        return !danger && !selected && isActive
          ? images?.meetingRoomIllustration || meetingRoomIllustration.current
          : selected
          ? images?.meetingRoomSelectedIllustration ||
            meetingRoomSelectedIllustration.current
          : !isActive
          ? images?.meetingRoomInactiveIllustration ||
            meetingRoomInactiveIllustration.current
          : images?.meetingRoomDangerIllustration ||
            meetingRoomDangerIllustration.current;
      default:
        return !danger && !selected && isActive
          ? images?.workspaceIllustration || workspaceIllustration.current
          : selected
          ? images?.workspaceSelectedIllustration ||
            workspaceSelectedIllustration.current
          : !isActive
          ? images?.workspaceInactiveIllustration ||
            workspaceInactiveIllustration.current
          : images?.workspaceDangerIllustration ||
            workspaceDangerIllustration.current;
    }
  };

  const getFillStyleForType = (type: SeatType) => {
    switch (type) {
      case "lunch":
        return "rgba(225, 228, 177, .5)";
      case "meeting":
        return "rgba(97, 148, 224, .5)";
      default:
        return "rgba(64, 162, 178, .5)";
    }
  };

  const drawArrows = (
    context: CanvasRenderingContext2D,
    images?: { [key: string]: HTMLImageElement },
    currentX?: number,
    currentY?: number,
    currentZoom = 1,
  ) => {
    arrows.current.forEach(({ x: xPos, y: yPos, selected, rotation }) => {
      const xOffset = (currentX || 0) + xPos * currentZoom;
      const yOffset = (currentY || 0) + yPos * currentZoom;
      const xCenterOffset = xOffset + arrowIconSize / 2;
      const yCenterOffset = yOffset + arrowIconSize / 2;

      if (selected) {
        context.beginPath();
        context.arc(
          xCenterOffset,
          yCenterOffset,
          arrowIconSize / 2 + activeStrokeSize,
          0,
          2 * Math.PI
        );
        context.fillStyle = selectColor;
        context.fill();
      }

      context.save();
      context.translate(
        xOffset + arrowIconSize / 2,
        yOffset + arrowIconSize / 2
      );
      context.rotate(
        ((typeof rotation === "undefined" ? 0 : rotation) * Math.PI) / 180
      );

      context.drawImage(
        (images?.arrowImage || arrowIllustration.current) as CanvasImageSource,
        arrowIconSize / -2,
        arrowIconSize / -2,
        arrowIconSize,
        arrowIconSize
      );

      context.restore();
    });
  };

  const drawSeats = useCallback((
    context: CanvasRenderingContext2D,
    images?: { [key: string]: HTMLImageElement },
    currentX?: number,
    currentY?: number,
    currentZoom = 1,
  ) => {
    seats.current.forEach(
      ({
        coordX: possibleXPos,
        coordY: possibleYPos,
        selected,
        seatType,
        dirty,
        isActive,
        isZone,
        zoneCoordinates,
      }) => {
        const xPos = (isZone ? Number(zoneCoordinates?.split(',')[0]) : possibleXPos) || 0;
        const yPos = (isZone ? Number(zoneCoordinates?.split(',')[1]) : possibleYPos) || 0;

        const xOffset = (currentX || 0) + xPos * currentZoom; // The old admin saved images from top left instead of center location... - mapIconSize / 2;
        const yOffset = (currentY || 0) + yPos * currentZoom; // The old admin saved images from top left instead of center location... - mapIconSize / 2;

        if (selected) {
          context.beginPath();
          context.arc(
            xOffset + mapIconSize / 2,
            yOffset + mapIconSize / 2,
            mapIconSize / 2 + activeStrokeSize,
            0,
            2 * Math.PI
          );
          context.fillStyle = selectColor;
          context.fill();

          // This draws a square with the seat name, should be more optimized when enable again

          // ctx.current.beginPath();
          // ctx.current.font = "16pt sans-serif";
          // ctx.current.textBaseline = "top";
          // const { width } = ctx.current.measureText(name);

          // ctx.current.fillStyle = color.white;

          // const squareWidth = width + mapIconSize / 2;
          // const squareHeight = 30;

          // ctx.current.fillRect(
          //   xCenterOffset - squareWidth / 2,
          //   yOffset + mapIconSize + squareHeight / 2,
          //   squareWidth,
          //   squareHeight
          // );

          // const textOffsetBottom = 22;

          // ctx.current.fill();

          // ctx.current.beginPath();
          // ctx.current.fillStyle = color.darker;

          // ctx.current.fillText(
          //   name,
          //   xCenterOffset - width / 2,
          //   yOffset + mapIconSize + textOffsetBottom
          // );

          // ctx.current.fill();
        }

        const danger = Boolean(dirty && isCleaningMap);

        const seatIllustration = getIllustrationForType(
          seatType,
          danger,
          isActive,
          selected,
          images
        );

        context.drawImage(
          seatIllustration as CanvasImageSource,
          xOffset,
          yOffset,
          mapIconSize,
          mapIconSize
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seats.current]);

  const drawZones = () => {
    canvasDrawZones.current.forEach(({ cords, seatType }) => {
      ctx.current.beginPath();
      ctx.current.fillStyle = getFillStyleForType(seatType);

      cords.forEach(({ x: xPos, y: yPos }, index, array) => {
        const xOffset = x.current! + xPos * zoom.current;
        const yOffset = y.current! + yPos * zoom.current;
        ctx.current.lineTo(xOffset, yOffset);
      });

      ctx.current.fill();
      ctx.current.closePath();
    });
  };

  const zonePointSize = 10;

  // const drawCreatingZone = () => {
  //   ctx.current.beginPath();
  //   ctx.current.fillStyle = "rgba(100, 100, 255, .25)";

  //   zonePoints.current.forEach(({ x: xPos, y: yPos }) => {
  //     const xOffset = x.current! + xPos * zoom.current;
  //     const yOffset = y.current! + yPos * zoom.current;
  //     ctx.current.lineTo(xOffset, yOffset);

  //     ctx.current.arc(xOffset, yOffset, zonePointSize, 0, 2 * Math.PI);
  //   });

  //   ctx.current.fill();
  //   ctx.current.closePath();
  // };

  const draw = async (
    includeSeats = true,
    context?: CanvasRenderingContext2D | undefined,
    currentX?: number | undefined,
    currentY?: number | undefined,
    currentZoom?: number | undefined,
    images?: { [key: string]: HTMLImageElement }
  ) => {
    if (!context) {
      context = ctx.current;
    }
    if (context === null) {
      throw new Error("No context");
    }
    if (!map || !map.current) {
      return;
    }

    if (map.current === null) {
      return;
    }
    if (typeof currentX === "undefined") {
      currentX = x.current || 0;
    }
    if (typeof currentY === "undefined") {
      currentY = y.current || 0;
    }
    if (typeof currentZoom === "undefined") {
      currentZoom = zoom.current;
    }
    // console.log(
    //   "x.current",
    //   x.current,
    //   currentX,
    //   y.current,
    //   currentY,
    //   map.current,
    //   context
    // );
    context?.drawImage(
      (images?.mapImage || map.current) as CanvasImageSource,
      currentX,
      currentY,
      (map?.current?.width || 0) * currentZoom,
      (map?.current?.height || 0) * currentZoom
    );
    if (context) {
      drawArrows(context, images, currentX, currentY, currentZoom);
    }
    // drawZones();
    if (includeSeats && context) {
      drawSeats(context, images, currentX, currentY, currentZoom);
    }
    // if (isCreatingZone) drawCreatingZone();
    const errorCode = webGl && webGl.current ? webGl.current!.getError() : 0;
    if (errorCode != 0) handleWebGlError(errorCode);
    // @ts-ignore
    if (context?.flush) {
      // @ts-ignore
      context.flush();
    }
  };

  const handleWebGlError = (errorCode: number) => {
    switch (errorCode) {
      case webGlImageLoadErrorCode:
      case webGlImageUndefinedErrorCode:
        setTimeout(draw, 1);
        break;
      default:
        console.error(`ERROR: Web gl error code ${errorCode}`);
        break;
    }
  };

  const changeZoom = (value: number) => {
    if (!ctx.current || zoom.current + value <= 0.1) return;

    const yOffset =
      y.current! -
      (((map?.current?.height || 0) -
        (map?.current?.height || 0) * zoom.current) /
        2) *
      pixelDensity;
    const xOffset =
      x.current! -
      (((map?.current?.width || 0) -
        (map?.current?.width || 0) * zoom.current) /
        2) *
      pixelDensity;

    zoom.current += value;

    x.current =
      xOffset +
      (((map?.current?.width || 0) -
        (map?.current?.width || 0) * zoom.current) /
        2) *
      pixelDensity;
    y.current =
      yOffset +
      (((map?.current?.height || 0) -
        (map?.current?.height || 0) * zoom.current) /
        2) *
        pixelDensity;

    draw();
  };

  const changeRotationOfSelection = (value: number) => {
    setIsResettable(true);
    arrows.current = arrows.current.map((arrow) => {
      if (!arrow.selected) return arrow;

      const nextRotation =
        (typeof arrow.rotation === "undefined" ? 0 : arrow.rotation) +
        value * 45;
      if (nextRotation <= -360) arrow.rotation = 0;
      else if (nextRotation >= 360) arrow.rotation = 0;
      else arrow.rotation = nextRotation;

      return arrow;
    });

    draw();
  };

  const deselectAllItems = () => {
    setSelectedSeats([]);
    setSelectedArrows([]);

    arrows.current = arrows.current.map((arrow) => {
      arrow.selected = false;
      return arrow;
    });

    seats.current = seats.current.map((seat) => {
      seat.selected = false;
      return seat;
    });

    setHasSelectedItems(false);
    setHasRotateAbleItems(false);

    draw();
  };

  const setDeleteAble = () => {
    // @ts-ignore
    const mergedArray = seats.current.concat(arrows.current);

    setCanDelete(!(mergedArray
      .some(({ selected, _operations }) => selected && _operations && !_operations.canDelete)));

    const havingSelectedItems = mergedArray.some(({ selected }) => selected);
    setHasSelectedItems(havingSelectedItems);
  };

  const setRotateAble = () => {
    const rotateAble = arrows.current.some(({ selected }) => selected);

    setHasRotateAbleItems(rotateAble);
  };

  const toggleSelectedArrow = (arrow: ArrowOnMap) => {
    arrow.selected = !arrow.selected;

    if (arrow.selected) {
      setSelectedArrows((oldValue) => [...oldValue, arrow]);
    } else {
      setSelectedArrows((oldValue) =>
        oldValue.filter(({ id }) => id !== arrow.id)
      );
    }

    setDeleteAble();
    setRotateAble();

    draw();
  };

  const toggleSelectedSeat = (seat: SeatOnMap) => {
    seat.selected = !seat.selected;

    if (seat.selected) {
      setSelectedSeats((oldValue) => [...oldValue, seat]);
    } else {
      setSelectedSeats((oldValue) =>
        oldValue.filter(({ id }) => id !== seat.id)
      );
    }

    setDeleteAble();

    draw();
  };

  const getClickPosRelativeToMap = (nativeEvent: NativeTouchEvent) => {
    const canvas: any = nativeEvent.target;
    const { left, top } = canvas.getBoundingClientRect();
    return {
      x:
        ((nativeEvent.pageX - left) * pixelDensity - x.current!) / zoom.current,
      y: ((nativeEvent.pageY - top) * pixelDensity - y.current!) / zoom.current,
    };
  };

  const getTouchedSeat = (nativeEvent: NativeTouchEvent) => {
    const clickPosRelativeToMap = getClickPosRelativeToMap(nativeEvent);

    return seats.current.find(
      ({ coordX, coordY, isZone, zoneCoordinates }) => {
        const posX = (isZone ? Number(zoneCoordinates?.split(',')[0]) : coordX) || 0;
        const posY = (isZone ? Number(zoneCoordinates?.split(',')[1]) : coordY) || 0;

        return posX + mapIconSize / zoom.current >= clickPosRelativeToMap.x &&
                posX <= clickPosRelativeToMap.x &&
                posY + mapIconSize / zoom.current >= clickPosRelativeToMap.y &&
                posY <= clickPosRelativeToMap.y
      }
    );
  };

  const getTouchedArrow = (nativeEvent: NativeTouchEvent) => {
    const clickPosRelativeToMap = getClickPosRelativeToMap(nativeEvent);

    return arrows.current.find(
      ({ x, y }) =>
        x + arrowIconSize / zoom.current >= clickPosRelativeToMap.x &&
        x <= clickPosRelativeToMap.x &&
        y + arrowIconSize / zoom.current >= clickPosRelativeToMap.y &&
        y <= clickPosRelativeToMap.y
    );
  };

  const initializeContext = async (gl: ExpoWebGLRenderingContext) => {
    webGl.current = gl;

    try {
      ctx.current = new Expo2DContext(gl);
      await ctx.current.initializeText();
      if (map && map.current) {
        initializeMapPosition(
          map?.current?.width || 0,
          map?.current?.height || 0
        );
      }
      draw();
    } catch (error) {
      console.error(error);
    }
  };

  const getSelectedCordsFromNativeEvent = (nativeEvent: NativeTouchEvent) => {
    const canvas: any = nativeEvent.target;
    const { left, top } = canvas.getBoundingClientRect();

    return {
      selectedX:
        ((nativeEvent.pageX - left) * pixelDensity - x.current!) / zoom.current,
      selectedY:
        ((nativeEvent.pageY - top) * pixelDensity - y.current!) / zoom.current,
    };
  };

  const addZonePoint = (nativeEvent: NativeTouchEvent) => {
    const { selectedX, selectedY } =
      getSelectedCordsFromNativeEvent(nativeEvent);

    zonePoints.current.push({
      x: selectedX,
      y: selectedY,
    });

    draw();

    return false;
  };

  const addSeat = (nativeEvent: NativeTouchEvent) => {
    const { selectedX, selectedY } =
      getSelectedCordsFromNativeEvent(nativeEvent);

    // create seat
    if (createSeat.current.name) {
      const newSeat: SeatOnMap = {
        ...createSeat.current,
        id: `new-${new Date().getTime()}`,
        coordX: createSeat.current.isZone ? null : selectedX - mapIconSize / 2,
        coordY: createSeat.current.isZone ? null : selectedY - mapIconSize / 2,
        zoneCoordinates: createSeat.current.isZone ? `${selectedX - mapIconSize / 2},${selectedY - mapIconSize / 2}` : null,
        hasReservations: false,
      };

      // new seat
      seats.current.push(newSeat);

      createCallback.current(getNextSeatNumber());

      draw();

      setIsShowingCantDelete(false);
      setIsShowingDeleteWarning(false);
      setIsShowingCantAddSeat(false);
    } else {
      setIsShowingCantAddSeat(true);
    }

    return false;
  };

  const openDeleteOverlay = () => {
    setIsResettable(true);
    const canDelete = !selectedSeats.some(
      ({ hasReservations }) => hasReservations
    );
    if (canDelete) {
      setIsShowingDeleteWarning(true);
    } else {
      setIsShowingCantDelete(true);
    }
  };

  const addArrow = (nativeEvent: NativeTouchEvent) => {
    const { selectedX, selectedY } =
      getSelectedCordsFromNativeEvent(nativeEvent);

    const newArrow: ArrowOnMap = {
      layer: "furniture",
      typeName: "drawelement",
      subTypeName: "walkdirectionarrow",
      label: "",
      mapId,
      x: selectedX - arrowIconSize / 2,
      y: selectedY - arrowIconSize / 2,
      rotation: 0,
      id: `new-${new Date().getTime()}`,
    };

    arrows.current.push(newArrow);

    toggleSelectedArrow(newArrow);

    draw();
    return false;
  };

  const updateSeat = (newSeat: SeatOnMap) => {
    seats.current = seats.current.map((seat) =>
      seat.id === newSeat.id
        ? {
            ...newSeat,
            coordX: seat.isZone ? null : seat.coordX,
            coordY: seat.isZone ? null : seat.coordY,
            zoneCoordinates: seat.isZone ? seat.zoneCoordinates : null,
          }
        : seat
    );

    draw();
  };

  const setDragPosition = (nativeEvent: NativeTouchEvent) => {
    dragPosition.current.x = nativeEvent.pageX;
    dragPosition.current.y = nativeEvent.pageY;

    return false;
  };

  const cleanUpZoneCreation = () => {
    setIsCreatingZone(false);
    zonePoints.current = [];

    draw();
  };

  const createZone = (name: string, seatType: SeatType, seats?: number) => {
    if (zonePoints.current.length >= 3) {
      canvasDrawZones.current.push({
        name,
        seatType,
        seats,
        cords: zonePoints.current,
        id: `new-${new Date().getTime()}`,
      });

      setHtmlZoneState(canvasDrawZones.current);

      cleanUpZoneCreation();
      setZoneError("");
    } else {
      setZoneError(t("admin.map.zone.error"));
    }
  };

  const editZone = (editedZone: ZoneInterface) => {
    canvasDrawZones.current = canvasDrawZones.current.map((zone) => {
      if (zone.id === editedZone.id) return editedZone;
      return zone;
    });

    setHtmlZoneState(canvasDrawZones.current);

    draw();
  };

  const deleteZone = (deletedZone: ZoneInterface) => {
    canvasDrawZones.current = canvasDrawZones.current.filter(
      ({ id }) => id !== deletedZone.id
    );

    setHtmlZoneState(canvasDrawZones.current);

    draw();
  };

  const moveSelectedItems = ({ nativeEvent }: GestureResponderEvent) => {
    if (!isAddingSeat && !isAddingArrow) {
      const differenceX =
        (nativeEvent.pageX - dragPosition.current.x) * pixelDensity;
      const differenceY =
        (nativeEvent.pageY - dragPosition.current.y) * pixelDensity;

      seats.current = seats.current.map((seat) => {
        if (!seat.selected) return seat;

        if (seat.isZone) {
          seat.zoneCoordinates = `${Number(seat.zoneCoordinates?.split(',')[0]) + (differenceX / zoom.current)},${Number(seat.zoneCoordinates?.split(',')[1]) + (differenceY / zoom.current)}`;
          seat.coordX = null;
          seat.coordY = null;
        } else {
          seat.coordX = (seat.coordX ?? 0) + differenceX / zoom.current;
          seat.coordY = (seat.coordY ?? 0) + differenceY / zoom.current;
          seat.zoneCoordinates = null;
        }

        return seat;
      });

      arrows.current = arrows.current.map((arrow) => {
        if (!arrow.selected) return arrow;

        arrow.x += differenceX / zoom.current;
        arrow.y += differenceY / zoom.current;

        return arrow;
      });

      draw();

      return setDragPosition(nativeEvent);
    }

    return false;
  };

  const moveScreen = ({ nativeEvent }: GestureResponderEvent) => {
    const differenceX = nativeEvent.pageX - dragPosition.current.x;
    const differenceY = nativeEvent.pageY - dragPosition.current.y;

    x.current! += differenceX * pixelDensity;
    y.current! += differenceY * pixelDensity;

    draw();

    return setDragPosition(nativeEvent);
  };

  const onTouchMoveHandler = (event: any) => {
    if (isScreenMoving) {
      return moveScreen(event);
    }

    // if (isCreatingZone || isAddingArrow || isAddingSeat) {
    //   isScreenMoving && setIsScreenMoving(false);
    //   return false;
    // }

    isDraggingItems.current = true;

    if (selectingSeat || selectingArrow) {
      if ((selectingSeat && !selectingSeat.selected) || (selectingArrow && !selectingArrow?.selected)) {
        deselectAllItems();
      }

      if (selectingSeat && !selectingSeat.selected) {
        toggleSelectedSeat(selectingSeat);
      }
      if (selectingArrow && !selectingArrow.selected) {
        toggleSelectedArrow(selectingArrow);
      }

      isScreenMoving && setIsScreenMoving(false);
      return moveSelectedItems(event);
    }

    !isScreenMoving && setIsScreenMoving(true);
    return moveScreen(event);
  };

  const onMouseMoveHandler = ({ nativeEvent }: any) => {
    if (isScreenMoving) return;
    const selectedSeat = getTouchedSeat(nativeEvent);
    const selectedArrow = getTouchedArrow(nativeEvent);
    if (isHovering && !(selectedSeat || selectedArrow)) {
      setIsHovering(false);
    } else if (!isHovering && !!(selectedSeat || selectedArrow)) {
      setIsHovering(true);
    }
  };

  const onTouchStartHandler = ({ nativeEvent }: MouseEvent) => {
    if (isScreenMoving) {
      setDragPosition(nativeEvent);
      return false;
    }

    if (isEditable) {
      if (!(isAddingSeat || isAddingArrow || isCreatingZone)) {
        const selectedSeat = getTouchedSeat(nativeEvent);

        if (selectedSeat) {
          setSelectingSeat(selectedSeat);
        }

        const selectedArrow = getTouchedArrow(nativeEvent);

        if (selectedArrow) {
          setSelectingArrow(selectedArrow);
        }
      }
    }

    setDragPosition(nativeEvent);
    return false;
  };

  const resetMap = () => {
    seats.current = (mapData?.seats as SeatOnMap[])?.map((seat: SeatOnMap): SeatOnMap => ({
      ...seat,
      coordX: seat.isZone ? null : (seat.coordX ?? 0) - mapIconOffset,
      coordY: seat.isZone ? null : (seat.coordY ?? 0) - mapIconOffset,
      zoneCoordinates: seat.isZone ? `${Number(seat.zoneCoordinates?.split(',')[0])  - mapIconOffset},${Number(seat.zoneCoordinates?.split(',')[1])  - mapIconOffset}` : null,
    })) || [];
    arrows.current = (mapData?.arrows as ArrowOnMap[])?.map((seat: ArrowOnMap): ArrowOnMap => ({
      ...seat,
      x: seat.x - arrowIconOffset,
      y: seat.y - arrowIconOffset,
    })) || [];
    draw();
    setIsResettable(false);
  };

  const onTouchEndHandler = ({ nativeEvent }: MouseEvent) => {
    if (isEditable && !isScreenMoving) {
      if (selectingSeat) {
        setIsResettable(true);
      }
      if (isAddingSeat) return addSeat(nativeEvent);
      if (isAddingArrow) return addArrow(nativeEvent);
      if (isCreatingZone) return addZonePoint(nativeEvent);

      if (selectingSeat) {
        if (!isDraggingItems.current) {
          toggleSelectedSeat(selectingSeat);
        }

        if (!selectingSeat.selected) {
          setIsShowingCantDelete(false);
          setIsShowingDeleteWarning(false);
          setIsShowingCantAddSeat(false);
        }

        setSelectingSeat(undefined);
      }

      if (selectingArrow) {
        if (!isDraggingItems.current) {
          toggleSelectedArrow(selectingArrow);
        }

        setSelectingArrow(undefined);
      }

      if (!selectingSeat && !selectingArrow && !isDraggingItems.current) {
        deselectAllItems();
      }

      isDraggingItems.current = false;
    }
    isScreenMoving && setIsScreenMoving(false);

    return false;
  };

  const getSelectedItems = () => {
    const selectedSeats = seats.current.filter(({ selected }) => selected) as (ArrowOnMap & SeatOnMap)[];
    const selectedArrows = arrows.current.filter(({ selected }) => selected);
    return selectedSeats.concat(selectedArrows as (ArrowOnMap & SeatOnMap)[]);
  };

  const duplicateSelection = () => {
    setIsResettable(true);
    const selectedSeats = seats.current.filter(({ selected }) => selected);
    const selectedArrows = arrows.current.filter(({ selected }) => selected);
    deselectAllItems();

    const duplicatedSeats = selectedSeats.map((item, index) => {
      const newName = `${item.name} - copy`;
      const rExp = new RegExp(`${newName}([\\d]*)$`);

      const affixArray = seats.current.reduce((affixes, seat) => {

        if (rExp.test(seat.name)) {
          const affixNumber = +seat.name.replace(rExp, '$1');
          return [...affixes, affixNumber === 0 ? 1 : affixNumber];
        }
        return affixes;
      }, [] as number[]);

      const selectedAffix = Math.max(...affixArray, 1) + 1;

      return ({
        ...item,
        name: `${newName}${affixArray.length === 0 ? '' : selectedAffix}`,
        coordX: item.isZone ? null : (item.coordX ?? 0) + 40 + mapIconSize,
        zoneCoordinates: item.isZone ? `${Number(item.zoneCoordinates?.split(',')[0]) + 40 + mapIconSize},${Number(item.zoneCoordinates?.split(',')[1])}` : null,
        id: `new-${new Date().getTime()}-${index}`,
        selected: true,
      })
    });

    const duplicatedArrows = selectedArrows.map((item, index) => ({
      ...item,
      x: item.x + 40 + arrowIconSize,
      id: `new-${new Date().getTime()}-${index}`,
      selected: true,
    }));

    seats.current = [...seats.current, ...duplicatedSeats];
    setSelectedSeats(duplicatedSeats);
    arrows.current = [...arrows.current, ...duplicatedArrows];
    setSelectedArrows(duplicatedArrows);

    setHasSelectedItems(duplicatedArrows.length + duplicatedSeats.length > 0);
    setHasRotateAbleItems(duplicatedArrows.length > 0);

    draw();
  };

  const alignLeft = () => {
    setIsResettable(true);
    const selectedItems = getSelectedItems();

    const minimumX = selectedItems.reduce(
      (min, { isZone, coordX, zoneCoordinates, x }) => (((isZone ? Number(zoneCoordinates?.split(',')[0]) : coordX) ?? x) < min ? ((isZone ? Number(zoneCoordinates?.split(',')[0]) : coordX) ?? x) : min),
      ((selectedItems[0].isZone ? Number(selectedItems[0].zoneCoordinates?.split(',')[0]) : selectedItems[0].coordX) ?? selectedItems[0].x) || 0
    );

    seats.current.map((seat) => {
      if (seat.selected) {
        if (seat.isZone) {
          seat.zoneCoordinates = `${minimumX},${Number(seat.zoneCoordinates?.split(',')[1])}`;
        } else {
          seat.coordX = minimumX;
        }
      }
      return seat;
    });

    arrows.current.map((arrow) => {
      if (arrow.selected) arrow.x = minimumX;
      return arrow;
    });

    draw();
  };

  const alignTop = () => {
    setIsResettable(true);
    const selectedItems = getSelectedItems();

    const minimumY = selectedItems.reduce(
      (min, { isZone, coordY, zoneCoordinates, y }) => (((isZone ? Number(zoneCoordinates?.split(',')[1]) : coordY) ?? y) < min ? ((isZone ? Number(zoneCoordinates?.split(',')[1]) : coordY) ?? y) : min),
      ((selectedItems[0].isZone ? Number(selectedItems[0].zoneCoordinates?.split(',')[1]) : selectedItems[0].coordY) ?? selectedItems[0].y) || 0
    );
    seats.current.map((seat) => {
      if (seat.selected) {
        if (seat.isZone) {
          seat.zoneCoordinates = `${Number(seat.zoneCoordinates?.split(',')[0])},${minimumY}`;
        } else {
          seat.coordY = minimumY;
        }
      }
      return seat;
    });

    arrows.current.map((arrow) => {
      if (arrow.selected) arrow.y = minimumY;
      return arrow;
    });

    draw();
  };

  const alignBottom = () => {
    setIsResettable(true);
    const selectedItems = getSelectedItems();

    const maximumY = selectedItems.reduce(
      (max, { isZone, coordY, zoneCoordinates, y }) => (((isZone ? Number(zoneCoordinates?.split(',')[1]) : coordY) ?? y) > max ? ((isZone ? Number(zoneCoordinates?.split(',')[1]) : coordY) ?? y) : max),
      ((selectedItems[0].isZone ? Number(selectedItems[0].zoneCoordinates?.split(',')[1]) : selectedItems[0].coordY) ?? selectedItems[0].y) || 0
    );
    seats.current.map((seat) => {
      if (seat.selected) {
        if (seat.isZone) {
          seat.zoneCoordinates = `${Number(seat.zoneCoordinates?.split(',')[0])},${maximumY}`;
        } else {
          seat.coordY = maximumY;
        }
      }
      return seat;
    });

    arrows.current.map((arrow) => {
      if (arrow.selected) arrow.y = maximumY;
      return arrow;
    });

    draw();
  };

  const alignRight = () => {
    setIsResettable(true);
    const selectedItems = getSelectedItems();

    const minimumX = selectedItems.reduce(
      (max, { isZone, coordX, zoneCoordinates, x }) => (((isZone ? Number(zoneCoordinates?.split(',')[0]) : coordX) ?? x) > max ? ((isZone ? Number(zoneCoordinates?.split(',')[0]) : coordX) ?? x) : max),
      ((selectedItems[0].isZone ? Number(selectedItems[0].zoneCoordinates?.split(',')[0]) : selectedItems[0].coordX) ?? selectedItems[0].x) || 0
    );
    seats.current.map((seat) => {
      if (seat.selected) {
        if (seat.isZone) {
          seat.zoneCoordinates = `${minimumX},${Number(seat.zoneCoordinates?.split(',')[1])}`;
        } else {
          seat.coordX = minimumX;
        }
      }
      return seat;
    });

    arrows.current.map((arrow) => {
      if (arrow.selected) arrow.x = minimumX;
      return arrow;
    });

    draw();
  };

  const onChangeImage = async (image: DocumentResult) => {
    if (image?.type === "success" && !!image.file) {
      map.current = (await Asset.loadAsync(image.uri))[0];
      setUpdateMapFile(image.file);
      setTimeout(() => {
        if (webGl.current) {
          initializeContext(webGl.current);
        }
      }, 100);
    }
  };

  const { width: windowWidth } = useWindowDimensions();

  const cleaningMapMaxWidth = Math.min(1024, windowWidth);

  const getNextSeatNumber = () => {
    return (
      Math.max(
        0,
        ...seats.current.map((seat) => {
          const matches = seat.name.match(/(\d+)/);
          if (matches && matches.length > 0) {
            return parseInt(matches[matches.length - 1], 10);
          }
          return 0;
        })
      ) + 1
    );
  };

  useEffect(() => {
    setTimeout(() => {
      if (webGl.current) {
        initializeContext(webGl.current);
        draw();
      }
    }, 100);
  }, [windowWidth]);

  return (
    <MapWrapper
      ref={ref as RefObject<View>}
      zIndex={0}
      overflow="visible"
      isMoving={isScreenMoving}
      isHovering={isHovering}
      isAdding={isAddingSeat || isAddingArrow}
    >
      {saving && <LoadOverlay />}
      {isShowingDeleteWarning && (
        <Portal>
          <OverlayWrapper>
            <DismissAbleOverlay
              title={t("admin.map.editControl.deleteConfirmTitle")}
              onCloseCallback={() => setIsShowingDeleteWarning(false)}
            >
              <SmallText>
                {t("admin.map.editControl.deleteConfirmDescription")}
              </SmallText>
              <DeleteButtonWrapper>
                <Button
                  borderColor={color.primary}
                  backgroundColor="transparent"
                  backgroundHoverColor={color.primary}
                  textHoverColor={color.white}
                  onPress={() => {
                    deleteSelection();
                    draw();
                    setIsShowingDeleteWarning(false);
                  }}
                >
                  {t("admin.map.delete.yes")}
                </Button>
                <Button
                  backgroundColor={color.primary}
                  backgroundHoverColor={color.primaryLight}
                  textHoverColor={color.darker}
                  onPress={() => setIsShowingDeleteWarning(false)}
                  style={{
                    marginLeft: spacing.medium,
                  }}
                >
                  {t("admin.map.delete.no")}
                </Button>
              </DeleteButtonWrapper>
            </DismissAbleOverlay>
          </OverlayWrapper>
        </Portal>
      )}
      <View
        style={{
          zIndex: 4,
          backgroundColor: color.white,
          position: Position.sticky,
          flexDirection: flex.direction.row,
          top: isCleaningMap ? "0" : "105px",
          alignItems: flex.justifyContent.center,
          justifyContent: flex.justifyContent.spaceBetween,
          marginTop: `-${spacing.medium}`,
        }}
      >
        <StyledTitle style={{ opacity: 0 }}>
          {isCleaningMap && `${t("admin.map.cleaning.title")}: `}
          {/* {mapData.name} */}
        </StyledTitle>
        {/* <View style={{ backgroundColor: color.white,height: '80px',width:'100%' }}>

        </View> */}
        <View
          style={{
            flexDirection: "row",
          }}
        >
          {isCleaningMap && canPrint && (
            <Button
              borderRadius={borderRadius.tiny}
              style={{ marginRight: spacing.medium }}
              onPress={handlePrint}
            >
              {t("admin.map.cleaning.print")}
            </Button>
          )}

          {!isCleaningMap && !triggerActionFromParent && (
            <>
              <FileUpload
                style={{ marginRight: spacing.small }}
                acceptedFileTypes="image/png, image/jpeg"
                placeholder={t("admin.map.create.form.updateImagePlaceholder")}
                onSubmit={onChangeImage}
              />
              <Button
                borderRadius={borderRadius.tiny}
                style={{ marginRight: spacing.small }}
                onPress={onSaveAndClose}
              >
                {t("admin.map.edit.saveAndExit")}
              </Button>
              <Button
                borderRadius={borderRadius.tiny}
                style={{ marginRight: spacing.small }}
                onPress={onSave}
              >
                {t("admin.map.edit.save")}
              </Button>
            </>
          )}
        </View>
      </View>
      {showSavedMessage ? (
        <Notification
          type="success"
          closeNotification={() => setShowSavedMessage(false)}
        >
          {t("general.savedSuccess")}
        </Notification>
      ) : null}
      {isEditable ? (
        <ControlBar
          isAddingSeat={isAddingSeat}
          toggleIsAddingSeat={toggleAddSeat}
          isAddingWalkingRoute={isAddingArrow}
          toggleIsAddingWalkingRoute={toggleAddArrow}
          isCreatingZone={isCreatingZone}
          toggleIsCreatingZone={toggleCreateZone}
          hasSelectedItems={hasSelectedItems}
          hasRotatableItems={hasRotateAbleItems}
          rotateSelection={changeRotationOfSelection}
          duplicateSelection={duplicateSelection}
          deleteSelection={openDeleteOverlay}
          deletable={canDelete}
          alignTop={alignTop}
          alignBottom={alignBottom}
          alignLeft={alignLeft}
          alignRight={alignRight}
          isResettable={isResettable}
          reset={resetMap}
          amountOfSelectedItems={selectedSeats.length + selectedArrows.length}
        />
      ) : null}

      <MapDetailWrapper>
        <Wrapper ref={printComponentRef as RefObject<View>}>
          <WrapperRight>
            {isPrintMode && (
              <StyledTitleTextCenter>
                {mapData.locationName + ">"} {mapData.name}
              </StyledTitleTextCenter>
            )}
            <Wrapper>
              {!isCleaningMap && (
                <GLView
                  key={`${map.current.width}-${map?.current?.height || 0}`}
                  onStartShouldSetResponder={onTouchStartHandler}
                  onMouseUp={onTouchEndHandler}
                  onMouseMove={onMouseMoveHandler}
                  onMoveShouldSetResponder={onTouchMoveHandler}
                  style={{
                    flex: 1,
                    width: windowWidth,
                    height: map?.current?.height || 0,
                  }}
                  onContextCreate={initializeContext}
                />
              )}
              {isCleaningMap && cleaningMapImage && (
                <View
                  style={{
                    width:
                      map.current?.width === null || map.current?.width > cleaningMapMaxWidth
                        ? cleaningMapMaxWidth
                        : map.current.width === null ? undefined : map.current.width,
                    height:
                      map.current?.width === null || map.current?.width > cleaningMapMaxWidth
                        ? (cleaningMapMaxWidth / (map.current?.width || 1)) * (map.current?.height || 0)
                        : map.current.height === null ? undefined : map.current.height,
                  }}
                >
                  <ImageComponent
                    style={{ flex: 1, width: undefined, height: undefined }}
                    source={{
                      uri: cleaningMapImage,
                    }}
                  />
                </View>
              )}
            </Wrapper>

            {isCreatingZone && (
              <CreateZoneForm
                onCreate={createZone}
                onCancel={cleanUpZoneCreation}
              />
            )}
            {zoneError ? <ErrorText>{zoneError}</ErrorText> : null}

            {htmlZones.length ? (
              <StyledTitle>{t("admin.map.zone.title")}</StyledTitle>
            ) : null}

            {htmlZones.map((zone) => (
              <EditZoneCard
                key={zone.id}
                zone={zone}
                onUpdate={editZone}
                onDelete={deleteZone}
              />
            ))}
            {isCleaningMap ? (
              <CleaningCardWrapper style={{ maxWidth: cleaningMapMaxWidth }}>
                {seats.current
                  .sort((a, b) => Number(!a?.dirty) - Number(!b?.dirty))
                  .map((seat, index) => (
                    <CleaningSeatCard
                      size={pdfIconSize}
                      {...seat}
                      key={seat.id}
                      isLastInRow={(index + 1) % 3 === 0}
                    />
                  ))}
              </CleaningCardWrapper>
            ) : null}
          </WrapperRight>
          {!isCleaningMap && (
            <Portal wrapperId="zoom-wrapper">
              <ZoomButtonWrapper>
                <ZoomButton>
                  <ButtonText onPress={() => changeZoom(0.1)}>+</ButtonText>
                </ZoomButton>
                <ZoomButton last>
                  <ButtonText onPress={() => changeZoom(-0.1)}>-</ButtonText>
                </ZoomButton>
              </ZoomButtonWrapper>
            </Portal>
          )}
          <OverlayRightWrapper>
            {onSaveErrors && (
              <DismissAbleOverlay
                title={t("admin.map.saveErrors.title")}
                onCloseCallback={() => setOnSaveErrors(undefined)}
              >
                <SmallText>{t("admin.map.saveErrors.description")}</SmallText>
                {onSaveErrors.updatedMapFile && (
                  <SmallText>- {t("admin.map.saveErrors.mapFile")}</SmallText>
                )}
                {[...onSaveErrors.createdSeats, ...onSaveErrors.updatedSeats].map(
                  (seat) => (
                    <SmallText key={seat.id}>
                      - {t("admin.map.saveErrors.seat")}: {seat.name}
                    </SmallText>
                  )
                )}
                {onSaveErrors.deletedSeats.length > 0 && (
                  <SmallText>
                    - {t("admin.map.saveErrors.deletedSeats")}:{" "}
                    {onSaveErrors.deletedSeats.length}
                  </SmallText>
                )}
                {[
                  ...onSaveErrors.createdMapObjects,
                  ...onSaveErrors.updatedMapObjects,
                ].map((arrow, index) => (
                  <SmallText key={arrow.id}>
                    - {t("admin.map.saveErrors.arrow")}: {index + 1}
                  </SmallText>
                ))}
                {onSaveErrors.deletedMapObjects.length > 0 && (
                  <SmallText>
                    - {t("admin.map.saveErrors.deletedArrows")}:{" "}
                    {onSaveErrors.deletedMapObjects.length}
                  </SmallText>
                )}
              </DismissAbleOverlay>
            )}
            {isShowingCantAddSeat ? (
              <DismissAbleOverlay
                title={t("admin.map.add.noNameTitle")}
                onCloseCallback={() => setIsShowingCantAddSeat(false)}
              >
                <SmallText>{t("admin.map.add.noName")}</SmallText>
              </DismissAbleOverlay>
            ) : null}
            {isShowingCantDelete ? (
              <DismissAbleOverlay
                title={t("admin.map.delete.existingReservationsTitle")}
                onCloseCallback={() => setIsShowingCantDelete(false)}
              >
                <SmallText>
                  {t("admin.map.delete.existingReservations")}
                </SmallText>
              </DismissAbleOverlay>
            ) : null}
          </OverlayRightWrapper>
        </Wrapper>

        {(isAddingSeat || (isEditSeatFormOpened && selectedArrows.length === 0)) && (
          <Portal wrapperId="map-sidebar">
            <SeatForm
              mapId={mapId}
              onSubmit={updateSeat}
              onChange={(updatedSeat, callback) => {
                // update seat
                createSeat.current = updatedSeat;
                createCallback.current = callback;
              }}
              seat={selectedSeats[0]}
              seatTags={seatTags}
              getMSRooms={getMSRooms}
              userGroups={userGroups}
              isAdding={isAddingSeat}
              closeForm={() => {
                if (getSeatInfo) {
                  getSeatInfo(createSeat.current);
                }
                setIsAddingSeat(false);
                if (toggleSeatForm) {
                  toggleSeatForm(false);
                }
                setIsEditSeatFormOpened(false);
                // deselectAllItems();
              }}
              seatsAmount={getNextSeatNumber()}
              position={Position.fixed}
              top="253px"
              left={0}
              height="calc(100vh - 253px)"
              minHeight={0}
              overflow={Overflow.auto}
              onAddTag={addTag}
            />
          </Portal>
        )}
      </MapDetailWrapper>
    </MapWrapper>
  );
});

export default Map;
