module["exports"] = [
  "أحمر",
  "أحمر غامق",
  "أحمر فاتح",
  "أخضر",
  "أخضر غامق",
  "أخضر فاتح",
  "زيتوني",
  "فيروزي",
  "أزرق",
  "أزرق غامق",
  "أزرق فاتح",
  "تركواز",
  "أزرق سماوي",
  "أصفر",
  "ليموني",
  "أرجواني",
  "بنفسجي",
  "أرجواني فاتح",
  "أرجواني خفيف",
  "أبيض",
  "أسود",
  "برتقالي",
  "زهري",
  "بني",
  "كستنائي",
  "أسمر",
  "عاجي",
  "نيلي",
  "ذهبي",
  "فضي",
  "رمادي",
  "رصاصي"
];
