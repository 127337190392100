import React, {
  FC,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, RouterProps } from "../routing";

import { t } from "../i18n";

import Input from "../styles/Input";
import { SmallText } from "../styles/Text";

import Button from "../components/Button";
import Link from "../components/Link";
import Notification from "../components/Notification";
import {
  CenterContainer,
  LoginContainer,
  LoginFormCard,
  Logo,
  LoginTitle,
  Wrapper,
} from "./Login";

import { useApi } from "../hooks/useApi";
import { useClient } from "../hooks/useClient";
import { useAuth } from "../context/authContext";

import { isValidEmail } from "../utils";

import logo from "../assets/emblem.png";
import useQuery from "../hooks/useQuery";

const ResetPassword: FC<RouterProps> = () => {
  const [token, setToken] = useState<string | null>(null);
  const [selector, setSelector] = useState<string | null>(null);
  const query = useQuery();

  useEffect(() => {
    setToken(query.get("token"));
    setSelector(query.get("selector"));
  }, []);

  return (
    <>
      {(!token || !selector) && <Email />}
      {token && selector && <Password token={token} selector={selector} />}
    </>
  );
};

const Email = () => {
  const [email, setEmail] = useState<string>("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { login } = useAuth();
  const client = useClient();
  const history = useHistory();
  const { handleRequest, error, dismissError } = useApi();

  const emailInputChange = useCallback(
    (newValue) => {
      setEmail(newValue);
    },
    [setEmail]
  );

  const submitForm = useCallback(async () => {
    const response = await handleRequest(client.resetPassword({ email }));

    if (response) {
      setShowSuccessMessage(true);
    }
  }, [email, login]);

  const isValidForm = useCallback(() => isValidEmail(email), [email]);

  const handleSubmit = useCallback(() => {
    if (isValidForm()) {
      submitForm();
    }
  }, [isValidForm, submitForm]);

  return (
    <LoginContainer>
      <LoginFormCard>
        <Wrapper>
          <CenterContainer>
            <Logo source={logo} />
            <LoginTitle>{t("reset.title")}</LoginTitle>
            <SmallText>{t("reset.helper")}</SmallText>
          </CenterContainer>
          {Boolean(error) && (
            <Notification closeNotification={dismissError}>
              {t("general.error")}
            </Notification>
          )}
          {showSuccessMessage ? (
            <Notification
              type="success"
              closeNotification={() => setShowSuccessMessage(false)}
            >
              {t("reset.success")}
            </Notification>
          ) : null}
          <SmallText mediumWeight>{t("login.email")}</SmallText>
          <Input
            value={email}
            onChangeText={emailInputChange}
            placeholder={t("login.email")}
            onSubmitEditing={() => handleSubmit()}
            blurOnSubmit={false}
          />
          <Button
            hasMarginBottom
            disabled={!isValidForm()}
            onPress={submitForm}
          >
            {t("reset.mail")}
          </Button>
          <CenterContainer>
            <Link
              isActive
              onPress={() => {
                history.goBack();
              }}
            >
              {t("general.back")}
            </Link>
          </CenterContainer>
        </Wrapper>
      </LoginFormCard>
    </LoginContainer>
  );
};

interface PasswordProps {
  token: string;
  selector: string;
}

const Password: FunctionComponent<PasswordProps> = ({ token, selector }) => {
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const client = useClient();
  const history = useHistory();
  const { handleRequest, error, dismissError } = useApi();

  const passwordInputChange = useCallback(
    (newValue) => {
      setPassword(newValue);
    },
    [setPassword]
  );

  const passwordRepeatInputChange = useCallback(
    (newValue) => {
      setPasswordRepeat(newValue);
    },
    [setPasswordRepeat]
  );

  const submitForm = useCallback(async () => {
    const response = await handleRequest(
      client.updatePassword({
        password,
        resetToken: token,
        selector,
      })
    );
    if (response) {
      setShowSuccessMessage(true);
    }
  }, [password, token, selector, client, handleRequest]);

  const isValidForm = useCallback(
    () => password === passwordRepeat && password.length >= 6,
    [password, passwordRepeat]
  );

  const handleSubmit = useCallback(() => {
    if (isValidForm()) {
      submitForm();
    }
  }, [isValidForm, submitForm]);

  return (
    <LoginContainer>
      <LoginFormCard>
        <Wrapper>
          <CenterContainer>
            <Logo source={logo} />
            <LoginTitle>{t("reset.passwordTitle")}</LoginTitle>
            <SmallText>{t("reset.passwordHelper")}</SmallText>
          </CenterContainer>
          {Boolean(error) && (
            <Notification closeNotification={dismissError}>
              {t("general.error")}
            </Notification>
          )}
          {showSuccessMessage ? (
            <>
              <Notification
                type="success"
                closeNotification={() => setShowSuccessMessage(false)}
              >
                {t("reset.passwordSuccess")}
              </Notification>
              <CenterContainer>
                <Link
                  isActive
                  onPress={() => {
                    history.push("/login");
                  }}
                >
                  {t("reset.login")}
                </Link>
              </CenterContainer>
            </>
          ) : null}
          {!showSuccessMessage && (
            <>
              <SmallText mediumWeight>{t("reset.password")}</SmallText>
              <Input
                value={password}
                secureTextEntry
                onChangeText={passwordInputChange}
                placeholder={t("reset.password")}
              />
              <Input
                value={passwordRepeat}
                secureTextEntry
                onChangeText={passwordRepeatInputChange}
                placeholder={t("reset.passwordRepeat")}
              />
              <Button
                hasMarginBottom
                disabled={!isValidForm()}
                onPress={handleSubmit}
              >
                {t("reset.passwordSubmit")}
              </Button>
            </>
          )}
        </Wrapper>
      </LoginFormCard>
    </LoginContainer>
  );
};

export default ResetPassword;
