module["exports"] = [
  "back-uppen",
  "omzeilen",
  "hacken",
  "overschrijven",
  "samenpersen",
  "kopiëren",
  "navigeren",
  "indexeren",
  "aansluiten",
  "genereren",
  "kwantificeren",
  "berekenen",
  "samenstellen",
  "invoeren",
  "verzenden",
  "programmeren",
  "herstarten",
  "ontleden",
  "in stukjes snijden",
  "analyseren"

];
