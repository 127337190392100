module["exports"] = [
  "עברות",
"אביב",
"אביבי",
"אביגור",
"אבידור",
"אבידן",
"אביטל",
"אבינועם",
"אבינר",
"אבינרי",
"אביעד",
"אביעזר",
"אבירם",
"אבישי",
"אבישר",
"אביתר",
"אבן",
"אבן זהב",
"אבן חן",
"אבן צור",
"אבן שושן",
"אבנון",
"אבני",
"אבניאל",
"אבנר",
"אבנרי",
"אברהם",
"אברהמי",
"אברון",
"אבריאל",
"אגוז",
"אגוזי",
"אגמון",
"אגרון",
"אדוני",
"אדיב",
"אדירי",
"אדם",
"אדמון",
"אדמוני",
"אדר",
"אהרוני",
"אהרן",
"אוהד",
"און",
"אופיר",
"אופק",
"אור",
"אורון",
"אוריאל",
"אוריה",
"אוריון",
"אוריין",
"אורן",
"אזרחי",
"אחז",
"אחיטוב",
"אחרון",
"אייל",
"איילון",
"אילון",
"אילן",
"אילני",
"אילת",
"איש כסית",
"איששלום",
"איתם",
"איתן",
"אלדד",
"אלדד",
"אלדן",
"אלול",
"אלון",
"אלוני",
"אלוף",
"אלחנני",
"אליאב",
"אליהו",
"אליעז",
"אליעזר",
"אליצור",
"אליקים",
"אלישיב",
"אלמגור",
"אלמוג",
"אלעד",
"אלעזר",
"אלרום",
"אלרון",
"אמיר",
"אמית",
"אמיתי",
"אנקורי",
"אסא",
"אסיא",
"אסיף",
"אסף",
"אפעל",
"אפק",
"אפרת",
"אראל",
"ארבל",
"ארגוב",
"ארגמן",
"ארד",
"ארדון",
"ארוך",
"ארז",
"ארזי",
"אריאב",
"אריאל",
"אריאלי",
"ארידור",
"אריה",
"אריכא",
"ארם",
"ארמוני",
"ארנון",
"ארצי",
"אשבל",
"אשד",
"אשור",
"אשכול",
"אשכנזי",
"אשל",
"אשר",
"אשרי",
"אשרת",
"אתגר",
"אתר",
"אתרוג",
"ב׳׳ק",
"באב׳׳ד",
"באר",
"בארי",
"בבלי",
"בהט",
"בוצר",
"בורר",
"בחור",
"ביצור",
"בית הלחמי",
"בית יוסף",
"בית לחם",
"בכור",
"בן",
"בן אברהם",
"בן אליעזר",
"בן אמוץ",
"בן ארי",
"בן אריה",
"בן ארצי",
"בן אשר",
"בן ברוך",
"בן ברק",
"בן גל",
"בן גרא",
"בן דב",
"בן דוד",
"בן דור",
"בן דיין",
"בן זאב",
"בן זכאי",
"בן זקן",
"בן חור",
"בן חיים",
"בן חנן",
"בן חנניה",
"בן יהודה",
"בן יעקב",
"בן יצחק",
"בן ישי",
"בן ישראל",
"בן מלך",
"בן מנחם",
"בן נון",
"בן נחום",
"בן נר",
"בן נתן",
"בן עמי",
"בן ענת",
"בן פורת",
"בן צבי",
"בן צור",
"בן ציון",
"בן ראובן",
"בן שאול",
"בן שחר",
"בן שלום",
"בן שמחון",
"בן שמעון",
"בןגוריון",
"בןחורין",
"בןמאיר",
"בןסירה",
"בןתור",
"בנאי",
"משפחת בנאי",
"בנטוב",
"בנימין",
"בנימיני",
"בצלאל",
"בר",
"בר אור",
"בר גיורא",
"בר זוהר",
"בר יהודה",
"בר יעקב",
"בר נתן",
"בר שלום",
"בראון",
"בראילן",
"ברהלל",
"בריוסף",
"ברלב",
"ברעוז",
"בראל",
"ברוך",
"ברוש",
"ברושי",
"ברזילי",
"ברזל",
"ברטוב",
"ברטל",
"ברנע",
"ברעם",
"ברק",
"ברקאי",
"ברקין",
"ברקן",
"ברקת",
"בשן",
"גאון",
"גבאי",
"גביש",
"גבע",
"גבעול",
"גבעון",
"גבעתי",
"גבתון",
"גד",
"גדות",
"גדיש",
"גדליה",
"גדרון",
"גודר",
"גולן",
"גונן",
"גופר",
"גור אריה",
"גוריון",
"גורלי",
"גורן",
"גושן",
"גזית",
"גיא",
"גידור",
"גיל",
"גילון",
"גילת",
"גינוסר",
"גינת",
"גינתון",
"גל",
"גלבוע",
"גליל",
"גלילי",
"גלעד",
"גלעדי",
"גלרון",
"גמזו",
"גמליאל",
"גנור",
"גפן",
"גפני",
"גרא",
"גרינבלט",
"גרנות",
"גרשון",
"גת",
"דביר",
"דברת",
"דגן",
"דגני",
"דוד",
"דוידי",
"דולב",
"דור",
"דורי",
"דותן",
"דן",
"דניאלי",
"דקל",
"דר",
"דרומי",
"דרור",
"דרורי",
"דשא",
"הדס",
"הדר",
"הלוי",
"הללי",
"המאירי",
"הנגבי",
"העצני",
"הר נוי",
"הר שפי",
"הראבן",
"הראבן",
"הראובני",
"הראל",
"הרטוב",
"הרפז",
"הררי",
"הללי",
"המאירי",
"הנגבי",
"העצני",
"הר נוי",
"הר שפי",
"הראבן",
"הראבן",
"הראובני",
"הראל",
"הרטוב",
"הרפז",
"הררי",
"וילנאי",
"ורד",
"ורדי",
"ורדימון",
"זזאב",
"זאבי",
"זבולון",
"זהבי",
"זוהר",
"זורע",
"זיו",
"זיואב",
"זכאי",
"זכות",
"זכריה",
"זמורה",
"זמיר",
"זמר",
"זמרי",
"זעירא",
"זק",
"זק׳׳ש",
"זר",
"זר",
"זרובבל",
"זרטל",
"חדש",
"חובה",
"חורש",
"חזן",
"חזני",
"חזקיה",
"חי",
"חייט",
"חיים",
"חכם",
"חלד",
"חלמיש",
"חן",
"חנוך",
"חנוכה",
"חנני",
"חסון",
"חסיד",
"חפץ",
"חפר",
"חץ",
"חצור",
"חצרוני",
"חרובי",
"חרותי",
"חריף",
"חרל׳׳פ",
"חרמון",
"חרמוני",
"חת",
"טבת",
"טוביה",
"טלמור",
"טלשיר",
"יאיר",
"יבין",
"יגור",
"ידיד",
"ידין",
"ידעיה",
"יהב",
"יהודאי",
"יהודה",
"יהושע",
"יהלום",
"יובל",
"יוגב",
"יוטבת",
"יונה",
"יוסף",
"יושע",
"יחזקאל",
"יחיאל",
"יכין",
"ימיני",
"ינון",
"יניב",
"יעקבי",
"יער",
"יערי",
"יפה",
"יפרח",
"יפת",
"יצחק",
"יצחקי",
"יקיר",
"ירדני",
"ירוחם",
"ירום",
"ירון",
"ירושלמי",
"יריב",
"ירקוני",
"ישורון",
"ישי",
"ישר",
"ישראל",
"ישראלי",
"יששכר",
"כהן",
"משפחות כהן",
"משפחת כהןסקלי",
"כוכבי",
"כנען",
"כנעני",
"כנר",
"כספי",
"כפיר",
"כפרי",
"כרמון",
"כרמי",
"כרמל",
"כרמלי",
"לאור",
"לב",
"לב ארי",
"לבבי",
"לביא",
"לבנון",
"לבני",
"לבנת",
"להב",
"להבי",
"לוטם",
"לוטן",
"לוי (יהדות)",
"לוי (שם משפחה)",
"לוי נחום",
"לויתן",
"לחמן",
"ליאור",
"לידור",
"ליטאי",
"לימור",
"ליש",
"לשם",
"מאור",
"מאיר",
"מאירי",
"מגד",
"מגל",
"מואב",
"מוקד",
"מוקדי",
"מורג",
"מוריה",
"מורשת",
"מזא׳׳ה",
"מזור",
"מזל",
"מזר",
"מאיר מיבר",
"מיכה",
"מילוא",
"מישורי",
"מלכה",
"מלכי",
"מלמד",
"מנור",
"מנחם",
"מנשה",
"מעוז",
"מעיין",
"מעייני",
"מערבי",
"מרגליות",
"מרגלית",
"מרגנית",
"מרום",
"מרון",
"מרידור",
"משה",
"משולח",
"משיח",
"משעול",
"משעל",
"משען",
"משעני",
"מתניה",
"מתתיהו",
"נאור",
"נאמן",
"נבון",
"נגבי",
"נוב",
"נווה",
"נוימרק",
"נועם",
"נוריאל",
"נחום",
"נחושתן",
"נחשון",
"ניב",
"ניסני",
"ניצן",
"נמרי",
"נסים",
"נץ",
"נריה",
"נשר",
"נשרי",
"נתיב",
"נתן",
"נתנאל",
"נתניהו",
"סבוראי",
"סביר",
"סגל",
"סדן",
"סהר",
"סופר",
"סימן טוב",
"סיני",
"סכנין",
"סלע",
"סמדר",
"סנונית",
"סעד",
"סער",
"ספיר",
"סתוי",
"סתיו",
"עברון",
"עברי",
"עדיני",
"עדר",
"עובד",
"עובדיה",
"עובדיהו",
"עוגן",
"עוז",
"עוזר",
"עומר",
"עופר",
"עופרים",
"עזוז",
"עזריאל",
"עזריה",
"עזרתי",
"עידן",
"עילם",
"עינב",
"עמוס",
"עמיאל",
"עמיקם",
"עמיר",
"עמירן",
"עמית",
"עמרמי",
"ענבל",
"ענבר",
"עסיס",
"עפרת",
"עצמון",
"ערד",
"ערמון",
"ערן",
"עשת",
"פאר",
"פדהצור",
"פולני",
"פורת",
"פז",
"פלאי",
"פלד",
"פלס",
"פניני",
"פסח",
"פרג",
"פרוש",
"פרח",
"פרי",
"פריגת",
"פרימור",
"פרץ",
"פת",
"צבר",
"צדוק",
"צדקה",
"צדקיהו",
"צוק",
"צור",
"צורי",
"צוריאל",
"צורף",
"צידון",
"צמח",
"צפיר",
"צפריר",
"קדמון",
"קדמי",
"קדר",
"קדרון",
"קהת",
"קורח",
"קורן",
"קטן",
"קידר",
"קינן",
"קיסרי",
"קישון",
"קם",
"קמה",
"קמחי",
"קנר",
"קנת",
"קצב",
"קצין",
"קציר",
"קשת",
"קשתי",
"ראובני",
"רבי",
"רביב",
"רביד",
"רגב",
"רהב",
"רוגל",
"רווה",
"רווח",
"רוח",
"רום",
"רועה",
"רז",
"רזיאל",
"רחמים",
"רכס",
"רם",
"רמון",
"רמז",
"רמתי",
"רן",
"רענן",
"רשף",
"שאול",
"שאולי",
"שאלתיאל",
"שבח",
"שביב",
"שבת",
"שבתאי",
"שבתי",
"שגב",
"שגיא",
"שדה",
"שדר",
"שוב",
"שובל",
"שוהם",
"שוחט",
"שומרון",
"שוער",
"שופט",
"שור",
"שורק",
"שושן",
"שושני",
"שחור",
"שחורי",
"שחל",
"שחם",
"שחף",
"שחק",
"שחר",
"שילה",
"שילוח",
"שילון",
"שילוני",
"שיר",
"שיש",
"שלג",
"שלו",
"שלום",
"שלומי",
"שלח",
"שלם",
"שלמון",
"שם אור",
"שםטוב",
"שמואל",
"שמואלי",
"שמחון",
"שמחוני",
"שמחי",
"שמעון",
"שמרון",
"שמש (שם משפחה)",
"שמשון",
"שמשוני",
"שמשי",
"שנהר",
"שני",
"שנער",
"שער",
"שפיר",
"שפע",
"שץ",
"שקד",
"שקדי",
"שקלים",
"שרביט",
"שרון",
"שרוני",
"שריג",
"שריד",
"שריף",
"שריר",
"ששון",
"תאומים",
"תאני",
"תבור",
"תבורי",
"תגר",
"תדמור",
"תומר",
"תורן",
"תירוש",
"תל צור",
"תלם",
"תמוז",
"תמיר",
"תמרי",
"תקווה",
"תקוע",
"תרשיש",
"תשבי"
];
