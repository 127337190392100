import React, { FC, useEffect, useCallback, useState } from "react";
import { useHistory, useParams } from "../../../routing";

import styled from "styled-components/native";
import { t } from "../../../i18n";
import {
  ReportReasonProperties,
  InlineResponse400Errors,
} from "../../../../bookmydesk-api-sdk-typescript-axios";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Select, { SelectItemInterface } from "../../../components/Select";

import { MediumTitle } from "../../../styles/Title";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";

import { useClient } from "../../../hooks/useClient";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import { useAuth } from "../../../context/authContext";
import InputValidationErrors from "../../../components/InputValidationErrors";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const Update: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [reason, setReason] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [locations, setLocations] = useState<SelectItemInterface[]>([]);

  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();
  const { id } = useParams();

  const userCompany = userState?.companyIds[0];

  const isFormValid = useCallback(
    () => Boolean(reason) && Boolean(selectedLocation),
    [reason, selectedLocation]
  );

  useEffect(() => {
    if (userCompany) {
      const fetchData = async () => {
        const locationsResponse = await handleRequest(
          client.listLocations(userCompany)
        );
        if (!locationsResponse || !locationsResponse.data.result) {
          return;
        }

        const reportResponse = await handleRequest(client.getReportReason(id));
        if (!reportResponse || !reportResponse.data.result) {
          return;
        }

        const { items } = locationsResponse.data.result;
        const { reason, locationId } = reportResponse.data.result.reportReason;
        const locationItems = items.map((location) => ({
          label: location.name,
          value: location.id,
          isActive: location.id === locationId,
        }));
        setLocations(locationItems);
        setReason(reason);
        setSelectedLocation(locationId);
      };

      fetchData();
    }
  }, [userCompany, id, client, handleRequest]);

  const submitForm = useCallback(async () => {
    const report: ReportReasonProperties = {
      locationId: selectedLocation,
      reason,
    };
    try {
      const success = await handleRequest(
        client.updateReportReason(id, report)
      );

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [handleRequest, client, selectedLocation, reason, id, history]);

  const updateLocation = useCallback(
    (newValue) => {
      setSelectedLocation(newValue[0].value);
    },
    [setSelectedLocation]
  );

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.notification.form.editTitle")}
          </MediumTitle>
          <LabeledInput label={t("admin.notification.form.reason")} required>
            <Input
              onChangeText={setReason}
              value={reason}
              placeholder={t("admin.notification.form.reason") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.notification.form.location")} required>
            <Select
              isMultiSelect={false}
              required
              items={locations}
              onChange={updateLocation}
              placeholder={t("admin.notification.form.location") + " *"}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.notification.form.editSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Update;
