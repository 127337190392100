import React from "react";
import styled, { css } from "styled-components/native";

interface IconProps {
  color?: string;
}

const SvgWrapper = styled.View<IconProps>`
  width: 30px;

  ${({ color }) => {
    return css`
      color: ${color};
    `;
  }}
`;

export function GeneralIcon({ color }: IconProps) {
  return (
    <SvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="currentColor" fillRule="nonzero" transform="translate(-80 -226)">
            <g transform="translate(40 126)">
              <g transform="translate(40 100)">
                <path d="M19.475 8.375l-1.857-.368a7.77 7.77 0 00-.815-1.995l1.045-1.563a.652.652 0 00-.08-.824l-1.393-1.392a.652.652 0 00-.824-.081l-1.549 1.035a7.769 7.769 0 00-2.016-.84L11.625.525a.652.652 0 00-.64-.525h-1.97c-.311 0-.58.22-.64.525l-.361 1.822a7.769 7.769 0 00-2.016.84L4.449 2.152a.652.652 0 00-.824.08L2.233 3.625a.652.652 0 00-.081.824l1.045 1.563a7.771 7.771 0 00-.815 1.995l-1.857.368a.652.652 0 00-.525.64v1.97c0 .311.22.58.525.64l1.88.373c.184.685.458 1.337.82 1.949L2.151 15.55a.652.652 0 00.08.824l1.393 1.392c.22.22.565.254.824.081l1.619-1.082a7.775 7.775 0 001.928.795l.38 1.914c.06.305.328.525.64.525h1.969c.311 0 .58-.22.64-.525l.38-1.914a7.777 7.777 0 001.927-.795l1.619 1.082c.259.173.604.14.824-.08l1.393-1.393a.652.652 0 00.08-.824l-1.072-1.604a7.773 7.773 0 00.818-1.95l1.88-.372a.652.652 0 00.526-.64v-1.97c0-.311-.22-.58-.525-.64zM10 15.152a5.202 5.202 0 01-5.196-5.196A5.202 5.202 0 0110 4.76a5.202 5.202 0 015.196 5.196A5.202 5.202 0 0110 15.152z"></path>
                <path d="M12.095 8.321a.652.652 0 00-.922 0l-1.887 1.887-.459-.46a.652.652 0 00-.922.923l.92.92a.652.652 0 00.923 0l2.347-2.347a.652.652 0 000-.923z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgWrapper>
  );
}

export function ConnectionIcon({ color }: IconProps) {
  return (
    <SvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="currentColor" fillRule="nonzero" transform="translate(-80 -346)">
            <g transform="translate(40 126)">
              <g transform="translate(40 220)">
                <path d="M2.101 16.124c-.14-.14-.27-.286-.39-.439l-1.54 1.54a.586.586 0 000 .828l1.776 1.775c.23.23.6.23.829 0l1.538-1.538c-.317-.224-1.9-1.868-2.213-2.166zM6.362 8.313l1.775 1.775.948-.947a1.255 1.255 0 10-1.777-1.775l-.946.947zM12.634 10.916a1.257 1.257 0 00-1.776 0l-.946.947 1.775 1.775.947-.947a1.255 1.255 0 000-1.775zM5.09 8.698a.586.586 0 00-.83 0l-1.33 1.331a3.72 3.72 0 000 5.267l1.773 1.775a3.733 3.733 0 005.27 0l1.329-1.331a.586.586 0 000-.829L5.09 8.698zM19.828 1.947L18.053.172a.586.586 0 00-.829 0L15.686 1.71c.153.12.3.25.439.39.305.32 1.932 1.89 2.164 2.215l1.54-1.54a.586.586 0 000-.828zM17.07 9.97a3.72 3.72 0 000-5.266l-1.773-1.775a3.733 3.733 0 00-5.27 0L8.699 4.26a.586.586 0 000 .83l6.213 6.213c.229.229.6.229.828 0l1.33-1.331z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgWrapper>
  );
}

export function FinancialIcon({ color }: IconProps) {
  return (
    <SvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        viewBox="0 0 16 18"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="currentColor" fillRule="nonzero" transform="translate(-82 -467)">
            <g transform="translate(40 126)">
              <g transform="translate(42 341)">
                <path d="M1.06 3.524v13.949a.527.527 0 00.762.471l1.884-.941 1.903.942a.527.527 0 00.472-.002l1.862-.94 1.88.941a.527.527 0 00.471 0l1.893-.94 1.872.94a.527.527 0 00.764-.471V3.524H1.059zm8.8 1.79a.536.536 0 010 .745l-.38.381-2.713 2.713a.535.535 0 01-.746 0 .536.536 0 010-.746l.381-.38 2.713-2.713a.535.535 0 01.746 0zm.155 3.466c.013.285-.251.528-.527.528a.537.537 0 01-.528-.528c-.012-.285.252-.527.528-.527.295 0 .514.242.527.527zM6.394 5.16c.296 0 .515.24.527.527.013.284-.251.527-.527.527a.537.537 0 01-.527-.527c-.013-.285.251-.528.527-.528zm-1.63 5.782h6.354a.527.527 0 010 1.055H4.764a.527.527 0 010-1.055zm0 2.118h6.354a.527.527 0 010 1.055H4.764a.527.527 0 010-1.055z"></path>
                <path d="M15.882 1.942V.527A.527.527 0 0015.354 0H.527A.527.527 0 000 .527v1.415c0 .292.236.528.527.528h14.827a.527.527 0 00.528-.528z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgWrapper>
  );
}

export function CompanyIcon({ color }: IconProps) {
  return (
    <SvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="currentColor" fillRule="nonzero" transform="translate(-81 -527)">
            <g transform="translate(40 126)">
              <g transform="translate(41 401)">
                <circle cx="9" cy="11.25" r="1.5"></circle>
                <path d="M17.642 7.8L15.75 6.033V1.5A.75.75 0 0015 .75h-2.25a.75.75 0 00-.75.75v1.026L9.512.202a.75.75 0 00-1.024 0L.34 7.817a1.126 1.126 0 00.785 1.933H2.25v6.375c0 1.033.841 1.875 1.875 1.875h9.75a1.878 1.878 0 001.875-1.875V9.75h1.125c.62 0 1.125-.505 1.125-1.125 0-.302-.12-.589-.358-.826zm-4.267 3.803C11.957 13.36 10.485 14.25 9 14.25c-1.484 0-2.957-.89-4.375-2.647a.56.56 0 010-.706c2.836-3.513 5.914-3.513 8.75 0a.56.56 0 010 .706z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgWrapper>
  );
}

export function ModulesIcon({ color }: IconProps) {
  return (
    <SvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="currentColor" fillRule="nonzero" transform="translate(-80 -286)">
            <g transform="translate(40 126)">
              <path
                d="M57.93 167.233c-.665 0-.997.25-1.329.498-.166.083-.415.083-.498-.083-.083-.083-.083-.166-.083-.332l.332-3.818c0-.083 0-.249-.083-.332-.083-.083-.166-.166-.332-.166h-3.735a.872.872 0 00-.747.415.754.754 0 000 .83c.166.25.249.415.249.83 0 .664-.747 1.245-1.66 1.245-.913 0-1.66-.58-1.66-1.245 0-.332.083-.58.249-.83a.754.754 0 000-.83c-.166-.249-.415-.415-.747-.415H44.15c-.083 0-.249.083-.332.166-.083.083-.083.166-.083.332l.332 3.818c0 .166-.083.25-.083.332-.166.166-.332.25-.58.083-.333-.249-.665-.415-1.329-.415-1.162 0-2.075 1.08-2.075 2.49 0 1.412.913 2.49 2.075 2.49.664 0 .996-.248 1.328-.497.166-.083.415-.083.498.083.083.083.083.166.083.332l-.332 3.818c0 .083 0 .249.083.332.083.083.166.166.332.166h3.736a.872.872 0 00.747-.415.754.754 0 000-.83c-.166-.25-.25-.415-.25-.83 0-.664.748-1.245 1.66-1.245.914 0 1.66.58 1.66 1.245 0 .332-.082.58-.248.83a.754.754 0 000 .83c.166.249.415.415.747.415h3.735c.083 0 .25-.083.332-.166.083-.083.083-.166.083-.332l-.332-3.818c0-.166.083-.25.083-.332.166-.166.332-.25.581-.083.332.249.664.415 1.328.415 1.163 0 2.076-1.08 2.076-2.49 0-1.412-.913-2.49-2.076-2.49z"
                transform="rotate(45 50.002 169.765)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </SvgWrapper>
  );
}

export function TimeslotIcon({ color }: IconProps) {
  return (
    <SvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="currentColor" fillRule="nonzero" transform="translate(-82 -408)">
            <g transform="translate(40 126)">
              <g transform="translate(42 282)">
                <path d="M8 0a8 8 0 108 8 8.01 8.01 0 00-8-8zm2.696 10.696a.727.727 0 01-1.028 0L7.486 8.514A.727.727 0 017.273 8V3.636a.727.727 0 111.454 0V7.7l1.969 1.969a.727.727 0 010 1.028z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgWrapper>
  );
}

export function DownChevron({ color }: IconProps) {
  return (
    <SvgWrapper color={color}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 386.257 386.257"
        viewBox="0 0 386.257 386.257"
        height="10px"
        width="10px"
        version="1.1"
      >
        <path d="M0 96.879l193.129 192.5 193.128-192.5z"></path>
      </svg>
    </SvgWrapper>
  );
}
