import {
  MeUser,
  MeAdmin,
  Language,
} from "../../bookmydesk-api-sdk-typescript-axios";

export interface User {
  id: string | number;
  email: string;
  firstName: string;
  infix?: string;
  lastName: string;
  roles: string[];
  companyIds: string[];
  billingType?: string;
  currency: string;
  language: Language | null;
}

export const mapUser = (user: MeAdmin | MeUser): User => {
  if ((user as MeAdmin).roles) {
    return mapMeAdmin(user as MeAdmin);
  } else {
    return mapMeUser(user as MeUser);
  }
};

const mapMeUser = (user: MeUser): User => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.first_name,
    infix: user.infix,
    lastName: user.last_name,
    roles: [user.role],
    //TODO need propper companyId
    companyIds: ["0", "1", "2"],
    language: user.language,
  };
};

const mapMeAdmin = (user: MeAdmin): User => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    roles: user.roles,
    companyIds: user.companyIds,
    billingType: user.billingType,
    currency: user.currency,
    language: user.language,
  };
};
