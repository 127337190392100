import React, { FC, useRef, useCallback, useState } from "react";
import styled, { css } from "styled-components/native";
import {
  font,
  text,
  color,
  spacing,
  boxShadow,
  borderRadius,
  chevron,
  borderWidth,
} from "../../styles/theme";
import { TouchableOpacity } from "react-native";
import Text from "../../styles/Text";
import chevronDownIcon from "../../assets/icons/chevron_down.svg";
import chevronDownIconSecondary from "../../assets/icons/chevron_down_secondary.svg";
import { useFocus, useHover } from "react-native-web-hooks";

const DropdownBox = styled.View`
  top: calc(100% + ${spacing.tiny});
  right: 0;
  padding: ${spacing.small};
  background-color: ${color.white};
  ${boxShadow.extreme};
  border-radius: ${borderRadius.medium};
  position: absolute;
`;

const DropdownContainer = styled.View`
  position: relative;
`;

const BottomSpacer = styled.View`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: calc(${spacing.medium} + ${spacing.medium});
  top: 100%;
`;

interface DropdownTextStyleProps {
  border?: boolean;
  isActive?: boolean;
}

const DropdownText = styled(Text)<DropdownTextStyleProps>`
  font-family: ${font.defaultMedium};
  font-size: ${text.small.size};
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ isActive }) => (isActive ? color.secondary : color.darker)}
    ${({ border }) =>
      border &&
      css`
        border: ${borderWidth.small} solid ${color.dark};
        border-radius: ${borderRadius.full};
        padding: ${spacing.extraSmall} ${spacing.medium};
      `};
`;

interface IconProps {
  isOpen?: boolean;
}

export const Icon = styled.Image<IconProps>`
  width: ${chevron.width};
  height: ${chevron.height};
  margin-left: ${spacing.small};

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotateX(180deg);
    `};
`;

export interface DropdownProps {
  text: string;
  border?: boolean;
  isOpeningOnHover?: boolean;
  isActive?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: any) => void;
  onPress?: () => void;
}

const Dropdown: FC<DropdownProps> = ({
  children,
  text,
  border,
  isOpeningOnHover,
  isActive,
  setIsOpen: setIsOpenOverride,
  isOpen: isOpenOverride,
  onPress,
}) => {
  const element = useRef<any>(null);
  const [isOpenDefault, setIsOpenDefault] = useState(false);
  const isHovering = useHover(element);
  const isFocussing = useFocus(element);
  const isOpenByHover = (isHovering || isFocussing) && isOpeningOnHover;
  const setIsOpen = setIsOpenOverride ? setIsOpenOverride : setIsOpenDefault;
  const isOpen = isOpenOverride ? isOpenOverride : isOpenDefault;

  const toggleDropdown = useCallback(
    () => setIsOpen((oldValue) => !oldValue),
    [setIsOpen]
  );
  const onPressFunction = isOpeningOnHover ? () => null : toggleDropdown;

  return (
    <DropdownContainer ref={element}>
      <TouchableOpacity onPress={onPress || onPressFunction}>
        <DropdownText isActive={isActive} border={border}>
          {text}
          <Icon
            isOpen={isOpen || isOpenByHover}
            source={{
              uri: isActive
                ? chevronDownIcon.toString()
                : chevronDownIconSecondary.toString(),
            }}
          />
          {isOpenByHover ? <BottomSpacer /> : null}
        </DropdownText>
      </TouchableOpacity>
      {isOpen || isOpenByHover ? <DropdownBox style={{ zIndex: 101, }}>{children}</DropdownBox> : null}
    </DropdownContainer>
  );
};

export default Dropdown;
