import React, { FC } from "react";
import styled from "styled-components/native";
import Card from "../../styles/Card/card";
import { boxShadow, font, spacing, text } from "../../styles/theme";
import Text from "../../styles/Text/text";
import { TouchableOpacity } from "react-native";

const overlayMaxWidth = "420px";
const overlayMinWidth = "300px";

const DismissAbleOverlayWrapper = styled(Card)`
  max-width: ${overlayMaxWidth};
  min-width: ${overlayMinWidth};
  margin-bottom: ${spacing.medium};

  ${boxShadow.medium};
`;

const CloseDismissAbleOverlayText = styled(Text)`
  font-family: ${font.defaultMedium};
  font-size: ${text.large.size};
  margin-bottom: calc(${spacing.small} + ${spacing.extraSmall});
`;

const OverlayTitle = styled(Text)`
  font-size: ${text.medium.size};
  font-family: ${font.defaultMedium};
  padding-right: ${spacing.medium};
`;

const HeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface DismissAbleOverlayProps {
  onCloseCallback: () => void;
  title: string;
  backgroundColor?: string;
}

const DismissAbleOverlay: FC<DismissAbleOverlayProps> = ({
  children,
  onCloseCallback,
  title,
  backgroundColor,
}) => (
  <DismissAbleOverlayWrapper backgroundColor={backgroundColor}>
    <HeaderWrapper>
      <OverlayTitle>{title}</OverlayTitle>
      <TouchableOpacity onPress={onCloseCallback}>
        <CloseDismissAbleOverlayText>⨯</CloseDismissAbleOverlayText>
      </TouchableOpacity>
    </HeaderWrapper>
    {children}
  </DismissAbleOverlayWrapper>
);

export default DismissAbleOverlay;
