import React, { FC, forwardRef, useRef } from "react";
import { ViewProps } from "react-native";
import { useHover } from "react-native-web-hooks";

import styled, { css } from "styled-components/native";
import pureStyled from "styled-components";

import { font, spacing, text, color, flex, display } from "../../styles/theme";
import Text from "../../styles/Text";
import { CaretDownIcon, CaretUpIcon, CellHandleIcon } from "../Icons";

const defaultGrow = 1;

export interface HeaderItem {
  headerLabel?: string;
  headerContent?: JSX.Element;
  isSortable: boolean;
  sortField?: string;
  isActive?: boolean;
  isAscending?: boolean;
}

export const createHeaderItem = (
  headerLabel: string,
  isSortable: boolean,
  sortField?: string,
  isActive?: boolean,
  isAscending?: boolean
): HeaderItem => {
  return {
    headerLabel,
    isSortable,
    sortField,
    isActive,
    isAscending,
  };
};

interface TableHeaderItemWrapperProps extends ViewProps {
  columnSize?: number;
  isActive?: boolean;
}

//border-right-color: ${color.secondary} !important;

const TableHeaderItemWrapper = pureStyled.div<TableHeaderItemWrapperProps>`
  ${({ columnSize, isActive }) =>
    css`
      flex: ${columnSize || defaultGrow} 1 0;
      ${isActive ? `
      background-color: ${color.greyLight} !important;
      ` : ''}
      ${(columnSize && +columnSize < 1) ? 'border-right-color: transparent !important;' : ''}
    `};
  top: 0 !important;
  display: flex;
  align-items: center;
  margin: 0;
  background-color: ${color.greyLightest};
  border-right: 1px solid ${color.greyLight};
`;

const TableInnerWrapper = styled.View<{ noMargin?: boolean; position?: string; }>`
  ${({ noMargin }) =>
    noMargin
      ? css`
        padding: ${spacing.small} 0;
      `
      : css`
        padding: ${spacing.small};
  `};
  ${({ position }) =>
    css`
      justify-content: ${position || 'flex-start'};
  `};

  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

export const TableCellHandler = styled.TouchableOpacity`
  width: 100%;
  display: ${display.flex};
  flex-direction: ${flex.direction.row};
  justify-content: ${flex.justifyContent.spaceBetween};
  align-items: center;
`;

const TableHeaderText = styled(Text)`
  padding-right: 2px;
  font-family: ${font.defaultMedium};
  font-size: ${text.small.size};
  line-height: ${text.extraSmall.lineHeight};
  display: flex;
  align-items: center;
`;

const SortWrapper = styled.View`
  height: 14px;
  display: ${display.flex};
  flex-direction: ${flex.direction.column};
  justify-content: ${flex.justifyContent.spaceBetween};
`;

interface TableHeaderItemProps {
  headerItem: HeaderItem;
  columnSize?: number;
  noMargin?: boolean;
  noDrag?: boolean;
  position?: string;
  sortList?: (sortField: string) => void;
}

const TableHeaderItem: FC<TableHeaderItemProps> = forwardRef(({
  headerItem,
  columnSize,
  sortList,
  noMargin,
  noDrag,
  position,
  ...props
}, ref) => {
  const {
    headerLabel,
    headerContent,
    isSortable,
    isActive,
    isAscending,
    sortField,
  } = headerItem;
  const element = useRef(null);
  const isHovering = useHover(element);

  return (
    <TableHeaderItemWrapper
      ref={ref as React.RefObject<any>}
      columnSize={columnSize}
      isActive={isActive}
      {...props}
    >
      <TableInnerWrapper ref={element} noMargin={noMargin} position={position}>
        {!!headerLabel && (
          <TableCellHandler
            onPress={() => {
              if (sortList) sortList(sortField!);
            }}
            disabled={!isSortable}
          >
            <TableHeaderText>
              {isHovering && !noDrag && <CellHandleIcon style={{ marginRight: 5 }} />}
              {!isHovering && !noDrag && <CellHandleIcon style={{ width: 0, paddingLeft: 10 }} /> }
              {headerLabel}
            </TableHeaderText>
            {isSortable && (
              <SortWrapper>
                <CaretUpIcon color={(isAscending && isActive) ? color.secondary : color.greyChateau} />
                <CaretDownIcon color={(!isAscending && isActive) ? color.secondary : color.greyChateau} />
              </SortWrapper>
            )}
          </TableCellHandler>
        )}
        {!!headerContent && headerContent}
      </TableInnerWrapper>
    </TableHeaderItemWrapper>
  );
});

export default TableHeaderItem;
