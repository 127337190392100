module["exports"] = [
  "резервное копирование",
  "обход",
  "архивирование",
  "взлом",
  "шифрование",
  "переопределение",
  "сжатие",
  "скачивание",
  "копирование",
  "навигация",
  "индексирование",
  "соединение",
  "генерация",
  "определение количества",
  "вычисление",
  "синтез",
  "передача",
  "программирование",
  "разбор"
];
