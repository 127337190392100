import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Map, { SeatOnMap } from "../../../components/Map";
import SelectItem from "../../../components/Select";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import CreateSeat from "../SeatTagOverview/SeatTagForm";
import {
  borderRadius,
  boxShadow,
  color,
  cursor,
  display,
  flex,
  font,
  margin,
  opacity,
  Overflow,
  padding,
  Position,
  spacing,
  text,
  TextAlign,
  whiteSpace,
} from "../../../styles/theme";
import { useClient } from "../../../hooks/useClient";
import { ThenArg } from "../../../types";
import { useApi } from "../../../hooks/useApi";
import { useHistory, useParams } from "../../../routing";
import LoadOverlay from "../../../components/LoadOverlay/loadOverlay";
import { useAuth } from "../../../context/authContext";
import Notification from "../../../components/Notification";
import { useHover } from "react-native-web-hooks";
import {
  MapObject,
  SeatTag,
  SeatWithRelations,
  UserGroup,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import workspacePng from "../../../components/Map/mapIcons/workspace.png";
import workspaceRedPng from "../../../components/Map/mapIcons/workspaceRed.png";
import workspaceSelectedPng from "../../../components/Map/mapIcons/workspaceSelected.png";
import workspaceInactivePng from "../../../components/Map/mapIcons/workspaceInactive.png";
import lunchPng from "../../../components/Map/mapIcons/lunch.png";
import lunchRedPng from "../../../components/Map/mapIcons/lunchRed.png";
import lunchSelectedPng from "../../../components/Map/mapIcons/lunchSelected.png";
import lunchInactivePng from "../../../components/Map/mapIcons/lunchInactive.png";
import meetingRoomPng from "../../../components/Map/mapIcons/meetingRoom.png";
import meetingRoomRedPng from "../../../components/Map/mapIcons/meetingRoomRed.png";
import meetingRoomSelectedPng from "../../../components/Map/mapIcons/meetingRoomSelected.png";
import meetingRoomInactivePng from "../../../components/Map/mapIcons/meetingRoomInactive.png";
import arrow from "../../../components/Map/arrow.png";
import { Asset, useAssets } from "expo-asset";
import { useReactToPrint } from "react-to-print";
import { StyleSheet, TouchableOpacityProps, View } from "react-native";
import { Prompt } from "react-router";
import axios from "axios";
import { isEqual, orderBy, pick } from "lodash";

import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useSort } from "../../../hooks/useSort";
import Table, { TableContent } from "../../../components/Table";
import { ExtraSmallText } from "../../../styles/Text";
import Text from "../../../styles/Text/text";
import Paginator from "../../../components/Paginator";
import Toggle from "../../../components/Toggle";
import { t } from "../../../i18n";
import SeatForm from "../../../components/Map/SeatForm";
import styled from "styled-components/native";
import FileUpload from "../../../components/FileUpload";
import { DocumentResult } from "expo-document-picker/build/types";
import Dialog from "../../../components/Dialog";
import { DownChevron } from "../settingsIcons";
import Portal from "../../../components/Portal";

const pixelDensity = window.devicePixelRatio;
const DropdownWidth = "200px";
const DropdownHeaderWidth = "350px";
const DropDownListWidth = "300px";
const DropDownListHeght = "auto";

const DropDownContainer = styled.View`
  width: ${DropdownWidth};
  margin: 0 auto;
  position: ${Position.relative};
`;
const DropDownHeader = styled.View`
  margin-bottom: 3px;
  padding: ${padding.small};
  font-size: ${text.big.size};
  white-space: ${whiteSpace.nowrap};
  font-family: ${font.defaultSemiBold};
  display: ${display.flex};
  flex-direction: ${flex.direction.row};
  align-items: ${flex.justifyContent.center};
  width: ${DropdownHeaderWidth};
  margin-left: -${spacing.medium};
  margin-top: -${margin.small};
  cursor: ${cursor.pointer};
`;

const DropDownHeaderText = styled.Text`
  font-size: 24px;
  white-space: ${whiteSpace.nowrap};
  font-family: ${font.defaultSemiBold};
  cursor: ${cursor.pointer};
`;
const DropDownListContainer = styled.View``;
const DropDownList = styled.View`
  width: ${DropDownListWidth};
  height: ${DropDownListHeght};
  padding: ${margin.medium};
  overflow: ${Overflow.auto};
  cursor: ${cursor.pointer};
  top: -${spacing.medium};
  margin-left: -${spacing.medium};
  padding: ${spacing.medium};
  background-color: ${color.white};
  ${boxShadow.small};
  border-radius: ${borderRadius.medium};

  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
const ListItem = styled.View`
  list-style: none;
  margin-bottom: 4px;
  font-size: ${text.medium.size};
  color: ${color.secondary};
`;

function DropdownChildText({
  children,
  isActive,
}: {
  children: string;
  isActive: boolean;
}) {
  const element = useRef<any>(null);
  const isHovering = useHover(element);
  return (
    <Text
      ref={element}
      style={{
        color: isHovering || isActive ? color.secondary : color.black,
        textDecorationLine: isHovering ? "underline" : "none",
      }}
    >
      {children}
    </Text>
  );
}

const SvgWrapper = styled.View`
  margin-left: ${margin.small};
`;

interface SelectItem {
  label: string;
  value: string;
  isActive: boolean;
}

interface CustomSelectProps {
  items: SelectItem[];
  onChange: (value: SelectItem) => void;
}

type Tags = Array<string>;

function TagComponent({ tags }: { tags: Tags }) {
  const restSize = tags.length - 3;

  return (
    <>
      {tags.length > 3 ? (
        <Text style={{ fontSize: text.extraSmall.size }}>
          {tags.slice(0, 3).join(", ")}{" "}
          <Text color={color.secondary}>+{restSize}</Text>
        </Text>
      ) : (
        <Text style={{ fontSize: text.extraSmall.size }}>
          {tags.join(", ")}
        </Text>
      )}
    </>
  );
}

function CustomSelect({ items, onChange }: CustomSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );
  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (item: SelectItem) => () => {
    onChange(item);
    setSelectedOption(item.label);
    setIsOpen(false);
  };

  useEffect(() => {
    const findActive = items.find((item) => item.isActive);
    setSelectedOption(findActive?.label);
  }, []);
  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling}>
        <DropDownHeaderText>{selectedOption || ""}</DropDownHeaderText>
        <SvgWrapper>
          <DownChevron />
        </SvgWrapper>
      </DropDownHeader>

      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {items.map((item) => (
              <ListItem onClick={onOptionClicked(item)} key={Math.random()}>
                <DropdownChildText isActive={selectedOption === item.label}>
                  {item.label}
                </DropdownChildText>
                {/* <Text>{item.label}</Text> */}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
}

const mapIconSize = 20 * pixelDensity;
const mapIconOffset = mapIconSize / 2;
// const arrowIconSize = 40 * pixelDensity;
// const arrowIconOffset = arrowIconSize / 2;
// const webGlImageLoadErrorCode = 1281;
// const webGlImageUndefinedErrorCode = 1282;

interface OnSaveErrors {
  hasErrors: boolean;
  updatedSeats: SeatWithRelations[];
}

interface ButtonProps extends TouchableOpacityProps {
  bgColor?: string;
  onPress?: () => void;
  title?: string;
}

const ButtonContainer = styled.TouchableOpacity<ButtonProps>`
  background-color: ${(props) => props.bgColor};
  margin: ${spacing.extraSmall};
  padding: ${spacing.tiny};
  width: ${spacing.extraLarge};
  text-align: ${TextAlign.center};
  color: ${color.black};
  border-radius: ${borderRadius.full};
  cursor: ${cursor.pointer};
`;
const ButtonText = styled.Text`
  font-size: ${text.medium.size};
  text-align: ${TextAlign.center};
`;
const SwitchButton: FC<ButtonProps> = ({ onPress, bgColor, title }) => (
  <ButtonContainer onPress={onPress} bgColor={bgColor}>
    <ButtonText>{title}</ButtonText>
  </ButtonContainer>
);

const styles = StyleSheet.create({
  switchButon: {
    backgroundColor: color.red,
    margin: spacing.extraSmall,
    paddingTop: spacing.extraSmall,
    textAlign: TextAlign.center,
    color: color.black,
    borderRadius: parseInt(borderRadius.large),
    cursor: cursor.pointer,
    width: "80px",
  },
  switchParent: {
    backgroundColor: color.greyLightest,
    marginLeft: spacing.small,
    flexDirection: flex.direction.row,
    borderRadius: parseInt(borderRadius.full),
  },
  switchTitle: {
    marginTop: spacing.small,
    fontFamily: font.defaultMedium,
    fontSize: parseInt(text.small.size),
  },
  alternativeLayoutButtonContainer: {
    margin: parseInt(spacing.medium),
    flexDirection: flex.direction.row,
    justifyContent: flex.justifyContent.spaceBetween,
  },
  mapContainerView: {
    backgroundColor: color.white,
    position: Position.sticky,
    zIndex: 199,
    flexDirection: flex.direction.row,
    justifyContent: flex.justifyContent.spaceBetween,
    alignItems: TextAlign.center,
    top: 0,
  },
  mapContainerSubView: {
    backgroundColor: color.white,
    position: Position.sticky,
    zIndex: 199,
    flexDirection: flex.direction.row,
    justifyContent: flex.justifyContent.spaceBetween,
    alignItems: TextAlign.center,
    top: 0,
  },
});

const columnSizes = [1, 1, 2, 1, 1, 1];
const fieldsObervable = [
  "id",
  "name",
  "isActive",
  "seatTags",
  "mapId",
  "seatType",
  "notFutureReservable",
];

const sortMemory: Record<string, string> = {};

const MapDetail = () => {
  const client = useClient();
  const { handleRequest, isLoading } = useApi();
  const { id } = useParams();

  const { userState } = useAuth();
  const userCompany = userState?.companyIds[0];

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [skipParam, setSkipParam] = useState<number>(0);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState<SeatOnMap[]>([]);
  const [activeRowId, setActiveRowId] = useState<string>("");
  const [tempSeatData, setTempSeatData] = useState<SeatOnMap>({});
  const [saving, setSaving] = useState(false);

  const getTableHeaders = (): HeaderItem[] => [
    createHeaderItem(
      t("admin.map.overview.nameMap"),
      true,
      "name",
      true,
      false
    ),
    createHeaderItem(
      t("admin.map.overview.seatType"),
      true,
      "seatType",
      true,
      true
    ),

    createHeaderItem(
      t("admin.map.overview.tags"),
      true,
      "seatTags",
      true,
      true
    ),

    createHeaderItem(t("admin.map.overview.group"), true, "coordY", true),
    createHeaderItem(
      t("admin.map.overview.isActive"),
      true,
      "isActive",
      true,
      true
    ),
    createHeaderItem(
      t("admin.map.overview.hotdesk"),
      true,
      "notFutureReservable",
      true,
      true
    ),
    createHeaderItem(
      t("admin.map.overview.barcode"),
      true,
      "",
      true,
      true
    ),
  ];

  const tableHeaders = getTableHeaders();

  const history = useHistory();
  const [showSeatModal, setShowSeatModal] = useState(false);
  const [countSeatTagUpdate, setCountSeatTagUpdate] = useState(0);

  const [tableData, setTableData] = useState<Array<TableContent>>([]);

  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listAdminUsers>[0]>(tableHeaders);

  type MapItem = ThenArg<
    ReturnType<typeof client.getMap>
  >["data"]["result"]["map"];

  const [map, setMap] = useState<MapItem | undefined>(undefined);
  const [mapImage, setMapImage] = useState<Asset | undefined>(undefined);
  const [mapObjects, setMapObjects] = useState<MapObject[] | undefined>(
    undefined
  );
  const [seats, setSeats] = useState<SeatWithRelations[] | undefined>(
    undefined
  );

  const [selectKey, setSelectKey] = useState<number>(0);

  //initial seats data,useful for check if object realy change
  const [initialSeats, setInitialSeats] = useState<
    SeatWithRelations[] | undefined
  >(undefined);

  const [seatTags, setSeatTags] = useState<SeatTag[] | undefined>(undefined);
  const [userGroups, setUserGroups] = useState<UserGroup[] | undefined>(
    undefined
  );
  const [barcodes, setBarcodes] = useState<UserGroup[] | undefined>(
    undefined
  );

  const [seatHistoryIds, setSeatHistoryIds] = useState<string[]>([]);

  const [triggerOnSaveAndClose, setTriggerOnSaveAndClose] = useState<
    boolean | null
  >(null);

  const [triggerOnSave, setTriggerOnSave] = useState<boolean | null>(null);

  const [triggerUpload, setTriggerOnUpload] = useState<boolean | null>(null);
  const [currentImage, setCurrentImage] = useState<DocumentResult | null>(null);

  const [currentSeatId, setCurrentSeatId] = useState<string>("");

  const updateSeatHistoryIdsCallback = useCallback(
    (id: string) => {
      let ids = seatHistoryIds;
      ids = ids.filter((id) => id);
      if (!ids.includes(id)) {
        setSeatHistoryIds([...ids, id]);
      }
    },
    [activeRowId]
  );

  useEffect(() => {
    updateSeatHistoryIdsCallback(activeRowId);
  }, [activeRowId]);

  const updateSeat = useCallback(
    (key, value) => {
      if (!seats) return;
      let listOfSeats = [...seats];
      // get old object
      let oldObject: SeatWithRelations | undefined = initialSeats?.find(
        (seat) => seat.id === currentSeatId
      );

      listOfSeats = listOfSeats.map((seat) => {
        if (currentSeatId === seat.id) {
          return {
            ...seat,
            [key]: value,
          };
        }
        return seat;
      });

      let newObject: SeatWithRelations | undefined = listOfSeats?.find(
        (seat) => seat.id === currentSeatId
      );

      newObject = Object.assign({}, newObject);

      newObject.seatTags = newObject.seatTags.map(
        ({ id, label }: { id: string; label: string }) => {
          return {
            id,
            label,
          };
        }
      );

      oldObject = pick(oldObject, fieldsObervable);
      newObject = pick(newObject, fieldsObervable);

      if (!isEqual(oldObject, newObject)) {
        updateSeatHistoryIdsCallback(currentSeatId);
      }

      setSeats([...listOfSeats]);
    },
    [seats, currentSeatId, activeRowId]
  );

  const refreshSeatTableWithUpdatedMapData = useCallback(
    (data: SeatWithRelations) => {
      if (!seats) return;
      const seatId = data.id;

      let listOfSeats = [...seats];

      let oldObject: SeatWithRelations | undefined = initialSeats?.find(
        (seat) => seat.id === seatId
      );

      oldObject = pick(oldObject, fieldsObervable);

      const newObject = pick(data, fieldsObervable);

      newObject.seatTags = newObject.seatTags.map(
        ({ id, label }: { id: string; label: string }) => {
          return {
            id,
            label,
          };
        }
      );

      if (!isEqual(oldObject, newObject)) {
        updateSeatHistoryIdsCallback(seatId);

        listOfSeats = listOfSeats.map((seat) => {
          if (seat.id === data.id) {
            return {
              ...seat,
              ...data,
            };
          }
          return seat;
        });
        setSeats([...listOfSeats]);
      }
    },
    [seats, initialSeats]
  );

  const onSaveAndClose = async () => {
    await onSave();
    history.push(`/admin/map-overview?success=map-saved`);
  };

  const onSave = async () => {
    setSaving(true);
    const promises: Promise<any>[] = [];
    if (!seats) return;

    let getUpdatedSeats = seats?.filter((seat) =>
      seatHistoryIds.includes(seat.id)
    );

    //reformatted data for match api requirement
    getUpdatedSeats = getUpdatedSeats?.map((seat) => {
      seat.seatTags = seat.seatTags
        .map((tag: { value: string }) => {
          return {
            id: tag?.value,
          };
        })
        .filter((tag) => tag?.id);
      return seat;
    });

    const errors: OnSaveErrors = {
      hasErrors: false,
      updatedSeats: [],
    };

    getUpdatedSeats?.forEach((seat) => {
      promises.push(
        client.updateSeat(seat.id, seat).catch((error) => {
          errors.hasErrors = true;
          errors.updatedSeats.push(seat);
          console.error(error);
        })
      );
    });

    try {
      await Promise.all(promises);
      if (errors.hasErrors) {
        setSeats(errors.updatedSeats);
        console.log(errors.updatedSeats, "errors.updatedSeats");
      } else {
        setShowSavedMessage(true);
        setSeatHistoryIds([]);
      }
    } catch (e) {
      console.error(e);
    }
    setSaving(false);

    console.log("saving....");
  };

  // define fonction here for call inside maps
  const [mapList, setMapList] = useState<MapItem[]>([]);
  const [nextSeat, setNextSeat] = useState();
  const [mapStatus, setMapStatus] = useState(false);
  const [seatMapOnMapIsOpen, setSeatMapOnMapIsOpen] = useState(false);
  const [currentTab, setTab] = useState("map");

  const fetchMap = useCallback(
    async (id) => {
      return await handleRequest(client.getMap(id));
    },
    [handleRequest, client]
  );

  const checkIfUserReallychangeFields = useCallback(() => {
    let seatHasChanged = false;
    if (!seats) return;

    let newListOfSeats = [...seats];

    //get list of succeptible change seat
    newListOfSeats = newListOfSeats?.filter((seat) =>
      seatHistoryIds.includes(seat.id)
    );

    // compare succeptible seat change with initial seats
    newListOfSeats?.forEach((seat) => {
      const initialSeatValue = initialSeats?.find(
        (initialSeat) => initialSeat.id === seat.id
      );
      //compare initial seat object with current seat object
      if (!isEqual(initialSeatValue, seat)) {
        seatHasChanged = true;
      }
    });

    return seatHasChanged;
  }, [seatHistoryIds, seats, initialSeats]);

  const setHotDeskVisibility = useCallback(async (newValue, data) => {
    await handleRequest(
      client.updateSeat(data.id, {
        ...data,
        notFutureReservable: newValue,
      }),
      false
    );
    await initialize();
  }, []);

  const fetchMapObjects = useCallback(async () => {
    if (!userCompany) {
      return;
    }

    return await handleRequest(client.listMapObjects(userCompany, id));
  }, [id, handleRequest, client, userCompany]);

  const fetchSeats = useCallback(async () => {
    if (!userCompany) {
      return;
    }

    return await handleRequest(client.listSeats(userCompany, id));
  }, [id, handleRequest, client, userCompany]);

  const initializeLocally = useCallback(() => {
    if (!userCompany) {
      return;
    }
    client.listSeats(userCompany, id).then((result) => {
      if (!result || !result.data.result) {
        return;
      }
      setSeats(result.data.result.items);
      setInitialSeats(result.data.result.items);
    });
  }, [fetchSeats, setSeats, setInitialSeats]);

  const initialize = useCallback(() => {
    fetchSeats().then((result) => {
      if (!result || !result.data.result) {
        return;
      }
      setSeats(result.data.result.items);
      setInitialSeats(result.data.result.items);
    });
  }, [fetchSeats, setSeats, setInitialSeats]);

  const fetchSeatTags = useCallback(async () => {
    if (!userCompany) {
      return;
    }
    return await handleRequest(
      client.listSeatTags(userCompany, "label", "asc", 1000)
    );
  }, [handleRequest, client, userCompany, countSeatTagUpdate]);

  const fetchUserGroups = useCallback(async () => {
    if (!userCompany) {
      return;
    }
    return await handleRequest(
      client.listUserGroups(userCompany, "label", "asc", 1000)
    );
  }, [handleRequest, client, userCompany]);

  const fetchMapImage = useCallback(async () => {
    if (!map) {
      return;
    }
    const response = await handleRequest(client.getOriginalMapImageUrl(map.id));
    if (response) {
      return await axios({
        url: response?.data.result.url,
        responseType: "arraybuffer",
      });
    }
  }, [map]);

  const ComponentToPrintTable = React.forwardRef((props, ref) => {
    return (
      <View ref={ref}>
        <Table
          tableId="map_component_overview"
          preserveHeaderBorderTop={true}
          rowPaddingTop={"0px"}
          rowPaddingBottom={"0px"}
          headerPaddingTop={spacing.medium}
          headerPaddingBottom={spacing.medium}
          noShadow
          style={{ boxShadow: "none" }}
          activeRowId={activeRowId}
          onLineClick={(id) => {
            setCurrentSeatId(id);
            if (activeRowId && id === activeRowId) {
              setActiveRowId(undefined);
              setMapStatus(false);
            } else {
              setActiveRowId(id);
              setMapStatus(true);
              setNextSeat(getNextSeatNumber());
            }

            const findSeatInfos = seats?.find((seat) => seat.id === id);

            setTempSeatData(findSeatInfos);
          }}
          headerItems={headerItems}
          tableContent={tableData}
          sortList={(sort) => {
            sortMemory[sort] = sortMemory[sort] === "asc" ? "desc" : "asc";

            const direction =
              sortMemory[sort] === "asc" ? ["desc", "asc"] : ["asc", "desc"];

            const list = orderBy(seats, sort, direction);

            setSeats(list);
            onSortList(sort);
          }}
          sortOrder={sortOrder}
          sortField={sortField}
          skipParam={skipParam}
          setSkipParam={setSkipParam}
          columnSizes={columnSizes}
        />

        <Paginator
          totalItems={seats.length}
          skipParam={skipParam}
          setSkipParam={setSkipParam}
        />
      </View>
    );
  });

  const fetchMapList = useCallback(async () => {
    if (userCompany) {
      return await handleRequest(client.listMaps(userCompany));
    }
  }, [userCompany]);

  useEffect(() => {
    const createTableData = seats
      ?.slice(skipParam, skipParam + 20)
      .map(
        ({
          seatTags,
          userGroups,
          barcodes,
          isActive,
          name,
          seatType,
          notFutureReservable,
          id,
        }) => {
          const data: any[] = [
            <ExtraSmallText mediumWeight key="name">
              {name}
            </ExtraSmallText>,

            <ExtraSmallText
              style={{ opacity: opacity.disabled }}
              key="seatType"
            >
              {seatType}
            </ExtraSmallText>,
            <ExtraSmallText key="seatTags">
              <TagComponent tags={seatTags.map((e) => e.label)} />
            </ExtraSmallText>,

            <ExtraSmallText mediumWeight key="group">
              <>{userGroups.map((e) => e.label).join(", ")}</>
            </ExtraSmallText>,
            <ExtraSmallText mediumWeight key="isActive">
              <Toggle onChange={() => null} toggleValue={Boolean(isActive)} />
            </ExtraSmallText>,
            <ExtraSmallText mediumWeight key="notFutureReservable">
              <Toggle
                onChange={(toggleValue) => {
                  const findSeatInfos = seats?.find((seat) => seat.id === id);

                  setHotDeskVisibility(toggleValue, findSeatInfos);
                }}
                toggleValue={Boolean(notFutureReservable)}
              />
            </ExtraSmallText>,
            <ExtraSmallText mediumWeight key="isActive">
              <>{barcodes.map((group) => group.code)}</>
            </ExtraSmallText>,
          ];
          return {
            data,
            id,
            hideDeleteButton: true,
            hideEditButton: true,
          };
        }
      );

    setTableData(createTableData);

    const list =
      seats?.map((seat) => ({
        ...seat,
        coordX: seat.coordX - mapIconOffset,
        coordY: seat.coordY - mapIconOffset,
      })) || [];

    setSelectedSeats(list);
  }, [seats, skipParam]);

  useEffect(() => {
    fetchMapList().then((result) => {
      if (!result || !result.data.result) {
        return;
      }
      setMapList(result.data.result.items);
    });

    fetchMap(id).then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setMap(result.data.result.map);
    });
    fetchMapObjects().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setMapObjects(result.data.result.items);
    });
    fetchSeats().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setSeats(result.data.result.items);
      setInitialSeats(result.data.result.items);
    });
    fetchSeatTags().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setSeatTags(result.data.result.items);
    });

    fetchUserGroups().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      setUserGroups(result.data.result.items);
    });
  }, [
    fetchMap,
    setMap,
    fetchMapObjects,
    fetchSeats,
    fetchSeatTags,
    fetchUserGroups,
  ]);

  useEffect(() => {
    if (map) {
      fetchMapImage().then(async (result) => {
        console.log("result of mapImage!!!", result);
        if (!result?.data) {
          console.log("No data");
          return;
        }
        console.log("Got image");
        const mapImageAsset = await Asset.loadAsync(
          `data:image/png;base64,${Buffer.from(result.data, "binary").toString(
            "base64"
          )}`
        );
        setMapImage(mapImageAsset[0]);
      });
    }
  }, [map]);

  const getNextSeatNumber = () => {
    if (!seats) return 0;
    return (
      Math.max(
        0,
        ...seats?.map((seat) => {
          const matches = seat.name.match(/(\d+)/);
          if (matches && matches.length > 0) {
            return parseInt(matches[matches.length - 1], 10);
          }
          return 0;
        })
      ) + 1
    );
  };

  const getMSRooms = async () => {
    if (!userCompany) return [];

    const res = await client.getSeatsAvailableMSRooms(userCompany);
    return Object.keys(res.data.result).map((key) => res.data.result[key]);
  };

  const [asset, assetsError] = useAssets([
    workspacePng,
    workspaceSelectedPng,
    workspaceRedPng,
    workspaceInactivePng,
    lunchPng,
    lunchSelectedPng,
    lunchRedPng,
    lunchInactivePng,
    meetingRoomPng,
    meetingRoomSelectedPng,
    meetingRoomRedPng,
    meetingRoomInactivePng,
    arrow,
  ]);

  if (
    isLoading ||
    !map ||
    !seats ||
    !mapObjects ||
    !seatTags ||
    !userGroups ||
    !mapImage ||
    !asset ||
    assetsError
  ) {
    return <LoadOverlay />;
  }

  if (!map) {
    return null;
  }

  return (
    <>
      <Prompt
        when={checkIfUserReallychangeFields()}
        message={(_, action) => {
          setSelectKey(Math.random());
          return t("admin.map.overview.modal_message");
        }}
      />

      {saving && <LoadOverlay />}
      <Container paddingTop="0px" backgroundColor={color.white}>
        <View
          style={{
            height: currentTab === "table" ? "100px" : "0",
            position: Position.sticky,
            zIndex: 10,
            top: 0,
            backgroundColor: color.white,
          }}
        >
          <View style={styles.mapContainerView}>
            <View
              style={{
                paddingLeft: spacing.medium,
                backgroundColor: color.white,
              }}
            >
              <View style={styles.switchTitle}>
                <View style={styles.switchTitle}>
                  <Text fontSize={14}>{map.location.name}</Text>
                </View>
                <CustomSelect
                  key={selectKey}
                  items={mapList
                    .filter(
                      (mapData) => mapData.location.name === map.location.name
                    )
                    .map((map) => {
                      return {
                        label: map.name,
                        value: map.id,
                        isActive: map.id === id,
                      };
                    })}
                  onChange={(item) => {
                    setShowSavedMessage(false);
                    console.log(item);
                    const mapId = item.value;
                    if (mapId === id) return;
                    history.push(`/admin/map-detail/${mapId}`);
                  }}
                />
              </View>
            </View>

            <View style={styles.mapContainerSubView}>
              <View style={styles.switchTitle}>
                <Text>{t("admin.map.overview.display")}</Text>
              </View>

              <View style={styles.switchParent}>
                <View>
                  <SwitchButton
                    onPress={() => {
                      setTab("table");
                    }}
                    title={t("admin.map.overview.list")}
                    bgColor={currentTab === "table" ? color.white : "none"}
                  />
                </View>
                <View>
                  <SwitchButton
                    onPress={() => {
                      setTab("map");
                      setMapStatus(false);
                    }}
                    title={t("admin.map.overview.map")}
                    bgColor={currentTab === "map" ? color.white : "none"}
                  />
                </View>
              </View>
            </View>

            <View style={{ paddingRight: spacing.medium }}>
              <View style={styles.mapContainerSubView}>
                {currentTab === "table" && (
                  <>
                    <Button
                      borderRadius={borderRadius.tiny}
                      backgroundColor={color.white}
                      color={color.dark}
                      backgroundHoverColor={color.white}
                      borderColor={color.grey}
                      addHorizontalMargin={spacing.small}
                      onPress={handlePrint}
                      textHoverColor={color.dark}
                    >
                      <Text color={color.dark} fontSize={12}>
                        {t("admin.map.overview.print")}
                      </Text>
                    </Button>

                    <Button
                      borderRadius={borderRadius.tiny}
                      style={{ marginRight: spacing.small }}
                      onPress={onSaveAndClose}
                    >
                      <Text color={color.white} fontSize={12}>
                        {t("admin.map.edit.saveAndExit")}
                      </Text>
                    </Button>
                    <Button
                      borderRadius={borderRadius.tiny}
                      style={{ marginRight: spacing.small }}
                      onPress={onSave}
                    >
                      <Text color={color.white} fontSize={12}>
                        {t("admin.map.edit.save")}
                      </Text>
                    </Button>
                  </>
                )}

                {currentTab === "map" && (
                  <>
                    <FileUpload
                      borderRadius={borderRadius.tiny}
                      acceptedFileTypes="image/png, image/jpeg"
                      placeholder={t(
                        "admin.map.create.form.updateImagePlaceholder"
                      )}
                      onSubmit={(files) => {
                        setCurrentImage(files);
                        setTriggerOnUpload(!triggerUpload);
                      }}
                    />
                    <Button
                      borderRadius={borderRadius.tiny}
                      style={{ marginRight: spacing.small }}
                      onPress={() => {
                        setTriggerOnSaveAndClose(!triggerOnSaveAndClose);
                      }}
                    >
                      <Text color={color.white} fontSize={12}>
                        {t("admin.map.edit.saveAndExit")}
                      </Text>
                    </Button>
                    <Button
                      borderRadius={borderRadius.tiny}
                      style={{ marginRight: spacing.small }}
                      onPress={() => {
                        setTriggerOnSave(!triggerOnSave);
                        setTriggerOnSave(!triggerOnSave);
                      }}
                    >
                      <Text color={color.white} fontSize={12}>
                        {t("admin.map.edit.save")}
                      </Text>
                    </Button>
                  </>
                )}

                <Button
                  style={{ marginRight: spacing.small }}
                  borderRadius={borderRadius.tiny}
                  backgroundColor={color.white}
                  backgroundHoverColor={color.white}
                  borderColor={color.secondary}
                  textHoverColor={color.secondary}
                  onPress={() => {
                    history.push("/admin/map-overview");
                  }}
                >
                  <Text color={color.secondary} fontSize={12}>
                    {t("admin.map.overview.close")}
                  </Text>
                </Button>
              </View>
            </View>
          </View>
        </View>

        {showSavedMessage ? (
          <View style={{ marginTop: spacing.medium }}>
            <Notification
              type="success"
              closeNotification={() => setShowSavedMessage(false)}
            >
              <Text fontFamily={font.defaultBold} color={color.quinary}>
                {t("general.savedSuccess")}
              </Text>
            </Notification>
          </View>
        ) : null}

        <View>
          {mapStatus && (
            <Portal wrapperId="map-sidebar">
              <SeatForm
                key={tempSeatData.id}
                seat={tempSeatData}
                positionType="right"
                mapId={map.id}
                onSubmit={() => null}
                onChange={() => null}
                getMSRooms={getMSRooms}
                getSeatInfo={(key, value) => {
                  updateSeat(key, value);
                }}
                seatTags={seatTags}
                userGroups={userGroups}
                closeForm={() => {
                  setMapStatus(false);
                  setActiveRowId(undefined);
                }}
                seatsAmount={nextSeat}
                position={Position.fixed}
                top="195px"
                right={0}
                height="calc(100vh - 195px)"
                minHeight={0}
                overflow={Overflow.auto}
                borderTopLeftRadius={10}
                borderTopRightRadius={0}
              />
            </Portal>
          )}

          {currentTab === "table" && (
            <ComponentToPrintTable ref={componentRef} />
          )}
        </View>
        {currentTab === "map" && (
          <Map
            ref={componentRef}
            key={id}
            triggerActionFromParent={true}
            currentImage={currentImage || undefined}
            triggerOnSaveAndClose={triggerOnSaveAndClose}
            triggerUpload={triggerUpload}
            triggerOnSave={triggerOnSave}
            afterSeatFormSubmit={() => {
              setSeatHistoryIds([]);
              setTriggerOnSave(null);
              initializeLocally();
            }}
            getMSRooms={getMSRooms}
            getSeatInfo={(data) => {
              refreshSeatTableWithUpdatedMapData(data);
            }}
            isCleaningMap={false}
            asset={[mapImage, ...asset]}
            toggleSeatForm={(value) => {
              setSeatMapOnMapIsOpen(value);
            }}
            isEditable={true}
            mapData={{
              id: map.id,
              name: map.name,
              zones: [],
              arrows: mapObjects,
              seats: seats.map((seat) => ({
                ...seat,
                morningBlocked: [],
                afternoonBlocked: [],
              })),
            }}
            seatTags={seatTags}
            userGroups={userGroups}
            addTag={() => setShowSeatModal(true)}
          />
        )}
      </Container>

      <Portal wrapperId="add-tag-modal" zIndex={100}>
        <Dialog
          show={showSeatModal}
          setShow={() => {
            setShowSeatModal(false);
          }}
        >
          <CreateSeat
            onSuccess={() => {
              setShowSeatModal(false);
              fetchSeatTags();
              setCountSeatTagUpdate(countSeatTagUpdate + 1);
              setShowSavedMessage(true);
            }}
            onCancel={() => {
              setShowSeatModal(false);
            }}
          />
        </Dialog>
      </Portal>
    </>
  );
};

export default MapDetail;
