import React, { FC, useState } from "react";
import {
  borderRadius,
  color,
  spacing,
} from "../../styles/theme";

import * as DocumentPicker from "expo-document-picker";
import Button, { ButtonOptions } from "../Button";
import FileUploadIcon from "./FileUploadIcon";


interface FileUploadInterface extends ButtonOptions {
  // Seperate value's with comma to include multiple file extentions
  // For example: "image/png, image/jpg" to accept only jpg or png files
  acceptedFileTypes: string;
  placeholder: string;
  onSubmit(file: DocumentPicker.DocumentResult): void;
  FileUploadIconColors?: string;
}

const FileUpload: FC<FileUploadInterface> = ({
  placeholder,
  acceptedFileTypes,
  onSubmit,
  FileUploadIconColors
}) => {
  const [fileName, setFileName] = useState(placeholder);

  const selectFile = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: acceptedFileTypes,
      });
      if (result.type === "success") {
        onSubmit(result);
        setFileName(result.name);
      }
    } catch (err) {
      alert("Error: " + JSON.stringify(err));
      throw err;
    }
  };

  return (
    <Button
      addHorizontalMargin={spacing.medium}
      backgroundColor={color.white}
      backgroundHoverColor={color.secondary}
      borderRadius={borderRadius.tiny}
      borderColor={color.secondary}
      textHoverColor={color.white}
      onPress={selectFile}
      prefix={<FileUploadIcon color={FileUploadIconColors ? FileUploadIconColors : color.dark} />}
    >
      {fileName || placeholder}
    </Button>
  );
};

export default FileUpload;
