module["exports"] = [
  "فييستا",
  "التركيز",
  "الثور",
  "موستانج",
  "اكسبلورر",
  "البعثة",
  "نموذج T",
  "رانتشيرو",
  "فولت",
  "كروز",
  "ماليبو",
  "إمبالا",
  "كامارو",
  "كورفيت",
  "كولورادو",
  "سيلفرادو",
  "ش كامينو",
  "إسكاليد",
  "جبال الألب",
  "شاحن",
  "لو بارون",
  "بي تي كروزر",
  "تشالنجر",
  "دورانجو",
  "القافلة الكبرى",
  "رانجلر",
  "جراند شيروكي",
  "رودستر",
  "عارضات ازياء",
  "النموذج 3",
  "كامري",
  "بريوس",
  "لاند كروزر",
  "اتفاق",
  "المدنية",
  "جزء",
  "سنترا",
  "ألتيما",
  "خنفساء",
  "جيتا",
  "جولف",
  "سبايدر",
  "الكونتاش",
  "مورسيلاغو",
  "أفينتادور"
];
