module["exports"] = [
  "vermelho",
  "verde",
  "azul",
  "amarelo",
  "roxo",
  "branco",
  "preto",
  "laranja",
  "rosa",
  "cinzento",
  "castanho",
  "violeta",
  "turquesa",
  "bronzeado",
  "salmão",
  "ameixa",
  "orquídea",
  "magenta",
  "lima",
  "marfim",
  "índigo",
  "ouro",
  "fúcsia",
  "ciano",
  "azure",
  "lavanda",
  "prata"
];
