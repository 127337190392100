import React from "react";
import NavbarSwitch from "../components/Navbar";
import { useAuth } from "../context/authContext";
import { t } from "../i18n";
import { Redirect, Route, Router, Switch } from "../routing";
import AdminUserOverview from "../screens/admin/AdminUserOverview";
import AdminUserOverviewCreate from "../screens/admin/AdminUserOverview/Create";
import AdminUserOverviewUpdate from "../screens/admin/AdminUserOverview/Update";
import CleaningMapDetail from "../screens/admin/CleaningMapDetail";
import CompanyOverview from "../screens/admin/CompanyOverview";
import CompanyOverviewCreate from "../screens/admin/CompanyOverview/Create";
import CompanyOverviewUpdate from "../screens/admin/CompanyOverview/Update";

import Index from "../screens/admin/CompanySettings";
import NewSettings from "../screens/admin/generalCompanySettings";
// Pages
import Dashboard from "../screens/admin/Dashboard/Dashboard";
import LocationOverview from "../screens/admin/LocationOverview";
import LocationOverviewCreate from "../screens/admin/LocationOverview/Create";
import LocationOverviewUpdate from "../screens/admin/LocationOverview/Update";
import LocationOverviewUpdateHours from "../screens/admin/LocationOverview/Hours";
import MapDetail from "../screens/admin/MapOverview/MapDetail";
import MapOverview from "../screens/admin/MapOverview";
import MapOverviewCreate from "../screens/admin/MapOverview/Create";
import MapOverviewUpdate from "../screens/admin/MapOverview/Update";
import {
  DoorOverview,
  DoorCreate as DoorOverviewCreate,
  DoorUpdate as DoorOverviewUpdate,
} from "../screens/admin/DoorOverview";
import {
  ResourceOverview,
  ResourceCreate as ResourceOverviewCreate,
  ResourceUpdate as ResourceOverviewUpdate,
} from "../screens/admin/ResourceOverview";

import NotificationOverview from "../screens/admin/NotificationOverview";
import NotificationOverviewCreate from "../screens/admin/NotificationOverview/Create";
import NotificationOverviewUpdate from "../screens/admin/NotificationOverview/Update";
import ReportOverview from "../screens/admin/ReportOverview";
import ReservationOverview from "../screens/admin/ReservationOverview";
import SeatTagOverview from "../screens/admin/SeatTagOverview";
import SeatTagOverviewCreate from "../screens/admin/SeatTagOverview/Create";
import SeatTagOverviewUpdate from "../screens/admin/SeatTagOverview/Update";
import TimeslotOverview from "../screens/admin/TimeslotOverview";
import TimeslotOverviewCreate from "../screens/admin/TimeslotOverview/Create";
import TimeslotOverviewUpdate from "../screens/admin/TimeslotOverview/Update";
import UserGroupOverview from "../screens/admin/UserGroupOverview";
import UserGroupOverviewCreate from "../screens/admin/UserGroupOverview/Create";
import UserGroupOverviewUpdate from "../screens/admin/UserGroupOverview/Update";
import UserOverview from "../screens/admin/UserOverview";
import BarcodeOverview from "../screens/admin/BarcodeOverview";
import UserOverviewCreate from "../screens/admin/UserOverview/Create";
import UserOverviewImport from "../screens/admin/UserOverview/Import";
import UserOverviewUpdate from "../screens/admin/UserOverview/Update";
import RouteWithTitle from "./RouteWithTitle";
import ActiveDirectoryOverview from "../screens/admin/ActiveDirectoryOverview";
/* transport options */
import TransportOverview from "../screens/admin/TransportOverview";
import ArchiveSettings from "../screens/admin/CompanySettings/Archive";
import ReportSettings from "../screens/admin/CompanySettings/Reports";
import ResourceListOverview from "../screens/admin/ResourceListOverview";

export default function AuthenticatedRoutes(): JSX.Element {
  const { userState } = useAuth();
  return (
    <Router>
      <NavbarSwitch />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            if (!userState) {
              return null;
            }

            return <Redirect to="/admin/dashboard" />;
          }}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.dashboard")}
          path="/admin/dashboard"
          component={Dashboard}
        />

        <RouteWithTitle
          exact
          screenTitle={t("screens.adminUser")}
          path="/admin/admin-user-overview"
          component={AdminUserOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.adminUser")}
          path="/admin/admin-user-overview/create"
          component={AdminUserOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.adminUser")}
          path="/admin/admin-user-overview/update/:id"
          component={AdminUserOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.user")}
          path="/admin/user-overview"
          component={UserOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.user")}
          path="/admin/user-overview/update/:id"
          component={UserOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.user")}
          path="/admin/user-overview/create"
          component={UserOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.user")}
          path="/admin/user-overview/import"
          component={UserOverviewImport}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.company")}
          path="/admin/company-overview"
          component={CompanyOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.company")}
          path="/admin/company-overview/create"
          component={CompanyOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.company")}
          path="/admin/company-overview/update/:id"
          component={CompanyOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.settings")}
          path="/admin/company-settings"
          component={Index}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.general")}
          path="/admin/new-settings/general"
          component={NewSettings}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.location")}
          path="/admin/location-overview"
          component={LocationOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.location")}
          path="/admin/location-overview/update/:id"
          component={LocationOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.location")}
          path="/admin/location-overview/update/:id/hours"
          component={LocationOverviewUpdateHours}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.location")}
          path="/admin/location-overview/create"
          component={LocationOverviewCreate}
        />       
        <RouteWithTitle
          exact
          screenTitle={t("screens.salto")}
          path="/admin/salto-overview"
          component={DoorOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.salto")}
          path="/admin/salto-overview/update/:id"
          component={DoorOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.salto")}
          path="/admin/salto-overview/create"
          component={DoorOverviewCreate}
        />        
        <RouteWithTitle
          exact
          screenTitle={t("screens.resource")}
          path="/admin/resource-overview"
          component={ResourceOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.resource")}
          path="/admin/resource-overview/update/:id"
          component={ResourceOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.resource")}
          path="/admin/resource-overview/create"
          component={ResourceOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.timeslot")}
          path="/admin/timeslot-overview"
          component={TimeslotOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.timeslot")}
          path="/admin/timeslot-overview/update/:id"
          component={TimeslotOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.timeslot")}
          path="/admin/timeslot-overview/create"
          component={TimeslotOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.reservation")}
          path="/admin/reservation-overview"
          component={ReservationOverview}
        />        
        <RouteWithTitle
          exact
          screenTitle={t("screens.resource")}
          path="/admin/resource-list-overview"
          component={ResourceListOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.activeDirectory")}
          path="/admin/company/active-directory"
          component={ActiveDirectoryOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.transport")}
          path="/admin/transport-options"
          component={TransportOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.notification")}
          path="/admin/notification-overview"
          component={NotificationOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.notification")}
          path="/admin/notification-overview/update/:id"
          component={NotificationOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.notification")}
          path="/admin/notification-overview/create"
          component={NotificationOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.report")}
          path="/admin/report-overview"
          component={ReportOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.seatTag")}
          path="/admin/company/tag-overview"
          component={SeatTagOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.seatTag")}
          path="/admin/company/tag-overview/create"
          component={SeatTagOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.seatTag")}
          path="/admin/company/tag-overview/update/:id"
          component={SeatTagOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.archiving")}
          path="/admin/company/archiving"
          component={ArchiveSettings}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.barcodes")}
          path="/admin/office/barcodes"
          component={BarcodeOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.scheduled")}
          path="/admin/company/reports"
          component={ReportSettings}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.userGroup")}
          path="/admin/user-group-overview"
          component={UserGroupOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.userGroup")}
          path="/admin/user-group-overview/create"
          component={UserGroupOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.userGroup")}
          path="/admin/user-group-overview/update/:id"
          component={UserGroupOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.map")}
          path="/admin/map-overview"
          component={MapOverview}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.map")}
          path="/admin/map-overview/create"
          component={MapOverviewCreate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.map")}
          path="/admin/map-overview/update/:id"
          component={MapOverviewUpdate}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.mapDetail")}
          path="/admin/map-detail/:id"
          component={MapDetail}
        />
        <RouteWithTitle
          exact
          screenTitle={t("screens.cleaning")}
          path="/admin/cleaning-map-overview"
        >
          <MapOverview isCleaningMap />
        </RouteWithTitle>
        <RouteWithTitle
          exact
          screenTitle={t("screens.cleaningMapDetail")}
          path="/admin/cleaning-map-detail/:id"
          component={CleaningMapDetail}
        />
        <Redirect to="/admin/dashboard" />
      </Switch>
    </Router>
  );
}
