import globalAxios from "axios";
import moment from "moment-timezone";
import React, { FC, useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { REACT_APP_GOOGLE_API_KEY } from "@env";
import {
  InlineResponse400Errors,
  LocationWithoutId,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import InputValidationErrors from "../../../components/InputValidationErrors";
import LabeledInput from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import PickerInput from "../../../components/PickerInput";
import { InputValidationError, useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import useCountries from "../../../hooks/useCountries";
import useTimezones from "../../../hooks/useTimezones";
import { t } from "../../../i18n";
import { useHistory } from "../../../routing";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import { color, display, flex, font, spacing } from "../../../styles/theme";
import { MediumTitle } from "../../../styles/Title";
import { isValidEmail } from "../../../utils";
import { createRequestFunction } from "../../../../bookmydesk-api-sdk-typescript-axios/common";
import { ErrorText } from "../../../styles/Text";
import Text from "../../../styles/Text/text";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

export const HelperTextWrapper = styled(View)`
  display: ${display.flex};
  flex-direction: ${flex.direction.row};
  justify-content: ${flex.justifyContent.center};
  padding: 0 0 20px;
`;

export const StyledText = styled(Text)`
  font-family: ${font.defaultBold};
  color: ${color.quinary};
`;

const Create: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState<number | undefined>(undefined);
  const [addition, setAddition] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [maxPlacesCheck, setMaxPlacesCheck] = useState(false);
  const [maxPlaces, setMaxPlaces] = useState<number | undefined>(undefined);
  const [email, setEmail] = useState("");
  const [parkingSpots, setParkingSpots] = useState<number | undefined>(
    undefined
  );

  const [visitorReservationEmail, setVisitorReservationEmail] =
    useState<string>("");
  const [visible, setVisible] = useState(true);
  const [geoSuccess, setGeoSuccess] = useState<boolean | null>(null);
  const [centerLat, setCenterLat] = useState("0.0");
  const [centerLng, setCenterLng] = useState("0.0");
  const [proximityRadius, setProximityRadius] = useState(0);

  const countries = useCountries();

  const history = useHistory();
  const client = useClient();
  const timezones = useTimezones();
  const { handleRequest, isLoading, error, dismissError } = useApi();

  // TODO not valid? Strange, PickerInput not well styled yet, should be in the map editor?
  const isFormValid = useCallback(
    () =>
      Boolean(name) &&
      Boolean(street) &&
      Boolean(houseNumber) &&
      Boolean(zipCode) &&
      Boolean(city) &&
      Boolean(timezone) &&
      (!maxPlacesCheck || Boolean(maxPlaces)) &&
      parkingSpots! >= 0 &&
      isValidEmail(email),
    [
      name,
      street,
      houseNumber,
      zipCode,
      city,
      maxPlacesCheck,
      maxPlaces,
      parkingSpots,
      email,
      timezone,
    ]
  );

  const isLocationValid = useMemo(
    () => {
      setGeoSuccess(null);
      return !!street && !!houseNumber && !!zipCode && !!city && !!countryCode
    },
    [street, houseNumber, zipCode, city, countryCode]
  );

  const getCoordinate = useCallback(async () => {
    const address = [
      name,
      houseNumber,
      street,
      city,
      countryCode,
    ].filter((item) => !!item).join();
    const request = createRequestFunction({
        url: "",
        options: {
          method: "GET"
        }
      },
      globalAxios,
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${REACT_APP_GOOGLE_API_KEY}`,
    );
    const res = await handleRequest(request());
    if (res && res.data?.status.toString().toLowerCase() === "ok" && res.data.results?.length > 0) {
      const geometry = res.data.results[0]?.geometry?.location;
      setCenterLat(geometry.lat);
      setCenterLng(geometry.lng);
      setGeoSuccess(true);
    } else {
      setGeoSuccess(false);
    }
    // TODO: continue to implement logic after get api key
  }, [city, countryCode, handleRequest, houseNumber, name, street]);

  const submitForm = async () => {
    const location: LocationWithoutId = {
      name,
      address: {
        street,
        number: houseNumber!,
        addition: addition,
        postalCode: zipCode,
        city,
        countryCode,
      },
      bookingLimit: maxPlacesCheck ? maxPlaces || 0 : -1,
      parkingCount: parkingSpots!,
      visible,
      contactEmail: email,
      centerLat: parseFloat(centerLat),
      centerLng: parseFloat(centerLng),
      proximityRadius,
      visitorReservationEmail: visitorReservationEmail || null,
      startTime: startTime?.length ? moment(startTime, 'HH:mm').toISOString() : null,
      endTime: endTime?.length ? moment(endTime, 'HH:mm').toISOString() : null,
      timezone,
    };
    try {
      const success = await handleRequest(client.addLocation(location));

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  };

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <MediumTitle hasMarginBottom>
            {t("admin.location.form.addTitle")}
          </MediumTitle>
          <LabeledInput label={t("admin.location.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.location.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.location.form.street")} required>
            <Input
              onChangeText={setStreet}
              value={street}
              placeholder={t("admin.location.form.street") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.location.form.number")} required>
            <Input
              onChangeText={(value) => setHouseNumber(parseInt(value) || 0)}
              value={houseNumber?.toString()}
              placeholder={t("admin.location.form.number") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.location.form.addition")}>
            <Input
              onChangeText={setAddition}
              value={addition}
              placeholder={t("admin.location.form.addition")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.location.form.zipCode")} required>
            <Input
              onChangeText={setZipCode}
              value={zipCode}
              placeholder={t("admin.location.form.zipCode") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.location.form.city")} required>
            <Input
              onChangeText={setCity}
              value={city}
              placeholder={t("admin.location.form.city") + " *"}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.location.form.country")}>
            <PickerInput
              includeChoose
              selectedValue={countryCode}
              items={countries}
              onValueChange={setCountryCode}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            width="50%"
            addHorizontalMargin="auto"
            addHorizontalMargin="auto"
            disabled={!isLocationValid}
            onPress={getCoordinate}
          >
            {t("admin.location.form.getCoordinate")}
          </Button>
          <HelperTextWrapper>
            {(geoSuccess === false && geoSuccess !== null) && <ErrorText>{t("admin.location.form.getCoordinateFailed")}</ErrorText>}
            {geoSuccess && <StyledText>{t("admin.location.form.getCoordinateSuccess")}</StyledText>}
          </HelperTextWrapper>
          <LabeledInput
            label={t("admin.location.form.centerLat")}
            helperText={t("admin.location.form.helper.centerLat")}
          >
            <Input
              onChangeText={(value) => setCenterLat(value)}
              value={centerLat.toString()}
              placeholder={t("admin.location.form.centerLat")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.location.form.centerLng")}
            helperText={t("admin.location.form.helper.centerLng")}
          >
            <Input
              onChangeText={(value) => setCenterLng(value)}
              value={centerLng.toString()}
              placeholder={t("admin.location.form.centerLng")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.timeslot.form.start")}
            helperText={t("admin.timeslot.form.helper.from")}
          >
            <Input
              value={startTime}
              onChangeText={setStartTime}
              placeholder={t("admin.timeslot.form.start")}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.timeslot.form.end")}
            helperText={t("admin.timeslot.form.helper.to")}
          >
            <Input
              value={endTime}
              onChangeText={setEndTime}
              placeholder={t("admin.timeslot.form.end")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.location.form.timezone")} required>
            <PickerInput
              selectedValue={timezone}
              items={timezones}
              onValueChange={setTimezone}
            />
          </LabeledInput>
          <LabeledInput helperText={t("admin.location.form.helper.limit")}>
            <Checkbox
              checked={maxPlacesCheck}
              onChange={setMaxPlacesCheck}
              label={t("admin.location.form.limit")}
            />
          </LabeledInput>
          {maxPlacesCheck ? (
            <LabeledInput label={t("admin.location.form.limit")}>
              <Input
                onChangeText={(value) => setMaxPlaces(parseInt(value) || 0)}
                value={maxPlaces === -1 ? "" : maxPlaces?.toString()}
                placeholder={t("admin.location.form.limit")}
              />
            </LabeledInput>
          ) : null}
          <LabeledInput
            label={t("admin.location.form.parking")}
            helperText={t("admin.location.form.helper.parking")}
            required
          >
            <Input
              onChangeText={(value) => setParkingSpots(parseInt(value) || 0)}
              value={parkingSpots?.toString()}
              placeholder={t("admin.location.form.parking") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.location.form.proximityRadius")}
            helperText={t("admin.location.form.helper.proximityRadius")}
          >
            <Input
              onChangeText={(value) => setProximityRadius(parseInt(value) || 0)}
              value={proximityRadius.toString()}
              placeholder={t("admin.location.form.proximityRadius")}
            />
          </LabeledInput>
          <LabeledInput helperText={t("admin.location.form.helper.visible")}>
            <Checkbox
              label={t("admin.location.form.visible")}
              onChange={setVisible}
              checked={visible}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.location.form.email")}
            helperText={t("admin.location.form.helper.email")}
            required
          >
            <Input
              onChangeText={setEmail}
              value={email}
              placeholder={t("admin.location.form.email") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.location.form.visitorReservationEmail")}
          >
            <Input
              onChangeText={setVisitorReservationEmail}
              value={visitorReservationEmail}
              placeholder={t("admin.location.form.visitorReservationEmail")}
            />
          </LabeledInput>
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.location.form.createSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

export default Create;
