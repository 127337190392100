module["exports"] = {
  "adjective": [
    "Klein",
    "Ergonomisch",
    "Rustiek",
    "Intelligent",
    "Prachtig",
    "Ongelooflijk",
    "Fantastisch",
    "Praktisch",
    "Slank",
    "Geweldig",
    "Algemeen",
    "Handgemaakt",
    "Ambachtelijk",
    "Artistiek",
    "Verantwoord",
    "Uitgekiend",
    "Zeldzaam",
    "Verfijnd",
    "Merkloos",
    "Smakelijk"
  ],
  "material": [
    "Staal",
    "Glazen",
    "Wollen",
    "Houten",
    "Beton",
    "Plastic",
    "Katoenen",
    "Granieten",
    "Rubberen",
    "IJzeren",
    "Zachte",
    "Verse",
    "Bevroren"
  ],
  "product": [
    "Stoel",
    "Auto",
    "Computer",
    "Toetsenbord",
    "Muis",
    "Fiets",
    "Bal",
    "Handschoenen",
    "Broek",
    "Shirt",
    "Tafel",
    "Bijzettafeltje",
    "Boekenkast",
    "Kaasschaaf",
    "Leunstoel",
    "Poef",
    "Hangmat",
    "Trui",
    "Schoenen",
    "Hoed",
    "Handdoeken",
    "Zeep",
    "Tonijn",
    "Kip",
    "Vis",
    "Kaas",
    "Spek",
    "Pizza",
    "Salade",
    "Worstjes",
    "Chips"
  ]
};
