import { t } from "i18n-js";
import React, { FC, useState } from "react";
import { View } from "react-native";
import HeaderCard from "../../../components/HeaderCard";
import IconBadge from "../../../components/IconBadge";
import Text from "../../../styles/Text";
import { HorizontalCardDivider } from "../../../styles/Card";
import { Position, color, spacing } from '../../../styles/theme';
import {
  GeneralIcon,
  ModulesIcon,
  ConnectionIcon,
  TimeslotIcon,
  FinancialIcon,
  CompanyIcon,
} from "../settingsIcons";
import styled from "styled-components/native";
import settingIcon from "../../../assets/icons/icon-setting.svg";

const sidebarItems = [
  {
    title: t("admin.settings.sidebar.generalSettings"),
    icon: GeneralIcon,
  },
  {
    title: t("admin.settings.sidebar.modules"),
    icon: ModulesIcon,
  },
  {
    title: t("admin.settings.sidebar.links"),
    icon: ConnectionIcon,
  },
  {
    title: t("admin.settings.sidebar.timeslot"),
    icon: TimeslotIcon,
  },

  {
    title: t("admin.settings.sidebar.tags"),
    icon: FinancialIcon,
  },
  {
    title: t("admin.settings.sidebar.companyDetails"),
    icon: CompanyIcon,
  },
];

const FakeBorder = styled.View`
  position: ${Position.absolute};
  left: -22px;
  height: 55px;
  width: 4px;
  background-color: rgb(64, 162, 178);
  margin-top: -11px;
`;

const SidebarItem = styled.View`
  cursor: pointer;
  position: ${Position.relative};
`;

const SidebarContainer = styled.View`
  min-width: 350px;
`;

const FlexRowContainer = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

interface SettingsSideBarProps {
  onChange?: (index: number) => void;
}

const SettingsSideBar: FC<SettingsSideBarProps> = ({ onChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  function clickHandler(index: number) {
    setActiveIndex(index);
    onChange && onChange(index);
    return true;
  }

  function IsLastIndex(index: number) {
    return index === sidebarItems.length - 1;
  }

  return (
    <SidebarContainer>
      <View>
        <HeaderCard disableHorizontalDivider title="">
          <View>
            <FlexRowContainer>
              <IconBadge icon={settingIcon} />
              <Text mediumWeight marginLeft={spacing.medium}>{t("admin.company.form.settingsHeader")}</Text>
            </FlexRowContainer>
            <HorizontalCardDivider />
          </View>
          {sidebarItems.map(({ title, icon: Icon }, index) => {
            return (
              <SidebarItem key={index} onStartShouldSetResponder={() => clickHandler(index)}>
                {activeIndex === index && <FakeBorder />}

                <FlexRowContainer>
                  <Icon
                    color={
                      activeIndex === index
                        ? color.secondary
                        : color.greyChateau
                    }
                  />
                  <Text color={activeIndex === index ? color.secondary : undefined} mediumWeight>
                    {title}
                  </Text>
                </FlexRowContainer>
                {!IsLastIndex(index) && <HorizontalCardDivider />}
              </SidebarItem>
            );
          })}
        </HeaderCard>
      </View>
    </SidebarContainer>
  );
};

export default SettingsSideBar;
