module["exports"] = [
  "driver",
  "protocol",
  "bandbreedte",
  "paneel",
  "microchip",
  "programma",
  "port",
  "kaart",
  "array",
  "verbinding",
  "draad",
  "stekker",
  "systeem",
  "sensor",
  "firewall",
  "harde schijf",
  "pixel",
  "alarm",
  "voeding",
  "monitor",
  "toepassing",
  "zender",
  "bus",
  "stroomkring",
  "condensator",
  "matrix"
];
