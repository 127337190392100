import React, { FC, useCallback } from "react";
import styled from "styled-components/native";
import Card from "../../styles/Card";
import { color, font, spacing, text } from "../../styles/theme";

export const Backdrop = styled.View`
  background-color: ${color.transparentBackground};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  overflow: scroll;
  padding: ${spacing.large};
  z-index: 1;
`;

export const Modal = styled(Card)`
  margin: auto;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`;

export const Close = styled.TouchableOpacity`
  position: absolute;
  right: ${spacing.large};
  top: ${spacing.large};
`;

export const CloseText = styled.Text`
  line-height: 0;
  font-size: ${text.extraLarge.size};
  font-family: ${font.defaultMedium};
`;

interface DialogProps {
  show: boolean;
  setShow(show: boolean): void;
}

const Dialog: FC<DialogProps> = ({ children, show, setShow }) => {
  const closeModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <>
      {show && (
        <Backdrop>
          <Modal>
            {children}
            <Close onPress={closeModal}>
              <CloseText>⨯</CloseText>
            </Close>
          </Modal>
        </Backdrop>
      )}
    </>
  );
};

export default Dialog;
