module["exports"] = [
  "տարի",
  "որ",
  "փոխեց",
  "ամեն",
  "ինչ",
  "կենցաղից",
  "մինչև",
  "համաշխարհային",
  "տնտեսություն",
  "մի",
  "քանի",
  "շաբաթում",
  "բնականոն",
  "դարձնելով",
  "այն",
  "դեռ",
  "երեկ",
  "անհնար",
  "էր",
  "թվում",
  "ը",
  "շատերի",
  "համար",
  "կմնա",
  "որպես",
  "անտեսանելի",
  "աղետի",
  "խստագույն",
  "սահմանափակումների",
  "մարդկային",
  "կորուստների",
  "և",
  "գործազրկության",
  "Տարեսկզբին",
  "չինական",
  "Վուհանի",
  "շուկայում",
  "ենթադրաբար",
  "չղջիկից",
  "մարդուն",
  "փոխանցված",
  "վիրուսն",
  "ապացուցեց",
  "տեխնոլոգիական",
  "նվաճումների",
  "հասած",
  "մարդկությունը",
  "դեռևս",
  "շատ",
  "բան",
  "չգիտի",
  "իր",
  "սեփական",
  "առողջության",
  "բնության",
  "ու",
  "դրա",
  "հետ",
  "հարաբերությունների",
  "մասին",
  "Գարնանը",
  "երբ",
  "վիրուսը",
  "կաթվածահար",
  "արեց",
  "կյանքը",
  "գրեթե",
  "ողջ",
  "աշխարհում",
  "Հռոմի",
  "կաթոլիկ",
  "եկեղեցու",
  "առաջնորդն",
  "հավատացյալ",
  "հոտին",
  "դիմեց",
  "աղոթքով",
  "տարվա",
  "ընթացքում",
  "շատերն",
  "էին",
  "հիշում",
  "մեջբերում",
  "Մենք",
  "առաջ",
  "էինք",
  "շարժվում",
  "գլխապտույտ",
  "արագությամբ",
  "վստահ",
  "ուժեղ",
  "ենք",
  "կարող",
  "անել",
  "կամենանք",
  "Ձգտելով",
  "անձնական",
  "բարեկեցության",
  "մենք",
  "թույլ",
  "տալիս",
  "մեզ",
  "տարվել",
  "նյութականով",
  "վազում",
  "շահի",
  "ետևից",
  "չէին",
  "կանգնեցնում",
  "մեր",
  "շուրջ",
  "մոլեգնող",
  "պատերազմներն",
  "անարդարությունը",
  "ոչ",
  "էլ",
  "աղքատների",
  "օգնություն",
  "աղերսող",
  "ձայնն",
  "լսում",
  "շարունակում",
  "ընթացքը",
  "չնայելով",
  "բանի",
  "առողջ",
  "մնալ",
  "հիվանդ",
  "հայտարարել",
  "պապը",
  "Անտեսանելի",
  "առեղծվածային",
  "մեկ",
  "տարում",
  "խլեց",
  "ավելի",
  "քան",
  "միլիոն",
  "հազար",
  "մարդու",
  "կյանք",
  "վարակվեցին",
  "միլիոնից",
  "ավելին",
  "վարակվածներից",
  "յուրաքանչյուր",
  "չորրորդը",
  "մոտ",
  "միլիոնը",
  "այս",
  "պահին",
  "պայքարում",
  "է",
  "հիվանդության",
  "դեմ",
  "մարդ",
  "գտնվում",
  "ծայրահեղ",
  "ծանր",
  "վիճակում",
  "Եվ",
  "եթե",
  "տարեսկզբին",
  "որոշ",
  "պետություններ",
  "պարծենալ",
  "իրենց",
  "առողջապահական",
  "համակարգերով",
  "նորագույն",
  "տեխնոլոգիաներով",
  "ապա",
  "համավարակից",
  "հետո",
  "ակնհայտ",
  "դարձավ",
  "անկախ",
  "բյուջի",
  "չափից",
  "հնարավորություններից",
  "հարուստ",
  "թե",
  "աղքատ",
  "բոլորն",
  "են",
  "նույն",
  "նավակում",
  "նույնքան",
  "անպաշտպան"
];
