export const kebabCase = (text: string): string => text
  .replace(/([a-z])([A-Z])/g, "$1-$2")
  .replace(/[\s_]+/g, "-")
  .toLowerCase();

export const convertNumberStyles = (key: string, value: unknown): string | number => {
  const styleKey = kebabCase(key);
  if ([`font-size`].includes(styleKey) && typeof value === "number") {
    return `${value}px`;
  }
  return `${value}`;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertObjectToStyle = (styleObject: any, properties: string[] = []): string =>
  Object.entries(styleObject)
    .reduce((style, [key, value]) =>
      properties.includes(key) && ['string', 'number'].includes(typeof value)
        ? `${style}${kebabCase(key)}: ${convertNumberStyles(key, value)};` : style, '')

export const convertTranslate = (translateValue: string, [xOffset, yOffset]: [number, number]): string | undefined => {
  const [xPos, yPos] = (translateValue || '').replace(/translate\((-?\d*.?\d*)px, (-?\d*.?\d*)px\)/, '$1 $2').split(' ');

  if (!isNaN(+xPos) && !isNaN(+yPos)) {
    return `translate(${+xPos - xOffset}px, ${+yPos - yOffset}px)`;
  }
};
