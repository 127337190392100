module["exports"] = {
  AK:{
    min:99501,
    max:99950
  },
  AL:{
    min:35004,
    max:36925
  },
  AR:{
    min:71601,
    max:72959
  },
  AZ:{
    min:85001,
    max:86556
  },
  CA:{
    min:90001,
    max:96162
  },
  CO:{
    min:80001,
    max:81658
  },
  CT:{
    min:6001,
    max:6389
  },
  DC:{
    min:20001,
    max:20039
  },
  DE:{
    min:19701,
    max:19980
  },
  FL:{
    min:32004,
    max:34997
  },
  GA:{
    min:30001,
    max:31999
  },
  HI:{
    min:96701,
    max:96898
  },
  IA:{
    min:50001,
    max:52809
  },
  ID:{
    min:83201,
    max:83876
  },
  IL:{
    min:60001,
    max:62999
  },
  IN:{
    min:46001,
    max:47997
  },
  KS:{
    min:66002,
    max:67954
  },
  KY:{
    min:40003,
    max:42788
  },
  LA:{
    min:70001,
    max:71232
  },
  MA:{
    min:1001,
    max:2791
  },
  MD:{
    min:20331,
    max:20331
  },
  ME:{
    min:3901,
    max:4992
  },
  MI:{
    min:48001,
    max:49971
  },
  MN:{
    min:55001,
    max:56763
  },
  MO:{
    min:63001,
    max:65899
  },
  MS:{
    min:38601,
    max:39776
  },
  MT:{
    min:59001,
    max:59937
  },
  NC:{
    min:27006,
    max:28909
  },
  ND:{
    min:58001,
    max:58856
  },
  NE:{
    min:68001,
    max:68118
  },
  NH:{
    min:3031,
    max:3897
  },
  NJ:{
    min:7001,
    max:8989
  },
  NM:{
    min:87001,
    max:88441
  },
  NV:{
    min:88901,
    max:89883
  },
  NY:{
    min:6390,
    max:6390
  },
  OH:{
    min:43001,
    max:45999
  },
  OK:{
    min:73001,
    max:73199
  },
  OR:{
    min:97001,
    max:97920
  },
  PA:{
    min:15001,
    max:19640
  },
  PR:{
    min:0,
    max:0
  },
  RI:{
    min:2801,
    max:2940
  },
  SC:{
    min:29001,
    max:29948
  },
  SD:{
    min:57001,
    max:57799
  },
  TN:{
    min:37010,
    max:38589
  },
  TX:{
    min:73301,
    max:73301
  },
  UT:{
    min:84001,
    max:84784
  },
  VA:{
    min:20040,
    max:20041
  },
  VT:{
    min:5001,
    max:5495
  },
  WA:{
    min:98001,
    max:99403
  },
  WI:{
    min:53001,
    max:54990
  },
  WV:{
    min:24701,
    max:26886
  },
  WY:{
    min:82001,
    max:83128
  }
}
