module["exports"] = [
  "アフリカ",
  "南極大陸",
  "アジア",
  "ヨーロッパ",
  "北アメリカ",
  "南アメリカ",
  "南極",
  "北極",
  "アフガニスタン",
  "アルバニア",
  "アルジェリア",
  "アメリカ",
  "米国",
  "アンゴラ",
  "アルゼンチン",
  "オーストラリア",
  "濠洲",
  "オーストリア",
  "バハマ",
  "バーレーン",
  "バングラデシュ",
  "バルバドス",
  "ベルギー",
  "ベリーズ",
  "ブータン",
  "ボリビア",
  "ボスニヤ・",
  "ヘルツェゴビナ",
  "ボツワナ",
  "ブラジル",
  "ブルネイ",
  "ブルガリア",
  "カンボジア",
  "カメルーン",
  "カナダ",
  "中央アフリカ",
  "チャド",
  "チリ",
  "中国",
  "コロンビア",
  "コンゴ",
  "コスタリカ",
  "クロアチア",
  "キューバ",
  "キプロス",
  "チェコ",
  "デンマーク",
  "ドミニカ共和国",
  "エクアドル",
  "エジプト",
  "エルサルバドル",
  "赤道ギニア",
  "エストニア",
  "エチオピア",
  "フィジー",
  "フィンランド",
  "フランス",
  "ガンビア",
  "ドイツ",
  "ガーナ",
  "英国",
  "イギリス",
  "ギリシャ",
  "グリーンランド",
  "グレナダ",
  "グアテマラ",
  "ギニア",
  "ガイヤナ",
  "ハイチ",
  "オランダ",
  "ホンジェラス",
  "香港",
  "ハンガリー",
  "アイスランド",
  "インド",
  "インドネシア",
  "イラン",
  "イラク",
  "アイルランド",
  "イスラエル",
  "イタリア",
  "ジャマイカ",
  "日本",
  "ヨルダン",
  "ケニア",
  "コソボ",
  "クウェート",
  "ラオス",
  "ラトビア",
  "レバノン",
  "リベリア",
  "リビア",
  "リトアニア",
  "ルクセンブルク",
  "マカオ",
  "マダガスカル",
  "マラウィ",
  "マレーシア",
  "マルタ",
  "モルジブ",
  "モーリシャス",
  "メキシコ",
  "モルドバ",
  "モナコ",
  "蒙古",
  "モンゴル",
  "モロッコ",
  "モザンビーク",
  "ミャンマー",
  "ナミビア",
  "ネパール",
  "ニューギニア",
  "ニュージーランド",
  "ニカラグア",
  "ナイジェリア",
  "北朝鮮",
  "ノルウェー",
  "オーマン",
  "パキスタン",
  "パレスチナ",
  "パナマ",
  "パプアニューギニア",
  "パラグアイ",
  "ペルー",
  "フィリピン",
  "ポーランド",
  "ポルトガル",
  "カタール",
  "ルーマニア",
  "ロシア",
  "ルワンダ",
  "サウジアラビア",
  "スコットランド",
  "セネガル",
  "セイシェル",
  "シンガポール",
  "スロバキア",
  "スロベニア",
  "ソロモン諸島",
  "ソマリア",
  "南アフリカ",
  "韓国",
  "スペイン",
  "スリランカ",
  "スーダン",
  "スウェーデン",
  "スイス",
  "シリア",
  "タヒチ",
  "台湾",
  "タンザニア",
  "タイ",
  "トリニダード・トバゴ",
  "チュニジア",
  "トルコ",
  "ウガンダ",
  "ウクライナ",
  "アラブ首長国連邦",
  "ウルグアイ",
  "バチカン",
  "ベネズエラ",
  "ベトナム",
  "ウェールズ",
  "イエメン",
  "ザイール",
  "ザンビア",
  "ジンバブエ"
];