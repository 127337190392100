module["exports"] = [
  "Afonso",
  "Alexandre",
  "André",
  "Ângelo",
  "António",
  "Artur",
  "Benjamim",
  "Bernardo",
  "Bruno",
  "Carlos",
  "César",
  "Cristiano",
  "Daniel",
  "David",
  "Dinis",
  "Diogo",
  "Duarte",
  "Edgar",
  "Eduardo",
  "Elias",
  "Emanuel",
  "Fábio",
  "Feliciano",
  "Fernando",
  "Filipe",
  "Francisco",
  "Frederico",
  "Gabriel",
  "Gaspar",
  "Gil",
  "Gonçalo",
  "Guilherme",
  "Gustavo",
  "Hélio",
  "Henrique",
  "Hugo",
  "Igor",
  "Ígor",
  "Isac",
  "Ivan",
  "Ivo",
  "Jaime",
  "João",
  "Joaquim",
  "Jorge",
  "José",
  "Josué",
  "Júlio",
  "Leandro",
  "Leonardo",
  "Lourenço",
  "Lucas",
  "Luís",
  "Manel",
  "Manuel",
  "Marcelo",
  "Marco",
  "Marcos",
  "Mário",
  "Martim",
  "Mateus",
  "Matias",
  "Mauro",
  "Micael",
  "Miguel",
  "Moisés",
  "Norberto",
  "Nuno",
  "Paulo",
  "Pedro",
  "Rafael",
  "Raul",
  "Renato",
  "Ricardo",
  "Roberto",
  "Rodrigo",
  "Romeu",
  "Rúben",
  "Rui",
  "Salvador",
  "Samuel",
  "Sandro",
  "Santiago",
  "Sebastião",
  "Sérgio",
  "Simão",
  "Tiago",
  "Tomás",
  "Tomé",
  "Valentim",
  "Valter",
  "Vasco",
  "Vicente",
  "Vítor",
  "Xavier"
];
