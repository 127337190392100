module["exports"] = [
  "Հյուսիսային",
  "Արևելյան",
  "Հարավային",
  "Արևմտյան",
  "Հյուսիսարևելյան",
  "Հյուսիսարևմտյան",
  "Հարավարևելյան",
  "Հարավարևմտյան"
];
