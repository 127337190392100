module["exports"] = [
  "김",
  "이",
  "박",
  "최",
  "정",
  "강",
  "조",
  "윤",
  "장",
  "임",
  "오",
  "한",
  "신",
  "서",
  "권",
  "황",
  "안",
  "송",
  "류",
  "홍",
  '유',
  '전',
  '양',
  '고',
  '문',
  '손',
  '배',
  '백',
  '허',
  '노',
  '심',
  '하',
  '주',
  '구',
  '곽',
  '성',
  '차',
  '우',
  '진',
  '민',
  '나',
  '지',
  '엄',
  '변',
  '채',
  '원',
  '방',
  '천',
  '공',
  '현',
  '함',
  '여',
  '염',
  '석',
  '추',
  '도',
  '소',
  '설',
  '선',
  '마',
  '길',
  '연',
  '위',
  '표',
  '명',
  '기',
  '반',
  '라',
  '왕',
  '금',
  '옥',
  '육',
  '인',
  '맹',
  '제',
  '모',
  '남',
  '탁',
  '국',
  '어',
  '경',
  '은',
  '편',
  '용',
  '예',
  '봉',
  '사',
  '부',
  '황',
  '가',
  '복',
  '태',
  '목',
  '형',
  '피',
  '두',
  '감',
  '호',
  '제',
  '음',
  '빈',
  '동',
  '온',
  '사',
  '시',
  '범',
  '선',
  '좌',
  '팽',
  '승',
  '간',
  '상',
  '갈',
  '서',
  '단',
  '견',
  '당'
];
