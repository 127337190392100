module["exports"] = [
  "AG",
  "AR",
  "AI",
  "BL",
  "BS",
  "BE",
  "FR",
  "GE",
  "GL",
  "GR",
  "JU",
  "LU",
  "NE",
  "NW",
  "OW",
  "SG",
  "SH",
  "SZ",
  "SO",
  "TI",
  "TG",
  "UR",
  "VD",
  "VS",
  "ZG",
  "ZH",
];
