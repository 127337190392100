import React, { FC } from "react";
import styled from "styled-components/native";

import { t } from "../../i18n";

import Button from "../Button";
import Link from "../Link";
import FilterDialog from "./FilterDialog";

const filterHeaderMinHeight = "46px";

export const FilterHeader = styled.View`
  flex: 1;
  flex-direction: row;
  min-height: ${filterHeaderMinHeight};
  align-items: center;
`;

export const CenterContainer = styled.View`
  align-items: center;
`;

interface FilterInterface {
  showFilter: boolean;
  setShowFilter(newValue: boolean): void;
  setFilter(): void;
}

const Filter: FC<FilterInterface> = ({
  children,
  showFilter,
  setShowFilter,
  setFilter,
}) => {
  return (
    <FilterDialog show={showFilter} setShow={setShowFilter}>
      {children}
      <Button hasMarginBottom onPress={setFilter}>
        {t("general.filter.apply")}
      </Button>
      <CenterContainer>
        <Link isActive onPress={() => setShowFilter(false)}>
          {t("general.cancel")}
        </Link>
      </CenterContainer>
    </FilterDialog>
  );
};

export default Filter;
