module["exports"] = [
  "شورولت",
  "کادیلاک",
  "فورد",
  "کرایسلر",
  "دوج",
  "جیپ",
  "تسلا",
  "تویوتا",
  "هوندا",
  "نیسان",
  "آٔ‌ئودی",
  "مرسدس بنز",
  "بی ام و",
  "ولکس واگن",
  "پورشه",
  "جگوار",
  "استون مارتین",
  "لندرور",
  "بنتلی",
  "مینی",
  "رولز رویس",
  "فیات",
  "لامبورگینی",
  "مازراتی",
  "فراری",
  "بوگاتی",
  "کیا",
  "هیوندای"
];
