import React, { FC } from "react";
import { SeatInterface } from "../../../fake-data/mapData";
import Card from "../../styles/Card";
import Text from "../../styles/Text";
import styled from "styled-components/native";
import { iconSize, spacing } from "../../styles/theme";
import workspaceIllustration from "./mapIcons/workspace.png";
import workspaceRedIllustration from "./mapIcons/workspaceRed.png";
import lunchIllustration from "./mapIcons/lunch.png";
import lunchRedIllustration from "./mapIcons/lunchRed.png";
import meetingRoomIllustration from "./mapIcons/meetingRoom.png";
import meetingRoomRedIllustration from "./mapIcons/meetingRoomRed.png";
import { t } from "../../i18n";
import { SeatOnMap, SeatType } from "./index";

interface CardProps {
  isLast: boolean;
}

interface StyledImage {
  size: string;
}

const StyledCard = styled(Card)<CardProps>`
  padding: ${spacing.medium};
  flex-direction: row;
  width: calc(33% - ${spacing.medium});
  margin-right: ${({ isLast }) => (isLast ? 0 : spacing.medium)};
  margin-bottom: ${spacing.medium};
`;

const StyledImage = styled.Image<StyledImage>`
  ${props => props?.size};
  margin-right: ${spacing.medium};
  width: ${iconSize.medium};
  height: ${iconSize.medium};
`;

interface CleaningSeatCardProps extends SeatOnMap {
  isLastInRow: boolean;
  size?: string;
}

const CleaningSeatCard: FC<CleaningSeatCardProps> = ({
  name,
  seatType,
  dirty,
  isLastInRow,
  size = iconSize.large
}) => {
  const getIconForSeat = (type: SeatType, dirty?: boolean) => {
    switch (seatType) {
      case "lunch":
        return !dirty ? lunchIllustration : lunchRedIllustration;
      case "meeting":
        return !dirty ? meetingRoomIllustration : meetingRoomRedIllustration;
      default:
        return !dirty ? workspaceIllustration : workspaceRedIllustration;
    }
  };

  const cleanText = !dirty
    ? t("admin.map.cleaning.clean")
    : t("admin.map.cleaning.dirty");

  return (
    <StyledCard isLast={isLastInRow}>
      <StyledImage size={size} source={getIconForSeat(seatType, dirty)} />
      <Text>
        {name} - {cleanText}
      </Text>
    </StyledCard>
  );
};

export default CleaningSeatCard;
