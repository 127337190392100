module["exports"] = [
  "Pacifik/Midway",
  "Pacifik/Pago_Pago",
  "Pacifik/Honolulu",
  "Amerika/Juneau",
  "Amerika/Los_Angeles",
  "Amerika/Tijuana",
  "Amerika/Denver",
  "Amerika/Phoenix",
  "Amerika/Chihuahua",
  "Amerika/Mazatlan",
  "Amerika/Chicago",
  "Amerika/Regina",
  "Amerika/Mexico_City",
  "Amerika/Monterrey",
  "Amerika/Guatemala",
  "Amerika/New_York",
  "Amerika/Indiana/Indianapolis",
  "Amerika/Bogota",
  "Amerika/Lima",
  "Amerika/Lima",
  "Amerika/Halifax",
  "Amerika/Caracas",
  "Amerika/La_Paz",
  "Amerika/Santiago",
  "Amerika/St_Johns",
  "Amerika/Sao_Paulo",
  "Amerika/Argentina/Buenos_Aires",
  "Amerika/Guyana",
  "Amerika/Godthab",
  "Atlantik/South_Georgia",
  "Atlantik/Azori",
  "Atlantik/Cape_Verde",
  "Europa/Dublin",
  "Europa/London",
  "Europa/Lisbon",
  "Europa/London",
  "Africa/Casablanca",
  "Africa/Monrovia",
  "Etc/UTC",
  "Europa/Beograd",
  "Europa/Bratislava",
  "Europa/Budapest",
  "Europa/Ljubljana",
  "Europa/Prague",
  "Europa/Sarajevo",
  "Europa/Skopje",
  "Europa/Varšava",
  "Europa/Zagreb",
  "Europa/Brussels",
  "Europa/Copenhagen",
  "Europa/Madrid",
  "Europa/Paris",
  "Europa/Amsterdam",
  "Europa/Berlin",
  "Europa/Rim",
  "Europa/Stockholm",
  "Europa/Beč",
  "Africa/Alžir",
  "Europa/Bukurešt",
  "Africa/Kairo",
  "Europa/Helsinki",
  "Europa/Kiev",
  "Europa/Riga",
  "Europa/Sofia",
  "Europa/Tallinn",
  "Europa/Vilnius",
  "Europa/Atena",
  "Europa/Istanbul",
  "Europa/Minsk",
  "Azija/Jeruzalem",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Europa/Moskva",
  "Azija/Kuwait",
  "Azija/Riyadh",
  "Africa/Nairobi",
  "Azija/Baghdad",
  "Azija/Tehran",
  "Azija/Muscat",
  "Azija/Baku",
  "Azija/Tbilisi",
  "Azija/Yerevan",
  "Azija/Kabul",
  "Azija/Yekaterinburg",
  "Azija/Karachi",
  "Azija/Tashkent",
  "Azija/Kolkata",
  "Azija/Kathmandu",
  "Azija/Dhaka",
  "Azija/Colombo",
  "Azija/Almaty",
  "Azija/Novosibirsk",
  "Azija/Rangoon",
  "Azija/Bangkok",
  "Azija/Jakarta",
  "Azija/Krasnoyarsk",
  "Azija/Shanghai",
  "Azija/Chongqing",
  "Azija/Hong_Kong",
  "Azija/Urumqi",
  "Azija/Kuala_Lumpur",
  "Azija/Singapore",
  "Azija/Taipei",
  "Australia/Perth",
  "Azija/Irkutsk",
  "Azija/Ulaanbaatar",
  "Azija/Seoul",
  "Azija/Tokyo",
  "Azija/Yakutsk",
  "Australia/Darwin",
  "Australia/Adelaide",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Brisbane",
  "Australia/Hobart",
  "Azija/Vladivostok",
  "Pacifik/Guam",
  "Pacifik/Port_Moresby",
  "Azija/Magadan",
  "Pacifik/Noumea",
  "Pacifik/Fiji",
  "Azija/Kamchatka",
  "Pacifik/Majuro",
  "Pacifik/Auckland",
  "Pacifik/Tongatapu",
  "Pacifik/Fakaofo",
  "Pacifik/Apia"
];
