import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "../../../routing";

import {
  InlineResponse400Errors,
  DoorWithoutId,
  Saltolock,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";
import { t } from "../../../i18n";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import LabeledInput, { InputWrapper, LabeledInputWrapper, LabelWrapper } from "../../../components/LabeledInput";
import LoadOverlay from "../../../components/LoadOverlay";
import Select, { SelectItemInterface } from "../../../components/Select";

import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Title from "../../../styles/Title";
import Input, { InputHelpText } from "../../../styles/Input";

import { useAuth } from "../../../context/authContext";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import InputValidationErrors from "../../../components/InputValidationErrors";
import Checkbox from "../../../components/Checkbox";

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

export const DoorUpdate: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [saltoLockId, setSaltoLockId] = useState("");
  const [mapId, setMapId] = useState("");
  const [locationId, setLocationId] = useState("");

  const [maps, setMaps] = useState<SelectItemInterface[]>([]);
  const [locations, setLocations] = useState<SelectItemInterface[]>([]);
  const [saltoLocks, setSaltoLocks] = useState<SelectItemInterface[]>([]);
  const [saltoLockList, setSaltoLockList] = useState<Saltolock[]>([]);
  const [building, setBuilding] = useState(false);

  const { userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();

  const userCompany = userState?.companyIds[0];

  const { id } = useParams();
  
  useEffect(() => {
    if (userCompany) {
      const fetchData = async () => {
        const doorResponse = await handleRequest(client.getDoor(id));
        if (!doorResponse || !doorResponse.data.result) {
          return;
        }
        const mapResponse = await handleRequest(
          client.listMaps(userCompany)
        );
        if (!mapResponse || !mapResponse.data.result) {
          return;
        }        
        const locationResponse = await handleRequest(
          client.listLocations(userCompany)
        );
        if (!locationResponse || !locationResponse.data.result) {
          return;
        }

        const mapResponseItems = mapResponse.data.result.items;
        const locationResponseItems = locationResponse.data.result.items;
        const {
          name,
          mapId,
          locationId,
          saltoLockId,
        } = doorResponse.data.result.door;
        const mapItems = mapResponseItems.map((map) => ({
          label: map.name,
          value: map.id,
          isActive: map.id === mapId,
        }));        
        const locationItems = locationResponseItems.map((location) => ({
          label: location.name,
          value: location.id,
          isActive: location.id === locationId,
        }));
        setMaps(mapItems);
        setLocations(locationItems)
        setMapId(mapId || "");
        setBuilding(!mapId);
        setLocationId(locationId);
        setName(name);
        setSaltoLockId(saltoLockId);
      };

      fetchData();
    }
  }, [client, userCompany, handleRequest, id, setName]);

  useEffect(() => {
    if (!userCompany) {
      return;
    }
    const fetchMaps = async () =>
      await handleRequest(client.listMaps(userCompany));

    fetchMaps().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      setMaps(
        items.map((item) => ({
          label: item.name,
          value: item.id,
          isActive: item.id === mapId,
        }))
      );
    });    
    
    const fetchLocations = async () =>
      await handleRequest(client.listLocations(userCompany));

    fetchLocations().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;
      setLocations(
        items.map((item) => ({
          label: item.name,
          value: item.id,
          isActive: item.id === locationId,
        }))
      );
    });

    const fetchSaltoDoors = async () =>
    await handleRequest(client.listSaltolocks(userCompany));

    fetchSaltoDoors().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items } = result.data.result;

      setSaltoLockList(items)
      setSaltoLocks(
        items.map((item) => ({
          label: item.name,
          value: item.id,
          isActive: item.id === saltoLockId,
        }))
      );
    });


  }, [userCompany, client, handleRequest, mapId, locationId, saltoLockId]);

  const updateMap = useCallback(
    (newValue) => {
      setBuilding(false);
      setMapId(newValue[0].value);
    },
    [setMapId]
  );

  const updateBuilding = useCallback(
    (newValue) => {
      setBuilding(newValue);
      setMapId("");
    },
    [setMapId]
  );

  const updateLocations = useCallback(
    (newValue) => {
      setLocationId(newValue[0].value);
    },
    [setLocationId]
  );

  const updateSaltoLock = useCallback(
    (newValue) => {
      setSaltoLockId(newValue[0].value);
    },
    [setSaltoLockId]
  );

  const formIsFilledIn = useCallback(
    () => {
      return !!name &&
      !!locationId &&
      !!saltoLockId && (building || !!mapId)
    },
    [name, locationId, saltoLockId, building, mapId]
  );

  const submitForm = useCallback(async () => {
    const data: DoorWithoutId = {
      name,
      mapId,
      locationId,
      saltoIqId: saltoLockList.find(lock => lock.id === saltoLockId)?.saltoIqId || '',
      saltoLockId,
    };
    try {
      const success = await handleRequest(client.updateDoor(id, data));
      if (success) {
        history.goBack();
      }
    } catch (error) {
      console.error(error);
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [name, mapId, locationId, saltoLockList, saltoLockId, handleRequest, client, id, history]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <Title hasMarginBottom>{t("admin.salto.create.form.editTitle")}</Title>
          <LabeledInput label={t("admin.salto.create.form.name")} required>
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.salto.create.form.name") + " *"}
            />
          </LabeledInput>
          <LabeledInput
            label={t("admin.salto.create.form.location")}
            style={{ zIndex: 3 }}
            required
          >
            <Select
              required
              isMultiSelect={false}
              items={locations}
              onChange={updateLocations}
              placeholder={t("admin.salto.create.form.location")}
            />
          </LabeledInput>          
          <LabeledInput
            label={t("admin.salto.create.form.floorplan")}
            style={{ zIndex: 2 }}
            required
          >
            <Select
              required
              isMultiSelect={false}
              items={maps}
              onChange={updateMap}
              placeholder={t("admin.salto.create.form.floorplan")}
            />
          </LabeledInput>

          <LabeledInputWrapper>
            <LabelWrapper />

          <InputWrapper>
              <Checkbox
                label={t("admin.salto.create.form.building")}
                onChange={updateBuilding}
                checked={building}
              />
              <InputHelpText>
               {t("admin.salto.create.form.buildingText")}
              </InputHelpText>
            </InputWrapper>
          </LabeledInputWrapper>


        <LabeledInput
            label={t("admin.salto.create.form.saltoLock")}
            style={{ zIndex: 3}}
            required
          >
            <Select
              required
              isMultiSelect={false}
              items={saltoLocks}
              onChange={updateSaltoLock}
              placeholder={t("admin.salto.create.form.saltoLock")}
            />         
          </LabeledInput>


          <Button
            hasMarginBottom
            disabled={!formIsFilledIn()}
            onPress={submitForm}
          >
          {t("admin.salto.create.form.submitUpdate")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
      </Container>
    </>
  );
};

