import * as Localization from "expo-localization";
import dateEN from "date-fns/locale/en-GB";
import dateNL from "date-fns/locale/nl";
import dateDE from "date-fns/locale/de";
import i18n from "i18n-js";
import en from "./translations/en.json";
import nl from "./translations/nl.json";
import de from "./translations/de.json";
import { getFromStorage, storageKeys } from "./services/asyncStorageService";
import { registerLocale } from "react-datepicker";

registerLocale("en", dateEN);
registerLocale("nl", dateNL);
registerLocale("de", dateDE);

const defaultLanguage = "en";

const languages = {
  en,
  nl,
  de,
};

const supportedLanguages = Object.keys(languages);

// Set the key-value pairs for the different languages you want to support.
i18n.translations = languages;

getFromStorage(storageKeys.userLanguage).then((language) => {
  let locale = Localization.locale; // Device locale

  if (language) {
    locale = language;
  }
  i18n.locale = supportedLanguages.includes(locale) ? locale : defaultLanguage;
});

export default i18n;

export const t = i18n.t;
