import React, { FunctionComponent } from "react";
import { Picker } from "@react-native-picker/picker";
import styled, { css } from "styled-components/native";
import { PickerProps } from "@react-native-picker/picker/typings/Picker";
import { chevron, color, font, spacing, text } from "../../styles/theme";
import chevronDownIconSecondary from "../../assets/icons/chevron_down_secondary.svg";
import { t } from "../../i18n";

export const Icon = styled.Image`
  width: ${chevron.width};
  height: ${chevron.height};
  margin-left: ${spacing.small};
  position: absolute;
  right: ${spacing.medium};
  top: 24px;
`;

interface WrapperProps {
  noMarginBottom?: boolean;
}

const Wrapper = styled.View<WrapperProps>`
  position: relative;
  border-color: ${color.greyLightest};
  border-width: 1px;
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? "0" : "16px")};
`;

const Input = styled(Picker)`
  font-size: ${text.extraSmall.size};
  font-family: ${font.defaultRegular};
  padding-top: ${spacing.medium};
  padding-bottom: ${spacing.medium};
  padding-left: ${spacing.medium};
  padding-right: ${spacing.medium};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: ${color.white};
  font-family: ${font.defaultRegular};
  font-size: ${text.extraSmall.size};
`;

interface PickerInputProps extends PickerProps {
  items: Array<{
    label: string;
    value: string;
  }>;
  includeChoose?: boolean;
  noMarginBottom?: boolean;
}

const PickerInput: FunctionComponent<PickerInputProps> = ({
  items,
  includeChoose,
  noMarginBottom,
  ...rest
}) => {
  return (
    <Wrapper noMarginBottom={noMarginBottom}>
      <Input {...rest}>
        {includeChoose && (
          <Picker.Item label={t("general.choose")} value={""} />
        )}
        {items.map((item) => (
          <Picker.Item key={item.value} label={item.label} value={item.value} />
        ))}
      </Input>
      <Icon
        source={{
          uri: chevronDownIconSecondary.toString(),
        }}
      />
    </Wrapper>
  );
};

export default PickerInput;
