import styled, { css } from "styled-components/native";
import { spacing } from "../theme";
import Card from "../Card";

export const listFlexProps = css`
  display: flex;
  flex-direction: column;
  align-content: stretch;
`;

export const List = styled(Card)<{ noShadow: boolean; noPadding: boolean; }>`
  padding: ${spacing.medium} ${spacing.large};

  ${listFlexProps};

  ${(props) => {
    if (props.noShadow) {
      return css`
          box-shadow: none;
        `
    }
  }}

  ${(props) => {
    if (props.noPadding) {
      return css`
          padding: 0;
        `
    }
  }}
`;
