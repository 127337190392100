import React, { FC } from "react";
import Dialog from "./index";
import { SmallTitle } from "../../styles/Title";
import { t } from "../../i18n";
import Button, { ButtonText } from "../Button";
import { color, spacing } from "../../styles/theme";
import styled from "styled-components/native";

export const DialogInnerWrapper = styled.View`
  margin-top: ${spacing.medium};
  flex-direction: row;
  justify-content: center;
`;

export const ButtonWrapper = styled.View`
  margin-left: ${spacing.medium};
`;

interface ConfirmDialogInterface {
  confirm(): void;
  showDialog: boolean;
  setShowDialog(newValue: boolean): void;
  text: string;
}

const ConfirmDialog: FC<ConfirmDialogInterface> = ({
  confirm,
  showDialog,
  setShowDialog,
  text,
}) => {
  return (
    <Dialog show={showDialog} setShow={setShowDialog}>
      <SmallTitle hasMarginBottom>{text}</SmallTitle>
      <DialogInnerWrapper>
        <Button onPress={() => setShowDialog(false)}>
          <ButtonText>{t("general.cancel")}</ButtonText>
        </Button>
        <ButtonWrapper>
          <Button
            backgroundHoverColor={color.primary}
            backgroundColor={color.primaryLight}
            onPress={confirm}
            color={color.primary}
            textHoverColor={color.primaryLight}
          >
            {t("general.yes")}
          </Button>
        </ButtonWrapper>
      </DialogInnerWrapper>
    </Dialog>
  );
};

export default ConfirmDialog;
