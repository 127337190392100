module["exports"] = [
  "Afghanistan",
  "Albanie",
  "Algérie",
  "Andorre",
  "Angola",
  "Antigua-et-Barbuda",
  "Argentine",
  "Arménie",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreïn",
  "Bangladesh",
  "Barbade",
  "Biélorussie",
  "Belgique",
  "Belize",
  "Bénin",
  "Bhoutan",
  "Bolivie",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brésil",
  "Brunei",
  "Bulgarie",
  "Burkina Faso",
  "Burundi",
  "Cambodge",
  "Cameroun",
  "Canada",
  "Cap-Vert",
  "République centrafricaine",
  "Tchad",
  "Chili",
  "Chine",
  "Colombie",
  "Comores",
  "Costa Rica",
  "Côte d’Ivoire",
  "Croatie",
  "Cuba",
  "Chypre",
  "République tchèque",
  "République démocratique du Congo",
  "Danemark",
  "Djibouti",
  "Dominique",
  "République dominicaine",
  "Timor oriental",
  "Équateur",
  "Égypte",
  "Salvador",
  "Guinée équatoriale",
  "Érythrée",
  "Estonie",
  "Éthiopie",
  "Fidji",
  "Finlande",
  "France",
  "Gabon",
  "Gambie",
  "Géorgie",
  "Allemagne",
  "Ghana",
  "Grèce",
  "Grenade",
  "Guatemala",
  "Guinée",
  "Guinée-Bissau",
  "Guyane",
  "Haïti",
  "Honduras",
  "Hongrie",
  "Islande",
  "Inde",
  "Indonésie",
  "Iran",
  "Irak",
  "Irlande",
  "Israël",
  "Italie",
  "Jamaïque",
  "Japon",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Koweït",
  "Kirghizistan",
  "Laos",
  "Lettonie",
  "Liban",
  "Lesotho",
  "Liberia",
  "Libye",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaisie",
  "Maldives",
  "Mali",
  "Malte",
  "Îles Marshall",
  "Mauritanie",
  "Maurice",
  "Mexique",
  "Micronésie",
  "Moldavie",
  "Monaco",
  "Mongolie",
  "Monténégro",
  "Maroc",
  "Mozambique",
  "Birmanie",
  "Namibie",
  "Nauru",
  "Népal",
  "Pays-Bas",
  "Nouvelle-Zélande",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Corée du Nord",
  "Norvège",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pérou",
  "Philippines",
  "Pologne",
  "Portugal",
  "Qatar",
  "République du Congo",
  "Macédoine",
  "Roumanie",
  "Russie",
  "Rwanda",
  "Saint-Christophe-et-Niévès",
  "Sainte-Lucie",
  "Saint-Vincent-et-les-Grenadines",
  "Samoa",
  "Saint-Marin",
  "São Tomé-et-Principe",
  "Arabie saoudite",
  "Sénégal",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Îles Salomon",
  "Somalie",
  "Afrique du Sud",
  "Corée du Sud",
  "Soudan du Sud",
  "Espagne",
  "Sri Lanka",
  "Soudan",
  "Suriname",
  "Swaziland",
  "Suède",
  "Suisse",
  "Syrie",
  "Tadjikistan",
  "Tanzanie",
  "Thaïlande",
  "Togo",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turquie",
  "Turkménistan",
  "Tuvalu",
  "Ouganda",
  "Ukraine",
  "Émirats arabes unis",
  "Royaume-Uni",
  "États-Unis",
  "Uruguay",
  "Ouzbékistan",
  "Vanuatu",
  "Venezuela",
  "Viêt Nam",
  "Yémen",
  "Zambie",
  "Zimbabwe"
];