module["exports"] = {
  "descriptor": [
    "Glavni",
    "Senior",
    "Korporativni",
    "Nacionalni",
    "Regionalni",
    "Okružni",
    "Središnji",
    "Globalni",
    "Međunarodni",
    "Operativni"
  ],
  "level": [
    "programski",
    "brand",
    "sigurnosni",
    "marketing",
    "implementacijski",
    "integracijski",
    "funkcionalni",
    "tržišni",
    "grupni",
    "aplikacijski",
    "optimizacijski",
    "operativni",
    "infrastrukturni",
    "komercijalni",
    "prodajni",
    "web",
    "financijski",
    "podatkovni",
    "logistički",
    "kreativni",
    "računovodstveni",
    "financijski"
  ],
  "job": [
    "voditelj",
    "suradnik",
    "službenik",
    "menadžer",
    "inženjer",
    "specijalist",
    "direktor",
    "koordinator",
    "administrator",
    "arhitekt",
    "analist",
    "dizajner",
    "planer",
    "ekspert",
    "savjetnik",
    "organizator",
    "tehničar",
    "konzultant",
    "asistent",
    "agent",
    "predstavnik",
    "referent",
    "strateg"
  ]
};
