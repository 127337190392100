import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Language,
  UserStatus
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";
import { Image, Linking, ScrollView, View, Clipboard } from "react-native";
import Button, { ButtonText, ButtonWrapper } from "../../../components/Button";
import Container from "../../../components/Container";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import UsersFilter, { UserFilter } from "../../../components/Filter/UsersFilter";
import Link from "../../../components/Link";
import LoadOverlay from "../../../components/LoadOverlay";
import { getUserPages } from "../../../components/Navbar/AdminNavBarContent";
import Notification from "../../../components/Notification";
import PageSelector from "../../../components/PageSelector";
import Paginator, { takeSize } from "../../../components/Paginator";
import SearchInput from "../../../components/SearchInput";
import Table, { TableContent } from "../../../components/Table";
import microsoftIcon from "../../../assets/icons/microsoft.svg";
import surfconextIcon from '../../../assets/icons/surfconext_logo.svg';
import copyEmailIcon from "../../../assets/icons/icon-copy-email.svg";
import { createHeaderItem, HeaderItem } from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSearch } from "../../../hooks/useSearch";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { AmountIndicatorSecondary } from "../../../styles/AmountIndicator";
import { OverviewDeleteModalButtonWrapper, OverviewHeader, OverviewHeaderInnerRight } from "../../../styles/Overview";
import { ExtraSmallText } from "../../../styles/Text";
import { color, spacing } from "../../../styles/theme";
import Title from "../../../styles/Title";
import { ArrayElement, ThenArg } from "../../../types";
import Toggle from "../../../components/Toggle";
import { saveAs } from "file-saver";
import { DeleteIcon, MailIcon } from "../../../components/Icons";
import Dialog from "../../../components/Dialog";
import SelectItem from "../../../components/Select/SelectItem";

const IconWrapper = styled.View`
  width: 30px;
`;

const CopyIcon = styled.Image`
  margin-right: ${spacing.small};
  color: ${color.grey};
`;

const columnSizes = [3, 3, 2, 1, 1, 1, 1];

const UserOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.user.overview.name"), true, "firstName"),
    createHeaderItem(t("admin.user.overview.email"), true, "email"),
    createHeaderItem(t("admin.user.overview.department"), false),
    createHeaderItem(t("admin.user.overview.status"), true, "active"),
    createHeaderItem(t("admin.user.overview.connections"), false),
    createHeaderItem(t("admin.user.overview.language"), true, "language"),
    //createHeaderItem("", false),
  ];
  const { userState } = useAuth();
  const client = useClient();
  const history = useHistory();
  const { debouncedSearchTerm, setDebouncedSearchTerm } = useSearch();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listUsers>[1]>(tableHeaders);

  const userCompany = userState?.companyIds[0];

  type UserItem = ArrayElement<ThenArg<ReturnType<typeof client.listUsers>>["data"]["result"]["items"]>;

  const [data, setData] = useState<Array<UserItem>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showDeleteMultipleMessage, setShowDeleteMultipleMessage] =
    useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<UserItem>();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<UserFilter>({});
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [skipParam, setSkipParam] = useState<number>(0);
  const [showDeleteMultipleModal, setShowDeleteMultipleModal] = useState(false);
  const [showNotAllDeletedMessage, setShowNotAllDeletedMessage] =
    useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsActive, setTotalItemsActive] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showSentInvitationsMessage, setShowSentInvitationsMessage] =
    useState(false);

  const fetchListItems = useCallback(async () => {
    if (
      userCompany &&
      debouncedSearchTerm === (Boolean(searchTerm) ? searchTerm : undefined)
    ) {
      return handleRequest(
        client.listUsers(
          userCompany,
          sortField,
          filter.isVisible,
          filter.active,
          filter.language as Language,
          filter.department,
          sortOrder,
          debouncedSearchTerm,
          takeSize,
          skipParam
        )
      );
    }
  }, [
    userCompany,
    debouncedSearchTerm,
    searchTerm,
    handleRequest,
    client,
    sortField,
    filter,
    sortOrder,
    skipParam,
  ]);

  const fetch = useCallback(() => {
    fetchListItems().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setTableData(createTableData(items));
        setData(items);
        setTotalItems(total);
      }
    });
  }, [fetchListItems]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const setUserStatus = useCallback(
    async (status, id) => {
      await handleRequest(
        client.updateUser(id, {
          status,
        }),
        false
      );
      fetch();
    },
    [fetch]
  );

  function createTableData(items: UserItem[]) {
    const tableData: TableContent[] = items.map(
      ({
        firstName,
        infix,
        lastName,
        email,
        language,
        id,
        status,
        ssoProviders,
        userGroups,
        _operations,
      }) => {
        const fullName = infix
          ? `${firstName} ${infix} ${lastName}`
          : `${firstName} ${lastName}`;

        const copyEmail = ()=> Clipboard.setString(email);

        const data: ReactElement[] = [
          <ExtraSmallText key="fullname" mediumWeight>
            {fullName}
          </ExtraSmallText>,
          <ExtraSmallText
            onPress={(copyEmail)}
          >
            {email}
            <CopyIcon
              style={{ width: 14, height: 16, marginLeft: 5, top: 4 }}
              source={copyEmailIcon}
            />
          </ExtraSmallText>,
          <ExtraSmallText key="department" mediumWeight>
            {userGroups.map((group) => group.label).join(", ")}
          </ExtraSmallText>,
          <Toggle
            key="status"
            toggleValue={Boolean(status === "active")}
            onChange={(toggled) =>
              setUserStatus(toggled ? "active" : "inactive", id)
            }
          />,
          <View key="connections">
            {ssoProviders.map((ssoProvider) => (
              <>
                {ssoProvider.provider === "microsoft_graph" && (
                  <Image
                    source={{
                      uri: microsoftIcon.toString(),
                      width: 22,
                      height: 22,
                    }}
                  />
                )}
                {ssoProvider.provider === "surfconext" && (
                  <Image
                    source={{
                      uri: surfconextIcon.toString(),
                      width: 40,
                      height: 20,
                    }}
                  />
                )}
              </>
            ))}
          </View>,
          <ExtraSmallText key="language">{language}</ExtraSmallText>,
        ];
        return {
          data,
          id: id?.toString() || "",
          canDelete: _operations?.canDelete,
          cannotDeleteDescription: _operations?.cannotDeleteDescription,
          canEdit: true,
        };
      }
    );
    return tableData;
  }


  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(client.deleteUser(itemSelected?.id || ""));

    if (response && response.status === 204) {
      fetch();
      setShowDeleteMessage(true);
    }
  }, [
    client,
    createTableData,
    fetchListItems,
    setData,
    itemSelected,
    setShowDeleteModal,
  ]);

  const sendInvitations = useCallback(async () => {
    if (selectedIds.length === 0) {
      return;
    }
    try {
      await handleRequest(
        client.sendUsersInvitations({
          userIds: selectedIds,
        }),
        false
      );
      setShowSentInvitationsMessage(true);
    } catch (error) {
      console.error(error);
    }
  }, [selectedIds, handleRequest, client, setShowSentInvitationsMessage]);

  const exportUsers = useCallback(async () => {
    if (!userCompany) {
      return;
    }

    const response = await handleRequest(
      client.listUsersExport(
        userCompany,
        {
          responseType: "blob",
        },
      ),
      false,
    );

    if (!response) {
      return;
    }

    saveAs(response.data, `${t("admin.company.overview.users")}.xlsx`);
  }, [userCompany]);

  const updateSearch = (text: string) => {
    setSearchTerm(text);
    setDebouncedSearchTerm(text);
    setSkipParam(0);
  };

  const handleRowSelection = useCallback((ids: string[], isAllSelected?: boolean) => {
    if (isAllSelected) {
      const ids = data.map((item) => item.id).filter((id) => id !== null) as string[];
      setSelectedIds(ids);
    } else {
      setSelectedIds(ids);
    }
  }, [setSelectedIds, data]);

  const deleteSelected = useCallback(async () => {
    setShowDeleteMultipleModal(false);
    const errors = (
      await Promise.all(
        selectedIds.map(async (selectedId) => {
          const response = await handleRequest(
            client.deleteUser(selectedId)
          );

          if (!response || response.status !== 204) {
            return selectedId;
          }
          return undefined;
        })
      )
    ).filter((error) => typeof error !== "undefined");

    fetch();
    if (errors.length > 0) {
      setShowNotAllDeletedMessage(true);
    } else {
      setShowDeleteMultipleMessage(true);
    }
  }, [client, fetch, handleRequest, selectedIds]);
  return (
    <>
      {isLoading && <LoadOverlay/>}
      <Container>

        <OverviewHeader>
          <Title>{t("admin.user.overview.title")}</Title>
          <OverviewHeaderInnerRight>
            <SearchInput
              wrapperStyle={`margin-right: ${spacing.medium};`}
              placeholder={t("general.filter.search")}
              onChangeText={updateSearch}
              value={searchTerm}
            />
            <Button
              hasShadow
              backgroundColor={color.white}
              backgroundHoverColor={color.white}
              color={color.darker}
              onPress={() => setShowFilterModal(true)}
              indicator={
                <AmountIndicatorSecondary>
                  {numberOfFilters}
                </AmountIndicatorSecondary>
              }
            >
              {t("general.filter.filter")}
            </Button>
            <ButtonWrapper>
              <Button
                onPress={() => history.push("/admin/user-overview/create")}
              >
                {t("admin.user.create.addButton")}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                onPress={() => history.push("/admin/user-overview/import")}
              >
                {t("admin.user.overview.importButton")}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                onPress={exportUsers}
              >
                {t("admin.user.overview.exportButton")}
              </Button>
            </ButtonWrapper>
          </OverviewHeaderInnerRight>
        </OverviewHeader>
        <PageSelector pages={getUserPages()} />
        {showNotAllDeletedMessage && (
          <Notification
            type="danger"
            closeNotification={() => setShowNotAllDeletedMessage(false)}
          >
            {t("general.notAllDeletedWarning")}
          </Notification>
        )}
        {Boolean(error) && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        )}
        {showDeleteMultipleMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMultipleMessage(false)}
          >
            {t("general.deleteMultipleSuccess")}
          </Notification>
        )}
        {showSentInvitationsMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowSentInvitationsMessage(false)}
          >
            {t("admin.user.overview.invitationsSent")}
          </Notification>
        ) : null}
        <ScrollView >
        {!isLoading && (
          <Table
            tableId="user_overview"
            selectable
            hideColumn
            headerItems={headerItems}
            tableContent={tableData}
            columnSizes={columnSizes}
            sortList={onSortList}
            sortOrder={sortOrder}
            sortField={sortField}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/user-overview/update/${id}`)
            }
            onDeleteItem={openDeleteModal}
            onChangeSelectedIds={handleRowSelection}
            recordsCount={totalItems}
            recordsCountActive={totalItems}
          >
            {selectedIds.length > 0 && (
              <>
                <Link isActive onPress={sendInvitations}>
                  <MailIcon /> {t('admin.user.overview.sendInvitations')}
                </Link>
                <Link
                  isActive={selectedIds.length > 0}
                  disabled={selectedIds.length === 0}
                  marginLeft={20}
                  onPress={() => selectedIds.length > 0 && setShowDeleteMultipleModal(true)}
                >
                  <DeleteIcon /> {t('general.delete')}
                </Link>
              </>
            )}
          </Table>
        )}
        </ScrollView>
        {(totalItems > 0 && !isLoading) && (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        )}
      </Container>

      <UsersFilter
        showFilter={showFilterModal}
        setShowFilter={setShowFilterModal}
        onFilter={(filter, size) => {
          setNumberOfFilters(size);
          setFilter(filter);
        }}
      />

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.user.delete.warning", {
          name: itemSelected?.firstName,
        })}
      />
      <Dialog
        show={showDeleteMultipleModal}
        setShow={setShowDeleteMultipleModal}
      >
        <Title hasMarginBottom>
          {selectedIds.length === 1 && t("admin.reservation.delete.warning")}
          {selectedIds.length !== 1 &&
            t("admin.reservation.delete.multipleWarning")}
        </Title>
        <OverviewDeleteModalButtonWrapper>
          <Button onPress={() => setShowDeleteMultipleModal(false)}>
            <ButtonText>{t("general.cancel")}</ButtonText>
          </Button>
          <ButtonWrapper>
            <Button
              backgroundHoverColor={color.primary}
              backgroundColor={color.primaryLight}
              onPress={deleteSelected}
              color={color.primary}
              textHoverColor={color.primaryLight}
            >
              {t("general.yes")}
            </Button>
          </ButtonWrapper>
        </OverviewDeleteModalButtonWrapper>
      </Dialog>
    </>
  );
};

export default UserOverview;
