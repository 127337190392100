module["exports"] = [
  "Aston Martin",
  "Audi",
  "Bentley",
  "BMW",
  "Bugatti",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Dodge",
  "Ferrari",
  "Fiat",
  "Ford",
  "Honda",
  "Hyundai",
  "Jaguar",
  "Jeep",
  "Kia",
  "Lamborghini",
  "Land Rover",
  "Maserati",
  "Mazda",
  "Mercedes Benz",
  "Mini",
  "Nissan",
  "Polestar",
  "Porsche",
  "Rolls Royce",
  "Smart",
  "Tesla",
  "Toyota",
  "Volkswagen",
  "Volvo"
];
