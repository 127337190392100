import { useEffect, useState } from "react";
import {
  getFromStorage,
  removeFromStorage,
  saveInStorage,
  storageKeys,
} from "../services/asyncStorageService";
import { useAuth } from "../context/authContext";
import { useHistory } from "../routing";

interface UseImpersonationInterface {
  isImpersonating: boolean;
  impersonate: (refreshToken: string) => Promise<boolean>;
  stopImpersonating: () => Promise<boolean>;
}

export const useImpersonation = (): UseImpersonationInterface => {
  const { refreshTokenLogin } = useAuth();
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const initializeIsImpersonating = async () => {
      setIsImpersonating(
        Boolean(await getFromStorage(storageKeys.impersonationRefreshToken))
      );
    };

    initializeIsImpersonating();
  }, []);

  const impersonate = async (refreshToken: string) => {
    const currentToken = await getFromStorage(storageKeys.refreshToken);
    if (currentToken) {
      const hasRefreshToken = await getFromStorage(
        storageKeys.impersonationRefreshToken
      );
      if (!hasRefreshToken) {
        await saveInStorage(
          storageKeys.impersonationRefreshToken,
          currentToken
        );
      }

      await refreshTokenLogin(refreshToken);
      setIsImpersonating(true);

      window.location.pathname = "/";

      return true;
    }
    return false;
  };

  const stopImpersonating = async () => {
    const refreshToken = await getFromStorage(
      storageKeys.impersonationRefreshToken
    );

    if (refreshToken) {
      await refreshTokenLogin(refreshToken);
      await removeFromStorage(storageKeys.impersonationRefreshToken);

      setIsImpersonating(false);

      window.location.pathname = "/";

      return true;
    }

    return false;
  };

  return {
    impersonate,
    isImpersonating,
    stopImpersonating,
  };
};
