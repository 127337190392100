import React from "react";
import styled from "styled-components/native";
import { color } from "./src/styles/theme";
import {
  useFonts,
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
} from "@expo-google-fonts/poppins";

import Routes from "./src/routing/Routes";

// Context
import { AuthProvider } from "./src/context/authContext";

const AppWrapper = styled.View`
  display: flex;
  background-color: ${color.background};
  flex-grow: 1;
`;

export default function App(): JSX.Element {
  const [fontsLoaded] = useFonts({
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
  });
  return (
    <AppWrapper>
      {fontsLoaded && (
        <AuthProvider>
          <Routes />
        </AuthProvider>
      )}
    </AppWrapper>
  );
}
