module["exports"] = [
  "Aix-en-Provence",
  "Ajaccio",
  "Amiens",
  "Angers",
  "Antibes",
  "Antony",
  "Argenteuil",
  "Asnières-sur-Seine",
  "Aubervilliers",
  "Aulnay-sous-Bois",
  "Avignon",
  "Beauvais",
  "Besançon",
  "Béziers",
  "Bordeaux",
  "Boulogne-Billancourt",
  "Bourges",
  "Brest",
  "Caen",
  "Calais",
  "Cannes",
  "Cayenne",
  "Cergy",
  "Chambéry",
  "Champigny-sur-Marne",
  "Cholet",
  "Clermont-Ferrand",
  "Clichy",
  "Colmar",
  "Colombes",
  "Courbevoie",
  "Créteil",
  "Dijon",
  "Drancy",
  "Dunkerque",
  "Fort-de-France",
  "Grenoble",
  "Hyères",
  "Issy-les-Moulineaux",
  "Ivry-sur-Seine",
  "La Rochelle",
  "La Seyne-sur-Mer",
  "Le Havre",
  "Le Mans",
  "Le Tampon",
  "Les Abymes",
  "Levallois-Perret",
  "Lille",
  "Limoges",
  "Lorient",
  "Lyon",
  "Marseille",
  "Mérignac",
  "Metz",
  "Montauban",
  "Montpellier",
  "Montreuil",
  "Mulhouse",
  "Nancy",
  "Nanterre",
  "Nantes",
  "Neuilly-sur-Seine",
  "Nice",
  "Nîmes",
  "Niort",
  "Noisy-le-Grand",
  "Orléans",
  "Paris",
  "Pau",
  "Perpignan",
  "Pessac",
  "Poitiers",
  "Quimper",
  "Reims",
  "Rennes",
  "Roubaix",
  "Rouen",
  "Rueil-Malmaison",
  "Saint-Denis",
  "Saint-Denis",
  "Saint-Étienne",
  "Saint-Maur-des-Fossés",
  "Saint-Nazaire",
  "Saint-Paul",
  "Saint-Pierre",
  "Saint-Quentin",
  "Sarcelles",
  "Strasbourg",
  "Toulon",
  "Toulouse",
  "Tourcoing",
  "Tours",
  "Troyes",
  "Valence",
  "Vénissieux",
  "Versailles",
  "Villejuif",
  "Villeneuve-d'Ascq",
  "Villeurbanne",
  "Vitry-sur-Seine"
];
