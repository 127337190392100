module["exports"] = [
  "Josep",
  "Ángel",
  "Daniel",
  "Manuel",
  "Pedro",
  "Salvador",
  "Rubén",
  "Iván",
  "Miguel",
  "Jorge",
  "Sergi",
  "Josep",
  "Ricardo",
  "Carles",
  "Jordi",
  "Manuel"
];
