module["exports"] = [
  "021######",
  "031######",
  "0258######",
  "0358######",
  "0257######",
  "0357######",
  "0248######",
  "0348######",
  "0234######",
  "0334######",
  "0259######",
  "0359######",
  "0263######",
  "0363######",
  "0231######",
  "0331######",
  "0239######",
  "0339######",
  "0268######",
  "0368######",
  "0238######",
  "0338######",
  "0242######",
  "0342######",
  "0255######",
  "0355######",
  "0264######",
  "0364######",
  "0241######",
  "0341######",
  "0267######",
  "0367######",
  "0245######",
  "0345######",
  "0251######",
  "0351######",
  "0236######",
  "0336######",
  "0246######",
  "0346######",
  "0253######",
  "0353######",
  "0266######",
  "0366######",
  "0254######",
  "0354######",
  "0243######",
  "0343######",
  "0232######",
  "0332######",
  "0262######",
  "0362######",
  "0252######",
  "0352######",
  "0265######",
  "0365######",
  "0233######",
  "0333######",
  "0249######",
  "0349######",
  "0244######",
  "0344######",
  "0260######",
  "0360######",
  "0261######",
  "0361######",
  "0269######",
  "0369######",
  "0230######",
  "0330######",
  "0247######",
  "0347######",
  "0256######",
  "0356######",
  "0240######",
  "0340######",
  "0250######",
  "0350######",
  "0235######",
  "0335######",
  "0237######",
  "0337######"
];
