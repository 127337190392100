module["exports"] = {
  "درهم امارات": {
    "code": "AED",
    "symbol": ""
  },
  "افغانی": {
    "code": "AFN",
    "symbol": "؋"
  },
  "پزو آرژانتین": {
    "code": "ARS",
    "symbol": "$"
  },
  "دلار استرالیا": {
    "code": "AUD",
    "symbol": "$"
  },
  "مانات آذربایجان": {
    "code": "AZN",
    "symbol": "ман"
  },
  "دلار کانادا": {
    "code": "CAD",
    "symbol": "$"
  },
  "فرانک سوئیس": {
    "code": "CHF",
    "symbol": "CHF"
  },
  "کرون چک": {
    "code": "CZK",
    "symbol": "Kč"
  },
  "کرون": {
    "code": "EEK",
    "symbol": ""
  },
  "دینار عراق": {
    "code": "IQD",
    "symbol": ""
  },
  "ریال ایران": {
    "code": "IRR",
    "symbol": "﷼"
  },
  "کرون ایسلند": {
    "code": "ISK",
    "symbol": "kr"
  },
  "دینار اردن": {
    "code": "JOD",
    "symbol": ""
  },
  "ین": {
    "code": "JPY",
    "symbol": "¥"
  },
  "دینار کویت": {
    "code": "KWD",
    "symbol": ""
  },
  "دینار لیبی": {
    "code": "LYD",
    "symbol": ""
  },
  "درهم مراکش": {
    "code": "MAD",
    "symbol": ""
  },
  "روپی نپال": {
    "code": "NPR",
    "symbol": "₨"
  },
  "ریال عمان": {
    "code": "OMR",
    "symbol": "﷼"
  },
  "پزو فیلیپین": {
    "code": "PHP",
    "symbol": "Php"
  },
  "روپی پاکستان": {
    "code": "PKR",
    "symbol": "₨"
  },
  "ریال قطر": {
    "code": "QAR",
    "symbol": "﷼"
  },
  "روبل روسیه": {
    "code": "RUB",
    "symbol": "руб"
  },
  "ریال عربستان": {
    "code": "SAR",
    "symbol": "﷼"
  },
  "پوند سودان": {
    "code": "SDG",
    "symbol": ""
  },
  "کرون سوئد": {
    "code": "SEK",
    "symbol": "kr"
  },
  "دلار سنگاپور": {
    "code": "SGD",
    "symbol": "$"
  },
};
