import styled from "styled-components/native";
import { color, spacing } from "../theme";
import AmountIndicator from "./amountIndicator";

export const AmountIndicatorSecondary = styled(AmountIndicator)`
  background-color: ${color.secondary};
  color: ${color.white};
  margin-left: ${spacing.small};
  margin-right: 0;
`;
