import React, { FC } from "react";
import { View } from "react-native";
import styled, { css } from "styled-components/native";
import { color, borderRadius, badge } from "../../styles/theme";

export const Icon = styled.Image`
  flex: 1;
  resize-mode: center;
  width: 18px;
  height: 18px;
  margin: 8px;
  left: 0;
`;

interface BadgeProps {
  badgeColor?: string;
  size?: string;
}

export const Badge = styled.View<BadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius.large};

  ${({ badgeColor }) =>
    badgeColor
      ? css`
          background-color: ${badgeColor};
        `
      : css`
          background-color: ${color.tertiary};
        `};

  ${({ size }) =>
    size
      ? css`
          width: ${size};
          height: ${size};
        `
      : css`
          width: ${badge.medium.size};
          height: ${badge.medium.size};
        `};
`;

export interface IconBadgeProps {
  icon: string;
  badgeColor?: string;
  size?: string;
}

const IconBadge: FC<IconBadgeProps> = ({ icon, badgeColor, size, width, height }) => (
  <Badge badgeColor={badgeColor} size={size} widht={width} height={height} >
    <Icon source={{ uri: icon.toString(), width: width, height: height }} />
  </Badge>
);

export default IconBadge;
