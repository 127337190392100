module["exports"] = [
  "Rojo",
  "Azul",
  "Negro",
  "Gris",
  "Blanco",
  "Amarillo",
  "Verde",
  "Morado",
  "Violeta"
]