import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components/native";
import Filter, { FilterHeader } from ".";
import {
  ReservationStatus,
  ReservationType,
  SeatType,
} from "../../../bookmydesk-api-sdk-typescript-axios";
import { useAuth } from "../../context/authContext";
import useCompanyTransportOptions from "../../hooks/useCompanyTransportOptions";
import useLocations from "../../hooks/useLocations";
import useMaps from "../../hooks/useMaps";
import useSeatTypes from "../../hooks/useSeatTypes";
import useUserGroups from "../../hooks/useUserGroups";
import { t } from "../../i18n";
import { AmountIndicatorSecondary } from "../../styles/AmountIndicator";
import { ExtraSmallText } from "../../styles/Text";
import { spacing } from "../../styles/theme";
import { SmallTitle } from "../../styles/Title";
import Checkbox from "../Checkbox";
import DateSelector from "../DateSelector";
import PickerInput from "../PickerInput";
import Select, { SelectItemInterface } from "../Select";
import {
  comparisonValues,
  DateComparisor,
  today,
} from "./reservationFilter.constant";

const Row = styled.View`
  margin-bottom: ${spacing.medium};
`;

export interface ReservationFilter {
  dateOperator?: DateComparisor;
  from?: string;
  to?: string;
  status?: ReservationStatus;
  seatType?: SeatType;
  locationId?: string;
  mapId?: string;
  type?: ReservationType;
  includeAnonymous?: boolean;
  userGroupId?: string;
  companyTransportOptionId?: string;
}

interface ReservationsFilterInterface {
  showFilter: boolean;
  setShowFilter(newValue: boolean): void;
  onFilter(filter: ReservationFilter, filterSize: number): void;
}

const ReservationsFilter: FC<ReservationsFilterInterface> = ({
  showFilter,
  setShowFilter,
  onFilter,
}) => {
  const { userState } = useAuth();
  const initialComparisonCheck = useRef<boolean>(false);
  const [comparisonCheck, setComparisonCheck] = useState(false);
  const [includeAnonymous,setIncludeAnonymous] = useState(false);
  const [comparisonList, setComparisonList] = useState(comparisonValues());
  const [dateComparison, setDateComparison] = useState(DateComparisor.IS_EQUAL);

  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const [status, setStatus] = useState(undefined);
  const [locationId, setLocationId] = useState(undefined);
  const [mapId, setMapId] = useState(undefined);
  const [seatType, setSeatType] = useState(undefined);
  const [type, setType] = useState(undefined);

  const [userGroupId, setUserGroupId] = useState("");
  const [companyTransportOptionId, setCompanyTransportOptionId] = useState("");



  const userGroups = useUserGroups(userState?.companyIds[0] || "");
  const userGroupOptions = useMemo(() =>
      userGroups.map((group) => ({ value: group.id, label: group.label })),
    [userGroups],
  );

  const locations = useLocations({ companyId: userState?.companyIds[0] });
  const companyTransportOptions = useCompanyTransportOptions({ companyId: userState?.companyIds[0] });
  const maps = useMaps({ companyId: userState?.companyIds[0] });
  const seatTypes = useSeatTypes();

  const removeDuplicates = (array: any, key: string) => {
    return array.reduce((arr: any, item: any) => {
      const removed = arr.filter((i: any) => i[key] !== item[key]);
      return [...removed, item].sort((a, b) => a.label.localeCompare(b.label));
    }, []);
  };

  const updateDateComparison = useCallback(
    (newValue: SelectItemInterface[]) => {
      const updatedList = createUpdatedList(newValue, comparisonValues());
      const selectedComparison = newValue.length ? newValue[0].value : "";
      setDateComparison(selectedComparison);
      setComparisonList(removeDuplicates(updatedList, "value"));
    },
    [setDateComparison, setComparisonList]
  );

  const createUpdatedList = (
    selectedItems: SelectItemInterface[],
    currentList: SelectItemInterface[]
  ): SelectItemInterface[] => {
    return [
      ...currentList.map((item) => ({
        label: item.label,
        value: item.value,
      })),
      ...selectedItems,
    ];
  };

  const getFilterSize = useCallback(() => {
    const arr = [comparisonCheck];
    let count = arr.filter(Boolean).length;
    status && count++;
    seatType && count++;
    locationId && count++;
    mapId && count++;
    type && count++;
    includeAnonymous && count++;
    userGroupId && count++;
    companyTransportOptionId && count++;


    return count;
  }, [comparisonCheck, status, seatType, locationId, mapId, type, includeAnonymous, userGroupId, companyTransportOptionId]);

  const createFilter = (): ReservationFilter => ({
    dateOperator:
      comparisonCheck && dateComparison ? dateComparison : undefined,
    from:
      comparisonCheck &&
      (dateComparison === DateComparisor.IS_BETWEEN ||
        dateComparison === DateComparisor.IS_GREATER ||
        dateComparison === DateComparisor.IS_GREATER_OR_EQUAL ||
        dateComparison === DateComparisor.IS_EQUAL ||
        dateComparison === DateComparisor.IS_NOT_EQUAL)
        ? fromDate
        : undefined,
    to:
      comparisonCheck && dateComparison === DateComparisor.IS_BETWEEN
        ? toDate
        : comparisonCheck &&
          (dateComparison === DateComparisor.IS_LESS ||
            dateComparison === DateComparisor.IS_LESS_OR_EQUAL)
        ? fromDate
        : undefined,
    status: status || undefined,
    seatType: seatType || undefined,
    locationId: locationId || undefined,
    mapId: mapId || undefined,
    type: type || undefined,
    includeAnonymous: includeAnonymous || undefined,
    userGroupId: userGroupId || undefined,
    companyTransportOptionId: companyTransportOptionId || undefined,
  });

  const applyFilter = () => {
    setInitialCheckValues();
    onFilter(createFilter(), getFilterSize());
    setShowFilter(false);
  };

  const setInitialCheckValues = () => {
    initialComparisonCheck.current = comparisonCheck;
  };

  const showBetweenDate = useCallback(() => {
    return dateComparison === DateComparisor.IS_BETWEEN;
  }, [dateComparison]);

  const resetCheckState = useCallback(() => {
    setComparisonCheck(initialComparisonCheck.current);
  }, [setComparisonCheck]);

  const cancelFilter = useCallback(
    (hideFilter: boolean) => {
      setShowFilter(hideFilter);
      resetCheckState();
    },
    [setShowFilter, resetCheckState]
  );

  //console.log(" DateComparisor.IS_BETWEEN", dateComparison);

  return (
    <Filter
      showFilter={showFilter}
      setShowFilter={cancelFilter}
      setFilter={applyFilter}
    >
      <FilterHeader>
        <SmallTitle hasMarginBottom>{t("general.filter.title")}</SmallTitle>
        <AmountIndicatorSecondary hasMarginBottom>
          {getFilterSize()}
        </AmountIndicatorSecondary>
      </FilterHeader>
      <Checkbox
        checked={comparisonCheck}
        onChange={setComparisonCheck}
        label={t("admin.reservation.filter.comparisonCheck")}
      />
      {Boolean(comparisonCheck) && (
        <Select
          items={comparisonList}
          onChange={updateDateComparison}
          placeholder={t("admin.reservation.filter.selectDateComparison")}
          style={{ zIndex: 16 }}
        />
      )}
      {Boolean(comparisonCheck) && (
        <DateSelector
          date={fromDate}
          onSelect={setFromDate}
          style={{ zIndex: 15 }}
        />
      )}
      {showBetweenDate() && Boolean(comparisonCheck) && (
        <DateSelector
          date={toDate}
          onSelect={setToDate}
          style={{ zIndex: 14 }}
        />
      )}
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.user.filter.userGroups")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={userGroupId}
          onValueChange={(itemValue) => setUserGroupId(itemValue.toString())}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...userGroupOptions,
          ]}
        />
      </Row>     
       <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.user.filter.companyTransportOption")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={companyTransportOptionId}
          onValueChange={(itemValue) => setCompanyTransportOptionId(itemValue.toString())}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...companyTransportOptions.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ]}
        />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.reservation.overview.location")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={locationId}
          onValueChange={(itemValue) => {
            setLocationId(itemValue);
            if (!itemValue) {
              setMapId("");
            }
          }}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...locations.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ]}
        />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.reservation.overview.map")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={mapId}
          onValueChange={(itemValue) => setMapId(itemValue)}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...maps
              .filter((item) => !locationId || item.locationId === locationId)
              .map((item) => ({
                label: item.name,
                value: item.id,
              })),
          ]}
        />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.reservation.overview.type")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={type}
          onValueChange={(itemValue) => setType(itemValue)}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            {
              label: t("admin.reservation.type.normal"),
              value: "normal",
            },
            {
              label: t("admin.reservation.type.visitor"),
              value: "visitor",
            },
            {
              label: t("admin.reservation.type.home"),
              value: "home",
            },
          ]}
        />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.reservation.overview.seatType")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={seatType}
          onValueChange={(itemValue) => setSeatType(itemValue)}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            ...seatTypes.map((item) => ({
              label: item.name,
              value: item.value,
            })),
          ]}
        />
      </Row>
      <Row>
        <ExtraSmallText mediumWeight>
          {t("admin.reservation.overview.status")}
        </ExtraSmallText>
        <PickerInput
          noMarginBottom
          selectedValue={status}
          onValueChange={(itemValue) => setStatus(itemValue)}
          items={[
            {
              label: t("admin.reservation.overview.choose"),
              value: "",
            },
            {
              label: t("admin.reservation.status.reserved"),
              value: "reserved",
            },
            {
              label: t("admin.reservation.status.expired"),
              value: "expired",
            },
            {
              label: t("admin.reservation.status.cancelled"),
              value: "cancelled",
            },
            {
              label: t("admin.reservation.status.checkedOut"),
              value: "checkedOut",
            },
            {
              label: t("admin.reservation.status.checkedIn"),
              value: "checkedIn",
            },
          ]}
        />
      </Row>
      <Checkbox

        checked={includeAnonymous}
        onChange={setIncludeAnonymous}
        label={t("admin.reservation.filter.anonymous_reservation")}
      />
    </Filter>
  );
};

export default ReservationsFilter;
