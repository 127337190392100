module["exports"] = [
  "Dlamini",
  "Zulu",
  "Mabunda",
  "Makhanya",
  "Khoza",
  "Zuma",
  "Zondi",
  "Abbott",
  "Adams",
  "Anderson",
  "Adcock",
  "Ashley",
  "Amla",
  "Baloyi",
  "Bailey",
  "Barrows",
  "Barton",
  "Berge",
  "Bernhard",
  "Bernier",
  "Boyle",
  "Braun",
  "Blanckenberg",
  "Brekke",
  "Brown",
  "Bruen",
  "Bacher",
  "Boucher",
  "Bromfield",
  "Benjamin",
  "Bongo",
  "Bhana",
  "Bhengu",
  "Carter",
  "Cameron",
  "Champlin",
  "Collins",
  "Cullinan",
  "Chetty",
  "Cook",
  "Connelly",
  "Crooks",
  "Cummings",
  "Cassim",
  "Dube",
  "Dhlamini",
  "Daniel",
  "Davis",
  "Dower",
  "Dixon",
  "Dickinson",
  "Douglas",
  "Deane",
  "Ebert",
  "Elworthy",
  "Feest",
  "Fuller",
  "Fish",
  "Fisher",
  "Fichardt",
  "Govender",
  "Gupta",
  "Gandhi",
  "Gibson",
  "Gibbs",
  "Gleason",
  "Goonam",
  "Gordhan",
  "Goodwin",
  "Grady",
  "Graham",
  "Grant",
  "Green",
  "Greenholt",
  "Grimes",
  "Hlongwane",
  "Harris",
  "Hall",
  "Horne",
  "Harvey",
  "Hearne",
  "Heller",
  "Herzog",
  "Hudson",
  "Hlatshwayo",
  "Hitchinson",
  "Hathorn",
  "Hayworth",
  "Henderson",
  "Jacobs",
  "Jacobson",
  "Johnson",
  "Johnston",
  "Jones",
  "Joseph",
  "Kalyan",
  "Kathrada",
  "King",
  "Klusener",
  "Klein",
  "Keith",
  "Kennedy",
  "Kuhn",
  "Khumalu",
  "Khoza",
  "Kunene",
  "Kempis",
  "Kemp",
  "Kubheka",
  "Khuzwayo",
  "Linsey",
  "Lowe",
  "Letsoalo",
  "Mhlanga",
  "Mabaso",
  "Mazibuko",
  "Moosa",
  "Mhlongo",
  "Mahlangu",
  "Markham",
  "Mansell",
  "Marvin",
  "Mayer",
  "Mbatha",
  "Maseko",
  "Milton",
  "Mkhize",
  "Moses",
  "McKenzie",
  "McMillan",
  "McLaren",
  "McLean",
  "Miller",
  "Mills",
  "Mitchell",
  "Mchunu",
  "Munsamy",
  "Mnisi",
  "Moen",
  "Motaung",
  "Mudau",
  "Mohr",
  "Monahan",
  "Moore",
  "Moosa",
  "Moonsamy",
  "Mphahlele",
  "Morar",
  "Molefe",
  "Mthembu",
  "Muller",
  "Murray",
  "Moloi",
  "Mofokeng",
  "Modise",
  "Mtshali",
  "Mathebula",
  "Mthethwa",
  "Maluleke",
  "Mokwena",
  "Motloung",
  "Mahabeer",
  "Mngomezulu",
  "Nolan",
  "Nair",
  "Ndlovu",
  "Nkosi",
  "Ngcobo",
  "Ngwenya",
  "Ntuli",
  "Nxumalo",
  "Ngubane",
  "Nhlapo",
  "Ndaba",
  "Nkomo",
  "Oliphant",
  "Ochse",
  "O'Linn",
  "Patel",
  "Parker",
  "Parkin",
  "Pillay",
  "Parnell",
  "Peterson",
  "Procter",
  "Poore",
  "Pollock",
  "Powell",
  "Price",
  "Prince",
  "Pithey",
  "Reilly",
  "Reid",
  "Rowe",
  "Roberts",
  "Richards",
  "Richardson",
  "Schmidt",
  "Schultz",
  "Stewart",
  "Symcox",
  "Smith",
  "Stokes",
  "Sinclair",
  "Singh",
  "Shongwe",
  "Sibiya",
  "Schwarz",
  "Snooke",
  "Sithole",
  "Terry",
  "Thompson",
  "Tromp",
  "Tuckett",
  "Taylor",
  "Tsabalala",
  "Wesley",
  "Walsh",
  "Weber",
  "Weimann",
  "Willoughby",
  "White",
  "Welch",
  "West",
  "Williamson",
  "Ziemann",
  "Albertyn",
  "Bosman",
  "Bester",
  "Truter",
  "Tsotetsi",
  "Prinsloo",
  "van Niekerk",
  "Zimmerman",
  "Venter",
  "van den Berg",
  "Xaba",
  "Zulu",
  "Zungu",
  "Zuma",
  "Zwane"
];