module["exports"] = [
  "ספרים",
  "סרטים",
  "מוסיקה",
  "משחקים",
  "מכשירי חשמל",
  "מחשבים",
  "בית",
  "גן",
  "כלים",
  "מכולת",
  "בריאות",
  "יופי",
  "צעצועים",
  "ילדים",
  "תִינוֹק",
  "ביגוד",
  "נעליים",
  "תכשיטים",
  "ספורט",
  "מחוץ לבית",
  "רכב",
  "תעשייתי"
];
