import { useCallback, useState } from "react";
import { HeaderItem } from "../components/Table/TableHeaderItem";

export enum SortOrder {
  DESC = "desc",
  ASC = "asc",
}

export const useSort = <SortType>(
  listHeaders: HeaderItem[]
): {
  headerItems: HeaderItem[];
  sortField: SortType | undefined;
  sortOrder: SortOrder | undefined;
  onSortList: (sortValue: any, order?: SortOrder) => void;
} => {
  const activeSortHeaderItem = listHeaders.find((item) => item.isActive);
  const defaultSortField: SortType | undefined = (
    activeSortHeaderItem ? activeSortHeaderItem.sortField : undefined
  ) as SortType | undefined;
  const [sortField, setSortField] = useState<SortType | undefined>(
    defaultSortField
  );
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(
    activeSortHeaderItem
      ? activeSortHeaderItem.isAscending
        ? SortOrder.ASC
        : SortOrder.DESC
      : undefined
  );
  const [headerItems, setHeaderItems] = useState(listHeaders);

  const onSortList = useCallback(
    (sortValue, order) => {
      const updatedHeaderItems = headerItems.map((item) =>
        updateHeaderItemState(item, sortValue)
      );
      const item = updatedHeaderItems.find(
        (element) => element.sortField === sortValue
      );
      const sortDirection = order || (item?.isAscending ? SortOrder.ASC : SortOrder.DESC);
      setHeaderItems(updatedHeaderItems);
      setSortField(sortValue);
      setSortOrder(sortDirection);
    },
    [headerItems, setHeaderItems, setSortField]
  );

  const updateHeaderItemState = (item: HeaderItem, sortValue: string) => {
    let isAscending = false;
    let isActive = false;

    if (item.sortField === sortValue) {
      if (item.isActive) {
        isAscending = !item.isAscending;
      }
      isActive = true;
    }

    return {
      ...item,
      isActive,
      isAscending,
    };
  };
  return { headerItems, sortField, sortOrder, onSortList };
};
