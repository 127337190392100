import { useEffect, useState } from "react";
import { Location } from "../../bookmydesk-api-sdk-typescript-axios";
import { useApi } from "./useApi";
import { useClient } from "./useClient";

const useLocations = ({ companyId }: { companyId?: string }): Location[] => {
  const client = useClient();
  const { handleRequest } = useApi();

  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    const init = async () => {
      if (!companyId) {
        return setLocations([]);
      }
      const response = await handleRequest(client.listLocations(companyId));

      setLocations(response?.data.result.items || []);
    };
    init();
  }, [handleRequest, companyId, client]);

  return locations;
};

export default useLocations;
