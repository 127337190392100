module["exports"] = [
  "Aino",
  "Anja",
  "Anna",
  "Anne",
  "Anneli",
  "Annikki",
  "Eeva",
  "Elina",
  "Elisabet",
  "Emilia",
  "Eveliina",
  "Hanna",
  "Hannele",
  "Helena",
  "Inkeri",
  "Irmeli",
  "Johanna",
  "Kaarina",
  "Karoliina",
  "Katariina",
  "Kristiina",
  "Kyllikki",
  "Laura",
  "Leena",
  "Liisa",
  "Maarit",
  "Maija",
  "Mari",
  "Maria",
  "Marika",
  "Marja",
  "Marjatta",
  "Minna",
  "Orvokki",
  "Pauliina",
  "Pirjo",
  "Pirkko",
  "Päivi",
  "Riitta",
  "Ritva",
  "Sari",
  "Satu",
  "Sinikka",
  "Sofia",
  "Susanna",
  "Tarja",
  "Tellervo",
  "Tiina",
  "Tuula",
  "Tuulikki"
];