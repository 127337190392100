import styled from "styled-components/native";
import { css } from "styled-components";
import { ViewProps } from "react-native";

import { spacing } from "../theme";

const defaultGrow = 1;

interface ListItemProps extends Partial<ViewProps> {
  columnSize?: number;
}

export const ListItem = styled.View<ListItemProps>`
  ${({ columnSize }) =>
    css`
      flex: ${columnSize || defaultGrow} 1 0;
    `};
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  margin: 0 ${spacing.small};
`;
