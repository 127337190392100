import React, { FC, useCallback } from "react";
import styled from "styled-components/native";

import { font, spacing, text } from "../../styles/theme";

import { Backdrop, Modal } from "../Dialog";

const FilterModalBackdrop = styled(Backdrop)`
  align-items: flex-end;
`;

const FilterModal = styled(Modal)`
  margin: auto ${spacing.medium} auto;
`;

const Close = styled.TouchableOpacity`
  position: absolute;
  right: ${spacing.large};
  top: ${spacing.large};
`;

const CloseText = styled.Text`
  line-height: 0;
  font-size: ${text.extraLarge.size};
  font-family: ${font.defaultMedium};
`;

interface DialogProps {
  show: boolean;
  setShow(show: boolean): void;
}

const FilterDialog: FC<DialogProps> = ({ children, show, setShow }) => {
  const closeModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <>
      {show && (
        <FilterModalBackdrop>
          <FilterModal>
            {children}
            <Close onPress={closeModal}>
              <CloseText>⨯</CloseText>
            </Close>
          </FilterModal>
        </FilterModalBackdrop>
      )}
    </>
  );
};

export default FilterDialog;
