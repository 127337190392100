module["exports"] = [
  "amarelo",
  "âmbar",
  "ametista",
  "azul",
  "azul celeste",
  "azul marinho",
  "azul petróleo",
  "açafrão",
  "bordô",
  "bronze",
  "caramelo",
  "castanho ",
  "cenoura",
  "cinza",
  "cobre",
  "coral",
  "dourado",
  "escarlate",
  "esmeralda",
  "ferrugem",
  "fuligem",
  "fúchsia",
  "grená",
  "índigo",
  "jade",
  "laranja",
  "lilás",
  "limão",
  "madeira",
  "magenta",
  "marrom",
  "ouro",
  "pele",
  "prata",
  "preto",
  "púrpura",
  "rosa",
  "roxo",
  "salmão",
  "turquesa",
  "verde",
  "verde lima",
  "verde-azulado",
  "vermelho",
  "violeta"
];
