module["exports"] = {
  "descriptor": [
    "رهبر",
    "ارشد",
    "مستقیم",
    "پویا",
    "آینده",
    "محصول",
    "ملی",
    "منطقه",
    "ناحیه",
    "مرکزی",
    "جهانی",
    "مشتری",
    "سرمایه گذار",
    "بین المللی",
    "میراث",
    "داخلی",
    "انسان",
    "رئیس",
    "مدیر اصلی"
  ],
  "level": [
    "راه حل ها",
    "برنامه",
    "نام تجاری",
    "امنیت",
    "پژوهش",
    "بازار یابی",
    "رهنمود",
    "پیاده سازی",
    "ادغام",
    "عملکرد",
    "واکنش",
    "الگو",
    "تدابیر",
    "شناسایی",
    "بازارها",
    "گروه ها",
    "بخش",
    "برنامه های کاربردی",
    "بهينه سازي",
    "عملیات",
    "زیر ساخت",
    "اینترانت",
    "ارتباطات",
    "وب",
    "کیفیت",
    "اطمینان",
    "پویایی",
    "حساب ها",
    "داده ها",
    "خلاقیت",
    "پیکربندی",
    "مسئوليت",
    "فعل و انفعالات",
    "عوامل",
    "قابلیت استفاده",
    "معیار"
  ],
  "job": [
    "کارگر ساده",
    "سرپرست کارگاه",
    "ناظر",
    "مهندس پروژه",
    "کارفرما",
    "مدیر",
    "سرپرست",
    "اموردفتری",
    "کارمند",
    "مسئول",
    "مدیرعامل",
    "رئیس هیئت مدیره",
    "عضو هیئت مدیره",
    "کارشناس",
    "مربی",
    "مدرس",
    "منشی",
    "فروشنده",
    "متصدی",
    "تکنسین",
    "استادکار",
    "نصاب",
    "حسابدار",
    "راننده",
    "نگهبان",
    "رئیس",
    "مهندس",
    "مدیرفروش",
    "کارشناس فنی",
    "مسئول فنی",
    "کارگر فنی",
    "بازاریاب",
    "کارپرداز",
    "خدمتگزار"
  ]
};
