import React from "react";
import styled from "styled-components/native";
import { color, spacing } from "../theme";
import { css } from "styled-components";
import { FC, useRef } from "react";
import { useHover } from "react-native-web-hooks";

interface ListRowProps {
  isHovering?: boolean;
  isActive?: boolean;
  paddingTop?: string | number;
  paddingBottom?: string | number;
}

export const ListRow = styled.View<ListRowProps>`
  border-top-width: 1px;
  border-top-color: ${color.greyLightest};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ paddingTop }) =>
    css`
      padding-top: ${paddingTop || spacing.small};
    `}
  ${({ paddingBottom }) =>
    css`
      padding-bottom: ${paddingBottom || spacing.small};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${color.activeTable};
    `}
  ${({ isHovering }) =>
    isHovering &&
    css`
      background-color: ${color.greyLighter};
      cursor: pointer;
    `}
`;

export const HoverableListRow: FC<ListRowProps> = ({ children, ...rest }) => {
  const element = useRef(null);
  const isHovering = useHover(element);

  return (
    <ListRow ref={element} {...rest} isHovering={isHovering}>
      {children}
    </ListRow>
  );
};
