import React, { FC, useCallback, useRef, useState } from "react";
import styled from "styled-components/native";
import Filter, { FilterHeader } from ".";
import { t } from "../../i18n";
import { AmountIndicatorSecondary } from "../../styles/AmountIndicator";
import { spacing } from "../../styles/theme";
import { SmallTitle } from "../../styles/Title";
import Checkbox from "../Checkbox";
import DateSelector from "../DateSelector";
import Select, { SelectItemInterface } from "../Select";
import {
  comparisonValues,
  DateComparisor,
  today,
} from "./reservationFilter.constant";

const Row = styled.View`
  margin-bottom: ${spacing.medium};
`;


// from 
// to
// resourceType
// dateOperator
// isRepeatReservation

export interface ResourceReservationFilter {
  dateOperator?: DateComparisor;
  dateFrom?: string;
  dateTo?: string;
  resourceType?: string;
  isRepeatReservation?: boolean;
}

interface ReservationsFilterInterface {
  showFilter: boolean;
  setShowFilter(newValue: boolean): void;
  onFilter(filter: ResourceReservationFilter, filterSize: number): void;
}

const ReservationsFilter: FC<ReservationsFilterInterface> = ({
  showFilter,
  setShowFilter,
  onFilter,
}) => {
  const initialComparisonCheck = useRef<boolean>(false);
  const [comparisonCheck, setComparisonCheck] = useState(false);
  const [comparisonList, setComparisonList] = useState(comparisonValues());
  const [dateComparison, setDateComparison] = useState(DateComparisor.IS_EQUAL);

  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const [isRepeatReservation, setIsRepeatReservation] = useState(false);

  const removeDuplicates = (array: any, key: string) => {
    return array.reduce((arr: any, item: any) => {
      const removed = arr.filter((i: any) => i[key] !== item[key]);
      return [...removed, item].sort((a, b) => a.label.localeCompare(b.label));
    }, []);
  };

  const updateDateComparison = useCallback(
    (newValue: SelectItemInterface[]) => {
      const updatedList = createUpdatedList(newValue, comparisonValues());
      const selectedComparison = newValue.length ? newValue[0].value : "";
      setDateComparison(selectedComparison);
      setComparisonList(removeDuplicates(updatedList, "value"));
    },
    [setDateComparison, setComparisonList]
  );

  const createUpdatedList = (
    selectedItems: SelectItemInterface[],
    currentList: SelectItemInterface[]
  ): SelectItemInterface[] => {
    return [
      ...currentList.map((item) => ({
        label: item.label,
        value: item.value,
      })),
      ...selectedItems,
    ];
  };

  const getFilterSize = useCallback(() => {
    const arr = [comparisonCheck];
    let count = arr.filter(Boolean).length;
    status && count++;
    isRepeatReservation && count++;
    return count;
  }, [comparisonCheck, isRepeatReservation]);

  const createFilter = (): ResourceReservationFilter => ({
    dateOperator:
      comparisonCheck && dateComparison ? dateComparison : undefined,
    dateFrom:
      comparisonCheck &&
      (dateComparison === DateComparisor.IS_BETWEEN ||
        dateComparison === DateComparisor.IS_GREATER ||
        dateComparison === DateComparisor.IS_GREATER_OR_EQUAL ||
        dateComparison === DateComparisor.IS_EQUAL ||
        dateComparison === DateComparisor.IS_NOT_EQUAL)
        ? fromDate
        : undefined,
    dateTo:
      comparisonCheck && dateComparison === DateComparisor.IS_BETWEEN
        ? toDate
        : comparisonCheck &&
          (dateComparison === DateComparisor.IS_LESS ||
            dateComparison === DateComparisor.IS_LESS_OR_EQUAL)
        ? fromDate
        : undefined,
    isRepeatReservation: isRepeatReservation || undefined,
  });

  const applyFilter = () => {
    setInitialCheckValues();
    onFilter(createFilter(), getFilterSize());
    setShowFilter(false);
  };

  const setInitialCheckValues = () => {
    initialComparisonCheck.current = comparisonCheck;
  };

  const showBetweenDate = useCallback(() => {
    return dateComparison === DateComparisor.IS_BETWEEN;
  }, [dateComparison]);

  const resetCheckState = useCallback(() => {
    setComparisonCheck(initialComparisonCheck.current);
  }, [setComparisonCheck]);

  const cancelFilter = useCallback(
    (hideFilter: boolean) => {
      setShowFilter(hideFilter);
      resetCheckState();
    },
    [setShowFilter, resetCheckState]
  );

  return (
    <Filter
      showFilter={showFilter}
      setShowFilter={cancelFilter}
      setFilter={applyFilter}
    >
      <FilterHeader>
        <SmallTitle hasMarginBottom>{t("general.filter.title")}</SmallTitle>
        <AmountIndicatorSecondary hasMarginBottom>
          {getFilterSize()}
        </AmountIndicatorSecondary>
      </FilterHeader>
      <Checkbox
        checked={comparisonCheck}
        onChange={setComparisonCheck}
        label={t("admin.reservation.filter.comparisonCheck")}
      />
      {Boolean(comparisonCheck) && (
        <Select
          items={comparisonList}
          onChange={updateDateComparison}
          placeholder={t("admin.reservation.filter.selectDateComparison")}
          style={{ zIndex: 16 }}
        />
      )}
      {Boolean(comparisonCheck) && (
        <DateSelector
          date={fromDate}
          onSelect={setFromDate}
          style={{ zIndex: 15 }}
        />
      )}
      {showBetweenDate() && Boolean(comparisonCheck) && (
        <DateSelector
          date={toDate}
          onSelect={setToDate}
          style={{ zIndex: 14 }}
        />
      )}

      <Checkbox
        checked={isRepeatReservation}
        onChange={setIsRepeatReservation}
        label={t("admin.reservation.filter.isRepeatReservation")}
      />
    </Filter>
  );
};

export default ReservationsFilter;
