module["exports"] = [
  "5018-#{4}-#{4}-#{3}L",
  "5020-#{4}-#{4}-#{3}L",
  "5038-#{4}-#{4}-#{3}L",
  "5893-#{4}-#{4}-#{3}L",
  "6304-#{4}-#{4}-#{3}L",
  "6759-#{4}-#{4}-#{3}L",
  "676[1-3]-####-####-###L",
  "5018#{11,15}L",
  "5020#{11,15}L",
  "5038#{11,15}L",
  "5893#{11,15}L",
  "6304#{11,15}L",
  "6759#{11,15}L",
  "676[1-3]#{11,15}L",
];

// 5018 xxxx xxxx xxxx xxL
