module["exports"] = [
  "24 ساعت",
  "24/7",
  "نسل سوم",
  "نسل چهارم",
  "نسل پنجم",
  "نسل ششم",
  "تجزیه و تحلیل",
  "نامتقارن",
  "ناهمگام",
  "نگرش محور",
  "پس زمینه",
  "پهنای باند پایش",
  "دو جهته",
  "دوتایی",
  "روشن اندیشی",
  "مشتری محور",
  "سرویس دهنده مشتری",
  "منسجم",
  "مرکب",
  "حساس به متن",
  "مبتنی بر متن",
  "مبتنی بر محتوا",
  "اختصاصی",
  "تقاضا محور",
  "اموزشی",
  "جهت دار",
  "گسسته",
  "بی تفاوت",
  "پویا",
  "محیط زیست",
  "توانمند سازی",
  "اجرایی",
  "صریح",
  "خطاپذيری",
  "پیش زمینه",
  "پایدار",
  "ثابت",
  "سیستمی",
  "شایسته سیستم",
  "محسوس",
  "انتقالی",
  "یکسان",
  "روند رو به بالا",
  "تحمل صفر"
];
