module["exports"] = [
  "توانایی",
  "دسترسی",
  "آداپتور",
  "الگوریتم",
  "اتحاد",
  "تحلیلگر",
  "کاربرد",
  "رویکرد",
  "معماری",
  "بایگانی",
  "هوش مصنوعی",
  "آرایه",
  "نگرش",
  "معیار",
  "مدیریت بودجه",
  "قابلیت",
  "ظرفیت",
  "چالش",
  "جریان",
  "همکاری",
  "پیچیدگی",
  "مفهوم",
  "اجتماع",
  "احتمالی",
  "هسته",
  "بانک اطلاعاتی",
  "پایگاه داده تحلیلی",
  "تعریف",
  "شبیه سازی",
  "رمزگذاری",
  "اکسترانت",
  "سیستم عامل",
  "انعطاف پذیری",
  "نیروی کار"
];
