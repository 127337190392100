import React, { useCallback, useState } from "react";
import styled from 'styled-components/native';
import { t } from '../../i18n';
import Input from '../../styles/Input/input';
import Checkbox from '../Checkbox';
import DatePicker from "react-datepicker";
import { color } from '../../styles/theme';
import DeleteIcon from '../../assets/icons/deleteIconInactive.svg';
import DeleteIconHover from '../../assets/icons/deleteIconActive.svg';
import IconButtonSmall from '../Button/IconButtonSmall';
import { useApi } from '../../hooks/useApi';
import { useClient } from '../../hooks/useClient';
import { useParams } from '../../routing';

export interface ExceptionDay {
    id: string;
    date?: Date;
    start?: string;
    end?: string;
    name?: string;
    isWholeDay?: boolean;
}

interface Props {
    values: ExceptionDay;
    onChange?: (id: string, values: ExceptionDay) => void;
    onDeleted?: (id: string) => void;
}

export const ExceptionHours = ({ values, onChange, onDeleted }: Props): JSX.Element => {
    const { handleRequest } = useApi();
    const client = useClient();
    const { id } = useParams();

    const [name, setName] = useState(values.name);
    const [isWholeDay, setIsWholeDay] = useState(values.isWholeDay ?? false);
    const [date, setDate] = useState<Date>(values.date ?? new Date());
    const [start, setStart] = useState(values.start);
    const [end, setEnd] = useState(values.end);

    const handleChange = (field: keyof ExceptionDay, value: any) => {
        if (onChange) {
            // We want to update the whole exception day object at once
            onChange(values.id, {
                id: values.id,
                date,
                start,
                end,
                name,
                isWholeDay,
                [field]: value,
            });
        }

        if (field === 'name') setName(value);
        if (field === 'isWholeDay') setIsWholeDay(value);
        if (field === 'date') setDate(value);
        if (field === 'start') setStart(value);
        if (field === 'end') setEnd(value);
    };

    const handleDelete = async () => {
        try {
            await handleRequest(client.deleteLocationHours(id, values.id));
            if (onDeleted) onDeleted(values.id);
        } catch (error) { }
    }

    return (
        <Container>
            <Content>

                <Row>
                    <NameInput
                        placeholder={t("admin.timeslot.form.name")}
                        value={name}
                        onChangeText={(value) => handleChange('name', value)}
                    />
                    <StyledCheckbox
                        label={t("admin.timeslot.form.wholeDay")}
                        onChange={(value) => handleChange('isWholeDay', value)}
                        checked={isWholeDay}
                    />
                </Row>
                <Row>
                    <DatePickerWrapper $fill={isWholeDay}>
                        <DatePicker
                            selected={date}
                            popperClassName='exception-date-picker'
                            onChange={(value) => handleChange('date', value ?? new Date())}
                            customInput={<DatePickerInput />}
                        />
                    </DatePickerWrapper>
                    {!isWholeDay && (
                        <>
                            <StyledDateInput
                                placeholder={t("admin.timeslot.form.start")}
                                value={start}
                                onChangeText={(value) => handleChange('start', value)}
                                />
                            <StyledDateInput
                                placeholder={t("admin.timeslot.form.end")}
                                value={end}
                                onChangeText={(value) => handleChange('end', value)}
                            />
                        </>
                    )}
                </Row>
            </Content>
            <Right>
                <IconButtonSmall onPress={handleDelete} hoverIcon={DeleteIconHover} icon={DeleteIcon} />
            </Right>
        </Container>
    )
};

const Container = styled.View`
    margin-bottom: 20px;
    border-bottom-width: 1px;
    border: 0px solid ${color.greyLighter};
    display: flex;
    flex-direction: row;
`;

const Content = styled.View`
    flex: 1;
`;

const Right = styled.View`
    margin-left: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Row = styled.View`
    display: flex;
    flex-direction: row;
`;

const NameInput = styled(Input)`
    flex: 1;
`;

const StyledCheckbox = styled(Checkbox)`
    margin-left: 10px;
`;

const StyledDateInput = styled(Input)`
    margin-left: 10px;
    width: 33%;
`;

const DatePickerWrapper = styled.View<{ $fill?: boolean }>`
    width: ${(p) => p.$fill ? 100 : 33}%;
`;

const DatePickerInput = styled(Input)`
    width: 100%;
`;
