import React, { FC, useCallback } from "react";
import { Linking, TouchableOpacity, View } from 'react-native';
import { color, spacing } from "../../../../styles/theme";
import { ExtraSmallText } from '../../../../styles/Text';
import { t } from "../../../../i18n";
import HeaderCard from '../../../../components/HeaderCard';
import microsoftIcon from '../../../../assets/icons/microsoft.svg';
import azureActiveDirectoryIcon from '../../../../assets/icons/azure-active-directory.svg';
import calendarIcon from '../../../../assets/icons/calendar-sync.svg';
import Pill from '../../../../components/Pill';
import { ExtraSmallTextGood } from '../../../../styles/Text/extraSmallText';
import Button from '../../../../components/Button';
import { HorizontalCardDivider } from "../../../../styles/Card";
import Text from "../../../../styles/Text/text";
import { CardInGeneral, CardInGeneralTitle, FlexRow } from "./GeneralCard";

interface ConnectCardProps {
  microsoftSsoEnabled: boolean;
  microsoftSyncEnabled: boolean;
  azureActiveDirectoryActive: boolean;
  provideAzureActiveDirectoryAccess: () => Promise<void>;
}

const LinksCard: FC<ConnectCardProps> = ({
  microsoftSsoEnabled,
  microsoftSyncEnabled,
  azureActiveDirectoryActive,
  provideAzureActiveDirectoryAccess,
}) => {
  const card = useCallback((
    label: string,
    description: string
  ) => (
    <CardInGeneral>
      <FlexRow>
        <CardInGeneralTitle>
          {t(label)}
        </CardInGeneralTitle>
      </FlexRow>
      <HorizontalCardDivider margin="8px 0px" />
      <View>
        <Text color={color.darker} opacity={0.6}>
          {t(description)}
        </Text>
      </View>
    </CardInGeneral>
  ), []);
  return (
    <>
      <HeaderCard
        icon={microsoftIcon}
        iconAsBadge={false}
        iconWidth={26.5}
        iconHeight={26.5}
        title={t(`admin.company.form.microsoftSSO`)}
        disableHorizontalDivider
      >
        {card('admin.company.form.sso.title', 'admin.company.form.sso.description')}
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Pill
            backgroundColor={
              microsoftSsoEnabled ? color.quinaryLight : color.greyLight
            }
            textColor={microsoftSsoEnabled ? color.quinary : color.grey}
          >
            {microsoftSsoEnabled
              ? t(`general.enabled`)
              : t(`general.disabled`)}
          </Pill>
          {microsoftSsoEnabled ? (
            <Text marginLeft="8px">
              {t(`admin.company.form.microsoftSSOEnabled`)}
            </Text>
          ) : (
            <>
              <Text marginLeft="8px">
                {t(`admin.company.form.microsoftSSODisabledPreLink`)}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("mailto:sales@bookmydesk.com");
                }}
              >
                <ExtraSmallText style={{ color: color.primary }}>
                  sales@bookmydesk.com
                </ExtraSmallText>
              </TouchableOpacity>
              {t(`admin.company.form.microsoftSSODisabledSufLink`)}
            </>
          )}
        </View>
      </HeaderCard>
      <HeaderCard
        icon={azureActiveDirectoryIcon}
        iconAsBadge={false}
        iconWidth={26.5}
        iconHeight={26.5}
        title={t(`admin.company.form.azureActiveDirectory`)}
        titleSuffix={
          <View style={{ marginLeft: "auto" }}>
            <Pill
              backgroundColor={
                microsoftSyncEnabled
                  ? color.quinaryLight
                  : color.greyLight
              }
              textColor={
                microsoftSyncEnabled ? color.quinary : color.grey
              }
            >
              {microsoftSyncEnabled
                ? t(`general.enabled`)
                : t(`general.disabled`)}
            </Pill>
          </View>
        }
        disableHorizontalDivider
      >
        {card('admin.company.form.azzure.title', 'admin.settings.generalDescription')}
        <ExtraSmallTextGood
          italic
          style={{
            marginTop: spacing.medium,
            marginBottom: spacing.medium,
          }}
        >
          {microsoftSyncEnabled ? (
            t(`admin.company.form.azureActiveDirectoryEnabled`)
          ) : (
            <>
              {t(
                `admin.company.form.azureActiveDirectoryDisabledPreLink`
              )}
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("mailto:sales@bookmydesk.com");
                }}
              >
                <ExtraSmallTextGood
                  italic
                  style={{ color: color.primary }}
                >
                  sales@bookmydesk.com
                </ExtraSmallTextGood>
              </TouchableOpacity>
              {t(
                `admin.company.form.azureActiveDirectoryDisabledSufLink`
              )}
            </>
          )}
        </ExtraSmallTextGood>
        {microsoftSyncEnabled && (
          <View style={{ flexDirection: "row" }}>
            {!azureActiveDirectoryActive && (
              <Button onPress={provideAzureActiveDirectoryAccess}>
                {t("admin.company.form.provideAccess")}
              </Button>
            )}
            {azureActiveDirectoryActive && (
              <Pill
                backgroundColor={color.quinaryLight}
                textColor={color.quinary}
              >
                {t(`admin.company.form.providedAccess`)}
              </Pill>
            )}
          </View>
        )}
      </HeaderCard>
      <HeaderCard
        icon={calendarIcon}
        iconAsBadge={false}
        iconWidth={26.5}
        iconHeight={26.5}
        title={t(`admin.company.form.calendarSync`)}
        disableHorizontalDivider
      >
        {card('admin.company.form.calendar.title', 'admin.settings.generalDescription')}
      </HeaderCard>
    </>
  );
};

export default LinksCard;
