import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { View, Image } from 'react-native';
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";
import Paginator, { takeSize } from "../../../components/Paginator";
import Table, { TableContent } from "../../../components/Table";
import {
  createHeaderItem,
  HeaderItem,
} from "../../../components/Table/TableHeaderItem";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import { useSort } from "../../../hooks/useSort";
import { t } from "../../../i18n";
import { RouterProps, useHistory } from "../../../routing";
import { ExtraSmallText } from "../../../styles/Text";
import { ArrayElement, ThenArg } from "../../../types";
import Container from "../../../components/Container";
import { getCompanyPages } from "../../../components/Navbar/AdminNavBarContent";
import PageSelector from "../../../components/PageSelector";
import { OverviewHeader } from "../../../styles/Overview";
import Button from "../../../components/Button";
import Title from "../../../styles/Title";
import { color, spacing } from "../../../styles/theme";

const columnSizes = [1, 1];

const SeatTagOverview: FC<RouterProps> = () => {
  const tableHeaders: HeaderItem[] = [
    createHeaderItem(t("admin.seatTag.overview.name"), true, "label"),
    createHeaderItem("", false),
  ];
  const { companyPages, userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const { headerItems, sortOrder, sortField, onSortList } =
    useSort<Parameters<typeof client.listSeatTags>[1]>(tableHeaders);

  const userCompany = userState?.companyIds[0];

  type SeatTagItem = ArrayElement<ThenArg<ReturnType<typeof client.listSeatTags>>["data"]["result"]["items"]>;

  const [data, setData] = useState<Array<SeatTagItem>>([]);
  const [tableData, setTableData] = useState<Array<TableContent>>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<SeatTagItem>();
  const [skipParam, setSkipParam] = useState<number>(0);
  const [totalItems, setTotalItems] = useState(0);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const history = useHistory();

  const fetchData = useCallback(async () => {
    if (userCompany) {
      return handleRequest(
        client.listSeatTags(
          userCompany,
          sortField,
          sortOrder,
          takeSize,
          skipParam
        )
      );
    }
  }, [client, userCompany, handleRequest, sortField, sortOrder, skipParam]);

  useEffect(() => {
    fetchData().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { items, total } = result.data.result;
      if (items) {
        setTableData(createTableData(items));
        setData(items || []);
        setTotalItems(total);
      }
    });
  }, [fetchData, skipParam, sortOrder, sortField, userCompany]);
  // <Image
  //   source={{
  //     uri: icon,
  //     width: 18,
  //     height: 18,
  //   }}
  //   style={{ top: 5, left: 0, zIndex: 40, marginRight: 10, opacity: 0.5 }}
  // />
  const createTableData = useCallback((items: SeatTagItem[]) => {
    const tableData: TableContent[] = items.map(
      ({ icon, label, id, _operations }) => {
        const data: ReactElement[] = [
          <ExtraSmallText key="label" mediumWeight>
          {label}
          </ExtraSmallText>,
        ];
        return {
          data,
          id,
          canDelete: _operations?.canDelete,
          cannotDeleteDescription: _operations?.cannotDeleteDescription,
          canEdit: true,
        };
      }
    );
    return tableData;
  }, []);

  const getItemById = useCallback(
    (itemId: string) => data.find(({ id }) => id === itemId),
    [data]
  );

  const openDeleteModal = useCallback(
    (id: string) => {
      const item = getItemById(id);
      setItemSelected(item);
      setShowDeleteModal(true);
    },
    [setItemSelected, getItemById, setShowDeleteModal]
  );

  const deleteFromList = useCallback(async () => {
    setShowDeleteModal(false);

    const response = await handleRequest(
      client.deleteSeatTag(itemSelected!.id)
    );

    if (response && response.status === 204) {
      await fetchData().then((result) => {
        if (!result || !result.data.result) {
          return;
        }

        const { items, total } = result.data.result;
        if (items) {
          setTableData(createTableData(items));
          setData(items);
          setTotalItems(total);
        }
      });

      setShowDeleteMessage(true);
    }
  }, [
    itemSelected,
    setShowDeleteModal,
    client,
    fetchData,
    setTableData,
    createTableData,
    setData,
    setTotalItems,
  ]);
  return (
    <>
      {isLoading ? <LoadOverlay/> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.seatTag.overview.title")}</Title>
          <Button
            onPress={() => history.push("/admin/company/tag-overview/create")}
          >
            {t("admin.seatTag.overview.addButton")}
          </Button>
        </OverviewHeader>
        <PageSelector pages={companyPages} />
        {!!error && (
          <Notification closeNotification={dismissError}>
            {t("general.error")}
          </Notification>
        )}
        {showDeleteMessage && (
          <Notification
            type="success"
            closeNotification={() => setShowDeleteMessage(false)}
          >
            {t("general.deleteSuccess")}
          </Notification>
        )}
        {!isLoading && (
          <Table
            tableId="seat_tag_overview"
            headerItems={headerItems}
            tableContent={tableData}
            columnSizes={columnSizes}
            sortList={onSortList}
            sortOrder={sortOrder}
            sortField={sortField}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
            onEditItem={(id) =>
              history.push(`/admin/company/tag-overview/update/${id}`)
            }
            onDeleteItem={openDeleteModal}
          />
        )}
        {(!!totalItems && !isLoading) && (
          <Paginator
            totalItems={totalItems}
            skipParam={skipParam}
            setSkipParam={setSkipParam}
          />
        )}
      </Container>

      <ConfirmDialog
        confirm={deleteFromList}
        showDialog={showDeleteModal}
        setShowDialog={setShowDeleteModal}
        text={t("admin.seatTag.delete.warning")}
      />
    </>
  );
};

export default SeatTagOverview;
