module["exports"] = [
  "צפון",
  "מזרח",
  "דרום",
  "מערב",
  "צפון מזרח",
  "צפון מערב",
  "דרום מזרח",
  "דרום מערב"
];
