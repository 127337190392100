import { useCallback, useState } from "react";
import useDebounce from "./useDebounce";

export const useSearch = () => {
  const [search, setSearch] = useState<string | undefined>(undefined);

  const debouncedSearchTerm: string | undefined = useDebounce(search, 500);

  const setDebouncedSearchTerm = useCallback(
    (searchTerm: string) => {
      setSearch(Boolean(searchTerm) ? searchTerm : undefined);
    },
    [setSearch]
  );

  return { debouncedSearchTerm, search, setDebouncedSearchTerm };
};
