import styled, { css } from "styled-components/native";
import { OverviewHeader } from "../../../styles/Overview";
import { borderWidth, color, spacing, borderRadius, text, font } from "../../../styles/theme";
import Text, { SmallText } from "../../../styles/Text";
import { UtilizationBarProps } from "./DashboardTypes";

export const UtilizationBarWidth = "100px";

export const VerticalCardDivider = styled.View`
  border-right-width: ${borderWidth.small};
  border-right-color: ${color.greyLightest};
  margin-right: ${spacing.medium};
`;

export const DashboardOverviewHeader = styled(OverviewHeader)`
  flex-direction: row;
  align-items: center;
  margin-left: ${spacing.large};
  z-index: 100;
`;

export const HorizontalHeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
`;

export const OccupancyContainer = styled.View`
  flex-direction: row;
`;

export const OccupancyWrapper = styled.View`
  justify-content: center;
  margin: ${spacing.extraSmall} 0 ${spacing.small} 0;
  flex: 1;
`;

export const CardItemList = styled.View`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.medium};
  justify-content: center;
`;

export const UtilizationBarContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UtilizationBarWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UtilizationBar = styled.View`
  border-radius: ${borderRadius.full};
  background-color: ${color.greyLightest};
  height: ${spacing.small};
  width: ${UtilizationBarWidth};
  position: relative;
  display: flex;
  overflow: hidden;
`;

export const SimpleStatsWrapper = styled.View`
  flex-direction: row;
`;

export const SimpleStatWrapper = styled.View`
  flex-direction: row;
  flex: 1;
  margin-right: 0;
  align-items: center;
  &:* {
    margin-right: ${spacing.medium};
  }
`;

export const Number = styled(Text)`
  font-size: ${text.big.size};
  font-family: ${font.defaultSemiBold};
  margin-left: ${spacing.medium};
  margin-right: ${spacing.medium};
`;

export const StatTextWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

export const StatText = styled(Text)`
  font-family: ${font.defaultRegular};
`;


export const LargeStatText = styled(Text)`
  font-size: ${text.big.size};
  font-family: ${font.defaultSemiBold};
  margin-right: ${spacing.extraSmall};
  margin-left: ${spacing.medium};
`;

export const UtilizationUsage = styled(UtilizationBar)<UtilizationBarProps>`
  position: absolute;
  right: 0;
  ${({ usage }) =>
    color &&
    css`
      width: ${usage}%;
    `}

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const UtilizationPercentage = styled(SmallText)`
  margin-left: ${spacing.medium};
  text-align: right;
  width: ${spacing.large};
`;

export const Percentage = styled(Text)`
  font-size: ${text.big.size};
  font-family: ${font.defaultSemiBold};
`;

export const PercentageWrapper = styled.View`
  flex-direction: row;
`;

export const PercentageMark = styled(Text)`
  font-size: ${text.large.size};
`;

export const StatisticList = styled.View`
  flex-direction: row;
  padding: ${spacing.extraSmall} 0;
`;

export const StatisticListItemWrapper = styled.View`
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
`;
