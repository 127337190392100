import styled, { css } from "styled-components/native";
import { spacing } from "../../styles/theme";

interface ColumnProps {
  columns?: number;
}

export const Column = styled.View<ColumnProps>`
  padding: 0 ${spacing.medium};
  ${({ columns }) =>
    columns &&
    css`
      width: calc(100% / ${columns});
    `}
`;

export const ChartColumn = styled.View<ColumnProps>`
  padding: 0 ${spacing.medium};
  ${({ columns }) =>
    columns &&
    css`
      width: calc(100% / ${columns} * 2);
    `}
`;

const Grid = styled.View`
  flex-direction: row;
  margin: 0 -${spacing.medium};
  width: calc(100%+${spacing.large});
`;

export default Grid;
