module["exports"] = [
  "dog",
  "cat", 
  "snake", 
  "bear", 
  "lion", 
  "cetacean", 
  "insect", 
  "crocodilia", 
  "cow", 
  "bird", 
  "fish", 
  "rabbit", 
  "horse"
]