import React, { FC, SVGProps } from "react";
import { color } from "../../styles/theme";

export const CaretUpIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="9px" height="5px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="caret-up"
           transform="translate(4.251580, 2.496778) rotate(180.000000) translate(-4.251580, -2.496778) translate(0.000000, 0.496778)"
           fill="currentColor" fillRule="nonzero">
          <path
            d="M0,0 L8.50316062,0 L4.68098992,3.8221707 C4.44381257,4.05927643 4.05934805,4.05927643 3.8221707,3.8221707 L0,0 Z"
            id="Path"/>
        </g>
      </g>
    </svg>
  );
};

export const CaretDownIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="9px" height="5px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="caret-down" transform="translate(0.000000, 0.496778)" fill="currentColor" fillRule="nonzero">
          <path
            d="M0,0 L8.50316062,0 L4.68098992,3.8221707 C4.44381257,4.05927643 4.05934805,4.05927643 3.8221707,3.8221707 L0,0 Z"
            id="Path"/>
        </g>
      </g>
    </svg>
  );
};

export const CellHandleIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="5px"
      height="22px"
      viewBox="0 0 5 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      color={color.grey}
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <rect id="Rectangle" x="0" y="0" width="2" height="2" />
          <rect id="Rectangle" x="0" y="4" width="2" height="2" />
          <rect id="Rectangle" x="0" y="8" width="2" height="2" />
          <rect id="Rectangle" x="0" y="12" width="2" height="2" />
          <rect id="Rectangle" x="0" y="16" width="2" height="2" />
          <rect id="Rectangle" x="0" y="20" width="2" height="2" />
          <rect id="Rectangle" x="3" y="0" width="2" height="2" />
          <rect id="Rectangle" x="3" y="4" width="2" height="2" />
          <rect id="Rectangle" x="3" y="8" width="2" height="2" />
          <rect id="Rectangle" x="3" y="12" width="2" height="2" />
          <rect id="Rectangle" x="3" y="16" width="2" height="2" />
          <rect id="Rectangle" x="3" y="20" width="2" height="2" />
        </g>
      </g>
    </svg>
  );
};

export const MailIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="13px"
      height="10px"
      viewBox="0 0 13 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="send-invitation" transform="translate(0.000000, -27.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(0.000000, 27.267000)">
            <path d="M12.5357055,0 L0.464294458,0 L6.5000136,4.97157159 L12.6039609,0.0139285617 C12.5816263,0.00739954841 12.5588019,0.00274762643 12.5357055,0 Z" id="Path" />
            <path d="M6.79294867,5.9316902 C6.62188852,6.07176474 6.37577192,6.07176474 6.20471177,5.9316902 L0,0.81990805 L0,8.82140428 C0,9.07783128 0.207867461,9.28569874 0.464294458,9.28569874 L12.5357055,9.28569874 C12.7921325,9.28569874 13,9.07783128 13,8.82140428 L13,0.888625915 L6.79294867,5.9316902 Z" id="Path" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const DeleteIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="10px"
      height="14px"
      viewBox="0 0 10 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="delete" transform="translate(0.250000, -24.000000)" fill="currentColor" fillRule="nonzero">
          <g id="trash" transform="translate(0.000000, 24.450000)">
            <path d="M7.81771473,13 L1.67563945,13 C1.35474143,13 1.08902044,12.7517033 1.06724003,12.4308053 L0.540154138,4.59566626 C0.516921702,4.24282363 0.795710935,3.94515805 1.14855356,3.94515805 L8.34480063,3.94515805 C8.69764325,3.94515805 8.97643248,4.24427566 8.95320004,4.59566626 L8.42611415,12.4308053 C8.40433374,12.7517033 8.13861276,13 7.81771473,13 Z M9.55433933,2.8024126 L0.0145202725,2.8024126 C0.00580810901,2.8024126 0,2.79660449 0,2.78789233 L0,1.11951301 C0,1.11080085 0.00580810901,1.10499274 0.0145202725,1.10499274 L9.55433933,1.10499274 C9.56305149,1.10499274 9.5688596,1.11080085 9.5688596,1.11951301 L9.5688596,2.7864403 C9.5688596,2.79515246 9.56305149,2.8024126 9.55433933,2.8024126 L9.55433933,2.8024126 Z" id="Shape" />
            <path d="M7.32402547,1.92538814 L2.22595778,1.92538814 C2.21724562,1.92538814 2.21143751,1.91958003 2.21143751,1.91086787 L2.21143751,0.0145202725 C2.21143751,0.00580810901 2.21724562,0 2.22595778,0 L7.32402547,0 C7.33273763,0 7.33854574,0.00580810901 7.33854574,0.0145202725 L7.33854574,1.91086787 C7.33854574,1.918128 7.33273763,1.92538814 7.32402547,1.92538814 Z" id="Path" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CancelIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.016162, -25.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(0.000000, 25.000000)">
            <path d="M11.0959596,1.90357143 C8.55748322,-0.634645905 4.44203143,-0.634505924 1.90372776,1.90388409 C-0.634575919,4.4422741 -0.634575919,8.5577259 1.90372776,11.0961159 C4.44203143,13.6345059 8.55748322,13.6346459 11.0959596,11.0964286 C12.3150895,9.87742305 13,8.2240221 13,6.5 C13,4.7759779 12.3150895,3.12257695 11.0959596,1.90357143 L11.0959596,1.90357143 Z M8.82095957,8.14357143 C8.91472222,8.23135474 8.96793054,8.35405801 8.96793054,8.4825 C8.96793054,8.61094199 8.91472222,8.73364526 8.82095957,8.82142857 C8.73334011,8.9083366 8.61472524,8.95678492 8.49131671,8.9560792 C8.36951606,8.95555857 8.25279487,8.90720265 8.16631671,8.82142857 L6.499531,7.15464286 L4.85595957,8.82142857 C4.76948142,8.90720265 4.65276023,8.95555857 4.53095957,8.9560792 C4.40755104,8.95678492 4.28893617,8.9083366 4.20131671,8.82142857 C4.02130219,8.64033975 4.02130219,8.34787453 4.20131671,8.16678571 L5.84488814,6.5 L4.20131671,4.85642857 C4.0435632,4.67221844 4.05416959,4.39762413 4.22566236,4.22613136 C4.39715514,4.05463859 4.67174944,4.0440322 4.85595957,4.20178571 L6.499531,5.84535714 L8.14310243,4.20178571 C8.26004311,4.0806982 8.43270018,4.03119583 8.59603539,4.07192568 C8.75937059,4.11265552 8.88856944,4.23742974 8.93496396,4.3992471 C8.98135848,4.56106446 8.93790026,4.73534106 8.82095957,4.85642857 L7.15417386,6.5 L8.82095957,8.14357143 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const GridIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(-12.000000, -24.000000)" fill="currentColor" fillRule="nonzero">
          <g id="grid-(1)" transform="translate(12.000000, 24.000000)">
            <path d="M3.42105263,0 L0.263157895,0 C0.117819803,0 0,0.117819803 0,0.263157895 L0,3.42105263 C0,3.56639072 0.117819803,3.68421053 0.263157895,3.68421053 L3.42105263,3.68421053 C3.56639072,3.68421053 3.68421053,3.56639072 3.68421053,3.42105263 L3.68421053,0.263157895 C3.68421053,0.117819803 3.56639072,0 3.42105263,0 Z M3.15789474,3.15789474 L0.526315789,3.15789474 L0.526315789,0.526315789 L3.15789474,0.526315789 L3.15789474,3.15789474 Z" id="Shape" />
            <path d="M8.57894737,0 L5.42105263,0 C5.27571454,0 5.15789474,0.117819803 5.15789474,0.263157895 L5.15789474,3.42105263 C5.15789474,3.56639072 5.27571454,3.68421053 5.42105263,3.68421053 L8.57894737,3.68421053 C8.72428546,3.68421053 8.84210526,3.56639072 8.84210526,3.42105263 L8.84210526,0.263157895 C8.84210526,0.117819803 8.72428546,0 8.57894737,0 Z M8.31578947,3.15789474 L5.68421053,3.15789474 L5.68421053,0.526315789 L8.31578947,0.526315789 L8.31578947,3.15789474 Z" id="Shape" />
            <path d="M13.7368421,0 L10.5789474,0 C10.4336093,0 10.3157895,0.117819803 10.3157895,0.263157895 L10.3157895,3.42105263 C10.3157895,3.56639072 10.4336093,3.68421053 10.5789474,3.68421053 L13.7368421,3.68421053 C13.8821802,3.68421053 14,3.56639072 14,3.42105263 L14,0.263157895 C14,0.117819803 13.8821802,0 13.7368421,0 Z M13.4736842,3.15789474 L10.8421053,3.15789474 L10.8421053,0.526315789 L13.4736842,0.526315789 L13.4736842,3.15789474 Z" id="Shape" />
            <path d="M3.42105263,5.15789474 L0.263157895,5.15789474 C0.117819803,5.15789474 0,5.27571454 0,5.42105263 L0,8.57894737 C0,8.72428546 0.117819803,8.84210526 0.263157895,8.84210526 L3.42105263,8.84210526 C3.56639072,8.84210526 3.68421053,8.72428546 3.68421053,8.57894737 L3.68421053,5.42105263 C3.68421053,5.27571454 3.56639072,5.15789474 3.42105263,5.15789474 Z M3.15789474,8.31578947 L0.526315789,8.31578947 L0.526315789,5.68421053 L3.15789474,5.68421053 L3.15789474,8.31578947 Z" id="Shape" />
            <path d="M8.57894737,5.15789474 L5.42105263,5.15789474 C5.27571454,5.15789474 5.15789474,5.27571454 5.15789474,5.42105263 L5.15789474,8.57894737 C5.15789474,8.72428546 5.27571454,8.84210526 5.42105263,8.84210526 L8.57894737,8.84210526 C8.72428546,8.84210526 8.84210526,8.72428546 8.84210526,8.57894737 L8.84210526,5.42105263 C8.84210526,5.27571454 8.72428546,5.15789474 8.57894737,5.15789474 Z M8.31578947,8.31578947 L5.68421053,8.31578947 L5.68421053,5.68421053 L8.31578947,5.68421053 L8.31578947,8.31578947 Z" id="Shape" />
            <path d="M13.7368421,5.15789474 L10.5789474,5.15789474 C10.4336093,5.15789474 10.3157895,5.27571454 10.3157895,5.42105263 L10.3157895,8.57894737 C10.3157895,8.72428546 10.4336093,8.84210526 10.5789474,8.84210526 L13.7368421,8.84210526 C13.8821802,8.84210526 14,8.72428546 14,8.57894737 L14,5.42105263 C14,5.27571454 13.8821802,5.15789474 13.7368421,5.15789474 Z M13.4736842,8.31578947 L10.8421053,8.31578947 L10.8421053,5.68421053 L13.4736842,5.68421053 L13.4736842,8.31578947 Z" id="Shape" />
            <path d="M3.42105263,10.3157895 L0.263157895,10.3157895 C0.117819803,10.3157895 0,10.4336093 0,10.5789474 L0,13.7368421 C0,13.8821802 0.117819803,14 0.263157895,14 L3.42105263,14 C3.56639072,14 3.68421053,13.8821802 3.68421053,13.7368421 L3.68421053,10.5789474 C3.68421053,10.4336093 3.56639072,10.3157895 3.42105263,10.3157895 Z M3.15789474,13.4736842 L0.526315789,13.4736842 L0.526315789,10.8421053 L3.15789474,10.8421053 L3.15789474,13.4736842 Z" id="Shape" />
            <path d="M8.57894737,10.3157895 L5.42105263,10.3157895 C5.27571454,10.3157895 5.15789474,10.4336093 5.15789474,10.5789474 L5.15789474,13.7368421 C5.15789474,13.8821802 5.27571454,14 5.42105263,14 L8.57894737,14 C8.72428546,14 8.84210526,13.8821802 8.84210526,13.7368421 L8.84210526,10.5789474 C8.84210526,10.4336093 8.72428546,10.3157895 8.57894737,10.3157895 Z M8.31578947,13.4736842 L5.68421053,13.4736842 L5.68421053,10.8421053 L8.31578947,10.8421053 L8.31578947,13.4736842 Z" id="Shape" />
            <path d="M13.7368421,10.3157895 L10.5789474,10.3157895 C10.4336093,10.3157895 10.3157895,10.4336093 10.3157895,10.5789474 L10.3157895,13.7368421 C10.3157895,13.8821802 10.4336093,14 10.5789474,14 L13.7368421,14 C13.8821802,14 14,13.8821802 14,13.7368421 L14,10.5789474 C14,10.4336093 13.8821802,10.3157895 13.7368421,10.3157895 Z M13.4736842,13.4736842 L10.8421053,13.4736842 L10.8421053,10.8421053 L13.4736842,10.8421053 L13.4736842,13.4736842 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  );
};
