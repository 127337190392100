module["exports"] = [
  "Livros",
  "Filmes",
  "Música",
  "Jogos",
  "Electrónica",
  "Computadores",
  "Casa",
  "Jardim",
  "Ferramentas",
  "Mercearia",
  "Saúde",
  "Beleza",
  "Brinquedos",
  "Crianças",
  "Bebé",
  "Roupas",
  "Sapatos",
  "Jóias",
  "Desporto",
  "Ar Livre",
  "Automóveis",
  "Industrial"
];
