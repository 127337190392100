module["exports"] = [
  "تهران",
  "مشهد",
  "اصفهان",
  "کرج",
  "تبریز",
  "شیراز",
  "اهواز",
  "قم",
  "کرمانشاه",
  "ارومیه",
  "رشت",
  "زاهدان",
  "کرمان",
  "اراک",
  "همدان",
  "یزد",
  "اردبیل",
  "بندرعباس",
  "اسلام‌شهر",
  "زنجان",
  "قزوین",
  "سنندج",
  "خرم‌آباد",
  "گرگان",
  "ساری",
  "ملارد",
  "قدس",
  "کاشان",
  "گلستان",
  "شهریار",
  "دزفول",
  "خمینی‌شهر",
  "بروجرد",
  "نیشابور",
  "سبزوار",
  "نجف‌آباد",
  "آمل",
  "بابل",
  "ورامین",
  "آبادان",
  "پاکدشت",
  "خوی",
  "ساوه",
  "بجنورد",
  "قائم‌شهر",
  "بوشهر",
  "قرچک",
  "سیرجان",
  "بیرجند",
  "ایلام"
];
