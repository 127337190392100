import { useState } from "react";

import axios from "axios";
import {
  Configuration,
  DefaultApiFactory,
} from "../../bookmydesk-api-sdk-typescript-axios";
import { REACT_APP_API_URL } from "@env";

import { useAuth } from "../context/authContext";

import { refreshAccessToken } from "../services/clientService";

export const useClient = () => {
  const { authState, setTokens } = useAuth();

  const axiosApiInstance = axios.create();
  axiosApiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (
        error.response?.data.message === "Login Expired" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const { access_token, refresh_token } = await refreshAccessToken();
        setTokens(access_token, refresh_token);
        originalRequest.headers["Authorization"] = "Bearer " + access_token;
        return axiosApiInstance(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  const configuration = new Configuration({
    accessToken: authState.accessToken,
    basePath: REACT_APP_API_URL,
  });

  const [client] = useState(
    DefaultApiFactory(configuration, undefined, axiosApiInstance)
  );

  return client;
};
