module["exports"] = [
  "драйвер",
  "протокол",
  "ограничитель",
  "интерфейс",
  "микрочип",
  "код",
  "парсер",
  "сокет",
  "кортеж",
  "порт",
  "ключ",
  "массив",
  "интерфейс",
  "объект",
  "сенсор",
  "брандмауэр",
  "винчестер",
  "пиксель",
  "механизм сигнализации",
  "поглотитель",
  "монитор",
  "продукт",
  "передатчик",
  "блок разведения",
  "канал",
  "конденсатор",
  "оператор"
];
