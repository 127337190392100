import moment from "moment";
import { TimePeriod } from "./DashboardTypes";

export enum DashboardType {
    HISTORIC,
    REALTIME,
  } 

const oneFullDay = 1000 * 60 * 60 * 24;

export const today = new Date();

export const timePeriods: TimePeriod[] = [
    {
      label: "admin.dashboard.today",
      start: moment(today).toDate(),
      end: moment(today).toDate(),
    },
    {
      label: "admin.dashboard.yesterday",
      start: moment(new Date(new Date().getTime() - oneFullDay)).toDate(),
      end: moment(new Date(new Date().getTime() - oneFullDay)).toDate(),
    },
    {
      label: "admin.dashboard.tomorrow",
      start: moment(new Date(new Date().getTime() + oneFullDay)).toDate(),
      end: moment(new Date(new Date().getTime() + oneFullDay)).toDate(),
    },
    {
      label: "admin.dashboard.thisWeek",
      start: moment().startOf("isoWeek").toDate(),
      end: moment().endOf("isoWeek").toDate(),
    },
    {
      label: "admin.dashboard.nextWeek",
      start: moment().endOf("isoWeek").add(1, "days").toDate(),
      end: moment().endOf("isoWeek").add(1, "weeks").toDate(),
    },
    {
      label: "admin.dashboard.lastWeek",
      start: moment().startOf("isoWeek").subtract(1, "weeks").toDate(),
      end: moment().startOf("isoWeek").subtract(1, "days").toDate(),
    },
    {
      label: "admin.dashboard.thisMonth",
      start: moment().startOf("month").toDate(),
      end: moment().endOf("month").toDate(),
    },
    {
      label: "admin.dashboard.nextMonth",
      start: moment().endOf("month").add(1, "days").toDate(),
      end: moment().endOf("month").add(1, "months").toDate(),
    },
    {
      label: "admin.dashboard.lastMonth",
      start: moment().startOf("month").subtract(1, "months").toDate(),
      end: moment().startOf("month").subtract(1, "days").toDate(),
    },
    {
      label: "admin.dashboard.last6Months",
      start: moment(today).subtract(6, "months").toDate(),
      end: moment(today).toDate(),
    },
    {
      label: "admin.dashboard.thisYear",
      start: moment().startOf("year").toDate(),
      end: moment(today).toDate(),
    },
    {
      label: "admin.dashboard.lastYear",
      start: moment().startOf("year").subtract(1, "years").toDate(),
      end: moment().startOf("year").subtract(1, "days").toDate(),
    },
  ];