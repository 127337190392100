module["exports"] = [
  "כסא מנהלים ארגונומי מרופד בעור שחור מלוכדות ובמושב ובגב מרופד PVC לנוחות ותמיכה לאורך כל היום",
  "מערך הרכב מורכב מעיצוב מנוע קדמי, עם תיבות הילוכים מסוג ציר טרנסוולר המותקנות בחלק האחורי של המנוע והנעה עם ארבעה גלגלים",
  "חדש ABC 13 9370, 13.3, דור 5 CoreA5-8250U, 8GB RAM, 256GB SSD, כוח UHD גרפיקה, OS 10 Home, OS Office A & J 2016",
  "מקלדת מייפל גיימינג דקה ופשוטה מבית Dev Byte מגיעה עם גוף אלגנטי ותאורת RGB LED בגוון 7 צבעים לפונקציונליות חכמה",
  "אפולוטק B340 הוא עכבר אלחוטי במחיר סביר עם קישוריות אמינה, חיי סוללה של 12 חודשים ועיצוב מודרני",
  "נאגסאקי לנדר הוא השם המסחרי המסחרי של מספר סדרות של אופני ספורט נגסאקי, שהחלו ב- ABC800J משנת 1984",
  "הכדורגל טוב לאימונים ולמטרות פנאי",
  "כפפות שוער רשת קרבוניט מעוצבות באופן ארגונומי בכדי להעניק התאמה קלה",
  "טכנולוגיית שחיקת הדחיסה המתקדמת ביותר של בוסטון מגבירה את החמצון בשרירים, מייצבת שרירים פעילים",
  "מגוון חדש של חולצות רשמיות מעוצבות תוך התחשבות בך. עם התאמות ועיצוב שיגרמו לך להתבלט",
  "המגוון היפה של תפוח נטורל שיש בו שילוב מרגש של מרכיבים טבעיים. עם הטוב של 100% מרכיבים טבעיים",
  "נעלי אנדי נועדו לזכור עמידות כמו גם טרנדים, מגוון הנעליים והסנדלים המסוגננים ביותר"
];