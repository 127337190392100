import {
  CompanyProperties,
  InlineResponse400Errors,
} from '../../../../../bookmydesk-api-sdk-typescript-axios';

import buildingIcon from '../../../../assets/icons/icon_company_admin.svg';
import settingIcon from '../../../../assets/icons/icon_settings_admin.svg';
import officeChairIcon from '../../../../assets/icons/office_chair.svg';
import visitorIcon from '../../../../assets/icons/icon_visitors_admin.svg';
import reservationsIcon from '../../../../assets/icons/icon_reservations_admin.svg';

import Button from '../../../../components/Button';
import Container from '../../../../components/Container';
import Grid, { Column } from '../../../../components/Grid';
import HeaderCard from '../../../../components/HeaderCard';
import InputValidationErrors from '../../../../components/InputValidationErrors';
import LabeledInput from '../../../../components/LabeledInput';
import LoadOverlay from '../../../../components/LoadOverlay';
import Notification from '../../../../components/Notification';
import PageSelector from '../../../../components/PageSelector';
import PickerInput from '../../../../components/PickerInput';
import Pill from '../../../../components/Pill';
import Toggle from '../../../../components/Toggle';
import { useAuth } from '../../../../context/authContext';
import { InputValidationError, useApi } from '../../../../hooks/useApi';
import { useClient } from '../../../../hooks/useClient';
import useQuery from '../../../../hooks/useQuery';
import { t } from '../../../../i18n';
import InnerCard from '../../../../styles/Card/InnerCard';
import Input from '../../../../styles/Input';
import { OverviewHeader } from '../../../../styles/Overview';
import Text, { SmallText, ExtraSmallText } from '../../../../styles/Text';
import { ExtraSmallTextGood } from '../../../../styles/Text/extraSmallText';
import { color, spacing } from '../../../../styles/theme';
import { SmallTitle } from '../../../../styles/Title';
import Title from '../../../../styles/Title/title';
import Link from "../../components/Link";
import { isValidEmail } from '../../../../utils';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Linking, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
const Spacer = styled.View`
  margin-bottom: 10px;
`;

const SeatSettingRow = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const PickerInputWrapper = styled(View)`
  width: 45%;
  margin-right: ${spacing.medium};
`;

const HeaderCardTitleSuffixWrapper = styled.View`
  margin-left: auto;
  margin-right: -${spacing.medium};
`;

const expirationItems = [
  ...[0, 5, 10, 15, 20, 25, 30, 45, 60, 90].map((minutes) => ({
    label: `${minutes} ${t("general.minutes")}`,
    value: `${minutes}`,
  })),
];

const CardTitle = styled(Text)`
  font-size: 14px;
  margin-bottom: 1.2rem;
  line-height: 28px;
  color: #161616;
`;

const ArchiveSettings: FC = () => {
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const { companyPages, userState } = useAuth();
  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const query = useQuery();

  /* anonimizing and archiving of reservation data */
  const [reservationAnonymousDays, setReservationAnonymousDays] = useState("");
  const [archiveOnReservations, setArchiveOnReservations] = useState(false);
  const [showArchiveOnReservations, setShowArchiveOnReservations] = useState(true);
  const [defineArchiveOnReservations, setDefineArchiveOnReservations] = useState(false);
  const [keepReservationAnonymousDays, setKeepReservationAnonymousDays] = useState(365);

  const [visitorAnonymousDays, setVisitorAnonymousDays] = useState("");
  const [archiveOnVisitors, setArchiveOnVisitors] = useState(false);
  const [showArchiveOnVisitors, setShowArchiveOnVisitors] = useState(true);
  const [defineArchiveOnVisitors, setDefineArchiveOnVisitors] = useState(false);
  const [keepVisitorAnonymousDays, setKeepVisitorAnonymousDays] = useState(60);

  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const userCompany = userState?.companyIds[0];

  useEffect(() => {
    if (!userCompany) {
      return;
    }

    const fetchCompany = async () =>
      await handleRequest(client.getCompany(userCompany));

    fetchCompany().then((result) => {
      if (!result || !result.data.result) {
        return;
      }

      const { company } = result.data.result;

      setReservationAnonymousDays(company.reservationAnonymousDays?.toString());
      setVisitorAnonymousDays(company.visitorAnonymousDays?.toString());

      if (company.reservationAnonymousDays === null) {
        setShowArchiveOnReservations(false);
      } else {
        setKeepReservationAnonymousDays(company.reservationAnonymousDays?.toString());
      }
      if (company.visitorAnonymousDays === null) {
        setShowArchiveOnVisitors(false);
      } else {
        setKeepVisitorAnonymousDays(company.visitorAnonymousDays?.toString());
      }

    ;
    });
  }, [client, handleRequest, userCompany]);

  const isFormValid = useCallback(
    () =>

    []
  );

  const updateArchiveOnReservations = useCallback(
    (defineArchiveOnReservations) => {
      if (defineArchiveOnReservations === false) {
        setReservationAnonymousDays("");
        setShowArchiveOnReservations(false);
      }
      if (defineArchiveOnReservations === true) {
        setReservationAnonymousDays(keepReservationAnonymousDays);
        setShowArchiveOnReservations(true);
      }
    },
    [setReservationAnonymousDays,keepReservationAnonymousDays]
  );
  const updateArchiveOnVisitors = useCallback(
    (defineArchiveOnVisitors) => {
      if (defineArchiveOnReservations === false) {
        setVisitorAnonymousDays("");
        setShowArchiveOnVisitors(false);
      }
      if (defineArchiveOnVisitors === true) {
        setVisitorAnonymousDays(keepVisitorAnonymousDays);
        setShowArchiveOnVisitors(true);
      }
    },
    [setVisitorAnonymousDays,keepVisitorAnonymousDays]
  );

  const updateReservationAnonymousDays = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setReservationAnonymousDays(days);
    },
    [setReservationAnonymousDays, defineArchiveOnReservations]
  );
  const updateVisitorAnonymousDays = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setVisitorAnonymousDays(days);
    },
    [setVisitorAnonymousDays]
  );

  const submitForm = useCallback(async () => {
    const company: CompanyProperties = {

      reservationAnonymousDays: parseInt(reservationAnonymousDays),
      visitorAnonymousDays: parseInt(visitorAnonymousDays),

    };
    try {
      const success = await handleRequest(
        client.updateCompany(userCompany, company)
      );

      if (success) {
        setShowUpdateMessage(true);
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [reservationAnonymousDays, visitorAnonymousDays]);

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
        <OverviewHeader>
          <Title>{t("admin.company.form.editTitle", { name })}</Title>
          <Button disabled={!isFormValid()} onPress={submitForm}>
            {t("admin.company.form.editSubmit")}
          </Button>
        </OverviewHeader>
        <PageSelector pages={companyPages} />
        {Boolean(error) && (
          <InputValidationErrors
            errors={errors}
            closeNotification={dismissError}
          />
        )}
        {showUpdateMessage ? (
          <Notification
            type="success"
            closeNotification={() => setShowUpdateMessage(false)}
          >
            {t("general.savedSuccess")}
          </Notification>
        ) : null}

        <Grid>
          <Column columns={3}>
            <HeaderCard
              icon={settingIcon}
              title={t("admin.company.form.archivingReservations")}
              disableHorizontalDivider={!showArchiveOnReservations}
              titleSuffix={
                <HeaderCardTitleSuffixWrapper>
                  <Toggle
                    onChange={updateArchiveOnReservations}
                    toggleValue={showArchiveOnReservations}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
            >
            {showArchiveOnReservations && (
              <>
              <ExtraSmallTextGood
                hasMarginBottom
                italic
                color={color.bmdRed}
              >
                {t("admin.company.form.helper.explainReservationAnonymousDays")}
              </ExtraSmallTextGood>
              <LabeledInput
                label={t("admin.company.form.reservationAnonymousDays")}
                helperText={t("admin.company.form.helper.reservationAnonymousDays")}
              >
                <Input
                  maxLength={3}
                  onChangeText={updateReservationAnonymousDays}
                  value={reservationAnonymousDays}
                  placeholder={t("admin.company.form.reservationAnonymousDays")}
                />
              </LabeledInput>
              </>
            )}
            </HeaderCard>
          </Column>
          <Column columns={3}>
            <HeaderCard
              icon={visitorIcon}
              title={t("admin.company.form.archivingVisitors")}
              disableHorizontalDivider={!showArchiveOnVisitors}
              titleSuffix={
                <HeaderCardTitleSuffixWrapper>
                  <Toggle
                    onChange={updateArchiveOnVisitors}
                    toggleValue={showArchiveOnVisitors}
                  />
                </HeaderCardTitleSuffixWrapper>
              }
            >
            {showArchiveOnVisitors && (
              <>
              <ExtraSmallTextGood
                hasMarginBottom
                italic
              >
                {t("admin.company.form.helper.explainVisitorAnonymousDays")}
              </ExtraSmallTextGood>
              <LabeledInput
                label={t("admin.company.form.visitorAnonymousDays")}
                helperText={t("admin.company.form.helper.visitorAnonymousDays")}
              >
                <Input
                  maxLength={3}
                  onChangeText={updateVisitorAnonymousDays}
                  value={visitorAnonymousDays}
                  placeholder={t("admin.company.form.visitorAnonymousDays")}
                />
              </LabeledInput>
              </>
            )}
            </HeaderCard>
          </Column>
        </Grid>
      </Container>
    </>
  );
};

export default ArchiveSettings;
