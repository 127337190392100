module["exports"] = [
  "Acesso",
  "Alameda",
  "Avenida",
  "Azinhaga",
  "Bairro",
  "Beco",
  "Calçada",
  "Caminho",
  "Escadas",
  "Estrada",
  "Jardim",
  "Ladeira",
  "Largo",
  "Praça",
  "Praceta",
  "Quinta",
  "Rua",
  "Travessa",
  "Urbanização",
  "Viela"
];
