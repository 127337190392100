import styled from "styled-components/native";

import { spacing } from "../theme";
import { ListRow } from "./listRow";

interface ListHeaderRowProps {
  preserveHeaderBorderTop?: boolean;
  headerPaddingTop?: number;
  headerPaddingBottom?: number;
}

export const ListHeaderRow = styled(ListRow)<ListHeaderRowProps>`
  padding: 0;

  ${({ preserveHeaderBorderTop })=>{
      return `border-top-width: ${preserveHeaderBorderTop ? '1px' : '0'}`;
  }}

  ${({ preserveHeaderBorderTop })=>{
      return `padding-top: ${preserveHeaderBorderTop ? '16px' : ''}`;
  }}

`;
