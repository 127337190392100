import React, { FC, useEffect, useCallback, useState } from "react";
import { ViewStyle } from "react-native";

import Select, { SelectItemInterface } from "../Select";
import styled from "styled-components/native";
import { spacing } from "../../styles/theme";
import {
  dayFormat,
  monthFormat,
  yearFormat,
  dateFormat,
  days,
  months,
  years,
  getDaysOfMonth,
} from "./dateSelector.constant";
import moment from "moment";

const DateSelectorWrapper = styled.View<ViewStyle>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${({ zIndex }) => `z-index: ${zIndex ? zIndex : 1}`}
`;

const SelectorWrapper = styled.View`
  flex: 2;
`;
const MiddleSelectorWrapper = styled.View`
  flex: 3;
  margin: 0 ${spacing.medium};
`;

interface DateSelectorProps {
  style?: ViewStyle;
  date: string;
  onSelect(date: string): void;
}

const DateSelector: FC<DateSelectorProps> = ({ style, date, onSelect }) => {
  const [dayList, setDayList] = useState<Array<SelectItemInterface>>([]);
  const [monthList, setMonthList] = useState<Array<SelectItemInterface>>([]);
  const [yearList, setYearList] = useState<Array<SelectItemInterface>>([]);
  const [selectedDay, setSelectedDay] = useState(
    moment(date, dateFormat).format(dayFormat)
  );
  const [selectedMonth, setSelectedMonth] = useState(
    moment(date, dateFormat).format(monthFormat)
  );
  const [selectedYear, setSelectedYear] = useState(
    moment(date, dateFormat).format(yearFormat)
  );

  console.log(selectedYear, selectedMonth, selectedDay);

  const createList = useCallback(
    (
      items: SelectItemInterface[],
      selectedValue: string,
      setList: (name: SelectItemInterface[]) => void
    ) => {
      const newList = items.map((item) => ({
        ...item,
        isActive: parseInt(item.value) === parseInt(selectedValue),
      }));
      setList(newList);
    },
    []
  );

  useEffect(() => {
    createList(days, selectedDay, setDayList);
    createList(months, selectedMonth, setMonthList);
    createList(years, selectedYear, setYearList);
  }, [selectedYear, selectedMonth, selectedDay, createList]);

  const updateDay = useCallback(
    (newValue: SelectItemInterface[]) => {
      const newDay = newValue.length ? newValue[0].value : "";
      if (newDay) {
        setSelectedDay(newDay);
      }
    },
    [setSelectedDay]
  );

  const updateMonth = useCallback(
    (newValue: SelectItemInterface[]) => {
      const newMonth = newValue.length ? newValue[0].value : "";
      if (newMonth) {
        setSelectedMonth(newMonth);
      }
    },
    [setSelectedMonth]
  );

  const updateYear = useCallback(
    (newValue: SelectItemInterface[]) => {
      const newYear = newValue.length ? newValue[0].value : "";
      if (newYear) {
        setSelectedYear(newYear);
      }
    },
    [setSelectedYear]
  );

  useEffect(() => {
    const monthYear = `${selectedYear}-${selectedMonth}`;
    const daysOfTheMonth = getDaysOfMonth(monthYear);
    const lastDayMonth = daysOfTheMonth[daysOfTheMonth.length - 1].value;
    const newList = daysOfTheMonth.map((monthDays) => ({
      ...monthDays,
      isActive: monthDays.value === selectedDay,
    }));
    setDayList(newList);
    console.log(
      "Setting last day month?",
      selectedDay,
      lastDayMonth,
      parseInt(selectedDay) > parseInt(lastDayMonth)
    );
    if (parseInt(selectedDay) > parseInt(lastDayMonth)) {
      setSelectedDay(lastDayMonth);
      return;
    }

    onSelect(`${selectedYear}-${selectedMonth}-${selectedDay}`);
  }, [onSelect, selectedDay, selectedMonth, selectedYear]);

  return (
    <DateSelectorWrapper {...style}>
      <SelectorWrapper>
        {Boolean(dayList.length) && (
          <Select required items={dayList} onChange={updateDay} />
        )}
      </SelectorWrapper>
      <MiddleSelectorWrapper>
        {Boolean(monthList.length) && (
          <Select required items={monthList} onChange={updateMonth} />
        )}
      </MiddleSelectorWrapper>
      <SelectorWrapper>
        {Boolean(yearList.length) && (
          <Select required items={yearList} onChange={updateYear} />
        )}
      </SelectorWrapper>
    </DateSelectorWrapper>
  );
};

export default DateSelector;
