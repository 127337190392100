module["exports"] = [
  "قرمز",
  "سبز",
  "آبی",
  "زرد",
  "بنفش",
  "فیروزه ای",
  "سفید",
  "سیاه",
  "قهوه ای",
  "نارنجی",
  "صورتی",
  "خاکستری",
  "خرمایی",
  "آبی آسمانی",
  "ارغوانی",
  "زیتونی",
  "نیلی",
  "طلایی",
  "لاجوردی",
  "نقره ای"
];
