import React, {
  FC,
  useEffect,
  useCallback,
  useState
} from "react";
import {
  useHistory,
  useParams
} from "../../../routing";
import {
  ActivityIndicator,
  FlatList,
  View
} from 'react-native';
import { t } from "../../../i18n";
import {
  UserGroupWithoutId,
  InlineResponse400Errors,
  UserGroupLink,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import styled from "styled-components/native";

import Button from "../../../components/Button";
import Container from "../../../components/Container";
import Grid, { Column } from '../../../components/Grid';
import HeaderCard from '../../../components/HeaderCard';
import LabeledInput from "../../../components/LabeledInput";
import Checkbox from "../../../components/Checkbox";
import LoadOverlay from "../../../components/LoadOverlay";
import Notification from "../../../components/Notification";

import { SmallTitle } from "../../../styles/Title";
import { MediumTitle } from "../../../styles/Title";
import { SmallText } from "../../../styles/Text";
import { color, spacing } from "../../../styles/theme";
import { FormCard } from "../../../styles/Card";
import Input from "../../../styles/Input";
import Select, { SelectItemInterface } from "../../../components/Select";

import { useClient } from "../../../hooks/useClient";
import { useApi, InputValidationError } from "../../../hooks/useApi";
import InputValidationErrors from "../../../components/InputValidationErrors";
import Text from "../../../styles/Text";

import UsersCard from "../UserOverview/UsersCard";
import { ThenArg } from '../../../types';

const StyledFormCard = styled(FormCard)`
  margin: ${spacing.large} auto;
`;

const CardTitle = styled(Text)`
  font-size: 14px;
  margin-bottom: 1.2rem;
  line-height: 28px;
  color: #161616;
`;

const Update: FC = () => {
  type UserItem = ThenArg<
    ReturnType<typeof client.getUserGroup>
  >["data"]["result"]["userGroup"];
  const [errors, setErrors] = useState<InlineResponse400Errors[]>([]);
  const [name, setName] = useState("");
  const [reservationWindowLength, setReservationWindowLength] = useState("");

  const [deskLength, setDesksLength] = useState("");
  const [lunchLength, setLunchLength] = useState("");
  const [meetingLength, setMeetingLength] = useState("");
  const [visitorLength, setVisitorLength] = useState("");
  const [resourceLength, setResourceLength] = useState("");

  const [isEmergencyResponder, setEmergencyResponder] = useState<boolean>(false);
  const [isDepartment, setDepartment] = useState<boolean>(true);

  const client = useClient();
  const { handleRequest, isLoading, error, dismissError } = useApi();
  const history = useHistory();
  const { id } = useParams();

  const isFormValid = useCallback(() => Boolean(name), [name]);

  const [userCompany, setUserCompany] = useState("00000011-0000-4000-a000-000000000002");
  const [userListData, setUserListData] = useState<Array<FlatList>>([]);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  useEffect(() => {
    const fetchUserGroup = async () =>
      await handleRequest(client.getUserGroup(id));

    fetchUserGroup().then((result) => {
      if (!result || !result.data.result) {
        return;
      }
      setName(result.data.result.userGroup.label);
      setDesksLength(
        typeof result.data.result.userGroup.reservationWindowLengthDesk === "number"
          ? result.data.result.userGroup.reservationWindowLengthDesk.toString()
          : ""
      );
      setLunchLength(
        typeof result.data.result.userGroup.reservationWindowLengthLunch === "number"
          ? result.data.result.userGroup.reservationWindowLengthLunch.toString()
          : ""
      );
      setMeetingLength(
        typeof result.data.result.userGroup.reservationWindowLengthMeeting === "number"
          ? result.data.result.userGroup.reservationWindowLengthMeeting.toString()
          : ""
      );
      setVisitorLength(
        typeof result.data.result.userGroup.reservationWindowLengthVisitor === "number"
          ? result.data.result.userGroup.reservationWindowLengthVisitor.toString()
          : ""
      );
      setResourceLength(
        typeof result.data.result.userGroup.reservationWindowLengthResource === "number"
          ? result.data.result.userGroup.reservationWindowLengthResource.toString()
          : ""
      );
      setReservationWindowLength(
        typeof result.data.result.userGroup.reservationWindowLength === "number"
          ? result.data.result.userGroup.reservationWindowLength.toString()
          : ""
      );
      setEmergencyResponder(Boolean(result.data.result.userGroup.isEmergencyResponseOfficer));
      setDepartment(Boolean(result.data.result.userGroup.isDepartment));
    });
  }, [id, client, handleRequest]);

  const [usersInUserGroup, setUsersInUserGroup] = useState<SelectItemInterface[]>([]);
  const [selectedUsersInUserGroup, setSelectedUsersInUserGroup] = useState<UserGroupLink[]>(
    []
  );
  const [user, setUser] = useState<UserItem | null>(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  const submitForm = useCallback(async () => {
    const userGroup: UserGroupWithoutId = {
      label: name,
      reservationWindowLength: reservationWindowLength
        ? parseInt(reservationWindowLength, 10)
        : null,
      reservationWindowLengthDesk: deskLength
        ? parseInt(deskLength, 10)
        : null,
      reservationWindowLengthLunch: lunchLength
        ? parseInt(lunchLength, 10)
        : null,
      reservationWindowLengthMeeting: meetingLength
        ? parseInt(meetingLength)
        : null,
      reservationWindowLengthVisitor: visitorLength
        ? parseInt(visitorLength, 10)
        : null,
      reservationWindowLengthResource: resourceLength
        ? parseInt(resourceLength, 10)
        : null,
      isEmergencyResponseOfficer: isEmergencyResponder,
      isDepartment: isDepartment
    };
    try {
      const success = await handleRequest(
        client.updateUserGroup(id, userGroup)
      );

      if (success) {
        history.goBack();
      }
    } catch (error) {
      if (error instanceof InputValidationError) {
        const theError = error as InputValidationError;
        setErrors(theError.errors);
      }
    }
  }, [name, isEmergencyResponder, isDepartment, reservationWindowLength, deskLength, lunchLength, meetingLength, visitorLength, resourceLength, handleRequest, client, id, history]);

  const updateDeskLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setDesksLength(days);
    },
    [setDesksLength]
  );

  const updateLunchLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setLunchLength(days);
    },
    [setLunchLength]
  );

  const updateMeetingLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setMeetingLength(days);
    },
    [setMeetingLength]
  );

  const updateVisitorLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setVisitorLength(days);
    },
    [setVisitorLength]
  );

  const updateResourceLength = useCallback(
    (newValue) => {
      const days = newValue.replace(/[^0-9]/g, "");
      setResourceLength(days);
    },
    [setResourceLength]
  );

  return (
    <>
      {isLoading ? <LoadOverlay /> : null}
      <Container>
      <Grid>
        <Column columns={4}></Column>
        <Column columns={2}>
        <StyledFormCard>
          {Boolean(error) && (
            <InputValidationErrors
              errors={errors}
              closeNotification={dismissError}
            />
          )}
          <SmallTitle hasMarginBottom>
            <Text
              onPress={() =>
                history.push('/admin/user-group-overview', { activeNavigate: 'Usergroups' })
              }
              color="grey"
            >
              ← {t("menu.item.userGroup")}
            </Text> / {t("admin.userGroup.form.editTitle")}
          </SmallTitle>
          <MediumTitle hasMarginBottom>
            {name}
          </MediumTitle>

          <LabeledInput label={t("admin.userGroup.form.name")} >
            <Input
              onChangeText={setName}
              value={name}
              placeholder={t("admin.userGroup.form.name") + " *"}
            />
          </LabeledInput>

          <SmallTitle hasMarginBottom>
            {t("admin.userGroup.form.reservationWidowTitle")}
          </SmallTitle>
          <CardTitle>{t("admin.company.form.reservationSettingsTitle")}</CardTitle>

          <LabeledInput label={t("admin.company.form.desks")} >
            <Input
              onChangeText={updateDeskLength}
              value={deskLength}
              placeholder={t("admin.company.form.desksLength")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.meetingRooms")} >
            <Input
              onChangeText={updateMeetingLength}
              value={meetingLength}
              placeholder={t("admin.company.form.roomsLength")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.lunches")} >
            <Input
              onChangeText={updateLunchLength}
              value={lunchLength}
              placeholder={t("admin.company.form.lunchesLength")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.forVisitors")} >
            <Input
              onChangeText={updateVisitorLength}
              value={visitorLength}
              placeholder={t("admin.company.form.visitorsLength")}
            />
          </LabeledInput>
          <LabeledInput label={t("admin.company.form.forResourcesParking")} >
            <Input
              onChangeText={updateResourceLength}
              value={resourceLength}
              placeholder={t("admin.company.form.resourcesLengthParking")}
              // placeholder={t("admin.company.form.resourcesLength")}
            />
          </LabeledInput>
          <LabeledInput>
            <Checkbox
              label={t("admin.userGroup.form.isEmergencyResponder")}
              onChange={setEmergencyResponder}
              checked={isEmergencyResponder}
            />
          </LabeledInput>
          <LabeledInput helperText={t("admin.userGroup.form.helper.isDepartmentHelp")}>
            <Checkbox
              label={t("admin.userGroup.form.isDepartment")}
              onChange={setDepartment}
              checked={isDepartment}
            />
          </LabeledInput>
          {isDepartment}
          <Button
            hasMarginBottom
            disabled={!isFormValid()}
            onPress={submitForm}
          >
            {t("admin.userGroup.form.editSubmit")}
          </Button>
          <Button
            backgroundColor="transparent"
            backgroundHoverColor={color.primary}
            borderColor={color.primary}
            color={color.primary}
            textHoverColor={color.white}
            onPress={() => history.goBack()}
          >
            {t("general.cancel")}
          </Button>
        </StyledFormCard>
        </Column>
        <Column columns={4}>

        </Column>
      </Grid>

      </Container>
    </>
  );
};

export default Update;
