import React, { FC, useRef } from "react";
import {
  boxShadow,
  button,
  color,
  font,
  spacing,
  borderRadius,
  text,
} from "../../styles/theme";
import styled, { css } from "styled-components/native";
import { useHover, useFocus } from "react-native-web-hooks";
import { TextProps, TouchableOpacityProps } from "react-native";
import marginBottom, {
  MarginBottomProps,
} from "../../styles/helpers/marginBottom";
import Text from "../../styles/Text";

const buttonDefaultBackgroundColor = color.secondary;
const buttonDefaultTextColor = color.white;
const buttonDefaultHoverColor = color.quaternary;

export const ButtonsWrapper = styled.View`
  flex-direction: row;
`;

export const ButtonWrapper = styled.View`
  justify-content: center;
  margin-left: ${spacing.medium};
`;

export interface ButtonOptions extends TouchableOpacityProps {
  width?: string | number;
  backgroundColor?: string;
  backgroundHoverColor?: string;
  isHovering?: boolean;
  borderColor?: string;
  hasShadow?: boolean;
  style?: any;
  addHorizontalMargin?: string;
  borderRadius?: string;
}

interface ButtonTextOptions extends TextProps {
  textColor?: string;
  textHoverColor?: string;
  isHovering?: boolean;
  isDisabled?: any;
}

export const ButtonText = styled(Text)<ButtonTextOptions>`
  font-family: ${font.defaultMedium}
  font-size: ${text.small.size}
    ${({ textColor }) =>
      `color: ${textColor ? textColor : buttonDefaultTextColor};`}
    ${({ isHovering, isDisabled, textHoverColor }) =>
      isHovering && !isDisabled && textHoverColor
        ? css`
            color: ${textHoverColor};
          `
        : ""};
`;

export const DefaultButton = styled.TouchableOpacity<ButtonOptions>`
  padding: 0 ${button.padding};
  min-height: ${button.height};
  border-radius: ${borderRadius.full};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  ${({ hasShadow }) => hasShadow && boxShadow.small}

  ${(props) => props.width ? `width: ${isNaN(+props.width) ? props.width : `${props.width}px`};` : ""}

  ${(props) => `border-radius: ${props.borderRadius ?? borderRadius.full};`}

  ${({ addHorizontalMargin }) =>
    `
    margin-right: ${addHorizontalMargin};
    margin-left: ${addHorizontalMargin};
  `}

  ${({ borderColor }) =>
    `border: ${borderColor ? "1px" : "0px"};
     border-color: ${borderColor};`}

  ${({ backgroundColor }) =>
    `background-color: ${
      backgroundColor ? backgroundColor : buttonDefaultBackgroundColor
    };`}
  ${({ isHovering, backgroundHoverColor, disabled }) =>
    isHovering && !disabled
      ? `background-color: ${
          backgroundHoverColor ? backgroundHoverColor : buttonDefaultHoverColor
        }`
      : ""}
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : ""}
  ${(props) => marginBottom(props)}
`;

interface ButtonTypes extends TouchableOpacityProps, MarginBottomProps {
  backgroundColor?: string;
  backgroundHoverColor?: string;
  color?: string;
  textHoverColor?: string;
  borderColor?: string;
  hasShadow?: boolean;
  prefix?: React.ReactNode;
  indicator?: React.ReactNode;
  addHorizontalMargin?: string;
  borderRadius?: string;
  ref?: React.ForwardedRef<any>;
}

const Button: FC<ButtonTypes> = ({
  children,
  backgroundColor,
  backgroundHoverColor,
  color,
  textHoverColor,
  borderColor,
  prefix,
  indicator,
  hasShadow,
  ref: parentRef,
  ...rest
}) => {
  const ref = useRef(parentRef);
  const isHovering = useHover(ref);
  const hasFocus = useFocus(ref);

  return (
    <DefaultButton
      hasShadow={hasShadow}
      borderColor={borderColor}
      backgroundHoverColor={backgroundHoverColor}
      backgroundColor={backgroundColor}
      ref={ref}
      isHovering={isHovering || hasFocus}
      {...rest}
    >
      {prefix}
      <ButtonText
        isDisabled={rest.disabled}
        isHovering={isHovering || hasFocus}
        textColor={color || borderColor}
        textHoverColor={textHoverColor}
      >
        {children}
      </ButtonText>
      {indicator}
    </DefaultButton>
  );
};

export const DangerButton: FC<ButtonTypes> = ({ children, ...props }) => {
  return (
    <Button
      backgroundColor={color.primary}
      borderColor={color.primary}
      color={color.white}
      backgroundHoverColor={color.white}
      textHoverColor={color.primary}
      {...props}
    >
      {children}
    </Button>
  );
};

export default Button;
