import { t } from "i18n-js";
import moment, { Moment } from "moment";
import React, { forwardRef, FunctionComponent, useState } from "react";
import DatePicker from "react-datepicker";
import { Platform } from "react-native";
import i18n from "../../i18n";
import Link from "../../components/Link";
import { color } from "../../styles/theme";

if (Platform.OS == "web") {
  try {
    require("react-datepicker/dist/react-datepicker.css");
    require("./react-datepicker.css");
  } catch (error) {}
}

export interface DateRangeInputProps {
  startDate: Moment;
  endDate: Moment;
  onChange: (dates: [Date | null, Date | null]) => void;
}

const DateRangeInput: FunctionComponent<DateRangeInputProps> = ({
  startDate,
  endDate,
  onChange,
}) => {
  const [currentStartDate, setCurrentStartDate] = useState<Moment | null>(
    startDate
  );
  const [currentEndDate, setCurrentEndDate] = useState<Moment | null>(endDate);

  const onDateChange: DateRangeInputProps["onChange"] = (dates) => {
    const [start, end] = dates;

    setCurrentStartDate(start ? moment(start) : null);
    if (start && end && start.toISOString() === end.toISOString()) {
      return;
    }
    setCurrentEndDate(end ? moment(end) : null);
    if (start && end) {
      onChange(dates);
    }
  };

  const CustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <Link
      hasPadding
      onPress={onClick}
      color={color.secondary}>
      {t("admin.dashboard.custom")}
    </Link>
  ));

  CustomInput.displayName = "CustomInput";

  return (
    <DatePicker
      locale={i18n.locale}
      selected={currentStartDate ? currentStartDate.toDate() : null}
      onChange={onDateChange}
      startDate={currentStartDate ? currentStartDate.toDate() : null}
      endDate={currentEndDate ? currentEndDate.toDate() : null}
      selectsRange
      monthsShown={2}
      dateFormat="dd/MM/yyyy"
      customInput={<CustomInput />}
    />
  );
};

export default DateRangeInput;
