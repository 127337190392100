import React, { FC } from "react";
import { VictoryPie } from "./chart";
import { color } from "../../styles/theme";
import styled from "styled-components/native";
import { Svg } from "react-native-svg";

const PieWidth = 46;

const PieWrapper = styled.View`
  width: ${PieWidth}px;
`;

interface PieProps {
  usage: number;
}

const PieChart: FC<PieProps> = ({ usage }) => {
  const data = [
    { x: "usage", y: usage },
    { x: "rest", y: 100 - usage },
  ];

  return (
    <PieWrapper>
      <Svg viewBox="0 0 420 420">
        <VictoryPie
          standalone={false}
          startAngle={0}
          endAngle={360}
          innerRadius={120}
          data={data}
          colorScale={[color.quinary, color.greyLightest]}
          cornerRadius={15}
          labels={() => null}
        />
      </Svg>
    </PieWrapper>
  );
};

export default PieChart;
