import React, { FC } from "react";
import styled from "styled-components/native";
import { color, font, text, borderRadius, spacing } from "../../styles/theme";
import Text, { SmallText } from "../../styles/Text";
import { HeaderCardStatisticInterface } from "../../../fake-data/dashboardData";

const StatisticView = styled.View`
  padding: ${spacing.extraSmall} 0;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: ${spacing.medium};
`;

const Number = styled(Text)`
  font-size: ${text.extraLarge.size};
  font-family: ${font.defaultSemiBold};
`;

const Badge = styled.View`
  background-color: ${color.secondaryLight};
  border-radius: ${borderRadius.full};
  padding: 0 ${spacing.small};
  position: absolute;
  top: 0;
  right: 0;
`;

const BadgeText = styled(SmallText)`
  color: ${color.secondary};
  font-family: ${font.defaultSemiBold};
`;

const HeaderCardStatistic: FC<HeadeerCardStatisticInterfac> = ({
  name,
  number,
  percentage,
  horizontal,
}) => (
  <StatisticView style={{ flexDirection: horizontal ? 'row' : 'column' }}>
    <Number>{number}</Number>
    <SmallText style={{
      marginLeft: horizontal ? '10px' : 0,
      fontSize: text.tiny.size
    }}>{name}</SmallText>
    {percentage ? (
      <Badge>
        <BadgeText>{percentage}%</BadgeText>
      </Badge>
    ) : null}
  </StatisticView>
);

export default HeaderCardStatistic;
