import React, { FC, useState } from "react";
import { View } from "react-native";
import Checkbox from "../../../../components/Checkbox";
import Grid, { Column } from "../../../../components/Grid";
import Card, { HorizontalCardDivider } from "../../../../styles/Card";
import {
  borderRadius,
  color,
  display,
  flex,
  font,
  margin,
  spacing,
  TextAlign,
} from "../../../../styles/theme";
import Text from "../../../../styles/Text";
import styled from "styled-components/native";
import Input from "../../../../styles/Input";
import { t } from "../../../../i18n";

const TextBold = styled.Text`
  font-family: ${font.defaultBold};
  color: ${color.darker};
  opacity: 0.8;
  margin-top: 6px;
  margin-bottom: 40px;
`;

const OvalComponent = styled.Text`
  width: 19px;
  height: 19px;
  background-color: ${color.quaternary};
  opacity: 0.7;
  color: ${color.white};
  border-radius: ${borderRadius.full};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-left: 10px;
`;

const InnerReservationCard = styled.View`
  padding: 30px;
  background-color: ${color.background};
  border-radius: 10px;
`;

const DateChoiceInput = styled(Input)`
  background-color: ${color.white};
  text-align: ${TextAlign.center};
  width: 70px;
  height: 45px;
  margin: 0 15px;
  border: 1px solid ${color.alto};
`;

const CustomCard = styled(Card)`
  padding: ${margin.large};
  margin-bottom: ${spacing.large};
`;

const DayRows = styled.View`
  display: ${display.flex};
  flex-direction: ${flex.direction.row};
  align-items: ${flex.justifyContent.center};
`;

const DataCardTitle = styled.Text`
  font-family: ${font.defaultMedium};
  font-size: 15px;
  margin-bottom: ${margin.small};
`;

const DataCard: FC = () => {
  const [activeDay, setActiveDay] = useState(true);
  const [inactiveDay, setInactiveDay] = useState(false);
  const [daysAdvance, updateDaysAdvance] = useState("365");
  const [holiday, updateHoliday] = useState("60");

  function updateDaysAdvanceHandler(value: string) {
    if (!isNaN(+value)) {
      updateDaysAdvance(value);
    }
  }
  function updateHolidaysHandler(value: string) {
    if (!isNaN(+value)) {
      updateHoliday(value);
    }
  }
  return (
    <>
      <View style={{ width: 760 }}>
        <Text
          color={color.darker}
          lineHeight={28}
          opacity={0.8}
        >
           {t("admin.settings.numberDayDescribe") }
        </Text>
        <TextBold>{t("admin.settings.numberDayDescribeSubtitle") }</TextBold>
      </View>
      <Grid>
        <Column columns={1}>
          <CustomCard disableHorizontalDivider title="">
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <DataCardTitle>{t("admin.settings.reservationData") }</DataCardTitle>
            </View>

            <InnerReservationCard>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  mediumWeight
                  hasMarginBottom
                  color={color.darker}
                  lineHeight={0}
                >
                 {t("admin.settings.employeReservation") }
                </Text>
                <OvalComponent>?</OvalComponent>
              </View>

              <DayRows>
                <Checkbox
                  hasMarginBottom={false}
                  checked={activeDay}
                  onChange={setActiveDay}
                />
                <DateChoiceInput
                  color={activeDay ? color.black : color.grey}
                  maxLength={3}
                  disabled={!activeDay}
                  onChangeText={updateDaysAdvanceHandler}
                  value={daysAdvance}
                />
                <Text opacity={activeDay ? 1 : 0.5} color={color.darker}>
                {t("admin.settings.days") }
                </Text>
              </DayRows>

              <HorizontalCardDivider margin="4px 0px" />

              <Text mediumWeight hasMarginBottom color={color.darker}>
              {t("admin.settings.deleteVisitor") }
              </Text>

              <DayRows>
                <Checkbox
                  hasMarginBottom={false}
                  checked={inactiveDay}
                  onChange={setInactiveDay}
                />
                <DateChoiceInput
                  color={inactiveDay ? color.black : color.grey}
                  disabled={!inactiveDay}
                  maxLength={3}
                  onChangeText={updateHolidaysHandler}
                  value={holiday}
                />
                <Text opacity={inactiveDay ? 1 : 0.5} color={color.darker}>
                {t("admin.settings.days") }
                </Text>
              </DayRows>
            </InnerReservationCard>
          </CustomCard>
        </Column>
      </Grid>
    </>
  );
};

export default DataCard;
