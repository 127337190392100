import moment, { Moment } from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { View, TouchableOpacity, Linking } from "react-native";
import styled from "styled-components/native";
import {
  Company,
  Location,
  SeatType,
  Analytics,
} from "../../../../bookmydesk-api-sdk-typescript-axios";
import { HeaderCardStatisticInterface } from "../../../../fake-data/dashboardData";
import LineChart from "../../../components/Chart/LineChart";
import PieChartHalf from "../../../components/Chart/PieChartHalf";
import PieChart from "../../../components/Chart/PieChart";
import Container from "../../../components/Container";
import DateRangeInput from "../../../components/DateRangeInput/DateRangeInput";
import {
  dateFormat,
  alternativeDateFormat,
} from "../../../components/DateSelector/dateSelector.constant";
import Dropdown from "../../../components/Dropdown";
import Grid, { ChartColumn, Column } from "../../../components/Grid";
import HeaderCard, { DropdownItemProps } from "../../../components/HeaderCard";
import HeaderCardStatistic from "../../../components/HeaderCardStatistic";
import StatusIndicatorStatistic from "../../../components/StatusIndicatorStatistic";
import Link from "../../../components/Link";
import { useAuth } from "../../../context/authContext";
import { useApi } from "../../../hooks/useApi";
import { useClient } from "../../../hooks/useClient";
import isTablet from "../../../hooks/useIsTablet";
import { t } from "../../../i18n";
import { useHistory } from "../../../routing";
import Text, { SmallText } from "../../../styles/Text";
import gaugeIcon from "../../../assets/icons/gauge.svg";
import homeIcon from "../../../assets/icons/home.svg";
import lunchIcon from "../../../assets/icons/ic_reservation_lunch.svg";
import lineChartIcon from "../../../assets/icons/linechart.svg";
import locationIcon from "../../../assets/icons/location.svg";
import meetingRoomIcon from "../../../assets/icons/meeting_room.svg";
import officeChairIcon from "../../../assets/icons/office_chair.svg";
import parkingSpotIcon from "../../../assets/icons/parking_spot.svg";
import notificationIcon from "../../../assets/icons/notification_white.svg";
import IconBadge from "../../../components/IconBadge";
import Button from "../../../components/Button";
import Leaderboard from "../../../components/Leaderboard";

import {
  borderRadius,
  borderWidth,
  color,
  spacing,
} from "../../../styles/theme";
import Title from "../../../styles/Title";
import { ThenArg } from "../../../types";
import PadAngle from "../../../components/Chart/PadAngle";
import LoadOverlay from "../../../components/LoadOverlay";

import {
  DropDownFilter,
  leaderboardStatistic,
  LeaderboardStats,
  mostUsedStatistic,
  MostUsedStatisticDataType,
  MostUsedType,
  ReservationStatisticToday,
  StatisticCardProps,
  tagFilter,
  TimePeriod,
} from "./DashboardTypes";
import {
  DashboardOverviewHeader,
  HorizontalHeaderContainer,
  StatisticList,
  StatisticListItemWrapper,
  OccupancyContainer,
  OccupancyWrapper,
  PercentageWrapper,
  Percentage,
  PercentageMark,
  SimpleStatsWrapper,
  SimpleStatWrapper,
  StatText,
  VerticalCardDivider,
  StatTextWrapper,
  LargeStatText,
} from "./DashboardStyles";
import { DashboardType, timePeriods, today } from "./DashboardUtils";

const createStaticCardData = (
  icon: string,
  title: string,
  badgeColor: string,
  data: Array<HeaderCardStatisticInterface>
): StatisticCardProps => ({
  icon,
  title,
  badgeColor,
  data,
});

const currentDate = () => moment().subtract(0, "week").endOf("day");

const CustomerAdminDashboard: FC = () => {
  const { userState } = useAuth();
  const history = useHistory();
  const client = useClient();
  const { handleRequest, isLoading, error: apiError, dismissError } = useApi();
  const companyId = userState?.companyIds[0];

  type Statistics = ThenArg<
    ReturnType<typeof client.reservationsStatistics>
  >["data"]["result"];

  interface StatisticsCombined {
    desk: Statistics | null;
    meeting: Statistics | null;
    lunch: Statistics | null;
    parking: Statistics | null;
  }

  const [company, setCompany] = useState<Company | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationId, setLocationId] = useState<string | undefined>(undefined);

  const [leaderboardStatistics, setLeaderboardStatistics] = useState<
    LeaderboardStats | []
  >([]);
  const [mostUsedStatistics, setMostUsedStatistics] = useState<
    LeaderboardStats | []
  >([]);
  const [mostUsedStatisticsFormatted, setMostUsedStatisticsFormatted] =
    useState<LeaderboardStats[] | []>([]);

  // statistics per reservation type
  const [statistics, setStatistics] = useState<StatisticsCombined>({
    desk: null,
    meeting: null,
    lunch: null,
    parking: null,
  });

  // reservation info today (homeReservationCount, OfficereservationCount, parking info, seatType Info)
  const [reservationStatisticsToday, setReservationStatisticsToday] = useState<
    ReservationStatisticToday | undefined
  >();

  const [startDate, setStartDate] = useState<Moment>(
    currentDate().subtract("1", "week").startOf("day")
  );

  const [endDate, setEndDate] = useState<Moment>(currentDate());

  const [seatTags, setSeatTags] = useState<DropDownFilter[]>([]);

  const [seats, setSeats] = useState<DropDownFilter[]>([]);

  const [activeLegends, setActiveLegends] = useState<
    typeof OccupationChartData[]
  >([]);

  const [dashboardType, setDashboardType] = useState<DashboardType | null>(
    DashboardType.HISTORIC
  );

  const [dataLoading, setDataLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState<Analytics | undefined>();

  useEffect(() => {
    if (!company) {
      setDataLoading(false);
      return;
    }
    setDataLoading(true);

    if (!locationId) {
      // returns active and missed check ins
      handleRequest(client.getAnalytics(company.id))
        .then((res) => {

          setAnalyticsData(res?.data?.result);
        })
        .finally(() => {
          setDataLoading(false);
        });
      return;
    }

    handleRequest(client.getAnalyticsForLocation(locationId))
      .then((res) => {

        setAnalyticsData(res?.data?.result);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, [client, company, handleRequest, locationId]);

  useEffect(() => {
    if (userState && userState.roles.includes("ROLE_ADMIN")) {
      history.push("/admin/company-overview");
    }
  }, [userState, history]);

  // All the data fetching
  useEffect(() => {
    if (!companyId) {
      setDataLoading(false);
      return;
    }

    handleRequest(client.getCompany(companyId)).then((response) => {
      if (response && response.data) {
        setCompany(response.data.result.company as Company);
      }
    });

    handleRequest(client.listLocations(companyId)).then((response) => {
      if (response && response.data) {
        setLocations(response.data.result.items);
      }
    });

    //
    handleRequest(client.reservationsStatisticsToday(companyId)).then(
      (response) => {
        if (response && response.data) {
          setReservationStatisticsToday(response.data.result);
        }
      }
    );

    handleRequest(client.listSeatTags(companyId)).then((response) => {
      if (response && response.data) {
        const uniqueSeatTags = [
          ...new Set(response.data.result.items.map((item) => item.label)),
        ].map((item, id) => ({ label: item, id: (id + 1).toString() }));
        uniqueSeatTags.unshift({
          label: t("admin.dashboard.allTypes"),
          id: "0",
        });
        setSeatTags(uniqueSeatTags);
      }
    });

    handleRequest(client.listSeats(companyId)).then((response) => {
      if (response && response.data) {
        const uniqueSeats = [
          ...new Set(response.data.result.items.map((item) => item.name)),
        ].map((item, id) => ({ label: item, id: (id + 1).toString() }));
        uniqueSeats.unshift({ label: t("admin.dashboard.all"), id: "0" });
        setSeats(uniqueSeats);
      }
    });

    // Statistics calls
    // Desk
    handleRequest(
      client.reservationsStatistics(
        startDate
          ? startDate.format(dateFormat)
          : currentDate()
              .subtract("1", "week")
              .startOf("day")
              .format(dateFormat),
        endDate ? endDate.format(dateFormat) : currentDate().format(dateFormat),
        SeatType.Desk,
        companyId,
        locationId
      ),
      true
    ).then((response) => {
      if (response && response.data) {
        setStatistics((old: StatisticsCombined) => ({
          ...old,
          desk: response.data.result,
        }));

        const leaderboardStatisticsData =
          response.data.result?.items?.[0]?.seatTagLeaderboard?.map(
            (seatTagLeaderboardItem) => ({
              ...seatTagLeaderboardItem,
              active: true,
            })
          ) || [];

        leaderboardStatisticsReference.current = leaderboardStatisticsData;

        setLeaderboardStatistics(leaderboardStatisticsData);

        const colorOptions: Array<string> = [
          color.primary,
          color.secondary,
          color.tertiary,
          color.quaternary,
          color.reservationHome,
        ];

        const mostUsedStatisticsData: MostUsedStatisticDataType[] =
          response.data.result?.items[0]?.mostUsed?.map(
            (mostUsedItem: MostUsedType, index: number) => ({
              tag: mostUsedItem.name,
              percentage: mostUsedItem.percentage,
              active: true,
              color: colorOptions[index],
            })
          ) || [];

        mostUsedStatisticsReference.current = mostUsedStatisticsData;

        setMostUsedStatistics(mostUsedStatisticsData);

        setMostUsedStatisticsFormatted(
          mostUsedStatisticsData.map(
            (mostUsedStatisticsItem: MostUsedStatisticDataType) => ({
              x: mostUsedStatisticsItem.tag,
              y: mostUsedStatisticsItem.percentage,
            })
          )
        );
      }
    });
    // Meeting
    handleRequest(
      // client.v3Statistics
      client.reservationsStatistics(
        startDate
          ? startDate.format(dateFormat)
          : currentDate()
              .subtract("1", "week")
              .startOf("day")
              .format(dateFormat),
        endDate ? endDate.format(dateFormat) : currentDate().format(dateFormat),
        SeatType.Meeting,
        companyId,
        locationId
      ),
      true
    ).then((response) => {
      if (response && response.data) {
        setStatistics((old: StatisticsCombined) => ({
          ...old,
          meeting: response.data.result,
        }));
      }
    });
    // Lunch
    handleRequest(
      client.reservationsStatistics(
        startDate
          ? startDate.format(dateFormat)
          : currentDate()
              .subtract("1", "week")
              .startOf("day")
              .format(dateFormat),
        endDate ? endDate.format(dateFormat) : currentDate().format(dateFormat),
        SeatType.Lunch,
        companyId,
        locationId
      ),
      true
    ).then((response) => {
      if (response && response.data) {
        setStatistics((old: StatisticsCombined) => ({
          ...old,
          lunch: response.data.result,
        }));
      }
    });
  }, [client, companyId, endDate, handleRequest, locationId, startDate]);

  const toggleDashboardType = () => {
    setDashboardType(
      dashboardType === DashboardType.HISTORIC
        ? DashboardType.REALTIME
        : DashboardType.HISTORIC
    );
    setStartDate(moment(today));
    setEndDate(moment(today));
  };

  function onDateChange([start, end]: [Date, Date]): void {
    setStartDate(moment(start));
    setEndDate(moment(end));
    setDashboardType(DashboardType.HISTORIC);
  }

  const columns = isTablet() ? 2 : 3;

  const deskTotal = statistics.desk?.seatCount || 0;
  const parkingTotal = statistics.desk?.parkingCount || 0;

  const deskReservations = (statistics.desk?.items || [])
    .filter((item) => currentDate().format(dateFormat) === item.date)
    .map((item) => item.seatReservationsCount)
    .reduce((curr, prev) => prev + curr, 0);

  const deskReservationsPercentage =
    Math.round((deskReservations / deskTotal) * 100) || 0;

  const lunchTotal = statistics.lunch?.seatCount || 0;
  const lunchReservations = (statistics.lunch?.items || [])
    .filter((item) => currentDate().format(dateFormat) === item.date)
    .map((item) => item.seatReservationsCount)
    .reduce((curr, prev) => prev + curr, 0);
  const lunchReservationsPercentage = Math.round(
    (lunchReservations / lunchTotal) * 100
  );

  const meetingTotal = statistics.meeting?.seatCount || 0;
  const parkingTotalMeeting = statistics.meeting?.parkingCount || 0;

  const meetingReservations = (statistics.meeting?.items || [])
    .filter((item) => currentDate().format(dateFormat) === item.date)
    .map((item) => item.seatReservationsCount)
    .reduce((curr, prev) => prev + curr, 0);
  const meetingReservationsPercentage = Math.round(
    (meetingReservations / meetingTotal) * 100
  );
  const parkingReservations = [
    ...(statistics.desk?.items || []),
    ...(statistics.meeting?.items || []),
    ...(statistics.lunch?.items || []),
  ]
    .filter((item) => currentDate().format(dateFormat) === item.date)
    .map((item) => item.parkingSpotsClaimed)
    .reduce((curr, prev) => prev + curr, 0);

  const parkingReservationsPercentage = Math.round(
    (parkingReservations / parkingTotal) * 100
  );

  // create statistic cards data
  const statisticCards = [
    // Office desks
    createStaticCardData(
      officeChairIcon,
      t("admin.dashboard.desks"),
      color.quinaryLight,
      [
        {
          name: t(`admin.dashboard.total`),
          number: deskTotal,
        },
        {
          name: t(`admin.dashboard.occupied`),
          number: deskReservations,
          percentage: deskReservationsPercentage,
        },
        {
          name: t(`admin.dashboard.free`),
          number: deskTotal - deskReservations,
          percentage: 100 - deskReservationsPercentage,
        },
      ]
    ),
    company?.meetingEnabled
      ? // Office meeting rooms
        createStaticCardData(
          meetingRoomIcon,
          t("admin.dashboard.meetingRooms"),
          color.secondaryLight,
          [
            {
              name: t(`admin.dashboard.total`),
              number: meetingTotal,
            },
            {
              name: t(`admin.dashboard.occupied`),
              number: meetingReservations,
              percentage: meetingReservationsPercentage,
            },
            {
              name: t(`admin.dashboard.free`),
              number: meetingTotal - meetingReservations,
              percentage: 100 - meetingReservationsPercentage,
            },
          ]
        )
      : undefined,
    parkingTotal != 0
      ? // Office parking spots
        createStaticCardData(
          parkingSpotIcon,
          t("admin.dashboard.parkingSpots"),
          color.quaternaryLight,
          [
            {
              name: t(`admin.dashboard.total`),
              number: parkingTotal,
            },
            {
              name: t(`admin.dashboard.reserved`),
              number: parkingReservations,
              percentage: parkingReservationsPercentage,
            },
            {
              name: t(`admin.dashboard.free`),
              number: parkingTotal - parkingReservations,
              percentage: 100 - parkingReservationsPercentage,
            },
          ]
        )
      : undefined,
    // Office lunch spots
    company?.lunchEnabled
      ? createStaticCardData(
          lunchIcon,
          t("admin.dashboard.lunch"),
          color.tertiaryLight,
          [
            {
              name: t(`admin.dashboard.total`),
              number: lunchTotal,
            },
            {
              name: t(`admin.dashboard.reserved`),
              number: lunchReservations,
              percentage: lunchReservationsPercentage,
            },
            {
              name: t(`admin.dashboard.free`),
              number: lunchTotal - lunchReservations,
              percentage: 100 - lunchReservationsPercentage,
            },
          ]
        )
      : undefined,
  ].filter((item) => typeof item !== "undefined");
  const days = Array.from(
    { length: endDate.diff(startDate, "days") + 1 },
    (v, i) => i
  ).reverse();

  const deskLineData = days
    .map((subtractDays) => ({
      x: moment(endDate).subtract(subtractDays, "day").toDate(),
      y: Math.round(
        ((statistics.desk?.items || [])
          .filter(
            (item) =>
              moment(endDate)
                .subtract(subtractDays, "day")
                .format(dateFormat) === item.date
          )
          .map((item) => item.seatReservationsCount)
          .reduce((curr, prev) => prev + curr, 0) /
          deskTotal) *
          100
      ),
    }))
    .map((item) => ({
      ...item,
      y: isNaN(item.y) ? 0 : +item.y,
    }));

  const meetingLineData = days
    .map((subtractDays) => ({
      x: moment(endDate).subtract(subtractDays, "day").toDate(),
      y: Math.round(
        ((statistics.meeting?.items || [])
          .filter(
            (item) =>
              moment(endDate)
                .subtract(subtractDays, "day")
                .format(dateFormat) === item.date
          )
          .map((item) => item.seatReservationsCount)
          .reduce((curr, prev) => prev + curr, 0) /
          meetingTotal) *
          100
      ),
    }))
    .map((item) => ({
      ...item,
      y: isNaN(item.y) ? 0 : +item.y,
    }));

  const parkingSpotLineData = days
    .map((subtractDays) => ({
      x: moment(endDate).subtract(subtractDays, "day").toDate(),
      y: Math.round(
        ((statistics.meeting?.items || [])
          .filter(
            (item) =>
              moment(endDate)
                .subtract(subtractDays, "day")
                .format(dateFormat) === item.date
          )
          .map((item) => {
            return item.parkingSpotsClaimed;
          })
          .reduce((curr, prev) => prev + curr, 0) /
          parkingTotalMeeting) *
          100
      ),
    }))
    .map((item) => ({
      ...item,
      y: isNaN(item.y) ? 0 : +item.y,
    }));

  const lunchLineData = days
    .map((subtractDays) => ({
      x: moment(endDate).subtract(subtractDays, "day").toDate(),
      y: Math.round(
        ((statistics.lunch?.items || [])
          .filter(
            (item) =>
              moment(endDate)
                .subtract(subtractDays, "day")
                .format(dateFormat) === item.date
          )
          .map((item) => item.seatReservationsCount)
          .reduce((curr, prev) => prev + curr, 0) /
          lunchTotal) *
          100
      ),
    }))
    .map((item) => ({
      ...item,
      y: isNaN(item.y) ? 0 : +item.y,
    }));

  const OccupationChartData = [
    {
      id: t(`admin.dashboard.desks`),
      dataColor: color.quinary,
      lineData: deskLineData,
      isActive: true,
    },
    company?.meetingEnabled && meetingTotal > 0
      ? {
          id: t(`admin.dashboard.meetingRooms`),
          dataColor: color.secondary,
          lineData: meetingLineData,
          isActive: true,
        }
      : undefined,
    company?.lunchEnabled && lunchTotal > 0
      ? {
          id: t(`admin.dashboard.lunch`),
          dataColor: color.tertiary,
          lineData: lunchLineData,
          isActive: true,
        }
      : undefined,
    parkingTotal > 0
      ? {
          dataColor: color.quaternary,
          id: t(`admin.dashboard.reserved_parking_spots`),
          lineData: parkingSpotLineData,
          isActive: true,
        }
      : undefined,
  ].filter((item) => typeof item !== "undefined");

  const activeLocation = locations.find(
    (location) => location.id === locationId
  );

  const activeLineChartData = OccupationChartData.filter((item) => {
    const legend = activeLegends.find((legend) => legend?.id === item?.id);

    return !legend || legend?.isActive;
  });

  const tickValuesY = useMemo(() => {
    const yValues = activeLineChartData.reduce(
      (yValue, chartData) => [
        ...yValue,
        ...(chartData?.lineData.map((item) => item.y) || []),
      ],
      [0] as number[]
    );
    const maxYValue = Math.max(...yValues);
    if (maxYValue > 100) {
      return new Array(Math.ceil(maxYValue / 10) + 2)
        .fill("")
        .map((item, index) => index * 10);
    }
  }, [activeLineChartData]);

  const leaderboardStatisticsReference = React.useRef(leaderboardStatistics);
  const mostUsedStatisticsReference = React.useRef(mostUsedStatistics);

  const handleChangeFilter = (data: tagFilter) => {
    setLeaderboardStatistics(
      data.label !== t("admin.dashboard.allTypes")
        ? leaderboardStatisticsReference.current.map(
            (leaderboardStatistic: leaderboardStatistic) => ({
              tag: leaderboardStatistic.tag,
              percentage: leaderboardStatistic.percentage,
              active: leaderboardStatistic.tag === data.label,
            })
          )
        : leaderboardStatisticsReference.current
    );
  };

  const handleChangeSeatsFilter = (data: tagFilter) => {
    setMostUsedStatistics(
      data.label !== t("admin.dashboard.all")
        ? mostUsedStatisticsReference.current.map(
            (mostUsedStat: MostUsedStatisticDataType) => ({
              ...mostUsedStat,
              active: mostUsedStat.tag === data.label,
            })
          )
        : mostUsedStatisticsReference.current
    );
  };

  if (!company || isLoading) {
    return null;
  }

  const StyledText = styled(Text)`
    border: ${borderWidth.small};
    border-color: ${color.bmdBlue};
    background-color: ${color.bmdBlue};
    color: ${color.white};
    border-radius: ${borderRadius.medium};
    padding: ${spacing.small} ${spacing.medium};
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-bottom: ${spacing.medium};
  `;

  const InnerText = styled(Text)`
    color: ${color.white};
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    float: left;
  `;

  const timeOfDay = new Date().getHours();
  let timeOfDayMessage = "";
  if (timeOfDay < 12) {
    timeOfDayMessage = "_morning";
  } else if (timeOfDay < 18) {
    timeOfDayMessage = "_afternoon";
  } else {
    timeOfDayMessage = "_evening";
  }

  return (
    <>
      {dataLoading ? <LoadOverlay /> : null}
      <Container
        key={`${
          company.id
        }-${locationId}-${startDate.toISOString()}-${endDate.toISOString()}`}
      >
        {/* Dashboard header container */}
        <DashboardOverviewHeader>
          <View>
            <Title>
              {t("general.hi" + timeOfDayMessage, {
                name: userState?.firstName,
              })}
            </Title>
            <Text>
              {activeLocation
                ? t("admin.dashboard.subtitleLocation", {
                    location: activeLocation.name,
                  })
                : t("admin.dashboard.subtitle")}
            </Text>
          </View>

          {/* Time selectors and buttons container */}
          <HorizontalHeaderContainer>
            {/*  Right now selector if historic */}
            {dashboardType === DashboardType.HISTORIC && (
              <Button
                style={{ marginRight: "10px" }}
                onPress={toggleDashboardType}
              >
                {t("admin.dashboard.rightNow")}
              </Button>
            )}

            {/* Time Selector container */}
            <View style={{ marginRight: "10px" }}>
              {/* period selector if realtime */}
              <Dropdown
                border
                text={
                  dashboardType === DashboardType.REALTIME
                    ? t("admin.dashboard.rightNow")
                    : t("admin.dashboard.custom") +
                      ": " +
                      moment(startDate).format(alternativeDateFormat) +
                      " " +
                      moment(endDate).format(alternativeDateFormat)
                }
              >
                {/* Right now option in dropdown if historic */}
                {dashboardType === DashboardType.HISTORIC && (
                  <Link hasPadding onPress={() => toggleDashboardType()}>
                    {t("admin.dashboard.rightNow")}
                  </Link>
                )}

                {/* period selector */}
                {timePeriods.map((period: TimePeriod, index: number) => (
                  <Link
                    hasPadding
                    key={index}
                    onPress={() => onDateChange([period.start, period.end])}
                  >
                    {t(period.label)}
                  </Link>
                ))}

                {/* custom date range selector */}
                <DateRangeInput
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    if (dates[0] && dates[1])
                      onDateChange([dates[0], dates[1]]);
                  }}
                />
              </Dropdown>
            </View>

            <Dropdown
              text={`${
                locationId
                  ? locations.find((location) => location.id === locationId)
                      ?.name
                  : t("admin.dashboard.allLocations")
              }`}
              border
            >
              <Link
                onPress={() => setLocationId(undefined)}
                hasPadding
                isActive={!locationId}
              >
                {t("admin.dashboard.allLocations")}
              </Link>
              {locations.map((location) => (
                <Link
                  onPress={() => setLocationId(location.id)}
                  key={location.id}
                  hasPadding
                  isActive={location.id === locationId}
                >
                  {location.name}
                </Link>
              ))}
            </Dropdown>
          </HorizontalHeaderContainer>
        </DashboardOverviewHeader>

        {/* Notification container */}
        <StyledText>
          {/* TODO: notification: this should maybe come from somewhere instead of being hard-coded? */}
          <InnerText>
            <IconBadge icon={notificationIcon} badgeColor={color.transparent} />
          </InnerText>
          <InnerText style={{ paddingTop: 2, paddingLeft: 10 }}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://support.bookmydesk.com");
              }}
            >
              <Text style={{ color: color.white }}>
                {t("updates.notification.release_nov")}
              </Text>
            </TouchableOpacity>
          </InnerText>
        </StyledText>

        {/* Dashboard if Historic */}
        {dashboardType === DashboardType.HISTORIC && (
          <View>
            <Grid style={{ zIndex: 98 }}>
              <ChartColumn
                columns={2}
                style={{
                  flexDirection: "row",
                  flexGrow: 1,
                  padding: 0,
                  flex: 2,
                }}
              >
                {/* numberOfUsers */}
                <Column style={{ flex: 1 }}>
                  <HeaderCard title={t("admin.dashboard.numberOfUsers")}>
                    <StatisticList>
                      <StatisticListItemWrapper>
                        <HeaderCardStatistic number={"1.042 %"} />
                        {/* @ts-ignore style overwrite */}
                        <Text style={{ fontSize: "12px", alignSelf: "center" }}>
                          {" "}
                          &uarr; 14%{" "}
                        </Text>
                      </StatisticListItemWrapper>
                    </StatisticList>
                  </HeaderCard>
                </Column>

                {/* mostUsedDeskMeetingRooms */}
                <Column style={{ flex: 1 }}>
                  <HeaderCard
                    title={t("admin.dashboard.mostUsedDeskMeetingRooms")}
                    dropdownItems={seats}
                    onFilterChange={handleChangeSeatsFilter}
                  >
                    <PadAngle data={mostUsedStatisticsFormatted} />
                    <Leaderboard items={mostUsedStatistics}></Leaderboard>
                  </HeaderCard>
                </Column>
              </ChartColumn>
              <ChartColumn
                columns={1}
                style={{
                  flexDirection: "row",
                  flexGrow: 1,
                  padding: 0,
                  flex: 1,
                }}
              >
                {/* Occupancy */}
                <Column style={{ flex: 1 }}>
                  <HeaderCard title={t("admin.dashboard.occupancy")}>
                    <OccupancyContainer>
                      <PieChartHalf usage={50} />
                      <OccupancyWrapper>
                        <PercentageWrapper>
                          <Percentage>{50} </Percentage>
                          <PercentageMark>%</PercentageMark>
                        </PercentageWrapper>
                        <SmallText>{t("admin.dashboard.occupied")}</SmallText>
                      </OccupancyWrapper>
                    </OccupancyContainer>
                  </HeaderCard>
                </Column>
              </ChartColumn>
            </Grid>

            <Grid style={{ zIndex: 97 }}>
              <ChartColumn
                columns={3}
                style={{ flexDirection: "row", flexGrow: 1, padding: 0 }}
              >
                {/* occupancyMeetingRooms TODO: make dynamic */}
                <Column style={{ flex: 1 }}>
                  <HeaderCard
                    title={t("admin.dashboard.occupancyMeetingRooms")}
                  >
                    <StatisticList>
                      <StatisticListItemWrapper>
                        <PieChart usage={8} />
                        <HeaderCardStatistic number={"08 %"} />
                        {/* @ts-ignore style overwrite */}
                        <Text style={{ fontSize: "12px", alignSelf: "center" }}>
                          {" "}
                          4 van de 24 ruimtes{" "}
                        </Text>
                      </StatisticListItemWrapper>
                    </StatisticList>
                  </HeaderCard>
                </Column>
                {/* occupancyDesks */}
                <Column style={{ flex: 1 }}>
                  <HeaderCard title={t("admin.dashboard.occupancyDesks")}>
                    <StatisticList>
                      <StatisticListItemWrapper>
                        <PieChart usage={32} />
                        <HeaderCardStatistic number={"32 %"} />
                        {/* @ts-ignore style overwrite */}
                        <Text style={{ fontSize: "12px", alignSelf: "center" }}>
                          {" "}
                          28 van de 124 plekken{" "}
                        </Text>
                      </StatisticListItemWrapper>
                    </StatisticList>
                  </HeaderCard>
                </Column>

                {/* occupancyDesks */}
                <Column style={{ flex: 1 }}>
                  <HeaderCard
                    title={t("admin.dashboard.occupancyDesks")}
                    dropdownItems={seatTags}
                    onFilterChange={handleChangeFilter}
                  >
                    <Leaderboard items={leaderboardStatistics}></Leaderboard>
                  </HeaderCard>
                </Column>
              </ChartColumn>
            </Grid>

            <Grid style={{ zIndex: 96 }}>
              <ChartColumn columns={columns} style={{ zIndex: 20 }}>
                {/* occupationPercentage line chart*/}
                <HeaderCard
                  legends={OccupationChartData}
                  onChangeActiveLegends={setActiveLegends}
                  icon={lineChartIcon}
                  title={t("admin.dashboard.occupationPercentage")}
                  disableHorizontalDivider
                  titleSuffix={
                    <View style={{ marginLeft: "auto" }}>
                      <DateRangeInput
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(dates) => {
                          if (dates[0] && dates[1])
                            onDateChange([dates[0], dates[1]]);
                        }}
                      />
                    </View>
                  }
                >
                  <LineChart
                    tickValuesY={tickValuesY}
                    data={activeLineChartData}
                  />
                </HeaderCard>
              </ChartColumn>

              <Column columns={columns}>
                {/* occupationRate */}
                <HeaderCard
                  icon={gaugeIcon}
                  title={t("admin.dashboard.occupationRate")}
                  //dropdownItems={dropdownItems}
                >
                  <OccupancyContainer>
                    <PieChartHalf usage={deskReservationsPercentage} />
                    <OccupancyWrapper>
                      <PercentageWrapper>
                        <Percentage>{deskReservationsPercentage} </Percentage>
                        <PercentageMark>%</PercentageMark>
                      </PercentageWrapper>
                      <SmallText>
                        {t("admin.dashboard.occupancyRate")}
                      </SmallText>
                    </OccupancyWrapper>
                  </OccupancyContainer>
                </HeaderCard>

                {/* Statistic cards */}
                {statisticCards.map((statisticCard, index) => {
                  if (statisticCard) {
                    return (
                      <HeaderCard
                        key={index}
                        icon={statisticCard.icon}
                        title={statisticCard.title}
                        badgeColor={statisticCard?.badgeColor}
                      >
                        <StatisticList>
                          {statisticCard.data.map(
                            ({ name, number, percentage }, index) => (
                              <StatisticListItemWrapper key={index}>
                                <HeaderCardStatistic
                                  name={name}
                                  number={number}
                                  percentage={percentage}
                                />
                                {index < statisticCard.data.length - 1 ? (
                                  <VerticalCardDivider />
                                ) : null}
                              </StatisticListItemWrapper>
                            )
                          )}
                        </StatisticList>
                      </HeaderCard>
                    );
                  }
                })}

                {/* <HeaderCard
                  icon={officeChairIconSecondary}
                  title={t("admin.dashboard.utilizationRate")}
                >
                  <CardItemList>
                    {FakeUtilizationStatistics.map(
                      ({ name, color, usage }, index) => (
                        <View key={index}>
                          <UtilizationBarContainer>
                            <SmallText>{name}</SmallText>
                            <UtilizationBarWrapper>
                              <UtilizationBar>
                                <UtilizationUsage color={color} usage={usage} />
                              </UtilizationBar>
                              <UtilizationPercentage>{usage}%</UtilizationPercentage>
                            </UtilizationBarWrapper>
                          </UtilizationBarContainer>
                          {index < FakeUtilizationStatistics.length - 1 ? (
                            <HorizontalCardDivider />
                          ) : null}
                        </View>
                      )
                    )}
                  </CardItemList>
                </HeaderCard> */}

                {/* <HeaderCard
                  icon={gearIcon}
                  title={t("admin.dashboard.serviceNotifications")}
                  dropdownItems={dropdownItems}
                  last
                >
                  <CardItemList>
                    {FakeNotifications.map(
                      ({ spotNumber, spotMessage, city }, index) => (
                        <View key={index}>
                          <Notice
                            spotNumber={spotNumber}
                            spotMessage={spotMessage}
                            city={city}
                          />
                          {index < FakeNotifications.length - 1 ? (
                            <HorizontalCardDivider />
                          ) : null}
                        </View>
                      )
                    )}
                  </CardItemList>
                </HeaderCard> */}
              </Column>
            </Grid>
          </View>
        )}

        {/* Dashboard is Realtime */}
        {dashboardType === DashboardType.REALTIME && (
          <View>
            <Grid style={{ zIndex: 98 }}>
              <ChartColumn
                columns={3}
                style={{
                  flexDirection: "row",
                  flexGrow: 1,
                  padding: 0,
                  flex: 2,
                }}
              >
                <div>
                  <Column style={{ flex: 2  }}>
                    {/* number of reservations */}
                    <HeaderCard title={t("admin.dashboard.numberOfReservations")}>
                      <StatisticList>
                        <StatisticListItemWrapper>
                          <HeaderCardStatistic
                            number={
                              statistics.desk?.reservationsTodayCount || "0"
                            }
                          />
                          {/* @ts-ignore style overwrites */}
                          <Text style={{ fontSize: "12px", alignSelf: "center" }}>
                            {`${statistics.desk?.isPositive ? "▲" : "▼"} ${
                              statistics.desk?.reservationsPercentage || "0"
                            }%`}
                          </Text>
                        </StatisticListItemWrapper>
                      </StatisticList>
                      <View style={{ width: '100%', height: 1, marginTop: 10, marginBottom: 10, backgroundColor: '#EFEFEF' }} />
                      <StatisticList>
                        <StatisticListItemWrapper>
                          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ padding: 15, marginRight: 10, borderRadius: 40, backgroundColor: statistics.desk?.isPositive ? '#D7FFF7' : '#FBDBDB' }}>
                              <svg width="16" height="16" viewBox="0 0 426 426" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {statistics.desk?.isPositive
                                  ? <path d="M212.92 90.0033C217.364 89.94 221.774 90.7866 225.878 92.4911C229.982 94.1956 233.695 96.7218 236.787 99.9145L415.942 278.714C419.093 281.798 421.597 285.48 423.307 289.544C425.016 293.608 425.896 297.972 425.896 302.381C425.896 306.79 425.016 311.154 423.307 315.219C421.597 319.283 419.093 322.964 415.942 326.048C409.692 332.239 401.25 335.712 392.453 335.712C383.656 335.712 375.214 332.239 368.964 326.048L212.964 170.003L56.8754 326.048C50.6253 332.239 42.1838 335.712 33.3866 335.712C24.5894 335.712 16.1478 332.239 9.89766 326.048C6.76263 322.955 4.27332 319.27 2.57425 315.208C0.875176 311.145 0.000216306 306.785 0.000216306 302.381C0.000216306 297.977 0.875176 293.617 2.57425 289.555C4.27332 285.492 6.76263 281.807 9.89766 278.714L188.698 99.9145C191.81 96.7736 195.513 94.2805 199.595 92.5794C203.676 90.8782 208.054 90.0027 212.475 90.0033H212.92Z" fill="#3EB8A0" />
                                  : <path d="M212.977 335.708C208.533 335.772 204.123 334.925 200.018 333.221C195.914 331.516 192.202 328.99 189.11 325.797L9.95438 146.997C6.80305 143.914 4.29926 140.232 2.58989 136.168C0.880526 132.104 0 127.739 0 123.331C0 118.922 0.880526 114.557 2.58989 110.493C4.29926 106.429 6.80305 102.747 9.95438 99.6639C16.2045 93.473 24.646 90 33.4433 90C42.2405 90 50.682 93.473 56.9322 99.6639L212.932 255.708L369.021 99.6639C375.271 93.473 383.713 90 392.51 90C401.307 90 409.749 93.473 415.999 99.6639C419.134 102.757 421.623 106.441 423.322 110.504C425.021 114.567 425.896 118.927 425.896 123.331C425.896 127.734 425.021 132.094 423.322 136.157C421.623 140.22 419.134 143.905 415.999 146.997L237.199 325.797C234.087 328.938 230.383 331.431 226.302 333.132C222.221 334.833 217.843 335.709 213.421 335.708H212.977Z" fill='#ED8C8B' />
                                }
                              </svg>
                            </View>
                            <Text>
                              <Text style={{ fontWeight: 'bold', color: statistics.desk?.isPositive ? '#3EB8A0' : '#ED8C8B' }}>{((statistics.desk?.reservationsTodayCount || statistics.desk?.reservationsLastWeekCount || 0) - (statistics.desk?.reservationsLastWeekCount || statistics.desk?.reservationsTodayCount || 0)) * (statistics.desk?.isPositive ? 1 : -1)} {statistics.desk?.isPositive ? t("admin.dashboard.numberOfReservationsMore1") : t("admin.dashboard.numberOfReservationsLess1")}</Text> {statistics.desk?.isPositive ? t("admin.dashboard.numberOfReservationsMore2") : t("admin.dashboard.numberOfReservationsLess2")}
                            </Text>
                          </View>
                        </StatisticListItemWrapper>
                      </StatisticList>
                    </HeaderCard>
                  </Column>
                  {/* seatOccupancy */}
                  {company.checkInEnabled && (
                    <Column style={{ flex: 1 }}>
                      <HeaderCard title={t("admin.dashboard.seatOccupancy")}>
                        <StatisticList>
                          <StatisticListItemWrapper>
                            <StatusIndicatorStatistic
                              number={
                                analyticsData?.insights?.active_check_ins || 0
                              }
                              label={t("admin.dashboard.checkedIn")}
                              dataColor={"quinary"}
                            />
                            <StatusIndicatorStatistic
                              number={
                                analyticsData?.insights?.missed_check_ins || 0
                              }
                              label={t("admin.dashboard.noShows")}
                              dataColor={"primary"}
                            />
                          </StatisticListItemWrapper>
                        </StatisticList>
                      </HeaderCard>
                    </Column>
                  )}

                  {/* occupancyDesks */}
                  <Column style={{ flex: 1 }}>
                    <HeaderCard
                      title={t("admin.dashboard.occupancyDesks")}
                      dropdownItems={seatTags}
                      onFilterChange={handleChangeFilter}
                    >
                      <Leaderboard items={leaderboardStatistics}></Leaderboard>
                    </HeaderCard>
                  </Column>
                </div>


                {/* mostUsedDeskMeetingRooms */}
                <Column style={{ flex: 1 }}>
                  <HeaderCard
                    title={t("admin.dashboard.mostUsedDeskMeetingRooms")}
                    dropdownItems={seats}
                    onFilterChange={handleChangeSeatsFilter}
                  >
                    <PadAngle data={mostUsedStatisticsFormatted} />
                    <Leaderboard items={mostUsedStatistics}></Leaderboard>
                  </HeaderCard>
                </Column>


                {company.homeEnabled && (
                  <Column style={{ flex: 1 }}>
                  {/* reservationTypeCount */}
                    <HeaderCard title={t("admin.dashboard.reservationTypeCount")}>
                      <SimpleStatsWrapper>
                        <SimpleStatWrapper>
                          <IconBadge
                            icon={locationIcon}
                            badgeColor={color.senaryLight}
                          />
                          <StatTextWrapper>
                            <StatText>
                              <LargeStatText>
                                {reservationStatisticsToday?.officeReservationCount}
                              </LargeStatText>
                              {t("admin.dashboard.reservationTypeOffice")}
                            </StatText>
                          </StatTextWrapper>
                        </SimpleStatWrapper>
                        <SimpleStatWrapper>
                          <IconBadge
                            icon={homeIcon}
                            badgeColor={color.senaryLight}
                          />
                          <StatTextWrapper>
                            <StatText>
                              <LargeStatText>
                                {reservationStatisticsToday?.homeReservationCount}
                              </LargeStatText>
                              {t("admin.dashboard.reservationTypeHome")}
                            </StatText>
                          </StatTextWrapper>
                        </SimpleStatWrapper>
                      </SimpleStatsWrapper>
                    </HeaderCard>
                  </Column>
                )}
              </ChartColumn>
            </Grid>
          </View>
        )}
      </Container>
    </>
  );
};

export default CustomerAdminDashboard;
