module["exports"] = [
  "!##-!##-####",
  "(!##) !##-####",
  "1-!##-!##-####",
  "!##.!##.####",
  "!##-!##-####",
  "(!##) !##-####",
  "1-!##-!##-####",
  "!##.!##.####",
  "!##-!##-#### x###",
  "(!##) !##-#### x###",
  "1-!##-!##-#### x###",
  "!##.!##.#### x###",
  "!##-!##-#### x####",
  "(!##) !##-#### x####",
  "1-!##-!##-#### x####",
  "!##.!##.#### x####",
  "!##-!##-#### x#####",
  "(!##) !##-#### x#####",
  "1-!##-!##-#### x#####",
  "!##.!##.#### x#####"
];
