module["exports"] = [
  "сохранить",
  "обойти",
  "взломать",
  "переопределить",
  "сжать",
  "зашифровать",
  "импортировать",
  "экспортировать",
  "копировать",
  "навигировать",
  "индексировать",
  "соединить",
  "генерировать",
  "распарсить",
  "квантифицировать",
  "вычислить",
  "синтезировать",
  "ввести",
  "передать",
  "запрограммировать",
  "перезагрузить",
  "разобрать"
];
