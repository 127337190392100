module["exports"] = [
  "أفغانستان",
  "ألبانيا",
  "الجزائر",
  "أمريكا ساماو",
  "أندورا",
  "أنجولا",
  "أنجويلا",
  "أنتاركتيكا",
  "أنتيغوا وباربودا",
  "الأرجنتين",
  "أرمينيا",
  "أروبا",
  "أرستراليا",
  "أستريا",
  "أذرابيجان",
  "بهماس",
  "البحرين",
  "بنغلادش",
  "بربادوس",
  "بلاروسيا",
  "بلجيكا",
  "بليز",
  "بينين",
  "برمودا",
  "بوتان",
  "بوليفيا",
  "البوسنة والهرسك",
  "بوتسوانا",
  "جزيرة بوفيه",
  "البرازيل",
  "إقليم المحيط الهندي البريطاني",
  "برونوي دار السلام",
  "بلغاريا",
  "بوركينا فاسو",
  "بوروندي",
  "كمبوديا",
  "كاميرون",
  "كندا",
  "الرأس الأخضر",
  "جزر كايمان",
  "جمهورية إفريقيا الوسطى",
  "التشاد",
  "شيلي",
  "الصين",
  "جزيرة عيد الميلاد",
  "جزر كوكوس",
  "كولومبيا",
  "جزر القمر",
  "كونجو",
  "جزر كوك",
  "كوستا ريكا",
  "ساحل العاج",
  "كرواتيا",
  "كوبا",
  "قبرص",
  "التشيك",
  "دنمارك",
  "جيبوتي",
  "دومينيكا",
  "جمهورية الدومينيكان",
  "إكوادور",
  "مصر",
  "السلفادور",
  "غينيا الاستوائية",
  "إريتريا",
  "إستونيا",
  "أثيوبيا",
  "جزر فارو",
  "جزر فوكلاند",
  "فيجي",
  "فلندا",
  "فرنست",
  "غويانا الفرنسية",
  "بولينزيا الفرنسية",
  "أراض فرنسية جنوبية وأنتارتيكية",
  "جابون",
  "غمبيا",
  "جورجيا",
  "ألمانيا",
  "غانا",
  "جبل طارق",
  "اليونان",
  "الأرض الخضراء",
  "غرينادا",
  "غوادلوب",
  "غوام",
  "غواتيمالا",
  "غيرنزي",
  "غينيا",
  "غينيا بيساو",
  "غيانا",
  "هايتي",
  "جزيرة هيرد وجزر ماكدونالد",
  "الفاتيكان",
  "هندوراس",
  "هونكونغ",
  "هنقاريا",
  "إسلاند",
  "الهند",
  "أندونيسيا",
  "إيران",
  "العراق",
  "إيرلامدا",
  "جزيرة مان",
  "إيطاليا",
  "جامايكا",
  "اليابان",
  "جيرزي",
  "الأردن",
  "كازاخستان",
  "كنيا",
  "كيريباتي",
  "كوريا الشمالية",
  "كوريا الجنوبية",
  "الكويت",
  "قيرغيزستان",
  "لاوس",
  "لتفيا",
  "لبنان",
  "ليسوتو",
  "ليبيريا",
  "ليبيا",
  "ليختنشتاين",
  "ليتيواتيا",
  "ليكسمبورغ",
  "ماكاو",
  "مقدونيا",
  "مدغشقر",
  "ملاوي",
  "ماليزيا",
  "ملديف",
  "مالي",
  "مالطا",
  "جزر مارشال",
  "مارتينيك",
  "موريتانيا",
  "موريشيوس",
  "مايوت",
  "المكسيك",
  "ولايات ميكرونيسيا المتحدة",
  "مولدوفا",
  "موناكو",
  "منغوليا",
  "مونتينيغرو",
  "مونتسرات",
  "المغرب",
  "موزنبيق",
  "ميانمار",
  "ناميبيا",
  "ناورو",
  "نيبال",
  "جزر الأنتيل الهولندية",
  "هولاندا",
  "كالودونيا الجديدة",
  "زيلاندا الجديدة",
  "نيكاراغوا",
  "النيجر",
  "نيجيريا",
  "نييوي",
  "جزيرة نورفولك",
  "جزر ماريانا الشمالية",
  "نورواي",
  "عمان",
  "باكستان",
  "بالاو",
  "فلسطين",
  "بانما",
  "بابوا غينيا الجديدة",
  "باراغواي",
  "بيرو",
  "الفيليبين",
  "جزر بيتكيرن",
  "بولندا",
  "البرتغال",
  "بورتو ريكو",
  "قطر",
  "لا ريونيون",
  "رومانيا",
  "روسيا",
  "روندا",
  "سان بارتيلمي",
  "سانت هيلانة",
  "سانت كيتس ونيفيس",
  "سانت لوسيا",
  "سانت نرتان",
  "سان بيير وميكلون",
  "سانت فينسنت والغرينادين",
  "ساماو",
  "سان مارينو",
  "ساو تومي وبرينسيب",
  "السعودية",
  "السنغال",
  "صربيا",
  "سيشال",
  "سيراليون",
  "سنغفورة",
  "سلوفاكيا",
  "سلوفينيا",
  "جزر سليمان",
  "الصومال",
  "جنوب إفريقيا",
  "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  "إسبانيا",
  "سيري لانكا",
  "السودان",
  "سيرينام",
  "سفالبارد ويان ماين",
  "سوازيلاند",
  "السويد",
  "سويسرا",
  "سوريا",
  "تايوات",
  "طاجكستان",
  "تنزانيا",
  "تايلاند",
  "تيمور الشرقية",
  "توغو",
  "توكيلاو",
  "تونغوا",
  "ترينيداد وتوباغو",
  "تونس",
  "تركيا",
  "تركمنستان",
  "جزر توركس وكايكوس",
  "توفالو",
  "أوغندا",
  "أكرانيا",
  "الإمارات العربية المتحدة",
  "بريطانيا",
  "أمريكا",
  "جزر الولايات المتحدة الصغيرة النائية",
  "أرغواي",
  "أزباكستان",
  "فانواتو",
  "فينيزويلا",
  "فيتنام",
  "جزر العذراء البريطانية",
  "جزر العذراء الأمريكية",
  "واليس وفوتونا",
  "اليمن",
  "زمبيا",
  "زمبابوي"
];
