module["exports"] = [
  "+919##-###-####",
  "+919#########",
  "+91-9##-#######",
  "+918##-###-####",
  "+918#########",
  "+91-8##-#######",
  "+917##-###-####",
  "+917#########",
  "+91-7##-#######",
  "+916##-###-####",
  "+916#########",
  "+91-6##-#######",
];
