module["exports"] = [
  "pt",
  "gov.pt",
  "com.pt",
  "org.pt",
  "eu",
  "com",
  "biz",
  "info",
  "name",
  "net",
  "org"
];
