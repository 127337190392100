import styled, { css } from "styled-components/native";

import Text, { TextProps, FontWeightProps, FontItalicProps } from "./text";
import { font, text } from "../theme";

export const ExtraSmallText = styled(Text)<TextProps & FontWeightProps>`
  font-size: ${text.extraSmall.size};
  ${({ mediumWeight }) =>
    mediumWeight &&
    css`
    font-family: ${font.defaultMedium}};
 `}
`;
// TODO Change this to ExtraSmallText, good refactor is too big of a scope right now since the large line-heights are used throughout the whole app
export const ExtraSmallTextGood = styled(Text)<
  TextProps & FontWeightProps & FontItalicProps
>`
  font-size: ${text.extraSmallGood.size};
  line-height: ${text.extraSmallGood.lineHeight};
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  ${({ mediumWeight }) =>
    mediumWeight &&
    css`
    font-family: ${font.defaultMedium}};
 `}
`;
