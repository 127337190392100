module["exports"] = [
  "كارغو فان",
  "مكشوفة",
  "كوبيه",
  "طاقم الكابينة بيك آب",
  "تمديد الكابينة بيك آب",
  "هاتشباك",
  "ميني فان",
  "سيارة الركاب",
  "سيارة رياضية",
  "سيدان",
  "عربة"
];
