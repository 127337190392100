import React, { FC } from "react";
import styled, { css } from "styled-components/native";
import {
  borderRadius,
  borderWidth,
  boxShadow,
  color,
  font,
  spacing,
  Position,
  flex
} from "../../styles/theme";
import { SmallText } from "../../styles/Text";
import { ImageSourcePropType, View, ViewProps } from "react-native";
import plusIcon from "./controlIcons/plus_dark.svg";
import plusIconActive from "./controlIcons/plus.svg";
import walkingRouteIcon from "./controlIcons/walking_route_dark.svg";
import walkingRouteIconActive from "./controlIcons/walking_route.svg";
import zoneIcon from "./controlIcons/zone_dark.svg";
import zoneIconActive from "./controlIcons/zone.svg";
import duplicateIcon from "./controlIcons/duplicate_dark.svg";
import deleteIcon from "./controlIcons/delete_dark.svg";
import resetIcon from "./controlIcons/reset.svg";
import rotateCCWIcon from "./controlIcons/icon_rotate_left.svg";
import rotateCWIcon from "./controlIcons/icon_rotate_right.svg";
import alignLeftIcon from "./controlIcons/align_left_dark.svg";
import alignRightIcon from "./controlIcons/align_right_dark.svg";
import alignTopIcon from "./controlIcons/align_top_dark.svg";
import alignBottomIcon from "./controlIcons/align_bottom_dark.svg";
import { AmountIndicatorDark } from "../../styles/AmountIndicator";
import { t } from "../../i18n";

const ControlBarWrapper = styled.View`
  background-color: ${color.greyLighter};
  width: calc(100% + ${spacing.large} * 2);
  margin-left: -${spacing.large};
  padding: ${spacing.medium} ${spacing.large};
  flex-direction: ${flex.direction.row};
  position: ${Position.sticky};
  top: 100px;
  z-index: 10;
  cursor: auto;
`;

const ButtonTitle = styled(SmallText)`
  margin-right: ${spacing.medium};
`;

const Title = styled(ButtonTitle)`
  font-family: ${font.defaultMedium};
  position: relative;
`;

const buttonHeight = "40px";
const buttonWidth = "50px";

interface ButtonProps extends ViewProps {
  disabled?: boolean;
  active?: boolean;
  icon: ImageSourcePropType;
  activeIcon?: ImageSourcePropType;
}

const buttonStyle = `
  height: ${buttonHeight};
  width: ${buttonWidth};
  background-color: ${color.white};
  flex-grow: 1;
  border-radius: ${borderRadius.small};
  background-position: center;
  background-repeat: no-repeat;
  `;

const ControlButton = styled.View<ButtonProps>`
  ${buttonStyle};
  margin-right: ${spacing.small};

  ${({ icon }) => css`
    background-image: url(${icon.toString()});
  `}

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${color.white};
          border: ${borderWidth.small} solid ${color.darker};
          opacity: 0.1;
        `
      : css`
          ${boxShadow.small}
        `}
  ${({ active, activeIcon }) =>
    active &&
    css`
      background-color: ${color.darker};
      ${activeIcon && `background-image: url(${activeIcon})`}
    `};
`;

interface ControlButtonWrapperProps extends ViewProps {
  last?: boolean;
}

const ControlButtonWrapper = styled.View<ControlButtonWrapperProps>`
  flex-grow: 0;
  flex-direction: row;
  align-items: center;

  ${({ last }) =>
    !last &&
    css`
      padding-right: ${spacing.large};
      margin-right: calc(${spacing.large} + ${spacing.small});
      border-width: ${borderWidth.small};
      border-color: transparent;
      border-right-color: ${color.greyLight};
    `}
`;

const SingleButtonWrapper = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

const CombinedControlButtonWrapper = styled.View`
  flex-direction: row;
  margin-right: ${spacing.small};
`;

const AmountIndicator = styled(AmountIndicatorDark)`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: calc(100% + ${spacing.small});
`;

interface CombinedButtonInterface extends ControlButtonInterface {
  last?: boolean;
  first?: boolean;
}

const CombinedControlButton = styled.TouchableOpacity<CombinedButtonInterface>`
  ${buttonStyle};
  margin-right: -${borderWidth.small};
  border-radius: 0;

  ${({ first }) =>
    first &&
    css`
      border-top-left-radius: ${borderRadius.small};
      border-bottom-left-radius: ${borderRadius.small};
    `}

  ${({ last }) =>
    last &&
    css`
      border-top-right-radius: ${borderRadius.small};
      border-bottom-right-radius: ${borderRadius.small};
      margin-right: 0;
    `}
  ${({ icon }) => css`
    background-image: url(${icon.toString()});
  `}

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${color.white};
          border: ${borderWidth.small} solid ${color.darker};
          opacity: 0.1;
        `
      : css`
          ${boxShadow.small}
        `}
  ${({ active }) =>
    active &&
    css`
      background-color: ${color.darker};
    `};
`;

interface ControlButtonInterface {
  name?: string;
  icon: ImageSourcePropType;
  activeIcon?: ImageSourcePropType;
  disabled?: boolean;
  active?: boolean;
  onPress?: () => void;
}

interface CombinedControlButtonsInterface {
  items: ControlButtonInterface[];
}

interface ControlButtonCollectionInterface {
  name: string;
  hasAmountIndicator?: boolean;
  items: Array<ControlButtonInterface | CombinedControlButtonsInterface>;
}

interface ControlBarProps {
  isAddingSeat: boolean;
  toggleIsAddingSeat: () => void;
  isAddingWalkingRoute: boolean;
  toggleIsAddingWalkingRoute: () => void;
  isCreatingZone: boolean;
  toggleIsCreatingZone: () => void;
  deletable?: boolean;
  hasSelectedItems: boolean;
  hasRotatableItems: boolean;
  isResettable?: boolean;
  reset?: () => void;
  rotateSelection: (direction: number) => void;
  duplicateSelection: () => void;
  deleteSelection: () => void;
  alignTop: () => void;
  alignBottom: () => void;
  alignLeft: () => void;
  alignRight: () => void;
  amountOfSelectedItems: number;
}

const ControlBar: FC<ControlBarProps> = ({
  isAddingSeat,
  toggleIsAddingSeat,
  isAddingWalkingRoute,
  toggleIsAddingWalkingRoute,
  isCreatingZone,
  toggleIsCreatingZone,
  deletable= true,
  hasSelectedItems,
  hasRotatableItems,
  rotateSelection,
  duplicateSelection,
  deleteSelection,
  alignTop,
  alignBottom,
  alignLeft,
  alignRight,
  amountOfSelectedItems,
  isResettable = false,
  reset = () => null,
}) => {
  const instanceOfButton = (
    button: ControlButtonInterface | CombinedControlButtonsInterface
  ): button is ControlButtonInterface => Boolean("icon" in button);

  const menuItems: ControlButtonCollectionInterface[] = [
    {
      name: t("admin.map.add.title"),
      items: [
        {
          // name: t("admin.map.add.workspace"),
          icon: plusIcon,
          activeIcon: plusIconActive,
          active: isAddingSeat,
          onPress: toggleIsAddingSeat,
        },
        {
          // name: t("admin.map.add.walkingRoute"),
          icon: walkingRouteIcon,
          activeIcon: walkingRouteIconActive,
          active: isAddingWalkingRoute,
          onPress: toggleIsAddingWalkingRoute,
        },
        // {
        //   name: t("admin.map.add.workZone"),
        //   icon: zoneIcon,
        //   activeIcon: zoneIconActive,
        //   active: isCreatingZone,
        //   onPress: toggleIsCreatingZone,
        // },
      ],
    },
    {
      name: t("admin.map.edit.title"),
      hasAmountIndicator: true,
      items: [
        {
          icon: duplicateIcon,
          disabled: !hasSelectedItems,
          onPress: duplicateSelection,
        },
        {
          icon: deleteIcon,
          disabled: !hasSelectedItems || !deletable,
          onPress: deleteSelection,
        },
        {
          items: [
            {
              icon: rotateCCWIcon,
              disabled: !hasRotatableItems,
              onPress: () => rotateSelection(-1),
            },
            {
              icon: rotateCWIcon,
              disabled: !hasRotatableItems,
              onPress: () => rotateSelection(1),
            },
          ],
        },
        {
          items: [
            {
              icon: resetIcon,
              disabled: !isResettable,
              onPress: () => reset(),
            },
          ],
        },
      ],
    },
    {
      name: t("admin.map.align.title"),
      hasAmountIndicator: true,
      items: [
        {
          items: [
            {
              icon: alignLeftIcon,
              disabled: !hasSelectedItems || amountOfSelectedItems === 1,
              onPress: alignLeft,
            },
            {
              icon: alignRightIcon,
              disabled: !hasSelectedItems || amountOfSelectedItems === 1,
              onPress: alignRight,
            },
            {
              icon: alignTopIcon,
              disabled: !hasSelectedItems || amountOfSelectedItems === 1,
              onPress: alignTop,
            },
            {
              icon: alignBottomIcon,
              disabled: !hasSelectedItems || amountOfSelectedItems === 1,
              onPress: alignBottom,
            },
          ],
        },
      ],
    },
  ];

  return (
    <ControlBarWrapper>
      {menuItems.map(({ name, items, hasAmountIndicator }, index, array) => (
        <ControlButtonWrapper key={name} last={index == array.length - 1}>
          <Title>
            {hasAmountIndicator && amountOfSelectedItems > 0 ? (
              <AmountIndicator>{amountOfSelectedItems}</AmountIndicator>
            ) : null}
            {name}
          </Title>
          {items.map((item, index) => (
            <View key={index}>
              {instanceOfButton(item) ? (
                <SingleButtonWrapper
                  key={item.name}
                  disabled={item.disabled}
                  onPress={item.onPress}
                >
                  <ControlButton {...item} />
                  {item.name ? <ButtonTitle>{item.name}</ButtonTitle> : null}
                </SingleButtonWrapper>
              ) : (
                <CombinedControlButtonWrapper>
                  {item.items.map(({ ...rest }, index, array) => (
                    <CombinedControlButton
                      key={index}
                      first={index === 0}
                      last={index === array.length - 1}
                      {...rest}
                    />
                  ))}
                </CombinedControlButtonWrapper>
              )}
            </View>
          ))}
        </ControlButtonWrapper>
      ))}
    </ControlBarWrapper>
  );
};

export default ControlBar;
