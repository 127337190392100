module["exports"] = [
  "Aarau",
  "Adliswil",
  "Aesch",
  "Affoltern am Albis",
  "Aigle",
  "Allschwil",
  "Altdorf",
  "Altstätten",
  "Amriswil",
  "Appenzell",
  "Arbon",
  "Arlesheim",
  "Arosa",
  "Arth",
  "Baar",
  "Baden",
  "Bassersdorf",
  "Bellinzone",
  "Belp",
  "Berne",
  "Berthoud",
  "Bienne",
  "Binningen",
  "Birsfelden",
  "Brigue-Glis",
  "Brugg",
  "Buchs",
  "Bulle",
  "Bussigny",
  "Bâle",
  "Bülach",
  "Carouge",
  "Cham",
  "Chiasso",
  "Chêne-Bougeries",
  "Coire",
  "Crissier",
  "Davos",
  "Delémont",
  "Dietikon",
  "Dübendorf",
  "Ebikon",
  "Einsiedeln",
  "Emmen",
  "Flawil",
  "Frauenfeld",
  "Freienbach",
  "Fribourg",
  "Genève",
  "Gland",
  "Glaris Centre",
  "Glaris Nord",
  "Gossau",
  "Granges",
  "Herisau",
  "Hinwil",
  "Horgen",
  "Horw",
  "Illnau-Effretikon",
  "Interlaken",
  "Ittigen",
  "Kloten",
  "Kreuzlingen",
  "Kriens",
  "Köniz",
  "Küsnacht",
  "La Chaux-de-Fonds",
  "La Neuveville",
  "La Tour-de-Peilz",
  "Lancy",
  "Langenthal",
  "Lausanne",
  "Le Grand-Saconnex",
  "Le Locle",
  "Lenzbourg",
  "Liestal",
  "Locarno",
  "Lucerne",
  "Lugano",
  "Lyss",
  "Martigny",
  "Meilen",
  "Mendrisio",
  "Meyrin",
  "Monthey",
  "Montreux",
  "Morat",
  "Morges",
  "Moutier",
  "Muri bei Bern",
  "Muttenz",
  "Männedorf",
  "Möhlin",
  "Münchenbuchsee",
  "Münchenstein",
  "Münsingen",
  "Neuchâtel",
  "Neuhausen am Rheinfall",
  "Nyon",
  "Oberwil",
  "Oftringen",
  "Olten",
  "Onex",
  "Opfikon",
  "Ostermundigen",
  "Payerne",
  "Peseux",
  "Pfäffikon",
  "Plan-les-Ouates",
  "Poliez-Pittet",
  "Porrentruy",
  "Pratteln",
  "Prilly",
  "Pully",
  "Rapperswil-Jona",
  "Regensdorf",
  "Reinach",
  "Renens",
  "Rheinfelden",
  "Richterswil",
  "Riehen",
  "Risch-Rotkreuz",
  "Romanshorn",
  "Rorschach",
  "Rüti",
  "Saint-Gall",
  "Saint-Moritz",
  "Sarnen",
  "Schaffhouse",
  "Schlieren",
  "Schwytz",
  "Sierre",
  "Sion",
  "Soleure",
  "Spiez",
  "Spreitenbach",
  "Stans",
  "Steffisburg",
  "Steinhausen",
  "Stäfa",
  "Suhr",
  "Sursee",
  "Thalwil",
  "Thoune",
  "Thônex",
  "Urdorf",
  "Uster",
  "Uzwil",
  "Vernier",
  "Versoix",
  "Vevey",
  "Veyrier",
  "Villars-sur-Glâne",
  "Viège",
  "Volketswil",
  "Wallisellen",
  "Weinfelden",
  "Wettingen",
  "Wetzikon",
  "Wil",
  "Winterthour",
  "Wohlen",
  "Worb",
  "Wädenswil",
  "Yverdon-les-Bains",
  "Zermatt",
  "Zofingue",
  "Zollikofen",
  "Zollikon",
  "Zoug",
  "Zuchwil",
  "Zurich",
  "Écublens"
];
