import React, { FC } from 'react';
import styled from 'styled-components/native';
import HeaderCard from '../../../../components/HeaderCard';
import { t } from "../../../../i18n";
import timeSlotIcon from '../../../../assets/icons/icon-timeslot.svg';
import TimeslotOverview from '../../TimeslotOverview';
import Button from "../../../../components/Button";
import { useHistory } from "../../../../routing";

const HeaderCardTitleSuffixWrapper = styled.View`
  margin-left: auto;
`;

const TimeSlotCard: FC = () => {
  const history = useHistory();

  return (
    <HeaderCard
      icon={timeSlotIcon}
      badgeColor="transparent"
      title={t("admin.timeslot.overview.title")}
      titleSuffix={
        <HeaderCardTitleSuffixWrapper>
          <Button
            onPress={() => history.push("/admin/timeslot-overview/create")}
          >
            {t("admin.timeslot.overview.addButton")}
          </Button>

        </HeaderCardTitleSuffixWrapper>
      }
    >
      <TimeslotOverview />
    </HeaderCard>
  );
};

export default TimeSlotCard;
