module["exports"] = [
  "אוגנדה",
"אוזבקיסטאן",
"אוסטריה",
"אוסטרליה",
"אוקראינה",
"אורוגואי",
"אזרביג'אן",
"איטליה",
"איי הבהאמס",
"איי מרשל",
"איי סיישל",
"איי קוק",
"איי שלמה",
"איסלנד",
"איראן",
"אירלנד",
"אל סלבדור",
"אלבניה",
"אנגולה",
"אנדורה",
"אנטיגואהברבודה",
"אסוואטיני",
"אסטוניה",
"אקוואדור",
"ארגנטינה",
"אריתריאה",
"ארמניה",
"ארצות הברית",
"אתיופיה",
"בולגריה",
"בוליביה",
"בוסניה הרצגובינה",
"בורונדי",
"בורקינה פסו",
"בחריין",
"בלארוס",
"בלגיה",
"בליז",
"בנין",
"ברבדוס",
"ברזיל",
"בריטניה",
"ג'מאיקה",
"גאבון",
"גאנה",
"גואטמלה",
"גויאנה",
"גיאורגיה",
"גינאה",
"גינאה המשוונית",
"גינאהביסאו",
"גמביה",
"גרמניה",
"גרנדה",
"דומיניקה",
"דנמרק",
"דרום אפריקה",
"דרום סודאן",
"האיטי",
"הודו",
"הולנד",
"הונגריה",
"הונדורס",
"הרפובליקה הדומיניקנית",
"הרפובליקה הדמוקרטית של קונגו",
"ואטיקאןהכס הקדוש",
"ויטנאם",
"ונואטו",
"ונצואלה",
"זימבאבווה",
"זמביה",
"חוף השנהב (קוט דיוואר)",
"טג'קיסטאן",
"טובלו",
"טוגו",
"טונגה",
"טייואן",
"טנזניה",
"טרינידד וטובגו",
"יוון",
"יפן",
"ירדן",
"לאוס רפובליקה דמוקרטית עממית",
"לבנון",
"לוקסמבורג",
"לטביה",
"ליבריה",
"ליטא",
"ליכטנשטיין",
"לסוטו",
"מאוריטניה",
"מאוריציוס",
"מאלי",
"מאע׳׳מ אמירויות ערביות מאוחדות",
"מדגסקר",
"מוזמביק",
"מולדובה",
"מונגוליה",
"מונטנגרו",
"מונקו",
"מזרח טימור",
"מיאנמר",
"מיקרונזיה",
"מכסיקו",
"מלאווי",
"מלטה",
"מצרים",
"מקדוניה",
"מרוקו",
"נאורו",
"נורבגיה",
"ניגריה",
"ניו זילנד",
"ניז'ר",
"ניקרגואה",
"נמיביה",
"נפאל",
"סאו תומה ופרינציפה",
"סורינם",
"סט' וינסנט והגרנדינים",
"סט' לוציה",
"סט' קיטס ונביס",
"סיירה ליאונה",
"סין",
"סינגפור",
"סלובניה",
"סלובקיה",
"סמואה",
"סן מרינו",
"סנגל",
"ספרד",
"סרביה",
"סרי לנקה",
"עומאן",
"פולין",
"פורטוגל",
"פיג'י",
"פיליפינים",
"פינלנד",
"פלאו",
"פנמה",
"פפואה ניו גיני",
"פרגוואי",
"פרו",
"צ'אד",
"צ'ילה",
"צ'כיה",
"צרפת",
"קובה",
"קולומביה",
"קומורוס",
"קונגו",
"קוסטה ריקה",
"קוריאה",
"קזחסטאן",
"קטר",
"קייפ ורדה",
"קירגיסטאן",
"קיריבטי",
"קמבודיה",
"קמרון",
"קנדה",
"קניה",
"קפריסין",
"קרואטיה",
"רואנדה",
"רומניה",
"רוסיה",
"רפובליקה מרכז אפריקנית",
"שבדיה",
"שווייץ",
"תאילנד",
"תוניסיה",
"תורכיה",
"תורכמניסטאן"
];
