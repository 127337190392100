module["exports"] = [
  "Aleksi",
  "Antero",
  "Antti",
  "Ari",
  "Eero",
  "Ensio",
  "Erik",
  "Erkki",
  "Hannu",
  "Heikki",
  "Henrik",
  "Ilmari",
  "Jaakko",
  "Janne",
  "Jari",
  "Johannes",
  "Juha",
  "Juhani",
  "Juho",
  "Jukka",
  "Kalervo",
  "Kalevi",
  "Kari",
  "Kristian",
  "Lauri",
  "Markku",
  "Marko",
  "Markus",
  "Martti",
  "Matias",
  "Matti",
  "Mika",
  "Mikael",
  "Mikko",
  "Olavi",
  "Oskari",
  "Pekka",
  "Pentti",
  "Petri",
  "Petteri",
  "Sakari",
  "Sami",
  "Seppo",
  "Tapani",
  "Tapio",
  "Timo",
  "Tuomas",
  "Valtteri",
  "Veikko",
  "Ville"
]