module["exports"] = [
  "'s Herenelderen",
  "'s-Gravenvoeren",
  "'s-Gravenwezel",
  "Aaigem",
  "Aalbeke",
  "Aalst",
  "Aalst",
  "Aalter",
  "Aarschot",
  "Aarsele",
  "Aartrijke",
  "Aartselaar",
  "Achel",
  "Adegem",
  "Adinkerke",
  "Afsnee",
  "Alken",
  "Alsemberg",
  "Alveringem",
  "Antwerpen",
  "Anzegem",
  "Appels",
  "Appelterre-Eichem",
  "Ardooie",
  "Arendonk",
  "As",
  "Aspelare",
  "Asper",
  "Asse",
  "Assebroek",
  "Assenede",
  "Assent",
  "Astene",
  "Attenhoven",
  "Attenrode-Wever",
  "Avekapelle",
  "Avelgem",
  "Averbode",
  "Baaigem",
  "Baal",
  "Baardegem",
  "Baarle-Hertog",
  "Baasrode",
  "Bachte-Maria-Leerne",
  "Balegem",
  "Balen",
  "Bambrugge",
  "Bassevelde",
  "Batsheers",
  "Bavegem",
  "Bavikhove",
  "Bazel",
  "Beek",
  "Beerlegem",
  "Beernem",
  "Beerse",
  "Beersel",
  "Beerst",
  "Beert",
  "Beervelde",
  "Beerzel",
  "Begijnendijk",
  "Beigem",
  "Bekegem",
  "Bekkerzeel",
  "Bekkevoort",
  "Bellegem",
  "Bellem",
  "Bellingen",
  "Belsele",
  "Berbroek",
  "Berchem",
  "Berchem",
  "Berendrecht-Zandvliet-Lillo",
  "Berg",
  "Berg",
  "Beringen",
  "Berlaar",
  "Berlare",
  "Berlingen",
  "Bertem",
  "Beselare",
  "Betekom",
  "Bevel",
  "Bever",
  "Bevere",
  "Beveren",
  "Beveren",
  "Beveren",
  "Beveren",
  "Beverlo",
  "Beverst",
  "Bierbeek",
  "Bikschote",
  "Bilzen",
  "Binderveld",
  "Binkom",
  "Bissegem",
  "Blaasveld",
  "Blanden",
  "Blankenberge",
  "Bocholt",
  "Boechout",
  "Boekhout",
  "Boekhoute",
  "Boezinge",
  "Bogaarden",
  "Bommershoven",
  "Bonheiden",
  "Booischot",
  "Booitshoeke",
  "Boom",
  "Boorsem",
  "Boortmeerbeek",
  "Borchtlombeek",
  "Borgerhout",
  "Borgloon",
  "Borlo",
  "Bornem",
  "Borsbeek",
  "Borsbeke",
  "Bossuit",
  "Bost",
  "Bottelare",
  "Boutersem",
  "Bouwel",
  "Bovekerke",
  "Brasschaat",
  "Brecht",
  "Bredene",
  "Bree",
  "Breendonk",
  "Brielen",
  "Broechem",
  "Broekom",
  "Brugge",
  "Brussegem",
  "Brustem",
  "Budingen",
  "Buggenhout",
  "Buizingen",
  "Buken",
  "Bulskamp",
  "Bunsbeek",
  "Burcht",
  "Burst",
  "Buvingen",
  "Dadizele",
  "Daknam",
  "Damme",
  "De Klinge",
  "De Moeren",
  "De Panne",
  "De Pinte",
  "Deerlijk",
  "Deftinge",
  "Deinze",
  "Denderbelle",
  "Denderhoutem",
  "Denderleeuw",
  "Dendermonde",
  "Denderwindeke",
  "Dentergem",
  "Dessel",
  "Desselgem",
  "Destelbergen",
  "Desteldonk",
  "Deurle",
  "Deurne",
  "Deurne",
  "Diegem",
  "Diepenbeek",
  "Diest",
  "Diets-Heur",
  "Dikkebus",
  "Dikkele",
  "Dikkelvenne",
  "Diksmuide",
  "Dilbeek",
  "Dilsen",
  "Doel",
  "Donk",
  "Dormaal",
  "Dranouter",
  "Drieslinter",
  "Drogenbos",
  "Drongen",
  "Dudzele",
  "Duffel",
  "Duisburg",
  "Duras",
  "Dworp",
  "Edegem",
  "Edelare",
  "Eeklo",
  "Eernegem",
  "Egem",
  "Eggewaartskapelle",
  "Eigenbilzen",
  "Eindhout",
  "Eine",
  "Eisden",
  "Eke",
  "Ekeren",
  "Eksaarde",
  "Eksel",
  "Elen",
  "Elene",
  "Elewijt",
  "Eliksem",
  "Elingen",
  "Ellikom",
  "Elsegem",
  "Elst",
  "Elverdinge",
  "Elversele",
  "Emblem",
  "Emelgem",
  "Ename",
  "Engelmanshoven",
  "Eppegem",
  "Erembodegem",
  "Erondegem",
  "Erpe",
  "Erps-Kwerps",
  "Ertvelde",
  "Erwetegem",
  "Esen",
  "Essen",
  "Essene",
  "Etikhove",
  "Ettelgem",
  "Everbeek",
  "Everberg",
  "Evergem",
  "Ezemaal",
  "Gaasbeek",
  "Galmaarden",
  "Gavere",
  "Geel",
  "Geetbets",
  "Gelinden",
  "Gellik",
  "Gelrode",
  "Geluveld",
  "Geluwe",
  "Genk",
  "Genoelselderen",
  "Gent",
  "Gentbrugge",
  "Geraardsbergen",
  "Gerdingen",
  "Gestel",
  "Gierle",
  "Gijverinkhove",
  "Gijzegem",
  "Gijzelbrechtegem",
  "Gijzenzele",
  "Gingelom",
  "Gistel",
  "Gits",
  "Glabbeek-Zuurbemde",
  "Godveerdegem",
  "Goeferdinge",
  "Goetsenhoven",
  "Gontrode",
  "Gooik",
  "Gors-Opleeuw",
  "Gorsem",
  "Gotem",
  "Gottem",
  "Grammene",
  "Grazen",
  "Grembergen",
  "Grimbergen",
  "Grimminge",
  "Grobbendonk",
  "Groot-Bijgaarden",
  "Groot-Gelmen",
  "Groot-Loon",
  "Grote-Brogel",
  "Grote-Spouwen",
  "Grotenberge",
  "Gruitrode",
  "Guigoven",
  "Gullegem",
  "Gutschoven",
  "Haacht",
  "Haaltert",
  "Haasdonk",
  "Haasrode",
  "Hakendover",
  "Halen",
  "Hallaar",
  "Halle",
  "Halle",
  "Halle-Booienhoven",
  "Halmaal",
  "Hamme",
  "Hamme",
  "Hamont",
  "Handzame",
  "Hansbeke",
  "Harelbeke",
  "Hasselt",
  "Hechtel",
  "Heers",
  "Hees",
  "Heestert",
  "Heffen",
  "Heikruis",
  "Heindonk",
  "Heist",
  "Heist-op-den-Berg",
  "Hekelgem",
  "Heks",
  "Helchteren",
  "Heldergem",
  "Helen-Bos",
  "Helkijn",
  "Hemelveerdegem",
  "Hemiksem",
  "Hendrieken",
  "Henis",
  "Heppen",
  "Herderen",
  "Herdersem",
  "Herent",
  "Herentals",
  "Herenthout",
  "Herfelingen",
  "Herk-de-Stad",
  "Herne",
  "Herselt",
  "Herstappe",
  "Herten",
  "Hertsberge",
  "Herzele",
  "Heule",
  "Heurne",
  "Heusden",
  "Heusden",
  "Hever",
  "Heverlee",
  "Hillegem",
  "Hingene",
  "Hoboken",
  "Hoegaarden",
  "Hoeilaart",
  "Hoeke",
  "Hoelbeek",
  "Hoeleden",
  "Hoepertingen",
  "Hoeselt",
  "Hoevenen",
  "Hofstade",
  "Hofstade",
  "Hollebeke",
  "Holsbeek",
  "Hombeek",
  "Hooglede",
  "Hoogstade",
  "Hoogstraten",
  "Horpmaal",
  "Houtave",
  "Houtem",
  "Houthalen",
  "Houthulst",
  "Houtvenne",
  "Houwaart",
  "Hove",
  "Huise",
  "Huizingen",
  "Huldenberg",
  "Hulshout",
  "Hulste",
  "Humbeek",
  "Hundelgem",
  "Ichtegem",
  "Iddergem",
  "Idegem",
  "Ieper",
  "Impe",
  "Ingelmunster",
  "Ingooigem",
  "Itegem",
  "Itterbeek",
  "Izegem",
  "Izenberge",
  "Jabbeke",
  "Jesseren",
  "Jeuk",
  "Kaaskerke",
  "Kachtem",
  "Kaggevinne",
  "Kalken",
  "Kallo",
  "Kalmthout",
  "Kampenhout",
  "Kanegem",
  "Kanne",
  "Kapelle-op-den-Bos",
  "Kapellen",
  "Kapellen",
  "Kaprijke",
  "Kaster",
  "Kasterlee",
  "Kaulille",
  "Keerbergen",
  "Keiem",
  "Kemmel",
  "Kemzeke",
  "Kerkhove",
  "Kerkom",
  "Kerkom-bij-Sint-Truiden",
  "Kerksken",
  "Kermt",
  "Kerniel",
  "Kersbeek-Miskom",
  "Kessel",
  "Kessel-Lo",
  "Kessenich",
  "Kester",
  "Kieldrecht",
  "Kinrooi",
  "Klein-Gelmen",
  "Kleine-Brogel",
  "Kleine-Spouwen",
  "Klemskerke",
  "Klerken",
  "Kluizen",
  "Knesselare",
  "Knokke",
  "Kobbegem",
  "Koekelare",
  "Koersel",
  "Koksijde",
  "Koningshooikt",
  "Koninksem",
  "Kontich",
  "Kooigem",
  "Koolkerke",
  "Koolskamp",
  "Korbeek-Dijle",
  "Korbeek-Lo",
  "Kortemark",
  "Kortenaken",
  "Kortenberg",
  "Kortessem",
  "Kortijs",
  "Kortrijk",
  "Kortrijk-Dutsel",
  "Kozen",
  "Kraainem",
  "Krombeke",
  "Kruibeke",
  "Kruishoutem",
  "Kumtich",
  "Kuringen",
  "Kuttekoven",
  "Kuurne",
  "Kwaadmechelen",
  "Kwaremont",
  "Laar",
  "Laarne",
  "Lampernisse",
  "Lanaken",
  "Landegem",
  "Landen",
  "Landskouter",
  "Langdorp",
  "Langemark",
  "Lanklaar",
  "Lapscheure",
  "Lauw",
  "Lauwe",
  "Lebbeke",
  "Lede",
  "Ledeberg",
  "Ledegem",
  "Leefdaal",
  "Leerbeek",
  "Leest",
  "Leeuwergem",
  "Leffinge",
  "Leisele",
  "Leke",
  "Lembeek",
  "Lembeke",
  "Lemberge",
  "Lendelede",
  "Leopoldsburg",
  "Letterhoutem",
  "Leupegem",
  "Leut",
  "Leuven",
  "Lichtaart",
  "Lichtervelde",
  "Liedekerke",
  "Lieferinge",
  "Lier",
  "Liezele",
  "Lille",
  "Linden",
  "Linkebeek",
  "Linkhout",
  "Lint",
  "Lippelo",
  "Lissewege",
  "Lo",
  "Lochristi",
  "Loenhout",
  "Loker",
  "Lokeren",
  "Loksbergen",
  "Lombardsijde",
  "Lommel",
  "Londerzeel",
  "Loonbeek",
  "Loppem",
  "Lot",
  "Lotenhulle",
  "Lovendegem",
  "Lovenjoel",
  "Lubbeek",
  "Lummen",
  "Maarke-Kerkem",
  "Maaseik",
  "Machelen",
  "Machelen",
  "Mal",
  "Maldegem",
  "Malderen",
  "Mannekensvere",
  "Mariakerke",
  "Mariekerke",
  "Marke",
  "Markegem",
  "Martenslinde",
  "Massemen",
  "Massenhoven",
  "Mater",
  "Mazenzele",
  "Mechelen",
  "Mechelen-Bovelingen",
  "Mechelen-aan-de-Maas",
  "Meensel-Kiezegem",
  "Meer",
  "Meerbeek",
  "Meerbeke",
  "Meerdonk",
  "Meerhout",
  "Meerle",
  "Meeswijk",
  "Meetkerke",
  "Meeuwen",
  "Meigem",
  "Meilegem",
  "Meise",
  "Melden",
  "Meldert",
  "Meldert",
  "Meldert",
  "Melkwezer",
  "Melle",
  "Melsbroek",
  "Melsele",
  "Melsen",
  "Membruggen",
  "Mendonk",
  "Menen",
  "Merchtem",
  "Mere",
  "Merelbeke",
  "Merendree",
  "Merkem",
  "Merksem",
  "Merksplas",
  "Mesen",
  "Mespelare",
  "Messelbroek",
  "Mettekoven",
  "Meulebeke",
  "Michelbeke",
  "Middelburg",
  "Middelkerke",
  "Mielen-boven-Aalst",
  "Millen",
  "Minderhout",
  "Moelingen",
  "Moen",
  "Moerbeke",
  "Moerbeke",
  "Moere",
  "Moerkerke",
  "Moerzeke",
  "Mol",
  "Molenbeek-Wersbeek",
  "Molenbeersel",
  "Molenstede",
  "Mollem",
  "Montenaken",
  "Moorsel",
  "Moorsele",
  "Moorslede",
  "Moortsele",
  "Mopertingen",
  "Moregem",
  "Morkhoven",
  "Mortsel",
  "Muizen",
  "Muizen",
  "Mullem",
  "Munkzwalm",
  "Munsterbilzen",
  "Munte",
  "Nazareth",
  "Nederboelare",
  "Nederbrakel",
  "Nederename",
  "Nederhasselt",
  "Nederokkerzeel",
  "Nederzwalm-Hermelgem",
  "Neerglabbeek",
  "Neerharen",
  "Neerhespen",
  "Neerijse",
  "Neerlanden",
  "Neerlinter",
  "Neeroeteren",
  "Neerpelt",
  "Neerrepen",
  "Neervelp",
  "Neerwinden",
  "Neigem",
  "Nerem",
  "Nevele",
  "Niel",
  "Niel-bij-As",
  "Niel-bij-Sint-Truiden",
  "Nieuwenhove",
  "Nieuwenrode",
  "Nieuwerkerken",
  "Nieuwerkerken",
  "Nieuwkapelle",
  "Nieuwkerke",
  "Nieuwkerken-Waas",
  "Nieuwmunster",
  "Nieuwpoort",
  "Nieuwrode",
  "Nijlen",
  "Ninove",
  "Nokere",
  "Noorderwijk",
  "Noordschote",
  "Nossegem",
  "Nukerke",
  "Oedelem",
  "Oekene",
  "Oelegem",
  "Oeren",
  "Oeselgem",
  "Oetingen",
  "Oevel",
  "Okegem",
  "Olen",
  "Olmen",
  "Olsene",
  "Onkerzele",
  "Onze-Lieve-Vrouw-Lombeek",
  "Onze-Lieve-Vrouw-Waver",
  "Ooigem",
  "Ooike",
  "Oombergen",
  "Oorbeek",
  "Oordegem",
  "Oostakker",
  "Oostduinkerke",
  "Oosteeklo",
  "Oostende",
  "Oosterzele",
  "Oostham",
  "Oostkamp",
  "Oostkerke",
  "Oostkerke",
  "Oostmalle",
  "Oostnieuwkerke",
  "Oostrozebeke",
  "Oostvleteren",
  "Oostwinkel",
  "Opbrakel",
  "Opdorp",
  "Opglabbeek",
  "Opgrimbie",
  "Ophasselt",
  "Opheers",
  "Ophoven",
  "Opitter",
  "Oplinter",
  "Opoeteren",
  "Oppuurs",
  "Opvelp",
  "Opwijk",
  "Ordingen",
  "Orsmaal-Gussenhoven",
  "Otegem",
  "Ottenburg",
  "Ottergem",
  "Oud-Heverlee",
  "Oud-Turnhout",
  "Oudegem",
  "Oudekapelle",
  "Oudenaarde",
  "Oudenaken",
  "Oudenburg",
  "Outer",
  "Outgaarden",
  "Outrijve",
  "Ouwegem",
  "Overboelare",
  "Overhespen",
  "Overijse",
  "Overmere",
  "Overpelt",
  "Overrepen",
  "Overwinden",
  "Paal",
  "Pamel",
  "Parike",
  "Passendale",
  "Paulatem",
  "Peer",
  "Pellenberg",
  "Pepingen",
  "Perk",
  "Pervijze",
  "Petegem-aan-de-Leie",
  "Petegem-aan-de-Schelde",
  "Peutie",
  "Piringen",
  "Pittem",
  "Poederlee",
  "Poeke",
  "Poelkapelle",
  "Poesele",
  "Pollare",
  "Pollinkhove",
  "Poperinge",
  "Poppel",
  "Proven",
  "Pulderbos",
  "Pulle",
  "Putte",
  "Puurs",
  "Ramsdonk",
  "Ramsel",
  "Ramskapelle",
  "Ramskapelle",
  "Ransberg",
  "Ranst",
  "Ravels",
  "Reet",
  "Rekem",
  "Rekkem",
  "Relegem",
  "Remersdaal",
  "Reninge",
  "Reningelst",
  "Reppel",
  "Ressegem",
  "Retie",
  "Riemst",
  "Rijkel",
  "Rijkevorsel",
  "Rijkhoven",
  "Rijmenam",
  "Riksingen",
  "Rillaar",
  "Roborst",
  "Roesbrugge-Haringe",
  "Roeselare",
  "Roksem",
  "Rollegem",
  "Rollegem-Kapelle",
  "Romershoven",
  "Ronse",
  "Ronsele",
  "Roosbeek",
  "Rosmeer",
  "Rotem",
  "Rotselaar",
  "Rozebeke",
  "Ruddervoorde",
  "Ruien",
  "Ruisbroek",
  "Ruisbroek",
  "Ruiselede",
  "Rukkelingen-Loon",
  "Rumbeke",
  "Rummen",
  "Rumsdorp",
  "Rumst",
  "Runkelen",
  "Rupelmonde",
  "Rutten",
  "Schaffen",
  "Schalkhoven",
  "Schelderode",
  "Scheldewindeke",
  "Schelle",
  "Schellebelle",
  "Schendelbeke",
  "Schepdaal",
  "Scherpenheuvel",
  "Schilde",
  "Schoonaarde",
  "Schore",
  "Schorisse",
  "Schoten",
  "Schriek",
  "Schuiferskapelle",
  "Schulen",
  "Semmerzake",
  "Serskamp",
  "Sijsele",
  "Sinaai",
  "Sint-Agatha-Rode",
  "Sint-Amands",
  "Sint-Amandsberg",
  "Sint-Andries",
  "Sint-Antelinks",
  "Sint-Baafs-Vijve",
  "Sint-Blasius-Boekel",
  "Sint-Denijs",
  "Sint-Denijs-Boekel",
  "Sint-Denijs-Westrem",
  "Sint-Eloois-Vijve",
  "Sint-Eloois-Winkel",
  "Sint-Genesius-Rode",
  "Sint-Gillis-Waas",
  "Sint-Gillis-bij-Dendermonde",
  "Sint-Goriks-Oudenhove",
  "Sint-Huibrechts-Hern",
  "Sint-Huibrechts-Lille",
  "Sint-Jacobskapelle",
  "Sint-Jan",
  "Sint-Jan-in-Eremo",
  "Sint-Job-in-'t-Goor",
  "Sint-Joris",
  "Sint-Joris",
  "Sint-Joris-Weert",
  "Sint-Joris-Winge",
  "Sint-Katelijne-Waver",
  "Sint-Katherina-Lombeek",
  "Sint-Kornelis-Horebeke",
  "Sint-Kruis",
  "Sint-Kruis-Winkel",
  "Sint-Kwintens-Lennik",
  "Sint-Lambrechts-Herk",
  "Sint-Laureins",
  "Sint-Laureins-Berchem",
  "Sint-Lenaarts",
  "Sint-Lievens-Esse",
  "Sint-Lievens-Houtem",
  "Sint-Margriete",
  "Sint-Margriete-Houtem",
  "Sint-Maria-Horebeke",
  "Sint-Maria-Latem",
  "Sint-Maria-Lierde",
  "Sint-Maria-Oudenhove",
  "Sint-Martens-Bodegem",
  "Sint-Martens-Latem",
  "Sint-Martens-Leerne",
  "Sint-Martens-Lennik",
  "Sint-Martens-Lierde",
  "Sint-Martens-Voeren",
  "Sint-Michiels",
  "Sint-Niklaas",
  "Sint-Pauwels",
  "Sint-Pieters-Kapelle",
  "Sint-Pieters-Kapelle",
  "Sint-Pieters-Leeuw",
  "Sint-Pieters-Rode",
  "Sint-Pieters-Voeren",
  "Sint-Rijkers",
  "Sint-Stevens-Woluwe",
  "Sint-Truiden",
  "Sint-Ulriks-Kapelle",
  "Sleidinge",
  "Slijpe",
  "Sluizen",
  "Smeerebbe-Vloerzegem",
  "Smetlede",
  "Snaaskerke",
  "Snellegem",
  "Spalbeek",
  "Spiere",
  "Stabroek",
  "Staden",
  "Stalhille",
  "Stavele",
  "Steendorp",
  "Steenhuffel",
  "Steenhuize-Wijnhuize",
  "Steenkerke",
  "Steenokkerzeel",
  "Stekene",
  "Stene",
  "Sterrebeek",
  "Stevoort",
  "Stokkem",
  "Stokrooie",
  "Strijpen",
  "Strijtem",
  "Strombeek-Bever",
  "Stuivekenskerke",
  "Temse",
  "Teralfene",
  "Terhagen",
  "Ternat",
  "Tervuren",
  "Tessenderlo",
  "Testelt",
  "Teuven",
  "Tiegem",
  "Tielen",
  "Tielrode",
  "Tielt",
  "Tielt",
  "Tienen",
  "Tildonk",
  "Tisselt",
  "Tollembeek",
  "Tongeren",
  "Tongerlo",
  "Tongerlo",
  "Torhout",
  "Tremelo",
  "Turnhout",
  "Uikhoven",
  "Uitbergen",
  "Uitkerke",
  "Ulbeek",
  "Ursel",
  "Vaalbeek",
  "Val-Meer",
  "Varendonk",
  "Varsenare",
  "Vechmaal",
  "Veerle",
  "Veldegem",
  "Veldwezelt",
  "Velm",
  "Veltem-Beisem",
  "Velzeke-Ruddershove",
  "Verrebroek",
  "Vertrijk",
  "Veulen",
  "Veurne",
  "Viane",
  "Vichte",
  "Viersel",
  "Vilvoorde",
  "Vinderhoute",
  "Vinkem",
  "Vinkt",
  "Vissenaken",
  "Vladslo",
  "Vlamertinge",
  "Vlekkem",
  "Vlezenbeek",
  "Vliermaal",
  "Vliermaalroot",
  "Vlierzele",
  "Vlijtingen",
  "Vlimmeren",
  "Vlissegem",
  "Volkegem",
  "Vollezele",
  "Voorde",
  "Voormezele",
  "Voort",
  "Vorselaar",
  "Vorsen",
  "Vorst",
  "Vosselaar",
  "Vosselare",
  "Vossem",
  "Vrasene",
  "Vremde",
  "Vreren",
  "Vroenhoven",
  "Vucht",
  "Vurste",
  "Waanrode",
  "Waarbeke",
  "Waardamme",
  "Waarloos",
  "Waarmaarde",
  "Waarschoot",
  "Waasmont",
  "Waasmunster",
  "Wachtebeke",
  "Wakken",
  "Walem",
  "Walsbets",
  "Walshoutem",
  "Waltwilder",
  "Wambeek",
  "Wange",
  "Wannegem-Lede",
  "Wanzele",
  "Waregem",
  "Waterland-Oudeman",
  "Watervliet",
  "Watou",
  "Webbekom",
  "Wechelderzande",
  "Weelde",
  "Weerde",
  "Weert",
  "Welden",
  "Welle",
  "Wellen",
  "Wemmel",
  "Wenduine",
  "Werchter",
  "Werken",
  "Werm",
  "Wervik",
  "Wespelaar",
  "Westende",
  "Westerlo",
  "Westkapelle",
  "Westkerke",
  "Westmalle",
  "Westmeerbeek",
  "Westouter",
  "Westrem",
  "Westrozebeke",
  "Westvleteren",
  "Wetteren",
  "Wevelgem",
  "Wezemaal",
  "Wezembeek-Oppem",
  "Wezeren",
  "Wichelen",
  "Widooie",
  "Wiekevorst",
  "Wielsbeke",
  "Wieze",
  "Wijchmaal",
  "Wijer",
  "Wijgmaal",
  "Wijnegem",
  "Wijshagen",
  "Wijtschate",
  "Wilderen",
  "Willebringen",
  "Willebroek",
  "Wilrijk",
  "Wilsele",
  "Wilskerke",
  "Wimmertingen",
  "Wingene",
  "Winksele",
  "Wintershoven",
  "Woesten",
  "Wolvertem",
  "Wommelgem",
  "Wommersom",
  "Wondelgem",
  "Wontergem",
  "Wortegem",
  "Wortel",
  "Woubrechtegem",
  "Woumen",
  "Wulpen",
  "Wulvergem",
  "Wulveringem",
  "Wuustwezel",
  "Zaffelare",
  "Zandbergen",
  "Zande",
  "Zandhoven",
  "Zandvoorde",
  "Zandvoorde",
  "Zarlardinge",
  "Zarren",
  "Zaventem",
  "Zedelgem",
  "Zegelsem",
  "Zele",
  "Zelem",
  "Zellik",
  "Zelzate",
  "Zemst",
  "Zepperen",
  "Zerkegem",
  "Zevekote",
  "Zeveneken",
  "Zeveren",
  "Zevergem",
  "Zichem",
  "Zichen-Zussen-Bolder",
  "Zillebeke",
  "Zingem",
  "Zoerle-Parwijs",
  "Zoersel",
  "Zolder",
  "Zomergem",
  "Zonhoven",
  "Zonnebeke",
  "Zonnegem",
  "Zottegem",
  "Zoutenaaie",
  "Zoutleeuw",
  "Zuidschote",
  "Zuienkerke",
  "Zulte",
  "Zulzeke",
  "Zutendaal",
  "Zwevegem",
  "Zwevezele",
  "Zwijnaarde",
  "Zwijndrecht"
];
