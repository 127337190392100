import React, { FC } from "react";
import styled from "styled-components/native";
import { color } from "../../styles/theme";
import { PercentageIndicatorLineInterface } from "../../../fake-data/dashboardData";

/* we dont want to be figuring out colors as we go so we create a lookup table */

let colorLookupTable:Array<null> = []

let colorLookupTableSegments = [
    { start: 0, end: 10 },
    { start: 11, end: 25 },
    { start: 26, end: 50 },
    { start: 51, end: 80 },
    { start: 81, end: 94 },
    { start: 95, end: 100 }
]

for(let i=0; i<=100; i++) {
    colorLookupTableSegments.map(s => {
        if(i >= s.start && i <=s.end) {
            colorLookupTable.push(color['leaderboard' + String(s.start) + String(s.end)])
        }
    })
}

/* - */

const PercentageIndicatorLineContainer = styled.View`
    display: flex;
    width: 100%;
    height: 10px;
    align-items: flex-end;
    margin-top: 13px;
`;

const PercentageIndicatorLineBar = styled.View`
    flex: 1;
    width: ${(props) => props.percentage || 0}%;
    height: 10px;
    background: ${(props) => props.color || colorLookupTable[props.percentage || 0] || 'transparent'};
    border-radius: 10px;
`

const PercentageIndicatorLine: FC<PercentageIndicatorLineInterface> = ({
    percentage,
    color,
}) =>  (
        <PercentageIndicatorLineContainer>
            <PercentageIndicatorLineBar percentage={percentage} color={color}></PercentageIndicatorLineBar>
        </PercentageIndicatorLineContainer>
      );
  
  export default PercentageIndicatorLine;