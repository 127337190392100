module["exports"] = [
  "Agustina",
  "Andriani",
  "Anggraini",
  "Aryani",
  "Astuti",
  "Ananda",
  "Anandya",
  "Azzahra",
  "Aqila",
  "Amanta",
  "Afifah",
  "Ardiyanti",
  "Andini",
  "Aurelia",
  "Aryanti",
  "Azizah",
  "Anjani",
  "Damayanti",
  "Elvina",
  "Fujiati",
  "Farida",
  "Fathina",
  "Fernanda",
  "Febrianti",
  "Handayani",
  "Hassanah",
  "Hartati",
  "Hasanah",
  "Haryanti",
  "Hariyah",
  "Hastuti",
  "Halimah",
  "Harini",
  "Juwita",
  "Jelita",
  "Kusmawati",
  "Kuswandari",
  "Kusuma",
  "Kahyang",
  "Laksmiwati",
  "Laksita",
  "Lestari",
  "Lailasari",
  "Leilani",
  "Mandasari",
  "Mardhiyah",
  "Mayasari",
  "Melani",
  "Mulyani",
  "Maryati",
  "Mahestri",
  "Maharani",
  "Maheswari",
  "Melinda",
  "Mustika",
  "Nurdiyanti",
  "Novitasari",
  "Nuraini",
  "Nasyidah",
  "Nasyiah",
  "Namaga",
  "Nathania",
  "Nurlaela",
  "Ningrum",
  "Palastri",
  "Pudjiastuti",
  "Puspasari",
  "Puspita",
  "Purwanti",
  "Pratiwi",
  "Purnawati",
  "Pertiwi",
  "Permata",
  "Prastuti",
  "Padmasari",
  "Putri",
  "Purnama",
  "Rahmawati",
  "Rahayu",
  "Riyanti",
  "Rahimah",
  "Ramadhani",
  "Rachmawati",
  "Suartini",
  "Sudiati",
  "Suryatmi",
  "Susanti",
  "Safitri",
  "Salsabilla",
  "Salma",
  "Saputri",
  "Saraswati",
  "Oktaviani",
  "Utami",
  "Usamah",
  "Usada",
  "Uyainah",
  "Yuniar",
  "Yuliarti",
  "Yulianti",
  "Yolanda",
  "Wahyuni",
  "Wijayanti",
  "Widiastuti",
  "Winarsih",
  "Wulandari",
  "Wastuti",
  "Zulaika",
  "Zahrah"
];