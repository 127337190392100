import { useEffect, useState } from "react";
import { ArrayElement, ThenArg } from "../types";
import { useApi } from "./useApi";
import { useClient } from "./useClient";

const useMaps = ({ companyId }: { companyId?: string }) => {
  const client = useClient();

  type MapItem = ArrayElement<
    ThenArg<ReturnType<typeof client.listMaps>>["data"]["result"]["items"]
  >;

  const { handleRequest } = useApi();

  const [maps, setMaps] = useState<MapItem[]>([]);

  useEffect(() => {
    if (!companyId) {
      return setMaps([]);
    }
    const init = async () => {
      const response = await handleRequest(client.listMaps(companyId));

      setMaps(response?.data.result.items || []);
    };
    init();
  }, [handleRequest, companyId, client]);

  return maps;
};

export default useMaps;
